import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import DataTable from "../../components/table/DataTable";
import { RootState, useAppDispatch } from "../../store/store";
import Style from "./GroupManagementList.module.scss";

import moment from "moment";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useConfirm } from "material-ui-confirm";
import { useTranslation } from "react-multi-lang";
import TableFilters from "../../components/table/TableFilters";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import usePermission from "../../hooks/usePermission";
import DeleteSVG from "../../icons/DeleteSVG";
import EditSVG from "../../icons/EditSVG";
import ViewSVGAction from "../../icons/ViewSVGAction";
import {
  bulkDeleteGroups,
  deleteGroup,
  getListData,
  getWholeGroupListData,
} from "../../services/groupManagementService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import {
  groupManagementSelector,
  setPage,
  setWholeData,
} from "../../store/reducers/groupManagementReducer";
import { defaultThemeColor } from "../../utils/colors_values";
import { confirmProps } from "../../utils/confirmProps";

const GroupManagementList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const t = useTranslation();
  const lang = useCompanyLanguage();

  const addPermission = usePermission("add_customusergroup");
  const editUpdatePermission = usePermission("change_customusergroup");
  const deletePermission = usePermission("delete_customusergroup");
  const viewPermission = usePermission("view_customusergroup");
  const [itemPerPage, setItemPerPage] = useState<number>(10);

  const [searchTable, setSearchTable] = useState("");

  const [list, setList] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<any[]>([]);

  // const [page, setPage] = useState(1);
  const states = useSelector(groupManagementSelector).departmentReducer;
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;
  const [rows, setRows] = useState([]);
  const page = useSelector(
    (state: RootState) => state.groupManagementReducer.page
  );

  const dataList = useSelector(
    (state: RootState) => state.groupManagementReducer.data
  );

  const wholedataList = useSelector(
    (state: RootState) => state.groupManagementReducer.wholedata
  );

  const primaryButton =
    useColor("button_primary") || defaultThemeColor.button_primary;
  const primaryButtonText =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;

  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;
  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  useEffect(() => {
    dispatch(setPage(1));
  }, []);
  useEffect(() => {
    dispatch(
      getListData({
        page_size: Number(itemPerPage),
        page: Number(page),
        search: searchTable,
      })
    );
  }, [dispatch, itemPerPage, page, searchTable]);

  useEffect(() => {
    let data: any = dataList.results;
    if (Object.keys(dataList).length === 0) {
      setRows([]);
    } else setRows(data);
  }, [dataList]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      setSelectedCheckBox([]);
      dispatch(
        getListData({
          page_size: Number(itemPerPage),
          page: Number(page),
          search: searchTable,
        })
      );
    }
  }, [alertMessage]);

  const handleDeleteAction = (row: any) => {
    confirm({
      title: t("Are you sure!"),
      description: `Do you want to delete ${row?.name} group?`,
      ...confirmProps,
    })
      .then(() => {
        dispatch(deleteGroup(row.id));
      })
      .catch(() => {
        // toast.success("Your request canceled successfully!", {
        //   toastId: "Your request canceled successfully!",
        // });
      });
    return;
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setSelectedCheckBox([]);
    dispatch(setPage(page));
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    dispatch(setPage(1));
    setSelectedCheckBox([]);
    setItemPerPage(Number(rowsPerPage));
  };

  useEffect(() => {
    modifyRows();
  }, [rows]);
  const bulkDelete = (ids: any) => {
    // const sendSelected = selected.map((sel) => sel);
    confirm({
      title: t("Are you sure!"),
      description: t(`Do you want to delete selected groups?`),
      ...confirmProps,
    })
      .then(() => {
        dispatch(bulkDeleteGroups(ids));
        setSelectedCheckBox([]);
      })
      .catch(() => {
        setSelectedCheckBox([]);
      });
  };
  const modifyRows = () => {
    let f_rows: any = [];
    rows.forEach(
      (
        r: {
          id: string;
          name: string;
          is_auto_created: boolean;
          created_at: string;
          members_count: number;
        },
        i: number
      ) => {
        f_rows = [
          ...f_rows,
          {
            id: r.id,
            name: (
              <>
                {r.name}{" "}
                {r.is_auto_created && (
                  <span
                    className={`badge ${Style.badge} mx-2`}
                    style={{
                      background: primaryButton,
                      color: primaryButtonText,
                    }}
                  >
                    Auto
                  </span>
                )}
              </>
            ),
            no_of_members: r.members_count || 0,
            created_at:
              moment(new Date(r.created_at)).format("MMM Do, YYYY") || "",

            actions: (
              <div style={{ minWidth: "8em" }}>
                {viewPermission && (
                  <Link className="" to={`/detail-group/${r.id.toString()}`}>
                    <button
                      className="btn btn-link"
                      style={{ paddingInline: 5 }}
                    >
                      <ViewSVGAction />
                    </button>
                  </Link>
                )}
                {editUpdatePermission && (
                  <Link
                    className="text-capitalize"
                    to={`/detail-group/${r.id.toString()}`}
                  >
                    <EditSVG />
                  </Link>
                )}
                {deletePermission && (
                  <button
                    className="btn-link"
                    type="button"
                    onClick={() => {
                      handleDeleteAction(r);
                    }}
                  >
                    <DeleteSVG color="red" />
                  </button>
                )}
              </div>
            ),
          },
        ];
      }
    );

    setList(f_rows);
  };

  const AddGroup = () => {
    const handleAddNew = () => {
      navigate("/add-group/group-management");
    };

    const handleAddAutoGroup = () => {
      navigate("/add-auto-group");
    };

    return (
      <div className="d-flex flex-row gap-3">
        {addPermission && (
          <>
            <Button
              variant="text"
              color="inherit"
              endIcon={
                <AddIcon
                  style={{
                    fontSize: 16,
                    color: interfaceTextColor,
                    marginRight: lang === "ar" ? "10px" : "0px",
                  }}
                />
              }
              style={{
                fontSize: 13,
                color: interfaceTextColor,
                textTransform: "capitalize",
              }}
              onClick={() => handleAddNew()}
            >
              {t("Create Group")}
            </Button>
            <Button
              variant="text"
              color="inherit"
              endIcon={
                <AddIcon
                  style={{
                    fontSize: 16,
                    color: interfaceTextColor,
                    marginRight: lang === "ar" ? "10px" : "0px",
                  }}
                />
              }
              style={{
                fontSize: 13,
                color: interfaceTextColor,
                textTransform: "capitalize",
              }}
              onClick={() => handleAddAutoGroup()}
            >
              {t("Create Auto Group")}
            </Button>
          </>
        )}
      </div>
    );
  };

  const handleClicked = () => {
    getWholeAdminPermissions();
  };

  const getWholeAdminPermissions = () => {
    if (dataList)
      dispatch(
        getWholeGroupListData({
          page_size: dataList.count,
          page: 1,
          search: searchTable,
        })
      );
  };

  return (
    <>
      <div className={Style.pageStart}>
        <Header page={t("Group Management")} mainHead={t("Group Management")} />
        <TableFilters
          dataList={dataList}
          itemPerPage={itemPerPage}
          setItemPerPage={handleChangeRowsPerPage}
          selectedCheckBox={selectedCheckBox}
          bulkDelete={bulkDelete}
          setSearchTable={setSearchTable}
          createButton={<AddGroup />}
          handle="groups"
          isCheckboxes={deletePermission}
          exportCSV={true}
          setParentData={() => dispatch(setWholeData({}))}
          handleClicked={handleClicked}
          rows={wholedataList?.results?.map((r: any, i: any) => ({
            // num: i + 1,
            name: r.name + " " + (r.is_auto_created ? "(Auto)" : ""),
            no_of_members: r.members_count || 0,
            created_at:
              moment(new Date(r.created_at)).format("MMM Do, YYYY") || "",
          }))}
          headers={["Name", "No of Members", "Start Date"]}
          filename={"groups_list.csv"}
        />
        <DataTable
          columnData={[
            {
              id: "name",
              name: t("Name"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "checkbox",
            },

            {
              id: "no_of_members",
              name: t("No of Members"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "normal",
            },

            {
              id: "created_at",
              name: t("Created Date"),
              enableSort: true,
              align: "center",
              disablePadding: "none",
            },

            {
              id: "actions",
              name: t("Actions"),
              enableSort: false,
              align: "center",
              disablePadding: "none",
            },
          ]}
          rows={list}
          isCheckboxes={deletePermission}
          selectedRowsPerPage={itemPerPage.toString()}
          totalRecordCount={Number(dataList.count)}
          handleChangePageParent={handleChangePage}
          pagenum={Number(page)}
          handleChangeRowsPerPageParent={handleChangeRowsPerPage}
          bulkDelete={bulkDelete}
          selectedCheckBox={selectedCheckBox}
          setSelectedCheckBox={setSelectedCheckBox}
        />
      </div>
    </>
  );
};

export default GroupManagementList;
