import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
export interface DataList {
  count: number;
  next: string | null;
  previous: String | null;
  results: any[];
}
export interface InitState {
  data: DataList;
  page?: number;
  perPage?: number;
  search?: string;
  detail?: any;
  updatedData?: any;
}

export const initialState: InitState = {
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },

  page: 1,
  perPage: 10,
  search: "",
  updatedData: "",
};

export const policySlice = createSlice({
  name: "policy",
  initialState,
  reducers: {
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setPerPage: (state, { payload }) => {
      state.perPage = payload;
    },
    setSearch: (state, { payload }) => {
      state.search = payload;
    },
    setDetail: (state, { payload }) => {
      state.detail = payload;
    },
    setupdatedData: (state, { payload }) => {
      state.updatedData = payload;
    },
  },
});

export const {
  setData,
  setDetail,
  setSearch,
  setPerPage,
  setPage,
  setupdatedData,
} = policySlice.actions;

export const userSelector = (state: RootState) => state;

export default policySlice.reducer;
