import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
const licenseInfo = JSON.parse(localStorage.getItem("license_info") || "{}");
export interface licenseData {
  id: number;
  created_at: string;
  updated_at: string;
  is_active: boolean;
  name: string;
  email: string;
  lms: boolean;
  policy_management_selected: true;
  policy_management_expiry_date: any;
  phishing_management_selected: boolean;
  phishing_management_expiry_date: any;
  gamification_selected: boolean;
  gamification_expiry_date: any;
  number_of_users: any;
}
export interface InitState {
  data: licenseData;
}

export const initialState: InitState = {
  data: licenseInfo,
};

export const licenseSlice = createSlice({
  name: "license",
  initialState,
  reducers: {
    setData: (state, { payload }) => {
      state.data = payload;
    },
  },
});

export const { setData } = licenseSlice.actions;

export const userSelector = (state: RootState) => state;

export default licenseSlice.reducer;
