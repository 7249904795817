function ViewResultsSVG(props: { color: string }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 19C5 17.4845 5.60204 16.031 6.67368 14.9594C7.74531 13.8878 9.19876 13.2857 10.7143 13.2857C12.2298 13.2857 13.6833 13.8878 14.7549 14.9594C15.8265 16.031 16.4286 17.4845 16.4286 19H15C15 17.8634 14.5485 16.7733 13.7447 15.9695C12.941 15.1658 11.8509 14.7143 10.7143 14.7143C9.57764 14.7143 8.48756 15.1658 7.68383 15.9695C6.8801 16.7733 6.42857 17.8634 6.42857 19H5ZM10.7143 12.5714C8.34643 12.5714 6.42857 10.6536 6.42857 8.28571C6.42857 5.91786 8.34643 4 10.7143 4C13.0821 4 15 5.91786 15 8.28571C15 10.6536 13.0821 12.5714 10.7143 12.5714ZM10.7143 11.1429C12.2929 11.1429 13.5714 9.86429 13.5714 8.28571C13.5714 6.70714 12.2929 5.42857 10.7143 5.42857C9.13571 5.42857 7.85714 6.70714 7.85714 8.28571C7.85714 9.86429 9.13571 11.1429 10.7143 11.1429ZM16.6314 13.7879C17.6352 14.2399 18.4871 14.9723 19.0847 15.8969C19.6823 16.8216 20.0001 17.8991 20 19H18.5714C18.5716 18.1743 18.3333 17.3661 17.8851 16.6726C17.4369 15.9791 16.7979 15.4298 16.045 15.0907L16.6314 13.7879ZM16.14 5.72357C16.8597 6.02021 17.475 6.52396 17.9079 7.17088C18.3408 7.8178 18.5717 8.57874 18.5714 9.35714C18.5717 10.3374 18.2055 11.2823 17.5447 12.0063C16.8838 12.7303 15.9762 13.181 15 13.27V11.8321C15.5292 11.7563 16.0202 11.5129 16.4009 11.1375C16.7816 10.7621 17.0319 10.2745 17.1151 9.74638C17.1983 9.21825 17.11 8.67736 16.8631 8.20311C16.6163 7.72886 16.2239 7.34625 15.7436 7.11143L16.14 5.72357Z"
        fill={props?.color ?? "#2B3674"}
      />
    </svg>
  );
}

export default ViewResultsSVG;
