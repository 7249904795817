import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface LoaderState {
  isLoading: boolean;
}

export const initialState: LoaderState = {
  isLoading: false,
};

export const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
  },
});

export const { setLoading } = loaderSlice.actions;

export const loaderSelector = (state: RootState) => state;

export default loaderSlice.reducer;
