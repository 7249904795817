import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../components/Header";
import useColor from "../../../hooks/useColor";
import AssessImg from "../../../images/startAssessment.svg";
import { getPolicyAssessment } from "../../../services/policyAssessmentService";
import { RootState, useAppDispatch } from "../../../store/store";
import { defaultThemeColor } from "../../../utils/colors_values";
import { assessmentTimeOptionsLabel } from "../../../utils/staticEntities";
import Style from "./StartPolicyAssessment.module.scss";
// const trainingDetail = JSON.parse(
//   localStorage.getItem("training_detail") || "{}"
// );
function StartPolicyAssessment() {
  const { policy_assessment_id }: any = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const assessmentData: any = useSelector(
    (state: RootState) => state.policyAssessmentReducer.assessmentData
  );

  useEffect(() => {
    dispatch(getPolicyAssessment({ id: Number(policy_assessment_id) }));
  }, [policy_assessment_id]);

  return (
    <div
      className={`${Style.background}`}
      style={{
        background:
          useColor("interface_background_color") ||
          defaultThemeColor.interface_background_color,
        color:
          useColor("interface_foreground_text_color") ||
          defaultThemeColor.interface_foreground_text_color,
      }}
    >
      <div style={{ paddingBottom: "2.4em" }}>
        <Header
          page={assessmentData?.name}
          mainHead="Policy Assessment"
          // backLink={`/notifications`}
        />
      </div>
      <div>
        <div
          style={{
            background:
              useColor("interface_foreground_color") ||
              defaultThemeColor.interface_foreground_color,

            borderRadius: 10,
            padding: "3rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
          className={Style.bottomSpace}
        >
          <div className={`${Style.mainWrapper}`}>
            <div className={Style.assessImgWrap}>
              <img src={AssessImg} alt="assess-img" />
            </div>
            <div className={Style.assessHeading}>{assessmentData?.name}</div>
            <div
              className={Style.flexWrapper}
              style={{
                minWidth: "22em",
                justifyContent:
                  assessmentData?.time_option !== "No_Limit"
                    ? "space-between"
                    : "center",
              }}
            >
              <div className={Style.questionWrap}>
                <div className={Style.heading}>Questions</div>
                <div className={Style.count}>
                  {assessmentData?.questions?.length} Questions
                </div>
              </div>
              {/* <div className={Style.secWrap}>
                <div className={Style.heading}>Sections</div>
                <div className={Style.count}>2 Sections</div>
              </div> */}
              {assessmentData?.time_option !== "No_Limit" && (
                <div className={Style.durationWrap}>
                  <div className={Style.heading}>Time Duration</div>
                  <div className={Style.count}>
                    {assessmentData?.time_limit}{" "}
                    {assessmentTimeOptionsLabel[assessmentData?.time_option]}
                  </div>
                </div>
              )}
            </div>
            {assessmentData?.results?.score ? (
              <button
                className={Style.startBtn}
                type="button"
                onClick={() => {
                  navigate(
                    `/policy-assessment-progress-report/${policy_assessment_id}`
                  );
                }}
              >
                Result
              </button>
            ) : (
              <button
                className={Style.startBtn}
                onClick={() =>
                  navigate(`/policy-assessment-started/${policy_assessment_id}`)
                }
              >
                Start
              </button>
            )}
            {/* <button
              className={Style.startBtn}
              onClick={() =>
                navigate(
                  `/assessment-started/${training_id}/${module_id}/${assessment_id}`
                )
              }
            >
              Start
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StartPolicyAssessment;
