function BackArrowSVG(props: { color?: string }) {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.02857 9.98682H22.2973C22.5661 9.98682 22.8239 10.0936 23.0139 10.2836C23.204 10.4737 23.3107 10.7315 23.3107 11.0003C23.3107 11.269 23.204 11.5268 23.0139 11.7169C22.8239 11.9069 22.5661 12.0137 22.2973 12.0137H2.02857C1.75979 12.0137 1.50202 11.9069 1.31197 11.7169C1.12191 11.5268 1.01514 11.269 1.01514 11.0003C1.01514 10.7315 1.12191 10.4737 1.31197 10.2836C1.50202 10.0936 1.75979 9.98682 2.02857 9.98682Z"
        fill={props.color}
      />
      <path
        d="M2.44825 10.9999L10.8537 19.4033C11.044 19.5936 11.1509 19.8517 11.1509 20.1208C11.1509 20.3899 11.044 20.648 10.8537 20.8383C10.6634 21.0286 10.4053 21.1355 10.1362 21.1355C9.86706 21.1355 9.60896 21.0286 9.41866 20.8383L0.297735 11.7174C0.203357 11.6233 0.128479 11.5114 0.0773887 11.3883C0.0262984 11.2652 0 11.1332 0 10.9999C0 10.8666 0.0262984 10.7346 0.0773887 10.6115C0.128479 10.4884 0.203357 10.3765 0.297735 10.2824L9.41866 1.16146C9.60896 0.971165 9.86706 0.864258 10.1362 0.864258C10.4053 0.864258 10.6634 0.971165 10.8537 1.16146C11.044 1.35176 11.1509 1.60985 11.1509 1.87897C11.1509 2.14809 11.044 2.40619 10.8537 2.59649L2.44825 10.9999Z"
        fill={props.color}
      />
    </svg>
  );
}

export default BackArrowSVG;
