import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
export interface InitState {
  data?: any;
}

export const initialState: InitState = {
  data: null,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setData: (state, { payload }) => {
      state.data = payload;
    },
  },
});

export const { setData } = dashboardSlice.actions;

export const userSelector = (state: RootState) => state;

export default dashboardSlice.reducer;
