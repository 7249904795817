import UnfoldMoreTwoToneIcon from "@mui/icons-material/UnfoldMoreTwoTone";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/material/styles";
import Style from "./TableComponent.module.scss";
import { useTranslation } from "react-multi-lang";
import useCompanyLanguage from "../../hooks/language";

interface Params {
  totalCount: number;
  perpage: number;
  handleChangePageNum: any;
  pagenum: number;
  handleChangeRowsPerPageParent: any;
}
const SelectField = styled(Select)({
  "& #demo-simple-select-autowidth": {
    paddingRight: 4,
    paddingLeft: "1em",
  },
});

const Paginations = ({
  totalCount,
  perpage,
  handleChangePageNum,
  pagenum,
  handleChangeRowsPerPageParent,
}: Params) => {
  const t = useTranslation();
  const lang = useCompanyLanguage();
  return (
    <div
      className={`${Style.customStyle} d-flex flex-wrap justify-content-between align-items-center mt-3 px-3`}
    >
      <div className={Style.paginationBottom}>
        {t("Showing")} {pagenum * perpage - (perpage - 1)} {t("to")}{" "}
        {Number(pagenum) * Number(perpage) > totalCount
          ? totalCount
          : Number(pagenum) * Number(perpage)}{" "}
        {t("of")} {totalCount || 0} {t("entries")}
      </div>

      <div className={`d-flex align-items-center mr-3 ${Style.styleSum}`}>
        <span
          className={`d-flex align-items-center ${Style.paginationBottom}`}
          style={{ flexDirection: "row", justifyContent: "left" }}
        >
          <span className={`${Style.paginationDisplayBottom}`}>{t("Display")}</span>
          <FormControl
            variant="standard"
            sx={{ m: 0, mr: 3, minWidth: "4em" }}
            className={Style.aligncen}
          >
            <InputLabel
              id="demo-simple-select-autowidth-label"
              style={{ fontSize: "12px" }}
              disableAnimation={true}
              sx={{ p: 0 }}
              shrink={false}
            ></InputLabel>
            <SelectField
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={perpage}
              style={{
                border: "1px solid #c7c7c7",
                marginTop: 0,
                marginLeft: 15,
                minWidth: "6em",
                paddingRight: lang === "ar" ? "2rem" : "4px",
              }}
              onChange={(e) => handleChangeRowsPerPageParent(Number(e.target.value as string))}
              disableUnderline
              IconComponent={(props) => <UnfoldMoreTwoToneIcon {...props} />}
            >
              <MenuItem value={Number(totalCount)}>All ({Number(totalCount) || 0})</MenuItem>
              <MenuItem value="10">10</MenuItem>
              <MenuItem value="15">15</MenuItem>
              <MenuItem value="25">25</MenuItem>
            </SelectField>
          </FormControl>
        </span>
        <div className={Style.styleSum2}>
          <Pagination
            count={Math.ceil(Number(totalCount) / Number(perpage)) || 0}
            onChange={handleChangePageNum}
            page={Number(pagenum)}
            defaultPage={Number(pagenum)}
            shape="rounded"
            color="primary"
          />
        </div>
      </div>
    </div>
  );
};
export default Paginations;
