// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ColorPicker_editColorButton__KCRNr {
  background: #E8F7FF;
  border: none;
  outline: none;
  padding: 10px 1rem;
  border-radius: 5px;
}
.ColorPicker_editColorButton__KCRNr .ColorPicker_editText__84sxp {
  padding-left: 10px;
  color: #0089CF;
}`, "",{"version":3,"sources":["webpack://./src/pages/CompanySettings/colorPicker/ColorPicker.module.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,kBAAA;AACJ;AAAI;EACI,kBAAA;EACA,cAAA;AAER","sourcesContent":[".editColorButton {\n    background: #E8F7FF;\n    border: none;\n    outline: none;\n    padding: 10px 1rem;\n    border-radius: 5px;\n    .editText {\n        padding-left: 10px;\n        color: #0089CF;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editColorButton": `ColorPicker_editColorButton__KCRNr`,
	"editText": `ColorPicker_editText__84sxp`
};
export default ___CSS_LOADER_EXPORT___;
