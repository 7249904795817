import { CircularProgressbar } from "react-circular-progressbar";
export function CustomProgressbar(props: any) {
  const { children, color, ...otherProps } = props;

  const customStyles = {
    path: {
      stroke: color || "#00CF3A",
    },
  };
  return (
    <div
      style={{
        position: "relative",
        width: "120px",
        height: "100%",
      }}
    >
      <div style={{ position: "absolute" }}>
        <CircularProgressbar {...otherProps} styles={customStyles} />
      </div>
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props.children}
      </div>
    </div>
  );
}

export const maskSecret = (password: string) => {
  // Check if the password length is less than 3
  if (password.length < 3) {
    return password; // If so, return the password as it is
  }

  // Replace characters between the first and last characters with asterisks
  return password[0] + "*".repeat(password.length - 2) + password.slice(-2);
};
