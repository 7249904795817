import { yupResolver } from "@hookform/resolvers/yup";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import PasswordStrengthBar from "react-password-strength-bar";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import { newPassowrd } from "../../services/authService";
import { setAuthFailed } from "../../store/reducers/authReducer";
import { useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import Footer from "./Footer";
import Header from "./Header";
import Style from "./ResetPassword.module.scss";

const schema = yup
  .object({
    password: yup
      .string()
      .min(8, "Password must contain 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must contain 8 characters, One uppercase, one lowercase, One number and one special case character"
      )
      .required("Password is required"),
    RetypePassword: yup
      .string()
      .min(8, "Retype password must contain 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must contain 8 characters, One uppercase, one lowercase, One number and one special case character"
      )
      .required("Re-type password is required")
      .oneOf([yup.ref("password"), ""], "Re-type password does not match"),
  })
  .required();
const NewPassword = () => {
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const error = useSelector((state: any) => state.authReducer.error);
  const isLoading = useSelector((state: any) => state.loaderReducer.isLoading);
  const isUpdated = useSelector(
    (state: any) => state.authReducer.updatedPassword
  );
  const [fieldTypePassword, setFieldTypePassword] = useState<any>("password");
  const [fieldTypeConfirmPassword, setFieldTypeConfirmPassword] =
    useState<any>("password");
  useEffect(() => {
    if (error) {
      toast.error(String(error), {
        toastId: String(error),
      });
      dispatch(setAuthFailed(null));
    }
  }, [error, dispatch]);
  useEffect(() => {
    if (isUpdated) {
      navigate("/verification-success");
    }
  }, [isUpdated]);
  const onSubmit = (data: any) => {
    dispatch(newPassowrd(data));
  };
  const showHidePassword = () => {
    if (fieldTypePassword === "password") {
      setFieldTypePassword("text");
    } else {
      setFieldTypePassword("password");
    }
  };
  const showHideConfmPassword = () => {
    if (fieldTypeConfirmPassword === "password") {
      setFieldTypeConfirmPassword("text");
    } else {
      setFieldTypeConfirmPassword("password");
    }
  };
  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;
  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;
  const primaryButton =
    useColor("button_primary") || defaultThemeColor.button_primary;
  const primaryButtonText =
    useColor("button_primary_text") ||
    defaultThemeColor.button_button_primary_text;
  return (
    <div
      style={{
        direction: lang === "ar" ? "rtl" : "ltr",
        background: interfaceBgColor,
        color: interfaceTextColor,
        overflowY: "auto",
        height: "100vh",
      }}
    >
      <Header />
      <div
        className={`${Style.resetPasswordStart}`}
        style={{
          color: interfaceTextColor,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "3em",
        }}
      >
        <h2
          className={Style.maxWidd}
          style={{
            color: interfaceTextColor,
          }}
        >
          {t("Reset your password")}
        </h2>
        <p
          className={Style.maxWidd}
          style={{
            color: interfaceTextColor,
          }}
        >
          {t("Type in your new password")}
        </p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          style={{
            marginTop: "1.5em",
          }}
        >
          <div className="position-relative">
            <input
              className={`${Style.inputfields} ${Style.fieldFont}  ${
                errors.password ? "is-invalid" : ""
              }`}
              placeholder={t("New password *")}
              type={fieldTypePassword}
              {...register("password")}
              autoComplete="new-password"
            />
            <PasswordStrengthBar
              scoreWords={["Weak", "Weak", "Average", "Good", "Strong"]}
              shortScoreWord={[""]}
              minLength={8}
              password={watch("password")}
            />
            <button
              className="btn eye-btn"
              type="button"
              onClick={showHidePassword}
            >
              {fieldTypePassword === "password" ? (
                <VisibilityOutlined />
              ) : (
                <VisibilityOffOutlined />
              )}
            </button>
            <p className="color-red">{errors.password?.message}</p>
          </div>
          <div className="position-relative">
            <input
              className={`${Style.inputfields} ${Style.fieldFont}  ${
                errors.RetypePassword ? "is-invalid" : ""
              }`}
              placeholder={t("Retype new password *")}
              type={fieldTypeConfirmPassword}
              {...register("RetypePassword")}
              autoComplete="new-confirm-password"
            />
            <button
              className="btn eye-btn"
              type="button"
              onClick={showHideConfmPassword}
            >
              {fieldTypeConfirmPassword === "password" ? (
                <VisibilityOutlined />
              ) : (
                <VisibilityOffOutlined />
              )}
            </button>
            <p className="color-red">{errors.RetypePassword?.message}</p>
          </div>

          <button
            type="submit"
            className={`btn ${Style.mt24} ${Style.loginBtn}`}
            style={{ color: primaryButtonText, background: primaryButton }}
            disabled={isLoading}
          >
            <span className={Style.alignItemsInside}>
              <span
                className={`p-1 ${Style.hThree} ${Style.buttonFont} ${Style.white}`}
              >
                {t("RESET")}
              </span>
              <span className={Style.iconButton}>
                <ArrowForwardOutlinedIcon />
              </span>
            </span>
          </button>
        </form>
        <Link to="/login" className="d-flex justify-content-center w-100">
          <button
            className={`btn btn-dark w-100 ${Style.CreateAccountBtn} ${Style.mt80}`}
          >
            <span className={Style.alignItemsInside}>
              <span
                className={`p-1 ${Style.hThree} ${Style.buttonFont} ${Style.white}`}
              >
                {t("BACK TO LOGIN")}
              </span>
            </span>
          </button>
        </Link>
        <Footer />
      </div>
    </div>
  );
};

export default NewPassword;
