import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";
import useCompanyLanguage from "../hooks/language";

function CertificateBuilder({
  imageCertificate,
  imageDisplayable,
  certificateData,
  varsToReplace,
}: {
  imageCertificate: any;
  imageDisplayable: any;
  certificateData: any;
  varsToReplace: any;
}) {
  const [final, setFinal] = useState<any>();
  const [width, setWidht] = useState<any>();
  const [isView, setView] = useState<any>(false);
  const lang = useCompanyLanguage();

  useEffect(() => {
    let finalm = certificateData;
    varsToReplace.forEach((element: any, index: any) => {
      finalm = finalm.replaceAll(
        Object.keys(element)[0],
        Object.values(element)[0]
      );
    });
    setFinal(finalm);
    if (certificateData) {
      setView(true);
    }
  }, [certificateData, varsToReplace]);
  const pickerLabelStyles: React.CSSProperties = {
    paddingRight: lang === "ar" ? "2rem" : "0rem",
  };

  const getMeta = (url: any, cb: any) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  useEffect(() => {
    // Use like:
    if (imageDisplayable) {
      getMeta(imageDisplayable, (err: any, img: any) => {
        // let myImg: any = document.querySelector("#backimage");
        let realWidth = img.naturalWidth;
        let realHeight = img.naturalHeight;

        setWidht(realWidth);
      });
    }
  }, [imageDisplayable]);

  return (
    <>
      {isView && (
        <>
          <div
            style={{
              minHeight: '100%' ? "20em" : "0em",
              height: '105vh',
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ width: '100%' || "0px", position:'relative' }} id="print">
              {imageDisplayable && (
                <img
                  id={"backimage"}
                  className="mx-2"
                  style={{
                    width: '100%' || "0px",
                  }}
                  src={imageDisplayable}
                  alt="certificate"
                />
              )}

              <div
                style={{
                  position: "absolute",
                  top: "2.3em",
                  width: '100%' || "0px",
                  
                }}
                className="view ql-editor"
                dangerouslySetInnerHTML={{
                  __html: final,
                }}
              />
            </div>
          </div>
          <style>
            {`
          .ql-picker-label {
            ${Object.keys(pickerLabelStyles)
              .map((key) => `${key}: ${(pickerLabelStyles as any)[key]};`)
              .join(" ")}
          }
        `}
          </style>
        </>
      )}
    </>
  );
}

export default CertificateBuilder;
