import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface State {
  errors?: any | null;
  error?: string | null;
  success?: any | null;
}

export const initialState: State = {
  error: null,
  errors: null,
  success: null,
};

export const alertMessageSlice = createSlice({
  name: "alertMessage",
  initialState,
  reducers: {
    setErrors: (state, { payload }) => {
      state.errors = payload;
    },
    setErr: (state, { payload }) => {
      state.error = payload;
    },
    setSuccess: (state, { payload }) => {
      state.success = payload;
    },
  },
});

export const { setErr, setErrors, setSuccess } = alertMessageSlice.actions;

export const alertMessageSelector = (state: RootState) => state;

export default alertMessageSlice.reducer;
