import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DataTable from "../../components/table/DataTable";
import TableFilters from "../../components/table/TableFilters";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import usePermission from "../../hooks/usePermission";
import DeleteSVG from "../../icons/DeleteSVG";
import { bulkDeleteGroups } from "../../services/groupManagementService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { setPage } from "../../store/reducers/departmentReducer";
import { groupManagementSelector } from "../../store/reducers/groupManagementReducer";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import { confirmProps } from "../../utils/confirmProps";
import Style from "./AddModule.module.scss";

export interface SimpleDialogProps {
  setSelectedRows: any;
  selectedRows: any;
  setDefaultRows: any;
  defaultRows: any;
  setOpen: any;
  id?: any;
}

function AddAssessments(props: SimpleDialogProps) {
  const {
    id,
    setSelectedRows,
    selectedRows,
    setDefaultRows,
    defaultRows,
    setOpen,
  } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const t = useTranslation();
  const lang = useCompanyLanguage();

  const addPermission = usePermission("add_customusergroup");
  const editUpdatePermission = usePermission("change_customusergroup");
  const deletePermission = usePermission("delete_customusergroup");
  const viewPermission = usePermission("view_customusergroup");
  const [itemPerPage, setItemPerPage] = useState<number>(10);
  const [searchTable, setSearchTable] = useState("");

  const [list, setList] = useState([]);
  const [listCSV, setListCSV] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<any[]>([]);
  // const [page, setPage] = useState(1);
  const states = useSelector(groupManagementSelector).departmentReducer;
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;
  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  const [rows, setRows] = React.useState<any[]>([]);

  const page = useSelector(
    (state: RootState) => state.groupManagementReducer.page
  );

  const dataList = useSelector(
    (state: RootState) => state.groupManagementReducer.data
  );

  const handleSearchChange = (searchTerm: any) => {
    let data: any = selectedRows?.results ? selectedRows?.results : defaultRows;
    if (!searchTerm) {
      setRows(data);
    } else {
      const filteredRows = data?.filter((row: any) =>
        row?.first_name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      );
      setRows(filteredRows);
    }
  };

  useEffect(() => {
    dispatch(setPage(1));
  }, []);

  useEffect(() => {
    // dispatch(
    //   getListData({
    //     page_size: Number(itemPerPage),
    //     page: Number(page),
    //     search: searchTable,
    //   })
    // );
  }, [dispatch, itemPerPage, page, searchTable]);

  useEffect(() => {
    let data: any = selectedRows.results;
    if (Object.keys(selectedRows).length === 0) {
      setRows([]);
    } else setRows(data);
  }, [selectedRows]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      setSelectedCheckBox([]);
      // dispatch(
      //   getListData({
      //     page_size: Number(itemPerPage),
      //     page: Number(page),
      //     search: searchTable,
      //   })
      // );
    }
  }, [alertMessage]);

  useEffect(() => {
    handleSearchChange(searchTable);
  }, [searchTable]);

  useEffect(() => {
    if (defaultRows) {
      let data: any = defaultRows;
      if (Object.keys(defaultRows).length === 0) {
        setRows([]);
      } else setRows(data);
    }
  }, [defaultRows]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteAction = (row: any) => {
    confirm({
      title: t("Are you sure!"),
      description: `Do you want to delete ${row?.name} assessment?`,
      ...confirmProps,
    })
      .then(() => {
        if (selectedRows?.length === 0) {
          setDefaultRows(defaultRows.filter((item: any) => item.id != row?.id));
        } else {
          const updatedDataList = {
            ...selectedRows,
            count: selectedRows.count - 1,
            results: selectedRows.results.filter(
              (item: any) => item.id != row?.id
            ),
          };
          setSelectedRows(updatedDataList);
        }
      })
      .catch(() => {
        // toast.success("Your request canceled successfully!", {
        //   toastId: "Your request canceled successfully!",
        // });
      });
    return;
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setSelectedCheckBox([]);
    dispatch(setPage(page));
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    dispatch(setPage(1));
    setSelectedCheckBox([]);
    setItemPerPage(Number(rowsPerPage));
  };

  useEffect(() => {
    modifyRows();
  }, [rows]);

  const bulkDelete = (ids: any) => {
    // const sendSelected = selected.map((sel) => sel);
    confirm({
      title: t("Are you sure!"),
      description: t(`Do you want to delete selected groups?`),
      ...confirmProps,
    })
      .then(() => {
        dispatch(bulkDeleteGroups(ids));
        setSelectedCheckBox([]);
      })
      .catch(() => {});
  };

  const modifyRows = () => {
    let f_rows: any = [];
    rows.forEach(
      (
        r: {
          id: string;
          name: string;
          description: string;
          category: { id: number; name: string };
          // time_option: string;
          created_at: string;
          updated_at: string;
          assessment_status: string;
        },
        i: number
      ) => {
        f_rows = [
          ...f_rows,
          {
            id: r.id,
            name: r.name,
            description: (
              <div style={{ minWidth: "12em" }}>{r.description}</div>
            ),
            category: r.category?.name,
            // time_option: r.time_option,
            created_at:
              moment(new Date(r.created_at)).format("MMM Do, YYYY") || "",
            updated_at:
              moment(new Date(r.updated_at)).format("MMM Do, YYYY") || "",
            status: (
              <span
                className={
                  r.assessment_status === "Approved"
                    ? Style.statusSuccess
                    : Style.statusDraft
                }
              >
                {r.assessment_status}
              </span>
            ),
            actions: (
              <div style={{ minWidth: "13em" }}>
                <button
                  className="btn-link"
                  type="button"
                  onClick={() => {
                    handleDeleteAction(r);
                  }}
                >
                  <DeleteSVG color="red" />
                </button>
              </div>
            ),
          },
        ];
      }
    );
    setList(f_rows);
  };

  const AddAssessments = () => {
    const handleAddNew = () => {
      setOpen(true);
    };

    return (
      <div className="d-flex flex-row gap-3">
        {/* {addPermission && ( */}
        <>
          <Button
            variant="text"
            color="inherit"
            endIcon={
              <AddIcon
                style={{
                  fontSize: 16,
                  color:
                    useColor("interface_foreground_text_color") ||
                    defaultThemeColor.interface_foreground_text_color,
                  marginRight: lang === "ar" ? "10px" : "0px",
                }}
              />
            }
            style={{
              fontSize: 13,
              color:
                useColor("interface_foreground_text_color") ||
                defaultThemeColor.interface_foreground_text_color,
              textTransform: "capitalize",
            }}
            onClick={() => handleAddNew()}
          >
            {t("Add New")}
          </Button>
        </>
        {/* )} */}
      </div>
    );
  };

  const handleClicked = () => {
    // getWholeMembers();
    setListCSV(list);
  };

  return (
    <>
      <div
        className="mt-5"
        style={{ paddingRight: lang === "ar" ? "2rem" : "0rem" }}
      >
        <div
          className={`${Style.header} mb-3`}
          style={{
            color:
              useColor("interface_foreground_text_color") ||
              defaultThemeColor.interface_foreground_text_color,
          }}
        >
          {t("Add Assessments")}
        </div>
        <TableFilters
          dataList={selectedRows}
          itemPerPage={itemPerPage}
          setItemPerPage={setItemPerPage}
          selectedCheckBox={selectedCheckBox}
          bulkDelete={bulkDelete}
          setSearchTable={setSearchTable}
          createButton={<AddAssessments />}
          handle="addAssessments"
          exportCSV={false}
          setParentData={() => setListCSV([])}
          handleClicked={handleClicked}
          rows={listCSV?.map((r: any, i: any) => ({
            // id: r.id,
            name: r.name,
            description: r?.description?.props.children,
            category: r.category?.name,
            created_at: r.created_at,
            updated_at: r.updated_at,
            status: r.status?.props?.children,
          }))}
          headers={[
            // "Id.",
            "Assessment Name",
            "Description",
            "Category",
            "Created At",
            "Updated At",
            "Status",
          ]}
          filename={`assessments_of_module_${id || ""}.csv`}
        />

        <div style={{ marginRight: "0.7em" }}>
          <DataTable
            columnData={[
              {
                id: "assessment_name",
                name: t("Assessment Name"),
                enableSort: true,
                align: lang === "ar" ? "center" : "left",
                disablePadding: "none",
              },
              {
                id: "description",
                name: t("Description"),
                enableSort: true,
                align: lang === "ar" ? "center" : "left",
                disablePadding: "none",
              },
              {
                id: "category",
                name: t("Category"),
                enableSort: true,
                align: lang === "ar" ? "center" : "left",
                disablePadding: "none",
              },
              // {
              //   id: "time_optionn",
              //   name: "Time Option",
              //   enableSort: true,
              //   align: "left",
              //   disablePadding: "none",
              // },

              {
                id: "created_at",
                name: t("Created At"),
                enableSort: true,
                align: lang === "ar" ? "center" : "left",
                disablePadding: "none",
              },
              {
                id: "updated_at",
                name: t("Updated At"),
                enableSort: true,
                align: lang === "ar" ? "center" : "left",
                disablePadding: "none",
              },
              {
                id: "status",
                name: t("Status"),
                enableSort: true,
                align: lang === "ar" ? "center" : "center",
                disablePadding: "none",
              },

              {
                id: "actions",
                name: "Actions",
                enableSort: false,
                align: "center",
                disablePadding: "none",
              },
            ]}
            rows={list}
            showLoader={false}
            isCheckboxes={false}
            selectedRowsPerPage={itemPerPage.toString()}
            totalRecordCount={Number(selectedRows.count)}
            handleChangePageParent={handleChangePage}
            pagenum={Number(page)}
            handleChangeRowsPerPageParent={handleChangeRowsPerPage}
            bulkDelete={bulkDelete}
            selectedCheckBox={selectedCheckBox}
            setSelectedCheckBox={setSelectedCheckBox}
            isSelectedRowsOnly={true}
          />
        </div>
      </div>
    </>
  );
}

export default AddAssessments;
