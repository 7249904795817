// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ControlIcons_controls__div__TB-8r {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 2;
  background: rgba(0, 0, 0, 0.6);
}

.ControlIcons_controls__nav__S70Ih {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ControlIcons_controls__icons__llA4- {
  color: white;
  font-size: 50;
  transform: scale(0.9);
}

.ControlIcons_controls__icons__llA4-:hover {
  color: #fff;
  transform: scale(1);
}

.ControlIcons_bottom__icons__F\\+Xcd {
  color: #999;
}

.ControlIcons_bottom__icons__F\\+Xcd:hover {
  color: white;
}

.ControlIcons_header__L76Tw {
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.64px;
}

.ControlIcons_subTopic__vwIjh {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.4px;
}

.ControlIcons_linearGradient__apiKW {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
  min-height: 100%;
}

.ControlIcons_module__Dqycj {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ControlIcons_topicName__TgC11 {
  color: #fbfbfb;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/components/VideoPlayer/ControlIcons.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,aAAA;EACA,sBAAA;EACA,UAAA;EACA,8BAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,qBAAA;AACF;;AAEA;EACE,WAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;AACF;;AAEA;EACE,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,sBAAA;AACF;;AAEA;EACE,0FAAA;EACA,gBAAA;AACF;;AAEA;EACE,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACF","sourcesContent":[".controls__div {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  display: flex;\n  flex-direction: column;\n  z-index: 2;\n  background: rgba(0, 0, 0, 0.6);\n}\n\n.controls__nav {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n\n.controls__icons {\n  color: white;\n  font-size: 50;\n  transform: scale(0.9);\n}\n\n.controls__icons:hover {\n  color: #fff;\n  transform: scale(1);\n}\n\n.bottom__icons {\n  color: #999;\n}\n\n.bottom__icons:hover {\n  color: white;\n}\n\n.header {\n  color: #fff;\n  font-size: 32px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 42px;\n  letter-spacing: -0.64px;\n}\n\n.subTopic {\n  color: #fff;\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  letter-spacing: -0.4px;\n}\n\n.linearGradient {\n  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0) 100%);\n  min-height: 100%;\n}\n\n.module {\n  color: #fff;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n}\n\n.topicName {\n  color: #fbfbfb;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controls__div": `ControlIcons_controls__div__TB-8r`,
	"controls__nav": `ControlIcons_controls__nav__S70Ih`,
	"controls__icons": `ControlIcons_controls__icons__llA4-`,
	"bottom__icons": `ControlIcons_bottom__icons__F+Xcd`,
	"header": `ControlIcons_header__L76Tw`,
	"subTopic": `ControlIcons_subTopic__vwIjh`,
	"linearGradient": `ControlIcons_linearGradient__apiKW`,
	"module": `ControlIcons_module__Dqycj`,
	"topicName": `ControlIcons_topicName__TgC11`
};
export default ___CSS_LOADER_EXPORT___;
