import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Header from "../../components/Header";
import BaseImage from "../../images/ph_image.png";
import { RootState, useAppDispatch, useAppSelector } from "../../store/store";
import Style from "./MyProfile.module.scss";

import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-multi-lang";
import { toast } from "react-toastify";
import ImageSelect from "../../components/ImageSelect";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import { getUserDetail, updateUser } from "../../services/adminUserService";
import {
  alertMessageSelector,
  setErr,
  setErrors,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { defaultThemeColor } from "../../utils/colors_values";
import CompanyProfileManagement from "../CompanySettings/companyProfile";
// import EditAdminProfile from "./EditAdminProfile";
import EditProfile from "./EditProfile";
// import ViewAdminProfile from "./ViewAdminProfile";
import ViewProfile from "./ViewProfile";

const $ = require("jquery");

const schema = yup
  .object({
    profile_picture: yup
      .mixed()
      // .test(
      //   "type",
      //   "Invalid profile image type. Profile image type should be jpeg, jpg or png",
      //   (file: any) => {
      //     // console.log("file[0].type", file.type);
      //     return (
      //       file.length &&
      //       ["image/jpeg", "image/png", "image/jpg"].includes(file.type)
      //     );
      //   }
      // )
      .required("Profile picture is required"),
  })
  .required();
function MyProfile() {
  const {
    setError,
    setValue,
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const userInfo = useSelector((state: any) => state.authReducer.loginData);
  const { type } = useParams();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const isLoading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  const detail = useSelector(
    (state: RootState) => state.adminUserReducer.detail
  );

  console.log("detail", detail);
  const [edit, setEdit] = useState(type == "edit" ? true : false);
  const theme = useTheme();
  const [selectedImage, setSelectedImage] = useState<any>();
  const [isGetData, setIsGetData] = useState<boolean>(true);
  const [selectedImageBase64, setSelectedImageBase64] = useState<any>();
  const [image, setImage] = useState<any>();
  const alertMessage = useAppSelector(alertMessageSelector).alertMessageReducer;

  const handleChangePassword = () => {
    navigate("/change-password");
  };
  useEffect(() => {
    setValue("profile_picture", selectedImage);
  }, [selectedImage]);

  const Submit = (payload: any) => {
    payload.id = detail?.id;
    dispatch(updateUser(payload));
  };

  const updatePic = () => {
    let payload = {};
    payload = {
      id: detail?.id,
      profile_picture: {
        image: selectedImage,
        imagename: selectedImage.name,
      },
    };

    dispatch(updateUser(payload));
  };

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.errors) {
      if (alertMessage?.errors?.profile_picture?.length > 0) {
        toast.error(alertMessage?.errors?.nprofile_pictureame?.toString(), {
          toastId: alertMessage?.errors?.profile_picture?.toString(),
        });
        setError("profile_picture", {
          type: "server",
          message: alertMessage?.errors?.profile_picture?.toString(),
        });
      }

      if (alertMessage?.errors) {
        dispatch(setErrors(null));
      }
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      dispatch(getUserDetail(Number(userInfo?.id)));
      // setEdit(false);
      setSelectedImage("");
    }
  }, [alertMessage]);
  $(document).ready(function () {
    $("#OpenImgUpload").click(function () {
      $("#file").click();
    });
  });
  useEffect(() => {
    dispatch(getUserDetail(Number(userInfo?.id)));
  }, [userInfo]);

  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;
  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;
  const foregroundBgColor =
    useColor("interface_foreground_color") ||
    defaultThemeColor.interface_foreground_color;
  const foregroundTextColor =
    useColor("interface_foreground_text_color") ||
    defaultThemeColor.interface_foreground_text_color;
  const primaryButton =
    useColor("button_primary") || defaultThemeColor.button_primary;
  const primaryButtonText =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;

  return (
    <>
      {userInfo?.is_superadmin &&
      detail?.primary_role?.toLowerCase() === "admin" ? (
        <CompanyProfileManagement />
      ) : (
        <div
          className={`${Style.background}`}
          style={{ color: interfaceTextColor, background: interfaceBgColor }}
        >
          <div style={{ paddingBottom: "2.4em" }}>
            <Header
              page={t("Profile Management")}
              mainHead={t("Profile Management")}
            />
          </div>
          <div
            className={`${Style.background}`}
            style={{
              background: foregroundBgColor,
              color: foregroundTextColor,
            }}
          >
            <div
              style={{
                background: foregroundBgColor,
                color: foregroundTextColor,
                borderRadius: 10,
                // height: "100vh",
              }}
              className={"pb-3"}
            >
              <div
                className={`${Style.leftSpace2} ${Style.topSpace} ${Style.bottomSpace}`}
              >
                <div
                  className={`${Style.textHeader}`}
                  style={{ color: foregroundTextColor }}
                >
                  {t("Profile Details")}
                </div>
              </div>
              <div
                className={`d-flex justify-content-between align-items-center gap-5 ${Style.alignItemsInside}`}
              >
                <form onSubmit={handleSubmit(Submit)} autoComplete="off">
                  <ImageSelect
                    selectedImage={selectedImage}
                    imageFromBackend={detail?.profile_picture || ""}
                    BaseImage={BaseImage}
                    isLoading={isLoading}
                    setSelectedImage={setSelectedImage}
                    updatePicSubmit={updatePic}
                    minDimensions={true}
                    showButtons={true}
                    uploadButtonComponent={""}
                    maxFileSizeinBytes={5242880}
                  />
                </form>
                <div className="d-flex">
                  {!edit ? (
                    <button
                      className={`btn ${Style.loginBtn2}`}
                      style={{
                        color: primaryButtonText,
                        background: primaryButton,
                      }}
                      type="button"
                      onClick={() => {
                        setEdit(true);
                      }}
                    >
                      <span>
                        <span
                          className="text-white"
                          style={{ color: primaryButtonText }}
                        >
                          {t("Edit")}
                        </span>
                      </span>
                    </button>
                  ) : null}
                </div>
              </div>
              <div style={{ paddingBottom: "2em" }}></div>
              <hr />

              {detail?.primary_role?.toLowerCase() === "trainee" &&
                (edit ? (
                  <EditProfile setEdit={setEdit} detail={detail} />
                ) : (
                  <ViewProfile detail={detail} role={"trainee"} />
                ))}

              {detail?.primary_role?.toLowerCase() === "trainer" &&
                (edit ? (
                  <EditProfile setEdit={setEdit} detail={detail} />
                ) : (
                  <ViewProfile detail={detail} role={"trainer"} />
                ))}
              {!userInfo?.is_superadmin &&
                detail?.primary_role?.toLowerCase() === "admin" &&
                (edit ? (
                  <EditProfile setEdit={setEdit} detail={detail} />
                ) : (
                  <ViewProfile detail={detail} role={"admin"} />
                  //   <EditAdminProfile setEdit={setEdit} detail={detail} />
                  // ) : (
                  //   <ViewAdminProfile detail={detail} />
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MyProfile;
