import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import useCompanyLanguage from "../../../../hooks/language";
import useColor from "../../../../hooks/useColor";
import CheckedCheckMarkSVG from "../../../../icons/CheckedCheckMark";
import LectureChecklistSVG from "../../../../icons/LectureChecklistSVG";
import NotCheckedSVG from "../../../../icons/NotChecked";
import PdfSVG from "../../../../icons/PdfSVG";
import PlayListSVG from "../../../../icons/PlaylistSVG";
import { defaultThemeColor } from "../../../../utils/colors_values";
import Style from "./LectureBar.module.scss";

function LectureBar({
  index,
  number,
  header,
  type,
  activeBar,
  secondaryText,
  checked,
  video,
  row,
}: {
  index: any;
  number: any;
  header: any;
  type: any;
  activeBar: any;
  secondaryText?: any;
  checked: any;
  video?: any;
  row?: any;
}) {
  const [duration, setDuration] = useState<any>();
  const t = useTranslation();
  const lang = useCompanyLanguage();

  useEffect(() => {
    let video: any = document.getElementById("myVideo");
    video?.addEventListener(
      "loadeddata",
      function () {
        setDuration(format(video.duration));
      },
      false
    );
  }, []);

  const format = (seconds: any) => {
    if (isNaN(seconds)) {
      return "00:00";
    }
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, "0");

    if (hh) {
      return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
    } else {
      return `${mm}:${ss}`;
    }
  };

  const primaryButtonColor =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;
  const primaryButtonBg =
    useColor("button_primary") || defaultThemeColor.button_primary;
  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;
  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;
  const interfaceForegroundTextColor =
    useColor("interface_foreground_text_color") ||
    defaultThemeColor.interface_foreground_text_color;
  const interfaceForegroundColor =
    useColor("interface_foreground_color") ||
    defaultThemeColor.interface_foreground_color;

  return (
    <div className={Style.bar}>
      {type == "Video" && (
        <video
          id="myVideo"
          width="320"
          height="176"
          controls
          style={{ display: "none" }}
        >
          <source src={video} type="video/mp4" />
        </video>
      )}
      <div
        className={`${
          index == 1 && number == 1
            ? Style.onlyBar
            : index == 1
            ? Style.firstBar
            : index == number
            ? Style.lastBar
            : index != 1 && index != number
            ? Style.insideBar
            : ""
        } ${index == activeBar ? Style.active : ""}`}
        style={{
          background: interfaceForegroundColor,
          color: interfaceForegroundTextColor,
        }}
      >
        <div className="d-flex w-100">
          <Tooltip
            title={checked ? t("Finished") : t("Not finished")}
            placement="top"
          >
            <IconButton className={Style.iconChecked}>
              {checked ? (
                <CheckedCheckMarkSVG color={interfaceForegroundTextColor} />
              ) : (
                <NotCheckedSVG color={interfaceForegroundTextColor} />
              )}
            </IconButton>
          </Tooltip>
          <div className="w-100">
            <div className="d-flex flex-wrap w-100">
              <div
                className={`${Style.header} w-75`}
                style={{ color: interfaceForegroundTextColor }}
              >
                {index}. {header}{" "}
                <small style={{ fontWeight: "normal" }}>
                  <i>{row?.is_compulsory ? t("(Compulsory)") : ""}</i>
                </small>
              </div>

              <div className="float-end w-25 text-end px-2">
                {type == "Video" ? duration : null}
              </div>
            </div>
            {type == "PDF" ? (
              <Tooltip title={t("Pdf")} placement="top">
                <IconButton className={Style.icon}>
                  <PdfSVG color={interfaceForegroundTextColor} />
                </IconButton>
              </Tooltip>
            ) : type == "Video" ? (
              <Tooltip title={t("Video")} placement="top">
                <IconButton className={Style.icon}>
                  <PlayListSVG color={interfaceForegroundTextColor} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={t("Other")} placement="top">
                <IconButton className={Style.icon}>
                  <LectureChecklistSVG color={interfaceForegroundTextColor} />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LectureBar;
