function PipVideoSVG(props: { color: string }) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.6665 4C3.57344 4 2.6665 4.90694 2.6665 6V18C2.6665 19.0931 3.57344 20 4.6665 20H20.6665C21.7596 20 22.6665 19.0931 22.6665 18V6C22.6665 4.90694 21.7596 4 20.6665 4H4.6665ZM4.6665 6H20.6665V18H4.6665V6ZM11.6665 11V16H18.6665V11H11.6665Z"
        fill={props.color ?? "#E7E7E7"}
      />
    </svg>
  );
}

export default PipVideoSVG;
