/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/rules-of-hooks */
import { Business } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import useCompanyLanguage from "../hooks/language";
import useColor from "../hooks/useColor";
import usePermission from "../hooks/usePermission";
import SuperAdminSVG from "../icons/AdminSVG";
import AssessmentsSVG from "../icons/AssessmentSVG";
import BrowseTrainingSVG from "../icons/BrowseTrainingSVG";
import CertificateSVG from "../icons/CertificateSVG";
import DepartmentSVG from "../icons/DepartmentSVG";
import FormSVG from "../icons/FormSVG";
import GroupSVG from "../icons/GroupSVG";
import HomeSvg from "../icons/HomeSVG";
import ImageSVG from "../icons/ImageSVG";
import ModuleSVG from "../icons/ModuleSvg";
import MyCertificatesSVG from "../icons/MyCertificatesSVG";
import MyTrainingsSVG from "../icons/MyTrainings";
import QuestionSVG from "../icons/QuestionSVG";
import TrainingSVG from "../icons/TrainingSVG";
import UserSVG from "../icons/UserSVG";
import { useBasePath } from "../lib/useBasePath";
import { licenseData } from "../store/reducers/licenseReducer";
import { RootState } from "../store/store";
import { defaultThemeColor } from "../utils/colors_values";
import "./Navigation.module.scss";
let menu = require("../json/menu.json");
const Navigation = () => {
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const [licensePolicy, setLicensePolicy] = useState(false);
  const [isPolicShow, setIsPolicyShow] = useState(false);
  const [licenseUploaded, setLicenseUploaded] = useState(false);
  const userInfo = useSelector((state: any) => state.authReducer.loginData);
  const dataLicense: licenseData = useSelector(
    (state: RootState) => state.licenseReducer.data
  );
  const viewPolicyPermission = usePermission("view_policy");
  const viewPolicyAssessmentPermission = usePermission("view_policyassessment");
  const viewCateegoryPermission = usePermission("view_category");
  const viewQuestionPermission = usePermission("view_question");
  const viewAssessmentPermission = usePermission("view_assessment");
  const viewModulePermission = usePermission("view_module");
  const viewTrainingPermission = usePermission("view_training");

  useEffect(() => {
    if (
      (userInfo?.is_superadmin || userInfo?.role == "Admin") &&
      (viewPolicyPermission || viewPolicyAssessmentPermission) &&
      licensePolicy &&
      licenseUploaded
    ) {
      setIsPolicyShow(true);
    } else {
      setIsPolicyShow(false);
    }
  }, [
    userInfo?.is_superadmin,
    userInfo?.role,
    licensePolicy,
    licenseUploaded,
    viewPolicyPermission,
    viewPolicyAssessmentPermission,
  ]);

  useEffect(() => {
    if (Object.keys(dataLicense || {}).length > 0) {
      setLicenseUploaded(true);
    } else {
      setLicenseUploaded(false);
    }
    if (
      dataLicense?.policy_management_expiry_date &&
      dataLicense?.policy_management_selected &&
      moment(dataLicense?.policy_management_expiry_date) >= moment()
    ) {
      setLicensePolicy(true);
    } else {
      setLicensePolicy(false);
    }
  }, [
    dataLicense?.policy_management_expiry_date,
    dataLicense?.policy_management_selected,
  ]);

  const basePath = useBasePath();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownAdministration, setDropdownAdministration] = useState(false);
  const [dropdownTrainingOpen, setDropdownTrainingOpen] = useState(false);
  const [dropdownSettingOpen, setDropdownSettingOpen] = useState(false);
  const [dropdownPolicyOpen, setDropdownPolicyOpen] = useState(false);
  const [dropdownCompanyPolicyOpen, setDropdownCompanyPolicyOpen] =
    useState(false);
  const [dropdownOpenEmail, setDropdownOpenEmail] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    setDropdownTrainingOpen(false);
    setDropdownSettingOpen(false);
    setDropdownPolicyOpen(false);
    setDropdownCompanyPolicyOpen(false);
    setDropdownOpenEmail(false);
    // setDropdownAdministration(false);
  };
  const toggleTrainingDropdown = () => {
    setDropdownTrainingOpen(!dropdownTrainingOpen);
    setDropdownOpen(false);
    setDropdownSettingOpen(false);
    setDropdownPolicyOpen(false);
    setDropdownCompanyPolicyOpen(false);
    setDropdownOpenEmail(false);
    setDropdownAdministration(false);
  };
  const toggleSettingDropdown = () => {
    setDropdownSettingOpen(!dropdownSettingOpen);
    setDropdownOpen(false);
    setDropdownTrainingOpen(false);
    setDropdownPolicyOpen(false);
    setDropdownCompanyPolicyOpen(false);
    setDropdownOpenEmail(false);
    setDropdownAdministration(false);
  };
  const togglePolicyDropdown = () => {
    setDropdownPolicyOpen(!dropdownPolicyOpen);
    setDropdownOpen(false);
    setDropdownTrainingOpen(false);
    setDropdownSettingOpen(false);
    setDropdownCompanyPolicyOpen(false);
    setDropdownAdministration(false);
  };
  const toggleCompanyPolicyDropdown = () => {
    setDropdownCompanyPolicyOpen(!dropdownCompanyPolicyOpen);
    setDropdownSettingOpen(false);
    setDropdownOpen(false);
    setDropdownTrainingOpen(false);
    setDropdownPolicyOpen(false);
    setDropdownOpenEmail(false);
    setDropdownAdministration(false);
  };
  const toggleAdministrationDropdown = () => {
    setDropdownAdministration(!dropdownAdministration);
    setDropdownSettingOpen(false);
    setDropdownOpen(false);
    setDropdownTrainingOpen(false);
    setDropdownPolicyOpen(false);
    setDropdownOpenEmail(false);
    setDropdownCompanyPolicyOpen(false);
  };

  useEffect(() => {
    if (
      basePath !== "admin-permission" &&
      basePath !== "admin-list" &&
      basePath !== "admin-permission/add-new" &&
      basePath !== "admin-detail" &&
      basePath !== "add-admin" &&
      basePath !== "admin-permission/detail"
    ) {
      setDropdownOpen(false);
    }
    if (
      ![
        "questions-library",
        "create-question",
        "update-question",
        "assessments/assessment-library",
        "assessments/add-assessment",
        "assessments/update-assessment",
        "module/module-listing",
        "module/add-module",
        "detail-module",
        "edit-module",
        "module-assessments/module-assessment-detail",
        "question-link",
        "trainings",
        "modules-members",
        "add-training",
        "update-training",
        "category",
        "category-detail",
        "detail-training",
        "assessments/assessment-detail",
        "question-detail",
        "assessments/assessment-link",
        "module-link",
      ].includes(basePath as string)
    ) {
      setDropdownTrainingOpen(false);
    }
    if (
      basePath !== "company-setting/company-profile" &&
      basePath !== "company-setting/company-customization" &&
      basePath !== "company-setting/license" &&
      basePath !== "company-setting/change-company-password" &&
      basePath !== "company-setting/integration" &&
      basePath !== "email-template-management" &&
      basePath !== "email-template-management/view" &&
      basePath !== "email-template-management/edit"
    ) {
      setDropdownSettingOpen(false);
    }
  }, [basePath]);

  const sidebarTextColor =
    useColor("sidebar_text_color") || defaultThemeColor.sidebar_text_color;
  const sidebarTextActiveColor =
    useColor("sidebar_text_active") || defaultThemeColor.sidebar_text_active;
  const sidebarBgActiveColor =
    useColor("sidebar_text_active_background") ||
    defaultThemeColor.sidebar_text_active_background;

    const canViewUser = usePermission("view_user");
    const canViewGroup = usePermission("view_customusergroup");
    const canViewFormFields = usePermission("view_dynamicformfields");
    const canViewDept = usePermission("view_department")

  return (
    <>
      {((userInfo?.is_superadmin &&
        userInfo?.role?.toLowerCase() === "admin") ||
        userInfo?.role?.toLowerCase() === "admin") && (
        <ul
          className={`sidebar-nav`}
          style={{
            paddingRight: lang === "ar" ? "15px" : "",
            marginTop: "0px",
          }}
        >
          {licenseUploaded && (
            <li>
              <NavLink
                style={({ isActive }) => ({
                  color: isActive
                    ? sidebarTextActiveColor ||
                      defaultThemeColor.sidebar_text_active
                    : sidebarTextColor || defaultThemeColor.sidebar_text_color,
                  paddingRight: lang === "ar" ? "15px" : "0px",
                })}
                to="/"
              >
                {({ isActive }) => (
                  <div className="d-flex gap-3">
                    <span className="icon black-icon pl-3">
                      <HomeSvg
                        color={
                          isActive
                            ? sidebarTextActiveColor ||
                              defaultThemeColor.sidebar_text_active
                            : sidebarTextColor ||
                              defaultThemeColor.sidebar_text_color
                        }
                      />
                    </span>
                    <span>{t("Dashboard")}</span>
                  </div>
                )}
              </NavLink>
            </li>
          )}

          {userInfo?.is_superadmin && licenseUploaded && (
             <li>
             <NavLink
               to="#"
               onClick={toggleAdministrationDropdown}
               style={({ isActive }) => ({
                 color:
                   sidebarTextColor || defaultThemeColor.sidebar_text_active,
                 borderRight: 0,
                 paddingRight: lang === "ar" ? "15px" : "0px",
               })}
             >
               {({ isActive }) => (
                 <div className="d-flex gap-3">
                   <span className="icon black-icon">
                     <SuperAdminSVG
                       fill={
                         sidebarTextColor ||
                         defaultThemeColor.sidebar_text_color
                       }
                     />
                   </span>
                   <span>{t("Administration")}</span>
                   {dropdownAdministration ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                 </div>
               )}
             </NavLink>
             {dropdownAdministration && (
             <>
               {userInfo?.is_superadmin && licenseUploaded && (
            <li>
              <NavLink
                to="#"
                onClick={toggleDropdown}
                style={({ isActive }) => ({
                  color:
                    sidebarTextColor || defaultThemeColor.sidebar_text_active,
                  borderRight: 0,
                  paddingRight: lang === "ar" ? "15px" : "0px",
                  paddingLeft: lang === "ar" ? "0":"24px",
                })}
              >
                {({ isActive }) => (
                  <div className="d-flex gap-3">
                    {/* <span className="icon black-icon">
                      <SuperAdminSVG
                        fill={
                          sidebarTextColor ||
                          defaultThemeColor.sidebar_text_color
                        }
                      />
                    </span> */}
                    <span>{t("Admin Management")}</span>
                    {dropdownOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                  </div>
                )}
              </NavLink>
              {dropdownOpen && (
                <ul className="dropdown-items">
                  <li>
                    <NavLink
                      className={`${
                        menu?.adminListManagement.includes(basePath)
                          ? "active"
                          : ""
                      }`}
                      style={({ isActive }) => ({
                        color: isActive
                          ? sidebarTextActiveColor ||
                            defaultThemeColor.sidebar_text_active
                          : sidebarTextColor ||
                            defaultThemeColor.sidebar_text_color,

                        background: isActive
                          ? sidebarBgActiveColor ||
                            defaultThemeColor.sidebar_text_active_background
                          : "",
                      })}
                      to="/admin-list"
                    >
                      {t("Admin List")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={`${
                        menu?.adminPermissionManagement.includes(basePath)
                          ? "active"
                          : ""
                      }`}
                      to="/admin-permission"
                      style={({ isActive }) => ({
                        color: isActive
                          ? sidebarTextActiveColor ||
                            defaultThemeColor.sidebar_text_active
                          : sidebarTextColor ||
                            defaultThemeColor.sidebar_text_color,

                        background: isActive
                          ? sidebarBgActiveColor ||
                            defaultThemeColor.sidebar_text_active_background
                          : "",
                      })}
                    >
                      {t("Admin Permissions")}
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
          )}
               {canViewUser && licenseUploaded && (
            <li>
              <NavLink
                className={`${
                  menu?.userManagement.includes(basePath) ? "active" : ""
                }`}
                to="/user-management"
                style={({ isActive }) => ({
                  color: isActive
                    ? sidebarTextActiveColor ||
                      defaultThemeColor.sidebar_text_active
                    : sidebarTextColor || defaultThemeColor.sidebar_text_color,

                  paddingRight: lang === "ar" ? "15px" : "0px",
                  paddingLeft: lang === "ar" ? "0":"24px",
                })}
              >
                {({ isActive }) => (
                  <div className="d-flex gap-3">
                    {/* <span className="icon black-icon">
                      <UserSVG
                        color={
                          isActive
                            ? sidebarTextActiveColor ||
                              defaultThemeColor.sidebar_text_active
                            : sidebarTextColor ||
                              defaultThemeColor.sidebar_text_color
                        }
                      />
                    </span> */}
                    <span>{t("Tenants")}</span>
                  </div>
                )}
              </NavLink>
            </li>
           )}
              {canViewGroup && licenseUploaded && (
            <li>
              <NavLink
                className={`${
                  menu?.groupManagement.includes(basePath) ? "active" : ""
                }`}
                to="/group-management"
                style={({ isActive }) => ({
                  color: isActive
                    ? sidebarTextActiveColor ||
                      defaultThemeColor.sidebar_text_active
                    : sidebarTextColor || defaultThemeColor.sidebar_text_color,

                  paddingRight: lang === "ar" ? "15px" : "0px",
                  paddingLeft: lang === "ar" ? "0":"24px",
                })}
              >
                {({ isActive }) => (
                  <div className="d-flex gap-3">
                    {/* <span className="icon black-icon">
                      <GroupSVG
                        color={
                          isActive
                            ? sidebarTextActiveColor ||
                              defaultThemeColor.sidebar_text_active
                            : sidebarTextColor ||
                              defaultThemeColor.sidebar_text_color
                        }
                      />
                    </span> */}
                    <span>{t("Group Management")}</span>
                  </div>
                )}
              </NavLink>
            </li>
          )}
             {canViewFormFields && licenseUploaded && (
            <li>
              <NavLink
                className={`${
                  menu?.userRegistration.includes(basePath) ? "active" : ""
                }`}
                to="/user-registration"
                style={({ isActive }) => ({
                  color: isActive
                    ? sidebarTextActiveColor ||
                      defaultThemeColor.sidebar_text_active
                    : sidebarTextColor || defaultThemeColor.sidebar_text_color,
                  paddingRight: lang === "ar" ? "15px" : "0px",
                  paddingLeft: lang === "ar" ? "0":"24px",
                })}
              >
                {({ isActive }) => (
                  <div className="d-flex gap-3">
                    {/* <span className="icon black-icon">
                      <FormSVG
                        color={
                          isActive
                            ? sidebarTextActiveColor ||
                              defaultThemeColor.sidebar_text_active
                            : sidebarTextColor ||
                              defaultThemeColor.sidebar_text_color
                        }
                      />
                    </span> */}
                    <span>{t("Forms Management")}</span>
                  </div>
                )}
              </NavLink>
            </li>
          )}
             {canViewDept && licenseUploaded && (
            <li>
              <NavLink
                className={`${
                  menu?.departmentManagement.includes(basePath) ? "active" : ""
                }`}
                to="/department-management?page=1&perpage=10"
                style={({ isActive }) => ({
                  color: isActive
                    ? sidebarTextActiveColor ||
                      defaultThemeColor.sidebar_text_active
                    : sidebarTextColor || defaultThemeColor.sidebar_text_color,
                  paddingRight: lang === "ar" ? "15px" : "0px",
                  paddingLeft: lang === "ar" ? "0":"24px",
                })}
              >
                {({ isActive }) => (
                  <div className="d-flex gap-3">
                    {/* <span className="icon black-icon">
                      <DepartmentSVG
                        color={
                          isActive
                            ? sidebarTextActiveColor ||
                              defaultThemeColor.sidebar_text_active
                            : sidebarTextColor ||
                              defaultThemeColor.sidebar_text_color
                        }
                      />
                    </span> */}

                    <span>{t("Department Management")}</span>
                  </div>
                )}
              </NavLink>
            </li>
          )}
          </>
             )}
           </li>
          )}

          {/* {userInfo?.is_superadmin && licenseUploaded && (
            <li>
              <NavLink
                to="#"
                onClick={toggleDropdown}
                style={({ isActive }) => ({
                  color:
                    sidebarTextColor || defaultThemeColor.sidebar_text_active,
                  borderRight: 0,
                  paddingRight: lang === "ar" ? "15px" : "0px",
                })}
              >
                {({ isActive }) => (
                  <div className="d-flex gap-3">
                    <span className="icon black-icon">
                      <SuperAdminSVG
                        fill={
                          sidebarTextColor ||
                          defaultThemeColor.sidebar_text_color
                        }
                      />
                    </span>
                    <span>{t("Admin Management")}</span>
                    {dropdownOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                  </div>
                )}
              </NavLink>
              {dropdownOpen && (
                <ul className="dropdown-items">
                  <li>
                    <NavLink
                      className={`${
                        menu?.adminListManagement.includes(basePath)
                          ? "active"
                          : ""
                      }`}
                      style={({ isActive }) => ({
                        color: isActive
                          ? sidebarTextActiveColor ||
                            defaultThemeColor.sidebar_text_active
                          : sidebarTextColor ||
                            defaultThemeColor.sidebar_text_color,

                        background: isActive
                          ? sidebarBgActiveColor ||
                            defaultThemeColor.sidebar_text_active_background
                          : "",
                      })}
                      to="/admin-list"
                    >
                      {t("Admin List")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={`${
                        menu?.adminPermissionManagement.includes(basePath)
                          ? "active"
                          : ""
                      }`}
                      to="/admin-permission"
                      style={({ isActive }) => ({
                        color: isActive
                          ? sidebarTextActiveColor ||
                            defaultThemeColor.sidebar_text_active
                          : sidebarTextColor ||
                            defaultThemeColor.sidebar_text_color,

                        background: isActive
                          ? sidebarBgActiveColor ||
                            defaultThemeColor.sidebar_text_active_background
                          : "",
                      })}
                    >
                      {t("Admin Permissions")}
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
          )} */}

          {/* {usePermission("view_user") && licenseUploaded && (
            <li>
              <NavLink
                className={`${
                  menu?.userManagement.includes(basePath) ? "active" : ""
                }`}
                to="/user-management"
                style={({ isActive }) => ({
                  color: isActive
                    ? sidebarTextActiveColor ||
                      defaultThemeColor.sidebar_text_active
                    : sidebarTextColor || defaultThemeColor.sidebar_text_color,

                  paddingRight: lang === "ar" ? "15px" : "0px",
                })}
              >
                {({ isActive }) => (
                  <div className="d-flex gap-3">
                    <span className="icon black-icon">
                      <UserSVG
                        color={
                          isActive
                            ? sidebarTextActiveColor ||
                              defaultThemeColor.sidebar_text_active
                            : sidebarTextColor ||
                              defaultThemeColor.sidebar_text_color
                        }
                      />
                    </span>
                    <span>{t("User Management")}</span>
                  </div>
                )}
              </NavLink>
            </li>
          )} */}

          {/* {usePermission("view_customusergroup") && licenseUploaded && (
            <li>
              <NavLink
                className={`${
                  menu?.groupManagement.includes(basePath) ? "active" : ""
                }`}
                to="/group-management"
                style={({ isActive }) => ({
                  color: isActive
                    ? sidebarTextActiveColor ||
                      defaultThemeColor.sidebar_text_active
                    : sidebarTextColor || defaultThemeColor.sidebar_text_color,

                  paddingRight: lang === "ar" ? "15px" : "0px",
                })}
              >
                {({ isActive }) => (
                  <div className="d-flex gap-3">
                    <span className="icon black-icon">
                      <GroupSVG
                        color={
                          isActive
                            ? sidebarTextActiveColor ||
                              defaultThemeColor.sidebar_text_active
                            : sidebarTextColor ||
                              defaultThemeColor.sidebar_text_color
                        }
                      />
                    </span>
                    <span>{t("Group Management")}</span>
                  </div>
                )}
              </NavLink>
            </li>
          )} */}
          {/* {usePermission("view_dynamicformfields") && licenseUploaded && (
            <li>
              <NavLink
                className={`${
                  menu?.userRegistration.includes(basePath) ? "active" : ""
                }`}
                to="/user-registration"
                style={({ isActive }) => ({
                  color: isActive
                    ? sidebarTextActiveColor ||
                      defaultThemeColor.sidebar_text_active
                    : sidebarTextColor || defaultThemeColor.sidebar_text_color,
                  paddingRight: lang === "ar" ? "15px" : "0px",
                })}
              >
                {({ isActive }) => (
                  <div className="d-flex gap-3">
                    <span className="icon black-icon">
                      <FormSVG
                        color={
                          isActive
                            ? sidebarTextActiveColor ||
                              defaultThemeColor.sidebar_text_active
                            : sidebarTextColor ||
                              defaultThemeColor.sidebar_text_color
                        }
                      />
                    </span>
                    <span>{t("Forms Management")}</span>
                  </div>
                )}
              </NavLink>
            </li>
          )} */}
          {usePermission("view_certificate") && licenseUploaded && (
            <li>
              <NavLink
                className={`${
                  menu?.certificateManagement.includes(basePath) ? "active" : ""
                }`}
                to="/certificates-management?page=1&perpage=10"
                style={({ isActive }) => ({
                  color: isActive
                    ? sidebarTextActiveColor ||
                      defaultThemeColor.sidebar_text_active
                    : sidebarTextColor || defaultThemeColor.sidebar_text_color,
                  paddingRight: lang === "ar" ? "15px" : "0px",
                })}
              >
                {({ isActive }) => (
                  <div className="d-flex gap-3">
                    <span className="icon black-icon">
                      <CertificateSVG
                        color={
                          isActive
                            ? sidebarTextActiveColor ||
                              defaultThemeColor.sidebar_text_active
                            : sidebarTextColor ||
                              defaultThemeColor.sidebar_text_color
                        }
                      />
                    </span>
                    <span>{t("Certificates Management")}</span>
                  </div>
                )}
              </NavLink>
            </li>
          )}
          {usePermission("view_image") && licenseUploaded && (
            <li>
              <NavLink
                className={`${
                  menu?.imageLibrary.includes(basePath) ? "active" : ""
                }`}
                to="/image-library"
                style={({ isActive }) => ({
                  color: isActive
                    ? sidebarTextActiveColor ||
                      defaultThemeColor.sidebar_text_active
                    : sidebarTextColor || defaultThemeColor.sidebar_text_color,
                  paddingRight: lang === "ar" ? "15px" : "0px",
                })}
              >
                {({ isActive }) => (
                  <div className="d-flex gap-3">
                    <span className="icon black-icon">
                      <ImageSVG
                        color={
                          isActive
                            ? sidebarTextActiveColor ||
                              defaultThemeColor.sidebar_text_active
                            : sidebarTextColor ||
                              defaultThemeColor.sidebar_text_color
                        }
                      />
                    </span>

                    <span>{t("Image Library")}</span>
                  </div>
                )}
              </NavLink>
            </li>
          )}

       
          {userInfo?.is_superadmin && (
            <li>
              <NavLink
                to="#"
                onClick={toggleSettingDropdown}
                style={({ isActive }) => ({
                  color:
                    sidebarTextColor || defaultThemeColor.sidebar_text_color,
                  paddingRight: lang === "ar" ? "15px" : "0px",
                  borderRight: 0,
                })}
              >
                {({ isActive }) => (
                  <div className="d-flex gap-3">
                    <span className="icon black-icon">
                      <SuperAdminSVG
                        fill={
                          sidebarTextColor ||
                          defaultThemeColor.sidebar_text_color
                        }
                      />
                    </span>
                    <span>{t("Company Settings")}</span>
                    {dropdownSettingOpen ? (
                      <ArrowDropDownIcon />
                    ) : (
                      <ArrowRightIcon />
                    )}
                  </div>
                )}
              </NavLink>
              {dropdownSettingOpen && (
                <ul className="dropdown-company-items">
                  <li>
                    <NavLink
                      to="company-setting/company-profile"
                      style={({ isActive }) => ({
                        color: isActive
                          ? sidebarTextActiveColor ||
                            defaultThemeColor.sidebar_text_active
                          : sidebarTextColor ||
                            defaultThemeColor.sidebar_text_color,
                        background: isActive
                          ? sidebarBgActiveColor ||
                            defaultThemeColor.sidebar_text_active_background
                          : "",
                      })}
                    >
                      {t("Company Profile")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      style={({ isActive }) => ({
                        color: isActive
                          ? sidebarTextActiveColor ||
                            defaultThemeColor.sidebar_text_active
                          : sidebarTextColor ||
                            defaultThemeColor.sidebar_text_color,
                        background: isActive
                          ? sidebarBgActiveColor ||
                            defaultThemeColor.sidebar_text_active_background
                          : "",
                      })}
                      to="company-setting/change-company-password"
                    >
                      {t("Change Password")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      style={({ isActive }) => ({
                        color: isActive
                          ? sidebarTextActiveColor ||
                            defaultThemeColor.sidebar_text_active
                          : sidebarTextColor ||
                            defaultThemeColor.sidebar_text_color,

                        background: isActive
                          ? sidebarBgActiveColor ||
                            defaultThemeColor.sidebar_text_active_background
                          : "",
                      })}
                      to="company-setting/company-customization"
                    >
                      {t("Company Customization")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      style={({ isActive }) => ({
                        color: isActive
                          ? sidebarTextActiveColor ||
                            defaultThemeColor.sidebar_text_active
                          : sidebarTextColor ||
                            defaultThemeColor.sidebar_text_color,
                        background: isActive
                          ? sidebarBgActiveColor ||
                            defaultThemeColor.sidebar_text_active_background
                          : "",
                      })}
                      to="company-setting/license"
                    >
                      {t("License")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      style={({ isActive }) => ({
                        color: isActive
                          ? sidebarTextActiveColor ||
                            defaultThemeColor.sidebar_text_active
                          : sidebarTextColor ||
                            defaultThemeColor.sidebar_text_color,

                        background: isActive
                          ? sidebarBgActiveColor ||
                            defaultThemeColor.sidebar_text_active_background
                          : "",
                      })}
                      to="company-setting/integration"
                    >
                      {t("Integration")}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      style={({ isActive }) => ({
                        color: isActive
                          ? sidebarTextActiveColor ||
                            defaultThemeColor.sidebar_text_active
                          : sidebarTextColor ||
                            defaultThemeColor.sidebar_text_color,

                        background: isActive
                          ? sidebarBgActiveColor ||
                            defaultThemeColor.sidebar_text_active_background
                          : "",
                      })}
                      to="email-template-management"
                    >
                      {t("Email Templates")}
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
          )}
          {(viewCateegoryPermission ||
            viewQuestionPermission ||
            viewAssessmentPermission ||
            viewModulePermission ||
            viewTrainingPermission) &&
            licenseUploaded && (
              <li>
                <NavLink
                  to="#"
                  onClick={toggleTrainingDropdown}
                  style={({ isActive }) => ({
                    color:
                      sidebarTextColor || defaultThemeColor.sidebar_text_active,
                    paddingRight: lang === "ar" ? "15px" : "0px",
                    borderRight: 0,
                  })}
                >
                  {({ isActive }) => (
                    <div className="d-flex gap-3">
                      <span className="icon black-icon">
                        <TrainingSVG
                          color={
                            sidebarTextColor ||
                            defaultThemeColor.sidebar_text_color
                          }
                        />
                      </span>
                      <span>{t("Training Management")}</span>
                      {dropdownTrainingOpen ? (
                        <ArrowDropDownIcon />
                      ) : (
                        <ArrowRightIcon />
                      )}
                    </div>
                  )}
                </NavLink>
                {dropdownTrainingOpen && (
                  <ul className="dropdown-items">
                    {viewCateegoryPermission && (
                      <li>
                        <NavLink
                          className={`${
                            menu?.category.includes(basePath) ? "active" : ""
                          }`}
                          to="/category?page=1&perpage=10"
                          style={({ isActive }) => ({
                            color: isActive
                              ? sidebarTextActiveColor ||
                                defaultThemeColor.sidebar_text_active
                              : sidebarTextColor ||
                                defaultThemeColor.sidebar_text_color,

                            background: isActive
                              ? sidebarBgActiveColor ||
                                defaultThemeColor.sidebar_text_active_background
                              : "",
                          })}
                        >
                          {t("Category")}
                        </NavLink>
                      </li>
                    )}
                    {viewQuestionPermission && (
                      <li>
                        <NavLink
                          className={`${
                            menu?.QuestionsLibrary.includes(basePath)
                              ? "active"
                              : ""
                          }`}
                          to="/questions-library?page=1&perpage=10"
                          style={({ isActive }) => ({
                            color: isActive
                              ? sidebarTextActiveColor ||
                                defaultThemeColor.sidebar_text_active
                              : sidebarTextColor ||
                                defaultThemeColor.sidebar_text_color,

                            background: isActive
                              ? sidebarBgActiveColor ||
                                defaultThemeColor.sidebar_text_active_background
                              : "",
                          })}
                        >
                          {t("Questions Library")}
                        </NavLink>
                      </li>
                    )}
                    {viewAssessmentPermission && (
                      <li>
                        <NavLink
                          className={`${
                            menu?.assessments.includes(basePath) ? "active" : ""
                          }`}
                          to="/assessments/assessment-library?page=1&perpage=10"
                          style={({ isActive }) => ({
                            color: isActive
                              ? sidebarTextActiveColor ||
                                defaultThemeColor.sidebar_text_active
                              : sidebarTextColor ||
                                defaultThemeColor.sidebar_text_color,

                            background: isActive
                              ? sidebarBgActiveColor ||
                                defaultThemeColor.sidebar_text_active_background
                              : "",
                          })}
                        >
                          {t("Assessments")}
                        </NavLink>
                      </li>
                    )}
                    {viewModulePermission && (
                      <li>
                        <NavLink
                          className={`${
                            menu?.modules.includes(basePath) ? "active" : ""
                          }`}
                          to="/module/module-listing?page=1&perpage=10"
                          style={({ isActive }) => ({
                            color: isActive
                              ? sidebarTextActiveColor ||
                                defaultThemeColor.sidebar_text_active
                              : sidebarTextColor ||
                                defaultThemeColor.sidebar_text_color,

                            background: isActive
                              ? sidebarBgActiveColor ||
                                defaultThemeColor.sidebar_text_active_background
                              : "",
                          })}
                        >
                          {t("Modules")}
                        </NavLink>
                      </li>
                    )}
                    {viewTrainingPermission && (
                      <li>
                        <NavLink
                          className={`${
                            menu?.trainings.includes(basePath) ? "active" : ""
                          }`}
                          to="/trainings"
                          style={({ isActive }) => ({
                            color: isActive
                              ? sidebarTextActiveColor ||
                                defaultThemeColor.sidebar_text_active
                              : sidebarTextColor ||
                                defaultThemeColor.sidebar_text_color,

                            background: isActive
                              ? sidebarBgActiveColor ||
                                defaultThemeColor.sidebar_text_active_background
                              : "",
                          })}
                        >
                          {t("Training")}
                        </NavLink>
                      </li>
                    )}
                  </ul>
                )}
              </li>
            )}
          {isPolicShow && (
            <li>
              <NavLink
                to="#"
                onClick={togglePolicyDropdown}
                style={({ isActive }) => ({
                  color:
                    sidebarTextColor || defaultThemeColor.sidebar_text_color,
                  paddingRight: lang === "ar" ? "15px" : "0px",
                  borderRight: 0,
                })}
              >
                {({ isActive }) => (
                  <div className="d-flex gap-3">
                    <span className="icon black-icon">
                      <SuperAdminSVG
                        fill={
                          sidebarTextColor ||
                          defaultThemeColor.sidebar_text_color
                        }
                      />
                    </span>
                    <span>{t("Policy Management")}</span>
                    {dropdownPolicyOpen ? (
                      <ArrowDropDownIcon />
                    ) : (
                      <ArrowRightIcon />
                    )}
                  </div>
                )}
              </NavLink>
              {dropdownPolicyOpen && (
                <ul className="dropdown-company-items">
                  {viewPolicyPermission && (
                    <li>
                      <NavLink
                        className={`${
                          menu?.policyManagement.includes(basePath)
                            ? "active"
                            : ""
                        }`}
                        to="/policy-listing"
                        style={({ isActive }) => ({
                          color: isActive
                            ? sidebarTextActiveColor ||
                              defaultThemeColor.sidebar_text_active
                            : sidebarTextColor ||
                              defaultThemeColor.sidebar_text_color,

                          background: isActive
                            ? sidebarBgActiveColor ||
                              defaultThemeColor.sidebar_text_active_background
                            : "",
                        })}
                      >
                        {t("Policy List")}
                      </NavLink>
                    </li>
                  )}
                  {viewPolicyAssessmentPermission && (
                    <li>
                      <NavLink
                        className={`${
                          menu?.policyAssessment.includes(basePath)
                            ? "active"
                            : ""
                        }`}
                        to="/policy-assessment"
                        style={({ isActive }) => ({
                          color: isActive
                            ? sidebarTextActiveColor ||
                              defaultThemeColor.sidebar_text_active
                            : sidebarTextColor ||
                              defaultThemeColor.sidebar_text_color,

                          background: isActive
                            ? sidebarBgActiveColor ||
                              defaultThemeColor.sidebar_text_active_background
                            : "",
                        })}
                      >
                        {t("Policy Assessment")}
                      </NavLink>
                    </li>
                  )}
                </ul>
              )}
            </li>
          )}
          {!userInfo?.is_superadmin && licenseUploaded && (
            <li>
              <NavLink
                to="#"
                onClick={toggleCompanyPolicyDropdown}
                style={({ isActive }) => ({
                  color:
                    sidebarTextColor || defaultThemeColor.sidebar_text_color,
                  paddingRight: lang === "ar" ? "15px" : "0px",
                  borderRight: 0,
                })}
              >
                {({ isActive }) => (
                  <div className="d-flex gap-3">
                    <span className="icon black-icon">
                      <Business
                        fill={
                          sidebarTextColor ||
                          defaultThemeColor.sidebar_text_color
                        }
                      />
                    </span>
                    <span>{t("Company Policies")}</span>
                    {dropdownCompanyPolicyOpen ? (
                      <ArrowDropDownIcon />
                    ) : (
                      <ArrowRightIcon />
                    )}
                  </div>
                )}
              </NavLink>
              {dropdownCompanyPolicyOpen && (
                <ul className="dropdown-company-items">
                  <li>
                    <NavLink
                      to="company-policy-list"
                      style={({ isActive }) => ({
                        color: isActive
                          ? sidebarTextActiveColor ||
                            defaultThemeColor.sidebar_text_active
                          : sidebarTextColor ||
                            defaultThemeColor.sidebar_text_color,

                        background: isActive
                          ? sidebarBgActiveColor ||
                            defaultThemeColor.sidebar_text_active_background
                          : "none",
                      })}
                    >
                      {t("Assigned Policies")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      style={({ isActive }) => ({
                        color: isActive
                          ? sidebarTextActiveColor ||
                            defaultThemeColor.sidebar_text_active
                          : sidebarTextColor ||
                            defaultThemeColor.sidebar_text_color,

                        background: isActive
                          ? sidebarBgActiveColor ||
                            defaultThemeColor.sidebar_text_active_background
                          : "none",
                      })}
                      to="company-policy-assessment-list"
                    >
                      {t("Assigned Policy Assessments")}
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
          )}
        </ul>
      )}
      {userInfo?.role?.toLowerCase() === "trainer" && licenseUploaded && (
        <ul className={`sidebar-nav`}>
          <li>
            <NavLink
              style={({ isActive }) => ({
                color: isActive
                  ? sidebarTextActiveColor ||
                    defaultThemeColor.sidebar_text_active
                  : sidebarTextColor || defaultThemeColor.sidebar_text_color,
                paddingRight: lang === "ar" ? "15px" : "0px",
              })}
              to="/"
            >
              {({ isActive }) => (
                <div className="d-flex gap-3">
                  <span className="icon black-icon">
                    <HomeSvg
                      color={
                        isActive
                          ? sidebarTextActiveColor ||
                            defaultThemeColor.sidebar_text_active
                          : sidebarTextColor ||
                            defaultThemeColor.sidebar_text_color
                      }
                    />
                  </span>

                  <span>{t("Dashboard")}</span>
                </div>
              )}
            </NavLink>
          </li>

          <li>
            <NavLink
              className={`${
                menu?.trainings.includes(basePath) ? "active" : ""
              }`}
              to="/trainings"
              style={({ isActive }) => ({
                color: isActive
                  ? sidebarTextActiveColor ||
                    defaultThemeColor.sidebar_text_active
                  : sidebarTextColor || defaultThemeColor.sidebar_text_color,
                paddingRight: lang === "ar" ? "15px" : "0px",
              })}
            >
              {({ isActive }) => (
                <div className="d-flex gap-3">
                  <span className="icon black-icon">
                    <TrainingSVG
                      color={
                        isActive
                          ? sidebarTextActiveColor ||
                            defaultThemeColor.sidebar_text_active
                          : sidebarTextColor ||
                            defaultThemeColor.sidebar_text_color
                      }
                    />
                  </span>
                  <span>{t("Training")}</span>
                </div>
              )}
            </NavLink>
          </li>
          <li>
            <NavLink
              className={`${menu?.modules.includes(basePath) ? "active" : ""}`}
              to="/module/module-listing?page=1&perpage=10"
              style={({ isActive }) => ({
                color: isActive
                  ? sidebarTextActiveColor ||
                    defaultThemeColor.sidebar_text_active
                  : sidebarTextColor || defaultThemeColor.sidebar_text_color,
                paddingRight: lang === "ar" ? "15px" : "0px",
              })}
            >
              {({ isActive }) => (
                <div className="d-flex gap-3">
                  <span className="icon black-icon">
                    <ModuleSVG
                      color={
                        isActive
                          ? sidebarTextActiveColor ||
                            defaultThemeColor.sidebar_text_active
                          : sidebarTextColor ||
                            defaultThemeColor.sidebar_text_color
                      }
                    />
                  </span>

                  <span>{t("Modules")}</span>
                </div>
              )}
            </NavLink>
          </li>
          <li>
            <NavLink
              className={`${
                menu?.assessments.includes(basePath) ? "active" : ""
              }`}
              to="/assessments/assessment-library?page=1&perpage=10"
              style={({ isActive }) => ({
                color: isActive
                  ? sidebarTextActiveColor ||
                    defaultThemeColor.sidebar_text_active
                  : sidebarTextColor || defaultThemeColor.sidebar_text_color,
                paddingRight: lang === "ar" ? "15px" : "0px",
              })}
            >
              {({ isActive }) => (
                <div className="d-flex gap-3">
                  <span className="icon black-icon">
                    <AssessmentsSVG
                      color={
                        isActive
                          ? sidebarTextActiveColor ||
                            defaultThemeColor.sidebar_text_active
                          : sidebarTextColor ||
                            defaultThemeColor.sidebar_text_color
                      }
                    />
                  </span>
                  <span>{t("Assessments")}</span>
                </div>
              )}
            </NavLink>
          </li>
          <li>
            <NavLink
              className={`${
                menu?.QuestionsLibrary.includes(basePath) ? "active" : ""
              }`}
              to="/questions-library?page=1&perpage=10"
              style={({ isActive }) => ({
                color: isActive
                  ? sidebarTextActiveColor ||
                    defaultThemeColor.sidebar_text_active
                  : sidebarTextColor || defaultThemeColor.sidebar_text_color,
                paddingRight: lang === "ar" ? "15px" : "0px",
              })}
            >
              {({ isActive }) => (
                <div className="d-flex gap-3">
                  <span className="icon black-icon">
                    <QuestionSVG
                      color={
                        isActive
                          ? sidebarTextActiveColor ||
                            defaultThemeColor.sidebar_text_active
                          : sidebarTextColor ||
                            defaultThemeColor.sidebar_text_color
                      }
                    />
                  </span>

                  <span>{t("Questions Library")}</span>
                </div>
              )}
            </NavLink>
          </li>

          {/* )} */}
          <li>
            <NavLink
              to="#"
              onClick={toggleCompanyPolicyDropdown}
              style={({ isActive }) => ({
                color: sidebarTextColor || defaultThemeColor.sidebar_text_color,
                paddingRight: lang === "ar" ? "15px" : "0px",
                borderRight: 0,
              })}
            >
              {({ isActive }) => (
                <div className="d-flex gap-3">
                  <span className="icon black-icon">
                    <Business
                      fill={
                        sidebarTextColor || defaultThemeColor.sidebar_text_color
                      }
                    />
                  </span>
                  <span>{t("Company Policies")}</span>
                  {dropdownCompanyPolicyOpen ? (
                    <ArrowDropDownIcon />
                  ) : (
                    <ArrowRightIcon />
                  )}
                </div>
              )}
            </NavLink>
            {dropdownCompanyPolicyOpen && (
              <ul className="dropdown-company-items">
                <li>
                  <NavLink
                    to="company-policy-list"
                    style={({ isActive }) => ({
                      color: isActive
                        ? sidebarTextActiveColor ||
                          defaultThemeColor.sidebar_text_active
                        : sidebarTextColor ||
                          defaultThemeColor.sidebar_text_color,

                      background: isActive
                        ? sidebarBgActiveColor ||
                          defaultThemeColor.sidebar_text_active_background
                        : "none",
                    })}
                  >
                    {t("Assigned Policies")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    style={({ isActive }) => ({
                      color: isActive
                        ? sidebarTextActiveColor ||
                          defaultThemeColor.sidebar_text_active
                        : sidebarTextColor ||
                          defaultThemeColor.sidebar_text_color,

                      background: isActive
                        ? sidebarBgActiveColor ||
                          defaultThemeColor.sidebar_text_active_background
                        : "none",
                    })}
                    to="company-policy-assessment-list"
                  >
                    {t("Assigned Policy Assessments")}
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
        </ul>
      )}
      {userInfo?.role?.toLowerCase() === "trainee" && licenseUploaded && (
        <ul className={`sidebar-nav`}>
          <li>
            <NavLink
              style={({ isActive }) => ({
                color: isActive
                  ? sidebarTextActiveColor ||
                    defaultThemeColor.sidebar_text_active
                  : sidebarTextColor || defaultThemeColor.sidebar_text_color,
                paddingRight: lang === "ar" ? "15px" : "0px",
              })}
              to="/"
            >
              {({ isActive }) => (
                <div className="d-flex gap-3">
                  <span className="icon black-icon">
                    <HomeSvg
                      color={
                        isActive
                          ? sidebarTextActiveColor ||
                            defaultThemeColor.sidebar_text_active
                          : sidebarTextColor ||
                            defaultThemeColor.sidebar_text_color
                      }
                    />
                  </span>
                  <span>{t("Dashboard")}</span>
                </div>
              )}
            </NavLink>
          </li>

          <li>
            <NavLink
              className={`${
                menu?.trainings.includes(basePath) ? "active" : ""
              }`}
              to="/browse-trainings"
              style={({ isActive }) => ({
                color: isActive
                  ? sidebarTextActiveColor ||
                    defaultThemeColor.sidebar_text_active
                  : sidebarTextColor || defaultThemeColor.sidebar_text_color,
                paddingRight: lang === "ar" ? "15px" : "0px",
              })}
            >
              {({ isActive }) => (
                <div className="d-flex gap-3">
                  <span className="icon black-icon">
                    <BrowseTrainingSVG
                      color={
                        isActive
                          ? sidebarTextActiveColor ||
                            defaultThemeColor.sidebar_text_active
                          : sidebarTextColor ||
                            defaultThemeColor.sidebar_text_color
                      }
                    />
                  </span>
                  <span>{t("Browse Trainings")}</span>
                </div>
              )}
            </NavLink>
          </li>
          <li>
            <NavLink
              className={`${
                menu?.trainings.includes(basePath) ? "active" : ""
              }`}
              to="/my-training"
              style={({ isActive }) => ({
                color: isActive
                  ? sidebarTextActiveColor ||
                    defaultThemeColor.sidebar_text_active
                  : sidebarTextColor || defaultThemeColor.sidebar_text_color,
                paddingRight: lang === "ar" ? "15px" : "0px",
              })}
            >
              {({ isActive }) => (
                <div className="d-flex gap-3">
                  <span className="icon black-icon">
                    <MyTrainingsSVG
                      color={
                        isActive
                          ? sidebarTextActiveColor ||
                            defaultThemeColor.sidebar_text_active
                          : sidebarTextColor ||
                            defaultThemeColor.sidebar_text_color
                      }
                    />
                  </span>
                  <span>{t("My Trainings")}</span>
                </div>
              )}
            </NavLink>
          </li>
          <li>
            <NavLink
              className={`${
                menu?.trainings.includes(basePath) ? "active" : ""
              }`}
              to="/my-certificates"
              style={({ isActive }) => ({
                color: isActive
                  ? sidebarTextActiveColor ||
                    defaultThemeColor.sidebar_text_active
                  : sidebarTextColor || defaultThemeColor.sidebar_text_color,
                paddingRight: lang === "ar" ? "15px" : "0px",
              })}
            >
              {({ isActive }) => (
                <div className="d-flex gap-3">
                  <span className="icon black-icon">
                    <MyCertificatesSVG
                      color={
                        isActive
                          ? sidebarTextActiveColor ||
                            defaultThemeColor.sidebar_text_active
                          : sidebarTextColor ||
                            defaultThemeColor.sidebar_text_color
                      }
                    />
                  </span>
                  <span>{t("My Certificates")}</span>
                </div>
              )}
            </NavLink>
          </li>
          {userInfo?.role?.toLowerCase() ===
            userInfo?.primary_role?.toLowerCase() && (
            <li>
              <NavLink
                to="#"
                onClick={toggleCompanyPolicyDropdown}
                style={({ isActive }) => ({
                  color:
                    sidebarTextColor || defaultThemeColor.sidebar_text_color,
                  paddingRight: lang === "ar" ? "15px" : "0px",
                  borderRight: 0,
                })}
              >
                {({ isActive }) => (
                  <div className="d-flex gap-3">
                    <span className="icon black-icon">
                      <Business
                        fill={
                          sidebarTextColor ||
                          defaultThemeColor.sidebar_text_color
                        }
                      />
                    </span>
                    <span>{t("Company Policies")}</span>
                    {dropdownCompanyPolicyOpen ? (
                      <ArrowDropDownIcon />
                    ) : (
                      <ArrowRightIcon />
                    )}
                  </div>
                )}
              </NavLink>
              {dropdownCompanyPolicyOpen && (
                <ul className="dropdown-company-items">
                  <li>
                    <NavLink
                      to="company-policy-list"
                      style={({ isActive }) => ({
                        color: isActive
                          ? sidebarTextActiveColor ||
                            defaultThemeColor.sidebar_text_active
                          : sidebarTextColor ||
                            defaultThemeColor.sidebar_text_color,

                        background: isActive
                          ? sidebarBgActiveColor ||
                            defaultThemeColor.sidebar_text_active_background
                          : "none",
                      })}
                    >
                      {t("Assigned Policies")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      style={({ isActive }) => ({
                        color: isActive
                          ? sidebarTextActiveColor ||
                            defaultThemeColor.sidebar_text_active
                          : sidebarTextColor ||
                            defaultThemeColor.sidebar_text_color,
                        background: isActive
                          ? sidebarBgActiveColor ||
                            defaultThemeColor.sidebar_text_active_background
                          : "none",
                      })}
                      to="company-policy-assessment-list"
                    >
                      {t("Assigned Policy Assessments")}
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
          )}
        </ul>
      )}
    </>
  );
};

export default Navigation;
