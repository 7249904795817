// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PolicyListing_categoryStart__cgnz4 {
  width: 100%;
  overflow: auto !important;
}

.PolicyListing_header__BCqBD {
  color: #212b36;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
}

.PolicyListing_headerCheck__rifME {
  padding: 0;
}

.PolicyListing_statusSuccess__5-QUt {
  border-radius: 25px;
  background: #00c366;
  display: inline-flex;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  text-transform: capitalize;
}

.PolicyListing_statusDraft__CcebJ {
  border-radius: 25px;
  background: yellow;
  display: inline-flex;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: black;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  min-width: 6em;
  text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/pages/PolicyManagement/PolicyListing.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,yBAAA;AACF;;AAEA;EACE,cAAA;EAEA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;AAAF;;AAGA;EACE,UAAA;AAAF;;AAEA;EACE,mBAAA;EACA,mBAAA;EACA,oBAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,WAAA;EAEA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,0BAAA;AAAF;;AAGA;EACE,mBAAA;EACA,kBAAA;EACA,oBAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,YAAA;EAEA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;EACA,0BAAA;AADF","sourcesContent":[".categoryStart {\n  width: 100%;\n  overflow: auto !important;\n}\n\n.header {\n  color: #212b36;\n  // font-family: dmSans;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 22px; /* 137.5% */\n}\n\n.headerCheck {\n  padding: 0;\n}\n.statusSuccess {\n  border-radius: 25px;\n  background: #00c366;\n  display: inline-flex;\n  padding: 2px 10px;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n  color: #fff;\n  // font-family: Roboto;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 22px;\n  text-transform: capitalize;\n}\n\n.statusDraft {\n  border-radius: 25px;\n  background: yellow;\n  display: inline-flex;\n  padding: 2px 10px;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n  color: black;\n  // font-family: Roboto;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 22px;\n  min-width: 6em;\n  text-transform: capitalize;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"categoryStart": `PolicyListing_categoryStart__cgnz4`,
	"header": `PolicyListing_header__BCqBD`,
	"headerCheck": `PolicyListing_headerCheck__rifME`,
	"statusSuccess": `PolicyListing_statusSuccess__5-QUt`,
	"statusDraft": `PolicyListing_statusDraft__CcebJ`
};
export default ___CSS_LOADER_EXPORT___;
