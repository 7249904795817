import Cookies from "js-cookie";
import axios from "../lib/axios";
import axiosPrivate from "../lib/axiosPrivate";
import { setErr } from "../store/reducers/alertMessageReducer";
import {
  isForgotVerified,
  isReSentOtp,
  isVerified,
  setAuthFailed,
  setAuthLoginSuccess,
  setAuthSignUpData,
  setAuthSignUpFailed,
  setMicrosoftLoginUrl,
  setPermissions,
  setRedirect,
  setSuccess,
  signUpFields,
  updatedPassword,
} from "../store/reducers/authReducer";
import { setData } from "../store/reducers/licenseReducer";
import { setLoading } from "../store/reducers/loaderReducer";
import { AppThunk } from "../store/store";

export interface PostForgotPassword {
  email: string;
}

export interface PostNewPassword {
  password: string;
}
export interface PostLogin {
  email: string;
  password: string;
}
export interface PostSignUp {
  company_business_name?: string;
  first_name?: string;
  last_name?: string;
  phone_no?: string;
  email_address?: string;
  password?: string;
  confirm_password?: string;
}

export interface PostOtpVerify {
  otp?: string;
}

export const login =
  (payload: PostLogin): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axios
        .post("accounts/token/", {
          email: payload.email,
          password: payload.password,
        })
        .then((response) => {
          const data = response.data;

          if (
            response.status === 200 &&
            (data.access === null || !data.access) &&
            (data.refresh === null || !data.refresh) &&
            data?.user?.is_email_verified === false
          ) {
            const user = data?.user;
            user.type = "Email Verification";
            localStorage.setItem("signup_data", JSON.stringify(user));
            dispatch(setAuthSignUpData(user));
            dispatch(setAuthFailed("Please verify your email."));
            dispatch(setLoading(false));
          } else if (response.status === 200 && data.access && data.refresh) {
            // dispatch(getUserDataWithLogin(data?.access?.toString()));
            dispatch(setPermissions(data?.user?.permission_list));
            localStorage.setItem(
              "permissions_list",
              JSON.stringify(data?.user?.permission_list)
            );
            localStorage.setItem(
              "license_info",
              JSON.stringify(data?.user?.license_data)
            );
            dispatch(setData(data?.user?.license_data));
            localStorage.setItem("access_token", data.access);
            localStorage.setItem("redirect", data?.redirect);

            Cookies.set("refresh_token", data.refresh, { expires: 1 });
            localStorage.setItem("user_info", JSON.stringify(data?.user));
            // localStorage.setItem("secondary_role", data?.user?.role);
            localStorage.setItem(
              "permissions_list",
              JSON.stringify(data?.user?.permission_list)
            );

            dispatch(setAuthLoginSuccess(data));
            dispatch(setLoading(false));
            dispatch(setRedirect(data?.redirect));
            return;
          } else {
            dispatch(setAuthFailed("Something went wrong, Please try again"));
            dispatch(setLoading(false));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setAuthFailed(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setAuthFailed("Something went wrong, Please try again"));
    }
  };

export const signUp =
  (payload: PostSignUp): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axios
        .post("accounts/signup/", {
          first_name: payload.first_name,
          last_name: payload.last_name,
          email: payload.email_address,
          password: payload.password,
          password2: payload.confirm_password,
          personal_detail_data: {},
          sign_up_data: {
            company_business_name: payload.company_business_name,
            first_name: payload.first_name,
            last_name: payload.last_name,
            phone_no: payload.phone_no,
            email_address: payload.email_address,
            password: payload.password,
            confirm_password: payload.confirm_password,
          },
        })
        .then((response) => {
          const data = response.data;
          data.type = "Email Verification";
          localStorage.setItem("signup_data", JSON.stringify(data));
          dispatch(setLoading(false));
          dispatch(setAuthSignUpData(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setAuthFailed(error?.response?.data?.detail));
          } else {
            dispatch(setAuthSignUpFailed(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setAuthFailed("Something went wrong, Please try again"));
    }
  };

export const otpVerify =
  (payload: PostOtpVerify, isForgotVerified: boolean | undefined): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const signupData = JSON.parse(localStorage.getItem("signup_data") || "");
      await axios
        .post("accounts/verify-otp/", {
          otp: payload?.otp,
          email: signupData?.email,
        })
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (isForgotVerified) {
            localStorage.setItem(
              "reset_password_token",
              JSON.stringify(data?.token)
            );
          }
          dispatch(isVerified(true));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setAuthFailed(error?.response?.data?.detail));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setAuthFailed("Something went wrong, Please try again"));
    }
  };

export const reSendOtp = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const signupData = JSON.parse(localStorage.getItem("signup_data") || "");
    await axios
      .post("accounts/resend-otp/", {
        email: signupData?.email,
        type: signupData?.type,
      })
      .then((response) => {
        dispatch(setLoading(false));
        dispatch(isReSentOtp(true));
      })
      .catch((error) => {
        dispatch(setLoading(false));
        if (error?.response?.data?.detail) {
          dispatch(setAuthFailed(error?.response?.data?.detail));
        }
      });
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setAuthFailed("Something went wrong, Please try again"));
  }
};

export const forgotPassowrd =
  (payload: PostForgotPassword): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axios
        .post("accounts/reset-password/", {
          email: payload?.email,
        })
        .then((response) => {
          dispatch(setLoading(false));
          const data = response.data;

          if (
            response.status === 200 &&
            data?.user?.is_email_verified === false
          ) {
            const user = data?.user;
            user.type = "Email Verification";
            localStorage.setItem("signup_data", JSON.stringify(user));
            dispatch(setAuthSignUpData(user));
            dispatch(setAuthFailed("Please verify your email."));
          } else {
            localStorage.setItem(
              "signup_data",
              JSON.stringify({ email: payload?.email, type: "Password Reset" })
            );
            dispatch(setAuthSignUpData({ email: payload?.email }));
            dispatch(isForgotVerified(true));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setAuthFailed(error?.response?.data?.detail));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setAuthFailed("Something went wrong, Please try again"));
    }
  };

export const newPassowrd =
  (payload: PostNewPassword): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    const signupData = JSON.parse(localStorage.getItem("signup_data") || "");
    const resetPasswordToken = JSON.parse(
      localStorage.getItem("reset_password_token") || ""
    );
    try {
      await axios
        .post("accounts/change-password/", {
          password: payload?.password,
          email: signupData?.email,
          token: resetPasswordToken,
        })
        .then((response) => {
          dispatch(isVerified(false));
          dispatch(isForgotVerified(false));
          dispatch(updatedPassword(true));
          dispatch(setLoading(false));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setAuthFailed(error?.response?.data?.detail));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setAuthFailed("Something went wrong, Please try again"));
    }
  };

export const getSignUpFields = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await axios
      .get("accounts/registration-forms/?is_personal=0")
      .then((response) => {
        dispatch(setLoading(false));
        const data = response.data;

        dispatch(signUpFields(data));
      })
      .catch((error) => {
        dispatch(setLoading(false));

        if (error?.response?.data?.detail) {
          dispatch(setAuthFailed(error?.response?.data?.detail));
        }
      });
  } catch (error: any) {
    dispatch(setLoading(false));

    dispatch(setAuthFailed("Something went wrong, Please try again"));
  }
};

export const updateRegistrationForm =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .patch("accounts/registration-forms-bulk-update/", payload)
        .then((response) => {
          dispatch(setLoading(false));
          const data = response.data;
          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));

          if (error?.response?.data?.detail) {
            dispatch(setAuthFailed(error?.response?.data?.detail));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));

      dispatch(setAuthFailed("Something went wrong, Please try again"));
    }
  };

export const getMicrosoftLoginURL = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await axios
      .get("accounts/microsoft/login/")
      .then((response) => {
        dispatch(setLoading(false));
        const data = response.data;
        console.log("microsoft", data);
        if (data?.authorization_url) {
          dispatch(setMicrosoftLoginUrl(data?.authorization_url));
        } else {
          dispatch(setErr(data?.detail));
        }
      })
      .catch((error) => {
        console.log("microsoft error", error?.response);
        dispatch(setLoading(false));
        // if (error?.response?.data?.detail) {
        //   dispatch(setAuthFailed(error?.response?.data?.detail));
        // }
      });
  } catch (error: any) {
    dispatch(setLoading(false));

    dispatch(setAuthFailed("Something went wrong, Please try again"));
  }
};
export const getMicrosoftAuthenticate =
  (code: string): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axios
        .get(`accounts/microsoft/callback/?code=${code}`)
        .then((response) => {
          dispatch(setLoading(false));
          const data = response.data;
          console.log("microsoft callback", data);
          if (
            response.status === 200 &&
            (data.access === null || !data.access) &&
            (data.refresh === null || !data.refresh) &&
            data?.user?.is_email_verified === false
          ) {
            const user = data?.user;
            user.type = "Email Verification";
            localStorage.setItem("signup_data", JSON.stringify(user));
            dispatch(setAuthSignUpData(user));
            dispatch(setAuthFailed("Please verify your email."));
            dispatch(setLoading(false));
          } else if (response.status === 200 && data.access && data.refresh) {
            // dispatch(getUserDataWithLogin(data?.access?.toString()));
            dispatch(setPermissions(data?.user?.permission_list));
            localStorage.setItem(
              "permissions_list",
              JSON.stringify(data?.user?.permission_list)
            );
            localStorage.setItem(
              "license_info",
              JSON.stringify(data?.user?.license_data)
            );
            dispatch(setData(data?.user?.license_data));
            localStorage.setItem("access_token", data.access);
            localStorage.setItem("redirect", data?.redirect);

            Cookies.set("refresh_token", data.refresh, { expires: 1 });
            localStorage.setItem("user_info", JSON.stringify(data?.user));
            // localStorage.setItem("secondary_role", data?.user?.role);
            localStorage.setItem(
              "permissions_list",
              JSON.stringify(data?.user?.permission_list)
            );

            dispatch(setAuthLoginSuccess(data));
            dispatch(setLoading(false));
            dispatch(setRedirect(data?.redirect));
            return;
          } else {
            dispatch(setAuthFailed("Something went wrong, Please try again"));
            dispatch(setLoading(false));
          }
        })
        .catch((error) => {
          console.log("microsoft error callback", error?.response);
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setAuthFailed(error?.response?.data?.detail));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));

      dispatch(setAuthFailed("Something went wrong, Please try again"));
    }
  };
