import axiosPrivate from "../lib/axiosPrivate";
import { setLoading } from "../store/reducers/loaderReducer";
import { AppThunk } from "../store/store";

import {
  setErr,
  setErrors,
  setSuccess,
} from "../store/reducers/alertMessageReducer";
import {
  setAssessmentModulesData,
  setData,
  setModuleData,
  setVideoData,
  setWholeAssessmentsData,
  setWholeData,
  setWholeDetail,
  setWholeDetailforErrors,
} from "../store/reducers/moduleReducer";
import { setProgress } from "../store/reducers/progressReducer";

export interface GetModule {
  search?: string;
  category?: string;
  status?: string;
  type?: string;
  page: number;
  page_size: number;
}

export interface GetAssessmentsForModule {
  search: string;
  category: string;
  retaking_assessment: string;
  individual_assessment: string;
  assessment_status?: string;
  page: number;
  page_size: number;
}

export const getModules =
  (payload: GetModule): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setData({}));
    try {
      await axiosPrivate
        .get(
          "assessments/modules/?" +
            `${payload.page >= 0 ? `page=${payload.page}` : ""}` +
            `${
              payload.page_size >= 0 ? `&page_size=${payload.page_size}` : ""
            }` +
            `${payload.search ? `&search=${payload.search}` : ""}` +
            `${payload.category ? `&category=${payload.category}` : ""}` +
            `${payload.status ? `&status=${payload.status}` : ""}` +
            `${payload.type ? `&type=${payload.type}` : ""}`
        )
        .then((response) => {
          const data = response.data;

          dispatch(setLoading(false));
          dispatch(setData(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getModuleforsearch =
  (payload: GetModule): AppThunk =>
  async (dispatch) => {
    dispatch(setData({}));
    try {
      await axiosPrivate
        .get(
          "assessments/modules/?" +
            `${payload.page >= 0 ? `page=${payload.page}` : ""}` +
            `${
              payload.page_size >= 0 ? `&page_size=${payload.page_size}` : ""
            }` +
            `${payload.search ? `&search=${payload.search}` : ""}` +
            `${payload.category ? `&category=${payload.category}` : ""}` +
            `${payload.status ? `&status=${payload.status}` : ""}` +
            `${payload.type ? `&type=${payload.type}` : ""}`
        )
        .then((response) => {
          const data = response.data;

          dispatch(setData(data));
        })
        .catch((error) => {
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getWholeModules =
  (payload: any): AppThunk =>
  async (dispatch) => {
    if (payload?.for == "export") {
      dispatch(setWholeData({}));
    } else {
      dispatch(setWholeDetailforErrors({}));
    }
    try {
      await axiosPrivate
        .get(
          "assessments/modules/?" +
            `${payload.page >= 0 ? `page=${payload.page}` : ""}` +
            `${
              payload.page_size >= 0 ? `&page_size=${payload.page_size}` : ""
            }` +
            `${payload.search ? `&search=${payload.search}` : ""}` +
            `${payload.category ? `&category=${payload.category}` : ""}` +
            `${payload.status ? `&status=${payload.status}` : ""}` +
            `${payload.type ? `&type=${payload.type}` : ""}`
        )
        .then((response) => {
          const data = response.data;

          // dispatch(setLoading(false));
          if (payload?.for == "export") {
            dispatch(setWholeData(data));
          } else {
            dispatch(setWholeDetailforErrors(data));
          }
        })
        .catch((error) => {
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getAssessmentsForModules =
  (payload: GetAssessmentsForModule): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setData({}));
    try {
      await axiosPrivate
        .get(
          "assessments/assessment/?" +
            `${payload.page >= 0 ? `page=${payload.page}` : ""}` +
            `${
              payload.page_size >= 0 ? `&page_size=${payload.page_size}` : ""
            }` +
            `${payload.search ? `&search=${payload.search}` : ""}` +
            `${payload.category ? `&category=${payload.category}` : ""}` +
            `${
              payload.retaking_assessment
                ? `&retaking_assessment=${payload.retaking_assessment}`
                : ""
            }` +
            `${
              payload.individual_assessment
                ? `&individual_assessment=${payload.individual_assessment}`
                : ""
            }` +
            `${
              payload.assessment_status
                ? `&assessment_status=${payload.assessment_status}`
                : ""
            }`
        )
        .then((response) => {
          const data = response.data;

          dispatch(setLoading(false));
          dispatch(setAssessmentModulesData(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getWholeAssessmentsForModules =
  (payload: GetAssessmentsForModule): AppThunk =>
  async (dispatch) => {
    dispatch(setWholeAssessmentsData({}));
    try {
      await axiosPrivate
        .get(
          "assessments/assessment/?" +
            `${payload.page >= 0 ? `page=${payload.page}` : ""}` +
            `${
              payload.page_size >= 0 ? `&page_size=${payload.page_size}` : ""
            }` +
            `${payload.search ? `&search=${payload.search}` : ""}` +
            `${payload.category ? `&category=${payload.category}` : ""}` +
            `${
              payload.retaking_assessment
                ? `&retaking_assessment=${payload.retaking_assessment}`
                : ""
            }` +
            `${
              payload.individual_assessment
                ? `&individual_assessment=${payload.individual_assessment}`
                : ""
            }` +
            `${
              payload.assessment_status
                ? `&assessment_status=${payload.assessment_status}`
                : ""
            }`
        )
        .then((response) => {
          const data = response.data;

          dispatch(setWholeAssessmentsData(data));
        })
        .catch((error) => {
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const updateModuleData =
  (
    id: number,
    payload: {
      name: string;
      description: string;
      status: string;
      type: any;
      file: any;
      file_name: any;
      category: any;
      level: any;
      assessment: any;
    }
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));

    let formData = new FormData();
    if (payload.name) {
      formData.append("name", payload.name);
    }
    if (payload.description) {
      formData.append("description", payload.description);
    }
    if (payload.status) {
      formData.append("status", payload.status);
    }
    if (payload.type) {
      formData.append("type", payload.type);
    }
    if (payload?.file) {
      formData.append("file", payload?.file?.image, payload?.file?.imagename);
    }
    if (payload?.file_name) {
      formData.append("file_name", payload?.file_name);
    }
    if (payload?.category) {
      formData.append("category", payload?.category);
    }
    if (payload.level) {
      formData.append("level", payload.level);
    }
    if (payload?.assessment) {
      formData.append("assessment", payload?.assessment);
    }
    try {
      await axiosPrivate
        .patch(`assessments/modules/${id}/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=myboundary",
            enctype: "multipart/form-data; boundary=myboundary",
          },
          onUploadProgress: (progressEvent: any) => {
            dispatch(
              setProgress({
                total: progressEvent?.total,
                loaded: progressEvent?.loaded,
              })
            );
          },
        })
        .then((response) => {
          const data = response.data;

          dispatch(setLoading(false));

          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Module(s) updated successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else if (error?.response?.data) {
            dispatch(setErrors(error?.response?.data));
          } else {
            dispatch(setErr("Something went wrong, Please try again"));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
    dispatch(
      setProgress({
        total: 0,
        loaded: 0,
      })
    );
  };

export const addModules =
  (payload: {
    name: string;
    description: string;
    status: string;
    type: any;
    file: any;
    file_name: any;
    category: any;
    level: any;
    assessment: any;
  }): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    let formData = new FormData();
    formData.append("name", payload.name);
    formData.append("description", payload.description);
    formData.append("status", payload.status);
    formData.append("type", payload.type);
    if (payload?.file) {
      formData.append("file", payload?.file?.image, payload?.file?.imagename);
    }
    if (payload?.file_name) {
      formData.append("file_name", payload.file_name);
    }
    formData.append("category", payload.category);
    formData.append("level", payload.level);
    if (payload?.assessment) {
      formData.append("assessment", payload?.assessment);
    }

    try {
      await axiosPrivate
        .post("assessments/modules/", formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=myboundary",
            enctype: "multipart/form-data; boundary=myboundary",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            crossDomain: true,
          },
          onUploadProgress: (progressEvent: any) => {
            dispatch(
              setProgress({
                total: progressEvent?.total,
                loaded: progressEvent?.loaded,
              })
            );
          },
        })
        .then((response) => {
          const data = response.data;

          dispatch(setLoading(false));
          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Module(s) saved successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response)
            if (error?.response?.data?.detail) {
              dispatch(setErr(error?.response?.data?.detail));
            } else {
              dispatch(setErrors(error?.response?.data));
            }
          else {
            dispatch(
              setErr(
                "We detected slow internet connection at your end. Please connect to other network and try again"
              )
            );
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
    dispatch(
      setProgress({
        total: 0,
        loaded: 0,
      })
    );
  };

export const bulkDeleteModules =
  (payload: any[]): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .delete(`assessments/modules/bulk_delete/`, {
          data: JSON.stringify({ module_ids: payload }),
        })
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Module(s) deleted successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const deleteModule =
  (id: Number): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .delete(`assessments/modules/${id}/`)
        .then((response) => {
          const data = response.data;

          dispatch(setLoading(false));
          if (response.status === 204 || response.status === 200) {
            dispatch(setSuccess("Module deleted successfully"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getModulesForView =
  (id: number): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setModuleData({}));
    try {
      await axiosPrivate
        .get(`assessments/modules/${id}/`)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (response.status === 200) {
            dispatch(setModuleData(data));
          } else {
            dispatch(setErr("Something went wrong, Please try again"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };
export const getDetailDataAssociation =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setData({}));
    try {
      await axiosPrivate
        .get(
          `assessments/modules/${payload?.id}/association/?page=${payload?.page}&page_size=${payload?.page_size}&search=${payload?.search}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setData(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getWholeDetailDataAssociation =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setWholeDetail({}));
    try {
      await axiosPrivate
        .get(
          `assessments/modules/${payload?.id}/association/?page=${payload?.page}&page_size=${payload?.page_size}&search=${payload?.search}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setWholeDetail(data));
        })
        .catch((error) => {
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const handlePostVideo =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    let formData = new FormData();
    formData.append("video_path", "media/admin/module/this.mp4");

    try {
      await axiosPrivate
        .post("assessments/modules/stream_video_2/", formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=myboundary",
            enctype: "multipart/form-data; boundary=myboundary",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            crossDomain: true,
          },
          onUploadProgress: (progressEvent: any) => {
            dispatch(
              setProgress({
                total: progressEvent?.total,
                loaded: progressEvent?.loaded,
              })
            );
          },
        })
        .then((response) => {
          const data = response.data;
          dispatch(setVideoData(data));
          dispatch(setLoading(false));
          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Module(s) saved successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response)
            if (error?.response?.data?.detail) {
              dispatch(setErr(error?.response?.data?.detail));
            } else {
              dispatch(setErrors(error?.response?.data));
            }
          else {
            dispatch(
              setErr(
                "We detected slow internet connection at your end. Please connect to other network and try again"
              )
            );
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
    dispatch(
      setProgress({
        total: 0,
        loaded: 0,
      })
    );
  };
