import { Controller } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import "react-phone-input-2/lib/style.css";
import { AsyncPaginate } from "react-select-async-paginate";
import useColor from "../../hooks/useColor";
import axiosPrivate from "../../lib/axiosPrivate";
import { defaultThemeColor, light_text_color, primary_color } from "../../utils/colors_values";

interface QuestionForm {
  control?: any;
  parentName?: any;
  loadOptions?: any;
}

function PolicyLoadComponent({ control, parentName }: QuestionForm) {
  const conveetNestedToFlatten = (accounts: any, showChild: boolean) => {
    var a: any = [];
    for (var i = 0; i < accounts.length; i++) {
      if (showChild) {
        if (accounts[i].children) {
          a = a.concat(conveetNestedToFlatten(accounts[i].children, showChild));
        }
      }
      a.push(accounts[i]);
    }
    return a;
  };

  const loadOptions = async (searchQuery: any, loadedOptions: any, { page }: any) => {
    const response = await axiosPrivate.get(
      `policies/policies/?page=${page}&page_size=100&search=${searchQuery}`
    );
    const data = response.data;

    const showChild = searchQuery ? false : true;
    const dataFlatten = conveetNestedToFlatten(data?.results, showChild);

    const options = dataFlatten?.map((row: any) => {
      return {
        label: row.name || "N/A",
        value: row.id,
      };
    });

    const users = {
      options: options || [],
      hasMore: data?.next ? true : false,
      additional: {
        page: searchQuery ? 1 : page + 1,
      },
    };

    return users;
  };
  const t = useTranslation();

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  const interfaceBgColor =
    useColor("interface_background_color") || defaultThemeColor.interface_background_color;

  const primaryButton = useColor("button_primary_text") || defaultThemeColor.button_primary_text;
  const primaryButtonBg = useColor("button_primary") || defaultThemeColor.button_primary;

  return (
    <>
      <Controller
        control={control}
        name={parentName}
        render={({ field: { value, onChange, ref } }) => (
          <AsyncPaginate
            id={parentName}
            name={parentName}
            selectRef={ref}
            loadOptions={loadOptions}
            onChange={onChange}
            value={value || ""}
            getOptionValue={(option: any) => {
              return option.value || "";
            }}
            getOptionLabel={(option: any) => option.label}
            className="add-member-select"
            additional={{
              page: 1,
            }}
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
                color: interfaceTextColor,
                background: interfaceBgColor,
              }),
              control: (baseStyles: any, state: any) => ({
                ...baseStyles,
                padding: "0.6em",
                overflow: "auto",
                color: interfaceTextColor,
                background: interfaceBgColor,
              }),
              option: (provided: any, state: any) => ({
                ...provided,
                backgroundColor: state.isFocused ? primary_color : "",
                color: state.isFocused ? light_text_color : "",
              }),
              multiValue: (baseStyles) => ({
                ...baseStyles,
                backgroundColor: primaryButtonBg,
              }),
              multiValueLabel: (baseStyles) => ({
                ...baseStyles,
                color: primaryButton,
              }),
              multiValueRemove: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: primaryButtonBg,
                color: primaryButton,
                ":hover": {
                  backgroundColor: primaryButtonBg,
                  color: primaryButton,
                },
              }),
            }}
            isClearable
            isSearchable={true}
            placeholder={t("Select Policy")}
            debounceTimeout={500}
          />
        )}
      />
    </>
  );
}

export default PolicyLoadComponent;
