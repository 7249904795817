import Style from "./ViewProfile.module.scss";
import useColor from "../../../hooks/useColor";
import { defaultThemeColor } from "../../../utils/colors_values";
import { useTranslation } from "react-multi-lang";
import useCompanyLanguage from "../../../hooks/language";

interface Params {
  detail: any;
}

const ViewProfile = ({ detail }: Params) => {
  const t = useTranslation();
  const lang = useCompanyLanguage();
  return (
    <div
      style={{
        minHeight: "13em",
        background:
          useColor("interface_foreground_color") || defaultThemeColor.interface_foreground_color,
        color:
          useColor("interface_foreground_text_color") ||
          defaultThemeColor.interface_foreground_text_color,
        borderRadius: "10px",
        direction: lang === "ar" ? "rtl" : "ltr",
      }}
    >
      <div
        className={`${Style.topSpace} col-lg-12`}
        style={{
          borderBottom: "1px solid rgba(0,0,0,0.1)",
          paddingBottom: "1em",
          display: lang === "ar" ? "flex" : "unset",
        }}
      >
        <div
          className={`${Style.label} col-lg-6 px-5`}
          style={{
            color:
              useColor("interface_foreground_text_color") ||
              defaultThemeColor.interface_foreground_text_color,
          }}
        >
          {t("Company Name")}
        </div>

        <div className={`${Style.fieldValue} col-lg-6 px-5`} style={{ wordBreak: "break-word" }}>
          {detail?.company_name}
        </div>
      </div>
      <div
        className={`${Style.topSpace} col-lg-12`}
        style={{
          borderBottom: "1px solid rgba(0,0,0,0.1)",
          paddingBottom: "1em",
          display: lang === "ar" ? "flex" : "unset",
        }}
      >
        <div
          className={`${Style.label} col-lg-6 px-5`}
          style={{
            color:
              useColor("interface_foreground_text_color") ||
              defaultThemeColor.interface_foreground_text_color,
          }}
        >
          {t("Company Email")}
        </div>

        <div className={`${Style.fieldValue} col-lg-6 px-5`}>{detail?.company_email}</div>
      </div>
    </div>
  );
};
export default ViewProfile;
