import axiosPrivate from "../lib/axiosPrivate";
import { setLoading } from "../store/reducers/loaderReducer";
import { AppThunk } from "../store/store";

import {
  setErr,
  setErrors,
  setSuccess,
} from "../store/reducers/alertMessageReducer";
import { setData } from "../store/reducers/companyIntegrationReducer";

export interface GetAllData {
  search: string;
  page: number;
  page_size: number;
}

export const getAll = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setData([]));
  try {
    await axiosPrivate
      .get(`commom/company-inetgration/`)
      .then((response) => {
        const data = response.data;

        dispatch(setLoading(false));
        dispatch(setData(data));
      })
      .catch((error) => {
        dispatch(setLoading(false));
        dispatch(setErr(error.response.data.detail));
      });
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setErr("Something went wrong, Please try again"));
  }
};
export const addData =
  (payload: { type: string; detail: any }): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await axiosPrivate
        .post(`commom/company-inetgration/`, payload)
        .then((response) => {
          const data = response.data;
          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Integration data are saved successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };
