import React from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SaveIcon from "@mui/icons-material/Save";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { defaultThemeColor } from "../utils/colors_values";
import Style from "./ImageSelect.module.scss";
import useColor from "../hooks/useColor";
import { formatBytes } from "../utils/formatBytesToDelimeters";
import { useTranslation } from "react-multi-lang";

interface ImageSelectProps {
  selectedImage: File | null;
  imageFromBackend: string | null;
  BaseImage: string;
  isLoading: boolean;
  setSelectedImage: (image: File | null) => void;
  updatePicSubmit: () => void;
  minDimensions: boolean;
  showButtons: boolean;
  uploadButtonComponent: React.ReactNode;
  maxFileSizeinBytes?: any;
}

function ImageSelect({
  selectedImage,
  imageFromBackend,
  BaseImage,
  isLoading,
  setSelectedImage,
  updatePicSubmit,
  minDimensions,
  showButtons,
  uploadButtonComponent,
  maxFileSizeinBytes,
}: ImageSelectProps) {
  const t = useTranslation();
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return;

    const selectedFile = e.target.files[0];
    const validImageTypes = ["image/jpg", "image/jpeg", "image/png", "image/svg+xml"];

    if (!validImageTypes.includes(selectedFile.type)) {
      toast.error(t("Please select a valid image file"));
      return;
    } else if (selectedFile.size > maxFileSizeinBytes) {
      toast.error(
        String(
          t("Please select an image with a size less than ") +
            formatBytes(maxFileSizeinBytes).toLocaleUpperCase()
        ),
        {
          toastId: String(
            t("Please select an image with a size less than ") +
              formatBytes(maxFileSizeinBytes).toLocaleUpperCase()
          ),
        }
      );
      return;
    }

    setSelectedImage(selectedFile);
  };

  const handleUpdateImage = () => {
    if (selectedImage) {
      updatePicSubmit();
    }
  };

  const buttonPrimary = useColor("button_primary") || defaultThemeColor.button_primary;
  const buttonPrimaryText =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;

  return (
    <div className={`${Style.imageMain} d-flex justify-content-center`}>
      <div
        className={`position-relative ${Style.cover}`}
        style={{
          minHeight: minDimensions ? "142px" : "",
          minWidth: minDimensions ? "142px" : "",
        }}
      >
        <div className={Style.imageCover}>
          <img
            id="blah"
            alt=""
            src={
              selectedImage
                ? URL.createObjectURL(selectedImage)
                : imageFromBackend
                ? imageFromBackend
                : BaseImage
            }
            style={{
              borderRadius: "50%",
              maxWidth: "142px",
              maxHeight: "142px",
              width: "100%",
              height: "100%",
              minHeight: minDimensions ? "142px" : "",
              minWidth: minDimensions ? "142px" : "",
              objectFit: selectedImage || imageFromBackend ? "cover" : "none",
            }}
          />

          {isLoading ? (
            <div className={Style.editCover3} style={{ left: "4.2em" }}>
              <CircularProgress size="2rem" color="secondary" />
            </div>
          ) : null}

          <div
            className={showButtons ? Style.editCover : ""}
            style={{
              backgroundColor: useColor("button_primary") || defaultThemeColor.button_primary,
              color: useColor("button_primary_text") || defaultThemeColor.button_primary_text,
            }}
          >
            {showButtons ? (
              <ModeEditIcon
                id="OpenImgUpload"
                style={{
                  fontSize: 19,
                  color: buttonPrimaryText,
                  cursor: "pointer",
                }}
              />
            ) : null}

            {uploadButtonComponent}

            <input
              id="file"
              type="file"
              accept="image/jpg, image/jpeg, image/png, image/svg+xml"
              onChange={handleImageChange}
              style={{
                position: "absolute",
                top: 0,
                width: 20,
                opacity: 0,
                cursor: "pointer",
              }}
            />
          </div>
          {selectedImage && !isLoading && showButtons && (
            <button
              className={Style.editCover2}
              style={{
                right: "-40px",
                backgroundColor: buttonPrimary,
                color: buttonPrimaryText,
              }}
              onClick={handleUpdateImage}
            >
              <SaveIcon
                id="saveImgUpload"
                style={{
                  fontSize: 19,
                  color: buttonPrimaryText,
                  backgroundColor: buttonPrimary,
                }}
              />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ImageSelect;
