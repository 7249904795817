import { useEffect } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import Header from "../../components/Header";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import { getAll } from "../../services/companyIntegrationService";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import Style from "../companyProfileManagement/profileManagement.module.scss";
import ActiveDirectory from "./integration/ActiveDirectory";
import ExchangeServer from "./integration/ExchangeServer";

function CompanyIntegration() {
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const isLoading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );

  useEffect(() => {
    dispatch(getAll());
  }, []);
  return (
    <div
      className={`${Style.background}`}
      style={{
        background:
          useColor("interface_background_color") ||
          defaultThemeColor.interface_background_color,
        color:
          useColor("interface_background_text_color") ||
          defaultThemeColor.interface_background_text_color,
      }}
    >
      <div style={{ paddingBottom: "1.6em" }}>
        <Header page={t("Integrations")} mainHead={t("Company Settings")} />
      </div>
      <ActiveDirectory />
      <div className="mt-4">
        <ExchangeServer />
      </div>
    </div>
  );
}

export default CompanyIntegration;
