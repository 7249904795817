export const primary_color = "#0089cf";
export const secondary_color = "#F0F8FC";
export const error_color = "red";
export const success_color = "";
export const button_bg_primary_color = "#F0F8FC";
export const light_text_color = "white";

export const defaultThemeColor: any = {
  sidebar_text_color: "#656565",
  sidebar_text_active: "#0089CF",
  sidebar_text_active_background: "#E8F7FF",
  sidebar_background_color: "#fff",
  interface_background_text_color: "#2b3674",
  interface_background_color: "#f6f9ff",
  interface_foreground_text_color: "#14276F",
  interface_foreground_color: "#fff",
  button_primary: "#0089cf",
  button_primary_text: "#fff",
  button_secondary: "#FFFFFF",
  button_secondary_text: "#2B3674",
};
