import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Chip,
  FormControl,
  MenuItem,
  OutlinedInput,
  Radio,
} from "@mui/material";
import Select from "@mui/material/Select";
import { Theme, useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DateRangePicker } from "rsuite";
import * as yup from "yup";
import CancelButton from "../../../components/CancelButton";
import CategoryLoadComponent from "../../../components/CategoryLoad/CategoryLoad";
import CertificateLoadComponent from "../../../components/CertificateLoad/CertificateLoad";
import ChooseFile from "../../../components/ChooseFile";
import Header from "../../../components/Header";
import SubmitButton from "../../../components/SubmitButton";
import useCompanyLanguage from "../../../hooks/language";
import useColor from "../../../hooks/useColor";
import {
  addNewTraining,
  getTraining,
  getWholeMembersListData3,
  updateTraining,
} from "../../../services/trainingsService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../../store/reducers/alertMessageReducer";
import { RootState, useAppDispatch } from "../../../store/store";
import {
  defaultThemeColor,
  light_text_color,
  primary_color,
} from "../../../utils/colors_values";
import { assessment_level_values } from "../../../utils/staticEntities";
import AddGroup from "./AddGroup";
import AddMember from "./AddMember";
import AddModule from "./AddModule";
import Style from "./CreateTraining.module.scss";
// import "rsuite/dist/rsuite.min.css";
import moment from "moment";
import TrainingDetail from "../../../components/TrainingDetail";
import "./rsuite.css";

const ITEM_HEIGHT = 24;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(array: string[], name: string, theme: Theme) {
  return {
    fontWeight:
      array.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const typeofTraining = [
  { key: "Self_Paced", value: "Self-Paced" },
  { key: "Scheduled", value: "Scheduled" },
  { key: "Duration", value: "Duration" },
  { key: "Not_Scheduled", value: "Not Scheduled" },
];

const schema = yup
  .object({
    name: yup.string().required("Training Name is required"),
    type: yup.string().required("Type of training is required"),
    category: yup.object().required("Category is required"),
    daterange: yup
      .array()
      .when("type", {
        is: "Self-Paced",
        then: () => yup.array().optional().nullable(),
      })
      .when("type", {
        is: "Not Scheduled",
        then: () => yup.array().optional().nullable(),
      })
      .when("type", {
        is: "Scheduled",
        then: () =>
          yup
            .array()
            .min(2, "Date Range is required")
            .required("Date Range is required"),
      })
      .when("type", {
        is: "Duration",
        then: () =>
          yup
            .array()
            .min(2, "Date Range is required")
            .required("Date Range is required"),
      }),
    duration: yup
      .mixed()
      .when("type", {
        is: (value: any) => ["Scheduled", "Self-Paced"]?.includes(value),
        then: () => yup.string().optional().nullable(),
      })
      .when("type", {
        is: "Duration",
        then: () =>
          yup
            .number()
            .min(1, "Duration must be non-negative integer")
            .typeError("Duration must be a `number` type")
            .positive("Must be a positive value")
            .required("Duration is required"),
      })
      .when("type", {
        is: "Not Scheduled",
        then: () =>
          yup
            .number()
            .min(1, "Duration must be non-negative integer")
            .typeError("Duration must be a `number` type")
            .positive("Must be a positive value")
            .required("Duration is required"),
      }),

    level: yup.string().required("Level is required"),
    training_from: yup
      .string()
      .default("Module")
      .required("Training from is required"),
    description: yup.string().required("Description is required"),
    scorm: yup.string().optional(),
    certificate: yup.object().required("Certificate is required"),
  })
  .required();

function AddTraining() {
  const {
    reset,
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    setError,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  console.log(
    "getValues",
    (getValues()?.type?.replaceAll(" ", "_") as any)?.indexOf(
      "Not Scheduled"?.replaceAll(" ", "_")
    ) > -1
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const training = useParams();
  const theme = useTheme();
  const [level, setLevel] = React.useState("");
  // const [category, setCategory] = React.useState("");
  const [scorm, setScorm] = useState<any>({});
  console.log("scorm scorm", scorm);
  const [defaultRows, setDefaultRows] = React.useState<any>([]);
  const [selectedTimeLimit, setSelectedLimit] = useState("");
  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  const [defaultMemberRows, setDefaultMemberRows] = React.useState<any>([]);
  const [defaultGroupsRows, setDefaultGroupsRows] = React.useState<any>([]);
  const [selectedMemberRows, setSelectedMemberRows] = React.useState<any>([]);
  const [selectedGroupRows, setSelectedGroupRows] = React.useState<any>([]);
  const [selectedType, setSelectedType] = useState("");

  const defaultDate = { startDate: undefined, endDate: undefined };
  const [openCreatedDate, setOpenCreatedDate] = React.useState(false);
  const [dateRangeCreated, setDateRangeCreated] = React.useState<any>();

  const [modules, setModules] = React.useState<any>([]);
  const trainingData: any = useSelector(
    (state: RootState) => state.trainingsReducer.trainingData
  );

  const { beforeToday } = DateRangePicker;

  const states = useSelector(alertMessageSelector).alertMessageReducer;
  useEffect(() => {
    dispatch(getWholeMembersListData3());
  }, []);
  useEffect(() => {
    if (dateRangeCreated?.startDate && dateRangeCreated?.endDate) {
      setOpenCreatedDate(false);
    }
  }, [dateRangeCreated?.startDate, dateRangeCreated?.endDate]);

  useEffect(() => {
    //change order of selected rows as well on changing order of modules
    let f_sel: any = [];
    let f_sel2: any = [];
    modules.forEach((element: any) => {
      if (
        selectedRows &&
        selectedRows?.results &&
        selectedRows?.results.length > 0
      )
        f_sel = [
          ...f_sel,
          selectedRows.results.find((i: any) => element?.module == i?.id),
        ];
      else if (defaultRows && defaultRows.length > 0)
        f_sel2 = [
          ...f_sel2,
          defaultRows.find((i: any) => element?.module == i?.id),
        ];
    });

    if (training?.type && training?.type == "edit") {
      if (f_sel2 && f_sel2.length > 0) setDefaultRows(f_sel2);
      setSelectedRows({ ...selectedRows, results: f_sel });
    } else {
      setSelectedRows({ ...selectedRows, results: f_sel });
    }
  }, [modules]);

  useEffect(() => {
    clearErrors();
  }, [watch("type")]);

  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  const formSubmit = (payload: any) => {
    console.log("payload", payload);
    // return;
    if (!payload?.certificate?.value) {
      toast.error(String(t("Certificate is required")), {
        toastId: String(t("Certificate is required")),
      });
      return;
    }
    const formData = new FormData();
    formData.append("name", payload?.name);
    formData.append("description", payload?.description);
    formData.append("level", payload?.level);
    // formData.append("training_from", payload?.training_from);
    formData.append("training_from", "Module");
    formData.append(
      "type",
      payload?.type == "Self-Paced"
        ? "Self_Paced"
        : payload?.type == "Not Scheduled"
        ? "Not_Scheduled"
        : payload?.type
    );

    if (payload?.type == "Duration" || payload?.type == "Not Scheduled") {
      formData.append("days_to_complete", payload?.duration as any);
    }
    if (payload?.type == "Scheduled" || payload?.type == "Duration") {
      formData.append(
        "start_date",
        moment(new Date(payload?.daterange[0])).format("YYYY-MM-DD")
      );
      formData.append(
        "end_date",
        moment(new Date(payload?.daterange[1])).format("YYYY-MM-DD")
      );
    }
    formData.append("category", payload?.category?.value);
    formData.append("certificate", payload?.certificate?.value);
    let modulesArray =
      // modules?.length > 0
      //   ? modules
      //   :

      selectedRows &&
      selectedRows?.results &&
      selectedRows?.results?.length === 0
        ? defaultRows?.map((item: any, index: any) => {
            return {
              module: item?.id,
              order: index + 1,
              is_compulsory: item?.is_compulsory
                ? "True".replaceAll(`"`, ``)
                : "False".replaceAll(`"`, ``),
            };
          })
        : selectedRows?.results?.map((item: any, index: any) => {
            return {
              module: item?.id,
              order: index + 1,
              is_compulsory: item?.is_compulsory
                ? "True".replaceAll(`"`, ``)
                : "False".replaceAll(`"`, ``),
            };
          });
    // if (payload?.training_from == "Module") {
    if (modulesArray?.length === 0) {
      toast.error(String(t("Module(s) are required.")), {
        toastId: String(t("Module(s) are required.")),
      });
      return;
    }
    if (modulesArray?.length > 0) {
      const compulsoryExist = modulesArray?.filter((r: any) => {
        return r?.is_compulsory?.toLowerCase()?.toString() === "true";
      });

      if (compulsoryExist?.length === 0) {
        toast.error(String(t("At least one module should be compulsory.")), {
          toastId: String(t("At least one module should be compulsory.")),
        });
        return;
      }
    }
    console.log(
      "payload",
      JSON.stringify(modulesArray)
        .replaceAll(`"False"`, `False`)
        .replaceAll(`"True"`, `True`),
      payload
    );
    // return;
    modulesArray &&
      formData.append(
        "modules_with_order",
        JSON.stringify(modulesArray)
          .replaceAll(`"False"`, `False`)
          .replaceAll(`"True"`, `True`)
      );
    // }
    if (payload?.training_from == "SCORM") {
      if (Object.keys(scorm).length === 0) {
        toast.error(String(t("SCORM file is required")), {
          toastId: String(t("SCORM file is required")),
        });
        return;
      }
      scorm?.image && formData.append("file", scorm?.image);
    }
    formData.append("training_from", payload?.training_from);

    // return;
    // scorm?.imagename &&
    // formData.append("file_name", scorm?.imagename + Math.random());
    if (training?.id) {
      let memberIds =
        selectedMemberRows?.length === 0
          ? defaultMemberRows?.map((item: any) => item?.id)
          : selectedMemberRows?.results?.map((item: any) => item?.id);
      console.log("memberIds", memberIds);
      memberIds?.length > 0 && formData.append("members", memberIds);
      if (payload?.type == "Self-Paced") {
        let groupIDs =
          selectedGroupRows?.length === 0
            ? defaultGroupsRows?.map((item: any) => item?.id)
            : selectedGroupRows?.results?.map((item: any) => item?.id);
        console.log("groupIDs", groupIDs);
        groupIDs?.length > 0 && formData.append("groups", groupIDs);
      }

      dispatch(updateTraining(formData, parseInt(training?.id)));
    } else {
      dispatch(addNewTraining(formData));
    }
    // navigate("/trainings");
  };

  const foregroundTextColor =
    useColor("interface_foreground_text_color") ||
    defaultThemeColor.interface_foreground_text_color;

  useEffect(() => {
    if (training && training?.id) {
      dispatch(getTraining(parseInt(training?.id)));
    }
  }, [training]);

  useEffect(() => {
    if (training && training?.id) {
      const allModules = trainingData?.ordered_modules?.map((module: any) => ({
        id: module.module.id,
        category: {
          id: module.module.category.id,
          name: module.module.category.name,
        },
        certificate: {
          id: module?.module?.certificate?.id,
          name: module?.module?.certificate?.name,
        },
        name: module.module.name,
        status: module.module.status,
        type: module.module.type,
        is_compulsory: module?.is_compulsory,
      }));
      setDefaultRows(allModules);
      setDefaultMemberRows(trainingData?.members);
      setDefaultGroupsRows(trainingData?.groups);
      setSelectedType(
        trainingData?.type == "Self_Paced"
          ? "Self-Paced"
          : trainingData?.type == "Not_Scheduled"
          ? "Not Scheduled"
          : trainingData?.type
      );

      let defaultValues = {
        name: trainingData?.name,
        level: trainingData?.level,
        training_from: trainingData?.training_from,
        category: {
          value: trainingData?.category?.id,
          label: trainingData?.category?.name,
        },
        certificate: {
          value: trainingData?.certificate?.id,
          label: trainingData?.certificate?.name,
        },
        description: trainingData?.description,
        type:
          trainingData?.type == "Self_Paced"
            ? "Self-Paced"
            : trainingData?.type == "Not_Scheduled"
            ? "Not Scheduled"
            : trainingData?.type,
        duration: trainingData?.days_to_complete,
      };
      reset({ ...defaultValues });

      if (
        trainingData?.type == "Duration" ||
        trainingData?.type == "Scheduled"
      ) {
        setValue("daterange", [
          new Date(trainingData?.start_date),
          new Date(trainingData?.end_date),
        ]);
      }
    }
  }, [trainingData]);

  useEffect(() => {
    if (states?.errors) {
      if (states?.errors?.name?.length > 0) {
        toast.error(states?.errors?.name?.toString(), {
          toastId: states?.errors?.name?.toString(),
        });
        setError("name", {
          type: "server",
          message: states?.errors?.name?.toString(),
        });
      }
      if (states?.errors?.category?.length > 0) {
        toast.error(states?.errors?.category?.toString(), {
          toastId: states?.errors?.category?.toString(),
        });
        setError("category", {
          type: "server",
          message: states?.errors?.category?.toString(),
        });
      }
      if (states?.errors?.level?.length > 0) {
        toast.error(states?.errors?.level?.toString(), {
          toastId: states?.errors?.level?.toString(),
        });
        setError("level", {
          type: "server",
          message: states?.errors?.level?.toString(),
        });
      }
      if (states?.errors?.description?.length > 0) {
        toast.error(states?.errors?.description?.toString(), {
          toastId: states?.errors?.description?.toString(),
        });
        setError("description", {
          type: "server",
          message: states?.errors?.description?.toString(),
        });
      }
      if (states?.errors?.scorm?.length > 0) {
        toast.error(states?.errors?.scorm?.toString(), {
          toastId: states?.errors?.scorm?.toString(),
        });
        setError("scorm", {
          type: "server",
          message: states?.errors?.scorm?.toString(),
        });
      }
      if (states?.errors?.certificate?.length > 0) {
        toast.error(states?.errors?.certificate?.toString(), {
          toastId: states?.errors?.certificate?.toString(),
        });
        setError("certificate", {
          type: "server",
          message: states?.errors?.certificate?.toString(),
        });
      }

      if (states?.errors?.start_date?.length > 0) {
        toast.error(states?.errors?.start_date?.toString(), {
          toastId: states?.errors?.start_date?.toString(),
        });
        setError("daterange", {
          type: "server",
          message: states?.errors?.start_date?.toString(),
        });
      }
      if (states?.errors?.type?.length > 0) {
        toast.error(states?.errors?.type?.toString(), {
          toastId: states?.errors?.type?.toString(),
        });
        setError("type", {
          type: "server",
          message: states?.errors?.type?.toString(),
        });
      }
      if (states?.errors?.training_from?.length > 0) {
        toast.error(states?.errors?.training_from?.toString(), {
          toastId: states?.errors?.training_from?.toString(),
        });
        setError("training_from", {
          type: "server",
          message: states?.errors?.training_from?.toString(),
        });
      }

      if (states?.errors?.days_to_complete?.length > 0) {
        toast.error(states?.errors?.days_to_complete?.toString(), {
          toastId: states?.errors?.days_to_complete?.toString(),
        });
        setError("duration", {
          type: "server",
          message: states?.errors?.days_to_complete?.toString(),
        });
      }
      if (states?.error) {
        toast.error(String(states?.error), {
          toastId: String(states?.error),
        });
        dispatch(setErr(null));
      }
    }
    if (states?.success) {
      toast.success(String(states?.success), {
        toastId: String(states?.success),
      });
      dispatch(setSuccess(null));
      navigate("/trainings?page=1&perpage=10");
    }
  }, [states]);

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;
  const primaryButton =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;
  const primaryButtonBg =
    useColor("button_primary") || defaultThemeColor.button_primary;

  const handleTypeChange = (selected: any) => {
    setSelectedType(selected);
  };

  useEffect(() => {
    if (watch("daterange") && (watch("daterange") as any)?.length > 1) {
      var diff = Math.floor(
        (Date.parse((watch("daterange") as any)[1]) -
          Date.parse((watch("daterange") as any)[0])) /
          86400000
      );
      if ((watch("duration") as any) > diff) {
        setError("duration", {
          type: "server",
          message: `Duration days can't be greater than date range selected (Maximum ${diff} days)`,
        });
      } else {
        clearErrors("duration");
      }
    }
  }, [watch("duration"), watch("daterange")]);

  return (
    <>
      <Header
        page={
          !(training && training?.type) ? t("Add Training") : trainingData?.name
        }
        mainHead={t("Training")}
        backLink="/trainings"
      />
      <div
        className={`${Style.background} d-flex flex-wrap pb-5`}
        style={{
          background:
            useColor("interface_foreground_color") ||
            defaultThemeColor.interface_foreground_color,
          color:
            useColor("interface_foreground_text_color") ||
            defaultThemeColor.interface_foreground_text_color,
        }}
      >
        <div className={`col-12 my-4 mb-0`}>
          <div
            className={`${Style.textHeader} mb-1`}
            style={{
              color:
                useColor("interface_foreground_text_color") ||
                defaultThemeColor.interface_foreground_text_color,
              textAlign: lang === "ar" ? "right" : "left",
              paddingRight: lang === "ar" ? "2rem" : "0rem",
            }}
          >
            {training?.id
              ? training?.type == "view"
                ? t("Training Detail")
                : t("Update Training")
              : t("Add New")}
          </div>
        </div>

        {training?.type == "view" && (
          <TrainingDetail trainingData={trainingData} detail={true} />
        )}
        <div className={`col-12 my-3 mb-0`}>
          <form id="myform" onSubmit={handleSubmit(formSubmit)}>
            {training?.type !== "view" && (
              <div className={`row col-lg-12 px-0 mx-0`}>
                <div className={`${Style.col6} px-0 mt-3`}>
                  <span className={Style.labelHeader}>
                    {t("Name")}
                    <span style={{ color: "red" }}> *</span>
                  </span>
                  <input
                    className={`${Style.inputfields} ${Style.fieldFont} ${
                      errors.name ? "is-invalid" : ""
                    }`}
                    style={{
                      color: interfaceTextColor,
                      background: interfaceBgColor,
                    }}
                    type="text"
                    placeholder={t("Training name")}
                    autoComplete="off"
                    {...register("name")}
                  />
                  <p className="color-red">{errors.name?.message}</p>
                </div>
                <div className={`${Style.col6} px-0 mt-3`}>
                  <span className={Style.labelHeader}>
                    {t("Category")}
                    <span style={{ color: "red" }}> *</span>
                  </span>
                  <CategoryLoadComponent
                    control={control}
                    parentName={"category"}
                  ></CategoryLoadComponent>
                  <p className="color-red">{errors.category?.message || ""}</p>
                </div>
                <div className={`${Style.col6} px-0 mt-3`}>
                  <span className={`${Style.labelHeader} mb-2`}>
                    {t("Type of Training")}
                    <span className="color-red"> *</span>
                  </span>
                  <FormControl fullWidth sx={{ mt: 1.3 }}>
                    <Controller
                      name="type"
                      control={control}
                      defaultValue={typeofTraining[0].value}
                      render={({ field }) => (
                        <FormControl>
                          {/* <InputLabel id="type">{t("Type of Training")}</InputLabel> */}
                          <Select
                            {...field}
                            labelId="type"
                            label=""
                            style={{
                              color: interfaceTextColor,
                              background: interfaceBgColor,
                            }}
                            sx={{
                              height: "3.9em",
                              "& .MuiChip-root": {
                                color: primaryButton,
                                background: primaryButtonBg,
                              },
                            }}
                            MenuProps={{
                              sx: {
                                "&& .MuiPopover-paper": {
                                  color: interfaceTextColor,
                                  background: interfaceBgColor,
                                },
                                "&& .Mui-selected": {
                                  color: light_text_color,
                                  background: primary_color,
                                },
                                "&& .Mui-selected:hover": {
                                  color: "black",
                                  background: "skyblue",
                                },
                              },
                            }}
                            renderValue={(selected) => {
                              return (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                  }}
                                >
                                  {selected && (
                                    <Chip key={selected} label={selected} />
                                  )}
                                </Box>
                              );
                            }}
                          >
                            {typeofTraining.map((row: any) => (
                              <MenuItem
                                value={row.value}
                                key={row.key}
                                className="d-flex"
                                onClick={() => handleTypeChange(row?.value)}
                              >
                                <Radio
                                  color="default"
                                  sx={{
                                    color:
                                      getValues() && getValues().type
                                        ? (getValues()?.type?.replaceAll(
                                            " ",
                                            "_"
                                          ) as any) ==
                                          row.value?.replaceAll(" ", "_")
                                          ? "white"
                                          : ""
                                        : "",
                                  }}
                                  checked={
                                    getValues() && getValues().type
                                      ? (getValues()?.type?.replaceAll(
                                          " ",
                                          "_"
                                        ) as any) ==
                                        row.value?.replaceAll(" ", "_")
                                      : false
                                  }
                                  name="radio-buttons"
                                />
                                <div> {row.value}</div>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </FormControl>
                  <p className="error color-red">{errors.type?.message}</p>
                </div>

                {(selectedType == "Duration" ||
                  selectedType == "Scheduled") && (
                  <div
                    className={`${Style.col6} px-0 mt-3`}
                    style={{
                      marginRight: lang == "ar" ? "0em" : "",
                    }}
                  >
                    <span className={`${Style.labelHeader}`}>
                      {t("Date Range")}
                      <span className="color-red"> *</span>
                    </span>
                    <div className="color-picker-container">
                      <FormControl fullWidth>
                        <Controller
                          name="daterange"
                          control={control}
                          defaultValue={
                            trainingData?.id &&
                            (trainingData?.type == "Duration" ||
                              trainingData?.type == "Scheduled")
                              ? [
                                  new Date(trainingData?.start_date),
                                  new Date(trainingData?.end_date),
                                ]
                              : []
                          }
                          render={({ field: { value, onChange, ref } }) => (
                            <FormControl>
                              <DateRangePicker
                                onChange={onChange}
                                // value={[value] || []}
                                defaultValue={
                                  trainingData?.id &&
                                  (trainingData?.type == "Duration" ||
                                    trainingData?.type == "Scheduled")
                                    ? [
                                        new Date(trainingData?.start_date),
                                        new Date(trainingData?.end_date),
                                      ]
                                    : null
                                }
                                appearance="default"
                                disabledDate={
                                  training?.id
                                    ? null
                                    : ((beforeToday as any)() as any)
                                }
                                placeholder="Select Training Range"
                                className={`${Style.col6} d-flex justify-content-between align-content-center align-items-center`}
                                size="lg"
                                style={{
                                  width: "100%",
                                  marginTop: 10,
                                  display: "flex",
                                  border: "1px solid rgba(0, 0, 0, 0.23)",
                                  borderRadius: "6px",
                                  color: "black",
                                }}
                                menuStyle={{
                                  color: "black",
                                }}
                              />
                            </FormControl>
                          )}
                        />
                      </FormControl>

                      <p className="color-red">{errors.daterange?.message}</p>
                    </div>
                  </div>
                )}

                {(selectedType == "Duration" ||
                  selectedType == "Not Scheduled") && (
                  <div className={`${Style.col6} px-0 mt-3`}>
                    <span className={Style.labelHeader}>
                      {t("Day(s)")}
                      <span style={{ color: "red" }}> *</span>
                    </span>
                    <input
                      className={`${Style.inputfields} ${Style.fieldFont} ${
                        errors.duration ? "is-invalid" : ""
                      }`}
                      style={{
                        color: interfaceTextColor,
                        background: interfaceBgColor,
                      }}
                      type="number"
                      placeholder="Enter Day(s)"
                      autoComplete="off"
                      {...register("duration")}
                    />
                    <p className="color-red">{errors.duration?.message}</p>
                  </div>
                )}

                <div className={`${Style.col6} px-0 mt-3`}>
                  <span className={Style.labelHeader}>
                    {t("Level")}
                    <span style={{ color: "red" }}> *</span>
                  </span>
                  <FormControl sx={{ m: 0, width: "100%", mt: 1.25 }}>
                    {/* <InputLabel id="demo-multiple-name-label">
                      {t("Level")}
                    </InputLabel> */}
                    <Controller
                      control={control}
                      defaultValue={level || "Beginner"}
                      name="level"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Select
                          // labelId="level"
                          id="level"
                          // label="Select"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          value={value}
                          style={{
                            color: interfaceTextColor,
                            background: interfaceBgColor,
                          }}
                          onChange={(event) => {
                            onChange(event);
                          }}
                          input={<OutlinedInput />}
                          sx={{
                            height: "3.9em",
                            padding: "0",
                            "& .MuiChip-root": {
                              color: primaryButton,
                              background: primaryButtonBg,
                            },
                          }}
                          renderValue={(selected) => {
                            return (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected && (
                                  <Chip key={selected} label={selected} />
                                )}
                              </Box>
                            );
                          }}
                          MenuProps={{
                            ...MenuProps,
                            sx: {
                              "&& .MuiPopover-paper": {
                                color: interfaceTextColor,
                                background: interfaceBgColor,
                              },
                              "&& .Mui-selected": {
                                color: light_text_color,
                                background: primary_color,
                              },
                              "&& .Mui-selected:hover": {
                                color: "black",
                                background: "skyblue",
                              },
                            },
                          }}
                          placeholder={t("Select")}
                        >
                          {/* <MenuItem disabled value="">
                            <em>Select level</em>
                          </MenuItem> */}
                          {assessment_level_values.map((level: any) => (
                            <MenuItem
                              key={level}
                              value={level}
                              style={getStyles(
                                assessment_level_values,
                                level,
                                theme
                              )}
                            >
                              {level}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                  <p className="color-red">{errors.level?.message || ""}</p>
                </div>
                <div
                  className={`${Style.col6} px-0 ${
                    lang === "ar" ? "mr-4" : ""
                  } mt-3`}
                  style={{
                    marginRight: lang === "ar" ? "" : "2rem !important",
                  }}
                >
                  <span className={Style.labelHeader}>
                    {t("Certificate")}
                    <span style={{ color: "red" }}> *</span>
                  </span>
                  <CertificateLoadComponent
                    control={control}
                    parentName={"certificate"}
                  ></CertificateLoadComponent>
                  <p className="color-red">
                    {errors.certificate?.message || ""}
                  </p>
                </div>
                <div
                  className={`${Style.col6} px-0 mt-3 ${
                    lang === "ar" ? "mr-4" : "ml-0"
                  } ${
                    (selectedType == "Scheduled" ||
                      selectedType == "Not Scheduled") &&
                    "mt-3"
                  } mb-3`}
                  // style={{ marginRight: lang === "ar" ? "2rem !important" : "0rem !important" }}
                >
                  <span className={Style.labelHeader}>
                    {t("Description")}
                    <span style={{ color: "red" }}> *</span>
                  </span>
                  <input
                    className={`${Style.inputfields} ${Style.fieldFont} ${
                      errors.description ? "is-invalid" : ""
                    }`}
                    style={{
                      color: interfaceTextColor,
                      background: interfaceBgColor,
                    }}
                    type="text"
                    placeholder={t("Description")}
                    autoComplete="off"
                    {...register("description")}
                  />
                  <p className="color-red">{errors.description?.message}</p>
                  {/* <CertificateLoadComponent
                    control={control}
                    parentName={"certificate"}
                  ></CertificateLoadComponent> */}
                  {/* <p className="color-red">
                    {errors.certificate?.message || ""}
                  </p> */}
                </div>
                {/* <div className={`${Style.col6} px-0 mt-3`}>
                  <span className={Style.labelHeader}>
                    {t("Training From")}
                    <span style={{ color: "red" }}> *</span>
                  </span>
                  <FormControl sx={{ m: 0, width: "100%", mt: 1.25 }}>
                    <Controller
                      control={control}
                      defaultValue={"Module"}
                      name="training_from"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Select
                          id="training_from"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          value={value}
                          style={{
                            color: interfaceTextColor,
                            background: interfaceBgColor,
                          }}
                          onChange={(event) => {
                            onChange(event);
                          }}
                          input={<OutlinedInput />}
                          sx={{
                            height: "3.9em",
                            padding: "0",
                            "& .MuiChip-root": {
                              color: primaryButton,
                              background: primaryButtonBg,
                            },
                          }}
                          renderValue={(selected) => {
                            return (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected && (
                                  <Chip key={selected} label={selected} />
                                )}
                              </Box>
                            );
                          }}
                          MenuProps={{
                            ...MenuProps,
                            sx: {
                              "&& .MuiPopover-paper": {
                                color: interfaceTextColor,
                                background: interfaceBgColor,
                              },
                              "&& .Mui-selected": {
                                color: light_text_color,
                                background: primary_color,
                              },
                              "&& .Mui-selected:hover": {
                                color: "black",
                                background: "skyblue",
                              },
                            },
                          }}
                          placeholder={t("Select")}
                        >
                          {trainingFrom.map((row: any) => (
                            <MenuItem
                              key={row}
                              value={row}
                              style={getStyles(trainingFrom, row, theme)}
                            >
                              {row}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                  <p className="color-red">
                    {errors.training_from?.message || ""}
                  </p>
                </div> */}
                {watch("training_from") == "SCORM" && (
                  <div className={`${Style.col6} px-0 mt-3`}>
                    <span className={Style.labelHeader}>
                      {t("Upload SCORM file")}
                      {/* <span className="color-red"> *</span> */}
                    </span>
                    <ChooseFile
                      size="small"
                      maxFileSizeinBytes={104857600} // 5gb
                      fileTypes="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                      loading={loading}
                      errors={errors}
                      setValue={setValue}
                      imageName={"scorm"}
                      clearErrors={clearErrors}
                      acceptedImageTypesArray={[
                        "zip",
                        "application/octet-stream",
                        "application/zip",
                        "application/x-zip",
                        "application/x-zip-compressed",
                      ]}
                      setImageParent={setScorm}
                    ></ChooseFile>
                    <div
                      className={Style.noteText}
                      style={{
                        color:
                          // useColor("interface_foreground_text_color") ||
                          defaultThemeColor.interface_foreground_text_color,
                      }}
                    >
                      {t("Note: Only Compressed (.zip) files are allowed")}!
                    </div>
                    {training?.id && trainingData?.file && (
                      <>
                        <div
                          className={`${Style.noteText2}`}
                          style={{
                            color:
                              defaultThemeColor.interface_foreground_text_color,
                          }}
                        >
                          {`File ${
                            trainingData?.file_name?.length > 20
                              ? "'" +
                                trainingData?.file_name?.slice(0, 20) +
                                "... .'"
                              : trainingData?.file_name || ""
                          } has already uploaded `}
                          <a href={trainingData?.file}>
                            {t("Click here to View")}
                          </a>
                        </div>
                      </>
                    )}
                    <p className="error color-red">{errors.scorm?.message}</p>
                  </div>
                )}
              </div>
            )}
            {training?.type !== "view" && (
              <div className={`row col-12 px-0 mx-0 mb-4`}></div>
            )}
            {/* {watch("training_from") == "Module" && ( */}
            <AddModule
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
              setDefaultRows={setDefaultRows}
              defaultRows={defaultRows}
              setModules={setModules}
              modules={modules}
              id={training?.id}
              view={training?.type == "view"}
            />
            {/* )} */}

            {training?.id && (
              <AddMember
                id={training?.id}
                setSelectedRows={setSelectedMemberRows}
                selectedRows={selectedMemberRows}
                setDefaultRows={setDefaultMemberRows}
                defaultRows={defaultMemberRows}
              />
            )}

            {training?.id && selectedType == "Self-Paced" && (
              <AddGroup
                id={training?.id}
                setSelectedRows={setSelectedGroupRows}
                selectedRows={selectedGroupRows}
                setDefaultRows={setDefaultGroupsRows}
                defaultRows={defaultGroupsRows}
              />
            )}

            {training?.type !== "view" && (
              <div
                className="d-flex flex-wrap gap-3 w-100 px-3 mt-5 align-items-center mb-5 pb-4"
                style={{
                  justifyContent: lang === "ar" ? "flex-start" : "flex-end",
                }}
              >
                <SubmitButton
                  label={training?.id ? t("Update") : t("Save")}
                  marginTop={false}
                />
                <CancelButton
                  size="small"
                  outlined={true}
                  onClick={() => {
                    navigate("/trainings");
                  }}
                  textColor=""
                  outlineColor=""
                  text={t("Cancel")}
                  className={``}
                  bg={""}
                ></CancelButton>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default AddTraining;
