import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-multi-lang";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import { defaultThemeColor } from "../../utils/colors_values";
import Style from "../questionsLibrary/CreateQuestion.module.scss";
import CreateQuestionForm from "../questionsLibrary/CreateQuestionForm";

export interface SimpleDialogProps {
  setOpenCreateNewQuestionModal: any;
  openCreateNewQuestionModal: any;
  onClose: any;
  isLoading?: boolean;
}

function AddQuestion(props: SimpleDialogProps) {
  const { setOpenCreateNewQuestionModal, openCreateNewQuestionModal, onClose, isLoading } = props;
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const handleCloseModal = () => {
    if (isLoading) {
    } else {
      onClose();
    }
  };

  return (
    <Dialog
      onClose={handleCloseModal}
      open={openCreateNewQuestionModal !== undefined ? openCreateNewQuestionModal : false}
      maxWidth={"lg"}
      style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
    >
      <div className="d-flex justify-content-between ">
        <DialogTitle style={{ paddingTop: "1em", fontSize: 22 }} className="ml-3 mt-3 mb-0 pb-0">
          {t("Create Question")}
        </DialogTitle>
        <div className="m-3">
          <CloseIcon sx={{ cursor: "pointer" }} onClick={handleCloseModal} />
        </div>
      </div>
      <DialogContent>
        <div
          className={`${Style.background}`}
          style={{
            background:
              useColor("interface_background_color") ||
              defaultThemeColor.interface_background_color,
            color:
              useColor("interface_background_text_color") ||
              defaultThemeColor.interface_background_text_color,
          }}
        >
          <div>
            <div
              style={{
                background:
                  useColor("interface_foreground_color") ||
                  defaultThemeColor.interface_foreground_color,
                color:
                  useColor("interface_foreground_text_color") ||
                  defaultThemeColor.interface_foreground_text_color,
                borderRadius: 10,
              }}
              className="pb-3"
            >
              <div className={`${Style.padding} ${Style.questionModalWidth}`}>
                <CreateQuestionForm
                  cancelAction={() => setOpenCreateNewQuestionModal(false)}
                  isModal={true}
                  setOpenCreateNewQuestionModal={setOpenCreateNewQuestionModal}
                />
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default AddQuestion;
