/* eslint-disable react-hooks/exhaustive-deps */
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, Drawer } from "@mui/material";
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import useColor from "../hooks/useColor";
import Logo from "../images/logo.svg";
import { defaultThemeColor } from "../utils/colors_values";
import Navigation from "./Navigation";
import Style from "./Sidebar.module.scss";
import useCompanyLanguage from "../hooks/language";

const Sidebar = () => {
  const lang = useCompanyLanguage();
  const [drawer, setDrawer] = React.useState(false);
  const [image, setImage] = React.useState<any>();
  const onOpenDrawer = () => {
    setDrawer(true);
  };
  const onCloseDrawer = () => {
    setDrawer(false);
  };

  useEffect(() => {
    if (localStorage.getItem("company_profile")) {
      setImage(JSON.parse(localStorage.getItem("company_profile") as any)?.company_logo);
    }
  }, [localStorage.getItem("company_profile")]);

  return (
    <div
      className={Style.sidebarStart}
      style={{
        background:
          useColor("sidebar_background_color") || defaultThemeColor.sidebar_background_color,
        paddingRight: lang === "ar" ? "30px" : "0px",
        fontWeight: lang === "ar" ? "bold" : "",
      }}
    >
      <div className={Style.sidebarDesk}>
        <div className={Style.logo} style={{ minHeight: "4.2em", paddingBottom: "40px" }}>
          <NavLink to="/">
            <img src={image ? image : Logo} alt="Loading Logo..." />
          </NavLink>
        </div>

        <div className={Style.sideMenu}>
          <Navigation />
        </div>
      </div>

      <div className={`${Style.sidebarMob}`}>
        <AppBar position="fixed" sx={{ backgroundColor: "#fff", color: "#2b3674" }}>
          <div className="d-flex py-3 px-3 gap-3 align-items-center">
            <MenuIcon fontSize="large" onClick={onOpenDrawer} />
            <div className={Style.logo}>
              <NavLink to="/">
                <img src={Logo} alt="logo" />
              </NavLink>
            </div>
          </div>
        </AppBar>
      </div>

      <Drawer open={drawer} onClose={onCloseDrawer}>
        <Box sx={{ width: 300, paddingLeft: "30px" }}>
          <Navigation />
        </Box>
      </Drawer>
    </div>
  );
};

export default Sidebar;
