// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserDetailsEdit_labelHeader__ZmiX2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #212b36;
}

.UserDetailsEdit_inputfields__wNdjI {
  display: flex;
  padding: 0px 12px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border: 1px solid grey;
  height: 55px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
}

.UserDetailsEdit_fieldFont__jKs9R {
  color: rgb(0, 0, 0);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.UserDetailsEdit_col3__97aF6 {
  width: 25%;
  margin-inline: 1.5rem;
}
@media only screen and (max-width: 900px) {
  .UserDetailsEdit_col3__97aF6 {
    width: 100%;
    margin-inline: 0.9em;
  }
}

.UserDetailsEdit_col8__hsi-7 {
  width: 62.3%;
  margin-inline: 0.5rem;
}
@media only screen and (max-width: 900px) {
  .UserDetailsEdit_col8__hsi-7 {
    width: 85%;
    margin-inline: 1em;
    margin-bottom: 1em;
  }
}

.UserDetailsEdit_col6__BE9xc {
  width: 30%;
  margin-inline: 0.5rem;
}
@media only screen and (max-width: 900px) {
  .UserDetailsEdit_col6__BE9xc {
    width: 40%;
    margin-inline: 1em;
  }
}
@media only screen and (max-width: 720px) {
  .UserDetailsEdit_col6__BE9xc {
    width: 40%;
    margin-inline: 0.9em;
  }
}

.UserDetailsEdit_justify__5FiB2 {
  padding-bottom: 3em;
}
@media only screen and (max-width: 900px) {
  .UserDetailsEdit_justify__5FiB2 {
    justify-content: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/users/UserDetailsEdit.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;AACF;;AAEA;EACE,aAAA;EACA,iBAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,qCAAA;AACF;;AAEA;EACE,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;AACF;;AAEA;EACE,UAAA;EACA,qBAAA;AACF;AAAE;EAHF;IAII,WAAA;IACA,oBAAA;EAGF;AACF;;AAAA;EACE,YAAA;EACA,qBAAA;AAGF;AADE;EAJF;IAKI,UAAA;IACA,kBAAA;IACA,kBAAA;EAIF;AACF;;AADA;EACE,UAAA;EACA,qBAAA;AAIF;AAFE;EAJF;IAKI,UAAA;IACA,kBAAA;EAKF;AACF;AAHE;EATF;IAUI,UAAA;IACA,oBAAA;EAMF;AACF;;AAHA;EACE,mBAAA;AAMF;AALE;EAFF;IAGI,uBAAA;EAQF;AACF","sourcesContent":[".labelHeader {\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 22px;\n  letter-spacing: 0px;\n  text-align: left;\n  color: #212b36;\n}\n\n.inputfields {\n  display: flex;\n  padding: 0px 12px;\n  flex-direction: column;\n  align-items: flex-start;\n  align-self: stretch;\n  border: 1px solid grey;\n  height: 55px;\n  width: 100%;\n  border-radius: 4px;\n  border: 1px solid rgba(0, 0, 0, 0.23);\n}\n\n.fieldFont {\n  color: rgba(0, 0, 0, 1);\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  letter-spacing: 0.15px;\n}\n\n.col3 {\n  width: 25%;\n  margin-inline: 1.5rem;\n  @media only screen and (max-width: 900px) {\n    width: 100%;\n    margin-inline: 0.9em;\n  }\n}\n\n.col8 {\n  width: 62.3%;\n  margin-inline: 0.5rem;\n\n  @media only screen and (max-width: 900px) {\n    width: 85%;\n    margin-inline: 1em;\n    margin-bottom: 1em;\n  }\n}\n\n.col6 {\n  width: 30%;\n  margin-inline: 0.5rem;\n\n  @media only screen and (max-width: 900px) {\n    width: 40%;\n    margin-inline: 1em;\n  }\n\n  @media only screen and (max-width: 720px) {\n    width: 40%;\n    margin-inline: 0.9em;\n  }\n}\n\n.justify {\n  padding-bottom: 3em;\n  @media only screen and (max-width: 900px) {\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelHeader": `UserDetailsEdit_labelHeader__ZmiX2`,
	"inputfields": `UserDetailsEdit_inputfields__wNdjI`,
	"fieldFont": `UserDetailsEdit_fieldFont__jKs9R`,
	"col3": `UserDetailsEdit_col3__97aF6`,
	"col8": `UserDetailsEdit_col8__hsi-7`,
	"col6": `UserDetailsEdit_col6__BE9xc`,
	"justify": `UserDetailsEdit_justify__5FiB2`
};
export default ___CSS_LOADER_EXPORT___;
