import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CancelButton from "../../components/CancelButton";
import CreateButton from "../../components/CreateButton";
import DataTable from "../../components/table/DataTable";
import TableFilters from "../../components/table/TableFilters";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import usePermission from "../../hooks/usePermission";
import { bulkDeleteGroups } from "../../services/groupManagementService";
import {
  getAssessmentsForModules,
  getWholeAssessmentsForModules,
} from "../../services/moduleService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { groupManagementSelector } from "../../store/reducers/groupManagementReducer";
import {
  setPage,
  setWholeAssessmentsData,
} from "../../store/reducers/moduleReducer";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import { confirmProps } from "../../utils/confirmProps";
import Style from "./ModuleListing.module.scss";

export interface SimpleDialogProps {
  open: boolean;
  setParentOpen: any;
  selectedValue?: string;
  isLoading: boolean;
  onClose: () => void;
  setParentCheckedQuestions?: any;
  parentCheckedQuestions?: any;
  setParentCheckedButNotAddedQuestions?: any;
  parentCheckedButNotAddedQuestions?: any;
  setType?: any;
  setSelectedRows?: any;
}

function AssessmentDialog(props: SimpleDialogProps) {
  const {
    onClose,
    selectedValue,
    open,
    setParentOpen,
    isLoading,
    setParentCheckedQuestions,
    parentCheckedQuestions,
    setParentCheckedButNotAddedQuestions,
    parentCheckedButNotAddedQuestions,
    setType,
    setSelectedRows,
  } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  const addPermission = usePermission("add_customusergroup");
  const editUpdatePermission = usePermission("change_customusergroup");
  const deletePermission = usePermission("delete_customusergroup");
  const viewPermission = usePermission("view_customusergroup");
  const [itemPerPage, setItemPerPage] = useState<number>(10);

  const [searchTable, setSearchTable] = useState("");

  const [list, setList] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<any[]>([]);
  const [selectedIDsForCurrentPage, setSelectedIDsForCurrentPage] = useState(
    []
  );
  const states = useSelector(groupManagementSelector).departmentReducer;
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;
  const [rows, setRows] = useState([]);

  const page = useSelector((state: RootState) => state.moduleReducer.page);

  const dataList = useSelector(
    (state: RootState) => state.moduleReducer.assessmentModulesData
  );
  const wholeData = useSelector(
    (state: RootState) => state.moduleReducer.wholeAssessmentData
  );

  useEffect(() => {
    if (
      parentCheckedButNotAddedQuestions.length == 0 &&
      parentCheckedQuestions.length == 0
    ) {
      setSelectedIDsForCurrentPage([]);
      setSelectedIDsForCurrentPage([]);
    }
  }, [parentCheckedButNotAddedQuestions, parentCheckedQuestions]);

  useEffect(() => {
    dispatch(setPage(1));
  }, []);

  useEffect(() => {
    if (parentCheckedQuestions && parentCheckedQuestions.length > 0) {
      // setParentCheckedQuestions(parentCheckedQuestions);
      if (
        !(
          parentCheckedQuestions.length ==
          parentCheckedButNotAddedQuestions.length
        )
      )
        setParentCheckedButNotAddedQuestions(parentCheckedQuestions);
    }
  }, [parentCheckedQuestions]);

  useEffect(() => {
    if (!open) {
      dispatch(setPage(1));
      setItemPerPage(10);
    }
  }, [open]);

  useEffect(() => {
    dispatch(
      getAssessmentsForModules({
        page_size: Number(itemPerPage),
        page: Number(page),
        search: searchTable,
        category: "",
        retaking_assessment: "",
        individual_assessment: "",
        assessment_status: "Approved",
      })
    );
  }, [dispatch, itemPerPage, page, searchTable]);

  useEffect(() => {
    let data: any = dataList.results;
    if (Object.keys(dataList).length === 0) {
      setRows([]);
    } else setRows(data);
  }, [dataList]);

  useEffect(() => {
    let selectedIDsForCurrentPage2: any = [];
    let currentPageIDs = dataList && dataList?.results?.map((i: any) => i.id);
    parentCheckedButNotAddedQuestions?.forEach((element: any) => {
      if (!currentPageIDs?.find((it: any) => it == element?.id)) {
      } else {
        selectedIDsForCurrentPage2 = [
          ...selectedIDsForCurrentPage2,
          element?.id,
        ];
      }
    });
    setSelectedIDsForCurrentPage(selectedIDsForCurrentPage2);
  }, [dataList, open]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      setSelectedCheckBox([]);
      dispatch(
        getAssessmentsForModules({
          page_size: Number(itemPerPage),
          page: Number(page),
          search: searchTable,
          category: "",
          retaking_assessment: "",
          individual_assessment: "",
          assessment_status: "Approved",
        })
      );
    }
  }, [alertMessage]);

  useEffect(() => {
    let final: any = [];
    selectedCheckBox.length > 0 &&
      selectedCheckBox.forEach((checkbox) => {
        final = [
          ...final,
          dataList?.results?.find((item: any) => item?.id == checkbox) ||
            parentCheckedButNotAddedQuestions.find(
              (i: any) => i?.id == checkbox
            ) ||
            undefined,
        ];
      });
    final = [...final, ...parentCheckedButNotAddedQuestions];

    final = removeDuplicates(final);
    final = final.filter((i: any) => typeof i == "object");
    setParentCheckedButNotAddedQuestions(final);
  }, [selectedCheckBox]);

  useEffect(() => {
    setSelectedCheckBox([
      ...selectedCheckBox,
      ...(parentCheckedButNotAddedQuestions &&
        parentCheckedButNotAddedQuestions.map((i: any) => i?.id)),
    ]);
  }, [page]);

  const removeDuplicates = (arr: any) => {
    return arr.filter(
      (value: any, index: any, self: any) =>
        index === self.findIndex((t: any) => t?.id === value?.id)
    );
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setSelectedCheckBox([]);
    dispatch(setPage(page));
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    dispatch(setPage(1));
    setSelectedCheckBox([]);
    setItemPerPage(Number(rowsPerPage));
  };

  useEffect(() => {
    modifyRows();
  }, [rows]);
  const bulkDelete = (ids: any) => {
    confirm({
      title: t("Are you sure!"),
      description: t(`Do you want to delete selected Module(s)?`),
      ...confirmProps,
    })
      .then(() => {
        dispatch(bulkDeleteGroups(ids));
        setSelectedCheckBox([]);
      })
      .catch(() => {});
  };
  const modifyRows = () => {
    let f_rows: any = [];
    rows.forEach(
      (
        r: {
          id: string;
          name: string;
          description: string;
          category: { id: number; name: string };
          // time_option: string;
          created_at: string;
          updated_at: string;
          assessment_status: string;
        },
        i: number
      ) => {
        if (parentCheckedQuestions?.find((val: any) => val?.id == r?.id)) {
          setSelectedCheckBox([...selectedCheckBox, r.id]);
        }
        f_rows = [
          ...f_rows,
          {
            id: r.id,
            name: r.name,
            description: r.description,
            category: r.category?.name,
            // time_option: r.time_option,
            created_at:
              moment(new Date(r.created_at)).format("MMM Do, YYYY") || "",
            updated_at:
              moment(new Date(r.updated_at)).format("MMM Do, YYYY") || "",
            status: (
              <span
                className={
                  r.assessment_status?.toLowerCase() === "approved"
                    ? Style.statusSuccess
                    : Style.statusDraft
                }
              >
                {r.assessment_status}
              </span>
            ),
            // actions: (
            //   <div style={{ minWidth: "13em" }}>
            //     <button className="btn-link" type="button">
            //       <ViewSVG />
            //     </button>
            //     <button className="btn-link" type="button">
            //       <EditSVG />
            //     </button>
            //     <button
            //       className="btn-link"
            //       type="button"
            //       onClick={() => {
            //         handleDeleteAction(r);
            //       }}
            //     >
            //       <DeleteSVG color="red" />
            //     </button>
            //   </div>
            // ),
          },
        ];
      }
    );
    setList(f_rows);
  };

  const handleClose = () => {
    setSelectedCheckBox([]);
    setParentCheckedButNotAddedQuestions([]);
    setSelectedIDsForCurrentPage([]);
    onClose();
  };

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;

  const handleClicked = () => {
    getWholeMembers();
  };

  const getWholeMembers = () => {
    if (dataList)
      dispatch(
        getWholeAssessmentsForModules({
          page_size: dataList.count,
          page: 1,
          search: "",
          category: "",
          retaking_assessment: "",
          individual_assessment: "",
          assessment_status: "Approved",
        })
      );
  };

  return (
    <Dialog
      onClose={onClose}
      maxWidth={"lg"}
      open={open && isLoading ? true : open}
      style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
    >
      <div
        className="d-flex justify-content-between align-items-baseline"
        style={{
          color: interfaceTextColor,
          background: interfaceBgColor,
        }}
      >
        <DialogTitle className="p-3 mt-3 mx-3 pb-0">
          {t("Assessments")}
        </DialogTitle>
        <div className="p-3">
          <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
        </div>
      </div>
      <DialogContent
        style={{ color: interfaceTextColor, background: interfaceBgColor }}
      >
        <TableFilters
          dataList={dataList}
          itemPerPage={itemPerPage}
          selectedCheckBox={selectedCheckBox}
          bulkDelete={() => {}}
          setItemPerPage={handleChangeRowsPerPage}
          setSearchTable={setSearchTable}
          createButton={<></>}
          handle="groups"
          isCheckboxes={false}
          exportCSV={false}
          setParentData={() => dispatch(setWholeAssessmentsData({}))}
          handleClicked={handleClicked}
          rows={wholeData?.results?.map((r: any, i: any) => ({
            // id: r.id,
            name: r.name,
            description: r.description,
            category: r.category?.name,
            // time_option: r.time_option,
            created_at:
              moment(new Date(r.created_at)).format("MMM Do, YYYY") || "",
            updated_at:
              moment(new Date(r.updated_at)).format("MMM Do, YYYY") || "",
            status: r.assessment_status,
          }))}
          headers={[
            // "Id.",
            "Assessment Name",
            "Description",
            "Category",
            "Created At",
            "Updated At",
            "Status",
          ]}
          filename={`approved_assessments.csv`}
        />
        <DataTable
          columnData={[
            {
              id: "assessment_name",
              name: t("Assessment Name"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "description",
              name: t("Description"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "category",
              name: t("Category"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            // {
            //   id: "time_optionn",
            //   name: "Time Option",
            //   enableSort: true,
            //   align: "left",
            //   disablePadding: "none",
            // },

            {
              id: "created_at",
              name: t("Created At"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "updated_at",
              name: t("Updated At"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "status",
              name: t("Status"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            // {
            //   id: "actions",
            //   name: "Actions",
            //   enableSort: false,
            //   align: "center",
            //   disablePadding: "none",
            // },
          ]}
          rows={list}
          rowsScroll={true}
          minHeight={"25em"}
          isCheckboxes={true}
          selectedRowsPerPage={itemPerPage.toString()}
          totalRecordCount={Number(dataList.count)}
          handleChangePageParent={handleChangePage}
          pagenum={Number(page)}
          handleChangeRowsPerPageParent={handleChangeRowsPerPage}
          bulkDelete={bulkDelete}
          selectedCheckBox={selectedIDsForCurrentPage}
          setSelectedCheckBox={(boxes: any) => {
            // setSelectedCheckBox(boxes);
            let currentPageIDs =
              dataList && dataList?.results?.map((i: any) => i.id);
            let parentAll: any = [];
            let parentAllWithData: any = [];
            let notSelectedIDsForCurrentPage: any = [];
            let selectedIDsForCurrentPage: any = [];
            dataList?.results?.forEach((element: any) => {
              if (!boxes.find((it: any) => it == element.id)) {
                notSelectedIDsForCurrentPage = [
                  ...notSelectedIDsForCurrentPage,
                  element.id,
                ];
              } else {
                selectedIDsForCurrentPage = [
                  ...selectedIDsForCurrentPage,
                  element.id,
                ];
              }
            });
            parentAll = parentCheckedButNotAddedQuestions.filter(
              (x: any) => !currentPageIDs.includes(x?.id)
            );
            parentAllWithData = parentCheckedButNotAddedQuestions.filter(
              (x: any) => !currentPageIDs.includes(x?.id)
            );
            parentAll = [...parentAll, ...selectedIDsForCurrentPage];
            setSelectedCheckBox(parentAll);
            setParentCheckedButNotAddedQuestions(parentAll);
            setSelectedIDsForCurrentPage(selectedIDsForCurrentPage);
          }}
          displayForceBottomComponent={true}
          bottomButtonsComponent={
            <div className="d-flex flex-wrap gap-3 w-100 px-3 mt-4 mb-2 align-items-center justify-content-end ">
              <CreateButton
                size="large"
                outlined={false}
                onClick={() => {
                  let final: any = [];
                  selectedCheckBox.forEach((checkbox) => {
                    final = [
                      ...final,
                      dataList?.results?.find(
                        (item: any) => item.id == checkbox
                      ),
                    ];
                  });
                  setParentCheckedQuestions(parentCheckedButNotAddedQuestions);
                  const selectedCheckBoxArray = selectedCheckBox?.map((str) =>
                    Number(str)
                  );
                  const updatedDataList = {
                    ...dataList,
                    count: selectedCheckBoxArray.length,
                    results: dataList.results.filter((item: any) =>
                      selectedCheckBoxArray.includes(Number(item.id))
                    ),
                  };
                  setSelectedRows(updatedDataList);
                  setParentOpen(false);
                  // setType("");
                }}
                disabled={
                  !(
                    parentCheckedButNotAddedQuestions &&
                    parentCheckedButNotAddedQuestions.length > 0
                  )
                }
                text={t("Add to Module")}
              ></CreateButton>
              <CancelButton
                size="small"
                outlined={true}
                onClick={() => {
                  setItemPerPage(10);
                  setPage(1);
                  setSelectedCheckBox([]);
                  setParentCheckedButNotAddedQuestions([]);
                  setSelectedIDsForCurrentPage([]);
                  handleClose();
                }}
                textColor=""
                outlineColor=""
                text={t("Cancel")}
                className={``}
                bg={""}
                disabled={
                  false
                  // !(
                  //   parentCheckedButNotAddedQuestions &&
                  //   parentCheckedButNotAddedQuestions.length > 0
                  // )
                }
              ></CancelButton>
            </div>
          }
          isSingleSelectRowOnly={true}
        />
      </DialogContent>
    </Dialog>
  );
}

export default AssessmentDialog;
