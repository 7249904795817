import Checkbox from "@mui/material/Checkbox";
import { Theme, useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CancelButton from "../../components/CancelButton";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import usePermission from "../../hooks/usePermission";
import {
  getPersonalForm,
  personalFormSelector,
  setPersonalFormData,
  updatePersonalForm,
} from "../../store/reducers/personalFormReducer";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import Style from "./PersonalForm.module.scss";

const ITEM_HEIGHT = 24;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(city: string[], cityName: string, theme: Theme) {
  return {
    fontWeight:
      city.indexOf(cityName) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function PersonalForm() {
  const theme = useTheme();
  const navigate = useNavigate();
  const editUpdatePermission = usePermission("change_dynamicformfields");
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const states = useSelector(personalFormSelector).personalFormReducer;
  const personalFormData = useSelector(
    (state: RootState) => state.personalFormReducer.personal_form_data
  );

  const updatePersonalFormData = useSelector(
    (state: RootState) => state.personalFormReducer.up_personal_form_data
  );

  const [gender, setGender] = useState(false);
  const [dob, setDOB] = useState(false);
  const [address, setAddress] = useState(false);
  const [countryField, setCountryField] = useState(false);
  const [departmentField, setDepartmentField] = useState(false);
  const [designationField, setDesignationField] = useState(false);
  const [experienceField, setExperienceField] = useState(false);
  const [street, setStreet] = useState(false);
  const [cityfield, setCityField] = useState(false);
  const [regionfield, setRegionField] = useState(false);

  const [gender2, setGender2] = useState(false);
  const [dob2, setDOB2] = useState(false);
  const [address2, setAddress2] = useState(false);
  const [countryField2, setCountryField2] = useState(false);
  const [departmentField2, setDepartmentField2] = useState(false);
  const [designationField2, setDesignationField2] = useState(false);
  const [experienceField2, setExperienceField2] = useState(false);
  const [street2, setStreet2] = useState(false);
  const [cityfield2, setCityField2] = useState(false);
  const [regionfield2, setRegionField2] = useState(false);

  useEffect(() => {
    if (states?.errors) {
      if (states?.errors) {
        dispatch(setPersonalFormData(null));
      }
    }
    if (states?.errors) {
      toast.error(String(states?.errors), {
        toastId: String(states?.errors),
      });
    }
  }, [states]);
  // getPersonalForm({ is_personal: 1 });

  useEffect(() => {
    dispatch(getPersonalForm({ is_personal: 1 }));
  }, []);

  useEffect(() => {
    if (personalFormData && Object.keys(personalFormData).length === 0) {
    } else {
      personalFormData &&
        personalFormData.forEach(
          (field: {
            field_name: string;
            is_mandatory: boolean;
            is_disable: boolean;
          }) => {
            if (field.field_name === "Gender") {
              setGender(field.is_mandatory);
              setGender2(field.is_disable);
            } else if (field.field_name === "Date of Birth") {
              setDOB(field.is_mandatory);
              setDOB2(field.is_disable);
            } else if (field.field_name === "Address") {
              setAddress(field.is_mandatory);
              setAddress2(field.is_disable);
            } else if (field.field_name === "Country") {
              setCountryField(field.is_mandatory);
              setCountryField2(field.is_disable);
            } else if (field.field_name === "Department") {
              setDepartmentField(field.is_mandatory);
              setDepartmentField2(field.is_disable);
            } else if (field.field_name === "Designation") {
              setDesignationField(field.is_mandatory);
              setDesignationField2(field.is_disable);
            } else if (field.field_name === "Experience Level") {
              setExperienceField(field.is_mandatory);
              setExperienceField2(field.is_disable);
            } else if (field.field_name === "Street Number") {
              setStreet(field.is_mandatory);
              setStreet2(field.is_disable);
            } else if (field.field_name === "City") {
              setCityField(field.is_mandatory);
              setCityField2(field.is_disable);
            } else if (field.field_name === "Region") {
              setRegionField(field.is_mandatory);
              setRegionField2(field.is_disable);
            }
          }
        );
    }
  }, [personalFormData]);

  const handleSubmit = () => {
    let personalFormDataforUpdate = [] as any;
    personalFormData.forEach(
      (field: { field_name: string; is_mandatory: boolean }) => {
        if (field.field_name === "Gender") {
          personalFormDataforUpdate = [
            ...personalFormDataforUpdate,
            { ...field, is_mandatory: gender, is_disable: gender2 },
          ];
        } else if (field.field_name === "Date of Birth") {
          personalFormDataforUpdate = [
            ...personalFormDataforUpdate,
            { ...field, is_mandatory: dob, is_disable: dob2 },
          ];
        } else if (field.field_name === "Address") {
          personalFormDataforUpdate = [
            ...personalFormDataforUpdate,
            { ...field, is_mandatory: address, is_disable: address2 },
          ];
        } else if (field.field_name === "Country") {
          personalFormDataforUpdate = [
            ...personalFormDataforUpdate,
            { ...field, is_mandatory: countryField, is_disable: countryField2 },
          ];
        } else if (field.field_name === "Department") {
          personalFormDataforUpdate = [
            ...personalFormDataforUpdate,
            {
              ...field,
              is_mandatory: departmentField,
              is_disable: departmentField2,
            },
          ];
        } else if (field.field_name === "Designation") {
          personalFormDataforUpdate = [
            ...personalFormDataforUpdate,
            {
              ...field,
              is_mandatory: designationField,
              is_disable: designationField2,
            },
          ];
        } else if (field.field_name === "Experience Level") {
          personalFormDataforUpdate = [
            ...personalFormDataforUpdate,
            {
              ...field,
              is_mandatory: experienceField,
              is_disable: experienceField2,
            },
          ];
        } else if (field.field_name === "Street Number") {
          personalFormDataforUpdate = [
            ...personalFormDataforUpdate,
            { ...field, is_mandatory: street, is_disable: street2 },
          ];
        } else if (field.field_name === "City") {
          personalFormDataforUpdate = [
            ...personalFormDataforUpdate,
            { ...field, is_mandatory: regionfield, is_disable: regionfield2 },
          ];
        } else if (field.field_name === "Region") {
          personalFormDataforUpdate = [
            ...personalFormDataforUpdate,
            { ...field, is_mandatory: regionfield, is_disable: regionfield2 },
          ];
        }
      }
    );
    const countryValue = personalFormDataforUpdate.find((obj: any) => {
      return obj.field_name === "Country";
    });
    let newPayload = [] as any;
    personalFormDataforUpdate.forEach(
      (field: { field_name: string; is_mandatory: boolean }) => {
        if (field.field_name === "Department") {
          newPayload = [
            ...newPayload,
            {
              ...field,
              is_mandatory: true,
              is_disable: false,
            },
          ];
        } else if (field.field_name === "Designation") {
          newPayload = [
            ...newPayload,
            {
              ...field,
              is_mandatory: true,
              is_disable: false,
            },
          ];
        } else if (field.field_name === "Experience Level") {
          newPayload = [
            ...newPayload,
            {
              ...field,
              is_mandatory: true,
              is_disable: false,
            },
          ];
        } else if (field.field_name === "City") {
          const countryValue = personalFormDataforUpdate.find((obj: any) => {
            return obj.field_name === "Country";
          });
          newPayload = [
            ...newPayload,
            {
              ...field,
              is_mandatory: countryValue?.is_mandatory,
              is_disable: countryValue?.is_disable,
            },
          ];
        } else if (field.field_name === "Region") {
          const countryValue = personalFormDataforUpdate.find((obj: any) => {
            return obj.field_name === "Country";
          });
          newPayload = [
            ...newPayload,
            {
              ...field,
              is_mandatory: countryValue?.is_mandatory,
              is_disable: countryValue?.is_disable,
            },
          ];
        } else if (field.field_name === "Street Number") {
          const AddressValue = personalFormDataforUpdate.find((obj: any) => {
            return obj.field_name === "Address";
          });
          newPayload = [
            ...newPayload,
            {
              ...field,
              is_mandatory: AddressValue?.is_mandatory,
              is_disable: AddressValue?.is_disable,
            },
          ];
        } else {
          newPayload = [
            ...newPayload,
            {
              ...field,
            },
          ];
        }
      }
    );

    dispatch(
      updatePersonalForm({
        updateData: newPayload,
      })
    );
  };

  const primaryButton =
    useColor("button_primary") || defaultThemeColor.button_primary;
  const primaryButtonText =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;

  const buttonSecondary =
    useColor("button_secondary") || defaultThemeColor.button_secondary;
  const buttonSecondaryText =
    useColor("button_secondary_text") ||
    defaultThemeColor.button_secondary_text;

  return (
    <div>
      <div
        className={`mt-5 ${Style.formStart}`}
        style={{
          background:
            useColor("interface_foreground_color") ||
            defaultThemeColor.interface_foreground_color,
          color:
            useColor("interface_foreground_text_color") ||
            defaultThemeColor.interface_foreground_text_color,
        }}
      >
        <h2
          style={{
            color:
              useColor("interface_foreground_text_color") ||
              defaultThemeColor.interface_foreground_text_color,
          }}
        >
          {t("Personal Detail Form")}
        </h2>
        <form className="mt-4">
          <div className="row">
            <div className={`col-6`}></div>
            <div
              className="col-3 mb-2 text-center"
              style={{
                color:
                  useColor("interface_foreground_text_color") ||
                  defaultThemeColor.interface_foreground_text_color,
              }}
            >
              {t("Mandatoy")}
            </div>
            <div
              className="col-3 text-center"
              style={{
                color:
                  useColor("interface_foreground_text_color") ||
                  defaultThemeColor.interface_foreground_text_color,
              }}
            >
              {t("Disable")}
            </div>

            <div
              className={`col-6 mb-5 ${Style.labelHeader}`}
              style={{
                color:
                  useColor("interface_foreground_text_color") ||
                  defaultThemeColor.interface_foreground_text_color,
                textAlign: lang === "ar" ? "right" : "left",
              }}
            >
              {t("Gender")}
              {/* <span className="color-red">*</span> */}
            </div>
            <div className="col-3">
              <div className={Style.checkStart}>
                <Checkbox
                  defaultChecked
                  checked={gender}
                  onClick={() => setGender(!gender)}
                />
              </div>
            </div>

            <div className="col-3">
              <div className={Style.checkStart}>
                <Checkbox
                  defaultChecked
                  checked={gender2}
                  onClick={() => setGender2(!gender2)}
                />
              </div>
            </div>

            <div
              className={`col-6 mb-5 ${Style.labelHeader}`}
              style={{
                color:
                  useColor("interface_foreground_text_color") ||
                  defaultThemeColor.interface_foreground_text_color,
                textAlign: lang === "ar" ? "right" : "left",
              }}
            >
              {t("Date of Birth")}
              {/* <span className="color-red">*</span> */}
            </div>

            <div className="col-3 text-center">
              <div className={Style.checkStart}>
                <Checkbox checked={dob} onClick={() => setDOB(!dob)} />
              </div>
            </div>

            <div className="col-3 text-center">
              <div className={Style.checkStart} style={{ paddingTop: "7px" }}>
                <Checkbox checked={dob2} onClick={() => setDOB2(!dob2)} />
              </div>
            </div>

            <div
              className={`col-6 mb-5 ${Style.labelHeader}`}
              style={{
                color:
                  useColor("interface_foreground_text_color") ||
                  defaultThemeColor.interface_foreground_text_color,
                textAlign: lang === "ar" ? "right" : "left",
              }}
            >
              {t("Address")}
              {/* <span className="color-red">*</span> */}
            </div>
            <div className="col-3">
              <div className={Style.checkStart}>
                <Checkbox
                  checked={address}
                  onClick={() => setAddress(!address)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className={Style.checkStart}>
                <Checkbox
                  checked={address2}
                  onClick={() => setAddress2(!address2)}
                />
              </div>
            </div>

            <div
              className={`col-6 mb-5 ${Style.labelHeader}`}
              style={{
                color:
                  useColor("interface_foreground_text_color") ||
                  defaultThemeColor.interface_foreground_text_color,
                textAlign: lang === "ar" ? "right" : "left",
              }}
            >
              {t("Country")}
              {/* <span className="color-red">*</span> */}
            </div>
            <div className="col-3">
              <div className={Style.checkStart}>
                <Checkbox
                  checked={countryField}
                  onClick={() => setCountryField(!countryField)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className={Style.checkStart}>
                <Checkbox
                  checked={countryField2}
                  onClick={() => setCountryField2(!countryField2)}
                />
              </div>
            </div>

            <span
              className={`col-6 mb-5 ${Style.labelHeader}`}
              style={{
                color:
                  useColor("interface_foreground_text_color") ||
                  defaultThemeColor.interface_foreground_text_color,
                textAlign: lang === "ar" ? "right" : "left",
              }}
            >
              {t("Department")}
              {/* <span className="color-red">*</span> */}
            </span>
            <div className="col-3">
              <div className={Style.checkStart}>
                <Checkbox
                  defaultChecked={true}
                  disabled={true}
                  // checked={departmentField}
                  onClick={() => setDepartmentField(!departmentField)}
                />
              </div>
            </div>

            <div className="col-3">
              <div className={Style.checkStart}>
                <Checkbox
                  defaultChecked={false}
                  disabled={true}
                  checked={departmentField2}
                  onClick={() => setDepartmentField2(!departmentField2)}
                />
              </div>
            </div>

            <span
              className={`col-6 mb-5 ${Style.labelHeader}`}
              style={{
                color:
                  useColor("interface_foreground_text_color") ||
                  defaultThemeColor.interface_foreground_text_color,
                textAlign: lang === "ar" ? "right" : "left",
              }}
            >
              {t("Designation")}
              {/* <span className="color-red">*</span> */}
            </span>
            <div className="col-3">
              <div className={Style.checkStart}>
                <Checkbox
                  defaultChecked={true}
                  disabled={true}
                  // checked={designationField}
                  onClick={() => setDesignationField(!designationField)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className={Style.checkStart}>
                <Checkbox
                  defaultChecked={false}
                  disabled={true}
                  // checked={designationField2}
                  onClick={() => setDesignationField2(!designationField2)}
                />
              </div>
            </div>

            <div
              className={`col-6 ${Style.labelHeader}`}
              style={{
                color:
                  useColor("interface_foreground_text_color") ||
                  defaultThemeColor.interface_foreground_text_color,
                textAlign: lang === "ar" ? "right" : "left",
              }}
            >
              {t("Experience Level")}
              {/* <span className="color-red">*</span> */}
            </div>
            <div className="col-3">
              <div className={Style.checkStart}>
                <Checkbox
                  defaultChecked={true}
                  disabled={true}
                  // checked={experienceField}
                  onClick={() => setExperienceField(!experienceField)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className={Style.checkStart}>
                <Checkbox
                  defaultChecked={false}
                  disabled={true}
                  // checked={experienceField2}
                  onClick={() => setExperienceField2(!experienceField2)}
                />
              </div>
            </div>
          </div>
          {editUpdatePermission && (
            <div
              className={`d-flex gap-3 ${Style.buttuns}`}
              style={{
                justifyContent: lang === "ar" ? "flex-start" : "flex-end",
              }}
            >
              <button
                className={`${Style.btn} ${Style.btnPrimary}`}
                type="button"
                onClick={() => handleSubmit()}
                style={{
                  backgroundColor: primaryButton,
                  color: primaryButtonText,
                }}
              >
                {t("Save")}
              </button>
              <CancelButton
                size="small"
                outlined={true}
                onClick={() => {
                  navigate("/");
                  setTimeout(() => {
                    navigate("/user-registration");
                  }, 100);
                }}
                textColor=""
                outlineColor={""}
                text={t("Cancel")}
                className={``}
                bg={""}
              ></CancelButton>
              {/* <button
                className={`${Style.btn} ${Style.btnSecond}`}
                style={{
                  backgroundColor: buttonSecondary,
                  color: buttonSecondaryText,
                }}
              >
                {t("Cancel")}
              </button> */}
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default PersonalForm;
