// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProgressReport_background__OpdWx .ProgressReport_bottomSpace__Yjayr .ProgressReport_rightBorderProgress__yzSjx {
  border-right: 1.5px solid #e6e6e6;
  height: 100px;
}
.ProgressReport_background__OpdWx .ProgressReport_bottomSpace__Yjayr .ProgressReport_rightBorderProgress__yzSjx .ProgressReport_perText__fHvFr {
  color: #a3aed0;
  font-weight: 500;
}
.ProgressReport_background__OpdWx .ProgressReport_bottomSpace__Yjayr .ProgressReport_rightBorderProgress__yzSjx .ProgressReport_perText__fHvFr .ProgressReport_num__TtPnU {
  font-size: 2rem;
  color: #2b3674;
  font-weight: 700;
}
.ProgressReport_background__OpdWx .ProgressReport_bottomSpace__Yjayr .ProgressReport_rightBorder__Vzmge {
  border-right: 1.5px solid #e6e6e6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
}
.ProgressReport_background__OpdWx .ProgressReport_bottomSpace__Yjayr .ProgressReport_rightBorder__Vzmge .ProgressReport_perText__fHvFr {
  color: #a3aed0;
  font-weight: 500;
}
.ProgressReport_background__OpdWx .ProgressReport_bottomSpace__Yjayr .ProgressReport_rightBorder__Vzmge .ProgressReport_num__TtPnU {
  font-size: 2rem;
  color: #2b3674;
  font-weight: 700;
}
.ProgressReport_background__OpdWx .ProgressReport_bottomSpace__Yjayr .ProgressReport_head__rHoNF {
  font-size: 24px;
  font-weight: 700;
  color: #2b3674;
  line-height: 22px;
}
.ProgressReport_background__OpdWx .ProgressReport_bottomSpace__Yjayr .ProgressReport_timer__GwCO\\+ {
  font-size: 20px;
  font-weight: 700;
  color: #2b3674;
  line-height: 22px;
}
.ProgressReport_background__OpdWx .ProgressReport_bottomSpace__Yjayr .ProgressReport_question__ymwGh {
  color: #2f2f2f;
  font-weight: 700;
  line-height: 22px;
}
.ProgressReport_background__OpdWx .ProgressReport_bottomSpace__Yjayr .ProgressReport_mcqOptions__C0pUO .ProgressReport_optionLabel__7JxGG {
  color: #2f2f2f;
  padding-left: 1rem;
  font-weight: 400;
}
.ProgressReport_background__OpdWx .ProgressReport_bottomSpace__Yjayr .ProgressReport_mcqOptions__C0pUO .ProgressReport_optionLabelRed__YMrXV {
  color: red;
  padding-left: 1rem;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/pages/training/ProgressReport.module.scss"],"names":[],"mappings":"AAEI;EACE,iCAAA;EACA,aAAA;AADN;AAEM;EACE,cAAA;EACA,gBAAA;AAAR;AACQ;EACE,eAAA;EACA,cAAA;EACA,gBAAA;AACV;AAGI;EACE,iCAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AADN;AAEM;EACE,cAAA;EACA,gBAAA;AAAR;AAEM;EACE,eAAA;EACA,cAAA;EACA,gBAAA;AAAR;AAGI;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,iBAAA;AADN;AAGI;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,iBAAA;AADN;AAGI;EACE,cAAA;EACA,gBAAA;EACA,iBAAA;AADN;AAIM;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;AAFR;AAIM;EACE,UAAA;EACA,kBAAA;EACA,gBAAA;AAFR","sourcesContent":[".background {\n  .bottomSpace {\n    .rightBorderProgress {\n      border-right: 1.5px solid #e6e6e6;\n      height: 100px;\n      .perText {\n        color: #a3aed0;\n        font-weight: 500;\n        .num {\n          font-size: 2rem;\n          color: #2b3674;\n          font-weight: 700;\n        }\n      }\n    }\n    .rightBorder {\n      border-right: 1.5px solid #e6e6e6;\n      display: flex;\n      flex-direction: column;\n      justify-content: center;\n      align-items: center;\n      height: 100px;\n      .perText {\n        color: #a3aed0;\n        font-weight: 500;\n      }\n      .num {\n        font-size: 2rem;\n        color: #2b3674;\n        font-weight: 700;\n      }\n    }\n    .head {\n      font-size: 24px;\n      font-weight: 700;\n      color: #2b3674;\n      line-height: 22px;\n    }\n    .timer {\n      font-size: 20px;\n      font-weight: 700;\n      color: #2b3674;\n      line-height: 22px;\n    }\n    .question {\n      color: #2f2f2f;\n      font-weight: 700;\n      line-height: 22px;\n    }\n    .mcqOptions {\n      .optionLabel {\n        color: #2f2f2f;\n        padding-left: 1rem;\n        font-weight: 400;\n      }\n      .optionLabelRed {\n        color: red;\n        padding-left: 1rem;\n        font-weight: 400;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": `ProgressReport_background__OpdWx`,
	"bottomSpace": `ProgressReport_bottomSpace__Yjayr`,
	"rightBorderProgress": `ProgressReport_rightBorderProgress__yzSjx`,
	"perText": `ProgressReport_perText__fHvFr`,
	"num": `ProgressReport_num__TtPnU`,
	"rightBorder": `ProgressReport_rightBorder__Vzmge`,
	"head": `ProgressReport_head__rHoNF`,
	"timer": `ProgressReport_timer__GwCO+`,
	"question": `ProgressReport_question__ymwGh`,
	"mcqOptions": `ProgressReport_mcqOptions__C0pUO`,
	"optionLabel": `ProgressReport_optionLabel__7JxGG`,
	"optionLabelRed": `ProgressReport_optionLabelRed__YMrXV`
};
export default ___CSS_LOADER_EXPORT___;
