import { BarElement, CategoryScale, Chart as ChartJS, LinearScale, Title, Tooltip } from "chart.js";
import React from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

interface HorizontalChartProps {
  chartData: Chart.ChartData;
  chartOptions: Chart.ChartOptions;
}

const HorizontalChart: React.FC<HorizontalChartProps> = ({ chartData, chartOptions }) => {
  return <Bar options={chartOptions as any} data={chartData as any} />;
};

export default HorizontalChart;
