import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import DataTable from "../../components/table/DataTable";
import TableFilters from "../../components/table/TableFilters";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import usePermission from "../../hooks/usePermission";
import ViewSVGAction from "../../icons/ViewSVGAction";
import { deleteCategory } from "../../services/categoryService";
import {
  getDetailData,
  getQuestion,
  getWholeDetailData,
} from "../../services/questionsService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { setWholeDetail } from "../../store/reducers/questionReducer";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import { confirmProps } from "../../utils/confirmProps";
import Style from "../category/Category.module.scss";

function QuestionLink() {
  const { id } = useParams();

  const navigate = useNavigate();
  const confirm = useConfirm();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const addPermission = usePermission("add_certificate");
  const editUpdatePermission = usePermission("change_category");
  const deletePermission = usePermission("delete_category");
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<number[]>([]);
  const dataList: any = useAppSelector((state) => state.questionReducer.data);
  const detail = useAppSelector((state) => state.questionReducer.questionData);
  const wholeData = useAppSelector(
    (state) => state.questionReducer.wholeDetail
  );
  const [pageNum, setPageNum] = useState(Number(1));
  const [perPageNum, setPerPageNum] = useState(10);
  const [pageSearch, setPageSearch] = useState("");

  const [list, setList] = useState([]);

  const alertMessage = useAppSelector(alertMessageSelector).alertMessageReducer;
  useEffect(() => {
    dispatch(getQuestion({ id: id }));
  }, [id]);
  useEffect(() => {
    AllData();
  }, [pageNum, perPageNum, pageSearch]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }

    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));

      AllData();
    }
  }, [alertMessage]);

  const AllData = () => {
    let query: any = {};
    query.id = Number(id);
    query.page = Number(pageNum);

    query.page_size = Number(perPageNum);

    query.search = String(pageSearch);
    dispatch(getDetailData(query));
  };

  const handleDeleteAction = (row: any) => {
    confirm({
      title: t("Are you sure!"),
      description: `Do you want to delete ${row?.name} category?`,
      ...confirmProps,
    })
      .then(() => {
        dispatch(deleteCategory(Number(row?.id)));
      })
      .catch(() => {});
    return;
  };
  const modifyRows = () => {
    let f_rows: any = [];
    dataList?.results?.forEach(
      (
        r: {
          id: string;
          name: string;
          instance_type: string;
          created_at: string;
        },
        i: number
      ) => {
        f_rows = [
          ...f_rows,
          {
            id: r.id,
            name: r.name,
            associated_to: r?.instance_type,
            created_at:
              moment(new Date(r.created_at)).format("MMM Do, YYYY") || "",

            actions: (
              <>
                <div style={{ textAlign: "center", paddingLeft: "1em" }}>
                  <button
                    className="btn-link"
                    type="button"
                    onClick={(e) => {
                      handleNavigation(r);
                    }}
                  >
                    <ViewSVGAction />
                  </button>
                </div>
              </>
            ),
          },
        ];
      }
    );
    setList(f_rows);
  };
  // useEffect(() => {
  //   let data: any = dataList.results;
  //   if (Object.keys(dataList).length === 0) {
  //     setRows([]);
  //   } else setRows(data);
  // }, [dataList]);
  useEffect(() => {
    modifyRows();
  }, [dataList]);

  const handleNavigation = (row: any) => {
    if (row?.instance_type?.toLowerCase() === "assessment") {
      navigate(`/assessments/assessment-link/${row?.id}`);
      return;
    } else if (row?.instance_type?.toLowerCase() === "module") {
      navigate(`/module-link/${row?.id}`);
      return;
    } else if (row?.instance_type?.toLowerCase() === "training") {
      navigate(`/detail-training/view/${row?.id}`);
      return;
    }
    return;
    // alert("Pending...");
  };
  const handleChangePage = (event: React.ChangeEvent<unknown>, val: number) => {
    setPageNum(Number(val));
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPageNum(1);
    setPerPageNum(Number(rowsPerPage));
  };
  const handleChangeSearch = (val: string) => {
    setPageNum(1);
    setPageSearch(String(val));
    setPerPageNum(Number(10));
  };

  const handleClicked = () => {
    getWhole();
  };

  const getWhole = () => {
    if (dataList)
      dispatch(
        getWholeDetailData({
          id: id,
          page_size: dataList.count,
          page: 1,
          search: "",
        })
      );
  };

  return (
    <div className={`${Style.categoryStart}`}>
      <div style={{ paddingBottom: "0.6em" }}>
        <Header
          page={t("Question Detail")}
          mainHead={t("Question Library")}
          backLink="/questions-library"
        />
        <h6
          className="mb-3"
          style={{
            color:
              useColor("interface_background_text_color") ||
              defaultThemeColor.interface_background_text_color,
          }}
        >
          Q: {detail?.question_text}
        </h6>
        <TableFilters
          dataList={dataList}
          itemPerPage={Number(perPageNum)}
          selectedCheckBox={selectedCheckBox}
          setItemPerPage={handleChangeRowsPerPage}
          setSearchTable={handleChangeSearch}
          handle="category"
          isCheckboxes={false}
          exportCSV={dataList?.count ? true : false}
          setParentData={() => dispatch(setWholeDetail({}))}
          handleClicked={handleClicked}
          rows={wholeData?.results?.map((r: any, i: any) => ({
            // id: r.id,
            name: r.name,
            associated_to: r?.instance_type,
            created_at:
              moment(new Date(r.created_at)).format("MMM Do, YYYY") || "",
          }))}
          headers={["Name", "Associated to", "Date Created"]}
          filename={`question_id_${id}__associations.csv`}
        />

        <DataTable
          columnData={[
            {
              id: "name",
              name: t("Name"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "checkbox",
            },
            {
              id: "associated_to",
              name: t("Associated To"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "created_at",
              name: t("Date Created"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "action",
              name: t("Actions"),
              enableSort: false,
              align: "center",
              disablePadding: "none",
            },
          ]}
          rows={list}
          isCheckboxes={false}
          selectedRowsPerPage={String(perPageNum)}
          totalRecordCount={dataList.count}
          handleChangePageParent={handleChangePage}
          pagenum={Number(pageNum)}
          handleChangeRowsPerPageParent={handleChangeRowsPerPage}
          selectedCheckBox={selectedCheckBox}
          setSelectedCheckBox={setSelectedCheckBox}
        />
      </div>
    </div>
  );
}

export default QuestionLink;
