import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  styled,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useTranslation } from "react-multi-lang";
import useColor from "../../hooks/useColor";
import DeleteSVG from "../../icons/DeleteSVG";
import GridViewSVG from "../../icons/GridViewSVG";
import ListViewSVG from "../../icons/ListViewSVG";
import SearchSVG from "../../icons/SearchSVG";
import { defaultThemeColor } from "../../utils/colors_values";
import ExportCSV from "../ExportCSV/ExportCSV";
import Style from "./TableFilters.module.scss";

const SearchTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#A0AAB4",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& #custom-css-outlined-input": {
    zIndex: 1,
    maxWidth: "10em",
    padding: 9,
    paddingLeft: 2,
    marginRight: "1em",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      backgroundColor: "white",
      border: "1px solid #CECECE",
      borderRadius: 50,
      fontFamily: "dmSans",
      fontSize: "14px",
      fontWeight: 400,
      letterSpacing: "-0.0175rem",
      width: "14rem",
      zIndex: 0,
      borderColor: "#B2BAC2",
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6F7E8C",
    },
  },
});

interface TableFiltersType {
  dataList: any;
  itemPerPage: number;
  setItemPerPage: any;
  selectedCheckBox?: any;
  bulkDelete?: any;
  setSearchTable: (name: string) => void;
  createButton?: any;
  handle: string;
  setView?: any;
  view?: string;
  isCheckboxes?: boolean;
  exportCSV?: any;
  rows?: any;
  handleClicked?: any;
  setParentData?: any;
  headers?: any;
  filename?: any;
}

const TableFilters = ({
  dataList,
  itemPerPage,
  setItemPerPage,
  selectedCheckBox,
  bulkDelete,
  setSearchTable,
  createButton,
  handle,
  setView,
  view,
  isCheckboxes = false,
  exportCSV = false,
  rows,
  handleClicked,
  setParentData,
  headers,
  filename,
}: TableFiltersType) => {
  const t = useTranslation();
  const BgTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;
  const BgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;

  return (
    <div
      className={`d-flex justify-content-between flex-wrap gap-2 mb-3 ${Style.tableFiltersStart}`}
    >
      <div className="d-flex justify-content-center align-items-center gap-3">
        <FormControl
          className={
            handle === "addModules" ||
            handle === "addMembers" ||
            handle === "addAssessments"
              ? "d-none"
              : ""
          }
          variant="standard"
          sx={{ minWidth: 67 }}
        >
          <Select
            labelId="demo-simple-select-autowidth-label"
            style={{ color: BgTextColor }}
            id="demo-simple-select-autowidth"
            value={itemPerPage}
            onChange={(e) => {
              setItemPerPage(e.target.value as string);
            }}
            autoWidth
            disableUnderline
            sx={{
              marginTop: "0px !important",
              ".MuiSvgIcon-root ": {
                fill: BgTextColor,
              },
            }}
            MenuProps={{ PaperProps: { style: { background: BgColor } } }}
          >
            <MenuItem
              // style={{ color: BgTextColor, background: BgColor }}
              value={dataList?.count}
            >
              {t("All")} ({dataList?.count || 0})
            </MenuItem>
            <MenuItem
              // style={{ color: BgTextColor, background: BgColor }}
              value="10"
            >
              10 {t("Per Page")}
            </MenuItem>
            <MenuItem
              // style={{ color: BgTextColor, background: BgColor }}
              value="15"
            >
              15 {t("Per Page")}
            </MenuItem>
            <MenuItem
              // style={{ color: BgTextColor, background: BgColor }}
              value="25"
            >
              25 {t("Per Page")}
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 80 }}>{createButton}</FormControl>
      </div>

      <div className="d-flex justify-content-center align-items-center gap-3">
        {exportCSV && (
          <div className={handle == "addAssessments" ? "px-4" : "px-1"}>
            <ExportCSV
              setParentData={() => setParentData()}
              handleClicked={handleClicked}
              rows={rows}
              headers={headers}
              filename={filename}
            />
          </div>
        )}

        {isCheckboxes && (
          <Button
            variant="text"
            color="inherit"
            disabled={selectedCheckBox?.length > 0 ? false : true}
            onClick={(e: any) => {
              bulkDelete(selectedCheckBox);
            }}
            className={`d-flex gap-2 align-items-center justify-content-center ${
              selectedCheckBox.length > 0
                ? Style.removeBtn
                : Style.disabledBorder
            }`}
          >
            <DeleteSVG
              color={
                selectedCheckBox.length > 0 ? "#FF0000" : "rgba(0, 0, 0, 0.26)"
              }
            />
            {t("Remove")}
          </Button>
        )}
        {handle == "image-library" && (
          <div className="">
            <IconButton
              sx={{
                m: 0,
                p: 0,
                mr: 0,
                mt: 0.5,
              }}
              onClick={() => {
                setView("grid");
              }}
            >
              <GridViewSVG
                width={27}
                height={27}
                bg={view == "grid" ? "#A1AEB5" : "white"}
                color={view == "grid" ? "white" : "#A1AEB5"}
              ></GridViewSVG>
            </IconButton>
            <IconButton
              sx={{
                m: 0,
                p: 0,
                mr: 1,
                mt: 0.5,
              }}
              onClick={() => {
                setView("list");
              }}
            >
              <ListViewSVG
                width={27}
                height={27}
                bg={view == "list" ? "#A1AEB5" : "white"}
                color={view == "list" ? "white" : "#A1AEB5"}
              ></ListViewSVG>
            </IconButton>
          </div>
        )}

        <SearchTextField
          className={
            handle === "addAssessments"
              ? "d-none"
              : `${handle == "group-member" && "member-search"}`
          }
          autoComplete="off"
          placeholder={t("Search")}
          onKeyUp={(e: any) => setSearchTable(e.target.value)}
          id="custom-css-outlined-input"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ pl: 1 }}>
                <SearchSVG />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: false,
          }}
        />
      </div>
    </div>
  );
};

export default TableFilters;
