import ImportExportTwoToneIcon from "@mui/icons-material/ImportExportTwoTone";
import SyncTwoToneIcon from "@mui/icons-material/SyncTwoTone";
import { Button } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { useConfirm } from "material-ui-confirm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import DataTable from "../../components/table/DataTable";
import TableFilters from "../../components/table/TableFilters";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import {
  getActiveDirectoryUser,
  syncActiveDirectoryUser,
  updateBulkADUserRole,
} from "../../services/userService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import Style from "./ActiveDirectoryUser.module.scss";
import ActiveDirectoryUserAssignRoleModel from "./ActiveDirectoryUserAssignRoleModel";

const ITEM_HEIGHT = 24;
const ITEM_PADDING_TOP = 0;

function ActiveDirectoryUser() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  const [openActiveDirectoryUserModel, setOpenActiveDirectoryUserModel] =
    React.useState<any>(false);
  const [saveActiveDirectoryUserModel, setSaveActiveDirectoryUserModel] =
    React.useState<any>(false);
  const [rolesStateActiveDirectoryUser, setRolesStateActiveDirectoryUser] =
    useState("");

  const theme = useTheme();

  const [itemPerPage, setItemPerPage] = useState<number>(10);

  const [searchTable, setSearchTable] = useState("");
  const [rolesState, setRolesState] = useState("");
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<any>([]);
  // const [page, setPage] = useState(1);

  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;
  const [rows, setRows] = React.useState<any[]>([]);
  const [notShowCheckboxes, setNotShowCheckboxesArray] = React.useState<any[]>(
    []
  );
  const [isSync, setSync] = React.useState(false);

  const dataList = useSelector(
    (state: RootState) => state.userReducer.active_directory_users
  );
  useEffect(() => {
    console.log(
      "Submit",
      selectedCheckBox?.length,
      selectedCheckBox,
      rolesStateActiveDirectoryUser,
      saveActiveDirectoryUserModel
    );

    if (
      saveActiveDirectoryUserModel &&
      selectedCheckBox?.length > 0 &&
      rolesStateActiveDirectoryUser != ""
    ) {
      dispatch(
        updateBulkADUserRole(selectedCheckBox, rolesStateActiveDirectoryUser)
      );
    }
  }, [
    saveActiveDirectoryUserModel,
    selectedCheckBox,
    rolesStateActiveDirectoryUser,
  ]);
  useEffect(() => {
    setPage(1);
  }, [searchTable]);

  useEffect(() => {
    dispatch(
      getActiveDirectoryUser({
        page_size: Number(itemPerPage),
        page: Number(page),
        search: searchTable,
      })
    );
  }, [itemPerPage, page, searchTable]);

  useEffect(() => {
    let data: any = dataList.results;
    const notShowCheckboxesArray = data
      ?.filter((r: any) => r.user != null)
      .map((obj: any) => obj.id);
    setNotShowCheckboxesArray(notShowCheckboxesArray);
    console.log("notShowCheckboxesArray", notShowCheckboxesArray);
    if (Object.keys(dataList).length === 0) {
      setRows([]);
    } else {
      setRows(data);
      if (!(itemPerPage == 10 || itemPerPage == 15 || itemPerPage == 25)) {
        setItemPerPage(dataList?.count);
      }
    }
  }, [dataList]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      setSync(false);
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      setSync(false);
      setSaveActiveDirectoryUserModel(false);
      dispatch(setSuccess(null));
      setSelectedCheckBox([]);
      setRolesStateActiveDirectoryUser("");
      setOpenActiveDirectoryUserModel(false);
      dispatch(
        getActiveDirectoryUser({
          page_size: Number(itemPerPage),
          page: Number(page),
          search: searchTable,
        })
      );
    }
  }, [alertMessage]);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    // setSelectedCheckBox([]);
    setPage(page);
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPage(1);
    // setSelectedCheckBox([]);
    setItemPerPage(Number(rowsPerPage));
  };

  // const handleClose = () => {
  //   setParentOpen(false);
  // };

  useEffect(() => {
    modifyRows();
  }, [rows]);

  const modifyRows = () => {
    let f_rows: any = [];
    rows.forEach(
      (
        r: {
          id: string;
          first_name: string;
          last_name: string;
          email: any;
          user: any;
          phone_no: string;
        },
        i: number
      ) => {
        f_rows = [
          ...f_rows,
          {
            id: r.id,
            first_name: r?.first_name ? r?.first_name + " " + r?.last_name : "N/A",
            role: r?.user?.primary_role ? r?.user?.primary_role : "N/A",
            email: r?.email ? r?.email : "N/A",
            phone_no: r?.phone_no ? r?.phone_no : "N/A",
          },
        ];
      }
    );
    setList(f_rows);
  };

  const handleClose = () => {
    setSelectedCheckBox([]);
    setSearchTable("");
    setRolesStateActiveDirectoryUser("");
  };

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;
  const primaryButton =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;
  const primaryButtonBg =
    useColor("button_primary") || defaultThemeColor.button_primary;

  const MenuPropsCountry = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 11.5 + ITEM_PADDING_TOP,
        width: 250,
        color: interfaceTextColor,
        background: interfaceBgColor,
      },
    },
  };
  useEffect(() => {
    if (isSync) {
      dispatch(syncActiveDirectoryUser());
    }
  }, [isSync]);
  const SyncADUsers = () => {
    const handleSyncUsers = () => {
      setSync(true);
    };

    const handleAssignADUserRole = () => {
      setOpenActiveDirectoryUserModel(true);
    };

    return (
      <div className="d-flex flex-row gap-3">
        <>
          <Button
            variant="text"
            color="inherit"
            endIcon={
              <SyncTwoToneIcon
                style={{
                  fontSize: 16,
                  color: interfaceTextColor,
                  marginRight: lang === "ar" ? "10px" : "0px",
                }}
              />
            }
            disabled={loading}
            style={{
              fontSize: 13,
              color: interfaceTextColor,
              textTransform: "capitalize",
              width: lang === "ar" ? "135px" : "120px",
            }}
            onClick={() => handleSyncUsers()}
          >
            {isSync ? t("Syncing..") : t("Sync Users")}
          </Button>
          <Button
            variant={selectedCheckBox?.length == 0 ? "text" : "outlined"}
            color="primary"
            endIcon={
              <ImportExportTwoToneIcon
                style={{
                  fontSize: 16,
                  color:
                    selectedCheckBox?.length == 0
                      ? "rgba(0, 0, 0, 0.26)"
                      : interfaceTextColor,
                  marginRight: lang === "ar" ? "10px" : "0px",
                }}
              />
            }
            disabled={
              isSync || loading
                ? true
                : selectedCheckBox?.length == 0
                ? true
                : false
            }
            style={{
              fontSize: 13,
              color:
                selectedCheckBox?.length == 0
                  ? "rgba(0, 0, 0, 0.26)"
                  : interfaceTextColor,
              textTransform: "capitalize",
              padding: selectedCheckBox?.length == 0 ? "5px 15px" : "5px 15px",
              border:
                selectedCheckBox?.length == 0
                  ? "1px solid rgba(0, 0, 0, 0.26)"
                  : "1px solid ",
              borderRadius: selectedCheckBox?.length == 0 ? "36px" : "36px",
            }}
            // className="btn btn-primary"
            onClick={() => handleAssignADUserRole()}
          >
            {t("Import Users")}
          </Button>
        </>
      </div>
    );
  };
  return (
    <div className={Style.pageStart}>
      <Header
        page={t("Import Active Directory User(s)")}
        mainHead={t("Import Active Directory User(s)")}
      />

      <TableFilters
        dataList={dataList}
        itemPerPage={itemPerPage}
        selectedCheckBox={selectedCheckBox}
        bulkDelete={() => {}}
        setItemPerPage={handleChangeRowsPerPage}
        setSearchTable={setSearchTable}
        createButton={<SyncADUsers />}
        handle="activeDirectoryUser"
        isCheckboxes={false}
        exportCSV={false}
      />
      <DataTable
        columnData={[
          {
            id: "first_name",
            name: t("Username"),
            enableSort: true,
            align: lang === "ar" ? "center" : "left",
            disablePadding: "none",
          },

          {
            id: "role",
            name: t("Role"),
            enableSort: true,
            align: lang === "ar" ? "center" : "left",
            disablePadding: "none",
          },

          {
            id: "email",
            name: t("Email"),
            enableSort: true,
            align: lang === "ar" ? "center" : "left",
            disablePadding: "none",
          },
          {
            id: "phone_no",
            name: t("Phone No"),
            enableSort: true,
            align: lang === "ar" ? "center" : "left",
            disablePadding: "none",
          },
        ]}
        rows={list}
        isCheckboxes={true}
        selectedRowsPerPage={itemPerPage.toString()}
        totalRecordCount={Number(dataList.count)}
        handleChangePageParent={handleChangePage}
        pagenum={Number(page)}
        handleChangeRowsPerPageParent={handleChangeRowsPerPage}
        selectedCheckBox={selectedCheckBox}
        setSelectedCheckBox={setSelectedCheckBox}
        rowsScroll={false}
        minHeight={"20em"}
        displayForceBottomComponent={true}
      />
      <ActiveDirectoryUserAssignRoleModel
        setParentOpen={setOpenActiveDirectoryUserModel}
        open={openActiveDirectoryUserModel}
        onClose={handleClose}
        selectedRows={selectedCheckBox}
        save={saveActiveDirectoryUserModel}
        setSave={setSaveActiveDirectoryUserModel}
        setRolesState={setRolesStateActiveDirectoryUser}
        rolesState={rolesStateActiveDirectoryUser}
      />
    </div>
  );
}

export default ActiveDirectoryUser;
