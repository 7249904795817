import { ErrorMessage } from "@hookform/error-message";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import PasswordStrengthBar from "react-password-strength-bar";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "yup-phone-lite";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import SignupImage from "../../images/Signup.svg";
import { getSignUpFields, signUp } from "../../services/authService";
import {
  authSelector,
  isForgotVerified,
  isVerified,
  setAuthFailed,
  setAuthSignUpData,
  setAuthSignUpFailed,
  updatedPassword,
} from "../../store/reducers/authReducer";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import Footer from "./Footer";
import Header from "./Header";
import Style from "./Signup.module.scss";

function Signup() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const states = useSelector(authSelector).authReducer;
  const signData = useSelector(
    (state: RootState) => state.authReducer.singup_data
  );
  const formFields = useSelector(
    (state: RootState) => state.authReducer.signUpFields
  );
  const isLoading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  const [phoneValue, setPhoneValue] = useState<any>("");
  const [feilds, setFields] = useState<any>([]);
  const [schemaValidate, setSchemaValidate] = useState<any>({});
  const [fieldTypePassword, setFieldTypePassword] = useState<any>("password");
  const [fieldTypeConfirmPassword, setFieldTypeConfirmPassword] =
    useState<any>("password");
  useEffect(() => {
    dispatch(getSignUpFields());
  }, [dispatch]);

  useEffect(() => {
    const valObj: any = {};
    formFields?.map((row: any) => {
      if (
        row?.field_label === "company_business_name" &&
        row?.is_mandatory === true
      ) {
        valObj.company_business_name = "";
      }
      if (row?.field_label === "first_name" && row?.is_mandatory === true) {
        valObj.first_name = "";
      }
      if (row?.field_label === "last_name" && row?.is_mandatory === true) {
        valObj.last_name = "";
      }
      if (row?.field_label === "email_address" && row?.is_mandatory === true) {
        valObj.email_address = "";
      }
      if (row?.field_label === "phone_no" && row?.is_mandatory === true) {
        valObj.phone_no = "";
      }
      if (
        row?.field_label === "confirm_password" &&
        row?.is_mandatory === true
      ) {
        valObj.confirm_password = "";
      }
      if (row?.field_label === "password" && row?.is_mandatory === true) {
        valObj.password = "";
      }
    });
    setSchemaValidate(valObj);
    setFields(formFields);
  }, [formFields]);
  useEffect(() => {}, [feilds]);
  const {
    control,
    watch,
    setValue,
    setError,
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: schemaValidate,
  });

  useEffect(() => {
    if (watch("phone_no") && "phone_no" in schemaValidate) {
      if (!isValidPhoneNumber(watch("phone_no"))) {
        setError("phone_no", {
          type: "server",
          message: "Invalid Phone number",
        });
        return;
      } else {
        clearErrors("phone_no");
      }
    }
  }, [watch("phone_no")]);

  const onSubmit = (data: any) => {
    toast.dismiss();
    if ("phone_no" in data && "phone_no" in schemaValidate) {
      if (!isValidPhoneNumber(data?.phone_no)) {
        setError("phone_no", {
          type: "server",
          message: "Invalid Phone number",
        });
        return;
      } else {
        clearErrors("phone_no");
      }
    }
    dispatch(signUp(data));
  };
  useEffect(() => {
    if (phoneValue) {
      setValue("phone_no", "+" + String(phoneValue));
    } else {
      setValue("phone_no", "");
    }
  }, [phoneValue, setValue]);
  useEffect(() => {
    localStorage.removeItem("signup_data");
    localStorage.removeItem("reset_password_token");
    dispatch(updatedPassword(false));
    dispatch(isForgotVerified(false));
    dispatch(isVerified(false));
    dispatch(setAuthSignUpData(null));
  }, [dispatch]);

  useEffect(() => {
    if (signData && signData?.email?.length > 0) {
      navigate("/verify");
    }
  }, [signData]);
  useEffect(() => {
    if (states?.errors) {
      if (states?.errors?.first_name?.length > 0) {
        toast.error(
          String("First name: " + states?.errors?.first_name?.toString()),
          {
            toastId: String(
              "First name: " + states?.errors?.first_name?.toString()
            ),
          }
        );
        setError("first_name", {
          type: "server",
          message: states?.errors?.first_name?.toString(),
        });
      }
      if (states?.errors?.last_name?.length > 0) {
        toast.error(
          String("Last name: " + states?.errors?.last_name?.toString()),
          {
            toastId: String(
              "Last name: " + states?.errors?.last_name?.toString()
            ),
          }
        );
        setError("last_name", {
          type: "server",
          message: states?.errors?.last_name?.toString(),
        });
      }
      if (states?.errors?.email?.length > 0) {
        toast.error(String("Email: " + states?.errors?.email?.toString()), {
          toastId: String("Email: " + states?.errors?.email?.toString()),
        });
        setError("email_address", {
          type: "server",
          message: states?.errors?.email?.toString(),
        });
      }
      if (states?.errors?.phone_no?.length > 0) {
        toast.error(String("Phone: " + states?.errors?.phone_no?.toString()), {
          toastId: String("Phone: " + states?.errors?.phone_no?.toString()),
        });
        setError("phone_no", {
          type: "server",
          message: states?.errors?.phone?.toString(),
        });
      }
      if (states?.errors?.password?.length > 0) {
        toast.error(
          String("Password: " + states?.errors?.password?.toString()),
          {
            toastId: String(
              "Password: " + states?.errors?.password?.toString()
            ),
          }
        );
        setError("password", {
          type: "server",
          message: states?.errors?.password?.toString(),
        });
      }
      if (states?.errors?.password2?.length > 0) {
        toast.error(
          String("Confirm password: " + states?.errors?.password2?.toString()),
          {
            toastId: String(
              "Confirm password: " + states?.errors?.password2?.toString()
            ),
          }
        );
        setError("confirm_password", {
          type: "server",
          message: states?.errors?.password2?.toString(),
        });
      }
      if (states?.errors) {
        dispatch(setAuthSignUpFailed(null));
      }
    }
    if (states?.error) {
      toast.error(<div>{states?.error as any}</div>, {
        toastId: String(states?.error),
      });
      dispatch(setAuthFailed(null));
    }
  }, [states]);
  const showHidePassword = () => {
    if (fieldTypePassword === "password") {
      setFieldTypePassword("text");
    } else {
      setFieldTypePassword("password");
    }
  };
  const showHideConfmPassword = () => {
    if (fieldTypeConfirmPassword === "password") {
      setFieldTypeConfirmPassword("text");
    } else {
      setFieldTypeConfirmPassword("password");
    }
  };
  const companyField = (row: any) => {
    return (
      <label
        key={row.id}
        className={`w-100 ${Style.input} ${Style.mt24} ${Style.minWidthinput}`}
      >
        <input
          className={`${Style.input__field} ${Style.fieldFont} ${
            errors?.company_business_name ? "is-invalid" : ""
          }`}
          placeholder=" "
          type="text"
          autoComplete="off"
          {...register(row?.field_label, {
            required: `Please enter your ${row?.field_name}`,
          })}
        />
        <span className={`${Style.input__label}`}>{row?.field_name}</span>
        <p className="color-red">
          <ErrorMessage errors={errors} name={row?.field_label} />
        </p>
      </label>
    );
  };
  const firstNameField = (row: any) => {
    return (
      <label
        key={row.id}
        className={`w-100 ${Style.input} ${Style.mt24} ${Style.minWidthinput}`}
      >
        {row?.is_mandatory === true ? (
          <input
            className={`${Style.input__field} ${Style.fieldFont} ${
              errors.first_name ? "is-invalid" : ""
            }`}
            type="text"
            placeholder=" "
            autoComplete="off"
            {...register(row?.field_label, {
              required: `Please enter your ${row?.field_name}`,
            })}
          />
        ) : (
          <input
            className={`${Style.input__field} ${Style.fieldFont} ${
              errors.first_name ? "is-invalid" : ""
            }`}
            type="text"
            placeholder=" "
            autoComplete="off"
            {...register(row?.field_label)}
          />
        )}

        <span className={`${Style.input__label}`}>{row?.field_name}</span>
        <p className="color-red">
          <ErrorMessage errors={errors} name={row?.field_label} />
        </p>
      </label>
    );
  };
  const lastNameField = (row: any) => {
    return (
      <label
        key={row.id}
        className={`w-100 ${Style.input} ${Style.mt24} ${Style.minWidthinput}`}
      >
        {row?.is_mandatory === true ? (
          <input
            className={`${Style.input__field} ${Style.fieldFont} ${
              errors.last_name ? "is-invalid" : ""
            }`}
            type="text"
            placeholder=" "
            autoComplete="off"
            {...register(row?.field_label, {
              required: `Please enter your ${row?.field_name}`,
            })}
          />
        ) : (
          <input
            className={`${Style.input__field} ${Style.fieldFont} ${
              errors.last_name ? "is-invalid" : ""
            }`}
            type="text"
            placeholder=" "
            autoComplete="off"
            {...register(row?.field_label)}
          />
        )}
        <span className={`${Style.input__label}`}>{row?.field_name}</span>
        <p className="color-red">
          <ErrorMessage errors={errors} name={row?.field_label} />
        </p>
      </label>
    );
  };
  const emailField = (row: any) => {
    return (
      <label
        key={row.id}
        className={`w-100 ${Style.input} ${Style.mt24} ${Style.minWidthinput}`}
      >
        {row?.is_mandatory === true ? (
          <input
            className={`${Style.input__field} ${Style.fieldFont} ${
              errors.email_address ? "is-invalid" : ""
            }`}
            type="text"
            placeholder=" "
            autoComplete="off"
            {...register(row?.field_label, {
              required: `Please enter your ${row?.field_name}`,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Invalid email format",
              },
            })}
          />
        ) : (
          <input
            className={`${Style.input__field} ${Style.fieldFont} ${
              errors.email_address ? "is-invalid" : ""
            }`}
            type="text"
            placeholder=" "
            autoComplete="off"
            {...register(row?.field_label, {
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Invalid email format",
              },
            })}
          />
        )}
        <span className={`${Style.input__label}`}>{row?.field_name}</span>
        <p className="color-red">
          <ErrorMessage errors={errors} name={row?.field_label} />
        </p>
      </label>
    );
  };
  const passwordField = (row: any) => {
    return (
      <label
        key={row.id}
        className={`w-100 ${Style.input} ${Style.mt24} ${Style.minWidthinput}`}
      >
        {row?.is_mandatory === true ? (
          <input
            id="password"
            className={`${Style.input__field} ${Style.fieldFont} ${
              errors.password ? "is-invalid" : ""
            }`}
            style={{ paddingRight: lang === "ar" ? "3rem" : "0rem" }}
            placeholder=" "
            type={fieldTypePassword}
            {...register(row?.field_label, {
              required: `Please enter your ${row?.field_name}`,
              pattern: {
                value:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                message:
                  "Must contain 8 characters, One uppercase, one lowercase, One number and one special case character",
              },
            })}
            autoComplete="new-password"
          />
        ) : (
          <input
            id="password"
            className={`${Style.input__field} ${Style.fieldFont} ${
              errors.password ? "is-invalid" : ""
            }`}
            style={{ paddingRight: lang === "ar" ? "3rem" : "0rem" }}
            placeholder=" "
            type={fieldTypePassword}
            {...register(row?.field_label, {
              pattern: {
                value:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                message:
                  "Must contain 8 characters, One uppercase, one lowercase, One number and one special case character",
              },
            })}
            autoComplete="new-password"
          />
        )}

        <span className={`${Style.input__label}`}>{row?.field_name}</span>
        <PasswordStrengthBar
          scoreWords={["Weak", "Weak", "Average", "Good", "Strong"]}
          shortScoreWord={[""]}
          minLength={8}
          password={watch("password")}
        />
        <button
          className="btn eye-btn"
          type="button"
          onClick={showHidePassword}
        >
          {fieldTypePassword === "password" ? (
            <VisibilityOutlined />
          ) : (
            <VisibilityOffOutlined />
          )}
        </button>
        <p className="color-red">
          <ErrorMessage errors={errors} name={row?.field_label} />
        </p>
      </label>
    );
  };
  const confirmPasswordField = (row: any) => {
    return (
      <label
        key={row.id}
        className={`w-100 ${Style.input} ${Style.mt24} ${Style.minWidthinput}`}
      >
        {row?.is_mandatory === true ? (
          <input
            id="confirm_password"
            className={`${Style.input__field} ${Style.fieldFont} ${
              errors.confirm_password ? "is-invalid" : ""
            }`}
            style={{ paddingRight: lang === "ar" ? "3rem" : "0rem" }}
            placeholder=" "
            type={fieldTypeConfirmPassword}
            {...register(row?.field_label, {
              required: `Please enter ${row?.field_name}`,
              pattern: {
                value:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                message:
                  "Must contain 8 characters, One uppercase, one lowercase, One number and one special case character",
              },
              validate: (val: string) => {
                if (watch("password") != val) {
                  return "Your passwords do no match";
                }
              },
            })}
            autoComplete="new-confirm-password"
          />
        ) : (
          <input
            id="confirm_password"
            className={`${Style.input__field} ${Style.fieldFont} ${
              errors.confirm_password ? "is-invalid" : ""
            }`}
            style={{ paddingRight: lang === "ar" ? "3rem" : "0rem" }}
            placeholder=" "
            type={fieldTypeConfirmPassword}
            {...register(row?.field_label, {
              pattern: {
                value:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                message:
                  "Must contain 8 characters, One uppercase, one lowercase, One number and one special case character",
              },
              validate: (val: string) => {
                if (watch("password") != val) {
                  return "Your passwords do no match";
                }
              },
            })}
            autoComplete="new-confirm-password"
          />
        )}

        <span className={`${Style.input__label}`}>{row?.field_name}</span>
        <button
          className="btn eye-btn"
          type="button"
          onClick={showHideConfmPassword}
        >
          {fieldTypeConfirmPassword === "password" ? (
            <VisibilityOutlined />
          ) : (
            <VisibilityOffOutlined />
          )}
        </button>
        <p className="color-red">
          <ErrorMessage errors={errors} name={row?.field_label} />
        </p>
      </label>
    );
  };
  const phoneNumberField = (row: any) => {
    return (
      <label
        key={row.id}
        className={`w-100 countryPhone ${Style.input} ${Style.mt24} ${Style.minWidthinput}`}
        style={{ direction: lang === "ar" ? "ltr" : "unset" }}
      >
        <Controller
          control={control}
          name="phone_no"
          rules={{
            required: row?.is_mandatory,
          }}
          render={({ field: { ref, ...field } }) => (
            <PhoneInput
              {...field}
              country={"us"}
              enableSearch={true}
              value={phoneValue}
              onChange={setPhoneValue}
              countryCodeEditable={false}
              inputClass={`${errors.phone_no ? "is-invalid" : ""}`}
              placeholder="Enter phone number"
              defaultErrorMessage="Invalid Phone Number."
              isValid={(value, country: any) => {
                if (value.match(/12345/)) {
                  return "Invalid phone number: " + value + ", " + country.name;
                } else if (value.match(/1234/)) {
                  return false;
                } else {
                  return true;
                }
              }}
              inputProps={{
                name: "phone_no",
                required: row?.is_mandatory,
                autoFocus: false,
              }}
            />
          )}
        />

        <p className="color-red">
          {errors.phone_no ? "Phone number is required or invalid" : ""}
        </p>
      </label>
    );
  };
  const primaryButton =
    useColor("button_primary") || defaultThemeColor.button_primary;
  const primaryButtonText =
    useColor("button_primary_text") ||
    defaultThemeColor.button_button_primary_text;
  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;
  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;
  return (
    <div
      className="d-flex flex-wrap px-0 mx-0"
      style={{
        direction: lang === "ar" ? "rtl" : "ltr",
        background: interfaceBgColor,
        color: interfaceTextColor,
        overflowY: "auto",
        height: "100vh",
      }}
    >
      <div
        className={`position-relative ${Style.leftSide}`}
        style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
      >
        <Header />
        <div className={Style.signInFormSection}>
          <h2>{t("Sign up to MiltechLMS")}</h2>
          {feilds ? (
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              {feilds?.map((row: any) => {
                if (row?.field_label === "company_business_name") {
                  return companyField(row);
                }
                if (
                  row?.field_label === "first_name" &&
                  row?.is_disable === false
                ) {
                  return firstNameField(row);
                }
                if (
                  row?.field_label === "last_name" &&
                  row?.is_disable === false
                ) {
                  return lastNameField(row);
                }
                if (
                  row?.field_label === "email_address" &&
                  row?.is_disable === false
                ) {
                  return emailField(row);
                }
                if (
                  row?.field_label === "password" &&
                  row?.is_disable === false
                ) {
                  return passwordField(row);
                }
                if (
                  row?.field_label === "confirm_password" &&
                  row?.is_disable === false
                ) {
                  return confirmPasswordField(row);
                }
                if (
                  row?.field_label === "phone_no" &&
                  row?.is_disable === false
                ) {
                  return phoneNumberField(row);
                }
              })}

              <div className={`${Style.minWidthinput}`}>
                <button
                  className={`btn ${Style.loginBtn} ${Style.mt24} w-100`}
                  style={{
                    background: primaryButton,
                    color: primaryButtonText,
                  }}
                  type="submit"
                  disabled={isLoading}
                >
                  <span className={`${Style.alignItemsInside}`}>
                    <span
                      className={`p-1 ${Style.hThree} ${Style.buttonFont} ${Style.white}`}
                    >
                      {t("SIGN UP")}
                    </span>
                  </span>
                </button>
              </div>
            </form>
          ) : (
            <p style={{ color: interfaceTextColor }}>
              {t("Please wait. Page is loading....")}
            </p>
          )}

          <div
            className={`d-flex justify-content-center w-100 ${Style.mt24} ${Style.minWidthinput} ${Style.fontAlready} `}
          >
            <div className="m-1" style={{ color: interfaceTextColor }}>
              {t("Already have an account")}?
            </div>
            <Link className="m-1" to="/login">
              {t("Sign in")}
            </Link>
          </div>
        </div>
        <Footer />
      </div>

      <div className={`${Style.rightSide}`}>
        <h2
          className={`${Style.headerGreeting} ${Style.headerMargins} ${Style.white}`}
          style={{
            color: interfaceTextColor,
            paddingRight: lang === "ar" ? "3rem" : "0rem",
          }}
        >
          {t("Hello, Let’s get started")}.
        </h2>
        <div
          className={`${Style.subHead} ${Style.white}`}
          style={{ color: interfaceTextColor }}
        >
          {t("Enter your details to get registered")}.
        </div>
        <img
          className={`w-100 ${Style.imgCover}`}
          src={SignupImage}
          alt={"SignupImage"}
        ></img>
      </div>
    </div>
  );
}

export default Signup;
