import Header from "../../../components/Header";
import MembersList from "./MembersList";
import ModulesList from "./ModulesList";

function ModulesMemberLists() {
  return (
    <>
      <Header page="Training Name" mainHead=" Module" />
      <ModulesList />
      <MembersList />
    </>
  );
}

export default ModulesMemberLists;
