import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Chip } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import "react-phone-input-2/lib/style.css";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import CancelButton from "../../components/CancelButton";
import Header from "../../components/Header";
import SubmitButton from "../../components/SubmitButton";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import axiosPrivate from "../../lib/axiosPrivate";
import { getPolicyData, updatePolicyData } from "../../services/policyService";
import {
  alertMessageSelector,
  setErrors,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { RootState, useAppDispatch } from "../../store/store";
import {
  defaultThemeColor,
  light_text_color,
  primary_color,
} from "../../utils/colors_values";
import Style from "./AddNewPolicy.module.scss";
import PolicyEditor from "./Editor/PolicyEditor";

const statuses = [
  { key: "Approved", value: "Approved" },
  { key: "Draft", value: "Draft" },
];

const schema = yup
  .object({
    name: yup
      .string()
      .required("Name is required")
      .max(100, "Name must be at most 100 characters"),
    description: yup
      .string()
      .required("Description is required")
      .max(2000, "Description must be at most 2000 characters"),
    status: yup.string().required("Status is required"),
  })
  .required();

function EditPolicyDetails() {
  const {
    reset,
    control,
    setError,
    setValue,
    getValues,
    watch,
    clearErrors,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });
  const [description, setDescription] = useState("");
  const [descriptionEditData, setDescriptionEditData] = useState<string>("");
  const [text, setText] = useState<string>("");
  const t = useTranslation();
  const lang = useCompanyLanguage();

  //   useEffect(() => {
  //     setValue("description", description);
  //     setError("description", {
  //       message: "",
  //       type: "server",
  //     });
  //     if (description === "<p><br></p>" || description === `<p class="ql-align-center"><br></p>`) {
  //       setError("description", {
  //         message: "Description is required!",
  //         type: "server",
  //       });
  //     }
  //   }, [description]);
  useEffect(() => {
    if (description) {
      setValue("description", description);
    } else {
      setValue("description", "");
    }
  }, [description]);
  // useEffect(() => {
  //   setTrainingName(watch("training"));
  // }, [watch("training")]);
  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );

  const page = useSelector((state: RootState) => state.policyReducer.page);
  const perPage = useSelector(
    (state: RootState) => state.policyReducer.perPage
  );
  const search = useSelector((state: RootState) => state.policyReducer.search);
  const navigate = useNavigate();
  const urlWithQueryString = () => {
    let defaultValues = {};
    reset({ ...defaultValues });
    setDescriptionEditData("");
    let object: any = {};
    if (page) {
      object.page = page;
    }
    if (perPage) {
      object.perpage = perPage;
    }
    if (
      search !== null &&
      search !== "" &&
      search !== undefined &&
      search !== "null"
    ) {
      object.search = search;
    }

    const objString = "?" + new URLSearchParams(object).toString();
    navigate(`/policy-listing${objString}`);
  };
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const data: any = useSelector(
    (state: RootState) => state.policyReducer.detail
  );

  useEffect(() => {
    if (data?.description) {
      setDescriptionEditData(JSON.parse(data?.description));
    }
    setError("description", {
      message: "",
      type: "server",
    });
    if (
      description == "<p><br></p>" ||
      description == `<p class="ql-align-center"><br></p>`
    ) {
      setError("description", {
        message: "Description is required!",
        type: "server",
      });
    }
  }, [data?.description]);
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;

  useEffect(() => {
    let defaultValues = {};
    reset({ ...defaultValues });
    setDescriptionEditData("");
    dispatch(getPolicyData(Number(id)));
  }, []);

  useEffect(() => {
    if (alertMessage?.errors) {
      if (alertMessage?.errors?.name?.length > 0) {
        toast.error(alertMessage?.errors?.name?.toString(), {
          toastId: alertMessage?.errors?.name?.toString(),
        });
        setError("name", {
          type: "server",
          message: alertMessage?.errors?.name?.toString(),
        });
      }
      if (alertMessage?.errors?.description?.length > 0) {
        toast.error(alertMessage?.errors?.description?.toString(), {
          toastId: alertMessage?.errors?.description?.toString(),
        });
        setError("description", {
          type: "server",
          message: alertMessage?.errors?.description?.toString(),
        });
      }
      if (alertMessage?.errors?.status?.length > 0) {
        toast.error(alertMessage?.errors?.status?.toString(), {
          toastId: alertMessage?.errors?.status?.toString(),
        });
        setError("status", {
          type: "server",
          message: alertMessage?.errors?.status?.toString(),
        });
      }
      if (alertMessage?.errors) {
        dispatch(setErrors(null));
      }
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      urlWithQueryString();
    }
  }, [alertMessage]);

  useEffect(() => {
    let defaultValues = {
      name: data?.name,
      description: data?.description,
      status: data?.status,
    };
    reset({ ...defaultValues });
    setDescription(data?.description || "");
  }, [data]);

  const Submit = (payload: any) => {
    if (
      description === "<p><br></p>" ||
      description === `<p class="ql-align-center"><br></p>`
    ) {
      setError("description", {
        message: "Description is required!",
        type: "server",
      });
    } else {
      if (data?.id) {
        dispatch(
          updatePolicyData(Number(data?.id), {
            ...payload,
          })
        );
      }
    }
  };

  const borderColorInput =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  const loadOptions = async (
    searchQuery: any,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await axiosPrivate.get(
      `assessments/trainings/?page=${page}&page_size=100&search=${searchQuery}`
    );
    const data = response.data;

    const options = data?.results?.map((row: any) => {
      return {
        label: row?.name || "N/A",
        value: row.id,
      };
    });

    const users = {
      options: options || [],
      hasMore: data?.next ? true : false,
      additional: {
        page: searchQuery ? 1 : page + 1,
      },
    };

    return users;
  };

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;
  const primaryButton =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;
  const primaryButtonBg =
    useColor("button_primary") || defaultThemeColor.button_primary;
  return (
    <>
      <div
        className={`${Style.certificatesStart}`}
        style={{
          background:
            useColor("interface_background_color") ||
            defaultThemeColor.interface_background_color,
        }}
      >
        <Header
          page={t("Edit Policy")}
          mainHead={t("Policy Management / Edit Policy")}
          backLink="/policy-listing"
        />
        <div
          className="page-start"
          style={{
            background:
              useColor("interface_foreground_color") ||
              defaultThemeColor.interface_foreground_color,
            color:
              useColor("interface_foreground_text_color") ||
              defaultThemeColor.interface_foreground_text_color,
          }}
        >
          <h2
            className="page-heading mb-4"
            style={{
              color:
                useColor("interface_foreground_text_color") ||
                defaultThemeColor.interface_foreground_text_color,
              textAlign: lang === "ar" ? "right" : "left",
            }}
          >
            {t("Edit Policy")}
          </h2>
          <form onSubmit={handleSubmit(Submit)} autoComplete="off">
            <div className={`row`}>
              <div className="col-lg-6 mb-4">
                <span className="form-label">
                  {t("Name")}
                  <span className="color-red"> *</span>
                </span>
                <input
                  className={`form-input ${
                    errors.name?.message ? "is-invalid" : ""
                  }`}
                  style={{
                    background:
                      useColor("interface_background_color") ||
                      defaultThemeColor.interface_background_color,
                    color:
                      useColor("interface_background_text_color") ||
                      defaultThemeColor.interface_background_text_color,
                    outline: "none",
                    border: "none",
                  }}
                  type="text"
                  placeholder={t("Name")}
                  autoComplete="off"
                  {...register("name")}
                />
                <p className="error color-red">{errors.name?.message}</p>
              </div>
              <div className="col-lg-6 mb-4">
                <div className={Style.columnWrap}>
                  <div
                    className={`${Style.labelHeader} col-lg-6 px-0`}
                    style={{
                      textAlign: lang === "ar" ? "right" : "left",
                      width: lang === "ar" ? "100%" : "unset",
                    }}
                  >
                    {t("Status")}
                    <span style={{ color: "red" }}> *</span>
                  </div>
                  <Controller
                    name="status"
                    control={control}
                    defaultValue={""}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormControl sx={{ m: 0, width: "100%", mt: 1 }}>
                        <InputLabel id="demo-multiple-name-label">
                          {t("Status")}
                        </InputLabel>
                        <Select
                          labelId="status"
                          label="Status"
                          defaultValue={data?.status}
                          value={value}
                          style={{
                            color: interfaceTextColor,
                            background: interfaceBgColor,
                          }}
                          onChange={(event) => {
                            onChange(event);
                          }}
                          sx={{
                            height: "3.9em",
                            "& .MuiChip-root": {
                              color: primaryButton,
                              background: primaryButtonBg,
                            },
                          }}
                          MenuProps={{
                            sx: {
                              "&& .Mui-selected": {
                                color: light_text_color,
                                background: primary_color,
                              },
                              "&& .Mui-selected:hover": {
                                color: "black",
                                background: "skyblue",
                              },
                            },
                          }}
                          renderValue={(selected) => {
                            return (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected && (
                                  <Chip key={selected} label={selected} />
                                )}
                              </Box>
                            );
                          }}
                        >
                          {statuses.map((row) => (
                            <MenuItem
                              value={row.value}
                              key={row.key}
                              className="d-flex"
                            >
                              <Radio
                                color="default"
                                sx={{
                                  color:
                                    getValues() && getValues().status
                                      ? (getValues()?.status as any).indexOf(
                                          row.value
                                        ) > -1
                                        ? "white"
                                        : ""
                                      : "",
                                }}
                                checked={
                                  getValues() && getValues().status
                                    ? (getValues()?.status as any).indexOf(
                                        row.value
                                      ) > -1
                                    : false
                                }
                                name="radio-buttons"
                              />
                              <div> {row.value}</div>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <p className="color-red">{errors.status?.message}</p>
                  {/* <p className="color-red">{errors.question_status?.message}</p> */}
                </div>
              </div>

              <div className="col-12 mb-4">
                <span className="form-label">
                  {t("Description")}
                  <span className="color-red"> *</span>
                </span>
                <PolicyEditor
                  setDescription={setDescription}
                  prviousData={descriptionEditData}
                />
                <p className="error color-red">{errors.description?.message}</p>
              </div>

              <div
                className="d-flex flex-wrap gap-3 w-100 px-3 mt-5 align-items-center"
                style={{
                  justifyContent: lang === "ar" ? "flex-start" : "flex-end",
                }}
              >
                <SubmitButton label={t("Update")} marginTop={false} />
                <CancelButton
                  size="small"
                  outlined={true}
                  onClick={urlWithQueryString}
                  textColor=""
                  outlineColor=""
                  text={t("Cancel")}
                  className={``}
                  bg={""}
                ></CancelButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EditPolicyDetails;
