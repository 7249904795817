function UploadSVG(props: { color: string }) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.1379 4H6.56648C5.96027 4 5.37889 4.24082 4.95023 4.66947C4.52158 5.09812 4.28076 5.67951 4.28076 6.28571V17.7143C4.28076 18.3205 4.52158 18.9019 4.95023 19.3305C5.37889 19.7592 5.96027 20 6.56648 20H17.995C18.6013 20 19.1826 19.7592 19.6113 19.3305C20.0399 18.9019 20.2808 18.3205 20.2808 17.7143V6.28571"
        stroke={props.color}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7094 12L12.2809 15.4286L8.85229 12"
        stroke={props.color}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.2808 4H15.7093C14.8 4 13.9279 4.36122 13.285 5.00421C12.642 5.64719 12.2808 6.51926 12.2808 7.42857V15.4286"
        stroke={props.color}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default UploadSVG;
