import { useEffect } from "react";
import { setLanguage } from "react-multi-lang";
import { useSelector } from "react-redux";
import Logo from "../../images/logo.svg";
import { getCompanyForm } from "../../services/profileManagementService";
import { RootState, useAppDispatch } from "../../store/store";
import { languages } from "../../utils/languages";
import Style from "./Header.module.scss";

const Header = () => {
  const dispatch = useAppDispatch();
  const userInfo = useSelector((state: any) => state.authReducer.loginData);
  const companyFormData =
    useSelector(
      (state: RootState) => state.profileManagementReducer.company_form_data
    ) || [];
  useEffect(() => {
    const intervalLogout = setInterval(() => {
      const isAccessToken = localStorage.getItem("access_token");
      if (isAccessToken) {
        window.location.href = window.location.href;
        return;
      }
    }, 2000);
    return () => {
      clearInterval(intervalLogout);
    };
  }, []);
  useEffect(() => {
    if (userInfo?.preferred_language) {
      const lang = languages?.find((r: any) => {
        return r?.name?.toString() === userInfo?.preferred_language?.toString();
      });
      setLanguage(lang?.code?.toString() || "en");
    } else {
      const lang = languages?.find((r: any) => {
        return (
          r?.name?.toString() === companyFormData?.company_language?.toString()
        );
      });
      setLanguage(lang?.code?.toString() || "en");
    }
  }, [
    companyFormData?.company_language,
    languages,
    userInfo?.preferred_language,
  ]);
  useEffect(() => {
    dispatch(getCompanyForm());
  }, []);
  return (
    <div className={Style.headerLogo}>
      <img src={Logo} alt={"Logo"}></img>
    </div>
  );
};

export default Header;
