import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import OtpInput from "react-otp-input";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import { otpVerify, reSendOtp } from "../../services/authService";
import {
  authSelector,
  isReSentOtp,
  isVerified,
  setAuthFailed,
} from "../../store/reducers/authReducer";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import Footer from "./Footer";
import Header from "./Header";
import Style from "./Verify.module.scss";
const hideEmailPhone = require("partially-hide-email-phone");

const schema = yup
  .object({
    otp: yup
      .string()
      .required("OTP is required")
      .trim()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(6, "Must be exactly 6 digits")
      .max(6, "Must be exactly 6 digits"),
  })
  .required();

function Verify() {
  const {
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const [countDown, setCountDown] = React.useState(0);
  const [runTimer, setRunTimer] = React.useState(false);
  const [reSend, setReSend] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState("");
  const states = useSelector(authSelector).authReducer;
  const isLoading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  useEffect(() => {
    if (!states?.singup_data?.email) {
      navigate("/");
    }
    if (states?.error) {
      toast.error(String(states?.error), {
        toastId: String(states?.error),
      });
      dispatch(setAuthFailed(null));
    }
    if (states?.isAuth) {
      navigate("/");
    }
    if (states?.singup_data) {
      setUserEmail(states.singup_data?.email);
    }
    if (states?.isVerified && !states?.isForgotVerified) {
      dispatch(isVerified(false));
      navigate("/verification-success");
    }
    if (states?.isForgotVerified && states?.isVerified) {
      navigate("/new-password");
    }
    if (states?.isReSentOtp) {
      toast.success("OTP sent successfully", {
        toastId: "OTP sent successfully",
      });
      dispatch(isReSentOtp(false));
      setRunTimer(true);
      setReSend(false);
    }
  }, [states, dispatch, navigate]);
  React.useEffect(() => {
    let timerId: any;

    if (runTimer) {
      setCountDown(60 * 2);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  React.useEffect(() => {
    if (countDown < 0 && runTimer) {
      // console.log("expired");
      setReSend(true);
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  // const togglerTimer = () => setRunTimer((t) => !t);
  React.useEffect(() => {
    setRunTimer(true);
  }, []);
  const seconds = String(countDown % 60).padStart(2, String(0));
  const minutes = String(Math.floor(countDown / 60)).padStart(2, String(0));
  const onSubmit = (payload: any) => {
    dispatch(otpVerify(payload, states?.isForgotVerified));
  };
  // before
  const [otp, setOtp] = useState<number | any>();
  // const onChange = (value: string) => {
  //   setOtp(String(parseInt(value)));
  // };
  useEffect(() => {
    setValue("otp", otp);
  }, [otp]);

  const reSendHandler = () => {
    dispatch(reSendOtp());
  };

  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;
  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  const interfaceTextColor2 =
    useColor("interface_text_color") ||
    defaultThemeColor.interface_background_text_color;
  const foregroundBgColor =
    useColor("interface_foreground_color") ||
    defaultThemeColor.interface_foreground_color;
  const foregroundTextColor =
    useColor("interface_foreground_text_color") ||
    defaultThemeColor.interface_foreground_text_color;
  const primaryButton =
    useColor("button_primary") || defaultThemeColor.button_primary;
  const primaryButtonText =
    useColor("button_primary_text") ||
    defaultThemeColor.button_button_primary_text;

  return (
    <div
      style={{
        direction: lang === "ar" ? "rtl" : "ltr",
        overflowY: "auto",
        height: "100vh",
      }}
    >
      <div>
        <Header />
      </div>
      <div
        className={`${Style.verifyStart}`}
        style={{ color: interfaceTextColor, background: interfaceBgColor }}
      >
        <div className={`d-flex justify-content-center`}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="d-flex align-items-start flex-column p-5">
              <div
                className={`${Style.enterOTP}`}
                style={{ color: interfaceTextColor }}
              >
                {t("Enter OTP")}
              </div>
              <div
                className={`${Style.enterOTPDesc} ${Style.mt24}`}
                style={{ color: interfaceTextColor }}
              >
                {t("Enter the OTP code we just sent")} <br />
                {t("you on your registered Email/Phone number")}
              </div>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span>&nbsp;&nbsp;</span>}
                containerStyle={Style.input}
                renderInput={(props) => <input {...props} />}
              />
              {/* <OtpInput value={String(otp)} valueLength={6} onChange={onChange} /> */}
              <p className="color-red">{errors.otp?.message}</p>
              <div
                className={`${Style.timer} ${Style.mt8}`}
                style={{ color: interfaceTextColor }}
              >
                {minutes}:{seconds}
              </div>
              <div
                className={`${Style.enterOTPDesc} ${Style.mt24}`}
                style={{ color: interfaceTextColor }}
              >
                {t("We send verification code to your email")} <br />
                {userEmail ? hideEmailPhone(userEmail) : ""}.{" "}
                {t("You can check your inbox")}.
              </div>

              {reSend && (
                <button
                  type="button"
                  className={`${Style.sendAgain} ${Style.mtBeforeSent} btn-btn-primary`}
                  // style={{ color: "#0089cf" }}
                  onClick={reSendHandler}
                >
                  {t("I didn't receive the code? Send again")}
                </button>
              )}
              <button
                className={`${Style.loginBtn} btn ${Style.mt24}`}
                style={{ background: primaryButton, color: primaryButtonText }}
                type="submit"
                disabled={isLoading}
              >
                <span
                  className={`p-1 ${Style.hThree} ${Style.buttonFont} ${Style.white}`}
                >
                  {t("Verify")}
                </span>
              </button>
            </div>
          </form>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Verify;
