/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import * as React from "react";
import { useSelector } from "react-redux";
import { updateUserDetails } from "../services/userService";
import { useAppDispatch } from "../store/store";
import Style from "./LanguageButton.module.scss";
import { defaultThemeColor } from "../utils/colors_values";
import useColor from "../hooks/useColor";

export default function LanguageButton() {
  const userInfo = useSelector((state: any) => state.authReducer.loginData);

  const [selectedLang, setSelectedLang] = React.useState(
    userInfo?.preferred_language?.toString() || "English"
  );
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    if (selectedLang != userInfo?.preferred_language) {
      dispatch(
        updateUserDetails(
          {
            id: userInfo?.id,
            preferred_language: selectedLang,
          },
          true
        )
      );
    }
  }, [selectedLang]);

  const primaryColor =
    useColor("button_primary") || defaultThemeColor.button_primary;
  const primaryButtonText =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;
  const bgColor =
    useColor("button_secondary") || defaultThemeColor.button_secondary;
  const bgColorText =
    useColor("button_secondary_text") ||
    defaultThemeColor.button_secondary_text;

  React.useEffect(() => {
    if (selectedLang == "English") transformOnClickEnglish2();
  }, []);

  const transformOnClickArabic = () => {
    var style = (document?.getElementById("lang") as any).style;
    style.webkitTransform = "translateX(-40px)";
  };

  const transformOnClickEnglish = () => {
    var style = (document?.getElementById("lang") as any).style;
    style.webkitTransform = "translateX(-40px)";

    setTimeout(() => {
      style.webkitTransform =
        selectedLang == "English" ? "translateX(40px)" : "";
    }, 1500);
  };

  const transformOnClickEnglish2 = () => {
    var style = (document?.getElementById("lang") as any).style;
    style.webkitTransform = selectedLang == "English" ? "" : "";
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        className={`${Style.maskbox}`}
        sx={{
          background: bgColor,
          color: bgColorText,
        }}
      >
        <Box
          id={"lang"}
          className={Style.mask}
          style={{
            background: primaryColor,
            left: "3em",
          }}
        />

        <Button
          disableRipple
          variant="text"
          sx={{
            color:
              selectedLang === "Arabic - العربية"
                ? primaryButtonText
                : bgColorText,
            minWidth: "4em",
          }}
          onClick={() => {
            if (!(selectedLang == "Arabic - العربية")) {
              setSelectedLang("Arabic - العربية");
              transformOnClickArabic();
            }
          }}
        >
          AR
        </Button>
        <Button
          disableRipple
          variant="text"
          sx={{
            color: selectedLang === "English" ? primaryButtonText : bgColorText,
            minWidth: "4em",
          }}
          onClick={() => {
            if (!(selectedLang == "English")) {
              setSelectedLang("English");
              transformOnClickEnglish();
            }
          }}
        >
          EN
        </Button>
      </Box>
    </Box>
  );
}
