import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Chip, FormControl, MenuItem, Radio, Select } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import CancelButton from "../../../components/CancelButton";
import SubmitButton from "../../../components/SubmitButton";
import { maskSecret } from "../../../helpers/helpers";
import useCompanyLanguage from "../../../hooks/language";
import useColor from "../../../hooks/useColor";
import { addData } from "../../../services/companyIntegrationService";
import {
  alertMessageSelector,
  setErr,
} from "../../../store/reducers/alertMessageReducer";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import {
  defaultThemeColor,
  light_text_color,
  primary_color,
} from "../../../utils/colors_values";
const tls = [
  { key: "True", value: "True" },
  { key: "False", value: "False" },
];
const schema = yup
  .object({
    type: yup.string().required("Type is required"),
    smtp_host_name: yup.string().required("Host is required"),
    smtp_port: yup
      .number()
      .typeError("Only digit allow")
      .required("Port is required"),
    smtp_host_user: yup.string().required("User is required"),
    smtp_host_password: yup.string().required("Password is required"),
    smtp_tls: yup.string().default("True").required("TLS is required"),
  })
  .required();
export interface PropsInterface {
  setIsEdit: any;
  row: any;
}
function ExchangeServerEdit(props: PropsInterface) {
  const { setIsEdit, row } = props;
  const {
    setValue,
    getValues,
    setError,
    reset,
    register,
    control,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const isLoading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  const onSubmit = (payload: any) => {
    let fPayload = {
      type: payload.type,
      detail: {
        smtp_host_name: payload.smtp_host_name,
        smtp_port: payload.smtp_port,
        smtp_host_user: payload.smtp_host_user,
        smtp_host_password: payload.smtp_host_password,
        smtp_tls: payload.smtp_tls,
      },
    };

    dispatch(addData(fPayload));
  };
  useEffect(() => {
    let defaultValues = {
      type: row?.type,
      smtp_host_name: row?.detail?.smtp_host_name || "",
      smtp_port: row?.detail?.smtp_port || "",
      smtp_host_user: row?.detail?.smtp_host_user || "",
      smtp_host_password: "",
      smtp_tls: row?.detail?.smtp_tls || "True",
    };
    reset({ ...defaultValues });
  }, [row]);

  const alertMessage = useAppSelector(alertMessageSelector).alertMessageReducer;
  useEffect(() => {
    if (alertMessage?.error) {
      if (alertMessage?.error) {
        toast.error(alertMessage?.error?.toString(), {
          toastId: alertMessage?.error?.toString(),
        });
      }

      dispatch(setErr(null));
    }
  }, [alertMessage]);

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;
  const primaryButton =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;
  const primaryButtonBg =
    useColor("button_primary") || defaultThemeColor.button_primary;

  return (
    <>
      <form className="d-flex flex-column" onSubmit={handleSubmit(onSubmit)}>
        <div className={`row`}>
          <div className={`col-lg-6 px-2 `}>
            <span>
              {t("Host Name")}
              <span style={{ color: "red" }}> *</span>
            </span>
            <input
              className="form-control"
              style={{
                height: "50px",
                border: "1px solid #E2E8F0",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
              type="text"
              placeholder={t("Host Name")}
              autoComplete="off"
              {...register("smtp_host_name")}
            />
            <p className="color-red">{errors.smtp_host_name?.message}</p>
          </div>
          <div className={`col-lg-6 px-2 `}>
            <span>
              {t("Port")}
              <span style={{ color: "red" }}> *</span>
            </span>
            <input
              className="form-control"
              style={{
                height: "50px",
                border: "1px solid #E2E8F0",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
              type="number"
              placeholder={t("Port")}
              autoComplete="off"
              {...register("smtp_port")}
            />
            <p className="color-red">{errors.smtp_port?.message}</p>
          </div>

          <div className={`col-lg-6 px-2 `}>
            <span>
              {t("TLS")}
              <span style={{ color: "red" }}> *</span>
            </span>
            <Controller
              name="smtp_tls"
              control={control}
              defaultValue={"True".toString()}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl sx={{ m: 0, width: "100%", mt: 1 }}>
                  {/* <InputLabel id="demo-multiple-name-label">{t("Status")}</InputLabel> */}
                  <Select
                    labelId="smtp_tls"
                    label=""
                    defaultValue={""}
                    value={value.toString()}
                    style={{
                      color: interfaceTextColor,
                      background: interfaceBgColor,
                    }}
                    onChange={(event) => {
                      onChange(event);
                    }}
                    sx={{
                      height: "3.9em",
                      "& .MuiChip-root": {
                        // color: interfaceTextColor,
                        // background: interfaceBgColor,
                        color: primaryButton,
                        background: primaryButtonBg,
                      },
                    }}
                    MenuProps={{
                      sx: {
                        "&& .Mui-selected": {
                          // color: interfaceTextColor,
                          // background: interfaceBgColor,
                          color: light_text_color,
                          background: primary_color,
                        },
                        "&& .Mui-selected:hover": {
                          color: "black",
                          background: "skyblue",
                        },
                      },
                    }}
                    displayEmpty
                    renderValue={(selected: any) => {
                      // setSelectedLimit(selected);
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.5,
                          }}
                        >
                          {(selected && (
                            <Chip key={selected} label={selected} />
                          )) || (
                            <span style={{ color: "hsl(0, 0%, 50%)" }}>
                              Select Question Status
                            </span>
                          )}
                        </Box>
                      );
                    }}
                  >
                    {tls.map((row) => (
                      <MenuItem
                        value={row.value}
                        key={row.key}
                        className="d-flex"
                      >
                        <Radio
                          color="default"
                          sx={{
                            color:
                              getValues() && getValues().smtp_tls
                                ? (getValues()?.smtp_tls as any).indexOf(
                                    row.value
                                  ) > -1
                                  ? "white"
                                  : ""
                                : "",
                          }}
                          checked={
                            getValues() && getValues().smtp_tls
                              ? (getValues()?.smtp_tls as any).indexOf(
                                  row.value
                                ) > -1
                              : false
                          }
                          name="radio-buttons"
                        />
                        <div> {row.value}</div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <p className="color-red">{errors.smtp_tls?.message}</p>
          </div>
          <div className={`col-lg-6 px-2 `}>
            <span>
              {t("Username")}
              <span style={{ color: "red" }}> *</span>
            </span>
            <input
              className="form-control"
              style={{
                height: "50px",
                border: "1px solid #E2E8F0",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
              type="text"
              placeholder={t("Username")}
              autoComplete="off"
              {...register("smtp_host_user")}
            />
            <p className="color-red">{errors.smtp_host_user?.message}</p>
          </div>
          <div className={`col-lg-6 px-2 `}>
            <span>
              {t("Password")}
              <span style={{ color: "red" }}> *</span>
            </span>
            <input
              className="form-control"
              style={{
                height: "50px",
                border: "1px solid #E2E8F0",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
              type="text"
              placeholder={t("Password")}
              autoComplete="off"
              {...register("smtp_host_password")}
            />
            {row?.detail?.smtp_host_password && (
              <p>{maskSecret(row?.detail?.smtp_host_password)}</p>
            )}
            <p className="color-red">{errors.smtp_host_password?.message}</p>
          </div>
        </div>
        <div className="d-flex justify-content-end w-100 gap-4  p-4">
          <SubmitButton label="Save" />
          <CancelButton
            size="small"
            outlined={true}
            onClick={() => {
              setIsEdit(false);
            }}
            textColor=""
            outlineColor=""
            text={t("Cancel")}
            className={`mt-5`}
            bg={""}
          />
        </div>
      </form>
    </>
  );
}

export default ExchangeServerEdit;
