import { useConfirm } from "material-ui-confirm";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import PDFViewer from "../../components/PDFViewer";
import useCompanyLanguage from "../../hooks/language";
import usePermission from "../../hooks/usePermission";
import { getModulesForView } from "../../services/moduleService";
import { RootState, useAppDispatch, useAppSelector } from "../../store/store";
import Style from "../category/Category.module.scss";
function fileToScormURL(url: any) {
  // Get the last part of the URL after the last slash
  const fileNameWithExtension = url.substring(url.lastIndexOf("/") + 1);

  // Get the file name without the extension
  const fileNameWithoutExtension = fileNameWithExtension
    .split(".")
    .slice(0, -1)
    .join(".");
  return (
    process.env.REACT_APP_SCORM_BASE_URL +
    "Content/module/" +
    fileNameWithoutExtension +
    "/index_lms.html"
  );
}
function ModuleDetail() {
  const { id } = useParams();
  const vidRef = useRef<any>();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const dispatch = useAppDispatch();
  const [scormUrl, setScormUrl] = useState<string>("");
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const addPermission = usePermission("add_certificate");
  const editUpdatePermission = usePermission("change_category");
  const deletePermission = usePermission("delete_category");
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<number[]>([]);
  const dataList: any = useAppSelector((state) => state.moduleReducer.data);
  const wholeData: any = useAppSelector(
    (state) => state.moduleReducer.wholedetail
  );
  useEffect(() => {
    dispatch(getModulesForView(Number(id)));
  }, [id]);
  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  const detail = useSelector(
    (state: RootState) => state.moduleReducer.moduleData
  );

  useEffect(() => {
    console.log("detail", detail);
    if (!loading) {
      if (detail?.type?.toLowerCase() == "scorm") {
        if (detail?.file) {
          setScormUrl(fileToScormURL(detail?.file));
        } else {
          setScormUrl("");
        }
      }
      if (detail?.type?.toLowerCase() == "assessment") {
        navigate(
          `/module-assessments/module-assessment-detail/${detail?.assessment?.id}/true`
        );
        return;
      }
    }
  }, [detail, loading]);
  return (
    <div className={`${Style.categoryStart}`}>
      <div style={{ paddingBottom: "0.6em" }}>
        <Header
          page={detail?.name}
          mainHead={t("Module")}
          backLink="/module/module-listing"
        />
        {detail?.type?.toLowerCase() == "scorm" && !loading && (
          <iframe
            style={{ width: "100%", height: "700px" }}
            className="main-iframe"
            src={`${
              process.env.REACT_APP_SCORM_BASE_URL
            }index.html?module_status=${
              detail?.module_status
            }&training_id=0&module_id=${
              detail?.id
            }&courseUrl=${scormUrl}&access_token=${localStorage.getItem(
              "access_token"
            )}`}
            width={1080}
            height={800}
          />
        )}
        {detail?.type?.toLowerCase() == "pdf" && !loading && (
          <div style={{ width: "100%", height: "700px" }}>
            <PDFViewer
              link={detail?.file}
              moduleName={detail?.name}
              topicName={detail?.name}
              isOnlyView={true}
            />
          </div>
        )}
        {detail?.type?.toLowerCase() == "video" && !loading && (
          <video
            id={"vid"}
            ref={vidRef}
            width="100%"
            height="700px"
            controls={true}
          >
            <source src={detail?.file} type="video/mp4" />
          </video>
        )}
      </div>
    </div>
  );
}

export default ModuleDetail;
