import axiosPrivate from "../lib/axiosPrivate";
import { setLoading } from "../store/reducers/loaderReducer";
import { AppThunk } from "../store/store";

import {
  setErr,
  setErrors,
  setSuccess,
} from "../store/reducers/alertMessageReducer";
import {
  setData,
  setDetail,
  setupdatedData,
} from "../store/reducers/policyReducer";
import { getUserData } from "./userService";

export interface GetAllData {
  search: string;
  page: number;
  page_size: number;
}

export const getAll =
  (payload: GetAllData): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setData({}));
    try {
      await axiosPrivate
        .get(
          `policies/policies/?page=${payload.page}&page_size=${payload.page_size}&search=${payload.search}`
        )
        .then((response) => {
          const data = response.data;

          dispatch(setLoading(false));
          dispatch(setData(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };
export const getCompanyPolicy =
  (payload: GetAllData): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setData({}));
    try {
      await axiosPrivate
        .get(
          `policies/policies/my/?page=${payload.page}&page_size=${payload.page_size}&search=${payload.search}`
        )
        .then((response) => {
          const data = response.data;

          dispatch(setLoading(false));
          dispatch(setData(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };
export const getCompanyPolicyAssessment =
  (payload: GetAllData): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setData({}));
    try {
      await axiosPrivate
        .get(
          `policies/policy_assessments/my/?page=${payload.page}&page_size=${payload.page_size}&search=${payload.search}`
        )
        .then((response) => {
          const data = response.data;

          dispatch(setLoading(false));
          dispatch(setData(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };
export const addPolicyData =
  (payload: {
    name: string;
    description: string;
    status: string;
    policy_apply: string;
    department?: any;
    group?: any;
    role?: any;
  }): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    let formData = new FormData();

    formData.append("name", payload.name);
    formData.append("description", JSON.stringify(payload?.description));
    formData.append("status", payload.status);
    formData.append(
      "applied_to",
      payload?.policy_apply == "Group" ? "Groups" : payload?.policy_apply
    );
    formData.append(
      "applied_user_groups",
      payload?.policy_apply == "Department"
        ? payload?.department?.map((i: any) => i?.value)
        : payload?.policy_apply == "Group"
        ? payload?.group?.map((i: any) => i?.value)
        : payload?.policy_apply == "Roles"
        ? payload?.role
        : ""
    );

    try {
      await axiosPrivate
        .post("policies/policies/", formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=myboundary",
            enctype: "multipart/form-data; boundary=myboundary",
          },
        })
        .then((response) => {
          const data = response.data;

          dispatch(setLoading(false));
          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Policy saved successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getPolicyData =
  (id: number): AppThunk =>
  async (dispatch) => {
    dispatch(setDetail({}));
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .get(`policies/policies/${id}/`)
        .then((response) => {
          const data = response.data;

          dispatch(setDetail(data));
          dispatch(setLoading(false));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const updatePolicyData =
  (
    id: number,
    payload: {
      name: string;
      description: string;
      status: string;
    }
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    let formData = new FormData();
    formData.append("name", payload.name);
    formData.append("description", JSON.stringify(payload?.description));
    formData.append("status", payload.status);

    try {
      await axiosPrivate
        .put(`policies/policies/${id}/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=myboundary",
            enctype: "multipart/form-data; boundary=myboundary",
          },
        })
        .then((response) => {
          const data = response.data;

          dispatch(setLoading(false));

          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Policy updated successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const deletePolicy =
  (id: number): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .delete(`policies/policies/${id}/`)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Policy deleted successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setData({}));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setData({}));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const bulkDeletePolicyData =
  (payload: any[]): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .post(`policies/policies/bulk_delete/`, {
          ids: payload,
        })
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Policy's deleted successfully!"));
          }
          // if (response.status === 204 || response.status === 200) {
          //   dispatch(setSuccess("Policy's deleted successfully"));
          // }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const acceptRejectPolicy =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .post(`policies/policies/update_user_selected_policy_status/`, {
          policy_id: payload?.id,
          status: payload?.status,
        })
        .then((response) => {
          const data = response.data;
          dispatch(getUserData());
          dispatch(setupdatedData(data));
          dispatch(setLoading(false));
          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Policy's status updated successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };
