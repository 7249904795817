// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DetailsGroup_leftSide__jlZa9 {
  width: 50%;
}
@media only screen and (max-width: 767px) {
  .DetailsGroup_leftSide__jlZa9 {
    width: 100%;
  }
}

.DetailsGroup_editButton__2pt80 {
  width: 38px;
  height: 38px;
  border-radius: 50% !important;
  padding: 3px;
}

.DetailsGroup_cancelBtn__5F3kz {
  min-width: 150px;
  min-height: 42px;
  border: 1px solid #0089cf;
  border-radius: 4px !important;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}

.DetailsGroup_addMemberBtn__f4vmJ {
  text-transform: none;
}

.DetailsGroup_badge__FmpSv {
  padding: 8px !important;
  background-color: #0089cf;
  border-radius: 7px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/groupManagement/DetailsGroup.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;AAAE;EAFF;IAGI,WAAA;EAGF;AACF;;AADA;EACE,WAAA;EACA,YAAA;EACA,6BAAA;EACA,YAAA;AAIF;;AAFA;EACE,gBAAA;EACA,gBAAA;EACA,yBAAA;EACA,6BAAA;EACA,oIAAA;AAKF;;AAFA;EACE,oBAAA;AAKF;;AAHA;EACE,uBAAA;EACA,yBAAA;EACA,6BAAA;EACA,0BAAA;EACA,2BAAA;AAMF","sourcesContent":[".leftSide {\n  width: 50%;\n  @media only screen and (max-width: 767px) {\n    width: 100%;\n  }\n}\n.editButton {\n  width: 38px;\n  height: 38px;\n  border-radius: 50% !important;\n  padding: 3px;\n}\n.cancelBtn {\n  min-width: 150px;\n  min-height: 42px;\n  border: 1px solid #0089cf;\n  border-radius: 4px !important;\n  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),\n    0px 3px 1px -2px rgba(0, 0, 0, 0.2) !important;\n}\n.addMemberBtn {\n  text-transform: none;\n}\n.badge {\n  padding: 8px !important;\n  background-color: #0089cf;\n  border-radius: 7px !important;\n  font-size: 14px !important;\n  font-weight: 400 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leftSide": `DetailsGroup_leftSide__jlZa9`,
	"editButton": `DetailsGroup_editButton__2pt80`,
	"cancelBtn": `DetailsGroup_cancelBtn__5F3kz`,
	"addMemberBtn": `DetailsGroup_addMemberBtn__f4vmJ`,
	"badge": `DetailsGroup_badge__FmpSv`
};
export default ___CSS_LOADER_EXPORT___;
