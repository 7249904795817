import axiosPrivate from "../lib/axiosPrivate";
import { setLoading } from "../store/reducers/loaderReducer";
import { AppThunk } from "../store/store";

import {
  setErr,
  setErrors,
  setSuccess,
} from "../store/reducers/alertMessageReducer";
import {
  setData,
  setDetail,
  setWholeData,
  setWholeDetail,
} from "../store/reducers/categoryReducer";

export interface GetAllData {
  search: string;
  page: number;
  page_size: number;
}

export const getAll =
  (payload: GetAllData): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setData({}));
    try {
      await axiosPrivate
        .get(
          `assessments/category/?page=${payload.page}&page_size=${payload.page_size}&search=${payload.search}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setData(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getWholeData =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setWholeData({}));
    try {
      await axiosPrivate
        .get(
          `assessments/category/?page=${payload.page}&page_size=${payload.page_size}&search=${payload.search}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setWholeData(data));
        })
        .catch((error) => {
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };
export const getData =
  (id: number): AppThunk =>
  async (dispatch) => {
    // dispatch(setLoading(true));
    dispatch(setDetail({}));
    try {
      await axiosPrivate
        .get(`assessments/category/${id}`)
        .then((response) => {
          const data = response.data;
          // dispatch(setLoading(false));
          dispatch(setDetail(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getDetailData =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setData({}));
    try {
      await axiosPrivate
        .get(
          `assessments/category/${payload?.id}/association/?page=${payload?.page}&page_size=${payload?.page_size}&search=${payload?.search}`,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Set the content type to form data
            },

            onDownloadProgress: (progressEvent: any) => {
              dispatch(setLoading(true));
            },
          }
        )
        .then((response) => {
          const data = response.data;

          dispatch(setData(data));

          setTimeout(() => {
            dispatch(setLoading(false));
          }, 500);
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getWholeDetailData =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setWholeDetail({}));
    try {
      await axiosPrivate
        .get(
          `assessments/category/${payload?.id}/association/?page=${payload?.page}&page_size=${payload?.page_size}&search=${payload?.search}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setWholeDetail(data));
        })
        .catch((error) => {
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const deleteCategory =
  (id: number): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .delete(`assessments/category/${id}/`)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (data?.message) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Category deleted successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));

          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const updateData =
  (id: number, payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await axiosPrivate
        .put(`assessments/category/${id}/`, payload)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (data?.message) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Category updated successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));

          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const addData =
  (payload: { name: string }): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await axiosPrivate
        .post(`assessments/category/`, payload)
        .then((response) => {
          const data = response.data;
          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Category added successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const bulkDeleteData =
  (payload: any[]): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await axiosPrivate
        .post(`assessments/category/bulk_delete/`, { category_ids: payload })
        .then((response) => {
          const data = response.data;
          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Category deleted successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };
