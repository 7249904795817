function DownloadSVG(props: { color: string }) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.92829 12L12.4997 16.8366L17.0712 12.0469M12.4997 4V16.5714M5.64258 20H19.3569"
        stroke={props.color}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DownloadSVG;
