import { useTranslation } from "react-multi-lang";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import { defaultThemeColor } from "../../utils/colors_values";
import Style from "./CreateQuestion.module.scss";
import CreateQuestionForm from "./CreateQuestionForm";

function CreateQuestion() {
  const navigate = useNavigate();
  const { id } = useParams();
  const t = useTranslation();
  const lang = useCompanyLanguage();

  return (
    <>
      <div
        className={`${Style.background}`}
        style={{
          background:
            useColor("interface_background_color") || defaultThemeColor.interface_background_color,
          color:
            useColor("interface_background_text_color") ||
            defaultThemeColor.interface_background_text_color,
        }}
      >
        <div style={{ paddingBottom: "2em" }}>
          <Header
            page={t("Question Library")}
            mainHead={t("Create Question")}
            backLink="/questions-library"
          />
        </div>

        <div>
          <div
            style={{
              background:
                useColor("interface_foreground_color") ||
                defaultThemeColor.interface_foreground_color,
              color:
                useColor("interface_foreground_text_color") ||
                defaultThemeColor.interface_foreground_text_color,
              borderRadius: 10,
            }}
            className="pb-3"
          >
            <div className={Style.padding}>
              <div
                className={`${Style.textHeader} pt-4 px-3`}
                style={{
                  color:
                    useColor("interface_foreground_text_color") ||
                    defaultThemeColor.interface_foreground_text_color,
                  textAlign: lang === "ar" ? "right" : "left",
                }}
              >
                {id ? t("Update Question") : t("Add New")}
              </div>

              <CreateQuestionForm
                cancelAction={() => navigate("/questions-library")}
                isModal={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateQuestion;
