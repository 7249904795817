function QuestionSVG(props: { color: string }) {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.9599 2.00599H3.37626C2.61799 2.00599 2.00117 2.62281 2.00117 3.38108V22.6324C2.00117 23.3906 2.61799 24.0075 3.37626 24.0075H7.9599C8.71817 24.0075 9.335 23.3906 9.335 22.6324V7.45673L17.4513 22.817C17.6205 23.1377 17.9051 23.3734 18.2516 23.4804C18.3841 23.5213 18.5193 23.5415 18.654 23.5415C18.8725 23.5415 19.0887 23.4882 19.287 23.3835L23.2676 21.2801C23.9301 20.9298 24.1844 20.1062 23.8343 19.444L15.0001 2.72487C14.8309 2.40393 14.5462 2.16826 14.1997 2.06127C13.8519 1.95362 13.4849 1.98898 13.1644 2.15818L9.335 4.1816V3.38108C9.335 2.62281 8.71817 2.00599 7.9599 2.00599ZM9.39587 5.33271C9.43079 5.21991 9.50733 5.12725 9.61163 5.0722L13.5923 2.96883C13.6572 2.93458 13.7275 2.91735 13.7982 2.91735C13.8421 2.91735 13.8864 2.92384 13.9294 2.93727C14.0422 2.97196 14.1344 3.04873 14.1899 3.15325L23.0241 19.8723C23.0792 19.9766 23.0904 20.0961 23.0555 20.209C23.0206 20.3218 22.944 20.4144 22.8397 20.4695L18.859 22.5728C18.7538 22.6283 18.6343 22.6395 18.522 22.6044C18.4092 22.5697 18.317 22.4929 18.2615 22.3886L9.42721 5.66955C9.37215 5.56525 9.36096 5.44551 9.39587 5.33271ZM8.41827 22.6324C8.41827 22.8851 8.21281 23.0907 7.9599 23.0907H3.37626C3.12336 23.0907 2.9179 22.8851 2.9179 22.6324V3.38108C2.9179 3.1284 3.12336 2.92272 3.37626 2.92272H7.9599C8.21281 2.92272 8.41827 3.1284 8.41827 3.38108V22.6324Z"
        fill={props.color}
      />
      <path
        d="M5.66825 17.1328C4.40462 17.1328 3.37643 18.161 3.37643 19.4246C3.37643 20.6883 4.40462 21.7165 5.66825 21.7165C6.93189 21.7165 7.96007 20.6883 7.96007 19.4246C7.96007 18.161 6.93189 17.1328 5.66825 17.1328ZM5.66825 20.7997C4.90998 20.7997 4.29316 20.1829 4.29316 19.4246C4.29316 18.6664 4.90998 18.0495 5.66825 18.0495C6.42652 18.0495 7.04335 18.6664 7.04335 19.4246C7.04335 20.1829 6.42652 20.7997 5.66825 20.7997Z"
        fill={props.color}
      />
      <path
        d="M7.27253 8.76562H4.06398C3.68485 8.76562 3.37643 9.07404 3.37643 9.45317V15.4119C3.37643 15.791 3.68485 16.0994 4.06398 16.0994H7.27253C7.65166 16.0994 7.96007 15.791 7.96007 15.4119V9.45317C7.96007 9.07404 7.65166 8.76562 7.27253 8.76562ZM7.04335 15.1827H4.29316V9.68235H7.04335V15.1827Z"
        fill={props.color}
      />
      <path
        d="M7.50171 4.29688H3.8348C3.58144 4.29688 3.37643 4.50211 3.37643 4.75524C3.37643 5.00837 3.58144 5.2136 3.8348 5.2136H7.50171C7.75506 5.2136 7.96007 5.00837 7.96007 4.75524C7.96007 4.50211 7.75506 4.29688 7.50171 4.29688Z"
        fill={props.color}
      />
      <path
        d="M7.50171 6.58984H3.8348C3.58144 6.58984 3.37643 6.79508 3.37643 7.04821C3.37643 7.30134 3.58144 7.50657 3.8348 7.50657H7.50171C7.75506 7.50657 7.96007 7.30134 7.96007 7.04821C7.96007 6.79508 7.75506 6.58984 7.50171 6.58984Z"
        fill={props.color}
      />
      <path
        d="M18.1151 16.3451C17.0135 16.9272 16.591 18.2969 17.1729 19.3983C17.5775 20.1639 18.3626 20.6017 19.1742 20.6017C19.5296 20.6017 19.8899 20.5176 20.2256 20.3403C20.7592 20.0583 21.1513 19.5856 21.3295 19.0089C21.5072 18.4323 21.4499 17.8209 21.1679 17.2873C20.8863 16.7537 20.4132 16.3618 19.8367 16.1839C19.2597 16.0053 18.6482 16.0631 18.1151 16.3451ZM19.5663 17.0599C19.9092 17.1655 20.1898 17.3985 20.3577 17.7157C20.5251 18.0326 20.5591 18.3961 20.4535 18.7385C20.3474 19.0811 20.1146 19.3623 19.7977 19.5297C19.1424 19.8759 18.3286 19.6241 17.9831 18.9699C17.6375 18.3155 17.8886 17.5015 18.543 17.1557C18.7391 17.0521 18.9531 16.9995 19.1688 16.9995C19.3018 16.9995 19.4356 17.0194 19.5663 17.0599Z"
        fill={props.color}
      />
      <path
        d="M12.5512 10.2224C12.4966 10.3978 12.5141 10.584 12.6 10.7467L15.3301 15.9143C15.416 16.0768 15.5601 16.1961 15.7356 16.2502C15.8027 16.2708 15.8708 16.2811 15.9393 16.2811C16.0494 16.2811 16.1591 16.2543 16.2593 16.2012L19.0386 14.7328L19.0395 14.7326C19.3739 14.5551 19.5023 14.1384 19.3255 13.8035L16.5955 8.63598C16.5095 8.4735 16.3654 8.35421 16.1899 8.30004C16.0149 8.24678 15.8292 8.26334 15.6662 8.34906L12.8869 9.8177C12.7245 9.90342 12.6054 10.0471 12.5512 10.2224ZM15.8918 9.26668L18.4079 14.0291L16.0337 15.2836L13.5176 10.5214L15.8918 9.26668Z"
        fill={props.color}
      />
      <path
        d="M10.8476 6.90831C10.9197 6.90831 10.9931 6.8913 11.0612 6.85527L14.246 5.17243C14.4698 5.05404 14.5557 4.77674 14.4371 4.55293C14.3194 4.32934 14.0419 4.2434 13.8181 4.36179L10.6332 6.04462C10.4094 6.16302 10.3235 6.44032 10.4421 6.66413C10.524 6.81968 10.6829 6.90831 10.8476 6.90831Z"
        fill={props.color}
      />
      <path
        d="M11.494 8.64845C11.5759 8.804 11.7348 8.89263 11.8995 8.89263C11.9716 8.89263 12.045 8.87562 12.113 8.83959L15.2974 7.15675C15.5212 7.03836 15.6072 6.76106 15.4886 6.53725C15.3708 6.31344 15.0929 6.22794 14.8695 6.34611L11.6851 8.02894C11.4613 8.14734 11.3754 8.42464 11.494 8.64845Z"
        fill={props.color}
      />
    </svg>
  );
}

export default QuestionSVG;
