/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { t, useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import { toast } from "react-toastify";
import * as yup from "yup";
import CancelButton from "../../../components/CancelButton";
import Header from "../../../components/Header";
import SubmitButton from "../../../components/SubmitButton";
import useCompanyLanguage from "../../../hooks/language";
import useColor from "../../../hooks/useColor";
import axiosPrivate from "../../../lib/axiosPrivate";
import {
  addAdminGroupPermission,
  getPermissions,
} from "../../../services/adminPermissionService";
import {
  alertMessageSelector,
  setErr,
  setErrors,
  setSuccess,
} from "../../../store/reducers/alertMessageReducer";
import { RootState, useAppDispatch } from "../../../store/store";
import { defaultThemeColor } from "../../../utils/colors_values";
import { permissionGroupLabel } from "../../../utils/staticEntities";
import Style from "./AddNewPermission.module.scss";
import "./CustomCheckBox.scss";

const schema = yup
  .object({
    name: yup
      .string()
      .max(150, t("Maximum 150 characters are allowed")!)
      .required("Name is required"),
    user_list: yup
      .array()
      .typeError("At least one member must be required")
      .min(1, "At least one member must be required")
      .required("Member is required"),
    permissions: yup
      .array()
      .of(yup.number())
      .typeError("At least one permission must be required")
      .min(1, "At least one permission must be required")
      .required("Permission's is required"),
  })
  .required();

function AddNewPermission() {
  const {
    control,
    setError,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const states = useSelector(alertMessageSelector).alertMessageReducer;
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const dispatch = useAppDispatch();
  const [permissionsGroup, setPermissionsGroup] = useState<any>([]);
  const permissionList = useSelector(
    (state: RootState) => state.adminPermissionReducer.permissions
  );

  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  useEffect(() => {
    const permissionsTitle = permissionList?.map((row: any, ind: any) => {
      return row?.codename?.split("_").pop();
    });
    const groupArray = permissionsTitle?.filter(
      (value, index, self) => self.indexOf(value) === index
    );
    let newArray: any = [];
    groupArray?.map((gr: any, index: any) => {
      newArray[index] = {
        title: gr,
        children: [],
      };
      permissionList?.map((row: any, ind: any) => {
        if (row?.codename?.toLowerCase().endsWith("_" + gr)) {
          newArray[index]["children"][ind] = row;
        }
      });
    });
    setPermissionsGroup(newArray);
  }, [permissionList]);
  const Submit = (payload: any) => {
    let ids: any[] = [];
    payload?.user_list.map((row: any) => {
      ids.push(row.value);
    });
    const payloadData = {
      name: payload.name,
      user_list: ids,
      permissions: payload?.permissions,
    };
    dispatch(addAdminGroupPermission(payloadData));
  };

  useEffect(() => {
    if (states?.errors) {
      if (states?.errors?.name?.length > 0) {
        toast.error(states?.errors?.name?.toString(), {
          toastId: states?.errors?.name?.toString(),
        });
        setError("name", {
          type: "server",
          message: states?.errors?.name?.toString(),
        });
      }
      if (states?.errors?.user_list?.length > 0) {
        toast.error(states?.errors?.user_list?.toString(), {
          toastId: states?.errors?.user_list?.toString(),
        });
        setError("user_list", {
          type: "server",
          message: states?.errors?.user_list?.toString(),
        });
      }
      if (states?.errors?.permissions?.length > 0) {
        toast.error(states?.errors?.permissions?.toString(), {
          toastId: states?.errors?.permissions?.toString(),
        });
        setError("permissions", {
          type: "server",
          message: states?.errors?.permissions?.toString(),
        });
      }
      dispatch(setErrors(null));
    }
    if (states?.error) {
      toast.error(String(states?.error), {
        toastId: String(states?.error),
      });
      dispatch(setErr(null));
    }
    if (states?.success) {
      toast.success(String(states?.success), {
        toastId: String(states?.success),
      });
      dispatch(setSuccess(null));
      navigate("/admin-permission");
    }
  }, [states]);
  const loadOptions = async (
    searchQuery: any,
    loadedOptions: any,
    { page }: any,
    excludeIds?: any
  ) => {
    const response = await axiosPrivate.get(
      `accounts/users/?page=${page}&page_size=100&search=${searchQuery}&primary_role__in=Admin&is_superuser=False`
    );
    const data = response.data;
    const options = data?.results.map((row: any) => {
      return {
        label:
          row?.first_name + " " + row?.last_name + " (" + row.email + ")" ||
          "N/A",
        value: row.id,
      };
    });
    const users = {
      options: options || [],
      hasMore: data?.next ? true : false,
      additional: {
        page: searchQuery ? 1 : page + 1,
      },
    };

    return users;
  };
  const foregroundTextColor =
    useColor("interface_foreground_text_color") ||
    defaultThemeColor.interface_foreground_text_color;

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;
  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;

  const primaryButton =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;
  const primaryButtonBg =
    useColor("button_primary") || defaultThemeColor.button_primary;
  return (
    <div
      className={`${Style.background}`}
      style={{
        background:
          useColor("interface_background_color") ||
          defaultThemeColor.interface_background_color,
      }}
    >
      <div style={{ paddingBottom: "2.4em" }}>
        <Header
          page={t("Create Admin Permission's Group")}
          mainHead={t("Admin Permissions")}
          backLink="/admin-permission"
        />
      </div>
      <div>
        <div
          style={{
            background:
              useColor("interface_foreground_color") ||
              defaultThemeColor.interface_foreground_color,
            color:
              useColor("interface_foreground_text_color") ||
              defaultThemeColor.interface_foreground_text_color,
            borderRadius: 10,
            height: "auto",
          }}
          className={Style.bottomSpace}
        >
          <div className={`${Style.leftSpace2} ${Style.topSpace} mb-4`}>
            <div
              className={`${Style.textHeader}`}
              style={{
                color: foregroundTextColor,
                textAlign: lang === "ar" ? "right" : "left",
                marginRight: lang === "ar" ? "3rem" : "0rem",
              }}
            >
              {t("Create Admin Permission's Group")}
            </div>
          </div>
          <form onSubmit={handleSubmit(Submit)} autoComplete="off">
            <div className={`${Style.alignItemsInside}`}>
              <div
                className={`row px-0 mx-0 justify-content-between align-items-center`}
                style={{ display: "flex" }}
              >
                <div
                  className="col-lg-6 position-relative"
                  style={{ marginTop: "10px" }}
                >
                  <span className={Style.labelHeader}>
                    {t("Name")}
                    <span style={{ color: "red" }}> *</span>
                  </span>
                  <input
                    style={{
                      marginTop: "1rem",
                      width: "100%",
                      color:
                        useColor("interface_background_text_color") ||
                        defaultThemeColor.interface_background_text_color,
                      background:
                        useColor("interface_background_color") ||
                        defaultThemeColor.interface_background_color,
                      outline: "none",
                    }}
                    className={`${Style.inputfields} ${Style.fieldFont}  ${
                      errors.name ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder={t("Name")}
                    autoComplete="off"
                    {...register("name")}
                  />
                  <p className="error color-red position-absolute">
                    {errors.name?.message}
                  </p>
                </div>
                <div className={"col-lg-6 mt-md-3 position-relative"}>
                  <span className={Style.labelHeader}>
                    {t("Members")}
                    <span style={{ color: "red" }}> *</span>
                  </span>
                  <Controller
                    control={control}
                    name="user_list"
                    render={({ field: { value, onChange, ref } }) => (
                      <AsyncPaginate
                        loadOptions={loadOptions}
                        onChange={onChange}
                        selectRef={ref}
                        value={value}
                        getOptionValue={(option: any) => option.value}
                        getOptionLabel={(option: any) => option.label}
                        className="add-member-select"
                        additional={{
                          page: 1,
                        }}
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused
                              ? interfaceBgColor
                              : "",
                            color: state.isFocused ? interfaceTextColor : "",
                          }),
                          valueContainer: (provided, state) => ({
                            ...provided,
                            padding: "0.9em",
                            overflow: "auto",
                            backgroundColor: interfaceBgColor,
                            color: interfaceTextColor,
                          }),
                          multiValue: (baseStyles) => ({
                            ...baseStyles,
                            backgroundColor: primaryButtonBg,
                          }),
                          multiValueLabel: (baseStyles) => ({
                            ...baseStyles,
                            color: primaryButton,
                          }),
                          multiValueRemove: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: primaryButtonBg,
                            color: primaryButton,
                            ":hover": {
                              backgroundColor: primaryButtonBg,
                              color: primaryButton,
                            },
                          }),
                        }}
                        isMulti
                        isClearable
                        isSearchable={true}
                        placeholder={t("Select Members")}
                        debounceTimeout={500}
                      />
                    )}
                  />
                  <p className="color-red position-absolute">
                    {errors.user_list?.message}
                  </p>
                </div>
              </div>
            </div>
            <div className={`${Style.alignItemsFeatures}`}>
              <span className={Style.labelHeader}>
                <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                  {t("Feature Permissions")}
                </span>
                <span style={{ color: "red" }}> *</span>
              </span>
              <div className="row justify-content-between">
                {permissionsGroup?.map((row: any, index: number) => {
                  return (
                    <div className={`col-lg-6 col-md-12`} key={row.title}>
                      <label
                        className={`${Style.labelManagement} my-3`}
                        key={`keys-${row.title}`}
                        style={{
                          color: foregroundTextColor,
                        }}
                      >
                        {t(permissionGroupLabel[row?.title])}
                      </label>
                      <div
                        className="checkBoxWrapper p-3 pb-0"
                        key={`keys-keys-${row.title}`}
                        style={{
                          color: foregroundTextColor,
                        }}
                      >
                        {row?.children?.map((ch: any) => {
                          return (
                            <label
                              className={`checkBoxContainer ${Style.permissionsText}`}
                              style={{
                                color: foregroundTextColor,
                              }}
                              key={`keys-${ch?.id}`}
                            >
                              <span
                                style={{
                                  textTransform: "capitalize",
                                  color: foregroundTextColor,
                                }}
                              >
                                {ch.name
                                  ?.toLowerCase()
                                  .replace("custom user", "")
                                  .replace("dynamic form fields", "Form")
                                  .replace("can ", "")
                                  .replace("change", "Edit")}
                              </span>

                              <input
                                type="checkbox"
                                value={ch?.id}
                                {...register("permissions")}
                              />
                              <span className="checkmark"></span>
                            </label>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
              <p className="color-red">{errors.permissions?.message}</p>
            </div>
            <div className="d-flex justify-content-end w-100 gap-4 pt-5 p-4 p-md-5">
              <SubmitButton label={t("Save")} marginTop={false} />
              <CancelButton
                size="small"
                outlined={true}
                onClick={() => {
                  navigate("/admin-permission");
                }}
                textColor=""
                outlineColor=""
                text={t("Cancel")}
                className={""}
                bg={""}
              ></CancelButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddNewPermission;
