function BrowseTrainingSVG(props: { color: string }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3639 13.121C15.2879 14.045 15.4839 15.421 14.9499 16.536L16.4849 18.071C16.6671 18.2596 16.7679 18.5123 16.7656 18.7744C16.7633 19.0366 16.6582 19.2875 16.4728 19.4729C16.2874 19.6583 16.0365 19.7634 15.7743 19.7657C15.5121 19.768 15.2595 19.6672 15.0709 19.485L13.5359 17.95C12.8973 18.2543 12.1717 18.3231 11.4873 18.1445C10.8028 17.9658 10.2035 17.5511 9.79505 16.9735C9.38661 16.396 9.19532 15.6927 9.25495 14.9878C9.31457 14.2829 9.62128 13.6218 10.1209 13.121C10.3995 12.8424 10.7303 12.6214 11.0943 12.4706C11.4583 12.3198 11.8484 12.2422 12.2424 12.2422C12.6365 12.2422 13.0266 12.3198 13.3906 12.4706C13.7546 12.6214 14.0854 12.8424 14.3639 13.121ZM12.9499 15.95C13.0455 15.8578 13.1216 15.7475 13.174 15.6255C13.2265 15.5034 13.254 15.3722 13.2552 15.2394C13.2563 15.1067 13.231 14.975 13.1808 14.8521C13.1305 14.7292 13.0562 14.6175 12.9623 14.5237C12.8684 14.4298 12.7568 14.3555 12.6339 14.3052C12.511 14.2549 12.3793 14.2296 12.2465 14.2308C12.1138 14.2319 11.9825 14.2595 11.8605 14.3119C11.7385 14.3644 11.6282 14.4405 11.5359 14.536C11.3538 14.7246 11.253 14.9773 11.2553 15.2394C11.2575 15.5016 11.3627 15.7525 11.5481 15.9379C11.7335 16.1233 11.9843 16.2284 12.2465 16.2307C12.5087 16.233 12.7613 16.1322 12.9499 15.95Z"
        fill={props.color}
        stroke="white"
        strokeWidth="0.5"
      />
      <path
        d="M7.99994 5H15.9999V7H7.99994V5ZM15.9999 9H7.99994V11H15.9999V9Z"
        fill={props.color}
        stroke="white"
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.99994 4C3.99994 3.20435 4.31601 2.44129 4.87862 1.87868C5.44123 1.31607 6.20429 1 6.99994 1H16.9999C17.7956 1 18.5587 1.31607 19.1213 1.87868C19.6839 2.44129 19.9999 3.20435 19.9999 4V20C19.9999 20.7956 19.6839 21.5587 19.1213 22.1213C18.5587 22.6839 17.7956 23 16.9999 23H6.99994C6.20429 23 5.44123 22.6839 4.87862 22.1213C4.31601 21.5587 3.99994 20.7956 3.99994 20V4ZM6.99994 3H16.9999C17.2652 3 17.5195 3.10536 17.707 3.29289C17.8946 3.48043 17.9999 3.73478 17.9999 4V20C17.9999 20.2652 17.8946 20.5196 17.707 20.7071C17.5195 20.8946 17.2652 21 16.9999 21H6.99994C6.73472 21 6.48037 20.8946 6.29283 20.7071C6.1053 20.5196 5.99994 20.2652 5.99994 20V4C5.99994 3.73478 6.1053 3.48043 6.29283 3.29289C6.48037 3.10536 6.73472 3 6.99994 3Z"
        fill={props.color}
        stroke="white"
        strokeWidth="0.5"
      />
    </svg>
  );
}
export default BrowseTrainingSVG;
