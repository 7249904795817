import { CircularProgress } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import React, { useEffect, useState } from "react";
import { getLanguage, useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Paginations from "../../components/table/Paginations";
import useCompanyLanguage from "../../hooks/language";
import {
  getAllNotifications,
  getReadNotifications,
  getUnreadNotifications,
  markAllAsRead,
  updateNotificationStatus,
} from "../../services/notificationService";
import { setPage, setPerPage } from "../../store/reducers/notificationReducer";
import { RootState, useAppDispatch } from "../../store/store";
import PolicyAcceptReject from "../PolicyManagement/PolicyAcceptRejectDialogue";
import NotificationBar from "./NotificationBar";
import Style from "./Notifications.module.scss";

function Notifications() {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();

  const searchParams = new URLSearchParams(document.location.search);

  const [selectedType, setSelectedType] = useState<"all" | "read" | "unread">(
    "all"
  );

  const [notifications, setNotifications] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState<any>();
  const [toshow, setToShow] = useState<any>(false);
  const userInfo = useSelector((state: any) => state.authReducer.loginData);
  const [pageNum, setPageNum] = useState(Number(searchParams.get("page") || 1));
  const [perPageNum, setPerPageNum] = useState(
    Number(searchParams.get("perpage")) || 10
  );

  const dataList = useSelector(
    (state: RootState) => state.notificationReducer.data
  );

  const updateddata = useSelector(
    (state: RootState) => state.notificationReducer.updateddata
  );

  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );

  const policyData: any = useSelector(
    (state: RootState) => state.policyReducer.updatedData
  );

  useEffect(() => {
    setToShow(false);
    getNotifications();
  }, [
    searchParams.get("page"),
    searchParams.get("perpage"),
    searchParams.get("type"),
    updateddata,
    policyData,
  ]);

  useEffect(() => {
    setPerPageNum(10);
  }, [searchParams.get("type")]);

  const getNotifications = () => {
    dispatch(setPage(Number(searchParams.get("page") || 1)));
    dispatch(setPerPage(Number(searchParams.get("perpage") || 10)));

    let query: any = {};
    query.page = Number(searchParams.get("page") || 1);
    query.page_size = Number(searchParams.get("perpage") || 10);
    query.type = searchParams.get("type") || "all";

    if (query.type == "read") {
      dispatch(getReadNotifications(query));
    } else if (query.type == "unread") {
      dispatch(getUnreadNotifications(query));
    } else dispatch(getAllNotifications(query));
  };

  useEffect(() => {
    let data = dataList.results;
    if (
      Object.keys(dataList).length === 0 &&
      notifications &&
      notifications.length == 0
    ) {
      setNotifications([]);
      setTimeout(() => {
        setToShow(true);
      }, 1000);
    } else {
      setNotifications(data);
      setTimeout(() => {
        setToShow(true);
      }, 1000);
    }
  }, [dataList]);

  const urlWithQueryString = (
    pageVal = Number(pageNum),
    perPageVal = Number(perPageNum),
    read: any
  ) => {
    let object: any = {};
    if (pageVal) {
      object.page = pageVal;
    }
    if (perPageVal) {
      object.perpage = perPageVal;
    }

    if (read) {
      object.type = read;
    }

    const objString = "?" + new URLSearchParams(object).toString();
    navigate(`/notifications${objString}`);
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPageNum(page);
    urlWithQueryString(Number(page), Number(perPageNum), selectedType);
  };

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPageNum(1);
    setPerPageNum(rowsPerPage);
    urlWithQueryString(1, Number(rowsPerPage), selectedType);
  };

  const handleClose = (open: any) => {
    setOpen(false);
    setSelectedNotification("");
  };

  const handleMarkAllAsRead = () => {
    //handleMarkAllAsRead
    dispatch(
      markAllAsRead({
        read: true,
      })
    );
  };

  const handleClickNotification = (row: any) => {
    //handle click notification
    if (row?.notification_type == "NEW_POLICY_ASSIGNED") {
      // show popup to accept or reject
      setSelectedNotification(row);
      setOpen(true);
    } else if (
      (row?.notification_type === "NEW_PERMISSION" ||
        row?.notification_type === "UPDATE_PERMISSION") &&
      !userInfo?.is_superadmin &&
      userInfo?.primary_role === "Admin"
    ) {
      if (!row?.read) {
        handleReadNotificationStatus(row);
      }
      navigate(`/my-profile`);
      return;
    } else if (row?.notification_type === "NEW_POLICY_ASSESSMENT_ASSIGNED") {
      navigate(`/policy-assessment-start/${row?.instance?.id}`);
      return;
    } else {
      // mark as read
      if (row?.read) {
        // if read
        // do nothing
      } else {
        handleReadNotificationStatus(row);
      }
    }
  };

  const handleReadNotificationStatus = (noti: any) => {
    dispatch(
      updateNotificationStatus({
        id: noti?.id,
        read: true,
      })
    );
  };

  //   useColor("interface_background_text_color") ||
  //   defaultThemeColor.interface_background_text_color,

  return (
    <>
      <Header page={t("Notifications")} mainHead={t("Notifications")} />

      <div className="d-flex justify-content-between  mt-4 mb-2">
        <div
          className="d-flex justify-content-between"
          style={{ width: lang === "ar" ? "20%" : "9%" }}
        >
          <div
            className={`${
              selectedType == "all"
                ? Style.breadcrumbactive
                : Style.breadcrumbnormal
            } px-2 py-1`}
            onClick={() => {
              setSelectedType("all");
              urlWithQueryString(1, Number(10), "all");
            }}
          >
            {t("All")}
          </div>
          <div
            className={`${
              selectedType == "read"
                ? Style.breadcrumbactive
                : Style.breadcrumbnormal
            } mx-3 px-2 py-1`}
            onClick={() => {
              setSelectedType("read");
              urlWithQueryString(1, Number(10), "read");
            }}
          >
            {t("Read")}
          </div>
          <div
            className={`${
              selectedType == "unread"
                ? Style.breadcrumbactive
                : Style.breadcrumbnormal
            } mr-3 px-2 py-1`}
            onClick={() => {
              setSelectedType("unread");
              urlWithQueryString(1, Number(10), "unread");
            }}
          >
            {t("Unread")}
          </div>
        </div>
        <div
          className={`${Style.markasRead} ${Style.breadcrumbnormal}`}
          onClick={handleMarkAllAsRead}
        >
          {t("Mark all as Read")}
        </div>
      </div>

      {loading ? (
        <div className="w-100 text-align-center d-flex justify-content-center mt-5 pt-5">
          <CircularProgress size="3rem" color="primary" />
        </div>
      ) : notifications && notifications.length > 0 ? (
        notifications?.map((noti: any, i: any) => (
          <div
            key={i}
            className={`w-100 mx-1`}
            onClick={() => handleClickNotification(noti)}
          >
            <NotificationBar
              row={noti}
              index={i + 1}
              number={notifications.length}
              header={noti?.notification_type.replaceAll("_", " ") || ""}
              secondaryText={
                getLanguage() === "ar"
                  ? noti?.instance?.arabic_content
                  : noti?.instance?.content || ""
              }
              activeBar={!noti?.read}
              date={noti?.created_at}
            />
          </div>
        ))
      ) : toshow ? (
        <div className="w-100">
          <div
            className={`d-flex justify-content-center w-100 mt-5 pt-5 overflow-hidden ${Style.bigText}`}
          >
            {t("No Notifications Found!")}
          </div>
        </div>
      ) : null}

      {!loading && notifications && notifications.length > 0 && (
        <Paginations
          totalCount={Number(dataList?.count)}
          perpage={Number(searchParams.get("perpage") || 10)}
          pagenum={Number(searchParams.get("page") || 1)}
          handleChangePageNum={handleChangePage}
          handleChangeRowsPerPageParent={handleChangeRowsPerPage}
        />
      )}

      <PolicyAcceptReject
        setParentOpen={setOpen}
        open={open}
        onClose={handleClose}
        isLoading={loading}
        policy={selectedNotification && selectedNotification?.instance}
      />
    </>
  );
}

export default Notifications;
