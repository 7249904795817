import AddIcon from "@mui/icons-material/Add";
import DatasetLinkedOutlinedIcon from "@mui/icons-material/DatasetLinkedOutlined";
import { Button } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import DataTable from "../../components/table/DataTable";
import TableFilters from "../../components/table/TableFilters";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import usePermission from "../../hooks/usePermission";
import DeleteSVG from "../../icons/DeleteSVG";
import EditSVG from "../../icons/EditSVG";
import ViewSVGAction from "../../icons/ViewSVGAction";
import {
  bulkDeleteModules,
  deleteModule,
  getModules,
  getWholeModules,
} from "../../services/moduleService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import {
  setModuleData,
  setWholeData,
} from "../../store/reducers/moduleReducer";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import { confirmProps } from "../../utils/confirmProps";
import Style from "./ModuleListing.module.scss";

function ModuleListing() {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();

  const addPermission = usePermission("add_module");
  const editUpdatePermission = usePermission("change_module");
  const deletePermission = usePermission("delete_module");
  const viewPermission = usePermission("view_module");
  const userInfo = useSelector((state: any) => state.authReducer.loginData);
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<number[]>([]);
  const dataList = useAppSelector((state) => state.moduleReducer.data);
  const wholeData = useAppSelector((state) => state.moduleReducer.wholeData);
  const searchParams = new URLSearchParams(document.location.search);

  const [pageNum, setPageNum] = useState(Number(searchParams.get("page") || 1));
  const [perPageNum, setPerPageNum] = useState(
    Number(searchParams.get("perpage")) || 10
  );
  const [pageSearch, setPageSearch] = useState(
    searchParams.get("search") || ""
  );
  const [rows, setRows] = useState([]);
  const [list, setList] = useState([]);
  const [searchTable, setSearchTable] = useState("");
  //   const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  //   const [defaultFormValues, setDefaultFormValues] = useState({});
  //   const [modalHeading, setModalHeading] = useState("");
  //   const [modalButtonLable, setModalButtonLable] = useState("");
  const alertMessage = useAppSelector(alertMessageSelector).alertMessageReducer;

  useEffect(() => {
    AllModules();
  }, [
    searchParams.get("page"),
    searchParams.get("perpage"),
    searchParams.get("search"),
  ]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }

    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      AllModules();
    }
  }, [alertMessage]);

  const AllModules = () => {
    let query: any = {};
    query.page = Number(searchParams.get("page") || 1);

    query.page_size = Number(searchParams.get("perpage") || 10);

    query.search = String(searchParams.get("search") || "");

    query.category = "";
    query.status = "";
    query.type = "";
    dispatch(getModules(query));
  };

  const urlWithQueryString = (
    pageVal = Number(pageNum),
    perPageVal = Number(perPageNum),
    searchVal = String(pageSearch)
  ) => {
    let object: any = {};
    if (pageVal) {
      object.page = pageVal;
    }
    if (perPageVal) {
      object.perpage = perPageVal;
    }
    if (searchVal) {
      object.search = searchVal;
    }

    const objString = "?" + new URLSearchParams(object).toString();

    navigate(`/module/module-listing${objString}`);
  };
  const handleDeleteAction = (row: any) => {
    return confirm({
      title: t("Are you sure!"),
      description: t(`Do you want to delete this module?`),
      ...confirmProps,
    })
      .then(() => {
        dispatch(deleteModule(row.id));
      })
      .catch(() => {});
  };
  const handleClickEvent = (data: any) => {
    console.log("handleClickEvent", data);
    dispatch(setModuleData(data));
    navigate(`/detail-module/${data?.id}`);
  };
  const modifyRows = () => {
    let f_rows: any = [];
    dataList?.results?.forEach(
      (
        r: {
          id: string;
          name: string;
          description: string;
          category: { id: number; name: string };
          type: string;
          status: string;
          is_editable: boolean;
          created_by: any;
        },
        i: number
      ) => {
        f_rows =
          userInfo?.primary_role == "Admin"
            ? [
                ...f_rows,
                {
                  id: r.id,
                  name: r.name,
                  category: r.category?.name,
                  type: r.type,
                  created_by: r?.created_by?.first_name
                    ? r?.created_by?.first_name + " " + r?.created_by?.last_name
                    : "N/A",
                  status: (
                    <span
                      className={
                        r.status == "Approved"
                          ? Style.statusSuccess
                          : Style.statusDraft
                      }
                    >
                      {r.status == "Approved" ? "Approved" : "Draft"}
                    </span>
                  ),
                  actions: (
                    <>
                      <div style={{ textAlign: "center", paddingLeft: "1em" }}>
                        {viewPermission && (
                          <Link
                            className=""
                            to={`/module-link/${r.id.toString()}`}
                            style={{ cursor: "pointer" }}
                          >
                            <button className="btn-link" type="button">
                              <DatasetLinkedOutlinedIcon
                                // htmlColor="#2B3674"
                                htmlColor="#424F97"
                              />
                            </button>
                          </Link>
                        )}
                        {viewPermission && (
                          // <Link
                          //   className=""
                          //   to={`/detail-module/${r.id.toString()}`}
                          //   style={{ cursor: "pointer" }}
                          // >
                          <button
                            className="btn-link"
                            type="button"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleClickEvent(r);
                            }}
                          >
                            <ViewSVGAction />
                          </button>
                          // </Link>
                        )}
                        {editUpdatePermission && (
                          <Link
                            className=""
                            to={`/edit-module/${r.id.toString()}`}
                            style={{ cursor: "pointer" }}
                          >
                            <button className="btn-link" type="button">
                              <EditSVG />
                            </button>
                          </Link>
                        )}
                        {deletePermission && (
                          <button
                            className="btn-link"
                            type="button"
                            onClick={(e) => {
                              handleDeleteAction(r);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <DeleteSVG color="red" />
                          </button>
                        )}
                      </div>
                    </>
                  ),
                },
              ]
            : [
                ...f_rows,
                {
                  id: r.id,
                  name: r.name,
                  category: r.category?.name,
                  type: r.type,

                  status: (
                    <span
                      className={
                        r.status == "Approved"
                          ? Style.statusSuccess
                          : Style.statusDraft
                      }
                    >
                      {r.status == "Approved" ? "Approved" : "Draft"}
                    </span>
                  ),
                  actions: (
                    <>
                      <div style={{ textAlign: "center", paddingLeft: "1em" }}>
                        {viewPermission && (
                          <Link
                            className=""
                            to={`/detail-module/${r.id.toString()}`}
                          >
                            <button className="btn-link" type="button">
                              <ViewSVGAction />
                            </button>
                          </Link>
                        )}
                        {editUpdatePermission && (
                          <Link
                            className=""
                            to={`/edit-module/${r.id.toString()}`}
                          >
                            <button className="btn-link" type="button">
                              <EditSVG />
                            </button>
                          </Link>
                        )}
                        {deletePermission && (
                          <button
                            className="btn-link"
                            type="button"
                            onClick={(e) => {
                              handleDeleteAction(r);
                            }}
                          >
                            <DeleteSVG color="red" />
                          </button>
                        )}
                      </div>
                    </>
                  ),
                },
              ];
      }
    );
    setList(f_rows);
  };
  // useEffect(() => {
  //   let data: any = dataList.results;
  //   if (Object.keys(dataList).length === 0) {
  //     setRows([]);
  //   } else setRows(data);
  // }, [dataList]);
  useEffect(() => {
    modifyRows();
  }, [dataList]);
  const handleAddNew = () => {
    // setModalHeading("Add Category");
    // setModalButtonLable("Add");
    // setDefaultFormValues({});
    // setOpenEditModal(true);
  };
  const handleChangePage = (event: React.ChangeEvent<unknown>, val: number) => {
    setPageNum(Number(val));
    urlWithQueryString(Number(val), Number(perPageNum), pageSearch);
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPageNum(1);
    setPerPageNum(Number(rowsPerPage));
    urlWithQueryString(1, Number(rowsPerPage), pageSearch);
  };
  const handleChangeSearch = (val: string) => {
    setPageNum(1);
    setPageSearch(String(val));
    setPerPageNum(Number(10));
    urlWithQueryString(1, perPageNum, String(val));
  };
  const bulkDelete = (ids: any) => {
    // const sendSelected = selected.map((sel) => sel);
    confirm({
      title: t("Are you sure!"),
      description: t(`Do you want to delete selected modules?`),
      ...confirmProps,
    })
      .then(() => {
        dispatch(bulkDeleteModules(ids));
        setSelectedCheckBox([]);

        urlWithQueryString(1, 10, pageSearch);
      })
      .catch(() => {
        setSelectedCheckBox([]);
      });
  };

  const AddGroup = () => {
    const handleAddNew = () => {
      setList([]);
      navigate("/module/add-module");
    };

    const interfaceBgTextColor =
      useColor("interface_background_text_color") ||
      defaultThemeColor.interface_background_text_color;

    return (
      <div className="d-flex flex-row gap-3">
        <>
          {addPermission && (
            <Button
              variant="text"
              color="inherit"
              endIcon={
                <AddIcon
                  style={{
                    fontSize: 16,
                    color: interfaceBgTextColor,
                    marginRight: lang === "ar" ? "10px" : "0px",
                  }}
                />
              }
              style={{
                fontSize: 13,
                color: interfaceBgTextColor,
                textTransform: "capitalize",
              }}
              onClick={() => handleAddNew()}
            >
              {t("Add New")}
            </Button>
          )}
        </>
      </div>
    );
  };

  const handleClicked = () => {
    getWhole();
  };

  const getWhole = () => {
    if (dataList)
      dispatch(
        getWholeModules({
          page_size: dataList.count,
          page: 1,
          search: "",
          for: "export",
        })
      );
  };

  return (
    <div className={`${Style.categoryStart}`}>
      <div style={{ paddingBottom: "0.6em" }}>
        <Header
          page={t("Module List")}
          mainHead={t("Training Management / Module")}
        />
        <TableFilters
          dataList={dataList}
          itemPerPage={perPageNum}
          setItemPerPage={handleChangeRowsPerPage}
          selectedCheckBox={selectedCheckBox}
          bulkDelete={bulkDelete}
          setSearchTable={handleChangeSearch}
          createButton={<AddGroup />}
          handle="modules"
          isCheckboxes={deletePermission}
          exportCSV={dataList?.count ? true : false}
          setParentData={() => dispatch(setWholeData({}))}
          handleClicked={handleClicked}
          rows={wholeData?.results?.map((r: any, i: any) => ({
            // id: r.id,
            name: r.name,
            category: r.category?.name,
            type: r.type,
            status: r.status == "Approved" ? "Approved" : "Draft",
          }))}
          headers={[
            // "ID",
            "Name",
            "Description",
            "Category",
            "Type",
            "Date Modified",
            "Status",
          ]}
          filename={`assessment_list.csv`}
        />

        <DataTable
          columnData={
            userInfo?.primary_role == "Admin"
              ? [
                  {
                    id: "name",
                    name: t("Name"),
                    enableSort: true,
                    align: lang === "ar" ? "center" : "left",
                    disablePadding: "none",
                  },
                  {
                    id: "category",
                    name: t("Category"),
                    enableSort: true,
                    align: lang === "ar" ? "center" : "left",
                    disablePadding: "none",
                  },
                  {
                    id: "type",
                    name: t("Type"),
                    enableSort: true,
                    align: lang === "ar" ? "center" : "left",
                    disablePadding: "none",
                  },
                  {
                    id: "created_by",
                    name: t("Created By"),
                    enableSort: true,
                    align: lang === "ar" ? "center" : "left",
                    disablePadding: "none",
                  },
                  {
                    id: "status",
                    name: t("Status"),
                    enableSort: false,
                    align: "center",
                    disablePadding: "checkbox",
                  },

                  {
                    id: "actions",
                    name: t("Actions"),
                    enableSort: false,
                    align: "center",
                    disablePadding: "none",
                  },
                ]
              : [
                  {
                    id: "name",
                    name: t("Name"),
                    enableSort: true,
                    align: lang === "ar" ? "center" : "left",
                    disablePadding: "none",
                  },
                  {
                    id: "category",
                    name: t("Category"),
                    enableSort: true,
                    align: lang === "ar" ? "center" : "left",
                    disablePadding: "none",
                  },
                  {
                    id: "type",
                    name: t("Type"),
                    enableSort: true,
                    align: lang === "ar" ? "center" : "left",
                    disablePadding: "none",
                  },

                  {
                    id: "status",
                    name: t("Status"),
                    enableSort: false,
                    align: "center",
                    disablePadding: "checkbox",
                  },

                  {
                    id: "actions",
                    name: t("Actions"),
                    enableSort: false,
                    align: "center",
                    disablePadding: "none",
                  },
                ]
          }
          rows={list}
          isCheckboxes={deletePermission}
          selectedRowsPerPage={String(searchParams.get("perpage") || 10)}
          totalRecordCount={dataList.count}
          handleChangePageParent={handleChangePage}
          pagenum={Number(searchParams.get("page") || 1)}
          handleChangeRowsPerPageParent={handleChangeRowsPerPage}
          bulkDelete={bulkDelete}
          selectedCheckBox={selectedCheckBox}
          setSelectedCheckBox={setSelectedCheckBox}
        />
      </div>
      {/* <CategoryModal
        openEditModal={openEditModal}
        CloseEditModal={CloseEditModal}
        defaultFormValues={defaultFormValues}
        modalHeading={modalHeading}
        modalButtonLable={modalButtonLable}
      /> */}
    </div>
  );
}

export default ModuleListing;
