import { useEffect, useRef, useState } from "react";

function useVisible(initialIsVisible: any) {
  const [isVisible, setIsVisible] = useState(initialIsVisible);
  const ref = useRef<any>();

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref?.current?.contains(event.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return { ref, isVisible, setIsVisible };
}

export default useVisible;
