/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DeleteSVG from "../icons/DeleteSVG";
import EditSVG from "../icons/EditSVG";
import PinSVG from "../icons/PinSVG";
import UploadSVG from "../icons/UploadSVG";
import { formatBytes } from "../utils/formatBytesToDelimeters";
import { formatBytesWithoutDM } from "../utils/formatBytesWithoutDelimeter";
import AddImagesFromLibrary from "./AddImages/AddImagesFromLibrary";
import Style from "./UploadFile.module.scss";
import useColor from "../hooks/useColor";
import { defaultThemeColor } from "../utils/colors_values";
import { useTranslation } from "react-multi-lang";
import useCompanyLanguage from "../hooks/language";
import { RootState } from "../store/store";
import { useSelector } from "react-redux";
const $ = require("jquery");

const classes = {
  progressBar: {
    // Disable the transition animation from 100 to 0 inside the progress bar
  },
  progressBarInner: {},
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 9,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#14276F" : "#14276F",
  },
  [`& [aria-valuenow="0"]`]: {
    "& > $progressBarInner": {
      transition: "none",
    },
  },
}));

interface UploadFileProps {
  size: string;
  fileTypes: string;
  loading: boolean;
  errors: any;
  setValue: any;
  imageName: string;
  clearErrors: any;
  acceptedImageTypesArray: any;
  setImageParent: any;
  maxFileSizeinBytes: number;
  setValueText?: any;
  questionName?: any;
  questionTextError?: any;
  register?: any;
  watch?: any;
  setParentImage?: any;
}

function UploadFile({
  size,
  fileTypes,
  loading,
  errors,
  setValue,
  setValueText,
  questionName,
  questionTextError,
  imageName,
  clearErrors,
  acceptedImageTypesArray,
  setImageParent,
  maxFileSizeinBytes,
  register,
  watch,
  setParentImage,
}: UploadFileProps) {
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedImageBase64, setSelectedImageBase64] = useState<any>();
  const [image, setImage] = useState<any>();
  const [fileSize, setFileSize] = useState<any>(0);
  const [fileSizeBytes, setFileSizeBytes] = useState<any>(0);
  const [fileName, setFileName] = useState<any>("");
  const [fileType, setFileType] = useState<any>("");
  const [progress, setProgress] = useState<any>();
  const [imageup, setImageUp] = useState(0);
  const [sizeProgress, setSizeProgress] = useState(0);
  const [selectedImageData, setSelectedImageData] = useState<string | ArrayBuffer | null>(null);
  const [open, setOpen] = React.useState(false);
  const t = useTranslation();
  const lang = useCompanyLanguage();

  $(document).ready(function () {
    $("#OpenImgUpload").unbind("click");
    $("#OpenImgUpload").click(function (event: any) {
      $("#file").click();
    });
    $("#file").click(function (event: any) {
      event.stopPropagation();
    });
  });

  $(document).ready(function () {
    $("#addImageFromLibrary").unbind("click");
    $("#addImageFromLibrary").click(function (event: any) {
      setOpen(true);
    });
  });

  $(document).ready(function () {
    $("#OpenImgUpload2").unbind("click");
    $("#OpenImgUpload2").click(function (event: any) {
      $("#file").click();
    });
    $("#file").click(function (event: any) {
      event.stopPropagation();
    });
  });

  useEffect(() => {
    setImage("");
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const readURL = (input: any) => {
    input = document?.getElementById("file");
    if (input.files && input.files[0]) {
      let file = input.files[0];
      const fileType = file["type"];
      const validImageTypes = acceptedImageTypesArray;
      if (!validImageTypes.includes(fileType)) {
        toast.error(String(t("Please select valid image file")), {
          toastId: String(t("Please select valid image file")),
        });
        setTimeout(() => {
          setImage(null);
          setSelectedImageBase64("");
          setSelectedImage("");
          setImageParent("");
          setSelectedImageData(null);
        }, 1000);
      } else if (file.size > maxFileSizeinBytes) {
        toast.error(
          String(
            t("Please select an image with a size less than ") +
              formatBytes(maxFileSizeinBytes).toLocaleUpperCase()
          ),
          {
            toastId: String(
              t("Please select an image with a size less than ") +
                formatBytes(maxFileSizeinBytes).toLocaleUpperCase()
            ),
          }
        );
        setTimeout(() => {
          setImage(null);
          setSelectedImageBase64("");
          setSelectedImage("");
          setImageParent("");
          setSelectedImageData(null);
        }, 1000);
      } else {
        if (input.files && input.files[0]) {
          setFileName(file.name);
          setFileType(fileType.split("/").length >= 2 ? fileType.split("/")[1] : "");
          setFileSize(formatBytes(file.size));
          setFileSizeBytes(file.size);
          var reader = new FileReader();
          reader.onload = function (e) {
            if (e?.target?.result) {
              setSelectedImageData(e.target.result as string | ArrayBuffer | null);
            }
            setSelectedImageBase64({
              ...selectedImageBase64,
              image: input.files[0],
              imagename: input.files[0].name,
            });
            setImageParent({
              ...selectedImageBase64,
              image: input.files[0],
              imagename: input.files[0].name,
            });
            $("#blah").attr("src", e?.target?.result).width(142).height(142);
          };
          reader.readAsDataURL(input.files[0]);
        }
      }
    }
  };

  useEffect(() => {
    setSelectedImageData(watch(imageName));
    setImage(watch(imageName));
  }, [watch(imageName)]);

  const progressInfo = useSelector((state: RootState) => state.progressReducer.progress);

  React.useEffect(() => {
    if (loading) {
      setProgress(progressInfo);
    } else {
      setProgress({ loaded: 0, total: 0 });
      setSizeProgress(0);
    }
  }, [loading, progressInfo]);

  const handleSetParentImage = (props: any) => {
    setImage(props.file);
    setImageParent(props);
    setSelectedImage(props.file);
    setValue(imageName, props.file);
    setParentImage(props);
    setOpen(false);
  };

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  const interfaceBgColor =
    useColor("interface_background_color") || defaultThemeColor.interface_background_color;

  return (
    <div
      className={`${Style.uploadOuterWrapper} col-lg-12 mx-0 px-0`}
      style={{
        border: "1px solid rgba(226, 232, 240, 1)",
        borderRadius: "4px",
        marginTop: "1rem",
      }}
    >
      <div
        className={`${Style.minWidth} ${Style.choosefileCover}`}
        style={{
          border: errors[imageName] ? "1px solid red" : "1px solid transparent",
        }}
      >
        <span className="d-flex flex-wrap">
          <div className={`${Style.chooseFile}`}>
            <span
              id="OpenImgUpload"
              style={{
                padding: "0px 5px",
                borderRight: "1px solid #E2E8F0",
                cursor: "pointer",
              }}
            >
              <PinSVG color="#2B3674" />
            </span>
            <span id="addImageFromLibrary" style={{ padding: "0px 5px", cursor: "pointer" }}>
              <UploadSVG color="#2B3674" />
              <span
                style={{
                  marginLeft: "1em",
                  fontSize: "12px",
                  color: interfaceTextColor,
                  cursor: "pointer",
                }}
              >
                {t("Import from Image Library")}
              </span>
            </span>
          </div>
        </span>
      </div>
      <span style={{ width: "100%", position: "relative" }}>
        <input
          style={{
            width: "100%",
            padding: "1rem",
            border: "none",
            outline: "none",
            background: interfaceBgColor,
            color: interfaceTextColor,
          }}
          type="text"
          placeholder={t("Question here")}
          onChange={(e) => {
            setValueText(questionName, e.target.value);
            clearErrors(questionName);
          }}
          {...register(questionName)}
        />
        <p
          className="color-red mx-3"
          style={{
            position: "absolute",
            left: lang === "ar" ? "unset" : "0px",
            bottom: "-37px",
            right: lang === "ar" ? "-12px" : "0px",
          }}
        >
          {questionTextError || ""}
        </p>
      </span>
      {selectedImageData && (
        <div
          className={`${Style.choosenFileText}`}
          style={{
            alignItems: image ? "" : "center",
            marginTop: loading ? "0.5em" : image ? "0.1em" : "0",
            marginBottom: "1rem",
          }}
        >
          {image ? (
            <div className={`${loading ? "" : "d-flex align-items-center"}`}>
              <div
                className="row col-lg-11 d-flex justify-content-center align-items-center px-0 mx-0"
                style={{
                  height: "200px",
                }}
              >
                <Box sx={{ width: "90%", pl: 0, ml: 0 }}>
                  <div
                    className={`${Style.logohead}  d-flex justify-content-start ${Style.fileNameCover}`}
                    style={{
                      minWidth: size === "large" ? "35em" : "23em",
                      height: "200px",
                    }}
                  >
                    {selectedImageData !== null && (
                      <img
                        src={(selectedImageData as string) || watch(imageName)}
                        alt="Selected"
                        style={{ maxWidth: "260px", borderRadius: "6px" }}
                      />
                    )}
                  </div>

                  {loading ? (
                    <span>
                      <BorderLinearProgress
                        variant="determinate"
                        value={Math.round((progress?.loaded * 100) / progress?.total) || 0}
                      />
                      <span className="row col-lg-12 row-lg-4 mx-0 px-0">
                        <span
                          className={`${Style.bottomProgressMB} col-lg-6 justify-content-start ${Style.textLeft}`}
                        >
                          {formatBytesWithoutDM(progress?.loaded)}
                        </span>
                        <span className={`${Style.bottomProgressMB} col-lg-6 ${Style.textRight}`}>
                          {(Math.round((progress?.loaded * 100) / progress?.total) || 0).toFixed(0)}
                          %
                        </span>
                      </span>
                    </span>
                  ) : null}
                </Box>
              </div>
            </div>
          ) : (
            <span></span>
          )}
          <div style={{ textAlign: "initial", padding: "1rem 0rem 0rem 1rem" }}>
            <button
              id="OpenImgUpload2"
              className="btn-link"
              type="button"
              style={{
                cursor: "pointer",
                background: "#DEE9EF",
                padding: "8px",
                borderRadius: "50%",
                marginRight: "7px",
              }}
            >
              <EditSVG color={"#2B3674"} />
            </button>
            <button
              className="btn-link"
              type="button"
              style={{
                cursor: "pointer",
                background: "#FF00001A",
                padding: "9px",
                borderRadius: "50%",
              }}
              onClick={() => {
                setImage("");
                setSelectedImageData(null as string | ArrayBuffer | null);
                setImageParent("");
                setParentImage("");
                setSelectedImage("");
                setValue(imageName, "");
                clearErrors(imageName);
              }}
            >
              <DeleteSVG color="#FF0000" />
            </button>
          </div>
        </div>
      )}
      <input
        id="file"
        type="file"
        className={`${Style.inputfields} ${Style.fieldFont} ${
          errors.file_name ? "is-invalid" : ""
        }`}
        accept={fileTypes}
        onChange={(e) => {
          readURL(e);
          setImage(e.target.value);
          setImageParent(e.target.value);
          setSelectedImage(e.target.value);
          setValue(imageName, e.target.value);
          clearErrors(imageName);
        }}
        style={{
          position: "absolute",
          top: 0,
          width: 20,
          opacity: 0,
        }}
      ></input>

      <AddImagesFromLibrary
        setParentOpen={setOpen}
        open={open}
        onClose={handleClose}
        isLoading={loading}
        setParentImage={handleSetParentImage}
      />
    </div>
  );
}
export default UploadFile;
