// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PolicyAssessmentProgressReport_background__5Dd2k .PolicyAssessmentProgressReport_bottomSpace__lYSvg .PolicyAssessmentProgressReport_rightBorderProgress__UZIZL {
  border-right: 1.5px solid #e6e6e6;
  height: 100px;
}
.PolicyAssessmentProgressReport_background__5Dd2k .PolicyAssessmentProgressReport_bottomSpace__lYSvg .PolicyAssessmentProgressReport_rightBorderProgress__UZIZL .PolicyAssessmentProgressReport_perText__TW3hv {
  color: #a3aed0;
  font-weight: 500;
}
.PolicyAssessmentProgressReport_background__5Dd2k .PolicyAssessmentProgressReport_bottomSpace__lYSvg .PolicyAssessmentProgressReport_rightBorderProgress__UZIZL .PolicyAssessmentProgressReport_perText__TW3hv .PolicyAssessmentProgressReport_num__6bw6W {
  font-size: 2rem;
  color: #2b3674;
  font-weight: 700;
}
.PolicyAssessmentProgressReport_background__5Dd2k .PolicyAssessmentProgressReport_bottomSpace__lYSvg .PolicyAssessmentProgressReport_rightBorder__ebIVX {
  border-right: 1.5px solid #e6e6e6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
}
.PolicyAssessmentProgressReport_background__5Dd2k .PolicyAssessmentProgressReport_bottomSpace__lYSvg .PolicyAssessmentProgressReport_rightBorder__ebIVX .PolicyAssessmentProgressReport_perText__TW3hv {
  color: #a3aed0;
  font-weight: 500;
}
.PolicyAssessmentProgressReport_background__5Dd2k .PolicyAssessmentProgressReport_bottomSpace__lYSvg .PolicyAssessmentProgressReport_rightBorder__ebIVX .PolicyAssessmentProgressReport_num__6bw6W {
  font-size: 2rem;
  color: #2b3674;
  font-weight: 700;
}
.PolicyAssessmentProgressReport_background__5Dd2k .PolicyAssessmentProgressReport_bottomSpace__lYSvg .PolicyAssessmentProgressReport_head__yvnWj {
  font-size: 24px;
  font-weight: 700;
  color: #2b3674;
  line-height: 22px;
}
.PolicyAssessmentProgressReport_background__5Dd2k .PolicyAssessmentProgressReport_bottomSpace__lYSvg .PolicyAssessmentProgressReport_timer__XG8Fv {
  font-size: 20px;
  font-weight: 700;
  color: #2b3674;
  line-height: 22px;
}
.PolicyAssessmentProgressReport_background__5Dd2k .PolicyAssessmentProgressReport_bottomSpace__lYSvg .PolicyAssessmentProgressReport_question__UXqlK {
  color: #2f2f2f;
  font-weight: 700;
  line-height: 22px;
}
.PolicyAssessmentProgressReport_background__5Dd2k .PolicyAssessmentProgressReport_bottomSpace__lYSvg .PolicyAssessmentProgressReport_mcqOptions__NHb\\+Z .PolicyAssessmentProgressReport_optionLabel__FwgQ2 {
  color: #2f2f2f;
  padding-left: 1rem;
  font-weight: 400;
}
.PolicyAssessmentProgressReport_background__5Dd2k .PolicyAssessmentProgressReport_bottomSpace__lYSvg .PolicyAssessmentProgressReport_mcqOptions__NHb\\+Z .PolicyAssessmentProgressReport_optionLabelRed__n\\+RKC {
  color: red;
  padding-left: 1rem;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/pages/PolicyManagement/StartPolicyAssessment/PolicyAssessmentProgressReport.module.scss"],"names":[],"mappings":"AAEI;EACE,iCAAA;EACA,aAAA;AADN;AAEM;EACE,cAAA;EACA,gBAAA;AAAR;AACQ;EACE,eAAA;EACA,cAAA;EACA,gBAAA;AACV;AAGI;EACE,iCAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AADN;AAEM;EACE,cAAA;EACA,gBAAA;AAAR;AAEM;EACE,eAAA;EACA,cAAA;EACA,gBAAA;AAAR;AAGI;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,iBAAA;AADN;AAGI;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,iBAAA;AADN;AAGI;EACE,cAAA;EACA,gBAAA;EACA,iBAAA;AADN;AAIM;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;AAFR;AAIM;EACE,UAAA;EACA,kBAAA;EACA,gBAAA;AAFR","sourcesContent":[".background {\n  .bottomSpace {\n    .rightBorderProgress {\n      border-right: 1.5px solid #e6e6e6;\n      height: 100px;\n      .perText {\n        color: #a3aed0;\n        font-weight: 500;\n        .num {\n          font-size: 2rem;\n          color: #2b3674;\n          font-weight: 700;\n        }\n      }\n    }\n    .rightBorder {\n      border-right: 1.5px solid #e6e6e6;\n      display: flex;\n      flex-direction: column;\n      justify-content: center;\n      align-items: center;\n      height: 100px;\n      .perText {\n        color: #a3aed0;\n        font-weight: 500;\n      }\n      .num {\n        font-size: 2rem;\n        color: #2b3674;\n        font-weight: 700;\n      }\n    }\n    .head {\n      font-size: 24px;\n      font-weight: 700;\n      color: #2b3674;\n      line-height: 22px;\n    }\n    .timer {\n      font-size: 20px;\n      font-weight: 700;\n      color: #2b3674;\n      line-height: 22px;\n    }\n    .question {\n      color: #2f2f2f;\n      font-weight: 700;\n      line-height: 22px;\n    }\n    .mcqOptions {\n      .optionLabel {\n        color: #2f2f2f;\n        padding-left: 1rem;\n        font-weight: 400;\n      }\n      .optionLabelRed {\n        color: red;\n        padding-left: 1rem;\n        font-weight: 400;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": `PolicyAssessmentProgressReport_background__5Dd2k`,
	"bottomSpace": `PolicyAssessmentProgressReport_bottomSpace__lYSvg`,
	"rightBorderProgress": `PolicyAssessmentProgressReport_rightBorderProgress__UZIZL`,
	"perText": `PolicyAssessmentProgressReport_perText__TW3hv`,
	"num": `PolicyAssessmentProgressReport_num__6bw6W`,
	"rightBorder": `PolicyAssessmentProgressReport_rightBorder__ebIVX`,
	"head": `PolicyAssessmentProgressReport_head__yvnWj`,
	"timer": `PolicyAssessmentProgressReport_timer__XG8Fv`,
	"question": `PolicyAssessmentProgressReport_question__UXqlK`,
	"mcqOptions": `PolicyAssessmentProgressReport_mcqOptions__NHb+Z`,
	"optionLabel": `PolicyAssessmentProgressReport_optionLabel__FwgQ2`,
	"optionLabelRed": `PolicyAssessmentProgressReport_optionLabelRed__n+RKC`
};
export default ___CSS_LOADER_EXPORT___;
