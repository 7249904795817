import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import useColor from "../../hooks/useColor";
import { getPersonalForm } from "../../store/reducers/personalFormReducer";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import UserDetailsEdit from "./UserDetailsEdit";
import Style from "./UserProfileForm.module.scss";

const $ = require("jquery");

function UserProfileForm(props: {
  edit: boolean;
  setEdit: any;
  formSubmit: any;
}) {
  const dispatch = useAppDispatch();

  const userData = useSelector(
    (state: RootState) => state.userReducer.user_data
  );
  const t = useTranslation();

  const personalFormData: any = useSelector(
    (state: RootState) => state.personalFormReducer.personal_form_data
  );

  useEffect(() => {
    dispatch(getPersonalForm({ is_personal: 1 }));
  }, []);

  const FieldCover = (props: {
    header: string;
    asterisk: boolean;
    value: string;
  }) => {
    return (
      <div
        className={`${Style.fieldBackgroundCover} mb-4 mx-4 d-flex flex-wrap`}
      >
        <div
          className={`${Style.label} ${Style.wfifty}`}
          style={{
            color:
              useColor("interface_foreground_text_color") ||
              defaultThemeColor.interface_foreground_text_color,
          }}
        >
          {props.header}{" "}
          {props.asterisk ? <span className="color-red">*</span> : null}
        </div>
        <div
          className={`${Style.fieldValue} ${Style.wfifty}`}
          style={{
            color:
              useColor("interface_foreground_text_color") ||
              defaultThemeColor.interface_foreground_text_color,
            wordBreak: "break-word",
          }}
        >
          {props.value}
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        {!props.edit ? (
          <div className={"pb-3"} style={{ minHeight: "20em" }}>
            {userData?.primary_role == "Trainee" && (
              <FieldCover
                header={t("Company/ Business Name")}
                asterisk={false}
                value={userData?.sign_up_data?.company_business_name || "N/A"}
              ></FieldCover>
            )}

            {personalFormData &&
            Array.isArray(personalFormData) &&
            personalFormData.length > 0 &&
            personalFormData.find(
              (i: any) => i?.field_label == "gender" && !i?.is_disable
            ) ? (
              <FieldCover
                header={t("Gender")}
                asterisk={false}
                value={
                  userData?.personal_detail_data?.gender == "male"
                    ? t("Male")
                    : userData?.personal_detail_data?.gender == "female"
                    ? t("Female")
                    : "N/A"
                }
              ></FieldCover>
            ) : null}
            {personalFormData &&
            Array.isArray(personalFormData) &&
            personalFormData.length > 0 &&
            personalFormData.find(
              (i: any) => i?.field_label == "date_of_birth" && !i?.is_disable
            ) ? (
              <FieldCover
                header={t("Date of Birth")}
                asterisk={false}
                value={
                  userData?.personal_detail_data?.date_of_birth
                    ? moment(
                        new Date(userData?.personal_detail_data?.date_of_birth)
                      ).format("MMM Do, YYYY")
                    : "N/A"
                }
              ></FieldCover>
            ) : null}
            <FieldCover
              header={t("Phone Number")}
              asterisk={false}
              value={
                (userData?.sign_up_data &&
                  typeof userData?.sign_up_data == "string" &&
                  JSON.parse(userData?.sign_up_data)?.phone_no) ||
                userData?.sign_up_data?.phone_no ||
                "N/A"
              }
            ></FieldCover>
            {personalFormData &&
            Array.isArray(personalFormData) &&
            personalFormData.length > 0 &&
            personalFormData.find(
              (i: any) => i?.field_label == "address" && !i?.is_disable
            ) ? (
              <FieldCover
                header={t("Address")}
                asterisk={false}
                value={
                  (userData?.personal_detail_data?.address
                    ? "H-No. " + userData?.personal_detail_data?.address
                    : "") +
                    (userData?.personal_detail_data?.street_number
                      ? "/ St.No. " +
                        userData?.personal_detail_data?.street_number +
                        ", "
                      : "") +
                    (userData?.personal_detail_data?.city
                      ? userData?.personal_detail_data?.city + ", "
                      : "") +
                    (userData?.personal_detail_data?.region
                      ? userData?.personal_detail_data?.region + ", "
                      : "") +
                    (userData?.personal_detail_data?.country
                      ? userData?.personal_detail_data?.country + ", "
                      : "") || "N/A"
                }
              ></FieldCover>
            ) : null}
            {personalFormData &&
            Array.isArray(personalFormData) &&
            personalFormData.length > 0 &&
            personalFormData.find(
              (i: any) => i?.field_label == "country" && !i?.is_disable
            ) ? (
              <FieldCover
                header={t("Country")}
                asterisk={false}
                value={
                  (userData?.personal_detail_data?.country
                    ? userData?.personal_detail_data?.country
                    : "") || "N/A"
                }
              ></FieldCover>
            ) : null}
            {personalFormData &&
            Array.isArray(personalFormData) &&
            personalFormData.length > 0 &&
            personalFormData.find(
              (i: any) => i?.field_label == "department" && !i?.is_disable
            ) ? (
              <FieldCover
                header={t("Department")}
                asterisk={false}
                value={userData?.department?.name || "N/A"}
              ></FieldCover>
            ) : null}
            {personalFormData &&
            Array.isArray(personalFormData) &&
            personalFormData.length > 0 &&
            personalFormData.find(
              (i: any) => i?.field_label == "designation" && !i?.is_disable
            ) ? (
              <FieldCover
                header={t("Designation")}
                asterisk={false}
                value={userData?.personal_detail_data?.designation || "N/A"}
              ></FieldCover>
            ) : null}
            {personalFormData &&
            Array.isArray(personalFormData) &&
            personalFormData.length > 0 &&
            personalFormData.find(
              (i: any) => i?.field_label == "experience_level" && !i?.is_disable
            ) ? (
              <FieldCover
                header={t("Experience Level")}
                asterisk={false}
                value={
                  userData?.personal_detail_data?.experience_level || "N/A"
                }
              ></FieldCover>
            ) : null}
          </div>
        ) : (
          <UserDetailsEdit
            setEdit={props.setEdit}
            formSubmit={props.formSubmit}
          />
        )}
      </div>
    </>
  );
}
export default UserProfileForm;
