function ArrowDownSVG() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.03033 5.46967C4.73744 5.17678 4.26256 5.17678 3.96967 5.46967C3.67678 5.76256 3.67678 6.23744 3.96967 6.53033L6.46967 9.03033C6.76256 9.32322 7.23744 9.32322 7.53033 9.03033L10.0303 6.53033C10.3232 6.23744 10.3232 5.76256 10.0303 5.46967C9.73744 5.17678 9.26256 5.17678 8.96967 5.46967L7 7.43934L5.03033 5.46967Z"
        fill="#868FA0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 7C14 10.866 10.866 14 7 14C3.13401 14 -4.74968e-07 10.866 -3.0598e-07 7C-1.36992e-07 3.13401 3.13401 -3.0598e-07 7 -3.0598e-07C10.866 -3.0598e-07 14 3.13401 14 7ZM12.5 7C12.5 10.0376 10.0376 12.5 7 12.5C3.96243 12.5 1.5 10.0376 1.5 7C1.5 3.96243 3.96243 1.5 7 1.5C10.0376 1.5 12.5 3.96243 12.5 7Z"
        fill="#868FA0"
      />
    </svg>
  );
}

export default ArrowDownSVG;
