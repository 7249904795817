import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
export interface DataList {
  count: number;
  next: string | null;
  previous: String | null;
  results: any[];
}
export interface InitState {
  data?: DataList;
  wholeData?: DataList | any;
  assessmentData?: any;
  page?: number;
  perPage?: number;
  search?: string;
  detail?: any;
  wholedetail?: any;
}

export const initialState: InitState = {
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  wholeData: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  assessmentData: {},
  wholedetail: {},
  page: 1,
  perPage: 10,
  search: "",
};

export const assessmentSlice = createSlice({
  name: "assessment",
  initialState,
  reducers: {
    setdata: (state, { payload }) => {
      state.data = payload;
    },
    setWholeData: (state, { payload }) => {
      state.wholeData = payload;
    },
    setAssessmentData: (state, { payload }) => {
      state.assessmentData = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setPerPage: (state, { payload }) => {
      state.perPage = payload;
    },
    setSearch: (state, { payload }) => {
      state.search = payload;
    },
    setDetail: (state, { payload }) => {
      state.detail = payload;
    },
    setWholeDetail: (state, { payload }) => {
      state.wholedetail = payload;
    },
  },
});

export const {
  setdata,
  setWholeData,
  setPage,
  setPerPage,
  setSearch,
  setAssessmentData,
  setWholeDetail,
} = assessmentSlice.actions;

export const userSelector = (state: RootState) => state;

export default assessmentSlice.reducer;
