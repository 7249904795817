import { yupResolver } from "@hookform/resolvers/yup";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import { forgotPassowrd, reSendOtp } from "../../services/authService";
import {
  isForgotVerified,
  isVerified,
  setAuthFailed,
  setAuthSignUpData,
  updatedPassword,
} from "../../store/reducers/authReducer";
import { useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import Footer from "./Footer";
import Header from "./Header";
import Style from "./ResetPassword.module.scss";
const schema = yup
  .object({
    email: yup.string().required("Email is required").email("Email is invalid"),
  })
  .required();
const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const error = useSelector((state: any) => state.authReducer.error);
  const isLoading = useSelector((state: any) => state.loaderReducer.isLoading);
  const isForgotVerify = useSelector(
    (state: any) => state.authReducer.isForgotVerified
  );

  const singUpData = useSelector((state: any) => state.authReducer.singup_data);
  const [emailVerify, setEmailVerify] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  useEffect(() => {
    localStorage.removeItem("access_token");
    Cookies.remove("refresh_token");
    localStorage.removeItem("user_info");
    localStorage.removeItem("video_info");
    localStorage.removeItem("signup_data");
    localStorage.removeItem("reset_password_token");
    dispatch(updatedPassword(false));
    dispatch(isVerified(false));
    dispatch(setAuthSignUpData(null));
  }, [dispatch]);
  useEffect(() => {
    if (singUpData?.is_email_verified === false) {
      setEmailVerify(true);
    } else {
      setEmailVerify(false);
    }
  }, [singUpData]);
  const onSubmit = (payload: any) => {
    dispatch(forgotPassowrd(payload));
  };
  useEffect(() => {
    if (error) {
      toast.error(String(error), {
        toastId: String(error),
      });
      dispatch(setAuthFailed(null));
    }
  }, [error, dispatch]);
  useEffect(() => {
    localStorage.removeItem("signup_data");
    localStorage.removeItem("reset_password_token");
    dispatch(updatedPassword(false));
    dispatch(isVerified(false));
    dispatch(isForgotVerified(false));
    dispatch(setAuthSignUpData(null));
  }, []);
  useEffect(() => {
    if (isForgotVerify) {
      navigate("/verify");
    }
  }, [isForgotVerify]);
  const reSendHandler = () => {
    dispatch(reSendOtp());
    setTimeout((): any => {
      navigate("/verify");
    }, 2000);
  };
  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;
  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;
  const primaryButton =
    useColor("button_primary") || defaultThemeColor.button_primary;
  const primaryButtonText =
    useColor("button_primary_text") ||
    defaultThemeColor.button_button_primary_text;
  return (
    <div
      style={{
        direction: lang === "ar" ? "rtl" : "ltr",
        background: interfaceBgColor,
        color: interfaceTextColor,
        overflowY: "auto",
        height: "100vh",
      }}
    >
      <Header />
      <div
        className={`${Style.resetPasswordStart}`}
        style={{
          color: interfaceTextColor,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "3em",
        }}
      >
        <h2
          className={Style.maxWidd}
          style={{ color: interfaceTextColor, textAlign: "center" }}
        >
          {t("Reset your password")}
        </h2>
        <p
          className={Style.maxWidd}
          style={{ color: interfaceTextColor, textAlign: "center" }}
        >
          {t("Type in your registered email address to reset password")}
        </p>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="">
          <input
            className={`${Style.inputfields} ${Style.fieldFont} ${
              errors.email ? "is-invalid" : ""
            }`}
            placeholder={t("Email Address *")}
            type="text"
            {...register("email")}
            autoComplete="off"
          />
          <p className="color-red">{errors.email?.message}</p>
          {emailVerify && (
            <div
              className={`${Style.verifyEmailMain}`}
              style={{ color: interfaceTextColor }}
            >
              <span className="color-red" style={{ color: interfaceTextColor }}>
                {t("Your email is not verified.")}
              </span>
              <br></br>
              <span
                className={`${Style.verifyEmailText}`}
                onClick={reSendHandler}
              >
                {t("Click here to verify your email")}
              </span>
            </div>
          )}
          <button
            type="submit"
            className={`btn ${Style.mt24} ${Style.loginBtn}`}
            style={{ color: primaryButtonText, background: primaryButton }}
            disabled={isLoading}
          >
            <span className={Style.alignItemsInside}>
              <span
                className={`p-1 ${Style.hThree} ${Style.buttonFont} ${Style.white}`}
              >
                {t("NEXT")}
              </span>
              <span className={Style.iconButton}>
                <ArrowForwardOutlinedIcon fontSize="small" />
              </span>
            </span>
          </button>
        </form>
        <Link to="/login" className="d-flex justify-content-center w-100">
          <button
            className={`btn btn-dark w-100 ${Style.CreateAccountBtn} ${Style.mt80}`}
          >
            <span className={Style.alignItemsInside}>
              <span
                className={`p-1 ${Style.hThree} ${Style.buttonFont} ${Style.white}`}
              >
                {t("BACK TO LOGIN")}
              </span>
            </span>
          </button>
        </Link>
        <Footer />
      </div>
    </div>
  );
};

export default ResetPassword;
