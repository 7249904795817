import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface DataList {
  count: number;
  next: string | null;
  previous: String | null;
  results: any[];
}
export interface InitState {
  data: DataList;
  wholeData: DataList;
  assessmentData?: any;
  page?: number;
  perPage?: number;
  search?: string;
  detail?: any;
  assessmentResultData?: any;
}

export const initialState: InitState = {
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  wholeData: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  assessmentData: {},
  assessmentResultData: {},
  page: 1,
  perPage: 10,
  search: "",
};

export const policyAssessmentSlice = createSlice({
  name: "policyAssessment",
  initialState,
  reducers: {
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setWholeData: (state, { payload }) => {
      state.wholeData = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setPerPage: (state, { payload }) => {
      state.perPage = payload;
    },
    setSearch: (state, { payload }) => {
      state.search = payload;
    },
    setDetail: (state, { payload }) => {
      state.detail = payload;
    },
    setAssessmentData: (state, { payload }) => {
      state.assessmentData = payload;
    },
    setPolicyResultData: (state, { payload }) => {
      state.assessmentResultData = payload;
    },
  },
});

export const {
  setData,
  setWholeData,
  setDetail,
  setSearch,
  setPerPage,
  setPage,
  setAssessmentData,
  setPolicyResultData,
} = policyAssessmentSlice.actions;

export const userSelector = (state: RootState) => state;

export default policyAssessmentSlice.reducer;
