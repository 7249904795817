/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import AddIcon from "@mui/icons-material/Add";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import UnarchiveOutlinedIcon from "@mui/icons-material/UnarchiveOutlined";
import {
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  MenuItem,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Menu from "@mui/material/Menu";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { useConfirm } from "material-ui-confirm";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import ExportCSV from "../../components/ExportCSV/ExportCSV";
import Header from "../../components/Header";
import Paginations from "../../components/table/Paginations";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import usePermission from "../../hooks/usePermission";
import DeleteSVG from "../../icons/DeleteSVG";
import EditSVG from "../../icons/EditSVG";
import SearchSVG from "../../icons/SearchSVG";
import ViewSVGAction from "../../icons/ViewSVGAction";
import HeadIcon from "../../images/head.png";
import {
  deleteUser,
  getUsers,
  getWholeUsers,
  updateUser,
  updateUserBulkStatus,
} from "../../services/adminUserService";
import { bulkDeleteUsers } from "../../services/userService";
import { setWholeData } from "../../store/reducers/adminUserReducer";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import {
  setGetUserDataFailed,
  userSelector,
} from "../../store/reducers/userReducers";
import { RootState, useAppDispatch, useAppSelector } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import { confirmProps } from "../../utils/confirmProps";
import Style from "./AdminList.module.scss";
const $ = require("jquery");

const SearchTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#A0AAB4",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& #custom-css-outlined-input": {
    zIndex: 1,
    padding: 7.5,
    paddingLeft: 2,
    fontSize: "12px",
    width: "14rem",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      backgroundColor: "white",
      borderColor: "white",
      borderRadius: 50,
      fontFamily: "dmSans",
      fontSize: "14px",
      fontWeight: 400,
      letterSpacing: "-0.0175rem",
      width: "14rem",
      zIndex: 0,
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6F7E8C",
    },
  },
});

function AdminList() {
  const searchParams = new URLSearchParams(document.location.search);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const states = useSelector(userSelector).userReducer;
  const confirm = useConfirm();
  const defaultDate = { startDate: undefined, endDate: undefined };

  const [openCreatedDate, setOpenCreatedDate] = React.useState(false);
  const [dateRangeCreated, setDateRangeCreated] = React.useState<any>();
  const [openUpdatedDate, setOpenUpdatedDate] = React.useState(false);
  const [dateRangeUpdated, setDateRangeUpdated] = React.useState<any>();
  const [status, setStatus] = React.useState<any>();
  const deletePermission = usePermission("delete_user");
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [singleRowData, setSingleRowData] = useState<any>();
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<number[]>([]);
  const isLoading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  const userListData = useSelector(
    (state: RootState) => state.adminUserReducer.data
  );
  const userListWholeData = useSelector(
    (state: RootState) => state.adminUserReducer.wholeData
  );
  const [rows, setRows] = useState<any[]>([]);
  const alertMessage = useAppSelector(alertMessageSelector).alertMessageReducer;
  const [pageNum, setPageNum] = useState(Number(searchParams.get("page") || 1));
  const [perPageNum, setPerPageNum] = useState(
    Number(searchParams.get("perpage")) || 10
  );
  const [pageSearch, setPageSearch] = useState(
    searchParams.get("search") || ""
  );
  const toggleCreated = () => {
    setDateRangeCreated(defaultDate);
    setOpenCreatedDate(true);
  };
  const toggleUpdated = () => {
    setDateRangeUpdated(defaultDate);
    setOpenUpdatedDate(true);
  };
  const [statusState, setStatusState] = useState("");
  const [showRecordNotFound, setShowRecordNotFound] = React.useState(false);

  useEffect(() => {
    if (openCreatedDate && dateRangeCreated)
      $("#layout").css("overflow", "hidden");
    else $("#layout").css("overflow", "scroll");
  }, [dateRangeCreated, openCreatedDate]);

  useEffect(() => {
    if (openUpdatedDate && dateRangeUpdated)
      $("#layout").css("overflow", "hidden");
    else $("#layout").css("overflow", "scroll");
  }, [dateRangeUpdated, openUpdatedDate]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      setSelectedCheckBox([]);
      getUser();
    }
  }, [alertMessage]);

  useEffect(() => {
    if (isLoading == false) {
      setTimeout(() => {
        setShowRecordNotFound(true);
      }, 1000);
    } else {
      setShowRecordNotFound(false);
    }
  }, [isLoading]);

  const handleViewClick = (id: number) => {
    // navigate(`/user-profile-detail/outlook/${id}/admin`);
    navigate(`/admin-detail/outlook/${id}/admin`);
  };
  const handleEditClick = (id: number) => {
    // navigate(`/user-profile-edit/emend/${id}/admin`);
    navigate(`/admin-detail/emend/${id}/admin`);
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setSingleRowData(row);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckboxClick = (event: any, id: number) => {
    const checked = event.target.checked;
    if (checked) {
      setSelectedCheckBox([
        ...(selectedCheckBox?.map((r: any) => r) ?? []),
        id,
      ]);
    } else {
      setSelectedCheckBox(
        selectedCheckBox?.filter(function (val) {
          return val !== id;
        })
      );
    }
  };

  useEffect(() => {
    let statuses: any = [];
    selectedCheckBox &&
      selectedCheckBox.length > 0 &&
      userListData?.results?.forEach((element: any) => {
        if (selectedCheckBox.includes(element?.id))
          statuses = [...statuses, element?.status];
      });

    if (statuses?.filter((i: any) => i == "Active").length == statuses.length) {
      setStatus("Active");
    } else if (
      statuses?.filter((i: any) => i == "Locked").length == statuses.length
    ) {
      setStatus("Locked");
    } else if (
      statuses?.filter((i: any) => i == "Archived").length == statuses.length
    ) {
      setStatus("Archived");
    } else if (
      statuses?.filter((i: any) => i == "Active").length > 0 &&
      statuses?.filter((i: any) => i == "Archived").length > 0 &&
      statuses?.filter((i: any) => i == "Locked").length > 0
    ) {
      setStatus("ShowAll");
    } else if (
      statuses?.filter((i: any) => i == "Active").length > 0 &&
      statuses?.filter((i: any) => i == "Archived").length > 0 &&
      statuses?.filter((i: any) => i == "Locked").length == 0
    ) {
      setStatus("ShowUnArchived");
    } else if (
      statuses?.filter((i: any) => i == "Active").length > 0 &&
      statuses?.filter((i: any) => i == "Archived").length == 0 &&
      statuses?.filter((i: any) => i == "Locked").length > 0
    ) {
      setStatus("ShowUnlocked");
    } else if (
      statuses?.filter((i: any) => i == "Active").length == 0 &&
      statuses?.filter((i: any) => i == "Archived").length > 0 &&
      statuses?.filter((i: any) => i == "Locked").length > 0
    ) {
      setStatus("ShowAll");
    }
  }, [selectedCheckBox]);

  const handleClicked = () => {
    getWholeUser();
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n?.id);
      setSelectedCheckBox(newSelected);
      return;
    }
    setSelectedCheckBox([]);
  };

  const isSelected = (id: any) => selectedCheckBox?.indexOf(id) !== -1;

  const handleAddNewUser = () => {
    navigate("/add-admin");
  };

  const handleRowAction = (row: any, action: string) => {
    setAnchorEl(null);
    if (action === "delete") {
      dispatch(deleteUser({ id: row?.id }));
    }

    if (
      action === "archive" ||
      action === "lock" ||
      action === "unlock" ||
      action === "unarchive"
    )
      dispatch(
        updateUser({
          id: row?.id,
          status:
            action === "archive"
              ? "Archived"
              : action === "unarchive"
              ? "Active"
              : action === "unlock"
              ? "Active"
              : "Locked",
          email: "",
          password: "",
        })
      );
  };

  const handleBulkAction = (action: string) => {
    let act = "";
    if (action === "archive") {
      act = "Archived";
    } else if (action === "unarchive") {
      act = "Active";
    } else if (action === "unlock") {
      act = "Active";
    } else if (action === "lock") {
      act = "Locked";
    }
    dispatch(updateUserBulkStatus(selectedCheckBox, act));
  };

  const urlWithQueryString = (
    pageVal = Number(pageNum),
    perPageVal = Number(perPageNum),
    searchVal = String(pageSearch)
  ) => {
    let object: any = {};
    if (pageVal) {
      object.page = pageVal;
    }
    if (perPageVal) {
      object.perpage = perPageVal;
    }
    if (searchVal) {
      object.search = searchVal;
    }

    const objString = "?" + new URLSearchParams(object).toString();
    navigate(`/admin-list${objString}`);
  };
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setSelectedCheckBox([]);
    setPageNum(page);

    // urlWithQueryString(Number(page), Number(perPageNum), pageSearch);
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPageNum(1);
    setSelectedCheckBox([]);
    setPerPageNum(rowsPerPage);
    // urlWithQueryString(1, Number(rowsPerPage), pageSearch);
  };
  const handleChangeSearch = (val: string) => {
    setPageNum(1);
    setPageSearch(String(val));
    setPerPageNum(Number(10));
    // urlWithQueryString(1, perPageNum, String(val));
  };
  useEffect(() => {
    if (states?.errors) {
      if (states?.errors) {
        dispatch(setGetUserDataFailed(null));
      }
    }
    if (states?.errors) {
      toast.error(String(states?.errors), {
        toastId: String(states?.errors),
      });
    }
  }, [states]);

  useEffect(() => {
    let data = userListData.results;
    if (Object.keys(userListData).length === 0) {
      setRows([]);
    } else {
      setRows(data);
      if (!(perPageNum == 10 || perPageNum == 15 || perPageNum == 25)) {
        setPerPageNum(userListData?.count);
      }
    }
  }, [userListData]);

  useEffect(() => {
    handleChangeRowsPerPage(perPageNum);
  }, [perPageNum]);

  // useEffect(() => {
  //   if (dateRangeCreated?.startDate && dateRangeCreated?.endDate) {
  //     setOpenCreatedDate(false);
  //   }
  //   if (dateRangeUpdated?.startDate && dateRangeUpdated?.endDate) {
  //     setOpenUpdatedDate(false);
  //   }
  //   if (pageNum === 1) {
  //     getUser();
  //   } else {
  //     setPageNum(1);
  //     urlWithQueryString(1, Number(perPageNum), pageSearch);
  //   }
  // }, [
  //   dateRangeCreated?.startDate,
  //   dateRangeCreated?.endDate,
  //   dateRangeUpdated?.startDate,
  //   dateRangeUpdated?.endDate,
  //   statusState,
  // ]);
  useEffect(() => {
    if (dateRangeCreated?.startDate && dateRangeCreated?.endDate) {
      setOpenCreatedDate(false);
    }
    if (dateRangeUpdated?.startDate && dateRangeUpdated?.endDate) {
      setOpenUpdatedDate(false);
    }

    getUser();
  }, [
    pageNum,
    pageSearch,
    perPageNum,
    dateRangeCreated?.startDate,
    dateRangeCreated?.endDate,
    dateRangeUpdated?.startDate,
    dateRangeUpdated?.endDate,
    statusState,
  ]);

  const getUser = () => {
    // dispatch(setPage(Number(searchParams.get("page") || 1)));
    // dispatch(setPerPage(Number(searchParams.get("perpage") || 10)));
    // dispatch(setSearch(String(searchParams.get("search"))));
    let query: any = {};
    query.page = Number(pageNum);
    query.page_size = Number(perPageNum);
    query.search = String(pageSearch);
    query.primary_role__in = "Admin";
    query.is_superuser = "False";
    if (dateRangeCreated?.startDate) {
      query.created_at_from = moment(
        new Date(dateRangeCreated?.startDate)
      ).format("YYYY-MM-DD");
    }
    if (dateRangeCreated?.endDate) {
      query.created_at_to = moment(new Date(dateRangeCreated?.endDate)).format(
        "YYYY-MM-DD"
      );
    }
    if (dateRangeUpdated?.startDate) {
      query.updated_at_from = moment(
        new Date(dateRangeUpdated?.startDate)
      ).format("YYYY-MM-DD");
    }
    if (dateRangeUpdated?.endDate) {
      query.updated_at_to = moment(new Date(dateRangeUpdated?.endDate)).format(
        "YYYY-MM-DD"
      );
    }

    query.status = statusState;
    dispatch(getUsers(query));
  };

  const getWholeUser = () => {
    if (userListData)
      dispatch(
        getWholeUsers({
          search: "",
          status: "",
          primary_role__in: "Admin",
          is_superuser: "False",
          page: 1,
          page_size: userListData?.count,
        })
      );
  };
  const bulkDelete = (ids: any) => {
    confirm({
      title: t("Are you sure!"),
      description: t(`Do you want to delete selected user(s)?`),
      ...confirmProps,
    })
      .then(() => {
        dispatch(bulkDeleteUsers(ids));
        setSelectedCheckBox([]);
      })
      .catch(() => {});
  };
  return (
    <div className={Style.manageSpace}>
      <div
        className={`${Style.background}`}
        style={{
          background:
            useColor("interface_background_color") ||
            defaultThemeColor.interface_background_color,
        }}
      >
        <div style={{ paddingBottom: "0.6em" }}>
          <Header
            page={t("Admin List")}
            mainHead={t("Admin Management / Admin List")}
          />
          <div
            className={`d-flex justify-content-between align-items-center flex-wrap ${Style.userFilters}`}
            style={{ marginTop: 3 }}
          >
            <div className={``}>
              <FormControl
                variant="standard"
                sx={{ m: 1, mr: 3, minWidth: 67 }}
              >
                <InputLabel
                  id="demo-simple-select-autowidth-label"
                  style={{ fontSize: "12px" }}
                  disableAnimation={true}
                  shrink={false}
                >
                  {perPageNum ? "" : `All (${userListData.count || 0})`}
                </InputLabel>
                <Select
                  style={{
                    color:
                      useColor("interface_background_text_color") ||
                      defaultThemeColor.interface_background_text_color,
                  }}
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={perPageNum}
                  onChange={(e) => {
                    handleChangeRowsPerPage(Number(e?.target?.value) as any);
                  }}
                  autoWidth
                  disableUnderline
                >
                  <MenuItem value={userListData.count}>
                    {t("All")} ({userListData.count || 0})
                  </MenuItem>
                  <MenuItem value="10">10 {t("Per Page")}</MenuItem>
                  <MenuItem value="15">15 {t("Per Page")}</MenuItem>
                  <MenuItem value="25">25 {t("Per Page")}</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ mt: 2.4, mr: 3, minWidth: 80 }}>
                <Button
                  variant="text"
                  color="inherit"
                  endIcon={
                    <AddIcon
                      style={{
                        fontSize: 16,
                        color:
                          useColor("interface_background_text_color") ||
                          "rgb(87, 88, 91)",
                        marginRight: lang === "ar" ? "10px" : "0px",
                      }}
                    />
                  }
                  style={{
                    fontSize: 13,
                    color:
                      useColor("interface_background_text_color") ||
                      "rgb(87, 88, 91)",
                    textTransform: "capitalize",
                  }}
                  onClick={() => handleAddNewUser()}
                >
                  {t("Add New")}
                </Button>
              </FormControl>
            </div>

            <div
              className={`d-flex align-items-center gap-3 ${Style.fitersStart}`}
            >
              <>
                {/* {userListData?.count && ( */}
                <div className="px-3">
                  <ExportCSV
                    setParentData={() => dispatch(setWholeData({}))}
                    handleClicked={handleClicked}
                    rows={userListWholeData?.results?.map(
                      (rw: any, i: any) => ({
                        num: i + 1,
                        user_name: rw.first_name + " " + rw.last_name,
                        email: rw.email,
                        created_at:
                          moment(new Date(rw.created_at)).format(
                            "MMM Do, YYYY"
                          ) || "",
                        updated_at:
                          moment(new Date(rw.updated_at)).format(
                            "MMM Do, YYYY"
                          ) || "",
                        status: rw.status,
                      })
                    )}
                    headers={[
                      "No.",
                      "Username",
                      "Email",
                      "Created at",
                      "Updated at",
                      "Status",
                    ]}
                    filename={"admin_users_list.csv"}
                  />
                </div>
                {/* )} */}
                {status == "Active" ||
                status == "Locked" ||
                status == "ShowUnlocked" ? null : (
                  <IconButton
                    className={Style.userFilterButton}
                    sx={{ m: 0, p: 0, mr: 1 }}
                    disabled={selectedCheckBox.length > 0 ? false : true}
                    onClick={() => {
                      confirm({
                        title: t("Are you sure!"),
                        description: t(
                          `Do you want to unarchive selected account's?`
                        ),
                        ...confirmProps,
                      })
                        .then(() => {
                          handleBulkAction("unarchive");
                        })
                        .catch((e) => {
                          // console.log(e);
                        });
                    }}
                  >
                    <UnarchiveOutlinedIcon
                      className={`${
                        selectedCheckBox.length > 0 && `${Style.svgIcon}`
                      }`}
                    />
                    {t("Unarchive")}
                  </IconButton>
                )}{" "}
                {status == "Locked" ||
                status == "Active" ||
                status == "ShowAll" ||
                status == "ShowUnlocked" ||
                status == "ShowUnArchived" ? (
                  <IconButton
                    className={Style.userFilterButton}
                    disabled={selectedCheckBox.length > 0 ? false : true}
                    sx={{ m: 0, p: 0, mr: 1 }}
                    onClick={() => {
                      confirm({
                        title: t("Are you sure!"),
                        description: t(
                          t(`Do you want to archive selected account's?`)
                        ),
                        ...confirmProps,
                      })
                        .then(() => {
                          handleBulkAction("archive");
                        })
                        .catch((e) => {
                          // console.log(e);
                        });
                    }}
                  >
                    <ArchiveOutlinedIcon
                      className={`${
                        selectedCheckBox.length > 0 && `${Style.svgIcon}`
                      }`}
                    />
                    {t("Archive")}
                  </IconButton>
                ) : null}
                {status == "Active" ? null : status == "Locked" ||
                  status == "ShowAll" ||
                  status == "ShowUnlocked" ? (
                  <IconButton
                    className={Style.userFilterButton}
                    sx={{ m: 0, p: 0, mr: 1 }}
                    disabled={selectedCheckBox.length > 0 ? false : true}
                    onClick={() => {
                      confirm({
                        title: t("Are you sure!"),
                        description: t(
                          `Do you want to unlock selected account's?`
                        ),
                        ...confirmProps,
                      })
                        .then(() => {
                          handleBulkAction("unlock");
                        })
                        .catch((e) => {
                          // console.log(e);
                        });
                    }}
                  >
                    <LockOpenTwoToneIcon
                      className={`${
                        selectedCheckBox.length > 0 && `${Style.svgIcon}`
                      }`}
                    />
                    {t("Unlock")}
                  </IconButton>
                ) : null}
                {status == "Locked" ||
                status == "ShowUnlockedUnarchived" ? null : (
                  <IconButton
                    className={Style.userFilterButton}
                    disabled={selectedCheckBox.length > 0 ? false : true}
                    sx={{ m: 0, p: 0, mr: 1 }}
                    onClick={() => {
                      confirm({
                        title: t("Are you sure!"),
                        description: t(
                          `Do you want to lock selected account's?`
                        ),
                        ...confirmProps,
                      })
                        .then(() => {
                          handleBulkAction("lock");
                        })
                        .catch((e) => {
                          // console.log(e);
                        });
                    }}
                  >
                    <LockOutlinedIcon
                      className={`${
                        selectedCheckBox.length > 0 && `${Style.svgIcon}`
                      }`}
                    />
                    {t("Lock")}
                  </IconButton>
                )}
              </>
              {deletePermission && (
                <Button
                  variant="text"
                  color="inherit"
                  disabled={selectedCheckBox?.length > 0 ? false : true}
                  onClick={(e: any) => {
                    bulkDelete(selectedCheckBox);
                  }}
                  className={`d-flex gap-2 align-items-center justify-content-center ${
                    selectedCheckBox.length > 0
                      ? Style.removeBtn
                      : Style.disabledBorder
                  }`}
                >
                  <DeleteSVG
                    color={
                      selectedCheckBox.length > 0
                        ? "#FF0000"
                        : "rgba(0, 0, 0, 0.26)"
                    }
                  />
                  {t("Remove")}
                </Button>
              )}
              <SearchTextField
                autoComplete="off"
                sx={{ mt: 0.3, mr: 0 }}
                style={{ width: "14rem" }}
                placeholder={t("Search")}
                onChange={(e) => {
                  handleChangeSearch(e.target.value);
                }}
                id="custom-css-outlined-input"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ pl: 1 }}>
                      <SearchSVG />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: false,
                }}
              />
            </div>
          </div>
        </div>
        {/* <div className="overflow-x-auto"> */}
        <div>
          <table
            className={`table ${Style.customTable} table-hover`}
            style={{
              background: useColor("interface_foreground_color") || "#fff",
            }}
          >
            <thead>
              <tr>
                <th>
                  <Checkbox
                    className={Style.headerCheck}
                    color="primary"
                    indeterminate={
                      selectedCheckBox?.length > 0 &&
                      selectedCheckBox?.length < rows?.length
                    }
                    checked={
                      rows?.length > 0 &&
                      selectedCheckBox?.length === rows?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                  />
                </th>
                <th>{t("Username")}</th>
                <th>{t("Email")}</th>

                <th>
                  <div className="position-relative">
                    {dateRangeCreated ? (
                      <div className="color-picker-container">
                        <DateRangePicker
                          open={openCreatedDate}
                          value={[
                            dateRangeCreated.startDate,
                            dateRangeCreated.endDate,
                          ]}
                          onChange={(range: any) => {
                            setPageNum(1);
                            setPerPageNum(Number(10));
                            if (range) {
                              setDateRangeCreated({
                                startDate: range[0],
                                endDate: range[1],
                              });
                            } else {
                              setDateRangeCreated(null);
                            }
                          }}
                          className={Style.menu}
                          menuStyle={{
                            color: "black",
                          }}
                          format="yyyy-MM-dd"
                          defaultCalendarValue={[new Date(), new Date()]}
                          onOk={() => {
                            setOpenCreatedDate(false);
                            // getUsers();
                          }}
                          onClick={() => {
                            setOpenCreatedDate(true);
                          }}
                          onClean={() => {
                            setPageNum(1);
                            setPerPageNum(Number(10));
                            setDateRangeCreated(null);
                          }}
                          shouldDisableDate={(date) => date > new Date()}
                        />
                      </div>
                    ) : (
                      <Button
                        variant="text"
                        color="inherit"
                        endIcon={<ArrowDropDownSharpIcon />}
                        className={Style.HeaderBtn}
                        onClick={toggleCreated}
                        style={{ textTransform: "capitalize" }}
                      >
                        {t("Created")}
                      </Button>
                    )}
                  </div>
                </th>
                <th>
                  <div className="position-relative">
                    {dateRangeUpdated ? (
                      <div className="color-picker-container">
                        <DateRangePicker
                          open={openUpdatedDate}
                          value={[
                            dateRangeUpdated.startDate,
                            dateRangeUpdated.endDate,
                          ]}
                          onChange={(range: any) => {
                            setPageNum(1);
                            setPerPageNum(Number(10));
                            if (range) {
                              setDateRangeUpdated({
                                startDate: range[0],
                                endDate: range[1],
                              });
                            } else {
                              setDateRangeUpdated(null);
                            }
                          }}
                          placement="autoVertical"
                          className={Style.menu}
                          menuStyle={{
                            color: "black",
                          }}
                          format="yyyy-MM-dd"
                          defaultCalendarValue={[new Date(), new Date()]}
                          onOk={() => {
                            setOpenUpdatedDate(false);
                            // getUsers();
                          }}
                          onClick={() => {
                            setOpenUpdatedDate(true);
                          }}
                          onClean={() => {
                            setPageNum(1);
                            setPerPageNum(Number(10));
                            setDateRangeCreated(null);
                          }}
                          shouldDisableDate={(date) => date > new Date()}
                        />
                      </div>
                    ) : (
                      <Button
                        variant="text"
                        color="inherit"
                        endIcon={<ArrowDropDownSharpIcon />}
                        className={Style.HeaderBtn}
                        onClick={toggleUpdated}
                        style={{ textTransform: "capitalize" }}
                      >
                        {t("Updated")}
                      </Button>
                    )}
                  </div>
                </th>
                <th className="text-center">
                  <PopupState variant="popover" popupId="demo-popup-menu2">
                    {(popupState) => (
                      <React.Fragment>
                        <FormControl sx={{ minWidth: 80 }}>
                          <Button
                            variant="text"
                            color="inherit"
                            style={{ textTransform: "capitalize" }}
                            // startIcon={lang === "ar" ? "" : <StatusSVG />}
                            endIcon={
                              statusState === "asc" ? (
                                <ArrowDropUpIcon />
                              ) : (
                                <ArrowDropDownSharpIcon />
                              )
                            }
                            {...bindTrigger(popupState)}
                            className={Style.HeaderBtn}
                          >
                            {statusState ? statusState : t("Status")}
                          </Button>
                          <Menu
                            {...bindMenu(popupState)}
                            style={{
                              minWidth: 80,
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                setPageNum(1);
                                setPerPageNum(Number(10));
                                setStatusState("");
                                popupState.close();
                              }}
                            >
                              {t("All")}
                            </MenuItem>
                            <MenuItem
                              style={{
                                minWidth: 110,
                              }}
                              onClick={() => {
                                setPageNum(1);
                                setPerPageNum(Number(10));
                                setStatusState("Active");
                                popupState.close();
                              }}
                            >
                              {t("Active")}
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setPageNum(1);
                                setPerPageNum(Number(10));
                                setStatusState("Archived");
                                popupState.close();
                              }}
                            >
                              {t("Archived")}
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setPageNum(1);
                                setPerPageNum(Number(10));
                                setStatusState("Locked");
                                popupState.close();
                              }}
                            >
                              {t("Locked")}
                            </MenuItem>
                          </Menu>
                        </FormControl>
                      </React.Fragment>
                    )}
                  </PopupState>
                  {/* Status */}
                </th>
                <th>{t("Actions")}</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td className="text-center" colSpan={9}>
                    <CircularProgress size="2rem" color="primary" />
                  </td>
                </tr>
              ) : userListData?.results && userListData?.results.length > 0 ? (
                userListData?.results?.map((rw: any, index: number) => {
                  const isItemSelected = isSelected(rw.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <tr key={rw.id}>
                      <td
                        onClick={(event) => handleCheckboxClick(event, rw.id)}
                      >
                        <Checkbox
                          className={Style.headerCheck}
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                        {/* <Checkbox
                      color="primary"
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    /> */}
                      </td>
                      <td className="align-middle">
                        <div className="d-flex gap-2 align-items-center">
                          <Avatar
                            src={rw.profile_picture || HeadIcon}
                            sx={{ width: 35, height: 35 }}
                            alt={rw.username}
                          />
                          <div>{rw.first_name + " " + rw.last_name}</div>
                        </div>
                      </td>
                      <td className="align-middle">{rw.email}</td>

                      <td className="align-middle">
                        {moment(new Date(rw.created_at)).format(
                          "MMM Do, YYYY"
                        ) || ""}
                      </td>
                      <td className="align-middle">
                        {moment(new Date(rw.updated_at)).format(
                          "MMM Do, YYYY"
                        ) || ""}
                      </td>
                      <td className="text-center align-middle">
                        <span
                          className={
                            rw.status == "Active"
                              ? Style.statusSuccess
                              : rw.status == "Archived"
                              ? Style.statusArchived
                              : rw.status == "Locked"
                              ? Style.statusLocked
                              : ""
                          }
                        >
                          {rw.status}
                        </span>
                      </td>
                      <td className="align-middle">
                        <IconButton
                          aria-label="more"
                          id={`long-button-${rw?.id}`}
                          aria-controls={
                            open ? `long-menu-${rw?.id}` : undefined
                          }
                          style={{
                            marginLeft: "8px",
                          }}
                          aria-expanded={open ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={(event) => handleClick(event, rw)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          className={Style.menu}
                          id={`long-menu-${rw?.id}`}
                          MenuListProps={{
                            "aria-labelledby": `long-button-${rw?.id}`,
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          PaperProps={{
                            style: {
                              width: "20ch",
                              boxShadow:
                                "0px 5px 5px -3px rgba(0,0,0,0.01), 0px 3px 6px 1px rgba(0,0,0,0.01), 0px 3px 6px 2px rgba(0,0,0,0.01)",
                            },
                          }}
                        >
                          <MenuItem
                            onClick={() => handleViewClick(singleRowData?.id)}
                          >
                            <IconButton
                              className="d-flex gap-2"
                              sx={{ m: 0, p: 0 }}
                            >
                              <ViewSVGAction />
                              <div>{t("View")}</div>
                            </IconButton>
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleEditClick(singleRowData?.id)}
                          >
                            <IconButton
                              className="d-flex gap-2"
                              sx={{ m: 0, p: 0 }}
                            >
                              <EditSVG />
                              <div>{t("Edit")}</div>
                            </IconButton>
                          </MenuItem>

                          {singleRowData?.status === "Archived" ? (
                            <MenuItem
                              onClick={() => {
                                confirm({
                                  title: t("Are you sure!"),
                                  description: `Do you want to unarchive ${singleRowData.first_name} account?`,
                                  ...confirmProps,
                                })
                                  .then(() => {
                                    handleRowAction(singleRowData, "unarchive");
                                  })
                                  .catch((e) => {
                                    // console.log(e);
                                  });
                              }}
                            >
                              <IconButton
                                className="d-flex gap-2"
                                sx={{ m: 0, p: 0, mr: 1 }}
                              >
                                <UnarchiveOutlinedIcon
                                  style={{
                                    fontSize: 20,
                                    color: "rgb(87, 88, 91)",
                                  }}
                                />
                                <div>{t("Unarchive")}</div>
                              </IconButton>
                            </MenuItem>
                          ) : (
                            <MenuItem
                              onClick={() => {
                                confirm({
                                  title: t("Are you sure!"),
                                  description: `Do you want to archive ${singleRowData.first_name} account?`,
                                  ...confirmProps,
                                })
                                  .then(() => {
                                    handleRowAction(singleRowData, "archive");
                                  })
                                  .catch((e) => {
                                    // console.log(e);
                                  });
                              }}
                            >
                              <IconButton
                                className="d-flex gap-2"
                                sx={{ m: 0, p: 0, mr: 1 }}
                                // onClick={() => handleRowAction(rw, "archive")}
                              >
                                <ArchiveOutlinedIcon
                                  style={{
                                    fontSize: 20,
                                    color: "rgb(87, 88, 91)",
                                  }}
                                />
                                <div>{t("Archive")}</div>
                              </IconButton>
                            </MenuItem>
                          )}

                          {singleRowData?.status === "Locked" ? (
                            <MenuItem
                              onClick={() => {
                                // handleRowAction(rw, "unlock")
                                // handleNotificationClicked()
                                confirm({
                                  title: t("Are you sure!"),
                                  description: `Do you want to unlock ${singleRowData.first_name} account?`,
                                  ...confirmProps,
                                })
                                  .then(() => {
                                    handleRowAction(singleRowData, "unlock");
                                  })
                                  .catch((e) => {
                                    // console.log(e);
                                  });
                              }}
                            >
                              <IconButton
                                className="d-flex gap-2"
                                sx={{ m: 0, p: 0, mr: 1 }}
                              >
                                <LockOpenTwoToneIcon
                                  style={{
                                    fontSize: 20,
                                    color: "rgb(87, 88, 91)",
                                  }}
                                />
                                <div>{t("UnLock")}</div>
                              </IconButton>
                            </MenuItem>
                          ) : (
                            <MenuItem
                              onClick={() => {
                                //  handleRowAction(rw, "lock")
                                // handleNotificationClicked()
                                confirm({
                                  title: t("Are you sure!"),
                                  description: `Do you want to lock ${singleRowData.first_name} account?`,
                                  ...confirmProps,
                                })
                                  .then(() => {
                                    handleRowAction(singleRowData, "lock");
                                  })
                                  .catch((e) => {
                                    // console.log(e);
                                  });
                              }}
                            >
                              <IconButton
                                className="d-flex gap-2"
                                sx={{ m: 0, p: 0, mr: 1 }}
                              >
                                <LockOutlinedIcon
                                  style={{
                                    fontSize: 20,
                                    color: "rgb(87, 88, 91)",
                                  }}
                                />
                                <div>{t("Lock")}</div>
                              </IconButton>
                            </MenuItem>
                          )}
                          <MenuItem
                            onClick={() => {
                              confirm({
                                title: t("Are you sure!"),
                                description: t(
                                  `Do you want to delete ${singleRowData.first_name} account?`
                                ),
                                ...confirmProps,
                              })
                                .then(() => {
                                  handleRowAction(singleRowData, "delete");
                                })
                                .catch((e) => {
                                  // console.log(e);
                                });
                            }}
                          >
                            <IconButton
                              className="d-flex gap-2"
                              sx={{ m: 0, p: 0 }}
                            >
                              <DeleteSVG color="red" />
                              <div>{t("Remove")}</div>
                            </IconButton>
                          </MenuItem>
                        </Menu>
                      </td>
                    </tr>
                  );
                })
              ) : showRecordNotFound ? (
                <tr style={{ cursor: "pointer" }}>
                  <td colSpan={9} className="text-center align-middle p-3">
                    {t("No Data Found")}
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
        {userListData?.count > 0 && (
          <Paginations
            totalCount={Number(userListData?.count)}
            perpage={Number(perPageNum || 10)}
            handleChangePageNum={handleChangePage}
            pagenum={Number(pageNum || 1)}
            handleChangeRowsPerPageParent={handleChangeRowsPerPage}
          />
        )}
      </div>
    </div>
  );
}

export default AdminList;
