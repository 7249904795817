import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import HeadIcon from "../../images/head.png";
import Style from "../../pages/imageLibrary/ImageLibrary.module.scss";
import ViewImage from "../../pages/imageLibrary/components/ViewImage";
import { searchImages } from "../../services/imageLibraryService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import { formatBytes } from "../../utils/formatBytesToDelimeters";
import DataTable from "../table/DataTable";

export interface SimpleDialogProps {
  open: boolean;
  setParentOpen: any;
  selectedValue?: string;
  isLoading: boolean;
  setParentImage?: any;
  onClose: () => void;
}

function AddImagesFromLibrary(props: SimpleDialogProps) {
  const { onClose, open, isLoading, setParentImage } = props;

  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const [itemPerPage, setItemPerPage] = useState<number>(10);
  const [openImage, setOpenImage] = React.useState(false);
  const [image, setImage] = React.useState("");
  const [imageName, setImageName] = React.useState("");
  const [searchTable, setSearchTable] = useState("");
  const [list, setList] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<any[]>([]);
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState<number>(1);
  const dataList = useSelector(
    (state: RootState) => state.imageLibraryReducer.image_list_data
  );

  useEffect(() => {
    setPage(1);
  }, []);

  useEffect(() => {
    dispatch(
      searchImages({
        page_size: Number(itemPerPage),
        page: Number(page),
        search: searchTable,
      })
    );
  }, [dispatch, itemPerPage, page, searchTable]);

  useEffect(() => {
    let data: any = dataList.results;
    if (Object.keys(dataList).length === 0) {
      setRows([]);
    } else setRows(data);
  }, [dataList]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      setSelectedCheckBox([]);
    }
  }, [alertMessage]);

  useEffect(() => {
    if (!open) {
      setPage(1);
      setItemPerPage(10);
    }
  }, [open]);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setSelectedCheckBox([]);
    setPage(page);
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPage(1);
    setSelectedCheckBox([]);
    setItemPerPage(Number(rowsPerPage));
  };

  const handleCloseImage = () => {
    setOpenImage(false);
  };

  useEffect(() => {
    modifyRows();
  }, [rows]);

  const modifyRows = () => {
    let f_rows: any = [];
    rows.forEach(
      (
        r: {
          id: string;
          filename: string;
          created_at: string;
          file: string;
          filesize: string;
          uploaded_by: string;
          dimensions: string;
        },
        i: number
      ) => {
        f_rows = [
          ...f_rows,
          {
            id: r.id.toString(),
            imageSrcLibrary:
              (
                <>
                  <img
                    alt="Loading..."
                    onClick={() => {
                      setOpenImage(true);
                      setImage(r?.file);
                      setImageName(r?.filename);
                    }}
                    height={35}
                    width={54}
                    src={r?.file}
                    className={Style.imageCover}
                    style={{
                      objectFit: "cover",
                      height: 25,
                      width: 54,
                    }}
                  ></img>
                </>
              ) || HeadIcon,
            filename:
              (r.filename === "undefined" ? "file" : r.filename) || "N/A",
            filesize: formatBytes(Number(r.filesize)) || "N/A",
            uploaded_by: r.uploaded_by || "N/A",
            dimensions: r.dimensions || "",
            created_at: moment(new Date(r.created_at)).format("MMM Do, YYYY"),
            actions: (
              <span>
                <button
                  className="btn-link"
                  type="button"
                  onClick={(e) => {
                    setParentImage(r);
                  }}
                >
                  <AddIcon />
                </button>
              </span>
            ),
          },
        ];
      }
    );
    setList(f_rows);
  };

  const handleClose = () => {
    if (isLoading) {
    } else {
      onClose();
    }
  };

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;

  return (
    <Dialog
      onClose={handleClose}
      maxWidth={"lg"}
      open={open && isLoading ? true : open}
      style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
    >
      <div
        className="d-flex justify-content-between"
        style={{ color: interfaceTextColor, background: interfaceBgColor }}
      >
        <DialogTitle style={{ paddingTop: "1em", fontSize: 22 }}>
          {t("Add Image From Library")}
        </DialogTitle>
        <div className="p-3">
          <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
        </div>
      </div>
      <DialogContent>
        <DataTable
          columnData={[
            {
              id: "imageSrcLibrary",
              name: t("File"),
              enableSort: false,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "filename",
              name: t("File Name"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "checkbox",
            },
            {
              id: "filesize",
              name: t("File Size"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "checkbox",
            },
            {
              id: "uploaded_by",
              name: t("Uploaded by"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "dimensions",
              name: t("Dimension"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "checkbox",
            },
            {
              id: "created_at",
              name: t("Uploaded at"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "actions",
              name: t("Actions"),
              enableSort: false,
              align: "center",
              disablePadding: "none",
            },
          ]}
          rows={list}
          isCheckboxes={false}
          selectedRowsPerPage={itemPerPage.toString()}
          totalRecordCount={Number(dataList.count)}
          handleChangePageParent={handleChangePage}
          pagenum={Number(page)}
          handleChangeRowsPerPageParent={handleChangeRowsPerPage}
          bulkDelete={() => {}}
          selectedCheckBox={selectedCheckBox}
          setSelectedCheckBox={setSelectedCheckBox}
          bottomButtonsComponent={<div></div>}
        />
      </DialogContent>
      <ViewImage
        setParentOpen={setOpenImage}
        open={openImage}
        onClose={handleCloseImage}
        image={image}
        imageName={imageName}
      />
    </Dialog>
  );
}

export default AddImagesFromLibrary;
