var d1 = new Date(2011, 0, 1) as any; // jan,1 2011
var d2 = new Date() as any; // now

export const DateToMatchWith = "3/16/2013 00:00:00";

export const timeDiff = ({ d1, d2 }: { d1: any; d2: any }) => {
  // d1 = new Date("3/16/2013 01:00:00") as any; // jan,1 2011
  // d2 = new Date("3/16/2013 00:00:00") as any;
  if (d1 && d2) {
    var diff = d2 - d1,
      sign = diff < 0 ? -1 : 1,
      milliseconds,
      seconds,
      minutes,
      hours,
      days;

    diff /= sign; // or diff=Math.abs(diff);
    diff = (diff - (milliseconds = diff % 1000)) / 1000;
    diff = (diff - (seconds = diff % 60)) / 60;
    diff = (diff - (minutes = diff % 60)) / 60;
    days = (diff - (hours = diff % 24)) / 24;

    return (
      (days || hours || minutes || seconds || milliseconds ? "(" : "") +
      (days
        ? days +
          (days == 1
            ? hours || minutes || seconds || milliseconds
              ? " day, "
              : "day"
            : " days, ")
        : "") +
      (hours
        ? hours +
          (hours == 1
            ? minutes || seconds || milliseconds
              ? " hour, "
              : " hour"
            : minutes || seconds || milliseconds
            ? " hours, "
            : " hours")
        : "") +
      (minutes
        ? minutes +
          (minutes == 1
            ? seconds || milliseconds
              ? " minute, "
              : " minute"
            : seconds || milliseconds
            ? " minutes, "
            : " minutes")
        : "") +
      (seconds
        ? seconds +
          (seconds == 1
            ? milliseconds
              ? " second, "
              : " second"
            : milliseconds
            ? " seconds, "
            : " seconds")
        : "") +
      (milliseconds ? milliseconds + " milliseconds." : "") +
      (days || hours || minutes || seconds || milliseconds ? ")" : "")
    );
  }
  return "";
};
