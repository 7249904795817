import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface ImageError {
  message: string;
}

export interface GetImages {
  search: string;
  page: number;
  page_size: number;
}

export interface AddImage {
  filename: string;
  image: any;
}

export interface DeleteImage {
  id: string;
}

export interface ImageState {
  isLoading: boolean;
  error?: ImageError;
  errors?: any;
  add_image_data: AddImage | any;
  image_list_data_for_check: GetImages | any;
  whole_image_list_data: GetImages | any;
  image_list_data: GetImages | any;
  delete_image_data: DeleteImage | any;
}

export const initialState: ImageState = {
  isLoading: false,
  add_image_data: {},
  whole_image_list_data: {},
  image_list_data: {},
  delete_image_data: {},
  image_list_data_for_check: {},
};

export const imagesSlice = createSlice({
  name: "images",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },

    setAddImageData: (state, { payload }) => {
      state.add_image_data = payload;
    },

    setImageListData: (state, { payload }) => {
      state.image_list_data = payload;
    },

    setWholeImageListData: (state, { payload }) => {
      state.whole_image_list_data = payload;
    },

    setAddImageFailed: (state, { payload }) => {
      state.errors = payload;
    },

    setDeleteImageData: (state, { payload }) => {
      state.delete_image_data = payload;
    },

    setDeleteImageFailed: (state, { payload }) => {
      state.errors = payload;
    },

    setGetImageDataFailed: (state, { payload }) => {
      state.errors = payload;
    },

    setImageListDataForCheck: (state, { payload }) => {
      state.image_list_data_for_check = payload;
    },
  },
});

export const {
  setAddImageFailed,
  setAddImageData,
  setImageListData,
  setWholeImageListData,
  setGetImageDataFailed,
  setDeleteImageData,
  setDeleteImageFailed,
  setImageListDataForCheck,
} = imagesSlice.actions;

export const imageSelector = (state: RootState) => state;

export default imagesSlice.reducer;
