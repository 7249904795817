import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import "react-phone-input-2/lib/style.css";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import CreateButton from "../../components/CreateButton";
import Header from "../../components/Header";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import usePermission from "../../hooks/usePermission";
import { getPolicyData } from "../../services/policyService";
import {
  alertMessageSelector,
  setErrors,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import Style from "./AddNewPolicy.module.scss";
import PolicyDepartments from "./PolicyDepartments";
import PolicyGroups from "./PolicyGroups";
import PolicyStatus from "./PolicyStatus";

const statuses = [
  { key: "Approved", value: "Approved" },
  { key: "Draft", value: "Draft" },
];

const schema = yup
  .object({
    name: yup
      .string()
      .required("Name is required")
      .max(100, "Name must be at most 100 characters"),
    description: yup
      .string()
      .required("Description is required")
      .max(2000, "Description must be at most 2000 characters"),
    status: yup.string().required("Status is required"),
  })
  .required();

function PolicyDetails() {
  const {
    reset,
    control,
    setError,
    setValue,
    getValues,
    watch,
    clearErrors,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });
  const editUpdatePermission = usePermission("change_policy");
  const [description, setDescription] = useState("");
  const [descriptionEditData, setDescriptionEditData] = useState<string>("");
  const [text, setText] = useState<string>("");
  const [defaultMemberRows, setDefaultMemberRows] = useState<any>([]);
  const [selectedMemberRows, setSelectedMemberRows] = useState<any>([]);
  const t = useTranslation();
  const lang = useCompanyLanguage();

  //   useEffect(() => {
  //     setValue("description", description);
  //     setError("description", {
  //       message: "",
  //       type: "server",
  //     });
  //     if (description === "<p><br></p>" || description === `<p class="ql-align-center"><br></p>`) {
  //       setError("description", {
  //         message: "Description is required!",
  //         type: "server",
  //       });
  //     }

  //   }, [description]);

  useEffect(() => {
    if (description) {
      setValue("description", description);
    } else {
      setValue("description", "");
    }
  }, [description]);
  // useEffect(() => {
  //   setTrainingName(watch("training"));
  // }, [watch("training")]);
  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );

  const page = useSelector((state: RootState) => state.policyReducer.page);
  const perPage = useSelector(
    (state: RootState) => state.policyReducer.perPage
  );
  const search = useSelector((state: RootState) => state.policyReducer.search);
  const navigate = useNavigate();
  const urlWithQueryString = () => {
    let object: any = {};
    if (page) {
      object.page = page;
    }
    if (perPage) {
      object.perpage = perPage;
    }
    if (
      search !== null &&
      search !== "" &&
      search !== undefined &&
      search !== "null"
    ) {
      object.search = search;
    }

    const objString = "?" + new URLSearchParams(object).toString();
    navigate(`/policy-listing${objString}`);
  };
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const data: any = useSelector(
    (state: RootState) => state.policyReducer.detail
  );

  useEffect(() => {
    if (data?.description) {
      setDescriptionEditData(data?.description);
    }
    setError("description", {
      message: "",
      type: "server",
    });
    if (
      description == "<p><br></p>" ||
      description == `<p class="ql-align-center"><br></p>`
    ) {
      setError("description", {
        message: "Description is required!",
        type: "server",
      });
    }
  }, [data?.description]);

  useEffect(() => {
    if (data) setDefaultMemberRows(data?.details?.users);
  }, [data]);

  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;

  useEffect(() => {
    let defaultValues = {};
    reset({ ...defaultValues });
    dispatch(getPolicyData(Number(id)));
  }, []);

  useEffect(() => {
    if (alertMessage?.errors) {
      if (alertMessage?.errors?.name?.length > 0) {
        toast.error(alertMessage?.errors?.name?.toString(), {
          toastId: alertMessage?.errors?.name?.toString(),
        });
        setError("name", {
          type: "server",
          message: alertMessage?.errors?.name?.toString(),
        });
      }
      if (alertMessage?.errors?.description?.length > 0) {
        toast.error(alertMessage?.errors?.description?.toString(), {
          toastId: alertMessage?.errors?.description?.toString(),
        });
        setError("description", {
          type: "server",
          message: alertMessage?.errors?.description?.toString(),
        });
      }
      if (alertMessage?.errors?.status?.length > 0) {
        toast.error(alertMessage?.errors?.status?.toString(), {
          toastId: alertMessage?.errors?.status?.toString(),
        });
        setError("status", {
          type: "server",
          message: alertMessage?.errors?.status?.toString(),
        });
      }
      if (alertMessage?.errors) {
        dispatch(setErrors(null));
      }
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      urlWithQueryString();
    }
  }, [alertMessage]);

  return (
    <>
      <div
        className={`${Style.certificatesStart}`}
        style={{
          background:
            useColor("interface_background_color") ||
            defaultThemeColor.interface_background_color,
        }}
      >
        <Header
          page={t("Policy Detail")}
          mainHead={t("Policy Management / Policy Detail")}
          backLink="/policy-listing"
        />
        <div
          className="page-start"
          style={{
            background:
              useColor("interface_foreground_color") ||
              defaultThemeColor.interface_foreground_color,
            color:
              useColor("interface_foreground_text_color") ||
              defaultThemeColor.interface_foreground_text_color,
          }}
        >
          <h2
            className="page-heading mb-4"
            style={{
              color:
                useColor("interface_foreground_text_color") ||
                defaultThemeColor.interface_foreground_text_color,
            }}
          >
            {data?.name}&nbsp;
            {editUpdatePermission && (
              <div className="float-end">
                <CreateButton
                  size="small"
                  outlined={false}
                  onClick={() => {
                    navigate(`/Edit-policy-detail/${data?.id}`);
                  }}
                  text={t("Edit")}
                ></CreateButton>
              </div>
            )}
          </h2>

          <div className="d-flex justify-content-between py-4 mb-4">
            <div>
              <div className={`${Style.topheads} pb-3`}>{t("Status")}</div>
              <div
                className={
                  data && data?.status == "Approved"
                    ? Style.statusSuccess
                    : data?.status == "Draft"
                    ? Style.statusDraftYellow
                    : ""
                }
              >
                {(data && data?.status) || "N/A"}
              </div>
            </div>
            <div>
              <div className={`${Style.topheads} pb-3`}>
                {t("Policy Applies to")}
              </div>
              <div className={Style.topvals}>
                {(data && data?.applied_to) || "N/A"}
              </div>
            </div>
            <div>
              <div className={`${Style.topheads} pb-3`}>
                {" "}
                {data && data?.applied_to == "Roles" ? "Roles" : ""}
              </div>
              <div className={Style.topvals}>
                {data && data?.applied_to == "Roles"
                  ? data?.applied_user_groups.replace(/,/g, ", ")
                  : ""}
              </div>
            </div>
            <div></div>
          </div>

          <div>
            <div className={Style.header2}>{t("Description")}</div>
            <p
              className={Style.value}
              dangerouslySetInnerHTML={{
                __html: data?.description
                  ? JSON.parse(data?.description)
                  : null,
              }}
            ></p>
          </div>

          {data && data?.details && data?.details?.Groups && (
            <PolicyGroups
              id={data?.id}
              setSelectedRows={setSelectedMemberRows}
              selectedRows={selectedMemberRows}
              setDefaultRows={setDefaultMemberRows}
              defaultRows={data?.details?.Groups}
            />
          )}

          {data && data?.details && data?.details?.Departments && (
            <PolicyDepartments
              id={data?.id}
              setSelectedRows={setSelectedMemberRows}
              selectedRows={selectedMemberRows}
              setDefaultRows={setDefaultMemberRows}
              defaultRows={data?.details?.Departments}
            />
          )}

          <PolicyStatus
            id={data?.id}
            setSelectedRows={setSelectedMemberRows}
            selectedRows={selectedMemberRows}
            setDefaultRows={setDefaultMemberRows}
            defaultRows={defaultMemberRows}
          />

          <div className={`row`}></div>
        </div>
      </div>
    </>
  );
}

export default PolicyDetails;
