// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CancelButton_cancelBtn__j9s8X {
  border-radius: 4px;
  outline: none !important;
}
@media only screen and (max-width: 1100px) {
  .CancelButton_cancelBtn__j9s8X {
    min-width: 120px;
  }
}
@media only screen and (max-width: 490px) {
  .CancelButton_cancelBtn__j9s8X {
    min-width: 120px;
  }
}

.CancelButton_widthLarge__eiwSi {
  min-width: 167px;
}
@media only screen and (max-width: 490px) {
  .CancelButton_widthLarge__eiwSi {
    min-width: 115px;
  }
}

.CancelButton_widthSmall__YPVKm {
  min-width: 150px;
}
@media only screen and (max-width: 490px) {
  .CancelButton_widthSmall__YPVKm {
    min-width: 115px;
  }
}

.CancelButton_buttonFont__\\+cOkn {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.46px;
}

.CancelButton_white__BlHAS {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/CancelButton.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EAQA,wBAAA;AANF;AADE;EAFF;IAGI,gBAAA;EAIF;AACF;AAHE;EALF;IAMI,gBAAA;EAMF;AACF;;AADA;EACE,gBAAA;AAIF;AAHE;EAFF;IAGI,gBAAA;EAMF;AACF;;AAHA;EACE,gBAAA;AAMF;AALE;EAFF;IAGI,gBAAA;EAQF;AACF;;AALA;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;AAQF;;AALA;EACE,YAAA;AAQF","sourcesContent":[".cancelBtn {\n  border-radius: 4px;\n  @media only screen and (max-width: 1100px) {\n    min-width: 120px;\n  }\n  @media only screen and (max-width: 490px) {\n    min-width: 120px;\n  }\n  // border: none !important;\n  outline: none !important;\n}\n\n.widthLarge {\n  min-width: 167px;\n  @media only screen and (max-width: 490px) {\n    min-width: 115px;\n  }\n}\n\n.widthSmall {\n  min-width: 150px;\n  @media only screen and (max-width: 490px) {\n    min-width: 115px;\n  }\n}\n\n.buttonFont {\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 26px;\n  letter-spacing: 0.46px;\n}\n\n.white {\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cancelBtn": `CancelButton_cancelBtn__j9s8X`,
	"widthLarge": `CancelButton_widthLarge__eiwSi`,
	"widthSmall": `CancelButton_widthSmall__YPVKm`,
	"buttonFont": `CancelButton_buttonFont__+cOkn`,
	"white": `CancelButton_white__BlHAS`
};
export default ___CSS_LOADER_EXPORT___;
