import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import DataTable from "../../components/table/DataTable";
import TableFilters from "../../components/table/TableFilters";
import useCompanyLanguage from "../../hooks/language";
import ViewSVGAction from "../../icons/ViewSVGAction";
import {
  getAssessment,
  getDetailDataAssociation,
  getWholeDetailDataAssociation,
} from "../../services/assessmentService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { setWholeDetail } from "../../store/reducers/assessmentReducer";
import { useAppDispatch, useAppSelector } from "../../store/store";
import Style from "../category/Category.module.scss";

function AssessmentLink() {
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<number[]>([]);
  const dataList: any = useAppSelector((state) => state.assessmentReducer.data);
  const wholeData: any = useAppSelector(
    (state) => state.assessmentReducer.wholedetail
  );
  const detail = useAppSelector(
    (state) => state.assessmentReducer.assessmentData
  );
  const [pageNum, setPageNum] = useState(Number(1));
  const [perPageNum, setPerPageNum] = useState(10);
  const [pageSearch, setPageSearch] = useState("");
  const [list, setList] = useState([]);

  const alertMessage = useAppSelector(alertMessageSelector).alertMessageReducer;
  useEffect(() => {
    dispatch(getAssessment({ id: id }));
  }, [id]);
  useEffect(() => {
    AllData();
  }, [pageNum, perPageNum, pageSearch]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }

    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));

      AllData();
    }
  }, [alertMessage]);

  const AllData = () => {
    let query: any = {};
    query.id = Number(id);
    query.page = Number(pageNum);

    query.page_size = Number(perPageNum);

    query.search = String(pageSearch);
    dispatch(getDetailDataAssociation(query));
  };

  const handleNavigation = (row: any) => {
    if (row?.instance_type?.toLowerCase() === "module") {
      navigate(`/module-link/${row?.id}`);
      return;
    } else if (row?.instance_type?.toLowerCase() === "training") {
      navigate(`/detail-training/view/${row?.id}`);
      return;
    }
    return;
  };
  const modifyRows = () => {
    let f_rows: any = [];
    dataList?.results?.forEach(
      (
        r: {
          id: string;
          name: string;
          instance_type: string;
          created_at: string;
        },
        i: number
      ) => {
        f_rows = [
          ...f_rows,
          {
            id: r.id,
            name: r.name,
            associated_to: r?.instance_type,
            created_at:
              moment(new Date(r.created_at)).format("MMM Do, YYYY") || "",

            actions: (
              <>
                <div style={{ textAlign: "center", paddingLeft: "1em" }}>
                  <button
                    className="btn-link"
                    type="button"
                    onClick={(e) => {
                      handleNavigation(r);
                    }}
                  >
                    <ViewSVGAction />
                  </button>
                </div>
              </>
            ),
          },
        ];
      }
    );
    setList(f_rows);
  };
  useEffect(() => {
    modifyRows();
  }, [dataList]);

  const handleChangePage = (event: React.ChangeEvent<unknown>, val: number) => {
    setPageNum(Number(val));
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPageNum(1);
    setPerPageNum(Number(rowsPerPage));
  };
  const handleChangeSearch = (val: string) => {
    setPageNum(1);
    setPageSearch(String(val));
    setPerPageNum(Number(10));
  };

  const handleClicked = () => {
    getWhole();
  };

  const getWhole = () => {
    if (dataList)
      dispatch(
        getWholeDetailDataAssociation({
          id: Number(id),
          page_size: dataList.count,
          page: 1,
          search: "",
        })
      );
  };

  return (
    <div className={`${Style.categoryStart}`}>
      <div style={{ paddingBottom: "0.6em" }}>
        <Header
          page={detail?.name}
          mainHead={t("Knowledge Assessment / Assessment")}
          backLink="/assessments/assessment-library"
        />
        <TableFilters
          dataList={dataList}
          itemPerPage={Number(perPageNum)}
          selectedCheckBox={selectedCheckBox}
          setItemPerPage={handleChangeRowsPerPage}
          setSearchTable={handleChangeSearch}
          handle="category"
          isCheckboxes={false}
          exportCSV={dataList?.count ? true : false}
          setParentData={() => dispatch(setWholeDetail({}))}
          handleClicked={handleClicked}
          rows={wholeData?.results?.map((r: any, i: any) => ({
            name: r.name,
            associated_to: r?.instance_type,
            created_at:
              moment(new Date(r.created_at)).format("MMM Do, YYYY") || "",
          }))}
          headers={[t("Name"), t("Associated To"), t("Date Created")]}
          filename={`assessment_detail_${id}.csv`}
        />
        <DataTable
          columnData={[
            {
              id: "name",
              name: t("Name"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "checkbox",
            },
            {
              id: "associated_to",
              name: t("Associated To"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "created_at",
              name: t("Date Created"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "action",
              name: t("Actions"),
              enableSort: false,
              align: "center",
              disablePadding: "none",
            },
          ]}
          rows={list}
          isCheckboxes={false}
          selectedRowsPerPage={String(perPageNum)}
          totalRecordCount={dataList.count}
          handleChangePageParent={handleChangePage}
          pagenum={Number(pageNum)}
          handleChangeRowsPerPageParent={handleChangeRowsPerPage}
          selectedCheckBox={selectedCheckBox}
          setSelectedCheckBox={setSelectedCheckBox}
        />
      </div>
    </div>
  );
}

export default AssessmentLink;
