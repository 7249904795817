import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { Link, useNavigate } from "react-router-dom";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import SuccessPopup from "../../images/success.svg";
import { updateCertificateView } from "../../services/trainingsService";
import { useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import Style from "./TrainingList/ModulesMemberLists.module.scss";

function TrainingLectureSuccessModal({
  trainingDetail,
}: {
  trainingDetail: any;
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  // const [module, setModule] = useState<any>();

  const handleClose = () => {
    setIsOpen(false);
    // Navigate back to the previous route when the modal is closed
  };
  useEffect(() => {
    dispatch(
      updateCertificateView({
        training_id: Number(trainingDetail?.id),
      })
    );
  }, [trainingDetail, dispatch]);

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;

  return (
    <Dialog
      onClose={handleClose}
      maxWidth={"lg"}
      open={isOpen}
      style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
    >
      <div
        className=""
        style={{
          position: "absolute",
          background: "#0089cf",
          borderRadius: "50%",
          padding: "4px",
          color: "#fff",
          top: "10px",
          right: "10px",
        }}
      >
        <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
      </div>
      <DialogContent
        style={{
          color: interfaceTextColor,
          background: interfaceBgColor,
          padding: "0",
        }}
      >
        <div className={Style.successImgWrap}>
          <img src={SuccessPopup} alt="success-img" />
        </div>
        <div className={Style.successContainer}>
          <div className={Style.heading}>{t("Success !")}</div>
          <div className={Style.desc}>
            Congratulations! You have successfully achieved a{" "}
            {trainingDetail?.name} certificate.
          </div>
          <div className={Style.flexWrapper}>
            <div className={Style.backLink}>
              <Link to="/my-training">{t("BACK TO TRAININGS")}</Link>
            </div>
            <div className={Style.flexWrap}>
              {/* {Number(trainingDetail?.progress) === 100 && ( */}
              <div>
                <button
                  className={Style.downloadBtn}
                  onClick={() => {
                    navigate(
                      `/certificate/download/${trainingDetail?.id}/true/my-training`
                    );
                  }}
                >
                  {t("DOWNLOAD")}
                </button>
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default TrainingLectureSuccessModal;
