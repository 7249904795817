// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ql-toolbar:not(:first-child) {
  display: none !important;
}

.ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

.ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.ql-editor {
  min-height: 18em;
  white-space: pre-wrap !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Editor/styles.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,gCAAgC;EAChC,iCAAiC;EACjC,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,6BAA6B;EAC7B,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;EAChB,gCAAgC;AAClC","sourcesContent":[".ql-toolbar:not(:first-child) {\n  display: none !important;\n}\n\n.ql-container {\n  border-bottom-left-radius: 0.5em;\n  border-bottom-right-radius: 0.5em;\n  background: #fefcfc;\n}\n\n.ql-snow.ql-toolbar {\n  display: block;\n  background: #eaecec;\n  border-top-left-radius: 0.5em;\n  border-top-right-radius: 0.5em;\n}\n\n.ql-editor {\n  min-height: 18em;\n  white-space: pre-wrap !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
