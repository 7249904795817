// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CompanyPolicyListing_categoryStart__l1ozu {
  width: 100%;
  overflow: auto !important;
}

.CompanyPolicyListing_header__kBodj {
  color: #212b36;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
}

.CompanyPolicyListing_headerCheck__fm3rE {
  padding: 0;
}

.CompanyPolicyListing_statusSuccess__IIPaf {
  border-radius: 25px;
  background: #00c366;
  display: inline-flex;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  text-transform: capitalize;
}

.CompanyPolicyListing_statusDraft__K\\+QBu {
  border-radius: 25px;
  background: yellow;
  display: inline-flex;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: black;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  min-width: 6em;
  text-transform: capitalize;
}

.CompanyPolicyListing_statusDecline__8ekTh {
  border-radius: 25px;
  background: #f00;
  display: inline-flex;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  min-width: 6em;
  text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/pages/companyPolicy/CompanyPolicyListing.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,yBAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;AACF;;AAEA;EACE,UAAA;AACF;;AACA;EACE,mBAAA;EACA,mBAAA;EACA,oBAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,0BAAA;AAEF;;AACA;EACE,mBAAA;EACA,kBAAA;EACA,oBAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;EACA,0BAAA;AAEF;;AAAA;EACE,mBAAA;EACA,gBAAA;EACA,oBAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;EACA,0BAAA;AAGF","sourcesContent":[".categoryStart {\n  width: 100%;\n  overflow: auto !important;\n}\n\n.header {\n  color: #212b36;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 22px; /* 137.5% */\n}\n\n.headerCheck {\n  padding: 0;\n}\n.statusSuccess {\n  border-radius: 25px;\n  background: #00c366;\n  display: inline-flex;\n  padding: 2px 10px;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n  color: #fff;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 22px;\n  text-transform: capitalize;\n}\n\n.statusDraft {\n  border-radius: 25px;\n  background: yellow;\n  display: inline-flex;\n  padding: 2px 10px;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n  color: black;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 22px;\n  min-width: 6em;\n  text-transform: capitalize;\n}\n.statusDecline {\n  border-radius: 25px;\n  background: #f00;\n  display: inline-flex;\n  padding: 2px 10px;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n  color: white;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 22px;\n  min-width: 6em;\n  text-transform: capitalize;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"categoryStart": `CompanyPolicyListing_categoryStart__l1ozu`,
	"header": `CompanyPolicyListing_header__kBodj`,
	"headerCheck": `CompanyPolicyListing_headerCheck__fm3rE`,
	"statusSuccess": `CompanyPolicyListing_statusSuccess__IIPaf`,
	"statusDraft": `CompanyPolicyListing_statusDraft__K+QBu`,
	"statusDecline": `CompanyPolicyListing_statusDecline__8ekTh`
};
export default ___CSS_LOADER_EXPORT___;
