import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Chip, RadioGroup } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import Select from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import BpRadioButton from "../../components/BpRadioButton";
import CancelButton from "../../components/CancelButton";
import CategoryLoadComponent from "../../components/CategoryLoad/CategoryLoad";
import SubmitButton from "../../components/SubmitButton";
import UploadFile from "../../components/uploadFile";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import DeleteSVG from "../../icons/DeleteSVG";
import EditSVG from "../../icons/EditSVG";
import {
  addQuestion,
  addQuestionWithImage,
  getImage,
  getQuestion,
  updateQuestion,
  updateQuestionWithImage,
} from "../../services/questionsService";
import {
  alertMessageSelector,
  setErr,
  setErrors,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { setImageData } from "../../store/reducers/questionReducer";
import { RootState, useAppDispatch } from "../../store/store";
import {
  defaultThemeColor,
  light_text_color,
  primary_color,
} from "../../utils/colors_values";
import AddAnswer from "./AddAnswer/AddAnswer";
import Style from "./CreateQuestion.module.scss";

interface QuestionForm {
  cancelAction?: any;
  id?: any;
  isModal?: boolean;
  setOpenCreateNewQuestionModal?: any;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const types = [
  { key: "MCQ", value: "Multiple Choice Questions" },
  { key: "TF", value: "True False" },
];

const statuses = [
  { key: "Approved", value: "Approved" },
  { key: "Draft", value: "Draft" },
];

const schema = yup
  .object({
    question_type: yup.string().required("Question Type is required"),
    question_status: yup.string().required("Status is required"),
    question_text: yup
      .string()
      .required("Question is required")
      .matches(/\S/, "Please input character other than space!")
      .max(250, "Question must be at most 250 characters"),
    category: yup.object().required("Category is required"),
    option: yup
      .string()
      .when("answer_options", {
        is: (arr: any) => arr && arr.length > 0,
        then: () => yup.string().required("Correct Option is required"),
      })
      .when("question_type", {
        is: "True False",
        then: () => yup.string().optional(),
      }),
    c_option_tf: yup
      .string()
      .when("question_type", {
        is: "True False",
        then: () =>
          yup
            .string()
            .oneOf(["true", "false"], "Please select correct option")
            .required("Correct Option is required"),
      })
      .when("question_type", {
        is: "Multiple Choice Questions",
        then: () => yup.string().optional(),
      }),
    answer_options: yup
      .array()
      .when("question_type", {
        is: "Multiple Choice Questions",
        then: () =>
          yup
            .array()
            .min(1, "Options field must have at least 1 items")
            .required("Question Options are required"),
      })
      .when("question_type", {
        is: "True False",
        then: () => yup.array().optional(),
      }),
    randomise_answer_position: yup.bool().optional(),
    image: yup.string().default("").optional(),
  })
  .required();

function CreateQuestionForm({
  cancelAction,
  isModal = false,
  setOpenCreateNewQuestionModal,
}: QuestionForm) {
  const {
    register,
    reset,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  const questionData: any = useSelector(
    (state: RootState) => state.questionReducer.questionData
  );
  const imageData: any = useSelector(
    (state: RootState) => state.questionReducer.imageData
  );
  const states = useSelector(alertMessageSelector).alertMessageReducer;
  const [open, setOpen] = React.useState(false);
  const [image, setImage] = useState<any>();
  const [edit, setEdit] = useState(false);
  const [editId, setEditId] = useState(0);

  const foregroundTextColor =
    useColor("interface_foreground_text_color") ||
    defaultThemeColor.interface_foreground_text_color;

  useEffect(() => {
    dispatch(setImageData({}));
  }, []);

  useEffect(() => {
    if (id && !isModal) {
      dispatch(getQuestion({ id: id }));
    }
  }, [id]);

  useEffect(() => {
    if (states?.error) {
      toast.error(String(states?.error), {
        toastId: String(states?.error),
      });
      dispatch(setErr(null));
    }
    if (states?.success) {
      toast.success(String(states?.success), {
        toastId: String(states?.success),
      });
      dispatch(setSuccess(null));
    }
  }, [states]);

  useEffect(() => {
    if (states?.errors) {
      if (states?.errors?.question_text?.length > 0) {
        setError("question_text", {
          type: "server",
          message: states?.errors?.question_text?.toString(),
        });
      }
      if (states?.errors?.question_type?.length > 0) {
        setError("question_type", {
          type: "server",
          message: states?.errors?.question_type?.toString(),
        });
      }
      if (states?.errors?.question_status?.length > 0) {
        setError("question_status", {
          type: "server",
          message: states?.errors?.question_status?.toString(),
        });
      }
      if (states?.errors?.category?.length > 0) {
        setError("category", {
          type: "server",
          message: states?.errors?.category?.toString(),
        });
      }
      if (states?.errors) {
        dispatch(setErrors(null));
      }
    }
    if (states?.error) {
      toast.error(String(states?.error), {
        toastId: String(states?.error),
      });
    }
    if (states?.success) {
      toast.success(String(states?.success), {
        toastId: String(states?.success),
      });
      dispatch(setSuccess(null));
      if (isModal) {
        setOpenCreateNewQuestionModal(false);
      } else {
        navigate("/questions-library");
      }
    }
  }, [states]);

  useEffect(() => {
    setImage(imageData.file);
    setValue("image", imageData.file);
  }, [imageData]);

  useEffect(() => {
    if (id && !isModal) {
      if (questionData?.image) {
        dispatch(getImage({ id: questionData.image }));
      } else {
        setValue("image", "");
        setImage("");
        dispatch(setImageData({}));
      }
      let defaultValues = {
        question_text: questionData?.question_text,
        question_type:
          questionData?.question_type == "MCQ"
            ? "Multiple Choice Questions"
            : questionData?.question_type == "TF"
            ? "True False"
            : "",
        category: {
          value: questionData?.category?.id,
          label: questionData?.category?.name,
        },
        question_status:
          questionData?.question_status?.toLowerCase() == "approved"
            ? "Approved"
            : questionData?.question_status?.toLowerCase() == "draft"
            ? "Draft"
            : "",

        answer_options:
          questionData?.question_type == "MCQ"
            ? questionData &&
              questionData?.options &&
              questionData?.options.map((val: any, i: any) => ({
                id: i,
                answer: val,
                checked: questionData?.correct_option == val,
              }))
            : [],
        option:
          questionData?.question_type == "MCQ"
            ? questionData?.correct_option
            : "",

        c_option_tf:
          questionData?.question_type == "TF"
            ? questionData?.correct_option
            : "",
        randomise_answer_position: questionData?.randomise_answer_position,
      };

      reset({ ...defaultValues });
    }
  }, [questionData]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddOption = (props: { answer_option: any }) => {
    clearErrors("answer_options");
    setValue("answer_options", [
      ...(getValues("answer_options") || []),
      {
        id: (getValues("answer_options") || []).length + 1,
        answer: props.answer_option,
        checked: false,
      },
    ]);
    setOpen(false);
    setEdit(false);
    setEditId(0);
    clearErrors("answer_options");
  };

  const handleUpdateOption = (props: { answer_option: any; id: any }) => {
    clearErrors("answer_options");
    setValue(
      "answer_options",
      [
        ...(getValues("answer_options") as any)?.filter(
          (i: any) => !(i.id == props.answer_option.id)
        ),
        {
          id: props.answer_option.id,
          answer: props.answer_option.answer,
          checked: props.answer_option.checked,
        },
      ].sort((a, b) => a.id - b.id)
    );
    setOpen(false);
    clearErrors("answer_options");
  };

  const questionSubmit = (payload: any) => {
    let final = null;
    if (payload.question_type == "Multiple Choice Questions") {
      if (payload.answer_options.length < 2) {
        setError("answer_options", {
          type: "server",
          message: "At least two options are required!",
        });
        return;
      }
    }
    final = {
      question_text: payload.question_text,
      question_type:
        payload.question_type == "Multiple Choice Questions" ? "MCQ" : "TF",
      question_status:
        payload.question_status == "Approved" ? "Approved" : "Draft",
      options:
        payload.question_type == "True False"
          ? ["true", "false"]
          : (payload?.answer_options &&
              payload?.answer_options.map((answer: any) => answer.answer)) ||
            [],
      correct_option:
        payload.question_type == "True False"
          ? payload.c_option_tf
          : payload.option,
      category: (payload?.category && payload?.category.value) || undefined,
      randomise_answer_position: payload?.randomise_answer_position || false,
    };

    if (image) {
      final = { ...final, image: image?.id ? image.id : image };
      if (id && !isModal) {
        if (typeof image == "string" || image?.id) {
          final = {
            ...final,
            image:
              typeof image == "string"
                ? questionData?.image
                : image?.id
                ? image?.id
                : "",
            id: id,
          };
          dispatch(updateQuestion(final));
        } else {
          final = { ...final, id: id };
          dispatch(updateQuestionWithImage(final));
        }
      } else {
        if (image?.id) {
          dispatch(addQuestion(final));
        } else {
          dispatch(addQuestionWithImage(final));
        }
      }
    } else {
      if (id && !isModal) {
        final = { ...final, id: id, image: null };
        setValue("image", "");
        dispatch(updateQuestion(final));
      } else dispatch(addQuestion(final));
    }
  };

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;
  const primaryButton =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;
  const primaryButtonBg =
    useColor("button_primary") || defaultThemeColor.button_primary;

  return (
    <>
      <form
        onSubmit={handleSubmit(questionSubmit)}
        autoComplete="off"
        id={"submitfor"}
      >
        <div className={`row col-lg-12 px-0 mx-0`}>
          <div className={Style.columnWrap}>
            <div
              className={`${Style.labelHeader} col-lg-6 px-0`}
              style={{ textAlign: lang === "ar" ? "right" : "left" }}
            >
              {t("Type")}
              <span style={{ color: "red" }}> *</span>
            </div>
            <Controller
              name="question_type"
              control={control}
              render={({
                field: { onChange, value, ref },
                fieldState: { error },
              }) => (
                <FormControl sx={{ m: 0, width: "100%", mt: 1 }}>
                  {/* <InputLabel id="demo-multiple-name-label">
                    {t("Type")}
                  </InputLabel> */}
                  <Select
                    labelId="select"
                    label=""
                    defaultValue={""}
                    value={value || ""}
                    style={{
                      color: interfaceTextColor,
                      background: interfaceBgColor,
                    }}
                    placeholder="Type"
                    onChange={(event) => {
                      onChange(event);
                    }}
                    sx={{
                      height: "3.85em",
                      marginTop: "2px",
                      "& .MuiChip-root": {
                        color: primaryButton,
                        background: primaryButtonBg,
                      },
                    }}
                    MenuProps={{
                      sx: {
                        "&& .Mui-selected": {
                          color: light_text_color,
                          background: primary_color,
                        },
                        "&& .Mui-selected:hover": {
                          color: "black",
                          background: "skyblue",
                        },
                      },
                    }}
                    displayEmpty
                    renderValue={(selected) => {
                      // setSelectedLimit(selected);
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.5,
                          }}
                        >
                          {(selected && (
                            <Chip key={selected} label={selected} />
                          )) || (
                            <span style={{ color: "hsl(0, 0%, 50%)" }}>
                              Select Question Type
                            </span>
                          )}
                        </Box>
                      );
                    }}
                  >
                    {types.map((row) => (
                      <MenuItem
                        value={row.value}
                        key={row.key}
                        className="d-flex"
                      >
                        <Radio
                          color="default"
                          sx={{
                            color:
                              getValues() && getValues().question_type
                                ? (getValues()?.question_type as any).indexOf(
                                    row.value
                                  ) > -1
                                  ? "white"
                                  : ""
                                : "",
                          }}
                          checked={
                            getValues() && getValues().question_type
                              ? (getValues()?.question_type as any).indexOf(
                                  row.value
                                ) > -1
                              : false
                          }
                          name="radio-buttons"
                        />
                        <div> {row.value}</div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <p className="color-red">{errors.question_type?.message}</p>
          </div>

          <div className={Style.columnWrap}>
            <div
              className={`${Style.labelHeader} col-lg-6 px-0`}
              style={{ textAlign: lang === "ar" ? "right" : "left" }}
            >
              {t("Category")}
              <span style={{ color: "red" }}> *</span>
            </div>

            <CategoryLoadComponent
              control={control}
              parentName={"category"}
            ></CategoryLoadComponent>

            <p className="color-red">{errors.category?.message}</p>
          </div>

          <div className={Style.columnWrap}>
            <div
              className={`${Style.labelHeader} col-lg-6 px-0`}
              style={{ textAlign: lang === "ar" ? "right" : "left" }}
            >
              {t("Status")}
              <span style={{ color: "red" }}> *</span>
            </div>
            <Controller
              name="question_status"
              control={control}
              defaultValue={""}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl sx={{ m: 0, width: "100%", mt: 1 }}>
                  {/* <InputLabel id="demo-multiple-name-label">{t("Status")}</InputLabel> */}
                  <Select
                    labelId="question_status"
                    label=""
                    defaultValue={""}
                    value={value}
                    style={{
                      color: interfaceTextColor,
                      background: interfaceBgColor,
                    }}
                    onChange={(event) => {
                      onChange(event);
                    }}
                    sx={{
                      height: "3.9em",
                      "& .MuiChip-root": {
                        // color: interfaceTextColor,
                        // background: interfaceBgColor,
                        color: primaryButton,
                        background: primaryButtonBg,
                      },
                    }}
                    MenuProps={{
                      sx: {
                        "&& .Mui-selected": {
                          // color: interfaceTextColor,
                          // background: interfaceBgColor,
                          color: light_text_color,
                          background: primary_color,
                        },
                        "&& .Mui-selected:hover": {
                          color: "black",
                          background: "skyblue",
                        },
                      },
                    }}
                    displayEmpty
                    renderValue={(selected) => {
                      // setSelectedLimit(selected);
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.5,
                          }}
                        >
                          {(selected && (
                            <Chip key={selected} label={selected} />
                          )) || (
                            <span style={{ color: "hsl(0, 0%, 50%)" }}>
                              Select Question Status
                            </span>
                          )}
                        </Box>
                      );
                    }}
                  >
                    {statuses.map((row) => (
                      <MenuItem
                        value={row.value}
                        key={row.key}
                        className="d-flex"
                      >
                        <Radio
                          color="default"
                          sx={{
                            color:
                              getValues() && getValues().question_status
                                ? (getValues()?.question_status as any).indexOf(
                                    row.value
                                  ) > -1
                                  ? "white"
                                  : ""
                                : "",
                          }}
                          checked={
                            getValues() && getValues().question_status
                              ? (getValues()?.question_status as any).indexOf(
                                  row.value
                                ) > -1
                              : false
                          }
                          name="radio-buttons"
                        />
                        <div> {row.value}</div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <p className="color-red">{errors.question_status?.message}</p>
          </div>
        </div>
        {(watch("question_type") === "True False" ||
          watch("question_type") === "Multiple Choice Questions") && (
          <div
            className={`row col-lg-12 mx-0 mt-5 ${Style.outerWrap}`}
            style={{
              background: interfaceBgColor,
            }}
          >
            <div
              className={`${
                watch("question_type") === "True False"
                  ? "col-lg-8"
                  : "col-lg-12"
              }  ps-0 pr-2`}
              style={{
                marginTop: "2em",
              }}
            >
              <span
                className={Style.labelHeader}
                style={{ color: interfaceTextColor }}
              >
                {t("Question")}
                <span style={{ color: "red" }}> *</span>
              </span>

              <UploadFile
                size="small"
                fileTypes="image/jpg, image/jpeg, image/png"
                loading={false}
                errors={errors}
                setValue={setValue}
                setValueText={setValue}
                imageName={"image"}
                questionName={"question_text"}
                questionTextError={errors.question_text?.message || ""}
                clearErrors={clearErrors}
                acceptedImageTypesArray={[
                  "image/jpg",
                  "image/jpeg",
                  "image/png",
                ]}
                setImageParent={setImage}
                maxFileSizeinBytes={5242880} // 5mb
                register={register}
                watch={watch}
                setParentImage={setImage}
              ></UploadFile>

              {/* <div className={`${Style.noteText}`}>
                      {
                        "Note: Only PNG, JPG and JPEG files are allowed with a maximum file size of 5MB"
                      }
                    </div> */}

              {/* <p className="color-red">{errors?.image?.message}</p> */}
            </div>

            {watch("question_type") === "True False" && (
              <div className="col-lg-4 px-0 mx-0" style={{ marginTop: "2em" }}>
                <span
                  className={Style.labelHeader}
                  style={{ marginTop: "2em", color: interfaceTextColor }}
                >
                  {t("Correct Answer")}
                  <span style={{ color: "red" }}> *</span>
                </span>
                <div
                  className="position-relative mt-3"
                  style={{
                    backgroundColor: interfaceBgColor,
                    color: interfaceTextColor,
                    padding: "1rem 2rem",
                  }}
                >
                  {/* <div className="row">
              <div className={`col-lg-6 col-md-12`}>
                <label
                  className={`checkBoxContainer mb-0 ${Style.permissionsText}`}
                >
                  <span style={{ textTransform: "capitalize" }}>True</span>

                  <input type="checkbox" />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className={`col-lg-6 col-md-12`}>
                <label
                  className={`checkBoxContainer mb-0 ${Style.permissionsText}`}
                >
                  <span style={{ textTransform: "capitalize" }}>False</span>

                  <input type="checkbox" />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div> */}

                  <FormControl className={` d-flex align-content-center`}>
                    <Controller
                      control={control}
                      name="c_option_tf"
                      defaultValue={watch("c_option_tf") || ""}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <RadioGroup
                          row
                          aria-labelledby="gender-row-radio-buttons-group-label"
                          className="d-flex col-12"
                          value={watch("c_option_tf") || ""}
                          onChange={(event) => {
                            onChange(event);
                            clearErrors("c_option_tf");
                          }}
                        >
                          <FormControlLabel
                            value="true"
                            control={<BpRadioButton />}
                            label="True"
                            className="col-6"
                          />
                          <FormControlLabel
                            value="false"
                            control={<BpRadioButton />}
                            label="False"
                            className="col-5"
                          />
                        </RadioGroup>
                      )}
                    />
                    <p className="color-red">
                      {errors?.c_option_tf?.message || ""}
                    </p>
                  </FormControl>
                  {/* <p className="color-red">{errors.permissions?.message}</p> */}
                </div>
              </div>
            )}
            {watch("question_type") === "Multiple Choice Questions" && (
              <div className="p-0">
                <div
                  className={`${Style.answerOuterWrap} p-0`}
                  style={{ marginTop: "2em" }}
                >
                  <div className={Style.headFlexWrapper}>
                    <div>
                      <span
                        className={Style.labelHeader}
                        style={{ color: interfaceTextColor }}
                      >
                        {t("Answer")}
                        <span style={{ color: "red" }}> *</span>
                      </span>
                    </div>
                    <div className="d-flex flex-row gap-3">
                      <>
                        <Button
                          variant="text"
                          color="inherit"
                          endIcon={
                            <AddIcon
                              style={{
                                fontSize: 15,
                                color: interfaceTextColor,
                                marginRight: lang === "ar" ? "10px" : "0px",
                              }}
                            />
                          }
                          style={{
                            fontSize: 13,
                            color: interfaceTextColor,
                            // textTransform: "capitalize",
                          }}
                          onClick={() => {
                            setEdit(false);
                            setOpen(true);
                            setEditId(0);
                          }}
                        >
                          {t("Add Answer")}
                        </Button>
                      </>
                    </div>{" "}
                  </div>

                  <div
                    className={Style.answerInnerWrapper}
                    style={{
                      background: interfaceBgColor,
                      color: interfaceTextColor,
                    }}
                  >
                    <div
                      className={Style.option}
                      style={{
                        background: interfaceBgColor,
                        color: interfaceTextColor,
                      }}
                    >
                      {t("Options")}
                    </div>
                    <div
                      className={Style.correct}
                      style={{
                        background: interfaceBgColor,
                        color: interfaceTextColor,
                      }}
                    >
                      {t("Correct")}
                    </div>
                    <div
                      className={Style.action}
                      style={{
                        background: interfaceBgColor,
                        color: interfaceTextColor,
                      }}
                    >
                      {t("Actions")}
                    </div>
                  </div>
                  <div
                    style={{
                      borderBottomLeftRadius: "10px",
                      border: "1px solid rgba(226, 232, 240, 1)",
                      borderBottomRightRadius: "10px",
                      backgroundColor:
                        interfaceBgColor || "rgba(249, 249, 249, 1)",
                      color: interfaceTextColor,
                    }}
                  >
                    {watch("answer_options") &&
                      watch("answer_options")?.map((option) => (
                        <div
                          key={option.id}
                          className={Style.answerInnerWrapper}
                          style={{ margin: "0" }}
                        >
                          <div
                            className={Style.optionText}
                            style={{ color: interfaceTextColor || "#6c757d" }}
                          >
                            {option.answer}
                          </div>
                          <div className={Style.correctRadio}>
                            <FormControlLabel
                              control={<Radio />}
                              label=""
                              onClick={() => {
                                let val = getValues("answer_options")?.find(
                                  (i) => i.id == option.id
                                );

                                setValue("option", val.answer);

                                setValue(
                                  "answer_options",
                                  [
                                    ...(getValues("answer_options")
                                      ?.filter((i) => !(i.id == option.id))
                                      ?.map((m: { id: any; answer: any }) => ({
                                        ...m,
                                        checked: false,
                                      })) as any),
                                    ...[
                                      {
                                        id: val.id,
                                        checked: true,
                                        answer: val.answer,
                                      },
                                    ],
                                  ].sort((a, b) => a.id - b.id)
                                );
                              }}
                              checked={option.checked}
                            />
                          </div>
                          <div className={Style.actionIcon}>
                            <button
                              className="btn-link"
                              type="button"
                              style={{ padding: "0px 5px", cursor: "pointer" }}
                              onClick={() => {
                                setOpen(true);
                                setEdit(true);
                                setEditId({ ...option, m: Math.random() });
                              }}
                            >
                              <EditSVG />
                            </button>
                            <button
                              className="btn-link"
                              type="button"
                              style={{ padding: "0px 5px", cursor: "pointer" }}
                              onClick={() => {
                                setValue(
                                  "answer_options",
                                  getValues("answer_options")?.filter(
                                    (i) => !(i.id == option.id)
                                  )
                                );
                              }}
                            >
                              <DeleteSVG color="#FF0000" />
                            </button>
                          </div>
                        </div>
                      ))}
                  </div>
                  <p className="color-red">
                    {/* {!watch("answer_options") ||
                    watch("answer_options")?.length == 0
                      ? "Options field must have at least 1 hh items"
                      : ""} */}
                    {errors.option?.message || ""}
                    {errors.answer_options?.message || ""}
                  </p>
                </div>
                {watch("answer_options") &&
                  (watch("answer_options") as any).length > 1 && (
                    <div className="row my-3 px-0 mt-4">
                      <div className={`col-lg-6 col-md-12`}>
                        <div className="mt-2 d-flex align-items-center">
                          <Controller
                            control={control}
                            name="randomise_answer_position"
                            defaultValue={false}
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <label
                                className={`checkBoxContainer mb-0 ${Style.permissionsText}`}
                              >
                                <span
                                  style={{
                                    textTransform: "capitalize",
                                    fontSize: "14px",
                                    color: "#64748B",
                                  }}
                                >
                                  {t("Randomise answer position")}
                                </span>

                                <input
                                  type="checkbox"
                                  onChange={onChange}
                                  checked={value}
                                />
                                <span className="checkmark"></span>
                              </label>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            )}
          </div>
        )}

        <label id="message" className="error"></label>

        <div className="d-flex flex-wrap gap-3 w-100 px-3 my-5 pt-5 align-items-center justify-content-end mb-5 pb-4">
          <SubmitButton
            label={id && !isModal ? t("Update") : t("Save")}
            marginTop={false}
          />

          <CancelButton
            size="small"
            outlined={true}
            onClick={cancelAction}
            textColor=""
            outlineColor=""
            text={t("Cancel")}
            className={``}
            bg={""}
          ></CancelButton>
        </div>
      </form>
      <AddAnswer
        setParentOpen={setOpen}
        open={open}
        onClose={handleClose}
        isLoading={loading}
        handleAddOption={handleAddOption}
        handleUpdateOption={handleUpdateOption}
        edit={edit}
        editId={editId}
        setValueParent={setValue}
        getValueParent={watch}
      />
    </>
  );
}

export default CreateQuestionForm;
