import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl } from "@mui/material";
import { useEffect, useState } from "react";
import { ColorResult } from "react-color";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import CancelButton from "../../../components/CancelButton";
import Header from "../../../components/Header";
import SubmitButton from "../../../components/SubmitButton";
import useCompanyLanguage from "../../../hooks/language";
import useColor from "../../../hooks/useColor";
import EditSVG from "../../../icons/EditSVG";
import { updateCompanyForm } from "../../../services/profileManagementService";
import { RootState, useAppDispatch } from "../../../store/store";
import {
  defaultThemeColor,
  light_text_color,
  primary_color,
  secondary_color,
} from "../../../utils/colors_values";
import ColorPicker from "../colorPicker/ColorPicker";
import Style from "./EditColorTheme.module.scss";

const schema = yup
  .object({
    sidebar_text_color: yup.string().optional(),
    sidebar_text_active: yup.string().optional(),
    sidebar_text_active_background: yup.string().optional(),
    sidebar_background_color: yup.string().optional(),
    interface_background_color: yup.string().optional(),
    interface_background_text_color: yup.string().optional(),
    interface_foreground_text_color: yup.string().optional(),
    interface_foreground_color: yup.string().optional(),
    button_primary: yup.string().optional(),
    button_primary_text: yup.string().optional(),
    button_secondary: yup.string().optional(),
    button_secondary_text: yup.string().optional(),
  })
  .required();

function EditColorTheme() {
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const companyFormData =
    useSelector(
      (state: RootState) => state.profileManagementReducer.company_form_data
    ) || [];

  const [selectedSidebarTextColor, setSelectedSidebarTextColor] = useState(
    companyFormData
      ? companyFormData?.sidebar_text_color ||
          defaultThemeColor.sidebar_text_color
      : defaultThemeColor.sidebar_text_color
  );
  const [selectedSidebarTextActive, setSelectedSidebarTextActive] = useState(
    companyFormData
      ? companyFormData?.sidebar_text_active ||
          defaultThemeColor.sidebar_text_active
      : defaultThemeColor.sidebar_text_active
  );
  const [selectedSidebarTextActiveBg, setSelectedSidebarTextActiveBg] =
    useState(
      companyFormData
        ? companyFormData?.sidebar_text_active_background ||
            defaultThemeColor.sidebar_text_active_background
        : defaultThemeColor.sidebar_text_active_background
    );
  const [selectedSidebarBg, setSelectedSidebarBg] = useState(
    companyFormData
      ? companyFormData?.sidebar_background_color ||
          defaultThemeColor.sidebar_background_color
      : defaultThemeColor.sidebar_background_color
  );
  const [selectedBackgroundColor, setSelectedBackgroundColor] = useState(
    companyFormData
      ? companyFormData?.interface_background_color ||
          defaultThemeColor.interface_background_color
      : defaultThemeColor.interface_background_color
  );
  const [selectedBackgroundTextColor, setSelectedBackgroundTextColor] =
    useState(
      companyFormData
        ? companyFormData?.interface_background_text_color ||
            defaultThemeColor.interface_background_text_color
        : defaultThemeColor.interface_background_text_color
    );
  const [selectedForegroundTextColor, setSelectedForegroundTextColor] =
    useState(
      companyFormData
        ? companyFormData?.interface_foreground_text_color ||
            defaultThemeColor.interface_foreground_text_color
        : defaultThemeColor.interface_foreground_text_color
    );
  const [selectedForegroundColor, setSelectedForegroundColor] = useState(
    companyFormData
      ? companyFormData?.interface_foreground_color ||
          defaultThemeColor.interface_foreground_color
      : defaultThemeColor.interface_foreground_color
  );
  const [selectedButtonPrimary, setSelectedButtonPrimary] = useState(
    companyFormData
      ? companyFormData?.button_primary || defaultThemeColor.button_primary
      : defaultThemeColor.button_primary
  );
  const [selectedPrimaryButtonText, setSelectedPrimaryButtonText] = useState(
    companyFormData
      ? companyFormData?.button_primary_text ||
          defaultThemeColor.button_primary_text
      : defaultThemeColor.button_primary_text
  );
  const [selectedSecondaryButton, setSelectedSecodaryButton] = useState(
    companyFormData
      ? companyFormData?.button_secondary || defaultThemeColor.button_secondary
      : defaultThemeColor.button_secondary
  );
  const [selectedSecondaryButtonText, setSelectedSecodaryButtonText] = useState(
    companyFormData
      ? companyFormData?.button_secondary_text ||
          defaultThemeColor.button_secondary_text
      : defaultThemeColor.button_secondary_text
  );

  const isLoading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );

  useEffect(() => {
    let comp_data = companyFormData;
    let defaultValues = {
      sidebar_text_color:
        (comp_data && companyFormData?.sidebar_text_color) ||
        defaultThemeColor.sidebar_text_color,
      sidebar_text_active:
        (comp_data && companyFormData?.sidebar_text_color) ||
        defaultThemeColor.sidebar_text_active,
      sidebar_text_active_background:
        (comp_data && companyFormData?.sidebar_text_active_background) ||
        defaultThemeColor.sidebar_text_active_background,
      sidebar_background_color:
        (comp_data && companyFormData?.sidebar_background_color) ||
        defaultThemeColor.sidebar_background_color,
      interface_background_color:
        (comp_data && companyFormData?.interface_background_color) ||
        defaultThemeColor.interface_background_color,
      interface_background_text_color:
        (comp_data && companyFormData?.interface_background_text_color) ||
        defaultThemeColor.interface_background_text_color,
      interface_foreground_text_color:
        (comp_data && companyFormData?.interface_foreground_text_color) ||
        defaultThemeColor.interface_foreground_text_color,
      interface_foreground_color:
        (comp_data && companyFormData?.interface_foreground_color) ||
        defaultThemeColor.interface_foreground_color,
      button_primary:
        (comp_data && companyFormData?.button_primary) ||
        defaultThemeColor.button_primary,
      button_primary_text:
        (comp_data && companyFormData?.button_primary_text) ||
        defaultThemeColor.button_primary_text,
      button_secondary:
        (comp_data && companyFormData?.button_secondary) ||
        defaultThemeColor.button_secondary,
      button_secondary_text:
        (comp_data && companyFormData?._text) || defaultThemeColor._text,
    };
    reset({ ...defaultValues });
  }, [companyFormData]);

  // Handle primary color change
  const handleSidebarTextColorChange = (color: ColorResult) => {
    setSelectedSidebarTextColor(color);
  };

  // Handle secondary color change
  const handleSidebarTextActiveColorChange = (color: ColorResult) => {
    setSelectedSidebarTextActive(color);
  };

  // Handle text color change
  const handleSidebarTextActiveBgColorChange = (color: ColorResult) => {
    setSelectedSidebarTextActiveBg(color);
  };

  const handleSidebarBgColorChange = (color: ColorResult) => {
    setSelectedSidebarBg(color);
  };

  const handleBgColorChange = (color: ColorResult) => {
    setSelectedBackgroundColor(color);
  };

  const handleBgTextColorChange = (color: ColorResult) => {
    setSelectedBackgroundTextColor(color);
  };

  const handleForegroundTextColorChange = (color: ColorResult) => {
    setSelectedForegroundTextColor(color);
  };

  const handleForegroundColorChange = (color: ColorResult) => {
    setSelectedForegroundColor(color);
  };

  const handleButtonPrimaryChange = (color: ColorResult) => {
    setSelectedButtonPrimary(color);
  };

  const handlePrimaryButtonTextChange = (color: ColorResult) => {
    setSelectedPrimaryButtonText(color);
  };

  const handleSecondaryButton = (color: ColorResult) => {
    setSelectedSecodaryButton(color);
  };

  const handleSecondaryButtonText = (color: ColorResult) => {
    setSelectedSecodaryButtonText(color);
  };

  const onSubmit = (payload: any) => {
    payload.id = companyFormData && companyFormData?.id;
    dispatch(updateCompanyForm(payload));
  };

  const resetColorsToDefault = (payload: any, resetColors: boolean = true) => {
    dispatch(updateCompanyForm(payload, resetColors));
    setSelectedSidebarTextColor(defaultThemeColor.sidebar_text_color);
    setSelectedSidebarTextActive(defaultThemeColor.sidebar_text_active);
    setSelectedSidebarTextActiveBg(
      defaultThemeColor.sidebar_text_active_background
    );
    setSelectedSidebarBg(defaultThemeColor.sidebar_background_color);
    setSelectedBackgroundColor(defaultThemeColor.interface_background_color);
    setSelectedBackgroundTextColor(
      defaultThemeColor.interface_background_text_color
    );
    setSelectedForegroundTextColor(
      defaultThemeColor.interface_foreground_text_color
    );
    setSelectedForegroundColor(defaultThemeColor.interface_foreground_color);
    setSelectedButtonPrimary(defaultThemeColor.button_primary);
    setSelectedPrimaryButtonText(defaultThemeColor.button_primary_text);
    setSelectedSecodaryButton(defaultThemeColor.button_secondary);
    setSelectedSecodaryButtonText(defaultThemeColor.button_secondary_text);
  };

  const iconColor =
    useColor("button_primary_text") || defaultThemeColor.button_primary;

  return (
    <div className={`overflow-auto`}>
      <div style={{ paddingBottom: "1.4em" }}>
        <Header
          page={t("Company Settings")}
          mainHead={t("Company Settings")}
          backLink="/company-setting/company-customization"
        />
      </div>
      <form
        className={"pb-4"}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        id="form_submit"
        style={{
          background:
            useColor("interface_foreground_color") ||
            defaultThemeColor.interface_foreground_color,
          borderRadius: 10,
          height: "auto",
        }}
      >
        <div className={`${Style.background}`}>
          <div className={Style.flexHeading}>
            <div
              className={Style.head}
              style={{
                color:
                  useColor("interface_foreground_text_color") ||
                  defaultThemeColor.interface_foreground_text_color,
              }}
            >
              {t("Edit Colour Theme")}
            </div>
            <div>
              <button
                className={Style.resetBtn}
                type="button"
                style={{
                  background:
                    useColor("button_primary") ||
                    defaultThemeColor.button_primary,
                  color:
                    useColor("button_primary_text") ||
                    defaultThemeColor.button_primary_text,
                }}
                onClick={() => resetColorsToDefault({}, true)}
              >
                {t("Reset")}
              </button>
            </div>
          </div>
          <div className={Style.sideBarButtonWrapper}>
            <div className={Style.sideBarColor}>
              <div
                className={`${Style.heading} mb-3`}
                style={{
                  color:
                    useColor("interface_foreground_text_color") ||
                    defaultThemeColor.interface_foreground_text_color,
                }}
              >
                {t("Sidebar")}
              </div>
              <div className={Style.colorPicker} style={{ marginLeft: "4rem" }}>
                <Controller
                  name="sidebar_text_color"
                  control={control}
                  defaultValue={""}
                  render={({ field: { value, onChange } }) => (
                    <FormControl>
                      <ColorPicker
                        name={t("Text Colour")}
                        icon={<EditSVG color={iconColor} />}
                        onChange={(color) => {
                          onChange(color);
                          handleSidebarTextColorChange(color);
                        }}
                        color={value as string}
                      />
                      <div
                        className={Style.selectedSidebarTextColor}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          backgroundColor: isLoading
                            ? ""
                            : selectedSidebarTextColor ||
                              value ||
                              primary_color,
                          display: "inline-block",
                          marginRight: "5px",
                          position: "absolute",
                          boxShadow: "1px 1px 6px 0px grey",
                          left: "-4rem",
                        }}
                      ></div>
                    </FormControl>
                  )}
                />
              </div>
              <div
                className={Style.colorPicker}
                style={{ marginTop: "1rem", marginLeft: "4rem" }}
              >
                <Controller
                  name="sidebar_text_active"
                  control={control}
                  defaultValue={""}
                  render={({ field: { value, onChange } }) => (
                    <FormControl>
                      <ColorPicker
                        name={t("Text Active")}
                        icon={<EditSVG color={iconColor} />}
                        onChange={(color) => {
                          onChange(color);
                          handleSidebarTextActiveColorChange(color);
                        }}
                        color={value as string}
                      />
                      <div
                        className={Style.selectedSidebarTextActive}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          backgroundColor: isLoading
                            ? ""
                            : selectedSidebarTextActive ||
                              value ||
                              secondary_color,

                          display: "inline-block",
                          marginRight: "5px",
                          position: "absolute",
                          boxShadow: "1px 1px 6px 0px grey",
                          left: "-4rem",
                        }}
                      ></div>
                    </FormControl>
                  )}
                />
              </div>
              <div
                className={Style.colorPicker}
                style={{ marginTop: "1rem", marginLeft: "4rem" }}
              >
                <Controller
                  name="sidebar_text_active_background"
                  control={control}
                  defaultValue={""}
                  render={({ field: { value, onChange } }) => (
                    <FormControl>
                      <ColorPicker
                        name={t("Text Active Background")}
                        icon={<EditSVG color={iconColor} />}
                        onChange={(color) => {
                          onChange(color);
                          handleSidebarTextActiveBgColorChange(color);
                        }}
                        color={value as string}
                      />
                      <div
                        className={Style.selectedSidebarTextActiveBg}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          backgroundColor: isLoading
                            ? ""
                            : selectedSidebarTextActiveBg ||
                              value ||
                              light_text_color,
                          display: "inline-block",
                          marginRight: "5px",
                          position: "absolute",
                          boxShadow: "1px 1px 6px 0px grey",
                          left: "-4rem",
                        }}
                      ></div>
                    </FormControl>
                  )}
                />
              </div>
              <div
                className={Style.colorPicker}
                style={{ marginTop: "1rem", marginLeft: "4rem" }}
              >
                <Controller
                  name="sidebar_background_color"
                  control={control}
                  defaultValue={""}
                  render={({ field: { value, onChange } }) => (
                    <FormControl>
                      <ColorPicker
                        name={t("Background Colour")}
                        icon={<EditSVG color={iconColor} />}
                        onChange={(color) => {
                          onChange(color);
                          handleSidebarBgColorChange(color);
                        }}
                        color={value as string}
                      />
                      <div
                        className={Style.selectedSidebarBg}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          backgroundColor: isLoading
                            ? ""
                            : selectedSidebarBg || value || light_text_color,
                          display: "inline-block",
                          marginRight: "5px",
                          position: "absolute",
                          boxShadow: "1px 1px 6px 0px grey",
                          left: "-4rem",
                        }}
                      ></div>
                    </FormControl>
                  )}
                />
              </div>
            </div>
            <div className={Style.buttonColor}>
              <div
                className={`${Style.heading} mb-3`}
                style={{
                  color:
                    useColor("interface_foreground_text_color") ||
                    defaultThemeColor.interface_foreground_text_color,
                }}
              >
                {t("Interface")}
              </div>
              <div className={Style.colorPicker} style={{ marginLeft: "4rem" }}>
                <Controller
                  name="interface_background_color"
                  control={control}
                  defaultValue={""}
                  render={({ field: { value, onChange } }) => (
                    <FormControl>
                      <ColorPicker
                        name={t("Background Colour")}
                        icon={<EditSVG color={iconColor} />}
                        onChange={(color) => {
                          onChange(color);
                          handleBgColorChange(color);
                        }}
                        color={value as string}
                      />
                      <div
                        className={Style.selectedBackgroundColor}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          backgroundColor: isLoading
                            ? ""
                            : selectedBackgroundColor || value || primary_color,
                          display: "inline-block",
                          marginRight: "5px",
                          position: "absolute",
                          boxShadow: "1px 1px 6px 0px grey",
                          left: "-4rem",
                        }}
                      ></div>
                    </FormControl>
                  )}
                />
              </div>
              <div
                className={Style.colorPicker}
                style={{ marginTop: "1rem", marginLeft: "4rem" }}
              >
                <Controller
                  name="interface_background_text_color"
                  control={control}
                  defaultValue={""}
                  render={({ field: { value, onChange } }) => (
                    <FormControl>
                      <ColorPicker
                        name={t("Text Colour")}
                        icon={<EditSVG color={iconColor} />}
                        onChange={(color) => {
                          onChange(color);
                          handleBgTextColorChange(color);
                        }}
                        color={value as string}
                      />
                      <div
                        className={Style.selectedBackgroundTextColor}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          backgroundColor: isLoading
                            ? ""
                            : selectedBackgroundTextColor ||
                              value ||
                              secondary_color, // Replace selectedSecondaryColor with the actual state or value

                          display: "inline-block",
                          marginRight: "5px",
                          position: "absolute",
                          boxShadow: "1px 1px 6px 0px grey",
                          left: "-4rem",
                        }}
                      ></div>
                    </FormControl>
                  )}
                />
              </div>
              <div
                className={Style.colorPicker}
                style={{ marginTop: "1rem", marginLeft: "4rem" }}
              >
                <Controller
                  name="interface_foreground_text_color"
                  control={control}
                  defaultValue={""}
                  render={({ field: { value, onChange } }) => (
                    <FormControl>
                      <ColorPicker
                        name={t("ForeGround Text Colour")}
                        icon={<EditSVG color={iconColor} />}
                        onChange={(color) => {
                          onChange(color);
                          handleForegroundTextColorChange(color);
                        }}
                        color={value as string}
                      />
                      <div
                        className={Style.selectedForegroundTextColor}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          backgroundColor: isLoading
                            ? ""
                            : selectedForegroundTextColor ||
                              value ||
                              light_text_color, // Replace selectedTextColor with the actual state or value

                          display: "inline-block",
                          marginRight: "5px",
                          position: "absolute",
                          boxShadow: "1px 1px 6px 0px grey",
                          left: "-4rem",
                        }}
                      ></div>
                    </FormControl>
                  )}
                />
              </div>
              <div
                className={Style.colorPicker}
                style={{ marginTop: "1rem", marginLeft: "4rem" }}
              >
                <Controller
                  name="interface_foreground_color"
                  control={control}
                  defaultValue={""}
                  render={({ field: { value, onChange } }) => (
                    <FormControl>
                      <ColorPicker
                        name={t("ForeGround Colour")}
                        icon={<EditSVG color={iconColor} />}
                        onChange={(color) => {
                          onChange(color);
                          handleForegroundColorChange(color);
                        }}
                        color={value as string}
                      />
                      <div
                        className={Style.selectedForegroundColor}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          backgroundColor: isLoading
                            ? ""
                            : selectedForegroundColor ||
                              value ||
                              light_text_color, // Replace selectedTextColor with the actual state or value

                          display: "inline-block",
                          marginRight: "5px",
                          position: "absolute",
                          boxShadow: "1px 1px 6px 0px grey",
                          left: "-4rem",
                        }}
                      ></div>
                    </FormControl>
                  )}
                />
              </div>
            </div>
          </div>
          <div className={Style.interfaceWrapper}>
            <div className={Style.interfaceColor}>
              <div className={Style.sideBarColor}>
                <div
                  className={`${Style.heading} mt-5 mb-3`}
                  style={{
                    color:
                      useColor("interface_foreground_text_color") ||
                      defaultThemeColor.interface_foreground_text_color,
                  }}
                >
                  {t("Button")}
                </div>
                <div
                  className={Style.colorPicker}
                  style={{ marginLeft: "4rem" }}
                >
                  <Controller
                    name="button_primary"
                    control={control}
                    defaultValue={""}
                    render={({ field: { value, onChange } }) => (
                      <FormControl>
                        <ColorPicker
                          name={t("Primary Button")}
                          icon={<EditSVG color={iconColor} />}
                          onChange={(color) => {
                            onChange(color);
                            handleButtonPrimaryChange(color);
                          }}
                          color={value as string}
                        />
                        <div
                          className={Style.selectedButtonPrimary}
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            backgroundColor: isLoading
                              ? ""
                              : selectedButtonPrimary || value || primary_color,
                            display: "inline-block",
                            marginRight: "5px",
                            position: "absolute",
                            boxShadow: "1px 1px 6px 0px grey",
                            left: "-4rem",
                          }}
                        ></div>
                      </FormControl>
                    )}
                  />
                </div>
                <div
                  className={Style.colorPicker}
                  style={{ marginTop: "1rem", marginLeft: "4rem" }}
                >
                  <Controller
                    name="button_primary_text"
                    control={control}
                    defaultValue={""}
                    render={({ field: { value, onChange } }) => (
                      <FormControl>
                        <ColorPicker
                          name={t("Primary Button Text")}
                          icon={<EditSVG color={iconColor} />}
                          onChange={(color) => {
                            onChange(color);
                            handlePrimaryButtonTextChange(color);
                          }}
                          color={value as string}
                        />
                        <div
                          className={Style.selectedPrimaryButtonText}
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            backgroundColor: isLoading
                              ? ""
                              : selectedPrimaryButtonText ||
                                value ||
                                secondary_color, // Replace selectedSecondaryColor with the actual state or value

                            display: "inline-block",
                            marginRight: "5px",
                            position: "absolute",
                            boxShadow: "1px 1px 6px 0px grey",
                            left: "-4rem",
                          }}
                        ></div>
                      </FormControl>
                    )}
                  />
                </div>
                <div
                  className={Style.colorPicker}
                  style={{ marginTop: "1rem", marginLeft: "4rem" }}
                >
                  <Controller
                    name="button_secondary"
                    control={control}
                    defaultValue={""}
                    render={({ field: { value, onChange } }) => (
                      <FormControl>
                        <ColorPicker
                          name={t("Seconday Button")}
                          icon={<EditSVG color={iconColor} />}
                          onChange={(color) => {
                            onChange(color);
                            handleSecondaryButton(color);
                          }}
                          color={value as string}
                        />
                        <div
                          className={Style.selectedSecondaryButton}
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            backgroundColor: isLoading
                              ? ""
                              : selectedSecondaryButton ||
                                value ||
                                light_text_color, // Replace selectedTextColor with the actual state or value

                            display: "inline-block",
                            marginRight: "5px",
                            position: "absolute",
                            boxShadow: "1px 1px 6px 0px grey",
                            left: "-4rem",
                          }}
                        ></div>
                      </FormControl>
                    )}
                  />
                </div>
                <div
                  className={Style.colorPicker}
                  style={{ marginTop: "1rem", marginLeft: "4rem" }}
                >
                  <Controller
                    name="button_secondary_text"
                    control={control}
                    defaultValue={""}
                    render={({ field: { value, onChange } }) => (
                      <FormControl>
                        <ColorPicker
                          name={t("Secodary Button Text")}
                          icon={<EditSVG color={iconColor} />}
                          onChange={(color) => {
                            onChange(color);
                            handleSecondaryButtonText(color);
                          }}
                          color={value as string}
                        />
                        <div
                          className={Style.selectedSecondaryButtonText}
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            backgroundColor: isLoading
                              ? ""
                              : selectedSecondaryButtonText ||
                                value ||
                                light_text_color,
                            display: "inline-block",
                            marginRight: "5px",
                            position: "absolute",
                            boxShadow: "1px 1px 6px 0px grey",
                            left: "-4rem",
                          }}
                        ></div>
                      </FormControl>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex w-100 gap-4 py-2 px-5"
          style={{ justifyContent: lang === "ar" ? "flex-start" : "flex-end" }}
        >
          <SubmitButton marginTop={false} label={t("Update")} />
          <CancelButton
            size="small"
            outlined={true}
            onClick={() => {
              navigate("/company-setting/company-customization");
            }}
            textColor=""
            outlineColor=""
            text={t("Cancel")}
            className={``}
            bg={""}
          ></CancelButton>
        </div>
      </form>
    </div>
  );
}

export default EditColorTheme;
