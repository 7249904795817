import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
export interface DataList {
  count: number;
  next: string | null;
  previous: string | null;
  results: any[];
}
export interface Detail {
  id: number;
  name: string;
  training: any;
  description: string;
  logo: string;
  background_image: string;
  certificate_data: any;
}
export interface States {
  data: DataList;
  wholedata: DataList;
  userListData?: DataList;
  success?: string;
  error?: string;
  errors?: any;
  detailData?: Detail;
  membersData?: DataList;
  page?: number;
  perPage?: number;
  search?: string;
}
export const initialState: States = {
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  wholedata: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  membersData: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  userListData: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  page: 1,
  perPage: 10,
  search: "",
};

export const certificateManagementSlice = createSlice({
  name: "certificateManagement",
  initialState,
  reducers: {
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setWholeData: (state, { payload }) => {
      state.wholedata = payload;
    },
    setDetailData: (state, { payload }) => {
      state.detailData = payload;
    },
    setMembersData: (state, { payload }) => {
      state.membersData = payload;
    },
    setUserListData: (state, { payload }) => {
      state.userListData = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setPerPage: (state, { payload }) => {
      state.perPage = payload;
    },
    setSearch: (state, { payload }) => {
      state.search = payload;
    },
  },
});

export const {
  setData,
  setWholeData,
  setUserListData,
  setDetailData,
  setPage,
  setMembersData,
  setPerPage,
  setSearch,
} = certificateManagementSlice.actions;

export const certificateManagementSelector = (state: RootState) => state;

export default certificateManagementSlice.reducer;
