/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import useColor from "../../../hooks/useColor";
import { defaultThemeColor } from "../../../utils/colors_values";
import { permissionGroupLabel } from "../../../utils/staticEntities";
import Style from "./AddNewPermission.module.scss";
export interface SimpleProps {
  permissionList: any[];
}
const UserPermission = (props: SimpleProps) => {
  const { permissionList } = props;
  const t = useTranslation();
  const [permissionsGroup, setPermissionsGroup] = useState<any>([]);
  useEffect(() => {
    const permissionsTitle = permissionList?.map((row: any, ind: any) => {
      return row?.codename?.split("_").pop();
    });
    const groupArray = permissionsTitle?.filter(
      (value, index, self) => self.indexOf(value) === index
    );
    let newArray: any = [];
    groupArray?.map((gr: any, index: any) => {
      newArray[index] = {
        title: gr,
        children: [],
      };
      permissionList?.map((row: any, ind: any) => {
        if (row?.codename?.toLowerCase().endsWith("_" + gr)) {
          newArray[index]["children"][ind] = row;
        }
      });
    });

    setPermissionsGroup(newArray);
  }, [permissionList]);

  const foregroundTextColor =
    useColor("interface_foreground_text_color") ||
    defaultThemeColor.interface_foreground_text_color;

  return (
    <div
      style={{
        background:
          useColor("interface_foreground_color") ||
          defaultThemeColor.interface_foreground_color,
        color:
          useColor("interface_foreground_text_color") ||
          defaultThemeColor.interface_foreground_text_color,
        borderRadius: 10,
        height: "auto",
      }}
      className={Style.bottomSpace}
    >
      <div className={`${Style.alignItemsFeatures}`}>
        <div className="row justify-content-between">
          {permissionsGroup?.map((row: any, index: number) => {
            return (
              <div className={`col-lg-6 col-md-12`} key={row.title}>
                <label
                  className={`${Style.labelManagement} my-3`}
                  key={`keys-${row.title}`}
                  style={{
                    color: foregroundTextColor,
                  }}
                >
                  {t(permissionGroupLabel[row?.title])}
                </label>
                <div
                  className="checkBoxWrapper p-3 pb-0"
                  key={`keys-keys-${row.title}`}
                  style={{
                    color: foregroundTextColor,
                  }}
                >
                  {row?.children?.map((ch: any) => {
                    return (
                      <div
                        className={`checkBoxContainer ${Style.permissionsText}`}
                        style={{
                          color: foregroundTextColor,
                          paddingLeft: "5px",
                          cursor: "default",
                        }}
                        key={`keys-${ch?.id}`}
                      >
                        <span
                          style={{
                            textTransform: "capitalize",
                            color: foregroundTextColor,
                          }}
                        >
                          {ch.name
                            ?.toLowerCase()
                            .replace("custom user", "")
                            .replace("dynamic form fields", "Form")
                            .replace("can ", "")
                            .replace("change", "Edit")}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default UserPermission;
