import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogContent, Menu, MenuItem } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import { useConfirm } from "material-ui-confirm";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import { toast } from "react-toastify";
import CancelButton from "../../../components/CancelButton";
import DataTable from "../../../components/table/DataTable";
import TableFilters from "../../../components/table/TableFilters";
import useCompanyLanguage from "../../../hooks/language";
import useColor from "../../../hooks/useColor";
import usePermission from "../../../hooks/usePermission";
import axiosPrivate from "../../../lib/axiosPrivate";
import {
  bulkDeleteGroups,
  deleteGroup,
} from "../../../services/groupManagementService";
import {
  getMembersListData,
  getWholeMembersListData,
  getWholeMembersListData3,
} from "../../../services/trainingsService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../../store/reducers/alertMessageReducer";
import { groupManagementSelector } from "../../../store/reducers/groupManagementReducer";
import { setWholeDataMember } from "../../../store/reducers/trainingsReducer";
import { RootState, useAppDispatch } from "../../../store/store";
import { defaultThemeColor } from "../../../utils/colors_values";
import { confirmProps } from "../../../utils/confirmProps";
import Style from "./AssignToUser.module.scss";

export interface SimpleDialogProps {
  setParentOpen: any;
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
  setSelectedRows: any;
  selectedRows: any;
  defaultRows: any;
  id?: any;
  save?: any;
  setSave?: any;
  disableCheckboxes?: any;
}

function AssignToUserModal(props: SimpleDialogProps) {
  const {
    id,
    setParentOpen,
    open,
    onClose,
    isLoading,
    setSelectedRows,
    selectedRows,
    defaultRows,
    save,
    disableCheckboxes,
    setSave,
  } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  const addPermission = usePermission("add_customusergroup");
  const editUpdatePermission = usePermission("change_customusergroup");
  const deletePermission = usePermission("delete_customusergroup");
  const viewPermission = usePermission("view_customusergroup");
  const [itemPerPage, setItemPerPage] = useState<number>(10);

  const [searchTable, setSearchTable] = useState("");
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<any[]>([]);
  // const [page, setPage] = useState(1);
  const states = useSelector(groupManagementSelector).departmentReducer;
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;
  const [rows, setRows] = React.useState<any[]>([]);
  const [valueDepartment, onChangeDepartment] = useState<any>();
  const dataList = useSelector(
    (state: RootState) => state.trainingsReducer.dataMember
  );
  const [rolesState, setRolesState] = useState("");
  const wholeData = useSelector(
    (state: RootState) => state.trainingsReducer.wholedataMember
  );
  console.log("rolesState rolesState", rolesState);
  useEffect(() => {
    let selected =
      selectedRows?.length === 0 ? defaultRows : selectedRows?.results;
    let selectedIds = selected?.map((item: any) => item?.id);
    setSelectedCheckBox(selectedIds);
  }, [selectedRows, defaultRows, open]);

  useEffect(() => {
    setPage(1);
  }, [searchTable]);

  useEffect(() => {
    dispatch(
      getMembersListData({
        page_size: Number(itemPerPage),
        page: Number(page),
        search: searchTable,
        department__id: valueDepartment?.value || "",
        primary_role__in: rolesState || "",
      })
    );
  }, [itemPerPage, page, searchTable, valueDepartment, rolesState]);

  useEffect(() => {
    let data: any = dataList.results;
    if (Object.keys(dataList).length === 0) {
      setRows([]);
    } else {
      setRows(data);
      if (!(itemPerPage == 10 || itemPerPage == 15 || itemPerPage == 25)) {
        setItemPerPage(dataList?.count);
      }
    }
  }, [dataList]);

  useEffect(() => {
    dispatch(getWholeMembersListData3());
  }, []);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      setSelectedCheckBox([]);
      dispatch(
        getMembersListData({
          page_size: Number(itemPerPage),
          page: Number(page),
          search: searchTable,
          department__id: valueDepartment?.value || "",
          primary_role__in: rolesState || "",
        })
      );
    }
  }, [alertMessage]);

  const handleDeleteAction = (row: any) => {
    confirm({
      title: t("Are you sure!"),
      description: `Do you want to delete ${row?.name} group?`,
      ...confirmProps,
    })
      .then(() => {
        dispatch(deleteGroup(row.id));
      })
      .catch(() => {
        // toast.success("Your request canceled successfully!", {
        //   toastId: "Your request canceled successfully!",
        // });
      });
    return;
  };

  const handleAdd = () => {
    const selectedCheckBoxArray = selectedCheckBox?.map((str) => Number(str));
    const updatedDataList = {
      ...dataList,
      count: selectedCheckBoxArray.length,
      results: wholeData.results.filter((item: any) =>
        selectedCheckBoxArray.includes(Number(item.id))
      ),
    };
    setSelectedRows(updatedDataList);
    setSave(true);
    handleClose();
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    // setSelectedCheckBox([]);
    setPage(page);
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPage(1);
    // setSelectedCheckBox([]);
    setItemPerPage(Number(rowsPerPage));
  };

  // const handleClose = () => {
  //   setParentOpen(false);
  // };

  useEffect(() => {
    modifyRows();
  }, [rows]);

  const bulkDelete = (ids: any) => {
    confirm({
      title: t("Are you sure!"),
      description: t(`Do you want to delete selected groups?`),
      ...confirmProps,
    })
      .then(() => {
        dispatch(bulkDeleteGroups(ids));
        setSelectedCheckBox([]);
      })
      .catch(() => {});
  };

  const modifyRows = () => {
    let f_rows: any = [];
    rows.forEach(
      (
        r: {
          id: string;
          first_name: string;
          last_name: string;
          email: any;
          primary_role: string;
          department: any;
        },
        i: number
      ) => {
        f_rows = [
          ...f_rows,
          {
            id: r.id,
            first_name: r?.first_name + " " + r?.last_name,
            email: r?.email,
            role: r?.primary_role,
            department: r?.department?.name || "N/A",
          },
        ];
      }
    );
    setList(f_rows);
  };

  const handleClose = () => {
    if (isLoading) {
    } else {
      onClose();
      setSelectedCheckBox([]);
      setSearchTable("");
    }
  };

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;

  const handleClicked = () => {
    getWhole();
  };

  const getWhole = () => {
    if (dataList)
      dispatch(
        getWholeMembersListData({
          page_size: dataList.count || 1,
          page: 1,
          search: "",
        })
      );
  };
  const conveetNestedToFlatten = (accounts: any, showChild: boolean) => {
    var a: any = [];
    for (var i = 0; i < accounts.length; i++) {
      if (showChild) {
        if (accounts[i].children) {
          a = a.concat(conveetNestedToFlatten(accounts[i].children, showChild));
        }
      }
      a.push(accounts[i]);
    }
    return a;
  };
  const loadOptions = async (
    searchQuery: any,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await axiosPrivate.get(
      `accounts/department/?page=${page}&page_size=100&search=${searchQuery}`
    );
    const data = response.data;
    const showChild = searchQuery ? false : true;
    const dataFlatten = conveetNestedToFlatten(data?.results, showChild);
    const options = dataFlatten?.map((row: any) => {
      return {
        label: row.name || "N/A",
        value: row.id,
      };
    });

    const users = {
      options: options || [],
      hasMore: data?.next ? true : false,
      additional: {
        page: searchQuery ? 1 : page + 1,
      },
    };

    return users;
  };

  const departmentHandle = (e: any) => {
    console.log("eee", e);
    setItemPerPage(10);
    setPage(1);
    onChangeDepartment(e);
  };

  return (
    <Dialog
      onClose={handleClose}
      maxWidth={"lg"}
      open={open && isLoading ? true : open}
      style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
    >
      <div
        className="d-flex justify-content-between"
        style={{ color: interfaceTextColor, background: interfaceBgColor }}
      >
        <DialogTitle style={{ paddingTop: "1em", fontSize: 22 }}>
          {t("Assign Training to User(s)")}
        </DialogTitle>
        <div className="p-3">
          <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
        </div>
      </div>
      <DialogContent
        style={{ color: interfaceTextColor, background: interfaceBgColor }}
      >
        <TableFilters
          dataList={dataList}
          itemPerPage={itemPerPage}
          selectedCheckBox={selectedCheckBox}
          bulkDelete={() => {}}
          setItemPerPage={handleChangeRowsPerPage}
          setSearchTable={setSearchTable}
          createButton={<></>}
          handle="groups"
          isCheckboxes={false}
          exportCSV={false}
          setParentData={() => dispatch(setWholeDataMember({}))}
          handleClicked={handleClicked}
          rows={wholeData?.results?.map((r: any, i: any) => ({
            // id: r.id,
            first_name: r?.first_name + " " + r?.last_name,
            email: r?.email,
            role: r?.primary_role,
            department: r?.department?.name || "N/A",
          }))}
          headers={["Username", "Email", "Roles", "Department"]}
          filename={`members_list.csv`}
        />
        <DataTable
          columnData={[
            {
              id: "first_name",
              name: t("Username"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },

            {
              id: "email",
              name: t("Email"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "roles",
              name: (
                <>
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <React.Fragment>
                        <FormControl sx={{ minWidth: 80 }}>
                          <Button
                            {...bindTrigger(popupState)}
                            variant="text"
                            color="inherit"
                            endIcon={
                              rolesState === "asc" ? (
                                <ArrowDropUpIcon />
                              ) : (
                                <ArrowDropDownSharpIcon />
                              )
                            }
                            style={{
                              textTransform: "capitalize",
                              marginRight: lang === "ar" ? "10px" : "0px",
                            }}
                            className={Style.HeaderBtn}
                          >
                            {rolesState ? rolesState : t("Roles")}
                          </Button>
                          <Menu
                            {...bindMenu(popupState)}
                            style={{
                              minWidth: 80,
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                setRolesState("");
                                setPage(1);
                                popupState.close();
                              }}
                            >
                              {t("All")}
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setRolesState("Admin");
                                setPage(1);
                                popupState.close();
                              }}
                            >
                              {t("Admin")}
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setRolesState("Trainer");
                                setPage(1);
                                popupState.close();
                              }}
                            >
                              {t("Trainer")}
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setRolesState("Trainee");
                                setPage(1);
                                popupState.close();
                              }}
                            >
                              {t("Trainee")}
                            </MenuItem>
                          </Menu>
                        </FormControl>
                      </React.Fragment>
                    )}
                  </PopupState>
                </>
              ) as any,
              enableSort: false,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },

            {
              id: "department",
              name: (
                <>
                  <FormControl sx={{ minWidth: "161px" }}>
                    <AsyncPaginate
                      loadOptions={loadOptions}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      onChange={(e: any) => {
                        departmentHandle(e);
                      }}
                      value={valueDepartment}
                      getOptionValue={(option: any) => option.value}
                      getOptionLabel={(option: any) => option.label}
                      className="department-select"
                      additional={{
                        page: 1,
                      }}
                      isClearable
                      isSearchable={true}
                      placeholder={t("Department")}
                      debounceTimeout={500}
                    />
                  </FormControl>
                </>
              ) as any,
              enableSort: false,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "checkbox",
            },
          ]}
          rows={list}
          isCheckboxes={true}
          disabledCheckboxes={disableCheckboxes.map((i: any) => i.id)}
          selectedRowsPerPage={itemPerPage.toString()}
          totalRecordCount={Number(dataList.count)}
          handleChangePageParent={handleChangePage}
          pagenum={Number(page)}
          handleChangeRowsPerPageParent={handleChangeRowsPerPage}
          bulkDelete={bulkDelete}
          selectedCheckBox={selectedCheckBox}
          setSelectedCheckBox={setSelectedCheckBox}
          rowsScroll={true}
          minHeight={"20em"}
          displayForceBottomComponent={true}
          bottomButtonsComponent={
            <div className="d-flex flex-wrap gap-3 w-100 px-3 mb-2 align-items-center justify-content-end ">
              <button
                type="button"
                className={`btn mt-3 ${Style.addBtn}`}
                disabled={selectedCheckBox.length == 0}
                style={{
                  color:
                    useColor("button_primary_text") ||
                    defaultThemeColor.button_secondary_text,
                  background:
                    useColor("button_primary") ||
                    defaultThemeColor.button_secondary,
                  border: "none",
                  outline: "none",
                }}
                onClick={handleAdd}
              >
                {t("Save")}
              </button>
              <CancelButton
                size="small"
                outlined={true}
                onClick={() => {
                  setSelectedCheckBox([]);
                  handleClose();
                }}
                textColor=""
                outlineColor=""
                text={t("Cancel")}
                className={`mt-3`}
                bg={""}
              ></CancelButton>
            </div>
          }
        />
      </DialogContent>
    </Dialog>
  );
}

export default AssignToUserModal;
