import { memo, useEffect, useState } from "react";

import arrayShuffle from "array-shuffle";
import moment from "moment";
import Countdown from "react-countdown";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../../components/Header";
import useColor from "../../../hooks/useColor";
import {
  getPolicyAssessment,
  updatePolicyAssessmentScore,
} from "../../../services/policyAssessmentService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../../store/reducers/alertMessageReducer";
import { RootState, useAppDispatch } from "../../../store/store";
import { defaultThemeColor } from "../../../utils/colors_values";
import { assessmentTimeOptionsLabel } from "../../../utils/staticEntities";
import Style from "./PolicyAssessmentStarted.module.scss";

function PolicyAssessmentStarted() {
  const { policy_assessment_id }: any = useParams();
  const navigate = useNavigate();
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  // const [module, setModule] = useState<any>();
  const [allQuestions, setAllQuestions] = useState<any>([]);
  const [topping, setTopping] = useState("");
  const [date, setDate] = useState<any>();
  const dispatch = useAppDispatch();
  const assessmentData: any = useSelector(
    (state: RootState) => state.policyAssessmentReducer.assessmentData
  );
  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  useEffect(() => {
    dispatch(getPolicyAssessment({ id: Number(policy_assessment_id) }));
  }, [policy_assessment_id]);

  const CountdownWrapper = ({
    time_limit,
    currentIndex,
    handleTimerComplete,
  }: {
    time_limit: any;
    currentIndex: any;
    handleTimerComplete: any;
  }) => (
    <Countdown
      date={date + Number(moment.duration(time_limit).asMilliseconds())}
      key={currentIndex}
      onComplete={handleTimerComplete}
    />
  );
  const MemoCountdown = memo(CountdownWrapper);

  useEffect(() => {
    if (assessmentData?.results?.results?.score) {
      toast.error(String("You are not allow to retake this assessment"), {
        toastId: String("You are not allow to retake this assessment"),
      });
      navigate("/notifications");
      return;
    }
    let quues = assessmentData?.questions?.map((item: any) =>
      item?.randomise_answer_position
        ? {
            ...item,
            user_answer: "",
            options: arrayShuffle(item?.options),
          }
        : {
            ...item,
            user_answer: "",
          }
    );

    if (assessmentData?.shuffle_order) {
      setAllQuestions(arrayShuffle(quues));
      setDate(Date.now());
    } else {
      setDate(Date.now());
      setAllQuestions(quues);
    }
  }, [assessmentData]);

  const onOptionChange = (e: any) => {
    setTopping(e.target.value);

    allQuestions[currentQuestionIndex].user_answer = e.target.value;
  };
  const handleNextQuestion = () => {
    setTopping("");
    if (assessmentData?.time_option === "Each_Question_Limit") {
      setDate(Date.now());
    }
    setCurrentQuestionIndex(Number(currentQuestionIndex) + 1);
  };

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      // navigate(`/progress-report/${training_id}/${module_id}/${assessment_id}`);
      // navigate(`/progress-report/${training_id}/${module_id}/${assessment_id}`);
      navigate(`/policy-assessment-progress-report/${policy_assessment_id}`);
    }
  }, [alertMessage]);
  const handleSubmiit = () => {
    const filtered = allQuestions?.filter((row: any) => {
      return row.correct_option === row.user_answer;
    });

    let payload = {
      score: filtered?.length + "/" + allQuestions?.length,
      policy_assessment_id: Number(policy_assessment_id),
    };

    dispatch(updatePolicyAssessmentScore(payload));
    // setCurrentQuestionIndex(Number(currentQuestionIndex) + 1);
  };

  const options = [
    "Global Variable",
    "Local Variable",
    "Both of above",
    "None of above",
  ];
  const options2 = [
    "Named Function",
    "Anonymous Function",
    "Both of above",
    "None of above",
  ];

  const handleTimerComplete = () => {
    setDate(Date.now());
    if (assessmentData?.time_option === "Each_Question_Limit") {
      if (currentQuestionIndex + 1 < allQuestions?.length) {
        setDate(Date.now());
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        setDate(Date.now());
        handleSubmiit();
      }
      return;
    }
    if (assessmentData?.time_option === "Full_Quiz_Limit") {
      setDate(Date.now());
      handleSubmiit();
      return;
    }
  };

  const foregroundTextColor =
    useColor("interface_foreground_text_color") ||
    defaultThemeColor.interface_foreground_text_color;

  const buttonPrimaryColor =
    useColor("button_primary") || defaultThemeColor.button_primary;
  const buttonPrimaryTextColor =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;

  return (
    <div
      className={`${Style.background}`}
      style={{
        background:
          useColor("interface_background_color") ||
          defaultThemeColor.interface_background_color,
        color:
          useColor("interface_foreground_text_color") ||
          defaultThemeColor.interface_foreground_text_color,
      }}
    >
      <div style={{ paddingBottom: "2.4em" }}>
        <Header
          page={assessmentData?.name}
          mainHead="Assessment"
          backLink={`/policy-assessment-start/${policy_assessment_id}`}
          confirm={
            "Do you want to leave this assessment?  Your results will not be saved."
          }
        />
      </div>
      <div>
        <div
          style={{
            background:
              useColor("interface_foreground_color") ||
              defaultThemeColor.interface_foreground_color,

            borderRadius: 10,
            // height: "100vh",
          }}
          className={Style.bottomSpace}
        >
          <div className="d-flex justify-content-between align-items-center px-3 py-4">
            <div
              className={Style.head}
              style={{
                color: foregroundTextColor,
              }}
            >
              Questions {currentQuestionIndex + 1}/{allQuestions?.length}
            </div>
            <div
              className={Style.timer}
              style={{
                color: foregroundTextColor,
              }}
            >
              {assessmentData?.time_option !== "No_Limit" &&
                moment.duration(assessmentData?.time_limit).asMilliseconds() >
                  0 && (
                  <>
                    Timer:{" "}
                    <MemoCountdown
                      time_limit={assessmentData?.time_limit}
                      currentIndex={
                        assessmentData?.time_option === "Each_Question_Limit"
                          ? currentQuestionIndex
                          : "timer"
                      }
                      handleTimerComplete={handleTimerComplete}
                    ></MemoCountdown>
                    {/* {memo(() => (
                      <Countdown
                        date={
                          Date.now() +
                          Number(
                            moment
                              .duration(module?.module?.assessment?.time_limit)
                              .asMilliseconds()
                          )
                        }
                        key={currentQuestionIndex}
                        onComplete={handleTimerComplete}
                      />
                    ))}{" "} */}{" "}
                    ({assessmentTimeOptionsLabel[assessmentData?.time_option]})
                  </>
                )}
            </div>
          </div>
        </div>
        <div
          style={{
            background:
              useColor("interface_foreground_color") ||
              defaultThemeColor.interface_foreground_color,
            color: foregroundTextColor,
            borderRadius: 10,
            marginTop: "1.5rem",
          }}
          className={Style.bottomSpace}
        >
          <div
            className={`${Style.question}  px-3 py-4`}
            style={{
              color: foregroundTextColor,
            }}
          >
            Q . {allQuestions?.[currentQuestionIndex]?.question_text}
          </div>
          <div className="d-flex justify-content-between px-3 pb-5">
            <div className={Style.mcqOptions}>
              {allQuestions?.[currentQuestionIndex]?.options?.map(
                (option: any, index: any) => (
                  <div key={index} className="option">
                    <input
                      type="radio"
                      name={`${allQuestions?.[
                        currentQuestionIndex
                      ]?.question_text.replaceAll(" ", "")}`}
                      id={`option1-${allQuestions?.[currentQuestionIndex]?.id}-${index}`}
                      value={option}
                      checked={topping === option}
                      onChange={onOptionChange}
                      style={{ cursor: "pointer" }}
                    />
                    <label
                      className={Style.optionLabel}
                      htmlFor={`option1-${allQuestions?.[currentQuestionIndex]?.id}-${index}`}
                    >
                      {option}
                    </label>
                  </div>
                )
              )}
            </div>
            <div className={Style.imageSection}>
              {allQuestions?.[currentQuestionIndex]?.image?.file && (
                <img
                  height="150px"
                  width="250px"
                  src={allQuestions?.[currentQuestionIndex]?.image?.file}
                  alt="image loading..."
                />
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end w-100 gap-4 pt-5 p-4">
          {/* <Link to="/progress-report">Submit</Link> */}

          {currentQuestionIndex === allQuestions?.length - 1 ? (
            // <SubmitButton label="Submit" marginTop={false} />
            <button
              className="btn"
              style={{
                background: buttonPrimaryColor,
                color: buttonPrimaryTextColor,
              }}
              onClick={handleSubmiit}
              disabled={loading}
            >
              Submit
            </button>
          ) : (
            <button
              className="btn"
              onClick={handleNextQuestion}
              style={{
                background: buttonPrimaryColor,
                color: buttonPrimaryTextColor,
              }}
            >
              Next
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default PolicyAssessmentStarted;
