import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../../components/Header";
import DataTable from "../../../components/table/DataTable";
import TableFilters from "../../../components/table/TableFilters";
import useCompanyLanguage from "../../../hooks/language";
import EditSVG from "../../../icons/EditSVG";
import ViewSVGAction from "../../../icons/ViewSVGAction";
import { getEmailNotifications } from "../../../services/emailNotificationService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../../store/reducers/alertMessageReducer";
import { RootState, useAppDispatch } from "../../../store/store";
// import Style from "../../questionsLibrary/QuestionsList.module.scss";
import Style from "./AddEmailNotification.module.scss";

function EmailNotificationListing() {
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const [itemPerPage, setItemPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [searchTable, setSearchTable] = useState("");
  const [list, setList] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<any[]>([]);
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;
  const [rows, setRows] = useState([]);
  const [fetchID, setFetchID] = useState<any>(0);

  const dataList =
    useSelector((state: RootState) => state.emailNotificationReducer.data) ||
    {};

  useEffect(() => {
    setPage(1);
  }, []);

  useEffect(() => {
    setPage(1);
  }, [itemPerPage]);

  useEffect(() => {
    setPage(1);
    setItemPerPage(10);
  }, [searchTable]);

  useEffect(() => {
    dispatch(
      getEmailNotifications({
        page_size: Number(itemPerPage),
        page: Number(page),
        search: searchTable,
      })
    );
  }, [dispatch, itemPerPage, page, searchTable]);

  useEffect(() => {
    let data: any = dataList?.results;
    if (Object.keys(dataList).length === 0) {
      setRows([]);
    } else setRows(data);
  }, [dataList]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      setSelectedCheckBox([]);
      dispatch(
        getEmailNotifications({
          page_size: Number(itemPerPage),
          page: Number(page),
          search: searchTable,
        })
      );
    }
  }, [alertMessage]);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setSelectedCheckBox([]);
    setPage(page);
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPage(1);
    setSelectedCheckBox([]);
    setItemPerPage(Number(rowsPerPage));
  };

  useEffect(() => {
    modifyRows();
  }, [rows, fetchID]);

  const modifyRows = () => {
    let f_rows: any = [];
    rows?.forEach((r: any, i: number) => {
      f_rows = [
        ...f_rows,
        {
          id: r.id,
          name: (
            <div style={{ textAlign: lang === "ar" ? "center" : "unset" }}>
              {r?.template_name}
            </div>
          ),
          subject: (
            <div style={{ textAlign: lang === "ar" ? "center" : "unset" }}>
              {r?.subject}
            </div>
          ),
          send_email: (
            <span
              className={r.send_email ? Style.statusSuccess : Style.statusDraft}
            >
              {r.send_email ? "Enabled" : "Disabled"}
            </span>
          ),
          actions: (
            <>
              <div style={{ textAlign: "center" }}>
                <Link
                  className=""
                  to={`/email-template-management/view/true/${r.id.toString()}`}
                >
                  <button className="btn-link" type="button">
                    <ViewSVGAction />
                  </button>
                </Link>
                <Link
                  className=""
                  to={`/email-template-management/edit/${r.id.toString()}`}
                >
                  <button className="btn-link" type="button">
                    <EditSVG />
                  </button>
                </Link>
              </div>
            </>
          ),
        },
      ];
    });
    setList(f_rows);
  };

  return (
    <>
      <Header
        page={t("Email Templates")}
        mainHead={t("Email Templates Management")}
      />

      <TableFilters
        dataList={dataList}
        itemPerPage={itemPerPage}
        setItemPerPage={setItemPerPage}
        selectedCheckBox={selectedCheckBox}
        setSearchTable={setSearchTable}
        handle="groups"
        isCheckboxes={false}
        createButton={<div />}
      />

      <DataTable
        columnData={[
          {
            id: "Name",
            name: t("Event"),
            enableSort: true,
            align: lang === "ar" ? "center" : "left",
            disablePadding: "none",
          },
          {
            id: "subject",
            name: t(`Subject`),
            enableSort: true,
            align: lang === "ar" ? "center" : "left",
            disablePadding: "none",
          },
          {
            id: "send_email",
            name: t(`Status`),
            enableSort: true,
            align: lang === "ar" ? "center" : "center",
            disablePadding: "none",
          },
          {
            id: "actions",
            name: t("Actions"),
            enableSort: false,
            align: "center",
            disablePadding: "none",
          },
        ]}
        rows={list}
        isCheckboxes={false}
        selectedRowsPerPage={itemPerPage.toString()}
        totalRecordCount={Number(dataList?.count || 0)}
        handleChangePageParent={handleChangePage}
        pagenum={Number(page)}
        handleChangeRowsPerPageParent={handleChangeRowsPerPage}
        selectedCheckBox={selectedCheckBox}
        setSelectedCheckBox={setSelectedCheckBox}
      />
    </>
  );
}

export default EmailNotificationListing;
