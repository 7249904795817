function PlayBottomSVG(props: { color: string }) {
  return (
    <svg
      width="30.8"
      height="31"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.109 10.336L9.109 4.336C8.775 4.113 8.388 4 8 4C7.676 4 7.352 4.078 7.056 4.237C6.406 4.585 6 5.262 6 6V18C6 18.738 6.406 19.415 7.056 19.763C7.352 19.922 7.676 20 8 20C8.388 20 8.775 19.887 9.11 19.664L18.11 13.664C18.666 13.293 19 12.669 19 12C19 11.331 18.666 10.707 18.109 10.336Z"
        fill={props.color ?? "#E7E7E7"}
      />
    </svg>
  );
}

export default PlayBottomSVG;
