import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ExportCSV from "../../components/ExportCSV/ExportCSV";
import Header from "../../components/Header";
import Paginations from "../../components/table/Paginations";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import usePermission from "../../hooks/usePermission";
import SearchSVG from "../../icons/SearchSVG";
import {
  deleteDepartment,
  getDepartmentList,
  getWholeDepartmentList,
} from "../../services/departmentService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import {
  setPage,
  setPerPage,
  setSearch,
  setWholeDepartmentData,
} from "../../store/reducers/departmentReducer";
import { RootState, useAppDispatch, useAppSelector } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import { confirmProps } from "../../utils/confirmProps";
import DepartmentModal from "./DepartmentModal";
import TreeView from "./TreeView";

const SearchTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#A0AAB4",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& #custom-css-outlined-input": {
    zIndex: 1,
    padding: 7.5,
    paddingLeft: 2,
    fontSize: "12px",
    width: "14rem",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      backgroundColor: "white",
      borderColor: "white",
      borderRadius: 50,
      fontFamily: "dmSans",
      fontSize: "14px",
      fontWeight: 400,
      letterSpacing: "-0.0175rem",
      width: "14rem",
      zIndex: 0,
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6F7E8C",
    },
  },
});

function DepartmentList() {
  const searchParams = new URLSearchParams(document.location.search);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const [pageNum, setPageNum] = useState(Number(searchParams.get("page") || 1));
  const [perPageNum, setPerPageNum] = useState(
    Number(searchParams.get("perpage")) || 10
  );
  const [pageSearch, setPageSearch] = useState(
    searchParams.get("search") || ""
  );
  const deparmentData = useAppSelector((state) => state.departmentReducer.data);
  const wholedeparmentData = useAppSelector(
    (state) => state.departmentReducer.wholedata
  );
  const alertMessage = useAppSelector(alertMessageSelector).alertMessageReducer;
  const page = useSelector((state: RootState) => state.departmentReducer.page);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [defaultFormValues, setDefaultFormValues] = useState({});
  const [modalHeading, setModalHeading] = useState("");
  const [modalButtonLable, setModalButtonLable] = useState("");
  const [parentId, setParentId] = useState<any>();
  const CloseEditModal = () => {
    setOpenEditModal(false);
    setDefaultFormValues({});
    setParentId(null);
  };
  const perPage = useSelector(
    (state: RootState) => state.departmentReducer.perPage
  );
  const search = useSelector(
    (state: RootState) => state.departmentReducer.search
  );
  useEffect(() => {
    dispatch(setPage(Number(searchParams.get("page") || 1)));
    dispatch(setPerPage(Number(searchParams.get("perpage") || 10)));
    dispatch(setSearch(String(searchParams.get("search"))));

    let query: any = {};
    query.page = Number(searchParams.get("page") || 1);

    query.page_size = Number(searchParams.get("perpage") || 10);

    query.search = String(searchParams.get("search") || "");

    dispatch(getDepartmentList(query));
  }, [
    dispatch,
    searchParams.get("page"),
    searchParams.get("perpage"),
    searchParams.get("search"),
  ]);
  useEffect(() => {}, [page, perPage, search]);

  const urlWithQueryString = (
    pageVal = Number(pageNum),
    perPageVal = Number(perPageNum),
    searchVal = String(pageSearch)
  ) => {
    let object: any = {};
    if (pageVal) {
      object.page = pageVal;
    }
    if (perPageVal) {
      object.perpage = perPageVal;
    }
    if (searchVal) {
      object.search = searchVal;
    }

    const objString = "?" + new URLSearchParams(object).toString();
    navigate(`/department-management${objString}`);
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, val: number) => {
    setPageNum(Number(val));
    urlWithQueryString(Number(val), Number(perPage), pageSearch);
  };

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPageNum(1);
    setPerPageNum(Number(rowsPerPage));
    urlWithQueryString(1, Number(rowsPerPage), pageSearch);
  };
  const handleChangeSearch = (val: string) => {
    setPageNum(1);
    setPageSearch(String(val));
    setPerPageNum(Number(10));
    urlWithQueryString(1, perPageNum, String(val));
  };

  const handleAddNew = () => {
    setModalHeading("Add Department");
    setModalButtonLable("Save");
    setParentId("");
    setDefaultFormValues({});
    setOpenEditModal(true);
  };

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      }); 
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      setModalHeading("");
      setModalButtonLable("");
      setDefaultFormValues({});
      setParentId("");
      setOpenEditModal(false);
      let object: any = {};
      if (page) {
        object.page = page;
      }
      if (perPage) {
        object.page_size = perPage;
      }
      if (
        search !== null &&
        search !== "" &&
        search !== undefined &&
        search !== "null"
      ) {
        object.search = search;
      }
      dispatch(getDepartmentList(object));
    }
  }, [alertMessage]);

  const handleDeleteAction = (row: any) => {
    confirm({
      title: t("Are you sure!"),
      description: `Do you want to delete ${row?.name} department?`,
      ...confirmProps,
    })
      .then(() => {
        dispatch(deleteDepartment(row.id));
      })
      .catch(() => {});
    return;
  };
  const flattenAccounts = (accounts: any) => {
    var a: any = [];
    for (var i = 0; i < accounts.length; i++) {
      if (accounts[i].children) {
        a = a.concat(flattenAccounts(accounts[i].children));
      }
      a.push(accounts[i]);
    }
    return a;
  };
  useEffect(() => {}, []);

  const handleClicked = () => {
    getWholeData();
  };

  const getWholeData = () => {
    if (deparmentData)
      dispatch(
        getWholeDepartmentList({
          page_size: deparmentData.count,
          page: 1,
          search: "",
        })
      );
  };

  const handleArr = (list: any, rez: any, pID: any, finalItems: any) => {
    list.forEach((item: any, i: any) => {
      finalItems["child" + item.id] = { ...item, childrenOf: pID };
      item.children && handleArr(item.children, rez, item.id, finalItems);
    });
  };

  const handle = (list: any) => {
    if (list) {
      const rezArr = [];
      for (const item of list) {
        const rezObj = { ...item };
        const finalItems: any = {};
        item.children && handleArr(item.children, rezObj, item.id, finalItems);
        rezArr.push(rezObj);
        const keys: any = Object.keys(finalItems);
        keys.forEach((key: any, index: any) => {
          rezArr.push(finalItems[key]);
        });
      }

      return rezArr;
    }
  };

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;
  return (
    <>
      <div style={{ paddingBottom: "0.6em" }}>
        <Header
          page={t("Department Management")}
          mainHead={t("Department Management")}
        />
        <div
          className="d-flex justify-content-between flex-wrap"
          style={{ marginTop: 3 }}
        >
          <div className="d-flex justify-content-between col-12">
            <div>
              <FormControl
                variant="standard"
                sx={{ m: 1, mr: 3, minWidth: 67 }}
              >
                <InputLabel
                  id="demo-simple-select-autowidth-label"
                  style={{ fontSize: "12px" }}
                  disableAnimation={true}
                  shrink={false}
                >
                  {perPageNum ? "" : `All (${deparmentData?.count || 0})`}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  style={{ color: interfaceTextColor }}
                  value={perPage}
                  onChange={(e) => {
                    handleChangeRowsPerPage(Number(e.target.value));
                  }}
                  autoWidth
                  disableUnderline
                  MenuProps={{
                    PaperProps: { style: { background: interfaceBgColor } },
                  }}
                  sx={{
                    ".MuiSvgIcon-root ": {
                      fill: interfaceTextColor,
                    },
                  }}
                >
                  <MenuItem
                    style={{
                      color: interfaceTextColor,
                      background: interfaceBgColor,
                    }}
                    value={Number(deparmentData?.count)}
                  >
                    {t("All")} ({Number(deparmentData?.count) || 0})
                  </MenuItem>
                  <MenuItem
                    style={{
                      color: interfaceTextColor,
                      background: interfaceBgColor,
                    }}
                    value="10"
                  >
                    10 {t("Per Page")}
                  </MenuItem>
                  <MenuItem
                    style={{
                      color: interfaceTextColor,
                      background: interfaceBgColor,
                    }}
                    value="15"
                  >
                    15 {t("Per Page")}
                  </MenuItem>
                  <MenuItem
                    style={{
                      color: interfaceTextColor,
                      background: interfaceBgColor,
                    }}
                    value="25"
                  >
                    25 {t("Per Page")}
                  </MenuItem>
                </Select>
              </FormControl>
              {usePermission("add_department") && (
                <FormControl sx={{ mt: 2.4, mr: 3, minWidth: 80 }}>
                  <Button
                    variant="text"
                    color="inherit"
                    endIcon={
                      <AddIcon
                        style={{
                          fontSize: 16,
                          color: interfaceTextColor,
                          marginRight: lang === "ar" ? "10px" : "0px",
                        }}
                      />
                    }
                    style={{
                      fontSize: 13,
                      color: interfaceTextColor,
                      textTransform: "capitalize",
                    }}
                    onClick={() => handleAddNew()}
                  >
                    {t("Add New")}
                  </Button>
                </FormControl>
              )}
            </div>
            <div className="d-flex justify-content-end align-items-center">
              <div className="">
                <ExportCSV
                  setParentData={() => dispatch(setWholeDepartmentData({}))}
                  handleClicked={handleClicked}
                  rows={handle(wholedeparmentData?.results)?.map(
                    (rw: any, i: any) => ({
                      num: rw.id,
                      childrenOf: rw?.childrenOf ? rw.childrenOf : "N/A",
                      name: rw.name,
                      members: rw.members,
                      created_date:
                        moment(new Date(rw.created_at)).format(
                          "MMM Do, YYYY"
                        ) || "",
                    })
                  )}
                  headers={[
                    "No.",
                    "Child Of",
                    "Department",
                    "Members",
                    "Created at",
                  ]}
                  filename={"department_list.csv"}
                />
              </div>

              <SearchTextField
                className="float-end"
                autoComplete="off"
                sx={{ mt: 0, ml: 5, mr: 0 }}
                style={{ width: "14rem" }}
                placeholder="Search"
                value={pageSearch}
                onChange={(e) => {
                  handleChangeSearch(e.target.value);
                }}
                id="custom-css-outlined-input"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ pl: 1 }}>
                      <SearchSVG />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: false,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <TreeView
        rows={deparmentData?.results}
        handleDeleteAction={handleDeleteAction}
        setOpenEditModal={setOpenEditModal}
        setParentId={setParentId}
        setDefaultFormValues={setDefaultFormValues}
        setModalHeading={setModalHeading}
        setModalButtonLable={setModalButtonLable}
      />

      {deparmentData?.count > 0 && (
        <Paginations
          totalCount={Number(deparmentData?.count)}
          perpage={Number(searchParams.get("perpage") || 10)}
          handleChangePageNum={handleChangePage}
          pagenum={Number(searchParams.get("page") || 1)}
          handleChangeRowsPerPageParent={handleChangeRowsPerPage}
        />
      )}

      <DepartmentModal
        openEditModal={openEditModal}
        CloseEditModal={CloseEditModal}
        parentId={parentId}
        defaultFormValues={defaultFormValues}
        modalHeading={modalHeading}
        modalButtonLable={modalButtonLable}
      />
    </>
  );
}

export default DepartmentList;
