import axiosPrivate from "../lib/axiosPrivate";
import {
  setErr,
  setErrors,
  setSuccess,
} from "../store/reducers/alertMessageReducer";
import {
  setDepartmentData,
  setDetailData,
  setMembersData,
  setWholeDepartmentData,
} from "../store/reducers/departmentReducer";
import { setLoading } from "../store/reducers/loaderReducer";
import { AppThunk } from "../store/store";
export interface AddDepartmentPost {
  name: string;
}
export interface GetData {
  page: any;
  page_size: any;
  search: any;
}
export const getDepartmentList =
  (payload: GetData): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(
      setDepartmentData({
        count: 0,
        next: "",
        previous: "",
        results: [],
      })
    );
    try {
      await axiosPrivate
        .get(
          `accounts/department/?page=${payload?.page}&page_size=${
            payload?.page_size
          }&search=${payload?.search || ""}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setDepartmentData(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(
            setDepartmentData({
              count: 0,
              next: "",
              previous: "",
              results: [],
            })
          );
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        setDepartmentData({
          count: 0,
          next: "",
          previous: "",
          results: [],
        })
      );
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getWholeDepartmentList =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(
      setWholeDepartmentData({
        count: 0,
        next: "",
        previous: "",
        results: [],
      })
    );
    try {
      await axiosPrivate
        .get(
          `accounts/department/?page=${payload?.page}&page_size=${
            payload?.page_size
          }&search=${payload?.search || ""}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setWholeDepartmentData(data));
        })
        .catch((error) => {
          dispatch(
            setWholeDepartmentData({
              count: 0,
              next: "",
              previous: "",
              results: [],
            })
          );
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        setWholeDepartmentData({
          count: 0,
          next: "",
          previous: "",
          results: [],
        })
      );
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const addDepartment =
  (payload: AddDepartmentPost): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .post(`accounts/department/`, payload)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setSuccess("Department saved successfully!"));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getDepartment =
  (id: Number): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .get(`accounts/department/${id}`)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));

          dispatch(setDetailData(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const updateDepartment =
  (id: Number, payload: AddDepartmentPost): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .put(`accounts/department/${id}/`, payload)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setDetailData(null));
          dispatch(setSuccess("Department updated successfully!"));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const deleteDepartment =
  (id: Number): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .delete(`accounts/department/${id}/`)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setSuccess("Department deleted successfully!"));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };
export const getDepMembers =
  (id: number, payload: GetData): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .get(
          `/accounts/department/${id}/members/?page=${payload.page}&page_size=${payload.page_size}&search=${payload.search}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setMembersData(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };
