import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DataTable from "../../../components/table/DataTable";
import TableFilters from "../../../components/table/TableFilters";
import useCompanyLanguage from "../../../hooks/language";
import useColor from "../../../hooks/useColor";
import usePermission from "../../../hooks/usePermission";
import DeleteSVG from "../../../icons/DeleteSVG";
import { bulkDeleteGroups } from "../../../services/groupManagementService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../../store/reducers/alertMessageReducer";
import { setPage } from "../../../store/reducers/departmentReducer";
import { groupManagementSelector } from "../../../store/reducers/groupManagementReducer";
import { RootState, useAppDispatch } from "../../../store/store";
import { defaultThemeColor } from "../../../utils/colors_values";
import { confirmProps } from "../../../utils/confirmProps";
import CreateGroupModal from "../CreateGroupModal";
import Style from "./AddModule.module.scss";

export interface SimpleDialogProps {
  setSelectedRows: any;
  selectedRows: any;
  setDefaultRows: any;
  defaultRows: any;
  id?: any;
}

function AddGroup(props: SimpleDialogProps) {
  const { setSelectedRows, selectedRows, setDefaultRows, defaultRows, id } =
    props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const { type } = useParams();

  const primaryButton =
    useColor("button_primary") || defaultThemeColor.button_primary;
  const primaryButtonText =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;

  const addPermission = usePermission("add_customusergroup");
  const editUpdatePermission = usePermission("change_customusergroup");
  const deletePermission = usePermission("delete_customusergroup");
  const viewPermission = usePermission("view_customusergroup");
  const [itemPerPage, setItemPerPage] = useState<number>(10);
  const [open, setOpen] = useState(false);
  const [searchTable, setSearchTable] = useState("");

  const [list, setList] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<any[]>([]);
  // const [page, setPage] = useState(1);
  const states = useSelector(groupManagementSelector).departmentReducer;
  const [listCSV, setListCSV] = useState([]);

  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;
  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  const [rows, setRows] = React.useState<any[]>([]);

  const page = useSelector(
    (state: RootState) => state.groupManagementReducer.page
  );

  const dataList = useSelector(
    (state: RootState) => state.groupManagementReducer.data
  );

  const handleSearchChange = (searchTerm: any) => {
    let data: any = selectedRows?.results ? selectedRows?.results : defaultRows;
    if (!searchTerm) {
      setRows(data);
    } else {
      const filteredRows = data?.filter(
        (row: any) =>
          row?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
          moment(new Date(row.created_at))
            .format("MMM Do, YYYY")
            ?.toLowerCase()
            ?.includes(searchTerm?.toLowerCase())
      );
      setRows(filteredRows);
    }
  };

  useEffect(() => {
    dispatch(setPage(1));
  }, []);

  useEffect(() => {
    // dispatch(
    //   getListData({
    //     page_size: Number(itemPerPage),
    //     page: Number(page),
    //     search: searchTable,
    //   })
    // );
  }, [dispatch, itemPerPage, page, searchTable]);

  useEffect(() => {
    let data: any = selectedRows.results;
    if (Object.keys(selectedRows).length === 0) {
      setRows([]);
    } else setRows(data);
  }, [selectedRows]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      setSelectedCheckBox([]);
      // dispatch(
      //   getListData({
      //     page_size: Number(itemPerPage),
      //     page: Number(page),
      //     search: searchTable,
      //   })
      // );
    }
  }, [alertMessage]);

  useEffect(() => {
    handleSearchChange(searchTable);
  }, [searchTable]);

  useEffect(() => {
    if (defaultRows) {
      let data: any = defaultRows;
      if (Object.keys(defaultRows).length === 0) {
        setRows([]);
      } else setRows(data);
    }
  }, [defaultRows]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setSelectedCheckBox([]);
    dispatch(setPage(page));
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    dispatch(setPage(1));
    setSelectedCheckBox([]);
    setItemPerPage(Number(rowsPerPage));
  };

  useEffect(() => {
    modifyRows();
  }, [rows]);

  const handleDeleteAction = (row: any) => {
    confirm({
      title: t("Are you sure!"),
      description: `Do you want to delete "${row?.name}" group?`,
      ...confirmProps,
    })
      .then(() => {
        if (selectedRows?.length === 0) {
          setDefaultRows(defaultRows.filter((item: any) => item.id != row?.id));
        } else {
          const updatedDataList = {
            ...selectedRows,
            count: selectedRows.count - 1,
            results: selectedRows.results.filter(
              (item: any) => item.id != row?.id
            ),
          };
          setSelectedRows(updatedDataList);
        }
      })
      .catch(() => {
        // toast.success("Your request canceled successfully!", {
        //   toastId: "Your request canceled successfully!",
        // });
      });
    return;
  };
  const bulkDelete = (ids: any) => {
    // const sendSelected = selected.map((sel) => sel);
    confirm({
      title: t("Are you sure!"),
      description: t(`Do you want to delete selected groups?`),
      ...confirmProps,
    })
      .then(() => {
        dispatch(bulkDeleteGroups(ids));
        setSelectedCheckBox([]);
      })
      .catch(() => {});
  };

  const modifyRows = () => {
    let f_rows: any = [];
    type !== "view"
      ? rows.forEach(
          (
            r: {
              id: string;
              name: string;
              is_auto_created: boolean;
              created_at: string;
            },
            i: number
          ) => {
            f_rows = [
              ...f_rows,
              {
                id: r.id,
                name: (
                  <>
                    {r.name}{" "}
                    {r.is_auto_created && (
                      <span
                        className={`badge ${Style.badge} mx-2`}
                        style={{
                          background: primaryButton,
                          color: primaryButtonText,
                        }}
                      >
                        Auto
                      </span>
                    )}
                  </>
                ),
                created_at:
                  moment(new Date(r.created_at)).format("MMM Do, YYYY") || "",
                actions: (
                  <div style={{ minWidth: "13em" }}>
                    <button
                      className="btn-link"
                      type="button"
                      onClick={() => {
                        handleDeleteAction(r);
                      }}
                    >
                      <DeleteSVG color="red" />
                    </button>
                  </div>
                ),
              },
            ];
          }
        )
      : rows.forEach(
          (
            r: {
              id: string;
              name: string;
              is_auto_created: boolean;
              created_at: string;
            },
            i: number
          ) => {
            f_rows = [
              ...f_rows,
              {
                id: r.id,
                name: (
                  <>
                    {r.name}{" "}
                    {r.is_auto_created && (
                      <span
                        className={`badge ${Style.badge} mx-2`}
                        style={{
                          background: primaryButton,
                          color: primaryButtonText,
                        }}
                      >
                        Auto
                      </span>
                    )}
                  </>
                ),
                created_at:
                  moment(new Date(r.created_at)).format("MMM Do, YYYY") || "",
              },
            ];
          }
        );
    setList(f_rows);
  };

  const AddMember = () => {
    const handleAddNew = () => {
      setOpen(true);
    };

    const interfaceForegroundTextColor =
      useColor("interface_foreground_text_color") ||
      defaultThemeColor.interface_foreground_text_color;

    return (
      <div className="d-flex flex-row gap-3">
        {/* {addPermission && ( */}
        {type !== "view" && (
          <>
            <Button
              variant="text"
              color="inherit"
              endIcon={
                <AddIcon
                  style={{
                    fontSize: 16,
                    color: interfaceForegroundTextColor,
                    marginRight: lang === "ar" ? "10px" : "0px",
                  }}
                />
              }
              style={{
                fontSize: 13,
                color: interfaceForegroundTextColor,
                textTransform: "capitalize",
              }}
              onClick={() => handleAddNew()}
            >
              {t("Add New")}
            </Button>
          </>
        )}

        {/* )} */}
      </div>
    );
  };

  const handleClicked = () => {
    // getWhole();

    if (list.length == 0) {
      toast.error(String(t("Nothing to export...")), {
        toastId: String(t("Nothing to export...")),
      });
    }
    setListCSV(list);
  };

  // const getWhole = () => {
  //   if (dataList)
  //     dispatch(
  //       getWholeModuleListData({
  //         status: "Approved",
  //         page_size: dataList.count || 1,
  //         page: 1,
  //         search: "",
  //       })
  //     );
  // };

  return (
    <>
      <div className="mt-5">
        <div
          className={`${Style.header} mb-3`}
          style={{
            color:
              useColor("interface_foreground_text_color") ||
              defaultThemeColor.interface_foreground_text_color,
            marginRight: lang === "ar" ? "2rem" : "0rem",
          }}
        >
          {t("Groups")}
        </div>
        <TableFilters
          dataList={selectedRows}
          itemPerPage={itemPerPage}
          setItemPerPage={setItemPerPage}
          selectedCheckBox={selectedCheckBox}
          bulkDelete={bulkDelete}
          setSearchTable={setSearchTable}
          createButton={<AddMember />}
          handle="addMembers"
          exportCSV={false}
          setParentData={() => setListCSV([])}
          handleClicked={handleClicked}
          rows={listCSV?.map((r: any, i: any) => ({
            // id: r.id,
            name: r.name?.props?.children[0],
            date: r.created_at,
          }))}
          headers={[t("Name"), t("Start Date")]}
          filename={`group_list_of_training_id_${id}.csv`}
        />

        <div style={{ marginRight: "0.7em" }}>
          <DataTable
            columnData={
              type !== "view"
                ? [
                    {
                      id: "name",
                      name: t("Name"),
                      enableSort: true,
                      align: lang === "ar" ? "center" : "left",
                      disablePadding: "none",
                    },

                    {
                      id: "start_date",
                      name: t("Start Date"),
                      enableSort: true,
                      align: lang === "ar" ? "center" : "left",
                      disablePadding: "none",
                    },
                    {
                      id: "actions",
                      name: t("Actions"),
                      enableSort: false,
                      align: "center",
                      disablePadding: "none",
                    },
                  ]
                : [
                    {
                      id: "name",
                      name: t("Name"),
                      enableSort: true,
                      align: lang === "ar" ? "center" : "left",
                      disablePadding: "none",
                    },

                    {
                      id: "start_date",
                      name: t("Start Date"),
                      enableSort: true,
                      align: lang === "ar" ? "center" : "left",
                      disablePadding: "none",
                    },
                  ]
            }
            showLoader={false}
            rows={list}
            isCheckboxes={false}
            selectedRowsPerPage={itemPerPage.toString()}
            totalRecordCount={Number(selectedRows.count)}
            handleChangePageParent={handleChangePage}
            pagenum={Number(page)}
            handleChangeRowsPerPageParent={handleChangeRowsPerPage}
            bulkDelete={bulkDelete}
            selectedCheckBox={selectedCheckBox}
            setSelectedCheckBox={setSelectedCheckBox}
            isSelectedRowsOnly={true}
          />
          <CreateGroupModal
            disableCheckboxes={list}
            setParentOpen={setOpen}
            open={open}
            onClose={handleClose}
            isLoading={loading}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            defaultRows={defaultRows}
          />
        </div>
      </div>
    </>
  );
}

export default AddGroup;
