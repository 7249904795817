import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogContent } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CancelButton from "../../components/CancelButton";
import CreateButton from "../../components/CreateButton";
import DataTable from "../../components/table/DataTable";
import TableFilters from "../../components/table/TableFilters";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import usePermission from "../../hooks/usePermission";
import { getListData, getWholeListData } from "../../services/questionsService";
import { alertMessageSelector, setErr, setSuccess } from "../../store/reducers/alertMessageReducer";
import { setWholeData } from "../../store/reducers/questionReducer";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import AddQuestion from "./AddQuestion";
import Style from "./AssessmentList.module.scss";
export interface SimpleDialogProps {
  open: boolean;
  setParentOpen?: any;
  isLoading?: boolean;
  onClose: any;
  setParentCheckedQuestions?: any;
  parentCheckedQuestions?: any;
  setParentCheckedButNotAddedQuestions?: any;
  parentCheckedButNotAddedQuestions?: any;
  setOpenCreateNewQuestionModal: any;
  openCreateNewQuestionModal: any;
}

function CreateQuestionModal(props: SimpleDialogProps) {
  const {
    onClose,
    open,
    setParentOpen,
    isLoading,
    setParentCheckedQuestions,
    parentCheckedQuestions,
    setParentCheckedButNotAddedQuestions,
    parentCheckedButNotAddedQuestions,
    setOpenCreateNewQuestionModal,
    openCreateNewQuestionModal,
  } = props;
  const addPermission = usePermission("add_question");
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const loading = useSelector((state: RootState) => state.loaderReducer.isLoading);
  const [itemPerPage, setItemPerPage] = useState<number>(10);
  const [page, setPage] = useState(1);
  const [searchTable, setSearchTable] = useState("");
  const [list, setList] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<any[]>([]);
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;
  const [rows, setRows] = useState([]);
  const [selectedIDsForCurrentPage, setSelectedIDsForCurrentPage] = useState([]);
  const dataList: any = useSelector((state: RootState) => state.questionReducer.data);
  const wholedata: any = useSelector((state: RootState) => state.questionReducer.wholedata);

  useEffect(() => {
    dispatch(
      getListData({
        page_size: Number(itemPerPage),
        page: Number(page),
        search: searchTable,
        question_status: "Approved",
      })
    );
  }, [dispatch, itemPerPage, page, searchTable]);

  useEffect(() => {
    let data: any = dataList.results;
    if (Object.keys(dataList).length === 0) {
      setRows([]);
    } else setRows(data);
  }, [dataList]);

  useEffect(() => {
    let selectedIDsForCurrentPage2: any = [];
    let currentPageIDs = dataList && dataList?.results?.map((i: any) => i.id);
    parentCheckedButNotAddedQuestions?.forEach((element: any) => {
      if (!currentPageIDs?.find((it: any) => it == element.id)) {
      } else {
        selectedIDsForCurrentPage2 = [...selectedIDsForCurrentPage2, element.id];
      }
    });
    setSelectedIDsForCurrentPage(selectedIDsForCurrentPage2);
  }, [dataList, open]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      setSelectedCheckBox([]);
      dispatch(
        getListData({
          page_size: Number(itemPerPage),
          page: Number(page),
          search: searchTable,
          question_status: "Approved",
        })
      );
    }
  }, [alertMessage]);

  useEffect(() => {
    let final: any = [];
    selectedCheckBox.length > 0 &&
      selectedCheckBox.forEach((checkbox) => {
        final = [
          ...final,
          dataList?.results?.find((item: any) => item?.id == checkbox) ||
            parentCheckedButNotAddedQuestions.find((i: any) => i?.id == checkbox) ||
            undefined,
        ];
      });
    final = [...final, ...parentCheckedButNotAddedQuestions];

    final = removeDuplicates(final);
    final = final.filter((i: any) => typeof i == "object");
    setParentCheckedButNotAddedQuestions(final);
  }, [selectedCheckBox]);

  useEffect(() => {
    setSelectedCheckBox([
      ...selectedCheckBox,
      ...(parentCheckedButNotAddedQuestions &&
        parentCheckedButNotAddedQuestions.map((i: any) => i?.id)),
    ]);
  }, [page]);

  const removeDuplicates = (arr: any) => {
    return arr.filter(
      (value: any, index: any, self: any) =>
        index === self.findIndex((t: any) => t?.id === value?.id)
    );
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setSelectedCheckBox([]);
    setPage(page);
  };

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPage(1);
    setSelectedCheckBox([]);
    setItemPerPage(Number(rowsPerPage));
  };

  useEffect(() => {
    modifyRows();
  }, [rows, open]);

  const modifyRows = () => {
    let f_rows: any = [];
    dataList?.results?.forEach(
      (
        r: {
          id: string;
          question_text: string;
          category: any;
          question_type: string;
          question_status: string;
          created_at: string;
          updated_at: string;
        },
        i: number
      ) => {
        if (parentCheckedQuestions.find((val: any) => val.id == r.id)) {
          setSelectedCheckBox([...selectedCheckBox, r.id]);
        }
        f_rows = [
          ...f_rows,
          {
            id: r.id,
            question: r.question_text,
            type: r.question_type == "TF" ? "T/F" : "MCQs",
            category: r?.category?.name || "N/A",
            date_created: moment(new Date(r.created_at)).format("MMM Do, YYYY") || "",
            date_modified: moment(new Date(r.updated_at)).format("MMM Do, YYYY") || "",
            status: (
              <span
                style={{ minWidth: "7em" }}
                className={
                  r.question_status?.toLowerCase() == "approved"
                    ? Style.statusSuccess
                    : Style.statusDraft
                }
              >
                {r.question_status?.toLowerCase() == "approved" ? "Approved" : "Draft"}
              </span>
            ),
          },
        ];
      }
    );
    setList(f_rows);
  };

  const handleClose = () => {
    if (isLoading) {
    } else {
      onClose();
    }
  };

  const AddQuestionButton = () => {
    const handleAddNew = () => {
      setOpenCreateNewQuestionModal(true);
    };
    const addIconColor =
      useColor("interface_foreground_text_color") ||
      defaultThemeColor.interface_foreground_text_color;
    const addIconTextColor =
      useColor("interface_foreground_text_color") ||
      defaultThemeColor.interface_foreground_text_color;
    return (
      <div className="d-flex flex-row gap-3">
        {addPermission && (
          <Button
            variant="text"
            color="inherit"
            endIcon={
              <AddIcon
                style={{
                  fontSize: 16,
                  color: addIconColor,
                }}
              />
            }
            style={{
              fontSize: 13,
              color: addIconTextColor,
              textTransform: "capitalize",
            }}
            onClick={() => handleAddNew()}
          >
            {t("Create Question")}
          </Button>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (!open) {
      setPage(1);
      setItemPerPage(10);
    }
  }, [openCreateNewQuestionModal, open]);

  const handleCloseModal = () => {
    if (isLoading) {
    } else {
      setOpenCreateNewQuestionModal(false);
    }
  };

  const handleClicked = () => {
    getWhole();
  };

  const getWhole = () => {
    if (dataList)
      dispatch(
        getWholeListData({
          page_size: dataList.count,
          page: 1,
          search: "",
        })
      );
  };

  return (
    <Dialog
      onClose={handleClose}
      maxWidth={"lg"}
      open={open !== undefined ? open : false}
      style={{
        direction: lang === "ar" ? "rtl" : "ltr",
      }}
    >
      <div
        className="d-flex justify-content-between align-items-baseline"
        style={{
          color:
            useColor("interface_background_text_color") ||
            defaultThemeColor.interface_background_text_color,
          background:
            useColor("interface_background_color") || defaultThemeColor.interface_background_color,
        }}
      >
        <DialogTitle style={{ paddingTop: "1em", fontSize: 22 }} className="ml-3 mb-0 pb-0">
          {t("Add Questions")}
        </DialogTitle>
        <div className="m-3">
          <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
        </div>
      </div>
      <DialogContent
        style={{
          color:
            useColor("interface_background_text_color") ||
            defaultThemeColor.interface_background_text_color,
          background:
            useColor("interface_background_color") || defaultThemeColor.interface_background_color,
        }}
      >
        <TableFilters
          dataList={dataList}
          itemPerPage={itemPerPage}
          selectedCheckBox={selectedCheckBox}
          bulkDelete={() => {}}
          setItemPerPage={handleChangeRowsPerPage}
          setSearchTable={setSearchTable}
          createButton={<AddQuestionButton />}
          handle="groups"
          isCheckboxes={false}
          exportCSV={false}
          setParentData={() => dispatch(setWholeData({}))}
          handleClicked={handleClicked}
          rows={wholedata?.results?.map((r: any, i: any) => ({
            question: r.question_text,
            type: r.question_type == "TF" ? "T/F" : "MCQs",
            category: r?.category?.name || "N/A",
            date_created: moment(new Date(r.created_at)).format("MMM Do, YYYY") || "",
            date_modified: moment(new Date(r.updated_at)).format("MMM Do, YYYY") || "",
            status: r.question_status?.toLowerCase() == "approved" ? "Approved" : "Draft",
          }))}
          headers={["Question", "Type", "Category", "Date Created", "Date Modified", "Status"]}
          filename={`all_questions.csv`}
        />
        <DataTable
          columnData={[
            {
              id: "question",
              name: t("Question"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "type",
              name: t("Type"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "category",
              name: t("Category"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "date_created",
              name: t("Date Created"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "date_modified",
              name: t("Date Modified"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "status",
              name: t("Status"),
              enableSort: true,
              align: "center",
              disablePadding: "none",
            },
          ]}
          rowsScroll={true}
          rows={list}
          isCheckboxes={true}
          selectedRowsPerPage={itemPerPage.toString()}
          totalRecordCount={Number(dataList.count)}
          handleChangePageParent={handleChangePage}
          pagenum={Number(page)}
          handleChangeRowsPerPageParent={handleChangeRowsPerPage}
          bulkDelete={() => {}}
          isTopCheckbox={true}
          displayForceBottomComponent={
            // parentCheckedButNotAddedQuestions &&
            // parentCheckedButNotAddedQuestions.length > 0
            true
          }
          minHeight={"25em"}
          selectedCheckBox={selectedIDsForCurrentPage}
          setSelectedCheckBox={(boxes: any) => {
            let currentPageIDs = dataList && dataList?.results?.map((i: any) => i.id);
            let parentAll: any = [];
            let parentAllWithData: any = [];
            let notSelectedIDsForCurrentPage: any = [];
            let selectedIDsForCurrentPage: any = [];
            dataList?.results?.forEach((element: any) => {
              if (!boxes.find((it: any) => it == element.id)) {
                notSelectedIDsForCurrentPage = [...notSelectedIDsForCurrentPage, element.id];
              } else {
                selectedIDsForCurrentPage = [...selectedIDsForCurrentPage, element.id];
              }
            });
            parentAll = parentCheckedButNotAddedQuestions.filter(
              (x: any) => !currentPageIDs.includes(x?.id)
            );
            parentAllWithData = parentCheckedButNotAddedQuestions.filter(
              (x: any) => !currentPageIDs.includes(x?.id)
            );
            parentAll = [...parentAll, ...selectedIDsForCurrentPage];
            setSelectedCheckBox(parentAll);
            setParentCheckedButNotAddedQuestions(parentAll);
            setSelectedIDsForCurrentPage(selectedIDsForCurrentPage);
          }}
          bottomButtonsComponent={
            <div className="d-flex flex-wrap gap-3 w-100 px-3 mt-4 mb-2 align-items-center justify-content-end ">
              <CreateButton
                size="large"
                outlined={false}
                onClick={() => {
                  let final: any = [];
                  selectedCheckBox.forEach((checkbox) => {
                    final = [...final, dataList?.results?.find((item: any) => item.id == checkbox)];
                  });
                  setParentCheckedQuestions(parentCheckedButNotAddedQuestions);
                  setParentOpen(false);
                }}
                // isDisabled={
                //   !parentCheckedButNotAddedQuestions ||
                //   parentCheckedButNotAddedQuestions.length === 0
                // }
                disabled={
                  !(
                    parentCheckedButNotAddedQuestions &&
                    parentCheckedButNotAddedQuestions.length > 0
                  )
                }
                text={t("Add to Assessment")}
              ></CreateButton>
              <CancelButton
                size="small"
                outlined={true}
                onClick={() => {
                  handleClose();
                  setSelectedCheckBox([]);
                  setSelectedIDsForCurrentPage([]);
                  setParentCheckedButNotAddedQuestions([]);
                }}
                textColor=""
                outlineColor=""
                text={t("Cancel")}
                className={` ${Style.cancelBtn}`}
                bg={""}
                disabled={
                  false
                  // !(
                  //   parentCheckedButNotAddedQuestions &&
                  //   parentCheckedButNotAddedQuestions.length > 0
                  // )
                }
              ></CancelButton>
            </div>
          }
        />
        <AddQuestion
          setOpenCreateNewQuestionModal={setOpenCreateNewQuestionModal}
          openCreateNewQuestionModal={openCreateNewQuestionModal}
          onClose={handleCloseModal}
          isLoading={loading}
        />
      </DialogContent>
    </Dialog>
  );
}

export default CreateQuestionModal;
