import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Theme, useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { t, useTranslation } from "react-multi-lang";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import Header from "../../components/Header";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import { addUser } from "../../services/userService";
import {
  setAddUserData,
  setAddUserFailed,
  userSelector,
} from "../../store/reducers/userReducers";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import { roles_values } from "../../utils/staticEntities";
import Style from "./AddUser.module.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(role: string, roleName: string[], theme: Theme) {
  return {
    fontWeight:
      roleName.indexOf(role) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const roles = roles_values;
const isAddUserRoute = "/add-user";
const filteredRoles = isAddUserRoute
  ? roles.filter((role) => role !== "Admin")
  : roles;
let EMAIL_REGX: any = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/;
let EMAIL_REGX_ERROR: any = /^[a-zA-Z0-9_.+-]+$/;

const schema = yup
  .object({
    roles: yup.string().default("Trainer").required("Role is required"),
    first_name: yup
      .string()
      .matches(
        /[a-zA-Z][a-zA-Z ]+[a-zA-Z]$/,
        t("Only allow alphabets with whitespace")
      )
      .required("First name is required"),
    last_name: yup
      .string()
      .matches(
        /[a-zA-Z][a-zA-Z ]+[a-zA-Z]$/,
        t("Only allow alphabets with whitespace")
      )
      .required("Last name is required"),
    email: yup
      .string()
      .required(t("Email is required"))
      .matches(EMAIL_REGX, "Invalid email address"),
    // .email(t("Email is invalid")),
    phone: yup
      .string()
      .phone(
        "US",
        // eslint-disable-next-line no-template-curly-in-string
        "Phone is invalid. Please enter with country code like: +112345678910"
      )
      .required(t("Phone number is required")),
    password: yup
      .string()
      .min(8, t("Password must contain 8 characters"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must contain 8 characters, One uppercase, one lowercase, One number and one special case character"
      )
      .required(t("Password is required")),
    confirm_password: yup
      .string()
      .min(8, t("Confirm password must contain 8 characters"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must contain 8 characters, One uppercase, one lowercase, One number and one special case character"
      )
      .required(t("Confirm Password is required"))
      .oneOf([yup.ref("password"), ""], t("Confirm Password does not match")),
  })
  .required();

function AddUser() {
  const {
    watch,
    setValue,
    control,
    trigger,
    setError,
    clearErrors,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const states = useSelector(userSelector).userReducer;

  const [phoneValue, setPhoneValue] = useState<any>("");
  const [fieldTypePassword, setFieldTypePassword] = useState("password");
  const [fieldTypeConfirmPassword, setFieldTypeConfirmPassword] =
    useState("password");
  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );

  const addUserData = useSelector(
    (state: RootState) => state.userReducer.add_user_data
  );

  const userSubmit = (payload: any) => {
    dispatch(addUser(payload));
  };

  const theme = useTheme();
  const [roleName, setRoleName] = React.useState<string[]>(["Trainer"]);

  useEffect(() => {
    localStorage.removeItem("add_user_data");
  }, [dispatch]);

  useEffect(() => {
    if (watch("email")) {
      trigger("email");
    }
  }, [watch("email")]);

  useEffect(() => {
    if (addUserData?.email?.length > 0) {
      navigate("/user-management");
      dispatch(setAddUserData(null));
    }
  }, [addUserData]);

  useEffect(() => {
    if (watch("phone")) {
      clearErrors("phone");
    }
  }, [watch("phone")]);

  useEffect(() => {
    if (states?.errors) {
      if (states?.errors?.first_name?.length > 0) {
        setError("first_name", {
          type: "server",
          message: states?.errors?.first_name?.toString(),
        });
      }
      if (states?.errors?.last_name?.length > 0) {
        setError("last_name", {
          type: "server",
          message: states?.errors?.last_name?.toString(),
        });
      }
      if (states?.errors?.email?.length > 0) {
        setError("email", {
          type: "server",
          message: states?.errors?.email?.toString(),
        });
      }
      if (states?.errors?.phone?.length > 0) {
        setError("phone", {
          type: "server",
          message: states?.errors?.phone?.toString(),
        });
      }
      if (states?.errors?.password?.length > 0) {
        setError("password", {
          type: "server",
          message: states?.errors?.password?.toString(),
        });
      }
      if (states?.errors?.password2?.length > 0) {
        setError("confirm_password", {
          type: "server",
          message: states?.errors?.password2?.toString(),
        });
      }
      if (states?.errors?.roles?.length > 0) {
        setError("roles", {
          type: "server",
          message: states?.errors?.roles?.toString(),
        });
      }

      if (states?.errors) {
        dispatch(setAddUserFailed(null));
      }
    }
    if (states?.error) {
      toast.error(String(states?.error), {
        toastId: String(states?.error),
      });
    }
  }, [states]);

  const handleChange = (event: SelectChangeEvent<typeof roleName>) => {
    const {
      target: { value },
    } = event;
    setValue("roles", value as string);
    clearErrors("roles");
    setRoleName(typeof value === "string" ? value.split(",") : value);
  };
  useEffect(() => {
    if (phoneValue) {
      setValue("phone", "+" + String(phoneValue));
    } else {
      setValue("phone", "");
    }
  }, [phoneValue, setValue]);

  const showHidePassword = () => {
    if (fieldTypePassword === "password") {
      setFieldTypePassword("text");
    } else {
      setFieldTypePassword("password");
    }
  };

  const showHideConfirmPassword = () => {
    if (fieldTypeConfirmPassword === "password") {
      setFieldTypeConfirmPassword("text");
    } else {
      setFieldTypeConfirmPassword("password");
    }
  };

  const backgroundBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;

  const backgroundTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  return (
    <>
      <div
        className={`${Style.background}`}
        style={{
          background:
            useColor("interface_background_color") ||
            defaultThemeColor.interface_background_color,
        }}
      >
        <div style={{ paddingBottom: "4em" }}>
          <Header
            page={t("User Management")}
            mainHead={t("User Management")}
            backLink="/user-management"
          />
        </div>

        <div>
          <div
            style={{
              background:
                useColor("interface_foreground_color") ||
                defaultThemeColor.interface_foreground_color,
              color:
                useColor("interface_foreground_text_color") ||
                defaultThemeColor.interface_foreground_text_color,
              borderRadius: 10,
              minHeight: "55em",
            }}
          >
            <div className="p-5">
              <div
                className={`${Style.textHeader}`}
                style={{
                  color:
                    useColor("interface_foreground_text_color") ||
                    defaultThemeColor.interface_foreground_text_color,
                  textAlign: lang === "ar" ? "right" : "left",
                }}
              >
                {t("Add User")}
              </div>

              <form onSubmit={handleSubmit(userSubmit)} autoComplete="off">
                <div style={{ marginTop: "2em", marginInline: "0.8em" }}>
                  <div
                    className={Style.labelHeader}
                    style={{ textAlign: lang === "ar" ? "right" : "left" }}
                  >
                    {t("Roles")}
                    <span style={{ color: "red" }}> *</span>
                  </div>
                  <FormControl sx={{ m: 0, width: "100%", mt: 1 }}>
                    <Select
                      style={{
                        color:
                          useColor("interface_background_text_color") ||
                          defaultThemeColor.interface_background_text_color,
                        background:
                          useColor("interface_background_color") ||
                          defaultThemeColor.interface_background_color,
                      }}
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      sx={{
                        border: errors.roles?.message ? "1px solid red" : null,
                      }}
                      value={roleName || ""}
                      onChange={handleChange}
                      input={<OutlinedInput label={t("")} />}
                      MenuProps={MenuProps}
                      placeholder={t("Select")}
                    >
                      {filteredRoles.map((role) => (
                        <MenuItem
                          key={role}
                          value={role}
                          style={getStyles(role, roleName, theme)}
                        >
                          {t(role)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <p className="color-red">{errors.roles?.message}</p>
                </div>

                <div
                  className={`row col-lg-12 px-0 mx-0 justify-content-between`}
                >
                  <div
                    className={`col-lg-6 px-0 ${Style.widthOptimize}`}
                    style={{
                      marginTop: "2em",
                      marginRight: lang === "ar" ? "0em" : "2.3em",
                      marginLeft: lang === "ar" ? "2.3em" : "0em",
                    }}
                  >
                    <span className={Style.labelHeader}>
                      {t("First Name")}
                      <span style={{ color: "red" }}> *</span>
                    </span>
                    <input
                      className={`${Style.inputfields} ${Style.fieldFont} ${
                        errors.first_name ? "is-invalid" : ""
                      }`}
                      style={{
                        background:
                          useColor("interface_background_color") ||
                          defaultThemeColor.interface_background_color,
                        color:
                          useColor("interface_background_text_color") ||
                          defaultThemeColor.interface_background_text_color,
                      }}
                      type="text"
                      placeholder={t("First Name")}
                      autoComplete="off"
                      {...register("first_name")}
                    />
                    <p className="color-red">{errors.first_name?.message}</p>
                  </div>
                  <div
                    className={`col-lg-6 px-0 mx-0 ${Style.widthOptimize}`}
                    style={{ marginTop: "2em" }}
                  >
                    <span className={Style.labelHeader}>
                      {t("Last Name")}
                      <span style={{ color: "red" }}> *</span>
                    </span>
                    <input
                      className={`${Style.inputfields} ${Style.fieldFont} ${
                        errors.last_name ? "is-invalid" : ""
                      }`}
                      style={{
                        background:
                          useColor("interface_background_color") ||
                          defaultThemeColor.interface_background_color,
                        color:
                          useColor("interface_background_text_color") ||
                          defaultThemeColor.interface_background_text_color,
                      }}
                      type="text"
                      placeholder={t("Last Name")}
                      autoComplete="off"
                      {...register("last_name")}
                    />
                    <p className="color-red">{errors.last_name?.message}</p>
                  </div>
                </div>

                <div
                  className={`row col-lg-12 px-0 mx-0 justify-content-between`}
                >
                  <div
                    className={`col-lg-6 px-0 ${Style.widthOptimize}`}
                    style={{
                      marginTop: "2em",
                      marginRight: lang === "ar" ? "0em" : "2.3em",
                      marginLeft: lang === "ar" ? "2.3em" : "0em",
                    }}
                  >
                    <span className={Style.labelHeader}>
                      {t("Email Address")}
                      <span style={{ color: "red" }}> *</span>
                    </span>
                    <input
                      className={`${Style.inputfields} ${Style.fieldFont} ${
                        errors.email ? "is-invalid" : ""
                      }`}
                      style={{
                        background:
                          useColor("interface_background_color") ||
                          defaultThemeColor.interface_background_color,
                        color:
                          useColor("interface_background_text_color") ||
                          defaultThemeColor.interface_background_text_color,
                      }}
                      placeholder={t("Email Address")}
                      autoComplete="off"
                      {...register("email")}
                    />
                    <p className="color-red">
                      {watch("email") && watch("email")?.match(EMAIL_REGX_ERROR)
                        ? "Valid email required"
                        : errors.email?.message}
                    </p>
                  </div>
                  <div
                    className={`col-lg-6 px-0 mx-0 ${Style.widthOptimize}`}
                    style={{
                      marginTop: "2em",
                    }}
                  >
                    <span className={Style.labelHeader}>
                      {t("Phone no")}.<span style={{ color: "red" }}> *</span>
                    </span>
                    <label
                      className={`w-100 countryPhone ${Style.phoneLabel} ${Style.input}  ${Style.minWidthinput}`}
                      style={{ direction: lang === "ar" ? "ltr" : "unset" }}
                    >
                      <Controller
                        control={control}
                        name="phone"
                        rules={{ required: true }}
                        render={({ field: { ref, ...field } }) => (
                          <PhoneInput
                            {...field}
                            country={"us"}
                            enableSearch={true}
                            searchPlaceholder={"Search"}
                            inputStyle={{
                              background: backgroundBgColor,
                              color: backgroundTextColor,
                            }}
                            value={phoneValue}
                            onChange={setPhoneValue}
                            countryCodeEditable={false}
                            inputClass={`${errors.phone ? "is-invalid" : ""}`}
                            placeholder={t("Enter phone number")}
                            defaultErrorMessage="Invalid Phone Number."
                            isValid={(value, country: any) => {
                              if (value.match(/12345/)) {
                                return (
                                  "Invalid phone number: " +
                                  value +
                                  ", " +
                                  country.name
                                );
                              } else if (value.match(/1234/)) {
                                return false;
                              } else {
                                return true;
                              }
                            }}
                            inputProps={{
                              name: "phone",
                              required: true,
                              autoFocus: false,
                            }}
                          />
                        )}
                      />

                      <p className="color-red font400">
                        <ErrorMessage errors={errors} name="phone" />
                      </p>
                    </label>
                  </div>
                </div>

                <div
                  className={`row col-lg-12 px-0 mx-0 justify-content-between`}
                >
                  <div
                    className={`col-lg-6 px-0 ${Style.widthOptimize}`}
                    style={{
                      marginTop: "2em",
                      marginRight: lang === "ar" ? "0em" : "2.3em",
                      marginLeft: lang === "ar" ? "2.3em" : "0em",
                    }}
                  >
                    <span className={Style.labelHeader}>
                      {t("Password")}
                      <span style={{ color: "red" }}> *</span>
                    </span>
                    <div className="position-relative">
                      <input
                        id="password"
                        className={`${Style.inputfields} ${Style.fieldFont} ${
                          errors.password ? "is-invalid" : ""
                        }`}
                        style={{
                          background:
                            useColor("interface_background_color") ||
                            defaultThemeColor.interface_background_color,
                          color:
                            useColor("interface_background_text_color") ||
                            defaultThemeColor.interface_background_text_color,
                          paddingRight: lang === "ar" ? "3rem" : "12px",
                        }}
                        placeholder={t("Password")}
                        type={fieldTypePassword}
                        {...register("password")}
                        autoComplete="new-password"
                      />
                      <button
                        className="btn eye-btn"
                        type="button"
                        onClick={showHidePassword}
                      >
                        {fieldTypePassword === "password" ? (
                          <VisibilityOutlined />
                        ) : (
                          <VisibilityOffOutlined />
                        )}
                      </button>
                    </div>
                    <p className="color-red">{errors.password?.message}</p>
                  </div>
                  <div
                    className={`col-lg-6 px-0 mx-0 ${Style.widthOptimize}`}
                    style={{ marginTop: "2em" }}
                  >
                    <span className={Style.labelHeader}>
                      {t("Confirm Password")}
                      <span style={{ color: "red" }}> *</span>
                    </span>
                    <div className="position-relative">
                      <input
                        id="confirm_password"
                        className={`${Style.inputfields} ${Style.fieldFont} ${
                          errors.confirm_password ? "is-invalid" : ""
                        }`}
                        style={{
                          background:
                            useColor("interface_background_color") ||
                            defaultThemeColor.interface_background_color,
                          color:
                            useColor("interface_background_text_color") ||
                            defaultThemeColor.interface_background_text_color,
                          paddingRight: lang === "ar" ? "3rem" : "12px",
                        }}
                        placeholder={t("Confirm Password")}
                        type={fieldTypeConfirmPassword}
                        {...register("confirm_password")}
                        autoComplete="new-confirm-password"
                      />
                      <button
                        className="btn eye-btn"
                        type="button"
                        onClick={showHideConfirmPassword}
                      >
                        {fieldTypeConfirmPassword === "password" ? (
                          <VisibilityOutlined />
                        ) : (
                          <VisibilityOffOutlined />
                        )}
                      </button>
                    </div>
                    <p className="color-red">
                      {errors.confirm_password?.message}
                    </p>
                  </div>
                </div>

                <label id="message" className="error"></label>

                <div className="d-flex justify-content-end px-0 mx-0 mt-4 w-100">
                  <button
                    className={`btn ${Style.loginBtn} ${Style.mt24}`}
                    style={{
                      background:
                        useColor("button_primary") ||
                        defaultThemeColor.button_primary,
                    }}
                    type="submit"
                    disabled={loading}
                  >
                    <span className={`${Style.alignItemsInside}`}>
                      <span
                        className={`p-1 ${Style.hThree} ${Style.buttonFont} ${Style.white}`}
                        style={{
                          color:
                            useColor("button_primary_text") ||
                            defaultThemeColor.button_primary_text,
                        }}
                      >
                        {t("Save")}
                      </span>
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddUser;
