import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { getMicrosoftAuthenticate } from "../services/authService";
import { useAppDispatch } from "../store/store";

const Authorized = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  console.log("searchParamssearchParamssearchParams", searchParams.get("code"));
  useEffect(() => {
    dispatch(getMicrosoftAuthenticate(String(searchParams.get("code"))));
  }, [searchParams.get("code")]);
  return (
    <>
      <h1>Authorized Page</h1>
    </>
  );
};

export default Authorized;
