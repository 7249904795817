// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navigation_adminNavlink__mVMyt {
  text-decoration: none;
  color: #656565;
  display: flex;
  align-items: center;
  height: 50px;
}

.Navigation_adminNavlink__mVMyt .Navigation_active__bWAOB {
  text-decoration: none;
  color: #0089cf;
  display: flex;
  align-items: center;
  height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/components/Navigation.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;AACF;;AAEA;EACE,qBAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;AACF","sourcesContent":[".adminNavlink {\n  text-decoration: none;\n  color: #656565;\n  display: flex;\n  align-items: center;\n  height: 50px;\n}\n\n.adminNavlink .active {\n  text-decoration: none;\n  color: #0089cf;\n  display: flex;\n  align-items: center;\n  height: 50px;\n}\nul > li a:hover {\n  // background-color: rgb(233, 233, 233);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adminNavlink": `Navigation_adminNavlink__mVMyt`,
	"active": `Navigation_active__bWAOB`
};
export default ___CSS_LOADER_EXPORT___;
