import axiosPrivate from "../lib/axiosPrivate";
import { setLoading } from "../store/reducers/loaderReducer";
import { AppThunk } from "../store/store";

import {
  setData,
  setDetailData,
  setWholeData,
} from "../store/reducers/certificateManagementReducer";

import { setErr, setErrors, setSuccess } from "../store/reducers/alertMessageReducer";
import { setProgress } from "../store/reducers/progressReducer";

export interface GetData {
  page: number;
  page_size: number;
  search: string;
}

export const getCertificateListData =
  (payload: GetData): AppThunk =>
  async (dispatch) => {
    dispatch(setData({}));
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .get(
          `imaging/certificate/?page=${payload.page}&page_size=${payload.page_size}&search=${payload.search}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setData(data));
          dispatch(setLoading(false));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setData({}));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setData({}));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getWholeCertificateListData =
  (payload: GetData): AppThunk =>
  async (dispatch) => {
    dispatch(setWholeData({}));
    try {
      await axiosPrivate
        .get(
          `imaging/certificate/?page=${payload.page}&page_size=${payload.page_size}&search=${payload.search}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setWholeData(data));
        })
        .catch((error) => {
          dispatch(setWholeData({}));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setWholeData({}));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const addCertificateData =
  (payload: {
    name: string;
    training: any;
    description: string;
    logo: any;
    image: any;
    background_image: any;
    certificate_data: any;
  }): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    let formData = new FormData();
    formData.append("name", payload.name);
    formData.append("training", payload?.training?.value);
    formData.append("description", payload.description);
    formData.append("logo", payload.image.image, payload.image.imagename);
    formData.append(
      "background_image",
      payload?.background_image?.image,
      payload.background_image?.imagename
    );
    formData.append("certificate_data", JSON.stringify(payload?.certificate_data));

    try {
      await axiosPrivate
        .post("imaging/certificate/", formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=myboundary",
            enctype: "multipart/form-data; boundary=myboundary",
          },
          onUploadProgress: (progressEvent: any) => {
            dispatch(
              setProgress({
                total: progressEvent?.total,
                loaded: progressEvent?.loaded,
              })
            );
          },
        })
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Certificate saved successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
    dispatch(
      setProgress({
        total: 0,
        loaded: 0,
      })
    );
  };

export const deleteCertifiicate =
  (id: number): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .delete(`imaging/certificate/${id}/`)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Certificate deleted successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setData({}));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setData({}));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const updateCertificateData =
  (
    id: number,
    payload: {
      name: string;
      training: any;
      description: string;
      logo: any;
      image: any;
      background_image: any;
      certificate_data: any;
    }
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    let formData = new FormData();
    formData.append("name", payload.name);
    formData.append("training", payload?.training?.value);
    formData.append("description", payload.description);
    if (payload.image) {
      formData.append("logo", payload.image.image, payload.image.imagename);
    }
    if (payload?.background_image) {
      formData.append(
        "background_image",
        payload?.background_image?.image,
        payload?.background_image?.imagename
      );
    }
    formData.append("certificate_data", JSON.stringify(payload?.certificate_data));
    try {
      await axiosPrivate
        .put(`imaging/certificate/${id}/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=myboundary",
            enctype: "multipart/form-data; boundary=myboundary",
          },
          onUploadProgress: (progressEvent: any) => {
            dispatch(
              setProgress({
                total: progressEvent?.total,
                loaded: progressEvent?.loaded,
              })
            );
          },
        })
        .then((response) => {
          const data = response.data;

          dispatch(setLoading(false));

          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Certificate updated successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
    dispatch(
      setProgress({
        total: 0,
        loaded: 0,
      })
    );
  };

export const getCertificateData =
  (id: number): AppThunk =>
  async (dispatch) => {
    dispatch(setDetailData({}));
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .get(`imaging/certificate/${id}/`)
        .then((response) => {
          const data = response.data;
          dispatch(setDetailData(data));
          dispatch(setLoading(false));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getCertificateDataByTraining =
  (training_id: number): AppThunk =>
  async (dispatch) => {
    dispatch(setDetailData({}));
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .get(`imaging/certificate/training/${training_id}/`)
        .then((response) => {
          const data = response.data;
          dispatch(setDetailData(data));
          dispatch(setLoading(false));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getMyCertificateListData =
  (payload: GetData): AppThunk =>
  async (dispatch) => {
    dispatch(setData({}));
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .get(
          `imaging/certificate/my/?page=${payload.page}&page_size=${payload.page_size}&search=${payload.search}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setData(data));
          dispatch(setLoading(false));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setData({}));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setData({}));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };
