/* eslint-disable eqeqeq */
import useColor from "../hooks/useColor";
import { defaultThemeColor } from "../utils/colors_values";
import Style from "./CancelButton.module.scss";

interface CreateButtonProps {
  size?: string;
  outlined?: boolean;
  textColor?: string;
  outlineColor?: string;
  text?: string;
  onClick?: any;
  className?: string;
  bg?: string;
  disabled?: boolean;
  isDisabled?: boolean;
}

function CreateButton({
  size,
  outlined,
  textColor,
  outlineColor,
  text,
  onClick,
  className,
  bg,
  disabled = false,
}: // isDisabled = false,
CreateButtonProps) {
  return (
    <button
      disabled={disabled}
      className={`btn ${Style.cancelBtn} ${className}`}
      style={{
        height: size == "large" ? "3.1em" : "3.1em",
        minWidth: size == "large" ? "167px" : "150px",
        border: outlined
          ? `1px solid ${outlineColor ? outlineColor : defaultThemeColor.button_primary}`
          : "",
        backgroundColor: useColor("button_primary") || defaultThemeColor.button_primary,
        // cursor: isDisabled ? "not-allowed" : "pointer",
        // pointerEvents: isDisabled ? "none" : "none",
      }}
      type={"button"}
      onClick={onClick}
    >
      <span
        className={`p-1 ${Style.hThree} ${Style.buttonFont}`}
        style={{
          color: useColor("button_primary_text") || defaultThemeColor.button_secondary_text,
        }}
      >
        {text}
      </span>
    </button>
  );
}

export default CreateButton;
