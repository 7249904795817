function FastForwardSVG(props: { color: string }) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0269 0.0820312V2.32359L12.1869 0.131719V2.89078C6.84778 3.14186 2.58691 7.55803 2.58691 12.9586C2.58691 18.5201 7.10544 23.0386 12.6669 23.0386C18.2284 23.0386 22.7469 18.5201 22.7469 12.9586C22.7478 12.895 22.7361 12.8318 22.7123 12.7728C22.6886 12.7138 22.6534 12.6601 22.6087 12.6148C22.5641 12.5695 22.5109 12.5335 22.4522 12.509C22.3935 12.4844 22.3305 12.4718 22.2669 12.4718C22.2033 12.4718 22.1403 12.4844 22.0816 12.509C22.023 12.5335 21.9697 12.5695 21.9251 12.6148C21.8804 12.6601 21.8452 12.7138 21.8215 12.7728C21.7978 12.8318 21.786 12.895 21.7869 12.9586C21.7869 18.0013 17.7096 22.0786 12.6669 22.0786C7.62423 22.0786 3.54691 18.0013 3.54691 12.9586C3.54691 8.07793 7.3694 4.11345 12.1869 3.86297V6.58547L16.0269 4.39359V6.63516L16.7657 6.16266L21.1522 3.35859L16.0269 0.0820312ZM13.1469 1.78547L15.9032 3.35859L13.1469 4.93172V3.43922C13.1555 3.38738 13.1555 3.33449 13.1469 3.28266V1.78547ZM16.9869 1.83516L19.371 3.35859L16.9869 4.88203V3.97734V2.73984V1.83516ZM9.78691 9.59859C9.78691 10.6786 8.82691 11.0386 7.86691 11.0386V11.9986H9.78691V17.2786H9.83473H10.7469V9.59859H9.78691ZM12.7869 9.59859L12.2619 13.1273L13.0944 13.5192C13.4131 13.1635 13.8698 12.9586 14.3469 12.9586C15.2733 12.9586 16.0269 13.7122 16.0269 14.6386C16.0269 15.565 15.2733 16.3186 14.3469 16.3186C13.4008 16.3186 12.6669 15.5448 12.6669 14.8786H11.7069C11.7069 16.1568 12.9405 17.2786 14.3469 17.2786C15.8028 17.2786 16.9869 16.094 16.9869 14.6386C16.9869 13.1832 15.8028 11.9986 14.3469 11.9986C14.0109 11.9986 13.6819 12.0628 13.3757 12.1842L13.6204 10.5586H16.5069V9.59859H12.7869Z"
        fill={props.color ?? "#E7E7E7"}
      />
    </svg>
  );
}

export default FastForwardSVG;
