// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IconButtonAdvance_cancelBtn__P3y9O {
  border-radius: 4px;
}
@media only screen and (max-width: 1100px) {
  .IconButtonAdvance_cancelBtn__P3y9O {
    min-width: 120px;
  }
}

.IconButtonAdvance_buttonFont__TamQy {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.46px;
}

.IconButtonAdvance_white__npdCX {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/IconButtonAdvance.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;EAFF;IAGI,gBAAA;EAGF;AACF;;AAAA;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;AAGF;;AAAA;EACE,YAAA;AAGF","sourcesContent":[".cancelBtn {\n  border-radius: 4px;\n  @media only screen and (max-width: \"1100px\") {\n    min-width: 120px;\n  }\n}\n\n.buttonFont {\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 26px;\n  letter-spacing: 0.46px;\n}\n\n.white {\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cancelBtn": `IconButtonAdvance_cancelBtn__P3y9O`,
	"buttonFont": `IconButtonAdvance_buttonFont__TamQy`,
	"white": `IconButtonAdvance_white__npdCX`
};
export default ___CSS_LOADER_EXPORT___;
