import { toast } from "react-toastify";
import axiosPrivate from "../lib/axiosPrivate";
import {
  setErr,
  setErrors,
  setSuccess,
} from "../store/reducers/alertMessageReducer";
import {
  setLoginData,
  setPermissions,
  setRedirect,
} from "../store/reducers/authReducer";
import { setCompanyFormData } from "../store/reducers/companyFormReducer";
import { setData } from "../store/reducers/licenseReducer";
import { setLoading } from "../store/reducers/loaderReducer";
import {
  setOwnNotification,
  setUnReadNotificationCount,
} from "../store/reducers/notificationReducer";
import {
  AddUser,
  DeleteUser,
  GetUser,
  UpdateUser,
  setActiveDirectoryUsers,
  setAddUserData,
  setAddUserFailed,
  setDeleteUserData,
  setDeleteUserFailed,
  setGetUserDataFailed,
  setUpdateUserData,
  setUpdateUserDetails,
  setUpdateUserFailed,
  setUserData,
  setUserListData,
  setWholeUserListData,
} from "../store/reducers/userReducers";
import { AppThunk } from "../store/store";

export const addUser =
  (payload: AddUser): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    let formData = new FormData();
    formData.append("role", payload.roles);
    formData.append("first_name", payload.first_name);
    formData.append("last_name", payload.last_name);
    // formData.append("phone_number", payload.phone);
    formData.append(
      "sign_up_data",
      JSON.stringify({ phone_no: payload.phone })
    );
    formData.append("email", payload.email);
    formData.append("password", payload.password);
    // formData.append("personal_detail_data", {});
    // formData.append("sign_up_data", formData);

    let postData = {
      role: payload.roles,
      first_name: payload.first_name,
      last_name: payload.last_name,
      sign_up_data: JSON.stringify({ phone_no: payload.phone }),
      email: payload.email,
      password: payload.password,
    };
    try {
      await axiosPrivate
        .post("accounts/users/", postData)
        .then((response) => {
          const data = response.data;
          toast.success(String("User added Successfully"), {
            toastId: String("User added Successfully"),
          });
          dispatch(setLoading(false));
          dispatch(setAddUserData(data));
        })
        .catch((error) => {
          let errormsg = error?.response?.data?.detail
            ? error?.response?.data?.detail
            : error?.response?.data && error?.response?.data?.email
            ? error?.response?.data?.email[0]
            : error?.response?.data && error?.response?.data?.password
            ? error?.response?.data?.password[0]
            : error?.response?.data && error?.response?.data?.phone_number
            ? error?.response?.data?.phone_number[0]
            : "Something went wrong, Please try again";
          toast.error(String(errormsg), {
            toastId: String(errormsg),
          });
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setAddUserFailed(error?.response?.data?.detail));
          } else {
            dispatch(setAddUserFailed(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setAddUserFailed("Something went wrong, Please try again"));
    }
  };

export const searchUsers =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setUserListData({}));
    try {
      await axiosPrivate
        .get(
          "accounts/users/?" +
            `${payload.page >= 0 ? `page=${payload.page}` : ""}` +
            `${
              payload.page_size >= 0 ? `&page_size=${payload.page_size}` : ""
            }` +
            `${payload.search ? `&search=${payload.search}` : ""}` +
            `${payload.role ? `&role__in=${payload.role}` : ""}` +
            `${
              payload.primary_role__in
                ? `&primary_role__in=${payload.primary_role__in}`
                : ""
            }` +
            `${
              payload?.is_superuser
                ? `&is_superuser=${payload?.is_superuser}`
                : ``
            }` +
            `${payload.status ? `&status=${payload.status}` : ""}` +
            `${
              payload.department__id
                ? `&department__id=${payload.department__id}`
                : ""
            }` +
            `${
              payload.created_at_from
                ? `&created_at_from=${payload.created_at_from}`
                : ""
            }` +
            `${
              payload.created_at_to
                ? `&created_at_to=${payload.created_at_to}`
                : ""
            }` +
            `${
              payload.updated_at_from
                ? `&updated_at_from=${payload.updated_at_from}`
                : ""
            }` +
            `${
              payload.updated_at_to
                ? `&updated_at_to=${payload.updated_at_to}`
                : ""
            }`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (response.status === 200) {
            dispatch(setUserListData(data));
          } else {
            dispatch(
              setGetUserDataFailed("Something went wrong, Please try again")
            );
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setGetUserDataFailed(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setGetUserDataFailed("Something went wrong, Please try again"));
    }
  };

export const getAllUsers =
  (payload: any): AppThunk =>
  async (dispatch) => {
    // dispatch(setLoading(true));
    dispatch(setWholeUserListData({}));
    try {
      await axiosPrivate
        .get(
          "accounts/users/?" +
            `${payload.page >= 0 ? `page=${payload.page}` : ""}` +
            `${
              payload.page_size >= 0 ? `&page_size=${payload.page_size}` : ""
            }` +
            `${payload.search ? `&search=${payload.search}` : ""}` +
            `${payload.role ? `&role__in=${payload.role}` : ""}` +
            `${
              payload.primary_role__in
                ? `&primary_role__in=${payload.primary_role__in}`
                : ""
            }` +
            `${
              payload?.is_superuser
                ? `&is_superuser=${payload?.is_superuser}`
                : ``
            }` +
            `${payload.status ? `&status=${payload.status}` : ""}` +
            `${
              payload.department__id
                ? `&department__id=${payload.department__id}`
                : ""
            }` +
            `${
              payload.created_at_from
                ? `&created_at_from=${payload.created_at_from}`
                : ""
            }` +
            `${
              payload.created_at_to
                ? `&created_at_to=${payload.created_at_to}`
                : ""
            }` +
            `${
              payload.updated_at_from
                ? `&updated_at_from=${payload.updated_at_from}`
                : ""
            }` +
            `${
              payload.updated_at_to
                ? `&updated_at_to=${payload.updated_at_to}`
                : ""
            }`
        )
        .then((response) => {
          const data = response.data;
          // dispatch(setLoading(false));
          if (response.status === 200) {
            dispatch(setWholeUserListData(data));
          } else {
            dispatch(
              setGetUserDataFailed("Something went wrong, Please try again")
            );
          }
        })
        .catch((error) => {
          // dispatch(setLoading(false));
          dispatch(setGetUserDataFailed(error.response.data.detail));
        });
    } catch (error: any) {
      // dispatch(setLoading(false));
      dispatch(setGetUserDataFailed("Something went wrong, Please try again"));
    }
  };

export const updateUser =
  (payload: UpdateUser): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    let formData = new FormData();
    formData.append("status", payload.status);

    try {
      await axiosPrivate
        .patch("accounts/users/" + payload.id + "/", formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=myboundary",
            enctype: "multipart/form-data; boundary=myboundary",
          },
        })
        .then((response) => {
          const data = response.data;

          dispatch(setLoading(false));
          dispatch(setUpdateUserData(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setUpdateUserFailed(error?.response?.data?.detail));
          } else {
            dispatch(setUpdateUserFailed(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setUpdateUserFailed("Something went wrong, Please try again"));
    }
  };

export const deleteUser =
  (payload: DeleteUser): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .delete(`accounts/users/${payload.id}/`)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setDeleteUserData(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));

          if (error?.response?.data?.message) {
            dispatch(setDeleteUserFailed(error?.response?.data?.message));
          } else {
            dispatch(setDeleteUserFailed(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setDeleteUserFailed("Something went wrong, Please try again"));
    }
  };

export const updateBulkStatus =
  (ids: any[], status: string): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .post("accounts/users/bulk_update/", {
          user_ids: ids,
          new_status: status,
        })
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (status == "Active") {
            dispatch(setSuccess("User status changed to Active"));
          } else if (status) {
            dispatch(setSuccess(`User ${status.toLowerCase()} successfully`));
          } else {
            dispatch(setSuccess("Status updated successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getUser =
  (payload: GetUser): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setUserData({}));
    try {
      await axiosPrivate
        .get("/accounts/users/" + payload.id + "/")
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (response.status === 200) {
            dispatch(setUserData(data));
          } else {
            dispatch(setErr("Something went wrong, Please try again"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const updateUserDetails =
  (payload: any, isLang = false): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    let formData = new FormData();

    if (payload?.image) {
      formData.append("profile_picture", payload.image, payload.imagename);
    }
    if (payload?.preferred_language) {
      formData.append("preferred_language", payload.preferred_language);
    }
    if (payload?.id) formData.append("id", payload.id);
    if (payload?.first_name) formData.append("first_name", payload?.first_name);
    if (payload?.last_name) formData.append("last_name", payload?.last_name);
    // if (payload.email) formData.append("email", payload?.email);
    // if (payload?.department) formData.append("department", payload?.department);
    // field that is required is country, so logically testing country
    // if (payload?.country)
    //   formData.append(
    //     "personal_detail_data",
    //     JSON.stringify({
    //       address: payload?.house_number,
    //       gender: payload?.gender,
    //       department: payload?.department,
    //       designation: payload?.designation,
    //       experience_level: payload?.experience,
    //       street_number: payload?.street_number,
    //       country: payload?.country,
    //       city: payload?.city,
    //       region: payload?.region,
    //       date_of_birth: payload?.date_of_birth,
    //     })
    //   );
    if (payload?.personal_detail_data) {
      formData.append("personal_detail_data", payload?.personal_detail_data);
    }
    try {
      await axiosPrivate
        .patch("accounts/users/" + payload.id + "/", formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=myboundary",
            enctype: "multipart/form-data; boundary=myboundary",
          },
        })
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (isLang) {
            const loginData: any = JSON.parse(
              localStorage.getItem("user_info") || "{}"
            );
            let userNewData = {
              email: loginData?.email,
              first_name: loginData?.first_name,
              id: loginData?.id,
              preferred_language: payload.preferred_language,
              is_email_verified: loginData?.is_email_verified,
              is_superadmin: loginData?.is_superadmin,
              last_name: loginData?.last_name,
              profile_picture: loginData?.profile_picture,
              role: loginData?.role,
              primary_role: loginData?.primary_role,
              status: loginData?.status,
              sign_up_data: loginData?.sign_up_data || "",
              username: loginData?.username,
              personal_detail_data: loginData?.personal_detail_data || "",
              permission_list: loginData?.permissions,
            };

            localStorage.setItem("user_info", JSON.stringify(userNewData));

            dispatch(setLoginData(userNewData));
          } else {
            dispatch(setSuccess("User details updated successfully!"));
            dispatch(setUpdateUserDetails(data));
            dispatch(getUser({ id: payload.id }));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErr(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getUserData = (): AppThunk => async (dispatch) => {
  // dispatch(setLoginData({}));
  // dispatch(setPermissions([]));
  try {
    await axiosPrivate
      .get("/accounts/user_data/")
      .then((response) => {
        const data = response.data;

        const redirectOld = localStorage.getItem("redirect");
        localStorage.setItem(
          "company_profile",
          JSON.stringify(data?.company_profile)
        );
        const licenseInfo = JSON.parse(
          localStorage.getItem("license_info") || "{}"
        );
        if (redirectOld != data?.redirect) {
          localStorage.setItem("redirect", data?.redirect);
          dispatch(setRedirect(data?.redirect));
        }

        if (
          !(
            Object.entries(licenseInfo).sort().toString() ===
            Object.entries(data?.user_info?.license_data).sort().toString()
          )
        ) {
          localStorage.setItem(
            "license_info",
            JSON.stringify(data?.user_info?.license_data)
          );
          dispatch(setData(data?.user_info?.license_data));
        }
        dispatch(
          setCompanyFormData(
            data?.company_profile ? data?.company_profile : null
          )
        );
        const loginData: any = JSON.parse(
          localStorage.getItem("user_info") || "{}"
        );
        const permissions_list: any = JSON.parse(
          localStorage.getItem("permissions_list") || "{}"
        );

        let oldPermissions: any = [];
        permissions_list?.map((row: any) => {
          oldPermissions.push(row?.codename?.toString());
        });
        let newPermissions: any = [];
        data?.user_info?.permission_list?.map((row: any) => {
          newPermissions.push(row?.codename?.toString());
        });
        const diff = oldPermissions?.filter(
          (n: any) => !newPermissions.includes(n)
        );
        const diff2 = newPermissions?.filter(
          (n: any) => !oldPermissions.includes(n)
        );

        // if (loginData?.permission_list) {

        // }
        dispatch(setOwnNotification(data?.notifications));
        dispatch(
          setUnReadNotificationCount(data?.unread_notifications_count || 0)
        );
        // if (
        //   !loginData?.is_superadmin &&
        //   loginData?.role?.toLowerCase().toString() === "admin"
        // ) {
        if (
          diff.length > 0 ||
          diff2.length > 0 ||
          loginData?.role?.toString() != data?.user_info?.role?.toString()
        ) {
          dispatch(setLoginData({}));
          dispatch(setPermissions([]));
          // let userNewData = {
          //   email: loginData?.email,
          //   first_name: loginData?.first_name,
          //   id: loginData?.id,
          //   is_email_verified: loginData?.is_email_verified,
          //   is_superadmin: loginData?.is_superadmin,
          //   last_name: loginData?.last_name,
          //   profile_picture: loginData?.profile_picture,
          //   role: loginData?.role,
          //   primary_role: loginData?.primary_role,
          //   status: loginData?.status,
          //   sign_up_data: data?.sign_up_data || "",
          //   username: data?.username,
          //   personal_detail_data: data?.personal_detail_data || "",
          //   permission_list: data?.permissions,
          // };

          localStorage.setItem("user_info", JSON.stringify(data?.user_info));
          localStorage.setItem(
            "permissions_list",
            JSON.stringify(data?.user_info?.permission_list)
          );

          dispatch(setLoginData(data?.user_info));
          dispatch(setPermissions(data?.user_info?.permission_list));
        }
        // }
      })
      .catch((error) => {});
  } catch (error: any) {}
};
// export const getUserDataWithLogin =
//   (access_token: string): AppThunk =>
//   async (dispatch) => {

//     // dispatch(setLoginData({}));
//     // dispatch(setPermissions([]));
//     try {
//       await axios
//         .get("/accounts/user_data/", {
//           headers: {
//             Authorization: "Bearer " + access_token,
//           },
//         })
//         .then((response) => {
//           const data = response.data;

//           // dispatch(setLoading(false));
//           localStorage.setItem(
//             "company_profile",
//             JSON.stringify(data?.company_profile)
//           );
//           const licenseInfo = JSON.parse(
//             localStorage.getItem("license_info") || "{}"
//           );

//           if (
//             !(
//               Object.entries(licenseInfo).sort().toString() ===
//               Object.entries(data?.license).sort().toString()
//             )
//           ) {
//             localStorage.setItem("license_info", JSON.stringify(data?.license));
//             dispatch(setData(data?.license));
//           }
//           dispatch(
//             setCompanyFormData(
//               data?.company_profile ? data?.company_profile : null
//             )
//           );
//           const loginData: any = JSON.parse(
//             localStorage.getItem("user_info") || "{}"
//           );
//           const permissions_list: any = JSON.parse(
//             localStorage.getItem("permissions_list") || "{}"
//           );

//           let oldPermissions: any = [];
//           permissions_list?.map((row: any) => {
//             oldPermissions.push(row?.codename?.toString());
//           });
//           let newPermissions: any = [];
//           data?.permissions?.map((row: any) => {
//             newPermissions.push(row?.codename?.toString());
//           });
//           const diff = oldPermissions?.filter(
//             (n: any) => !newPermissions.includes(n)
//           );
//           const diff2 = newPermissions?.filter(
//             (n: any) => !oldPermissions.includes(n)
//           );

//           // if (loginData?.permission_list) {

//           // }
//           dispatch(setOwnNotification(data?.notifications));
//           dispatch(
//             setUnReadNotificationCount(data?.unread_notifications_count || 0)
//           );
//           if (
//             !loginData?.is_superadmin &&
//             loginData?.role?.toLowerCase().toString() === "admin"
//           ) {
//             if (diff.length > 0 || diff2.length > 0) {
//               dispatch(setLoginData({}));
//               dispatch(setPermissions([]));
//               let userNewData = {
//                 email: loginData?.email,
//                 first_name: loginData?.first_name,
//                 id: loginData?.id,
//                 preferred_language: loginData?.preferred_language,
//                 is_email_verified: loginData?.is_email_verified,
//                 is_superadmin: loginData?.is_superadmin,
//                 last_name: loginData?.last_name,
//                 profile_picture: loginData?.profile_picture,
//                 role: loginData?.role,
//                 primary_role: loginData?.primary_role,
//                 status: loginData?.status,
//                 sign_up_data: data?.sign_up_data || "",
//                 username: data?.username,
//                 personal_detail_data: data?.personal_detail_data || "",
//                 permission_list: data?.permissions,
//               };

//               localStorage.setItem("user_info", JSON.stringify(userNewData));
//               localStorage.setItem(
//                 "permissions_list",
//                 JSON.stringify(data?.permissions)
//               );

//               dispatch(setLoginData(userNewData));
//               dispatch(setPermissions(data?.permissions));
//             }
//           }
//         })
//         .catch((error) => {});
//     } catch (error: any) {}
//   };
export const bulkDeleteUsers =
  (payload: any[]): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await axiosPrivate
        .post(`accounts/users/bulk_delete/`, { user_ids: payload })
        .then((response) => {
          const data = response.data;
          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("User(s) deleted successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const syncActiveDirectoryUser = (): AppThunk => async (dispatch) => {
  // dispatch(setLoading(true));

  try {
    await axiosPrivate
      .get("accounts/microsoft/sync-active-directory-users/")
      .then((response) => {
        const data = response.data;
        dispatch(setLoading(false));

        if (data?.detail) {
          dispatch(setSuccess(data?.detail));
        } else {
          dispatch(
            setSuccess("Successfully synced data with Active Directory.")
          );
        }
      })
      .catch((error) => {
        dispatch(setLoading(false));
        if (error.response.data.detail) {
          dispatch(setErr(error.response.data.detail));
        } else {
          dispatch(setErr("Something went wrong, Please try again"));
        }
      });
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setGetUserDataFailed("Something went wrong, Please try again"));
  }
};

export const getActiveDirectoryUser =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setActiveDirectoryUsers({}));
    try {
      await axiosPrivate
        .get(
          `accounts/microsoft/users/?page=${payload.page}&page_size=${
            payload.page_size
          }${payload.search ? `&search=${payload.search}` : ""}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setActiveDirectoryUsers(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error.response.data.detail) {
            dispatch(setErr(error.response.data.detail));
          } else {
            dispatch(setErr("Something went wrong, Please try again"));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setGetUserDataFailed("Something went wrong, Please try again"));
    }
  };
export const updateBulkADUserRole =
  (ad_user_ids: any[], role: string): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .post(
          "accounts/microsoft/users/bulk_update_active_director_users_role/",
          {
            ad_user_ids: ad_user_ids,
            role: role,
          }
        )
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("User(s) imported successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };
