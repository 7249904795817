import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-multi-lang";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import { defaultThemeColor } from "../../utils/colors_values";
import Style from "./NotificationBar.module.scss";

function NotificationBar({
  row,
  index,
  number,
  header,
  activeBar,
  secondaryText,
  date,
}: {
  row?: any;
  index: any;
  number: any;
  header: any;
  activeBar: any;
  secondaryText?: any;
  date?: any;
}) {
  const [duration, setDuration] = useState<any>();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const primaryButtonColor =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;
  const primaryButtonBg =
    useColor("button_primary") || defaultThemeColor.button_primary;
  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;
  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;
  const interfaceForegroundTextColor =
    useColor("interface_foreground_text_color") ||
    defaultThemeColor.interface_foreground_text_color;
  const interfaceForegroundColor =
    useColor("interface_foreground_color") ||
    defaultThemeColor.interface_foreground_color;

  return (
    <div>
      <div
        className={`${
          index == 1 && number == 1
            ? Style.onlyBar
            : index == 1
            ? Style.firstBar
            : index == number
            ? Style.lastBar
            : index != 1 && index != number
            ? Style.insideBar
            : ""
        } ${activeBar ? Style.active : Style.notActive} ${Style.bar}`}
        style={{
          color: interfaceForegroundTextColor,
        }}
      >
        <div
          className="d-flex w-100"
          style={{ padding: "13px 10px", height: "105px" }}
        >
          <div className="w-100 d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column flex-wrap px-5 py-2 pb-3">
              {/* <div
                className={`${Style.header} w-75 py-2 mb-1`}
                style={{ color: interfaceForegroundTextColor }}
              >
                {header}
              </div> */}
              <p
                className={Style.secondaryText}
                dangerouslySetInnerHTML={{
                  __html: secondaryText ? secondaryText : null,
                }}
              ></p>
            </div>
            <div className="d-flex flex-column flex-wrap px-5 py-2 pb-3 justify-content-center">
              {activeBar && (
                <div className={`${Style.unread} py-2 mb-1`}>{t("Unread")}</div>
              )}
              <div className={Style.secondaryText}>
                {moment(date).fromNow()}
                {/* {moment.duration(moment().diff(new Date(date))).humanize(true)} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationBar;
