import BlotFormatter from "quill-blot-formatter";
import "quill/dist/quill.snow.css";
import { memo, useEffect } from "react";
import { useQuill } from "react-quilljs";
import useCompanyLanguage from "../../hooks/language";

import "./styles.css";
const Editor = (props: any) => {
  const lang = useCompanyLanguage();
  const { setCertificateData, prviousData } = props;
  const { quill, quillRef, Quill } = useQuill({
    placeholder: "Write here ...",
    theme: "snow",

    modules: {
      blotFormatter: {},
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }, { size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ align: [] }],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        [{ color: [] }], // dropdown with defaults from theme , { background: []}
      ],
      clipboard: {
        matchVisual: false,
      },
    },
  });

  var i = 0;
  if (Quill && !quill && !i) {
    Quill.register("modules/blotFormatter", BlotFormatter);
    i = i + 1;
  }

  useEffect(() => {
    if (quill) {
      quill.on("text-change", (delta: any, oldContents: any, source: any) => {
        setCertificateData(quillRef.current.firstChild.innerHTML);
      });

      if (prviousData) {
        let f = prviousData.replace(/&nbsp;&nbsp;/g, "\u00A0\u00A0");
        f = f.replace(/&nbsp;/g, " ");
        f = f.replace(/style=/g, "\nstyle='");
        f = f.replace(/;/g, ";'");
        quill.clipboard.dangerouslyPasteHTML(f);
      }
    }
  }, [quill, Quill, prviousData, setCertificateData, quillRef]);

  return (
    <div
      className="row mx-0 mt-2"
      style={{ direction: lang === "ar" ? "ltr" : "unset" }}
    >
      <div id="react-quil" ref={quillRef} />
    </div>
  );
};

export default memo(Editor);
