import { useTranslation } from "react-multi-lang";
import Header from "../../components/Header";
import useCompanyLanguage from "../../hooks/language";

const NoPage = () => {
  const t = useTranslation();
  const lang = useCompanyLanguage();
  return (
    <>
      <div style={{ paddingBottom: "0.6em" }}>
        <Header page={t("Not found")} mainHead={t("Not found")} />
        <h1>404: {t("Not found")} </h1>
      </div>
    </>
  );
};

export default NoPage;
