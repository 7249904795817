import axiosPrivate from "../lib/axiosPrivate";
import { setLoading } from "../store/reducers/loaderReducer";
import { AppThunk } from "../store/store";

import {
  setErr,
  setErrors,
  setSuccess,
} from "../store/reducers/alertMessageReducer";
import { setData } from "../store/reducers/dashboardReducer";

export const getDashboardInsights =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setData(null));
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .get(
          `commom/dashboard/?${
            payload?.start_date ? `start_date=${payload?.start_date}&` : ""
          }${payload?.end_date ? `end_date=${payload?.end_date}` : ""}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setData(data));
          dispatch(setLoading(false));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };
