import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import DataTable from "../../components/table/DataTable";
import TableFilters from "../../components/table/TableFilters";
import useCompanyLanguage from "../../hooks/language";
import usePermission from "../../hooks/usePermission";
import {
  getPolicyAssessment,
  getPolicyAssessmentResults,
  getWholeAssessmentResults,
} from "../../services/policyAssessmentService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { setWholeData } from "../../store/reducers/policyAssessmentReducer";
import { RootState, useAppDispatch, useAppSelector } from "../../store/store";
import Style from "./AssessmentResult.module.scss";

function PolicyAssessmentResult() {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const { assessment_id } = useParams();

  const addPermission = usePermission("add_module");
  const editUpdatePermission = usePermission("change_module");
  const deletePermission = usePermission("delete_module");
  const viewPermission = usePermission("view_module");

  const [selectedCheckBox, setSelectedCheckBox] = React.useState<number[]>([]);
  const dataList = useAppSelector(
    (state) => state.policyAssessmentReducer.assessmentResultData
  );
  const wholeData = useAppSelector(
    (state) => state.policyAssessmentReducer.wholeData
  );
  const searchParams = new URLSearchParams(document.location.search);

  const [pageNum, setPageNum] = useState(Number(searchParams.get("page") || 1));
  const [perPageNum, setPerPageNum] = useState(
    Number(searchParams.get("perpage")) || 10
  );
  const [pageSearch, setPageSearch] = useState(
    searchParams.get("search") || ""
  );
  useEffect(() => {
    if (assessment_id) {
      dispatch(getPolicyAssessment({ id: assessment_id }));
    }
  }, [assessment_id]);

  const assessmentData: any = useSelector(
    (state: RootState) => state.policyAssessmentReducer.assessmentData
  );
  const [rows, setRows] = useState([]);
  const [list, setList] = useState([]);
  const [searchTable, setSearchTable] = useState("");
  //   const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  //   const [defaultFormValues, setDefaultFormValues] = useState({});
  //   const [modalHeading, setModalHeading] = useState("");
  //   const [modalButtonLable, setModalButtonLable] = useState("");
  const alertMessage = useAppSelector(alertMessageSelector).alertMessageReducer;

  useEffect(() => {
    AllModules();
  }, [
    searchParams.get("page"),
    searchParams.get("perpage"),
    searchParams.get("search"),
  ]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }

    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      AllModules();
    }
  }, [alertMessage]);

  const AllModules = () => {
    let query: any = {};
    query.page = Number(searchParams.get("page") || 1);

    query.page_size = Number(searchParams.get("perpage") || 10);

    query.search = String(searchParams.get("search") || "");
    query.id = assessment_id;

    query.category = "";
    query.status = "";
    query.type = "";
    dispatch(getPolicyAssessmentResults(query));
  };

  const urlWithQueryString = (
    pageVal = Number(pageNum),
    perPageVal = Number(perPageNum),
    searchVal = String(pageSearch)
  ) => {
    let object: any = {};
    if (pageVal) {
      object.page = pageVal;
    }
    if (perPageVal) {
      object.perpage = perPageVal;
    }
    if (searchVal) {
      object.search = searchVal;
    }

    const objString = "?" + new URLSearchParams(object).toString();

    navigate(`/policy-assessment-result/${assessment_id}${objString}`);
  };

  const modifyRows = () => {
    let f_rows: any = [];
    dataList?.results?.forEach(
      (
        r: {
          id: string;
          user: any;
          score: string;
          passing_criteria: number;
          completion_date: string;
        },
        i: number
      ) => {
        f_rows = [
          ...f_rows,
          {
            id: r.id,
            name: (
              <div style={{ textAlign: lang === "ar" ? "center" : "unset" }}>
                {r?.user?.first_name + " " + r?.user?.last_name}
              </div>
            ),
            category: (
              <div style={{ textAlign: lang === "ar" ? "center" : "unset" }}>
                {r?.user?.primary_role || r?.user?.role || "N/A"}
              </div>
            ),
            score: (
              <div style={{ textAlign: lang === "ar" ? "center" : "unset" }}>
                {r?.score}
              </div>
            ),
            completion_date: (
              <div style={{ textAlign: lang === "ar" ? "center" : "unset" }}>
                {moment(new Date(r?.completion_date)).format("MMM Do, YYYY") ||
                  ""}
              </div>
            ),
            status: (
              <span
                className={
                  Number(
                    (Number(r?.score?.split("/")?.[0]) /
                      Number(r?.score?.split("/")?.[1])) *
                      100
                  ) >= Number(r?.passing_criteria)
                    ? Style.statusSuccess
                    : Style.statusDraft
                }
              >
                {Number(
                  (Number(r?.score?.split("/")?.[0]) /
                    Number(r?.score?.split("/")?.[1])) *
                    100
                ) >= Number(r?.passing_criteria)
                  ? "Pass"
                  : "Fail"}
              </span>
            ),
          },
        ];
      }
    );
    setList(f_rows);
  };
  // useEffect(() => {
  //   let data: any = dataList.results;
  //   if (Object.keys(dataList).length === 0) {
  //     setRows([]);
  //   } else setRows(data);
  // }, [dataList]);
  useEffect(() => {
    modifyRows();
  }, [dataList]);
  const handleAddNew = () => {
    // setModalHeading("Add Category");
    // setModalButtonLable("Add");
    // setDefaultFormValues({});
    // setOpenEditModal(true);
  };
  const handleChangePage = (event: React.ChangeEvent<unknown>, val: number) => {
    setPageNum(Number(val));
    urlWithQueryString(Number(val), Number(perPageNum), pageSearch);
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPageNum(1);
    setPerPageNum(Number(rowsPerPage));
    urlWithQueryString(1, Number(rowsPerPage), pageSearch);
  };
  const handleChangeSearch = (val: string) => {
    setPageNum(1);
    setPageSearch(String(val));
    setPerPageNum(Number(10));
    urlWithQueryString(1, perPageNum, String(val));
  };

  const AddGroup = () => {
    return <></>;
  };

  const handleClicked = () => {
    getWhole();
  };

  const getWhole = () => {
    if (dataList)
      dispatch(
        getWholeAssessmentResults({
          id: assessment_id,
          page_size: dataList.count,
          page: 1,
          search: pageSearch,
        })
      );
  };

  return (
    <div className={`${Style.categoryStart}`}>
      <div style={{ paddingBottom: "0.6em" }}>
        <Header
          page={assessmentData?.name}
          mainHead={t("Policy Management / Assessment Result")}
          backLink="/policy-assessment"
        />
        <TableFilters
          dataList={dataList}
          itemPerPage={perPageNum}
          setItemPerPage={handleChangeRowsPerPage}
          selectedCheckBox={selectedCheckBox}
          setSearchTable={handleChangeSearch}
          createButton={<AddGroup />}
          handle="modules"
          isCheckboxes={false}
          exportCSV={dataList?.count ? true : false}
          setParentData={() => dispatch(setWholeData({}))}
          handleClicked={handleClicked}
          rows={wholeData?.results?.map((r: any, i: any) => ({
            // id: r.id,
            name: (
              <div style={{ textAlign: lang === "ar" ? "center" : "unset" }}>
                {r?.user?.first_name + " " + r?.user?.last_name}
              </div>
            ),
            category: (
              <div style={{ textAlign: lang === "ar" ? "center" : "unset" }}>
                {r?.user?.primary_role}
              </div>
            ),
            score: (
              <div style={{ textAlign: lang === "ar" ? "center" : "unset" }}>
                {r?.score}
              </div>
            ),
            completion_date: (
              <div style={{ textAlign: lang === "ar" ? "center" : "unset" }}>
                {moment(new Date(r?.completion_date)).format("MMM Do, YYYY") ||
                  ""}
              </div>
            ),
            status:
              Number(
                (Number(r?.score?.split("/")?.[0]) /
                  Number(r?.score?.split("/")?.[1])) *
                  100
              ) >= Number(r?.passing_criteria)
                ? "Pass"
                : "Fail",
          }))}
          headers={[
            t("Username"),
            t("Type of User"),
            t("Marks"),
            t("Date"),
            t("Status"),
          ]}
          filename={`assessment_result_list-${moment().format()}.csv`}
        />

        <DataTable
          columnData={[
            {
              id: "name",
              name: t("Username"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "type_of_user",
              name: t("Type of User"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "marks",
              name: t("Marks"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "date",
              name: t("Date"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "status",
              name: t("Status"),
              enableSort: false,
              align: "center",
              disablePadding: "checkbox",
            },
          ]}
          rows={list}
          isCheckboxes={false}
          selectedRowsPerPage={String(searchParams.get("perpage") || 10)}
          totalRecordCount={dataList.count}
          handleChangePageParent={handleChangePage}
          pagenum={Number(searchParams.get("page") || 1)}
          handleChangeRowsPerPageParent={handleChangeRowsPerPage}
          selectedCheckBox={selectedCheckBox}
          setSelectedCheckBox={setSelectedCheckBox}
        />
      </div>
      {/* <CategoryModal
        openEditModal={openEditModal}
        CloseEditModal={CloseEditModal}
        defaultFormValues={defaultFormValues}
        modalHeading={modalHeading}
        modalButtonLable={modalButtonLable}
      /> */}
    </div>
  );
}

export default PolicyAssessmentResult;
