import { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useCompanyLanguage from "../../../../hooks/language";
import useColor from "../../../../hooks/useColor";
import AssessImg from "../../../../images/startAssessment.svg";
import { getTraining } from "../../../../services/trainingsService";
import { RootState, useAppDispatch } from "../../../../store/store";
import { defaultThemeColor } from "../../../../utils/colors_values";
import Style from "./StartAssessment.module.scss";

function StartScorm({
  training_id,
  module_id,
}: {
  training_id: any;
  module_id: any;
}) {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const trainingDetail: any = useSelector(
    (state: RootState) => state.trainingsReducer.trainingData
  );
  useEffect(() => {
    if (Object.keys(trainingDetail).length === 0) {
      dispatch(getTraining(Number(training_id)));
    }
  }, [training_id]);
  const [module, setModule]: any = useState<any>();

  useEffect(() => {
    const currentModule = trainingDetail?.ordered_modules?.filter(function (
      row: any
    ) {
      return parseInt(row?.module?.id) === parseInt(module_id);
    });
    setModule(currentModule?.[0]);
  }, [trainingDetail, training_id, module_id]);
  useEffect(() => {
    // if (
    //   module?.module?.module_status?.toLowerCase() === "completed" &&
    //   !module?.module?.assessment?.retaking_assessment
    // ) {
    //   toast.error(String(t("You are not allow to retake this assessment")), {
    //     toastId: String(t("You are not allow to retake this assessment")),
    //   });
    //   // navigate("/my-training");
    //   return;
    // }
  }, [module]);

  const interfaceForegroundTextColor =
    useColor("interface_foreground_text_color") ||
    defaultThemeColor.interface_foreground_text_color;
  const primaryButton =
    useColor("button_primary") || defaultThemeColor.button_primary;
  const primaryButtonText =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;

  return (
    <div
      className={`${Style.background}`}
      style={{
        background:
          useColor("interface_background_color") ||
          defaultThemeColor.interface_background_color,
        color:
          useColor("interface_foreground_text_color") ||
          defaultThemeColor.interface_foreground_text_color,
      }}
    >
      <div>
        <div
          style={{
            background:
              useColor("interface_foreground_color") ||
              defaultThemeColor.interface_foreground_color,
            color:
              useColor("interface_foreground_text_color") ||
              defaultThemeColor.interface_foreground_text_color,
            borderRadius: 10,
            padding: "3rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
          className={Style.bottomSpace}
        >
          <div className={`${Style.mainWrapper}`}>
            <div className={Style.assessImgWrap}>
              <img src={AssessImg} alt="assess-img" />
            </div>
            <div
              className={Style.assessHeading}
              style={{
                color: interfaceForegroundTextColor,
              }}
            >
              {module?.module?.name}
            </div>

            {
              // module?.module?.module_status?.toLowerCase() === "completed" ? (
              //   <button
              //     className={Style.startBtn}
              //     type="button"
              //     onClick={() => {
              //       navigate(`/scorm-report/${training_id}/${module_id}`);
              //     }}
              //   >
              //     {t("Result")}
              //   </button>
              // ) :
              <button
                className={Style.startBtn}
                style={{ background: primaryButton, color: primaryButtonText }}
                onClick={() => {
                  navigate(`/scorm-started/${training_id}/${module_id}`);
                }}
              >
                {module?.module?.module_status?.toLowerCase() == "inprogress" ||
                module?.module?.module_status?.toLowerCase() == "completed"
                  ? t("Retake")
                  : t("Start")}
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default StartScorm;
