import { yupResolver } from "@hookform/resolvers/yup";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, Chip, FormControl, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import Header from "../../components/Header";
import ImageSelect from "../../components/ImageSelect";
import SubmitButton from "../../components/SubmitButton";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import EditSVG from "../../icons/EditSVG";
import BaseImage from "../../images/ph_image.png";
import {
  getCompanyForm,
  updateCompanyForm,
} from "../../services/profileManagementService";
import {
  companyFormSelector,
  setUpdateCompanyFormDataFailed,
} from "../../store/reducers/companyFormReducer";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import { languages } from "../../utils/languages";
import Style from "../companyProfileManagement/profileManagement.module.scss";

const schema = yup
  .object({
    company_language: yup.string().required("Language is required"),
  })
  .required();

function CompanyCustomization() {
  const {
    setError,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const navigate = useNavigate();
  const states = useSelector(companyFormSelector).profileManagementReducer;
  const companyFormData =
    useSelector(
      (state: RootState) => state.profileManagementReducer.company_form_data
    ) || [];

  const isLoading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  const [selectedImage, setSelectedImage] = useState<any>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (states?.errors) {
      if (states?.errors?.company_language?.length > 0) {
        setError("company_language", {
          type: "server",
          message: states?.errors?.company_language?.toString(),
        });
      }
      if (states?.errors) {
        dispatch(setUpdateCompanyFormDataFailed(null));
      }
    }
    if (states?.error) {
      toast.error(String(states?.error), {
        toastId: String(states?.error),
      });
    }
  }, [states]);

  useEffect(() => {
    getCompanyFormData();
  }, []);

  useEffect(() => {
    if (loading && !(companyFormData && companyFormData.company_logo)) {
      setLoading(false);
    }
  }, [isLoading]);

  const getCompanyFormData = () => {
    dispatch(getCompanyForm());
  };

  useEffect(() => {
    let comp_data = companyFormData;
    let defaultValues = {
      company_language:
        (comp_data && companyFormData.company_language) || "English",
    };
    setSelectedImage("");
    reset({ ...defaultValues });
  }, [companyFormData]);

  const onSubmit = (payload: any) => {
    payload.id = companyFormData && companyFormData?.id;
    payload.image = selectedImage;
    dispatch(updateCompanyForm(payload));
  };

  const handleEditColorButton = () => {
    navigate("/company-setting/company-customization/edit-color");
  };

  const primaryButtonText =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;
  const primaryButton =
    useColor("button_primary") || defaultThemeColor.button_primary;

  return (
    <div
      className={`${Style.background} ${Style.manageSpace} overflow-auto`}
      style={{
        background:
          useColor("interface_background_color") ||
          defaultThemeColor.interface_background_color,
      }}
    >
      <div style={{ paddingBottom: "2.4em" }}>
        <Header
          page={t("Company Settings")}
          mainHead={t("Company Settings / Company Customization")}
        />
      </div>
      <div
        className={`${Style.background}`}
        style={{
          background:
            useColor("interface_foreground_color") ||
            defaultThemeColor.interface_foreground_color,
        }}
      >
        <form
          style={{
            background:
              useColor("interface_foreground_color") ||
              defaultThemeColor.interface_foreground_color,
            color:
              useColor("interface_foreground_text_color") ||
              defaultThemeColor.interface_foreground_text_color,
            borderRadius: 10,
          }}
          className={"pb-4"}
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          id="form_submit"
        >
          <div
            className={`${Style.leftSpace2} ${Style.topSpace} ${Style.bottomSpace}`}
            style={{ paddingRight: lang === "ar" ? "3rem" : "0rem" }}
          >
            <div
              className={`${Style.textHeader}`}
              style={{
                color:
                  useColor("interface_foreground_text_color") ||
                  defaultThemeColor.interface_foreground_text_color,
                textAlign: lang === "ar" ? "right" : "left",
              }}
            >
              {t("Customization")}
            </div>
          </div>
          <div
            className={`d-flex justify-content-between gap-5 ${Style.alignItemsInsideCustom}`}
            style={{ direction: lang === "ar" ? "ltr" : "ltr" }}
          >
            <div className={`${Style.imageMain} mx-4`}>
              <ImageSelect
                selectedImage={selectedImage}
                imageFromBackend={
                  (companyFormData && companyFormData.company_logo) || ""
                }
                BaseImage={BaseImage}
                isLoading={isLoading}
                setSelectedImage={setSelectedImage}
                updatePicSubmit={() => {}}
                minDimensions={true}
                showButtons={false}
                maxFileSizeinBytes={5242880}
                uploadButtonComponent={
                  <span className={Style.coverComp}>
                    <label
                      htmlFor="file"
                      className={`${Style.editCoverCompany} ${Style.buttonUpload}`}
                      style={{
                        color:
                          useColor("button_primary_text") ||
                          defaultThemeColor.button_primary_text,
                        background:
                          useColor("button_primary") ||
                          defaultThemeColor.button_primary,
                      }}
                    >
                      <CloudUploadIcon
                        style={{
                          fontSize: 19,
                          color:
                            useColor("button_primary_text") ||
                            defaultThemeColor.button_primary_text,
                        }}
                      />
                      <span style={{ marginLeft: "0.5em" }}>
                        {t("Upload File")}
                      </span>
                    </label>
                  </span>
                }
              />
            </div>
            <div className={Style.colorEditor} style={{ paddingTop: "10px" }}>
              <div>
                <label className={Style.requiredLabel}>
                  {t("Colour Theme")}
                </label>
              </div>
              <button
                className={Style.colorThemeBtn}
                onClick={handleEditColorButton}
                style={{
                  background:
                    useColor("button_primary") ||
                    defaultThemeColor.button_primary,
                }}
              >
                <EditSVG
                  color={
                    useColor("button_primary_text") ||
                    defaultThemeColor.button_primary_text
                  }
                />{" "}
                <span
                  style={{
                    paddingLeft: "5px",
                    color:
                      useColor("button_primary_text") ||
                      defaultThemeColor.button_primary_text,
                    fontWeight: 500,
                  }}
                >
                  {t("Edit Colour Theme")}
                </span>
              </button>
            </div>
          </div>
          <div className="col-md-5 m-5 pt-3">
            <span className="form-label" style={{ lineHeight: "40px" }}>
              {t("Language")}
              <span className="color-red"> *</span>
            </span>
            <FormControl fullWidth>
              <Controller
                name="company_language"
                control={control}
                defaultValue={
                  (companyFormData && companyFormData.company_language) || ""
                }
                render={({ field }) => (
                  <FormControl>
                    <Select
                      {...field}
                      labelId="company_language"
                      label=""
                      defaultValue={
                        (companyFormData && companyFormData.company_language) ||
                        ""
                      }
                      sx={{
                        minHeight: "3em",
                        "& .MuiChip-root": {
                          color: primaryButtonText,
                          background: primaryButton,
                        },
                      }}
                      MenuProps={{
                        sx: {
                          "&& .Mui-selected": {
                            color: primaryButtonText,
                            background: primaryButton,
                          },
                          "&& .Mui-selected:hover": {
                            color: primaryButtonText,
                            background: primaryButton,
                          },
                        },
                      }}
                      renderValue={(selected) => {
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                            }}
                          >
                            <Chip key={selected} label={selected} />
                          </Box>
                        );
                      }}
                    >
                      {languages.map((row) => (
                        <MenuItem
                          value={row.name}
                          key={row.code}
                          className="d-flex justify-content-between"
                        >
                          <div>{row.name}</div>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </FormControl>
            <p className="color-red mt-1">{errors.company_language?.message}</p>
          </div>

          <div
            className="d-flex w-100 gap-4 py-2 px-5"
            style={{
              justifyContent: lang === "ar" ? "flex-start" : "flex-end",
            }}
          >
            <SubmitButton marginTop={false} label={t("Save")} />
          </div>
        </form>
      </div>
    </div>
  );
}

export default CompanyCustomization;
