import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import DataTable from "../../components/table/DataTable";
import TableFilters from "../../components/table/TableFilters";
import useCompanyLanguage from "../../hooks/language";
import { getCompanyPolicyAssessment } from "../../services/policyService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { useAppDispatch, useAppSelector } from "../../store/store";
import Style from "./CompanyPolicyListing.module.scss";

function CompanyPolicyAssessmentListing() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const dataList = useAppSelector((state) => state.policyReducer.data);
  const searchParams = new URLSearchParams(document.location.search);
  const [pageNum, setPageNum] = useState(Number(searchParams.get("page") || 1));
  const [perPageNum, setPerPageNum] = useState(
    Number(searchParams.get("perpage")) || 10
  );
  const [pageSearch, setPageSearch] = useState(
    searchParams.get("search") || ""
  );
  const [list, setList] = useState([]);

  const alertMessage = useAppSelector(alertMessageSelector).alertMessageReducer;

  useEffect(() => {
    AllData();
  }, [
    searchParams.get("page"),
    searchParams.get("perpage"),
    searchParams.get("search"),
  ]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }

    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      AllData();
    }
  }, [alertMessage]);

  const AllData = () => {
    let query: any = {};
    query.page = Number(searchParams.get("page") || 1);

    query.page_size = Number(searchParams.get("perpage") || 10);

    query.search = String(searchParams.get("search") || "");
    dispatch(getCompanyPolicyAssessment(query));
  };

  const urlWithQueryString = (
    pageVal = Number(pageNum),
    perPageVal = Number(perPageNum),
    searchVal = String(pageSearch)
  ) => {
    let object: any = {};
    if (pageVal) {
      object.page = pageVal;
    }
    if (perPageVal) {
      object.perpage = perPageVal;
    }
    if (searchVal) {
      object.search = searchVal;
    }

    const objString = "?" + new URLSearchParams(object).toString();

    navigate(`/company-policy-assessment-list${objString}`);
  };

  const modifyRows = () => {
    let f_rows: any = [];
    dataList?.results?.forEach(
      (
        r: {
          id: string;
          policy: any;
          policy_assessment: any;

          passing_criteria: number;
          score: string;
        },
        i: number
      ) => {
        f_rows = [
          ...f_rows,
          {
            id: r.id,
            policy_assessment_name: r?.policy_assessment?.name,
            policy_name: r?.policy?.name,
            score: r?.score || "N/A",

            status: (
              <div style={{ textAlign: "center" }}>
                {(r?.score &&
                  (Number(
                    (Number(r?.score?.split("/")?.[0]) /
                      Number(r?.score?.split("/")?.[1])) *
                      100
                  ) >= Number(r?.passing_criteria) ? (
                    <span className={Style.statusSuccess}>{t("Pass")}</span>
                  ) : (
                    <span className={Style.statusDraft}> {t("Fail")}</span>
                  ))) ||
                  "N/A"}
              </div>
            ),

            actions: (
              <div style={{ textAlign: "center", paddingLeft: "1em" }}>
                {r?.score && r?.score !== "" ? (
                  <button
                    className="btn-primary"
                    type="button"
                    onClick={() => {
                      navigate(
                        `/policy-assessment-progress-report/${r?.policy_assessment?.id}`
                      );
                    }}
                  >
                    {t("Result")}
                  </button>
                ) : (
                  <button
                    className="btn-primary"
                    type="button"
                    onClick={() => {
                      navigate(
                        `/policy-assessment-start/${r?.policy_assessment?.id}`
                      );
                    }}
                  >
                    {t("Attempt")}
                  </button>
                )}
              </div>
            ),
          },
        ];
      }
    );
    setList(f_rows);
  };

  useEffect(() => {
    modifyRows();
  }, [dataList]);

  const handleChangePage = (event: React.ChangeEvent<unknown>, val: number) => {
    setPageNum(Number(val));
    urlWithQueryString(Number(val), Number(perPageNum), pageSearch);
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPageNum(1);
    setPerPageNum(Number(rowsPerPage));
    urlWithQueryString(1, Number(rowsPerPage), pageSearch);
  };
  const handleChangeSearch = (val: string) => {
    setPageNum(1);
    setPageSearch(String(val));
    setPerPageNum(Number(10));
    urlWithQueryString(1, perPageNum, String(val));
  };

  return (
    <div className={`${Style.categoryStart}`}>
      <div style={{ paddingBottom: "0.6em" }}>
        <Header
          page={t("Assigned Policy Assessments")}
          mainHead={t("Company Policies / Assigned Policy Assessments")}
        />
        <TableFilters
          dataList={dataList}
          itemPerPage={Number(searchParams.get("perpage") || 10)}
          setItemPerPage={handleChangeRowsPerPage}
          setSearchTable={handleChangeSearch}
          handle="category"
          isCheckboxes={false}
        />
        <DataTable
          columnData={[
            {
              id: "policy_assessment_name",
              name: t("Name"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "policy_name",
              name: t("Policy Name"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "score",
              name: t("Score"),
              enableSort: false,
              align: lang === "ar" ? "center" : "center",
              disablePadding: "none",
            },
            {
              id: "status",
              name: t("Status"),
              enableSort: false,
              align: lang === "ar" ? "center" : "center",
              disablePadding: "none",
            },
            {
              id: "action",
              name: t("Actions"),
              enableSort: false,
              align: "center",
              disablePadding: "none",
            },
          ]}
          rows={list}
          isCheckboxes={false}
          selectedRowsPerPage={String(searchParams.get("perpage") || 10)}
          totalRecordCount={dataList.count}
          handleChangePageParent={handleChangePage}
          pagenum={Number(searchParams.get("page") || 1)}
          handleChangeRowsPerPageParent={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}

export default CompanyPolicyAssessmentListing;
