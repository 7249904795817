// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserProfileForm_label__Nld8u {
  color: #212b36;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  word-break: break-all;
}

.UserProfileForm_fieldValue__62-f2 {
  color: #64748b;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.UserProfileForm_fieldBackgroundCover__5DdDQ {
  padding: 18px 23px;
  border-radius: 6px;
  background: #f6f9ff;
}

.UserProfileForm_bottomSpace__WyywL {
  padding-bottom: 5em;
}

.UserProfileForm_spacing__Ja0uj {
  padding-left: 4.5em;
  padding-right: 1.5em;
  background: white;
  border-radius: 10px;
}
@media only screen and (max-width: 1100px) {
  .UserProfileForm_spacing__Ja0uj {
    padding-left: 1em;
  }
}

.UserProfileForm_wfifty__JYcwn {
  width: 50%;
}
@media only screen and (max-width: 500px) {
  .UserProfileForm_wfifty__JYcwn {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/users/UserProfileForm.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;EACA,qBAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;AACF;;AAEA;EACE,kBAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,mBAAA;EACA,oBAAA;EACA,iBAAA;EACA,mBAAA;AACF;AACE;EANF;IAOI,iBAAA;EAEF;AACF;;AACA;EACE,UAAA;AAEF;AADE;EAFF;IAGI,WAAA;EAIF;AACF","sourcesContent":[".label {\n  color: #212b36;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 22px; /* 137.5% */\n  word-break: break-all;\n}\n\n.fieldValue {\n  color: #64748b;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 22px; /* 137.5% */\n}\n\n.fieldBackgroundCover {\n  padding: 18px 23px;\n  border-radius: 6px;\n  background: #f6f9ff;\n}\n\n.bottomSpace {\n  padding-bottom: 5em;\n}\n\n.spacing {\n  padding-left: 4.5em;\n  padding-right: 1.5em;\n  background: white;\n  border-radius: 10px;\n\n  @media only screen and (max-width: \"1100px\") {\n    padding-left: 1em;\n  }\n}\n\n.wfifty {\n  width: 50%;\n  @media only screen and (max-width: 500px) {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `UserProfileForm_label__Nld8u`,
	"fieldValue": `UserProfileForm_fieldValue__62-f2`,
	"fieldBackgroundCover": `UserProfileForm_fieldBackgroundCover__5DdDQ`,
	"bottomSpace": `UserProfileForm_bottomSpace__WyywL`,
	"spacing": `UserProfileForm_spacing__Ja0uj`,
	"wfifty": `UserProfileForm_wfifty__JYcwn`
};
export default ___CSS_LOADER_EXPORT___;
