function DatePickerSVG(props: { color: string }) {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5878 1.98047H14.1253V1.36172C14.1253 1.02422 13.844 0.714844 13.4784 0.714844C13.1128 0.714844 12.8315 0.996094 12.8315 1.36172V1.98047H4.81592V1.36172C4.81592 1.02422 4.53467 0.714844 4.16904 0.714844C3.80342 0.714844 3.52217 0.996094 3.52217 1.36172V1.98047H2.08779C1.13154 1.98047 0.315918 2.76797 0.315918 3.75234V13.5398C0.315918 14.4961 1.10342 15.3117 2.08779 15.3117H15.5878C16.544 15.3117 17.3597 14.5242 17.3597 13.5398V3.72422C17.3597 2.76797 16.544 1.98047 15.5878 1.98047ZM1.60967 7.21172H4.00029V9.99609H1.60967V7.21172ZM5.26592 7.21172H8.21904V9.99609H5.26592V7.21172ZM8.21904 11.2617V14.018H5.26592V11.2617H8.21904ZM9.48467 11.2617H12.4378V14.018H9.48467V11.2617ZM9.48467 9.99609V7.21172H12.4378V9.99609H9.48467ZM13.6753 7.21172H16.0659V9.99609H13.6753V7.21172ZM2.08779 3.24609H3.55029V3.83672C3.55029 4.17422 3.83154 4.48359 4.19717 4.48359C4.56279 4.48359 4.84404 4.20234 4.84404 3.83672V3.24609H12.8878V3.83672C12.8878 4.17422 13.169 4.48359 13.5347 4.48359C13.9003 4.48359 14.1815 4.20234 14.1815 3.83672V3.24609H15.5878C15.869 3.24609 16.094 3.47109 16.094 3.75234V5.94609H1.60967V3.75234C1.60967 3.47109 1.80654 3.24609 2.08779 3.24609ZM1.60967 13.5117V11.2336H4.00029V13.9898H2.08779C1.80654 14.018 1.60967 13.793 1.60967 13.5117ZM15.5878 14.018H13.6753V11.2617H16.0659V13.5398C16.094 13.793 15.869 14.018 15.5878 14.018Z"
        fill="#64748B"
      />
    </svg>
  );
}

export default DatePickerSVG;
