/* eslint-disable eqeqeq */
import axiosPrivate from "../lib/axiosPrivate";
import { setLoading } from "../store/reducers/loaderReducer";
import { AppThunk } from "../store/store";

import {
  setData,
  setDetail,
  setPermissions,
  setWholeData,
} from "../store/reducers/adminPermissionReducer";
import {
  setErr,
  setErrors,
  setSuccess,
} from "../store/reducers/alertMessageReducer";
export interface AddUser {
  role: string;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  password: string;
  confirmPassword: string;
}
export interface GetUsers {
  search: string;
  role: string;
  status: string;
  page: number;
  page_size: number;
  department__id?: string;
  created_at_from?: string;
  created_at_to?: string;
  updated_at_from?: string;
  updated_at_to?: string;
}

export const getPermissions = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setPermissions([]));
  try {
    await axiosPrivate
      .get("accounts/admin_permissions/all_permissions/")
      .then((response) => {
        const data = response.data;
        dispatch(setLoading(false));
        dispatch(setPermissions(data));
      })
      .catch((error) => {
        dispatch(setLoading(false));
        dispatch(setErr(error.response.data.detail));
      });
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setErr("Something went wrong, Please try again"));
  }
};
export const addAdminGroupPermission =
  (payload: {
    name: string;
    permissions: number[];
    user_list: number[];
  }): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .post(`accounts/admin_permissions/`, payload)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Permission group saved successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getGroupPermissions =
  (payload: { page: number; page_size: number; search?: string }): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setData({}));
    try {
      await axiosPrivate
        .get(
          "accounts/admin_permissions/?" +
            `${payload.page >= 0 ? `page=${payload.page}` : ""}` +
            `${
              payload.page_size >= 0 ? `&page_size=${payload.page_size}` : ""
            }` +
            `${payload.search ? `&search=${payload.search}` : ""}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setData(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getWholeGroupPermissions =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setWholeData({}));
    try {
      await axiosPrivate
        .get(
          "accounts/admin_permissions/?" +
            `${payload.page >= 0 ? `page=${payload.page}` : ""}` +
            `${
              payload.page_size >= 0 ? `&page_size=${payload.page_size}` : ""
            }` +
            `${payload.search ? `&search=${payload.search}` : ""}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setWholeData(data));
        })
        .catch((error) => {
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const deleteGroupPermission =
  (id: number): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setData({}));
    try {
      await axiosPrivate
        .delete(`accounts/admin_permissions/${id}/`)
        .then((response) => {
          const data = response.data;

          dispatch(setLoading(false));
          if (data?.detail.toLowerCase() == "group deleted successfully.") {
            dispatch(setSuccess("Permission Group Deleted Successfully!"));
          } else if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Group permission deleted successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };
export const getGroupPermission =
  (id: number): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setDetail({}));
    try {
      await axiosPrivate
        .get(`accounts/admin_permissions/${id}/`)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setDetail(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };
export const updateAdminGroupPermission =
  (
    id: number,
    payload: {
      name: string;
      permissions: number[];
      user_list: number[];
    }
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .put(`accounts/admin_permissions/${id}/`, payload)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Permission's group updated successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };
export const bulkDeleteAdminGroups =
  (payload: any[]): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .delete(`accounts/admin_permissions/bulk_delete/`, {
          data: JSON.stringify({ admin_permissions: payload }),
        })
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (data?.detail.toLowerCase() == "successfully deleted groups.") {
            dispatch(setSuccess("Permissions Group Deleted Successfully!"));
          } else if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };
