import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import useColor from "../hooks/useColor";
import Style from "./SubmitButton.module.scss";
import { defaultThemeColor } from "../utils/colors_values";

const SubmitButton = ({ label = "Submit", marginTop = true }) => {
  const isLoading = useSelector((state: RootState) => state.loaderReducer.isLoading);

  const labelColour = useColor("button_primary_text") || defaultThemeColor.button_primary_text;
  return (
    <div className={marginTop ? "mt-5" : ""}>
      <button
        className={`${defaultThemeColor.button_primary} btn d-flex justify-content-center align-items-center ${Style.buttonStart}`}
        type="submit"
        disabled={isLoading}
        style={{
          backgroundColor: useColor("button_primary") || defaultThemeColor.button_primary,
          color: useColor("button_primary_text") || defaultThemeColor.button_primary_text,
        }}
      >
        {isLoading ? (
          <CircularProgress size="1.5rem" color="primary" />
        ) : (
          <span
            style={{
              color: labelColour,
            }}
          >
            {label}
          </span>
        )}
      </button>
    </div>
  );
};
export default SubmitButton;
