import "react-circular-progressbar/dist/styles.css";
import Header from "../../../components/Header";
import ProgressSvg from "../../../icons/ProgressSvg";

import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CustomProgressbar } from "../../../helpers/helpers";
import useColor from "../../../hooks/useColor";
// import Radio from "../../images/radio.svg";
import Chip from "@mui/material/Chip";
import { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import useCompanyLanguage from "../../../hooks/language";
import { getPolicyAssessment } from "../../../services/policyAssessmentService";
import { getUserData } from "../../../services/userService";
import { RootState, useAppDispatch } from "../../../store/store";
import { defaultThemeColor } from "../../../utils/colors_values";
import Style from "./PolicyAssessmentProgressReport.module.scss";

function PolicyAssessmentProgressReport() {
  const { policy_assessment_id }: any = useParams();
  const navigate = useNavigate();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const dispatch = useAppDispatch();
  const [obtainedScore, setObtainedScore] = useState<number>(0);
  const [totalScore, setTotalScore] = useState<number>(0);
  const [scoreStatus, setScoreStatus] = useState<string>("");

  const assessmentData: any = useSelector(
    (state: RootState) => state.policyAssessmentReducer.assessmentData
  );
  useEffect(() => {
    setScoreStatus("");
    dispatch(getUserData());
    dispatch(getPolicyAssessment({ id: Number(policy_assessment_id) }));
  }, [policy_assessment_id]);

  useEffect(() => {
    const score = assessmentData?.results?.score?.split("/");
    if (score?.length > 0) {
      setObtainedScore(Number(score?.[0]));
      setTotalScore(Number(score?.[1]));
    }
  }, [assessmentData]);

  useEffect(() => {
    if (totalScore > 0) {
      if (
        Number((obtainedScore / totalScore) * 100) >=
        Number(assessmentData?.passing_criteria)
      ) {
        setScoreStatus("Pass");
      } else {
        setScoreStatus("Fail");
      }
    }
  }, [assessmentData, obtainedScore, totalScore]);

  const options = [
    "Global Variable",
    "Local Variable",
    "Both of above",
    "None of above",
  ];
  const options2 = [
    "Named Function",
    "Anonymous Function",
    "Both of above",
    "None of above",
  ];

  const interfaceForegroundTextColor =
    useColor("interface_foreground_text_color") ||
    defaultThemeColor.interface_foreground_text_color;
  const interfaceForegroundColor =
    useColor("interface_foreground_color") ||
    defaultThemeColor.interface_foreground_color;
  const buttonPrimaryColor =
    useColor("button_primary") || defaultThemeColor.button_primary;
  return (
    <div
      className={`${Style.background}`}
      style={{
        background:
          useColor("interface_background_color") ||
          defaultThemeColor.interface_background_color,
        color:
          useColor("interface_foreground_text_color") ||
          defaultThemeColor.interface_foreground_text_color,
      }}
    >
      <div style={{ paddingBottom: "2.4em" }}>
        <Header
          page={t(`Progress Report`)}
          mainHead={t("Company Policies / Assigned Policy Assessment")}
          // backLink={`/notifications`}
        />
      </div>
      <div>
        <div
          style={{
            background:
              useColor("interface_foreground_color") ||
              defaultThemeColor.interface_foreground_color,

            borderRadius: 10,
            padding: "2rem",
          }}
          className={Style.bottomSpace}
        >
          <div
            className={`row justify-content-between align-items-center pb-5 pt-5`}
          >
            <div
              className={`col-lg-4 col-md-4 col-sm-6 d-flex align-items-center justify-content-around ${Style.rightBorderProgress}`}
            >
              <div
                style={{
                  height: "120px",
                }}
              >
                <CustomProgressbar
                  value={
                    totalScore > 0 &&
                    Number((obtainedScore / totalScore) * 100).toFixed(2)
                  }
                  color={"#00CF3A"}
                >
                  <div>
                    <ProgressSvg color="#B3B7C5" />
                  </div>
                </CustomProgressbar>
              </div>
              <div
                className={Style.perText}
                style={{ color: interfaceForegroundTextColor }}
              >
                {t("Total Percentage")}
                <div
                  className={Style.num}
                  style={{ textAlign: "center", color: buttonPrimaryColor }}
                >
                  {totalScore > 0 &&
                    Number((obtainedScore / totalScore) * 100).toFixed(2) + "%"}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-4 col-md-4 col-sm-6 text-center ${Style.rightBorder}`}
            >
              <div
                className={Style.perText}
                style={{ color: interfaceForegroundTextColor }}
              >
                {t("Total Questions")}
              </div>
              <div className={Style.num} style={{ color: buttonPrimaryColor }}>
                {totalScore}
              </div>
            </div>
            {/* <div className={`col-lg-2 col-md-4 col-sm-6 ${Style.rightBorder}`}>
              <div className={Style.perText}>Correct Answers</div>{" "}
              <div className={Style.num}>
                {progressReportData?.obtainedScore}
              </div>
            </div>
            <div className={`col-lg-2 col-md-4 col-sm-6 ${Style.rightBorder}`}>
              <div className={Style.perText}>Incorrect Answers</div>{" "}
              <div className={Style.num}>
                {progressReportData?.totalScore -
                  progressReportData?.obtainedScore}
              </div>
            </div> */}
            <div
              className={`col-lg-4 col-md-4 col-sm-6 ${Style.rightBorder}`}
              style={{ borderRight: "none" }}
            >
              <div
                className={Style.perText}
                style={{ color: interfaceForegroundTextColor }}
              >
                {t("Status")}
              </div>
              <div className={Style.num}>
                {scoreStatus !== "" &&
                  (scoreStatus?.length > 0 ? (
                    scoreStatus === "Pass" ? (
                      <Chip
                        label={scoreStatus}
                        variant="outlined"
                        color="success"
                      />
                    ) : (
                      <Chip
                        label={scoreStatus}
                        variant="outlined"
                        color="error"
                      />
                    )
                  ) : null)}
              </div>
            </div>
          </div>
          {/* {module?.module?.assessment?.correct_answer_option &&
            progressReportData?.questionsAnswers?.map((row: any, r: number) => {
              return (
                <>
                  <div className={`${Style.question}  px-3 py-4`}>
                    Q {r + 1}. {row?.question_text}
                  </div>
                  <div className="d-flex justify-content-between px-3 pb-5">
                    <div className={Style.mcqOptions}>
                      {row?.options?.map((option: any, index: number) => (
                        <div key={index} className="option">
                          {option === row?.correct_option &&
                          row?.user_answer === option ? (
                            <>
                              <img
                                width={20}
                                height={20}
                                src={CorrectRadio}
                                alt="Correct"
                              />
                            </>
                          ) : option !== row?.correct_option &&
                            row?.user_answer === option ? (
                            <>
                              <img
                                width={20}
                                height={20}
                                src={WrongRadio}
                                alt="Wrong"
                              />
                            </>
                          ) : (
                            <img
                              width={20}
                              height={20}
                              src={Radio}
                              alt="Wrong"
                            />
                          )}
                          <label
                            className={Style.optionLabel}
                            htmlFor={`option${index}`}
                          >
                            {option}
                            {option === row?.correct_option ? (
                              <>
                                <span className="px-5">
                                  <img
                                    width={20}
                                    height={20}
                                    src={CorrectAnwser}
                                    alt="Correct"
                                  />
                                </span>
                              </>
                            ) : option !== row?.correct_option &&
                              row?.user_answer === option ? (
                              <span className="px-5">
                                <img
                                  width={20}
                                  height={20}
                                  src={WrongAnswer}
                                  alt="Correct"
                                />
                              </span>
                            ) : null}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              );
            })} */}
        </div>
      </div>
    </div>
  );
}

export default PolicyAssessmentProgressReport;
