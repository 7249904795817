function RewindSVG(props: { color: string }) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.30696 0.0820312L4.18165 3.35859L4.81352 3.76266L9.30696 6.63516V4.39359L13.147 6.58547V3.86297C17.9645 4.11345 21.787 8.07793 21.787 12.9586C21.787 18.0013 17.7096 22.0786 12.667 22.0786C7.62428 22.0786 3.54696 18.0013 3.54696 12.9586C3.54786 12.895 3.53611 12.8318 3.51239 12.7728C3.48867 12.7138 3.45346 12.6601 3.4088 12.6148C3.36414 12.5695 3.31092 12.5335 3.25224 12.509C3.19355 12.4844 3.13057 12.4718 3.06696 12.4718C3.00335 12.4718 2.94037 12.4844 2.88169 12.509C2.82301 12.5335 2.76979 12.5695 2.72513 12.6148C2.68046 12.6601 2.64525 12.7138 2.62153 12.7728C2.59781 12.8318 2.58606 12.895 2.58696 12.9586C2.58696 18.5201 7.10549 23.0386 12.667 23.0386C18.2284 23.0386 22.747 18.5201 22.747 12.9586C22.747 7.55803 18.4861 3.14186 13.147 2.89078V0.131719L9.30696 2.32359V0.0820312ZM12.187 1.78547V3.27797C12.1784 3.3298 12.1784 3.3827 12.187 3.43453V4.93172L9.43071 3.35859L12.187 1.78547ZM8.34696 1.83516V2.73984V3.97734V4.88203L5.9629 3.35859L8.34696 1.83516ZM9.78696 9.11859C9.78696 10.1986 8.82696 10.5586 7.86696 10.5586V11.5186H9.78696V16.7986H9.83477H10.747V9.11859H9.78696ZM12.787 9.11859L12.262 12.6473L13.0945 13.0392C13.4132 12.6835 13.8698 12.4786 14.347 12.4786C15.2734 12.4786 16.027 13.2322 16.027 14.1586C16.027 15.085 15.2734 15.8386 14.347 15.8386C13.4009 15.8386 12.667 15.0648 12.667 14.3986H11.707C11.707 15.6768 12.9406 16.7986 14.347 16.7986C15.8028 16.7986 16.987 15.614 16.987 14.1586C16.987 12.7032 15.8028 11.5186 14.347 11.5186C14.011 11.5186 13.682 11.5828 13.3757 11.7042L13.6204 10.0786H16.507V9.11859H12.787Z"
        fill={props.color ?? "#E7E7E7"}
      />
    </svg>
  );
}

export default RewindSVG;
