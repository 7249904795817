/* eslint-disable eqeqeq */
import moment from "moment";
import { useTranslation } from "react-multi-lang";
import useColor from "../hooks/useColor";
import Style from "../pages/training/CreateTraining/CreateTraining.module.scss";
import { defaultThemeColor } from "../utils/colors_values";
const TrainingDetail = ({ trainingData, detail = false }: any) => {
  const t = useTranslation();
  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;
  console.log("trainingData", trainingData);
  return (
    <div
      className={`${Style.bg} ${trainingData?.type == "Duration" ? "" : ""}`}
      style={{
        marginTop: "2em",
        paddingBottom: "10px",
        width: "100%",
        marginRight: "10px",
      }}
    >
      <div className={Style.descriptionBox}>
        <div style={{ width: "25%", float: "left", marginBottom: "20px" }}>
          <div className={Style.descName} style={{ color: interfaceTextColor }}>
            {t("Type")}
          </div>
          <div className={Style.descActualVal}>
            {trainingData?.type == "Self_Paced"
              ? "Self-Paced"
              : trainingData?.type == "Not_Scheduled"
              ? "Not Scheduled"
              : trainingData?.type}
          </div>
        </div>
        {(trainingData?.type == "Scheduled" ||
          trainingData?.type == "Duration") && (
          <div style={{ width: "25%", float: "left", marginBottom: "20px" }}>
            <div
              className={Style.descName}
              style={{ color: interfaceTextColor }}
            >
              {trainingData?.type == "Self_Paced"
                ? ""
                : trainingData?.type == "Scheduled"
                ? t("Schedule Period")
                : trainingData?.type == "Duration"
                ? t("Date Range")
                : ""}
            </div>
            <div className={Style.descActualVal}>
              {trainingData?.type == "Scheduled" ||
              trainingData?.type == "Duration"
                ? "From " +
                  moment(new Date(trainingData?.start_date)).format(
                    "MMM Do, YYYY"
                  ) +
                  " to " +
                  moment(new Date(trainingData?.end_date)).format(
                    "MMM Do, YYYY"
                  )
                : ""}
              {trainingData?.type == "Scheduled" &&
              trainingData?.completion_status == "Incomplete" ? (
                <span className={`mx-2 ${Style.statusDraft}`}>{"Expired"}</span>
              ) : null}
            </div>
          </div>
        )}

        {(trainingData?.type == "Duration" ||
          trainingData?.type == "Not_Scheduled") && (
          <div style={{ width: "25%", float: "left", marginBottom: "20px" }}>
            <div
              className={Style.descName}
              style={{ color: interfaceTextColor }}
            >
              {(trainingData?.type == "Duration" ||
                trainingData?.type == "Not_Scheduled") &&
                t("Duration")}
            </div>
            <div className={Style.descActualVal}>
              {trainingData?.type == "Duration" ||
              trainingData?.type == "Not_Scheduled"
                ? `${t("Duration")} ${trainingData?.days_to_complete} ${
                    trainingData?.days_to_complete > 1 ? t("days") : t("day")
                  }`
                : ""}
            </div>
          </div>
        )}

        {detail && (
          <>
            {/* <div className={Style.descriptionBox}> */}
            <div style={{ width: "25%", float: "left", marginBottom: "20px" }}>
              <div
                className={Style.descName}
                style={{ color: interfaceTextColor }}
              >
                {t("Category")}
              </div>
              <div className={Style.descActualVal}>
                {trainingData?.category?.name}
              </div>
            </div>

            <div style={{ width: "25%", float: "left", marginBottom: "20px" }}>
              <div
                className={Style.descName}
                style={{ color: interfaceTextColor }}
              >
                {t("Level")}
              </div>
              <div className={Style.descActualVal}>{trainingData?.level}</div>
            </div>

            <div style={{ width: "25%", float: "left", marginBottom: "20px" }}>
              <div
                className={Style.descName}
                style={{ color: interfaceTextColor }}
              >
                {t("Certificate")}
              </div>
              <div className={Style.descActualVal}>
                {trainingData?.certificate?.name}
              </div>
            </div>
            {/* </div>
          <div
            className={Style.descriptionBox}
            style={{ alignItems: "start", display: "flex" }}
          > */}
            {/* <div style={{ width: "25%", float: "left", marginBottom: "20px" }}>
              <div>
                <div
                  className={Style.descName}
                  style={{ color: interfaceTextColor }}
                >
                  {t("SCORM file")}
                </div>
                <div className={Style.descActualVal}>
                  {trainingData?.file ? (
                    <a href={trainingData?.file}>{t("Click here to View")}</a>
                  ) : (
                    "N/A"
                  )}
                </div>
              </div>
            </div> */}

            {/* <div style={{ width: "15%" }}></div> */}
            {/* </div> */}
          </>
        )}
      </div>
      {detail && (
        <div className={Style.descriptionBox}>
          <div style={{ width: "100%", float: "left", textAlign: "justify" }}>
            <div
              className={Style.descName}
              style={{ color: interfaceTextColor }}
            >
              {t("Description")}
            </div>
            <p className={Style.descActualVal}>{trainingData?.description}</p>
          </div>
        </div>
      )}
    </div>
  );
};
export default TrainingDetail;
