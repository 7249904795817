import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import DataTable from "../../components/table/DataTable";
import TableFilters from "../../components/table/TableFilters";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import usePermission from "../../hooks/usePermission";
import DeleteSVG from "../../icons/DeleteSVG";
import HeadIcon from "../../images/head.png";
import {
  bulkDeleteImages,
  deleteImage,
  getAllImages,
  searchImages,
} from "../../services/imageLibraryService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import {
  setDeleteImageData,
  setWholeImageListData,
} from "../../store/reducers/imagesReducer";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import { confirmProps } from "../../utils/confirmProps";
import { formatBytes } from "../../utils/formatBytesToDelimeters";
import Style from "./ImageLibrary.module.scss";
import AddNewImage from "./components/AddNewImage";
import GridCover from "./components/GridCover";
import ViewImage from "./components/ViewImage";

function ImageLibrary() {
  const addPermission = usePermission("add_image");
  const editUpdatePermission = usePermission("change_image");
  const deletePermission = usePermission("delete_image");
  const viewPermission = usePermission("view_image");
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const t = useTranslation();
  const lang = useCompanyLanguage();

  const [itemPerPage, setItemPerPage] = useState<number>(10);
  const dataList = useSelector(
    (state: RootState) => state.imageLibraryReducer.image_list_data
  );

  const wholedataList = useSelector(
    (state: RootState) => state.imageLibraryReducer.whole_image_list_data
  );

  const emails = ["username@gmail.com", "user02@gmail.com"];

  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;

  const [searchTable, setSearchTable] = useState("");
  const [rows, setRows] = useState([]);
  const [list, setList] = useState<{ imageSrcLibrary: any }[]>([]);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [view, setView] = useState("list");
  const [age, setAge] = useState("");

  const [open, setOpen] = React.useState(false);
  const [openImage, setOpenImage] = React.useState(false);
  const [image, setImage] = React.useState("");
  const [imageName, setImageName] = React.useState("");
  const [selectedValue, setSelectedValue] = React.useState("");
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<any[]>([]);

  const addImageData = useSelector(
    (state: RootState) => state.imageLibraryReducer.add_image_data
  );

  const deleteImageData = useSelector(
    (state: RootState) => state.imageLibraryReducer.delete_image_data
  );

  const isLoading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );

  useEffect(() => {
    if (alertMessage?.error) {
      if (alertMessage?.error === "Invalid page.") {
        setPage(1);
      } else {
        toast.error(String(alertMessage?.error), {
          toastId: String(alertMessage?.error),
        });
      }
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      dispatch(
        searchImages({
          search: searchTable,
          page: page,
          page_size: rowsPerPage,
        })
      );
    }
  }, [alertMessage, rowsPerPage, page]);

  const handleAddNew = () => {
    setSelectedCheckBox([]);
    setOpen(true);
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setSelectedCheckBox([]);
    setPage(page);
  };

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPage(1);
    setSelectedCheckBox([]);
    setRowsPerPage(rowsPerPage);
  };

  const handleRowAction = (row: { id: any }, action: string) => {};

  useEffect(() => {
    let data = dataList.results;
    if (Object.keys(dataList).length === 0) {
      setRows([]);
    } else {
      setRows(data);
      if (!(rowsPerPage == 10 || rowsPerPage == 15 || rowsPerPage == 25)) {
        setRowsPerPage(dataList?.count);
      }
    }
  }, [dataList]);

  useEffect(() => {
    setPage(1);
  }, [rowsPerPage]);

  useEffect(() => {
    setAge("1");
    setList([]);
    getimages();
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (!(page === 1)) setPage(1);
    if (age === "") {
    } else {
      setList([]);
      getimages();
    }
  }, [searchTable]);

  const getimages = () => {
    if (
      dataList &&
      dataList.count > 0 &&
      page > Math.ceil(Number(dataList.count) / Number(rowsPerPage))
    ) {
      let viewLocal = view;
      setView("");
      setTimeout(() => {
        setView(viewLocal);
      }, 500);
    } else {
      dispatch(
        searchImages({
          search: searchTable,
          page: page,
          page_size: rowsPerPage,
        })
      );
    }
  };

  useEffect(() => {
    if (addImageData && addImageData.filename) {
      setOpen(false);
      setList([]);
      getimages();
    }
  }, [addImageData]);

  useEffect(() => {
    if (deleteImageData && deleteImageData.detail) {
      toast.success(String(deleteImageData.detail), {
        toastId: String(deleteImageData.detail),
      });
      setList([]);
      getimages();
      dispatch(setDeleteImageData({}));
    }
  }, [deleteImageData]);

  useEffect(() => {
    setList([]);
    setSelectedCheckBox([]);
    modifyRows();
  }, [rows, view]);

  // const handleDeleteAction = (row: any) => {
  //   confirm({
  //     title: "Are you sure!",
  //     description: `Do you want to delete "${row?.filename || "file"}" image?`,
  //   })
  //     .then(() => {
  //       dispatch(deleteImage(row.id));
  //     })
  //     .catch(() => {});
  //   return;
  // };
  const handleDeleteAction = (row: any) => {
    confirm({
      title: t("Are you sure?"),
      description: `Do you want to delete "${row?.filename || "file"}" image?`,
      confirmationText: "CONFIRM",
      ...confirmProps,
    })
      .then(() => {
        dispatch(deleteImage(row.id));
      })
      .catch(() => {});
  };

  const bulkDelete = (ids: any) => {
    confirm({
      title: t("Are you sure!"),
      description: t(`Do you want to delete selected images?`),
      ...confirmProps,
    })
      .then(() => {
        dispatch(bulkDeleteImages(ids));
        setSelectedCheckBox([]);
      })
      .catch(() => {});
  };

  const modifyRows = () => {
    let f_rows: any = [];
    rows.forEach(
      (
        r: {
          id: string;
          filename: string;
          created_at: string;
          file: string;
          filesize: string;
          uploaded_by: string;
          dimensions: string;
        },
        i: number
      ) => {
        f_rows = [
          ...f_rows,
          {
            id: r.id.toString(),
            // name: r.id.toString(),
            imageSrcLibrary:
              view == "list" ? (
                <>
                  <img
                    alt="Loading..."
                    onClick={() => {
                      setOpenImage(true);
                      setImage(r?.file);
                      setImageName(r?.filename);
                    }}
                    height={35}
                    width={54}
                    src={r?.file}
                    className={Style.imageCover}
                    style={{
                      objectFit: "cover",
                      height: 25,
                      width: 54,
                    }}
                  ></img>
                </>
              ) : (
                r?.file || HeadIcon
              ),
            filename:
              (r.filename === "undefined" ? "file" : r.filename) || "N/A",
            filesize: formatBytes(Number(r.filesize)) || "N/A",
            uploaded_by: r.uploaded_by || "N/A",
            dimensions: r.dimensions || "",
            created_at:
              view === "grid"
                ? moment(new Date(r.created_at)).format("DD/MM/YYYY")
                : moment(new Date(r.created_at)).format("MMM Do, YYYY") ||
                  "N/A",

            actions: (
              <>
                {deletePermission && (
                  <button
                    className="btn-link"
                    type="button"
                    onClick={(e) => {
                      handleDeleteAction(r);
                    }}
                  >
                    <DeleteSVG color="red" />
                  </button>
                )}
              </>
            ),
            // logo: r.id || "N/A",
            // actions: "imageLibraryTable",
          },
        ];
      }
    );
    setList(f_rows);
  };

  const handleClose = (value: string) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const handleCloseImage = () => {
    setOpenImage(false);
  };

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  const AddImage = () => {
    return (
      <div className="d-flex flex-row gap-3">
        {deletePermission && view == "grid" && (
          <Button
            variant="text"
            color="inherit"
            style={{
              fontSize: 13,
              color: interfaceTextColor,
              textTransform: "capitalize",
              fontWeight: "500",
              // fontSize: "0.875rem",
              lineHeight: "1.75",
              letterSpacing: "0.02857em",
            }}
          >
            <label
              style={{
                marginBottom: "0px",
                fontWeight: "inherit",
              }}
            >
              <Checkbox
                color="primary"
                indeterminate={
                  selectedCheckBox?.length > 0 &&
                  selectedCheckBox?.length < list?.length
                }
                checked={
                  list?.length > 0
                    ? list?.length == selectedCheckBox?.length
                    : false
                }
                onChange={handleSelectAllClick}
                inputProps={{
                  "aria-label": "select all desserts",
                }}
              />
              Select All
            </label>
          </Button>
        )}
        {addPermission ? (
          <Button
            variant="text"
            color="inherit"
            endIcon={
              <AddIcon
                style={{
                  fontSize: 16,
                  color: interfaceTextColor,
                  marginRight: lang === "ar" ? "10px" : "0px",
                }}
              />
            }
            style={{
              fontSize: 13,
              color: interfaceTextColor,
              textTransform: "capitalize",
            }}
            onClick={() => handleAddNew()}
          >
            {t("Add New")}
          </Button>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const handleClicked = () => {
    getWholeData();
  };

  const getWholeData = () => {
    if (dataList)
      dispatch(
        getAllImages({
          page_size: dataList.count,
          page: 1,
          search: "",
        })
      );
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = list.map((n: any) => n.id);
      setSelectedCheckBox(newSelected);
      return;
    }
    setSelectedCheckBox([]);
  };
  return (
    <div className={`${Style.background}`}>
      <div style={{ paddingBottom: "0.6em" }}>
        <Header page={t("Image Library")} mainHead={t("Image Library")} />

        <TableFilters
          dataList={dataList}
          itemPerPage={rowsPerPage}
          selectedCheckBox={selectedCheckBox}
          bulkDelete={bulkDelete}
          setItemPerPage={setRowsPerPage}
          createButton={<AddImage />}
          view={view}
          setView={setView}
          setSearchTable={setSearchTable}
          handle="image-library"
          isCheckboxes={deletePermission}
          exportCSV={false}
          setParentData={() => dispatch(setWholeImageListData({}))}
          handleClicked={handleClicked}
          rows={wholedataList?.results?.map((r: any, i: any) => ({
            // num: i + 1,
            imageSrcLibrary: r?.file,
            filename:
              (r.filename === "undefined" ? "file" : r.filename) || "N/A",
            filesize: formatBytes(Number(r.filesize)) || "N/A",
            uploaded_by: r.uploaded_by || "N/A",
            dimensions: r.dimensions || "",
            created_at:
              view === "grid"
                ? moment(new Date(r.created_at)).format("DD/MM/YYYY")
                : moment(new Date(r.created_at)).format("MMM Do, YYYY") ||
                  "N/A",
          }))}
          headers={[
            // "No.",
            "Image link",
            "File Name",
            "File size",
            "Uploaded by",
            "Dimensions",
            "Created at",
          ]}
          filename={`image_library_list.csv`}
        />
      </div>
      {view === "grid" ? (
        <GridCover
          rows={list}
          selectedRowsPerPage={Number(rowsPerPage)}
          totalRecordCount={dataList.count}
          handleAction={handleRowAction}
          handleChangePageParent={handleChangePage}
          pagenum={page}
          handleChangeRowsPerPageParent={handleChangeRowsPerPage}
          selectedCheckBox={selectedCheckBox}
          setSelectedCheckBox={setSelectedCheckBox}
        />
      ) : view === "list" ? (
        <DataTable
          columnData={[
            {
              id: "imageSrcLibrary",
              name: t("File"),
              enableSort: false,
              align: "left",
              disablePadding: "none",
            },
            {
              id: "filename",
              name: t("File Name"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "checkbox",
            },
            {
              id: "filesize",
              name: t("File Size"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "checkbox",
            },
            {
              id: "uploaded_by",
              name: t("Uploaded by"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "dimensions",
              name: t("Dimension"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "checkbox",
            },
            {
              id: "created_at",
              name: t("Uploaded at"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "actions",
              name: t("Actions"),
              enableSort: false,
              align: "left",
              disablePadding: "checkbox",
            },
          ]}
          rows={
            list &&
            list.length > 0 &&
            list[0] &&
            typeof list[0].imageSrcLibrary === "string"
              ? []
              : list
          }
          isCheckboxes={deletePermission}
          selectedRowsPerPage={String(rowsPerPage)}
          totalRecordCount={Number(dataList.count)}
          handleChangePageParent={handleChangePage}
          pagenum={Number(page)} // Use tablePage state here
          handleChangeRowsPerPageParent={handleChangeRowsPerPage}
          bulkDelete={bulkDelete}
          selectedCheckBox={selectedCheckBox}
          setSelectedCheckBox={setSelectedCheckBox}
        />
      ) : null}

      <AddNewImage
        selectedValue={selectedValue}
        setParentOpen={setOpen}
        open={open}
        onClose={handleClose}
        isLoading={isLoading}
      />
      <ViewImage
        setParentOpen={setOpenImage}
        open={openImage}
        onClose={handleCloseImage}
        image={image}
        imageName={imageName}
      />
    </div>
  );
}

export default ImageLibrary;
