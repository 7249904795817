function superAdminSVG(props: { fill: string }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.6048 14.3776C18.0788 14.3776 17.5528 14.5206 17.1224 14.7111C16.9789 14.5382 15.4955 13.1709 13.5359 12.5192C15.353 11.3282 16.4051 9.23254 16.1177 6.99299C15.7829 4.42045 13.6789 2.3243 11.0966 2.03846C9.47072 1.8479 7.84485 2.37194 6.60153 3.46765C5.35822 4.56336 4.68874 6.08784 4.68874 7.75523C4.68874 9.70846 5.69296 11.5188 7.31883 12.5668C5.3104 13.2333 3.58888 14.6154 2.39339 16.378C1.72391 17.4261 1.91519 18.8553 2.91941 19.5699C5.16742 21.1425 7.74969 22 10.4754 22C13.5866 22 16.1296 20.7618 16.6078 20.3736C17.1726 20.819 17.8665 21.0472 18.6526 21.0472C20.5176 21.0472 22 19.5704 22 17.7124C21.9522 15.8545 20.4698 14.3776 18.6048 14.3776ZM5.69344 7.70807C5.69344 6.37415 6.26727 5.04024 7.27149 4.13508C8.18007 3.32521 9.27992 2.94409 10.4276 2.94409C10.6189 2.94409 10.8102 2.94409 11.0014 2.99173C13.1533 3.22993 14.9227 4.94496 15.1618 7.08875C15.4492 9.32782 14.1575 11.424 12.0057 12.1862L10.4754 12.7579L8.89736 12.1862C6.98457 11.5193 5.69344 9.70894 5.69344 7.70807ZM3.58936 18.7605C3.01552 18.3317 2.87207 17.4742 3.25462 16.8549C4.49794 14.9493 6.55419 13.5678 8.80172 13.139L10.4754 12.8055L12.1491 13.139C13.7272 13.4248 15.1618 14.1866 16.3573 15.2828C15.6878 15.8545 15.2574 16.7596 15.2574 17.7124C15.2574 18.4556 15.4879 19.1068 15.8987 19.6685C14.6142 20.4241 12.5183 21.0472 10.4754 21.0472C7.98879 21.0472 5.59779 20.2373 3.58936 18.7605ZM19.8481 19.3798L18.6048 18.7129L17.3615 19.3798L17.6006 17.9983L16.5959 17.0455L17.9827 16.8554L18.6048 15.6163L19.2264 16.8554L20.6132 17.0455L19.6085 17.9983L19.8481 19.3798Z"
        fill={props.fill}
      />
    </svg>
  );
}

export default superAdminSVG;
