/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import Select from "@mui/material/Select";
import { memo, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import "react-phone-input-2/lib/style.css";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import CancelButton from "../../../components/CancelButton";
import Editor from "../../../components/Editor/Editor";
import Header from "../../../components/Header";
import useCompanyLanguage from "../../../hooks/language";
import useColor from "../../../hooks/useColor";
import {
  getEmailNotificationByID,
  updateEmailNotification,
} from "../../../services/emailNotificationService";
import {
  alertMessageSelector,
  setErrors,
  setSuccess,
} from "../../../store/reducers/alertMessageReducer";
import { setEmailByIdData } from "../../../store/reducers/emailNotificationReducer";
import { RootState, useAppDispatch } from "../../../store/store";
import {
  defaultThemeColor,
  light_text_color,
  primary_color,
} from "../../../utils/colors_values";
import Style from "./AddEmailNotification.module.scss";

const statuses = [
  { key: "Enabled", value: "Enabled" },
  { key: "Disabled", value: "Disabled" },
];

const schema = yup
  .object({
    name: yup
      .string()
      .required("Name is required")
      .max(100, "Name must be at most 100 characters"),
    subject: yup.string().required("Subject is required"),
    status: yup.string().required("Status is required"),
    certificate_data: yup
      .string()
      .min(10, "Must atleast 10 characters are required!")
      .required("Email text is required"),
  })
  .required();

function AddEmailNotification() {
  const {
    reset,
    setError,
    setValue,
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });
  const enabledEmailsArray = [
    "NEW_ACCOUNT",
    "PASSWORD_RESET",
    "EMAIL_VERIFICATION",
    "LICENSE_USER_REACH",
    "LICENSE_EXPIRE",
  ];
  const { id } = useParams();
  const { view } = useParams();
  const [certificateData, setCertificateData] = useState<string>("");
  const [certificateEditData, setCertificateEditData] = useState<string>("");
  const [view2, setView] = useState<any>(false);
  const [visible, setVisible] = useState<any>(false);
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  useEffect(() => {
    setValue("certificate_data", certificateData);
    setError("certificate_data", {
      message: "",
      type: "server",
    });
    if (
      certificateData == "<p><br></p>" ||
      certificateData == `<p class="ql-align-center"><br></p>`
    ) {
      setError("certificate_data", {
        message: "Email text is required!",
        type: "server",
      });
    }
  }, [certificateData]);

  const emailTemplateData = useSelector(
    (state: RootState) => state.emailNotificationReducer.emailByIdData
  );

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;

  useEffect(() => {
    let defaultValues = {};
    reset({ ...defaultValues });
  }, []);

  useEffect(() => {
    if (emailTemplateData && Object.keys(emailTemplateData).length > 0) {
      let defaultValues = {
        name: emailTemplateData?.template_name,
        subject: emailTemplateData?.subject,
        certificate_data: emailTemplateData?.body,
        status: enabledEmailsArray?.includes(
          emailTemplateData?.select_event?.toString()
        )
          ? "Enabled"
          : emailTemplateData?.send_email
          ? "Enabled"
          : "Disabled",
      };

      reset({ ...defaultValues });
      setCertificateEditData(emailTemplateData?.body);
    }
  }, [emailTemplateData]);

  useEffect(() => {
    setCertificateEditData("");
    setValue("name", "");
    setValue("subject", "");
    dispatch(setEmailByIdData({}));
    dispatch(
      getEmailNotificationByID({
        id: id,
      })
    );
  }, [id]);

  useEffect(() => {
    if (alertMessage?.errors) {
      if (alertMessage?.errors?.name?.length > 0) {
        toast.error(alertMessage?.errors?.name?.toString(), {
          toastId: alertMessage?.errors?.name?.toString(),
        });
        setError("name", {
          type: "server",
          message: alertMessage?.errors?.name?.toString(),
        });
      }
      if (alertMessage?.errors?.subject?.length > 0) {
        setError("subject", {
          type: "server",
          message: alertMessage?.errors?.subject?.toString(),
        });
      }
      if (alertMessage?.errors?.send_email?.length > 0) {
        setError("status", {
          type: "server",
          message: alertMessage?.errors?.send_email?.toString(),
        });
      }
      if (alertMessage?.errors?.certificate_data?.length > 0) {
        toast.error(alertMessage?.errors?.certificate_data?.toString(), {
          toastId: alertMessage?.errors?.certificate_data?.toString(),
        });
        setError("certificate_data", {
          type: "server",
          message: alertMessage?.errors?.certificate_data?.toString(),
        });
      }
      if (alertMessage?.errors) {
        dispatch(setErrors(null));
      }
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      navigate(`/email-template-management?page=1&perpage=10`);
    }
  }, [alertMessage]);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 700);
  }, []);

  const Submit = (payload: any) => {
    console.log("payload", payload);
    // return;
    let body = {
      subject: payload?.subject,
      body: payload?.certificate_data,
      send_email: enabledEmailsArray?.includes(
        emailTemplateData?.select_event?.toString()
      )
        ? true
        : payload?.status == "Enabled"
        ? true
        : false,
    };
    if (
      certificateData == "<p><br></p>" ||
      certificateData == `<p class="ql-align-center"><br></p>`
    ) {
      setError("certificate_data", {
        message: "Email Template text is required!",
        type: "server",
      });
    } else if (id) {
      dispatch(
        updateEmailNotification({
          ...body,
          id: id,
        })
      );
    } else {
      // dispatch(
      //   addEmailNotification({
      //     ...body,
      //   })
      // );
    }
  };

  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;

  const foregroundBgColor =
    useColor("interface_foreground_color") ||
    defaultThemeColor.interface_foreground_color;

  const foregroundTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  const primaryButtonText =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;
  const primaryButton =
    useColor("button_primary") || defaultThemeColor.button_primary;

  const handleCopyVariable = (variableName: any, variableValue: any) => {
    const tempElement = document.createElement("textarea");
    tempElement.value = variableValue;
    document.body.appendChild(tempElement);

    tempElement.select();
    document.execCommand("copy");
    document.body.removeChild(tempElement);
  };

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  const primaryButtonBg =
    useColor("button_primary") || defaultThemeColor.button_primary;
  console.log("emailTemplateData", emailTemplateData);
  return (
    <>
      <div
        className={`${Style.certificatesStart}`}
        style={{
          background:
            useColor("interface_background_color") ||
            defaultThemeColor.interface_background_color,
        }}
      >
        <Header
          page={t("Email Template")}
          mainHead={t("Email Template Management")}
          backLink="/email-template-management"
        />

        <form
          className="row col-12 page-start"
          onSubmit={handleSubmit(Submit)}
          style={{
            background:
              useColor("interface_foreground_color") ||
              defaultThemeColor.interface_foreground_color,
            color:
              useColor("interface_foreground_text_color") ||
              defaultThemeColor.interface_foreground_text_color,
          }}
        >
          <div className={loading && !visible ? "hide" : ""}>
            <h2
              className="page-heading mb-4"
              style={{
                color:
                  useColor("interface_foreground_text_color") ||
                  defaultThemeColor.interface_foreground_text_color,
                textAlign: lang === "ar" ? "right" : "left",
              }}
            >
              {view2
                ? t("Preview Email Template")
                : view
                ? t("View Email Template Details")
                : id
                ? t("Update Email Template")
                : t("Email Template Details")}
            </h2>

            {(view || view2) && (
              <div>
                <div
                  className={`w-100 ${Style.certificateGenericTemplate} mb-2`}
                  style={{
                    color: foregroundTextColor,
                  }}
                >
                  {t("Subject")}
                </div>
                <div
                  className={`${Style.secondaryText} mb-4`}
                  style={{
                    color: foregroundTextColor,
                    backgroundColor: foregroundBgColor,
                    minHeight: "3.5em",
                  }}
                >
                  {emailTemplateData?.subject}
                </div>
                <div
                  className={`w-100 ${Style.certificateGenericTemplate} mb-2`}
                  style={{
                    color: foregroundTextColor,
                  }}
                >
                  {t("Email Template")}
                </div>

                <p
                  className={Style.secondaryText}
                  style={{
                    color: foregroundTextColor,
                    backgroundColor: foregroundBgColor,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: emailTemplateData ? emailTemplateData?.body : null,
                  }}
                ></p>
              </div>
            )}
            {view || view2 ? null : (
              <div className="row col-12">
                <div className="col-6 mt-3">
                  <span className="form-label">{t("Event")}</span>
                  <input
                    className={`form-input ${
                      errors.name?.message ? "is-invalid" : ""
                    }`}
                    style={{
                      background: interfaceBgColor,
                      color: foregroundTextColor,
                      outline: "none",
                      border: "none",
                    }}
                    type="text"
                    disabled={true}
                    placeholder={t("Event")}
                    autoComplete="off"
                    {...register("name")}
                  />
                  <p className="error color-red">{errors.name?.message}</p>
                </div>

                <div className={`col-6 mt-3`}>
                  <span className={Style.labelHeader}>
                    {t("Subject")}
                    <span style={{ color: "red" }}> *</span>
                  </span>
                  <input
                    className={`form-input ${
                      errors.subject?.message ? "is-invalid" : ""
                    }`}
                    style={{
                      background: interfaceBgColor,
                      color: foregroundTextColor,
                      outline: "none",
                      border: "none",
                    }}
                    type="text"
                    disabled={view ? true : false}
                    placeholder={t("Subject")}
                    autoComplete="off"
                    {...register("subject")}
                  />
                  <p className="error color-red">{errors.subject?.message}</p>
                </div>
              </div>
            )}
            {view || view2 ? null : (
              <div className="row col-12">
                <div className="col-6 mt-3">
                  <span className="form-label">
                    {t("Status")} <span style={{ color: "red" }}> *</span>
                  </span>
                  <Controller
                    name="status"
                    control={control}
                    defaultValue={""}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormControl sx={{ m: 0, width: "100%", mt: 1 }}>
                        {/* <InputLabel id="demo-multiple-name-label">{t("Status")}</InputLabel> */}
                        <Select
                          labelId="status"
                          label=""
                          defaultValue={""}
                          value={value}
                          disabled={enabledEmailsArray?.includes(
                            emailTemplateData?.select_event?.toString()
                          )}
                          style={{
                            color: interfaceTextColor,
                            background: interfaceBgColor,
                          }}
                          onChange={(event) => {
                            onChange(event);
                          }}
                          sx={{
                            height: "3.9em",
                            "& .MuiChip-root": {
                              // color: interfaceTextColor,
                              // background: interfaceBgColor,
                              color: primaryButtonText,
                              background: primaryButtonBg,
                            },
                          }}
                          MenuProps={{
                            sx: {
                              "&& .Mui-selected": {
                                // color: interfaceTextColor,
                                // background: interfaceBgColor,
                                color: light_text_color,
                                background: primary_color,
                              },
                              "&& .Mui-selected:hover": {
                                color: "black",
                                background: "skyblue",
                              },
                            },
                          }}
                          displayEmpty
                          renderValue={(selected) => {
                            // setSelectedLimit(selected);
                            return (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {(selected && (
                                  <Chip key={selected} label={selected} />
                                )) || (
                                  <span style={{ color: "hsl(0, 0%, 50%)" }}>
                                    Select Status
                                  </span>
                                )}
                              </Box>
                            );
                          }}
                        >
                          {statuses.map((row) => (
                            <MenuItem
                              value={row.value}
                              key={row.key}
                              className="d-flex"
                            >
                              <Radio
                                color="default"
                                sx={{
                                  color:
                                    getValues() && getValues().status
                                      ? (getValues()?.status as any).indexOf(
                                          row.value
                                        ) > -1
                                        ? "white"
                                        : ""
                                      : "",
                                }}
                                checked={
                                  getValues() && getValues().status
                                    ? (getValues()?.status as any).indexOf(
                                        row.value
                                      ) > -1
                                    : false
                                }
                                name="radio-buttons"
                              />
                              <div> {row.value}</div>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <p className="error color-red">{errors.status?.message}</p>
                </div>
              </div>
            )}
            {view ? null : (
              <div className="col-12 mb-4 mt-3">
                <span className="form-label">
                  {t("Description")}
                  <span className="color-red"> *</span>
                </span>
                {visible && (
                  <Editor
                    setCertificateData={setCertificateData}
                    prviousData={certificateEditData}
                  />
                )}
                <p key="error_cert_mess" className="error color-red">
                  {errors?.certificate_data?.message}
                </p>

                {view ? null : (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>
                      <InfoIcon
                        style={{ fontSize: "15px", marginRight: "5px" }}
                      />
                      {t("Dynamically Variable")}:
                    </div>
                    <br />
                    {emailTemplateData &&
                      emailTemplateData?.body_keys
                        ?.split(",")
                        .map((i: any, key: any) => (
                          <span key={key}>
                            &#123;{t(i)}&#125;{" "}
                            <IconButton
                              onClick={() =>
                                handleCopyVariable(i, "{" + i + "}")
                              }
                            >
                              <FileCopyIcon />
                            </IconButton>
                          </span>
                        ))}
                    <br />
                    <br />
                  </div>
                )}
              </div>
            )}
            <div className={`col-12 row`}>
              {view || view2 ? null : (
                <div className="d-flex flex-wrap gap-3 w-100 px-3 mt-3 align-items-center justify-content-between">
                  <div></div>

                  <div className="d-flex justify-content-center align-content-center ">
                    <span className="mx-4">
                      <button
                        className={`btn ${Style.cancelBtn2}`}
                        style={{
                          height: "3.1em",
                          backgroundColor: primaryButton,
                          color: primaryButtonText,
                          border: "0px solid #fff",
                        }}
                        type={"submit"}
                        onClick={() => {}}
                      >
                        <span
                          className={`p-1 ${Style.hThree} ${Style.buttonFont}`}
                          style={{
                            color: primaryButtonText,
                          }}
                        >
                          {id ? t("Update") : t("Save")}
                        </span>
                      </button>
                    </span>
                    <CancelButton
                      size="small"
                      outlined={true}
                      onClick={() => {
                        navigate("/email-template-management");
                      }}
                      textColor=""
                      outlineColor=""
                      text={view ? t("Back") : t("Cancel")}
                      className={``}
                      bg={""}
                    ></CancelButton>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default memo(AddEmailNotification);
