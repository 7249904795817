import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { RootState, useAppDispatch } from "../../store/store";
import Style from "./DetailsGroup.module.scss";

import "react-phone-input-2/lib/style.css";

import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Modal, Typography } from "@mui/material";
import SubmitButton from "../../components/SubmitButton";

import { AsyncPaginate } from "react-select-async-paginate";
import { updateGroupMember } from "../../services/groupManagementService";

import { useTranslation } from "react-multi-lang";
import CancelButton from "../../components/CancelButton";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import axiosPrivate from "../../lib/axiosPrivate";
import { defaultThemeColor, light_text_color, primary_color } from "../../utils/colors_values";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #fff",
  boxShadow: 24,
  p: 4,
};

const schema = yup
  .object({
    user_ids: yup
      .array()
      .required("Member is required")
      .min(1, "At least one member must be required"),
  })
  .required();

function AddMember() {
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const t = useTranslation();
  const { id } = useParams();
  const lang = useCompanyLanguage();

  const dispatch = useAppDispatch();
  const detail = useSelector((state: RootState) => state.groupManagementReducer.detailData);
  const members = useSelector((state: RootState) => state.groupManagementReducer.membersData);
  const Submit = (payload: any) => {
    let ids: any[] = [];
    payload?.user_ids.map((row: any) => {
      ids.push(row.value);
    });
    const payloadData = {
      user_ids: ids,
    };
    setOpen(false);
    dispatch(updateGroupMember(Number(id), payloadData));
  };
  const isLoading = useSelector((state: RootState) => state.loaderReducer.isLoading);
  const loadOptions = async (searchQuery: any, loadedOptions: any, { page }: any) => {
    const response = await axiosPrivate.get(
      `accounts/users/?page=${page}&page_size=100&search=${searchQuery}`
    );
    const data = response.data;
    let Ids: any = [];
    members?.results?.map((row: any) => {
      Ids.push(String(row?.user?.id));
    });

    const filtered = data?.results?.filter((row: any) => {
      return !Ids.includes(String(row?.id));
    });
    const options = filtered?.map((row: any) => {
      return {
        label: row?.first_name + " " + row?.last_name + " (" + row.email + ")" || "N/A",
        value: row.id,
      };
    });

    const users = {
      options: options || [],
      hasMore: data?.next ? true : false,
      additional: {
        page: searchQuery ? 1 : page + 1,
      },
    };

    return users;
  };

  const handleAddNew = () => {
    let defaultValues = {};
    reset({ ...defaultValues });
    setOpen(true);
  };

  const interfaceTextColor =
    useColor("interface_foreground_text_color") ||
    defaultThemeColor.interface_foreground_text_color;

  const foregroundBgColor =
    useColor("interface_foreground_color") || defaultThemeColor.interface_foreground_color;
  const foregroundTextColor =
    useColor("interface_foreground_text_color") ||
    defaultThemeColor.interface_foreground_text_color;

  const primaryButton = useColor("button_primary_text") || defaultThemeColor.button_primary_text;
  const primaryButtonBg = useColor("button_primary") || defaultThemeColor.button_primary;

  return (
    <div>
      {!isLoading && (
        <Button
          variant="text"
          color="inherit"
          className={Style.addMemberBtn}
          endIcon={
            <AddIcon
              style={{
                fontSize: 16,
                color: foregroundTextColor,
                marginRight: lang === "ar" ? "10px" : "0px",
              }}
            />
          }
          style={{
            fontSize: 13,
            color: foregroundTextColor,
            textTransform: "capitalize",
          }}
          onClick={() => handleAddNew()}
        >
          {t("Add New Member")}
        </Button>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ background: foregroundBgColor, border: "none" }}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{
              color: interfaceTextColor,
              textAlign: lang === "ar" ? "right" : "left",
            }}
          >
            {t("Add New Member")}
          </Typography>
          <form
            onSubmit={handleSubmit(Submit)}
            autoComplete="off"
            className="mt-4"
            id="modal-modal-descriptio"
            style={{
              background: foregroundBgColor,
              border: "none",
              direction: lang === "ar" ? "rtl" : "unset",
            }}
          >
            <span className="form-label" style={{ color: interfaceTextColor }}>
              {t("Select Members")}
            </span>
            <Controller
              control={control}
              name="user_ids"
              render={({ field: { value, onChange, ref } }) => (
                <AsyncPaginate
                  // id="companyId"
                  loadOptions={loadOptions}
                  onChange={onChange}
                  selectRef={ref}
                  value={value}
                  getOptionValue={(option: any) => option.value}
                  getOptionLabel={(option: any) => option.label}
                  className="add-member-select"
                  additional={{
                    page: 1,
                  }}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused ? primary_color : "",
                      color: state.isFocused ? light_text_color : "",
                    }),
                    valueContainer: (provided, state) => ({
                      ...provided,
                      height: "4em",
                      overflow: "scroll",
                    }),
                    multiValue: (baseStyles) => ({
                      ...baseStyles,
                      backgroundColor: primaryButtonBg,
                    }),
                    multiValueLabel: (baseStyles) => ({
                      ...baseStyles,
                      color: primaryButton,
                    }),
                    multiValueRemove: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: primaryButtonBg,
                      color: primaryButton,
                      ":hover": {
                        backgroundColor: primaryButtonBg,
                        color: primaryButton,
                      },
                    }),
                  }}
                  isMulti
                  isClearable
                  isSearchable={true}
                  placeholder={t("Select Members")}
                  debounceTimeout={500}
                />
              )}
            />
            <span className="color-red">{errors.user_ids?.message}</span>

            <div className="d-flex gap-3 align-items-center float-end">
              <SubmitButton label={t("Update")} />
              {/* <button
                type="button"
                className={`btn mt-5 ${Style.cancelBtn}`}
                style={{
                  background: useColor("button_secondary") || defaultThemeColor.button_secondary,
                  color:
                    useColor("button_secondary_text") || defaultThemeColor.button_secondary_text,
                  border: "none",
                }}
                onClick={handleClose}
              >
                {t("Cancel")}
              </button> */}
              <CancelButton
                size="small"
                outlined={true}
                onClick={handleClose}
                textColor=""
                outlineColor=""
                text={t("Cancel")}
                className={` mt-5`}
                bg={""}
              ></CancelButton>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}

export default AddMember;
