/* eslint-disable react-hooks/exhaustive-deps */
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import DataTable from "../../../components/table/DataTable";
import { RootState, useAppDispatch } from "../../../store/store";
import Style from "./AdminPermissionList.module.scss";

import moment from "moment";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useConfirm } from "material-ui-confirm";
import TableFilters from "../../../components/table/TableFilters";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../../store/reducers/alertMessageReducer";

import { useTranslation } from "react-multi-lang";
import useCompanyLanguage from "../../../hooks/language";
import useColor from "../../../hooks/useColor";
import DeleteSVG from "../../../icons/DeleteSVG";
import EditSVG from "../../../icons/EditSVG";
import ViewSVGAction from "../../../icons/ViewSVGAction";
import {
  bulkDeleteAdminGroups,
  deleteGroupPermission,
  getGroupPermissions,
  getWholeGroupPermissions,
} from "../../../services/adminPermissionService";
import {
  setPage,
  setPerPage,
  setSearch,
  setWholeData,
} from "../../../store/reducers/adminPermissionReducer";
import { defaultThemeColor } from "../../../utils/colors_values";
import { confirmProps } from "../../../utils/confirmProps";

const AdminPermissionList = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const [pageNum, setPageNum] = useState(Number(searchParams.get("page") || 1));
  const [perPageNum, setPerPageNum] = useState(
    Number(searchParams.get("perpage")) || 10
  );
  const [pageSearch, setPageSearch] = useState(
    searchParams.get("search") || ""
  );
  const [list, setList] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<any[]>([]);
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;
  const buttonPrimaryBg =
    useColor("button_primary") || defaultThemeColor.button_primary;
  const dataList = useSelector(
    (state: RootState) => state.adminPermissionReducer.data
  );
  const dataWholeList = useSelector(
    (state: RootState) => state.adminPermissionReducer.wholedata
  );
  const buttonPrimaryText =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;

  const urlWithQueryString = (
    pageVal = Number(pageNum),
    perPageVal = Number(perPageNum),
    searchVal = String(pageSearch)
  ) => {
    let object: any = {};
    if (pageVal) {
      object.page = pageVal;
    }
    if (perPageVal) {
      object.perpage = perPageVal;
    }
    if (searchVal) {
      object.search = searchVal;
    }

    const objString = "?" + new URLSearchParams(object).toString();
    navigate(`/admin-permission${objString}`);
  };

  useEffect(() => {
    getPermissions();
  }, [
    searchParams.get("page"),
    searchParams.get("perpage"),
    searchParams.get("search"),
  ]);
  const getPermissions = () => {
    dispatch(setPage(Number(searchParams.get("page") || 1)));
    dispatch(setPerPage(Number(searchParams.get("perpage") || 10)));
    dispatch(setSearch(String(searchParams.get("search"))));

    let query: any = {};
    query.page = Number(searchParams.get("page") || 1);
    query.page_size = Number(searchParams.get("perpage") || 10);
    query.search = String(searchParams.get("search") || "");

    dispatch(getGroupPermissions(query));
  };
  useEffect(() => {
    modifyRows();
  }, [dataList]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      setSelectedCheckBox([]);
      getPermissions();
    }
  }, [alertMessage]);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setSelectedCheckBox([]);
    setPageNum(page);
    urlWithQueryString(Number(page), Number(perPageNum), pageSearch);
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPageNum(1);
    setSelectedCheckBox([]);
    setPerPageNum(rowsPerPage);
    urlWithQueryString(1, Number(rowsPerPage), pageSearch);
  };
  const handleChangeSearch = (val: string) => {
    setPageNum(1);
    setPageSearch(String(val));
    setPerPageNum(Number(10));
    urlWithQueryString(1, perPageNum, String(val));
  };

  const handleDeleteAction = (row: any) => {
    confirm({
      title: t("Are you sure!"),
      description: `${t("Do you want to delete")} ${row?.name} ${t(
        "group permission"
      )}?`,
      ...confirmProps,
    })
      .then(() => {
        dispatch(deleteGroupPermission(row?.id));
      })
      .catch((e) => {
        // console.log(e);
      });
  };
  const modifyRows = () => {
    let f_rows: any = [];
    dataList?.results?.forEach(
      (
        r: {
          id: string;
          name: string;
          created_at: string;
          user_count: number;
          permissions: any[];
        },
        i: number
      ) => {
        f_rows = [
          ...f_rows,
          {
            id: r.id,
            name: <>{r.name}</>,
            no_of_members: r.user_count || 0,
            created_at: (
              <div style={{ textAlign: lang === "ar" ? "center" : "unset" }}>
                {moment(new Date(r.created_at)).format("MMM Do, YYYY") || ""}
              </div>
            ),
            permissions: (
              <>
                <div>
                  {r?.permissions?.[0] ? r?.permissions?.[0]?.name : ""}
                  {r?.permissions?.[1]
                    ? ", " + r?.permissions?.[1]?.name
                    : ""}{" "}
                  {r?.permissions?.length > 2 && (
                    <span
                      style={{
                        background: buttonPrimaryBg,
                        color: buttonPrimaryText,
                        padding: "5px",
                        borderRadius: "6px",
                      }}
                    >
                      {"+" + (r?.permissions?.length - 2)}
                    </span>
                  )}
                </div>
              </>
            ),

            actions: (
              <>
                <Link
                  to={`/admin-permission/detail/${r.id}`}
                  className="btn-link"
                >
                  <ViewSVGAction />
                </Link>{" "}
                <Link
                  to={`/admin-permission/detail/${r.id}`}
                  className="btn-link"
                >
                  <EditSVG />
                </Link>
                <button
                  className="btn-link"
                  type="button"
                  style={{ marginLeft: "-4px" }}
                  onClick={() => {
                    handleDeleteAction(r);
                  }}
                >
                  <DeleteSVG color="red" />
                </button>
              </>
            ),
          },
        ];
      }
    );
    setList(f_rows);
  };

  const AddGroup = () => {
    const handleAddNewPermission = () => {
      navigate("/admin-permission/add-new");
    };

    return (
      <div className="d-flex flex-row gap-3">
        <Button
          variant="text"
          color="inherit"
          endIcon={
            <AddIcon
              style={{
                fontSize: 16,
                color:
                  useColor("interface_background_text_color") ||
                  defaultThemeColor.interface_background_text_color,
                marginRight: lang === "ar" ? "10px" : "0px",
              }}
            />
          }
          style={{
            fontSize: 13,
            color:
              useColor("interface_background_text_color") ||
              defaultThemeColor.interface_background_text_color,
            textTransform: "capitalize",
          }}
          onClick={() => handleAddNewPermission()}
        >
          {t("Add New")}
        </Button>
      </div>
    );
  };
  const bulkDelete = (ids: any) => {
    confirm({
      title: t("Are you sure!"),
      description: t(
        `Do you want to delete selected admin permission's groups?`
      ),
      ...confirmProps,
    })
      .then(() => {
        dispatch(bulkDeleteAdminGroups(ids));
        setSelectedCheckBox([]);
      })
      .catch(() => {
        setSelectedCheckBox([]);
      });
  };

  const handleClicked = () => {
    getWholeAdminPermissions();
  };

  const getWholeAdminPermissions = () => {
    if (dataList)
      dispatch(
        getWholeGroupPermissions({
          search: "",
          page: 1,
          page_size: dataList?.count,
        })
      );
  };

  return (
    <>
      <div className={Style.pageStart}>
        <Header
          page={t("Admin Permission's Group")}
          mainHead={t("Admin Management / Admin Permissions")}
        />
        <TableFilters
          dataList={dataList}
          itemPerPage={Number(searchParams.get("perpage") || 10)}
          setItemPerPage={handleChangeRowsPerPage}
          selectedCheckBox={selectedCheckBox}
          bulkDelete={bulkDelete}
          setSearchTable={handleChangeSearch}
          createButton={<AddGroup />}
          handle="groups"
          isCheckboxes={true}
          exportCSV={dataList?.count ? true : false}
          setParentData={() => dispatch(setWholeData({}))}
          handleClicked={handleClicked}
          rows={dataWholeList?.results?.map((r: any, i: any) => ({
            name: r.name,
            no_of_members: r.user_count || 0,
            created_at:
              moment(new Date(r.created_at)).format("MMM Do, YYYY") || "",
            permissions: r?.permissions?.map((p: any) => p.name),
          }))}
          headers={["Name", "No of Members", "Created at", "Permissions"]}
          filename={"admin_permissions_list.csv"}
        />
        <div className={Style.customTableContainer}>
          <DataTable
            columnData={[
              {
                id: "name",
                name: t("Name"),
                enableSort: true,
                align: lang === "ar" ? "center" : "left",
                disablePadding: "checkbox",
              },

              {
                id: "no_of_members",
                name: t("No of Members"),
                enableSort: true,
                align: lang === "ar" ? "center" : "left",
                disablePadding: "normal",
              },

              {
                id: "date_created",
                name: t("Date Created"),
                enableSort: true,
                align: lang === "ar" ? "center" : "left",
                disablePadding: "none",
              },

              {
                id: "permissions",
                name: t("Permissions"),
                enableSort: true,
                align: "left",
                disablePadding: "none",
              },
              {
                id: "actions",
                name: t("Actions"),
                enableSort: false,
                align: "center",
                disablePadding: "none",
              },
            ]}
            rows={list}
            isCheckboxes={true}
            selectedRowsPerPage={Number(
              searchParams.get("perpage") || 10
            ).toString()}
            totalRecordCount={Number(dataList.count)}
            handleChangePageParent={handleChangePage}
            pagenum={Number(searchParams.get("page") || 1)}
            handleChangeRowsPerPageParent={handleChangeRowsPerPage}
            selectedCheckBox={selectedCheckBox}
            setSelectedCheckBox={setSelectedCheckBox}
          />
        </div>
      </div>
    </>
  );
};

export default AdminPermissionList;
