function PlayButtonSVG(props: { color: string }) {
  return (
    <svg width="98" height="98" viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="98" height="98" rx="49" fill={props?.color ?? "white"} fillOpacity="0.2" />
      <path
        d="M63.7382 44.84L40.8921 29.84C40.0442 29.2825 39.0618 29 38.0769 29C37.2545 29 36.432 29.195 35.6806 29.5925C34.0306 30.4625 33 32.155 33 34V64C33 65.845 34.0306 67.5375 35.6806 68.4075C36.432 68.805 37.2545 69 38.0769 69C39.0618 69 40.0442 68.7175 40.8946 68.16L63.7408 53.16C65.1522 52.2325 66 50.6725 66 49C66 47.3275 65.1521 45.7675 63.7382 44.84Z"
        fill={props?.color ?? "white"}
      />
    </svg>
  );
}

export default PlayButtonSVG;
