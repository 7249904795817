/* eslint-disable eqeqeq */
import Style from "./CancelButton.module.scss";
import useColor from "../hooks/useColor";
import { defaultThemeColor } from "../utils/colors_values";

interface CancelButtonProps {
  size: string;
  outlined: boolean;
  textColor: string;
  outlineColor: string;
  text: string;
  onClick: any;
  className: string;
  bg: string;
  disabled?: any;
}

function CancelButton({
  size,
  outlined,
  textColor,
  outlineColor,
  text,
  onClick,
  className,
  bg,
  disabled = false,
}: CancelButtonProps) {
  const labelColour =
    useColor("button_primary") || defaultThemeColor.button_primary;

  return (
    <button
      disabled={disabled}
      className={`btn ${Style.cancelBtn} ${className} ${
        size == "large" ? Style.widthLarge : Style.widthSmall
      }`}
      style={{
        height: size == "large" ? "3.1em" : "3.1em",
        border: outlined
          ? `1.3px solid ${
              labelColour
                ? labelColour
                : outlineColor
                ? outlineColor
                : defaultThemeColor.button_primary
            }`
          : "",
        backgroundColor:
          useColor("button_secondary") || defaultThemeColor.button_secondary,
      }}
      type={"button"}
      onClick={onClick}
    >
      <span
        className={`p-1 ${Style.hThree} ${Style.buttonFont}`}
        style={{
          color:
            useColor("button_secondary_text") ||
            defaultThemeColor.button_secondary_text,
        }}
      >
        {text}
      </span>
    </button>
  );
}

export default CancelButton;
