import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CancelButton from "../../components/CancelButton";
import Header from "../../components/Header";
import SubmitButton from "../../components/SubmitButton";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import usePermission from "../../hooks/usePermission";
import {
  getSignUpFields,
  updateRegistrationForm,
} from "../../services/authService";
import { setAuthFailed, setSuccess } from "../../store/reducers/authReducer";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import PersonalForm from "./PersonalForm";
import Style from "./Registration.module.scss";

const Registration = () => {
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const editUpdatePermission = usePermission("change_dynamicformfields");
  const formFields = useSelector(
    (state: RootState) => state.authReducer.signUpFields
  );
  const error = useSelector((state: any) => state.authReducer.error);
  const success = useSelector((state: any) => state.authReducer.success);
  const isLoading = useSelector((state: any) => state.loaderReducer.isLoading);
  const [feilds, setFields] = useState<any>([]);
  const [postData, setPostData] = useState<any>([]);
  const [activeKey, setActiveKey] = useState("signup-form");

  useEffect(() => {
    dispatch(getSignUpFields());
  }, [dispatch]);
  useEffect(() => {
    setFields(formFields);
    setPostData(formFields);
  }, [formFields]);

  const handleChangeIsMandatory = (event: any, id: Number) => {
    const newState = postData.map((obj: any) =>
      obj.id === id ? { ...obj, is_mandatory: event.target.checked } : obj
    );
    setPostData(newState);
  };
  const handleChangeIsDisable = (event: any, id: Number) => {
    const newState = postData.map((obj: any) =>
      obj.id === id ? { ...obj, is_disable: event.target.checked } : obj
    );
    setPostData(newState);
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const newState1 = postData.map((obj: any) =>
      obj.field_label == "company_business_name" ||
      obj.field_label == "email_address" ||
      obj.field_label == "password" ||
      obj.field_label == "confirm_password"
        ? { ...obj, is_disable: false, is_mandatory: true }
        : obj
    );

    dispatch(updateRegistrationForm(newState1));
  };
  useEffect(() => {
    if (error) {
      toast.error(String(error), {
        toastId: String(error),
      });
      dispatch(setAuthFailed(null));
    }
    if (success) {
      toast.success(String(success), {
        toastId: String(success),
      });
      dispatch(setSuccess(null));
    }
  }, [error, success, dispatch]);
  const handleCancel = () => {
    dispatch(getSignUpFields());
  };

  const interfaceForegroundTextColor =
    useColor("interface_foreground_text_color") ||
    defaultThemeColor.interface_foreground_text_color;

  const buttonSecondary =
    useColor("button_secondary") || defaultThemeColor.button_secondary;
  const buttonSecondaryText =
    useColor("button_secondary_text") ||
    defaultThemeColor.button_secondary_text;

  const activeTabStyles = {
    color:
      useColor("interface_foreground_text_color") ||
      defaultThemeColor.interface_foreground_text_color,
    background:
      useColor("interface_foreground_color") ||
      defaultThemeColor.interface_foreground_color,
  };
  const handleTabSelect = (key: any) => {
    setActiveKey(key);
  };
  return (
    <div className={Style.manageSpace}>
      <Header
        page={t("Registration Form Management")}
        mainHead={t("User Management")}
      />
      <div
        className={Style.formStart}
        style={{
          background:
            useColor("interface_foreground_color") ||
            defaultThemeColor.interface_foreground_color,
        }}
      >
        <Tabs
          defaultActiveKey="signup-form"
          id="uncontrolled-tab-example"
          className={`"mb-3 mt-5" ${Style.tabsStart}`}
          onSelect={handleTabSelect}
          activeKey={activeKey}
        >
          <Tab
            eventKey="signup-form"
            title={t("Sign up Form")}
            style={
              activeKey === "signup-form" ? activeTabStyles : activeTabStyles
            }
          >
            <div className="mt-5 ">
              <h2
                style={{
                  color:
                    useColor("interface_foreground_text_color") ||
                    defaultThemeColor.interface_foreground_text_color,
                }}
              >
                {t("Sign up Form")}
              </h2>
              <form onSubmit={handleSubmit}>
                <div className="row mb-2">
                  <div className="col-6"></div>
                  <div
                    className="col-3 text-center"
                    style={{
                      color:
                        useColor("interface_foreground_text_color") ||
                        defaultThemeColor.interface_foreground_text_color,
                    }}
                  >
                    {t("Mandatoy")}
                  </div>
                  <div
                    className="col-3 text-center"
                    style={{
                      color:
                        useColor("interface_foreground_text_color") ||
                        defaultThemeColor.interface_foreground_text_color,
                    }}
                  >
                    {t("Disable")}
                  </div>
                </div>
                {feilds?.map((row: any) => {
                  return (
                    <div className="row mb-5" key={row.id}>
                      <div
                        className="col-6"
                        style={{
                          fontSize: "16px",
                          lineHeight: "22px",
                          fontFamily: "dmSans",
                          fontWeight: "400",
                        }}
                      >
                        <span
                          style={{
                            color: interfaceForegroundTextColor,
                          }}
                        >
                          {t(row.field_name)}
                          {/* <span className="color-red">*</span> */}
                        </span>
                      </div>
                      <div className="col-3">
                        <div className={Style.checkStart}>
                          {row.field_label === "company_business_name" ||
                          row.field_label === "email_address" ||
                          row.field_label === "password" ||
                          row.field_label === "confirm_password" ? (
                            <Checkbox
                              defaultChecked={true}
                              disabled={true}
                              onChange={(e) => {
                                handleChangeIsMandatory(e, row?.id);
                              }}
                            />
                          ) : (
                            <Checkbox
                              defaultChecked={row.is_mandatory}
                              onChange={(e) => {
                                handleChangeIsMandatory(e, row?.id);
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-3">
                        <div className={Style.checkStart}>
                          {row.field_label === "company_business_name" ||
                          row.field_label === "email_address" ||
                          row.field_label === "password" ||
                          row.field_label === "confirm_password" ? (
                            <Checkbox
                              defaultChecked={false}
                              disabled={true}
                              onChange={(e) => {
                                handleChangeIsDisable(e, row?.id);
                              }}
                            />
                          ) : (
                            <Checkbox
                              defaultChecked={row.is_disable}
                              onChange={(e) => {
                                handleChangeIsDisable(e, row?.id);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
                {editUpdatePermission && (
                  <div
                    className={`d-flex gap-3 ${Style.buttuns}`}
                    style={{
                      justifyContent: lang === "ar" ? "flex-start" : "flex-end",
                    }}
                  >
                    <SubmitButton label={t("Save")} marginTop={false} />

                    {/* <button
                      type="button"
                      onClick={handleCancel}
                      disabled={isLoading}
                      style={{
                        color: buttonSecondaryText,
                        background: buttonSecondary,
                      }}
                      className={`${Style.btn} ${Style.btnSecond}`}
                    >
                      {t("Cancel")}
                    </button> */}
                    <CancelButton
                      size="small"
                      outlined={true}
                      onClick={handleCancel}
                      textColor="#2196F3"
                      outlineColor=""
                      text={t("Cancel")}
                      bg={"rgba(33, 150, 243, 0.04)"}
                      className={``}
                    ></CancelButton>
                  </div>
                )}
              </form>
            </div>
          </Tab>
          <Tab
            eventKey="personal-detail-form"
            title={t("Personal detail form")}
            style={
              activeKey === "personal-detail-form"
                ? activeTabStyles
                : activeTabStyles
            }
          >
            <PersonalForm />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Registration;
