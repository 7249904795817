// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CategoryModal_leftSide__yKSUD {
  width: 50%;
}
@media only screen and (max-width: 767px) {
  .CategoryModal_leftSide__yKSUD {
    width: 100%;
  }
}

.CategoryModal_editButton__QjQ4r {
  width: 38px;
  height: 38px;
  border-radius: 50% !important;
  padding: 3px;
}

.CategoryModal_cancelBtn__v3S46 {
  min-width: 150px;
  min-height: 42px;
}

.CategoryModal_addMemberBtn__3-UTA {
  text-transform: none;
}

.CategoryModal_col6__9Pvmu {
  width: 98%;
  min-width: 16em;
  margin-right: 2em;
  margin-left: 0;
}
@media only screen and (max-width: 1400px) {
  .CategoryModal_col6__9Pvmu {
    width: 96%;
    margin-right: 0.9em;
    margin-left: 0;
  }
}
@media only screen and (max-width: 720px) {
  .CategoryModal_col6__9Pvmu {
    width: 95%;
    margin-right: 1em;
    margin-left: 0;
  }
}

.CategoryModal_labelHeader__m32iE {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 1.8em;
}
@media only screen and (max-width: 900px) {
  .CategoryModal_labelHeader__m32iE {
    font-size: 11px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/category/components/CategoryModal.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;AACE;EAHF;IAII,WAAA;EAEF;AACF;;AACA;EACE,WAAA;EACA,YAAA;EACA,6BAAA;EACA,YAAA;AAEF;;AACA;EACE,gBAAA;EACA,gBAAA;AAEF;;AAKA;EACE,oBAAA;AAFF;;AAKA;EACE,UAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAFF;AAIE;EANF;IAOI,UAAA;IACA,mBAAA;IACA,cAAA;EADF;AACF;AAGE;EAZF;IAaI,UAAA;IACA,iBAAA;IACA,cAAA;EAAF;AACF;;AAGA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,iBAAA;AAAF;AACE;EAPF;IAQI,eAAA;EAEF;AACF","sourcesContent":[".leftSide {\n  width: 50%;\n\n  @media only screen and (max-width: 767px) {\n    width: 100%;\n  }\n}\n\n.editButton {\n  width: 38px;\n  height: 38px;\n  border-radius: 50% !important;\n  padding: 3px;\n}\n\n.cancelBtn {\n  min-width: 150px;\n  min-height: 42px;\n  // border: 1px solid #0089cf !important;\n  // border-radius: 4px !important;\n  // box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),\n  //   0px 3px 1px -2px rgba(0, 0, 0, 0.2) !important;\n}\n\n.addMemberBtn {\n  text-transform: none;\n}\n\n.col6 {\n  width: 98%;\n  min-width: 16em;\n  margin-right: 2em;\n  margin-left: 0;\n\n  @media only screen and (max-width: 1400px) {\n    width: 96%;\n    margin-right: 0.9em;\n    margin-left: 0;\n  }\n\n  @media only screen and (max-width: 720px) {\n    width: 95%;\n    margin-right: 1em;\n    margin-left: 0;\n  }\n}\n\n.labelHeader {\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 22px;\n  letter-spacing: 0px;\n  text-align: left;\n  margin-top: 1.8em;\n  @media only screen and (max-width: 900px) {\n    font-size: 11px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leftSide": `CategoryModal_leftSide__yKSUD`,
	"editButton": `CategoryModal_editButton__QjQ4r`,
	"cancelBtn": `CategoryModal_cancelBtn__v3S46`,
	"addMemberBtn": `CategoryModal_addMemberBtn__3-UTA`,
	"col6": `CategoryModal_col6__9Pvmu`,
	"labelHeader": `CategoryModal_labelHeader__m32iE`
};
export default ___CSS_LOADER_EXPORT___;
