/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { defaultThemeColor } from "../utils/colors_values";

const useColor = (colorType: string) => {
  const cmpanyInfo = useSelector((state: any) => state.profileManagementReducer?.company_form_data);

  const [color, setColor] = useState<string>();

  useEffect(() => {
    setColor(cmpanyInfo?.[colorType] || defaultThemeColor?.[colorType]);
  }, [cmpanyInfo]);

  return color;
};

export default useColor;
