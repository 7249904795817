function VideoSvg(props: { color: string }) {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3122 7.03125L8.81219 4.03125C8.72742 3.9747 8.62889 3.94223 8.5271 3.93732C8.42532 3.93241 8.32412 3.95524 8.2343 4.00337C8.14448 4.05151 8.06943 4.12313 8.01716 4.2106C7.96488 4.29807 7.93735 4.3981 7.9375 4.5V10.5C7.93735 10.6019 7.96488 10.7019 8.01716 10.7894C8.06943 10.8769 8.14448 10.9485 8.2343 10.9966C8.32412 11.0448 8.42532 11.0676 8.5271 11.0627C8.62889 11.0578 8.72742 11.0253 8.81219 10.9688L13.3122 7.96875C13.3895 7.91744 13.453 7.84778 13.4969 7.76599C13.5408 7.6842 13.5638 7.59282 13.5638 7.5C13.5638 7.40718 13.5408 7.3158 13.4969 7.23401C13.453 7.15222 13.3895 7.08256 13.3122 7.03125ZM9.0625 9.44906V5.55094L11.9856 7.5L9.0625 9.44906ZM18.25 0.9375H1.75C1.4019 0.9375 1.06806 1.07578 0.821922 1.32192C0.575781 1.56806 0.4375 1.9019 0.4375 2.25V12.75C0.4375 13.0981 0.575781 13.4319 0.821922 13.6781C1.06806 13.9242 1.4019 14.0625 1.75 14.0625H18.25C18.5981 14.0625 18.9319 13.9242 19.1781 13.6781C19.4242 13.4319 19.5625 13.0981 19.5625 12.75V2.25C19.5625 1.9019 19.4242 1.56806 19.1781 1.32192C18.9319 1.07578 18.5981 0.9375 18.25 0.9375ZM18.4375 12.75C18.4375 12.7997 18.4177 12.8474 18.3826 12.8826C18.3474 12.9177 18.2997 12.9375 18.25 12.9375H1.75C1.70027 12.9375 1.65258 12.9177 1.61742 12.8826C1.58225 12.8474 1.5625 12.7997 1.5625 12.75V2.25C1.5625 2.20027 1.58225 2.15258 1.61742 2.11742C1.65258 2.08225 1.70027 2.0625 1.75 2.0625H18.25C18.2997 2.0625 18.3474 2.08225 18.3826 2.11742C18.4177 2.15258 18.4375 2.20027 18.4375 2.25V12.75ZM19.5625 16.5C19.5625 16.6492 19.5032 16.7923 19.3977 16.8977C19.2923 17.0032 19.1492 17.0625 19 17.0625H1C0.850816 17.0625 0.707742 17.0032 0.602252 16.8977C0.496763 16.7923 0.4375 16.6492 0.4375 16.5C0.4375 16.3508 0.496763 16.2077 0.602252 16.1023C0.707742 15.9968 0.850816 15.9375 1 15.9375H19C19.1492 15.9375 19.2923 15.9968 19.3977 16.1023C19.5032 16.2077 19.5625 16.3508 19.5625 16.5Z"
        fill={props.color ?? "#2B3674"}
      />
    </svg>
  );
}

export default VideoSvg;
