/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const TrainerPermissionsArray = [
  "add_assessment",
  "change_assessment",
  "delete_assessment",
  "view_assessment",
  "view_question",
  "add_training",
  "change_training",
  "delete_training",
  "view_training",
  "add_module",
  "change_module",
  "delete_module",
  "view_module",
];
const usePermission = (permissionCode: string) => {
  const userInfo = useSelector((state: any) => state.authReducer.loginData);

  const permissionList = useSelector((state: any) => state.authReducer.permissionsList);
  const [permissionsList, setPermissionsList] = useState<any[]>([]);

  useEffect(() => {
    let permissions: any = [];
    permissionList?.map((row: any) => {
      permissions.push(row?.codename);
    });
    setPermissionsList(permissions);
  }, [permissionList]);

  if (userInfo?.is_superadmin && userInfo?.role?.toLowerCase() === "admin") {
    return true;
  } else if (userInfo?.role?.toLowerCase() === "trainer") {
    return TrainerPermissionsArray?.includes(permissionCode.toString());
  } else if (!userInfo?.is_superadmin && userInfo?.role?.toLowerCase() === "admin") {
    return permissionsList?.includes(permissionCode.toString());
  }
  return false;
};

export default usePermission;
