import "bootstrap/dist/js/bootstrap";
import "jquery";
import { ConfirmProvider } from "material-ui-confirm";
import React from "react";
import ReactDOM from "react-dom/client";
import { setDefaultLanguage, setDefaultTranslations } from "react-multi-lang";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import "./index.css";
import store from "./store/store";
import ar from "./translations/ar.json";
import en from "./translations/en.json";
setDefaultTranslations({ ar, en });

if (JSON.parse(localStorage.getItem("user_info") as any)?.preferred_language) {
  if (
    JSON.parse(localStorage.getItem("user_info") as any)?.preferred_language == "Arabic - العربية"
  )
    setDefaultLanguage("ar");
  else setDefaultLanguage("en");
} else setDefaultLanguage("en");

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <ToastContainer autoClose={5000} style={{ zIndex: 10010 }} />
      <ConfirmProvider
        defaultOptions={{
          confirmationButtonProps: {
            autoFocus: true,
            color: "error",
          },
          confirmationText: "Confirm",
          cancellationText: "Cancel",
        }}
      >
        <App />
      </ConfirmProvider>
    </React.StrictMode>
  </Provider>
);
