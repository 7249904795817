/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { PauseSharp } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useEffect } from "react";
import PlayButtonSVG from "../../icons/PlayButtonSvg";
import Style from "./ControlIcons.module.scss";

const ControlIcons = ({
  mainTitle,
  mainSubTitle,
  screenfull,
  playandpause,
  playing,
  rewind,
  fastForward,
  muting,
  muted,
  volumeChange,
  volumeSeek,
  volume,
  playRate,
  playerbackRate,
  fullScreenMode,
  onSeek,
  played,
  onSeekMouseUp,
  onSeekMouseDown,
  fullMovieTime,
  playedTime,
  seeking,
  videoRef,
}: {
  mainTitle?: any;
  mainSubTitle?: any;
  screenfull?: any;
  playandpause: any;
  playing: any;
  rewind: any;
  fastForward: any;
  muting: any;
  muted: any;
  volumeChange: any;
  volumeSeek: any;
  volume: any;
  playRate: any;
  playerbackRate: any;
  fullScreenMode: any;
  onSeek: any;
  played: any;
  onSeekMouseUp: any;
  onSeekMouseDown: any;
  fullMovieTime: any;
  playedTime: any;
  seeking: any;
  videoRef?: any;
}) => {
  const [activity, setActivity] = React.useState<any>();
  const [show, setShow] = React.useState<any>(true);

  useEffect(() => {
    if (activity)
      setTimeout(() => {
        if (activity) volumeChange({ newValue: activity });
      }, 100);
  }, [activity]);

  return (
    <div className={Style.controls__div}>
      <span className={Style.linearGradient} onClick={playandpause}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="start"
          style={{ paddingInline: 16, paddingTop: 16, paddingBottom: 8 }}
        >
          <Grid item>
            <div style={{ color: "white" }} className={Style.header}>
              {mainTitle}
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="start"
          style={{ paddingLeft: 16 }}
        >
          <Grid item>
            <div style={{ color: "white" }} className={Style.subTopic}>
              {mainSubTitle}
            </div>
          </Grid>
        </Grid>
      </span>

      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        alignContent={"center"}
        alignSelf={"center"}
      >
        {show && (
          <IconButton
            className={Style.controls__icons}
            style={{
              position: "absolute",
              top: screenfull ? "45%" : "37%",
            }}
            aria-label="reqind"
            onClick={playandpause}
          >
            {playing ? (
              <div
                style={{
                  width: "98px",
                  height: "98px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PauseSharp
                  fontSize="large"
                  style={{ color: "white", fontSize: 50 }}
                />
              </div>
            ) : (
              <PlayButtonSVG color="white" />
            )}
          </IconButton>
        )}
      </Grid>
    </div>
  );
};

export default ControlIcons;
