import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface InitState {
  data: any;
  emailByIdData: any;
}

export const initialState: InitState = {
  data: {},
  emailByIdData: {},
};

export const emailNotificationSlice = createSlice({
  name: "emailNotification",
  initialState,
  reducers: {
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setEmailByIdData: (state, { payload }) => {
      state.emailByIdData = payload;
    },
  },
});

export const { setData, setEmailByIdData } = emailNotificationSlice.actions;

export const emailNotificationSelector = (state: RootState) => state;

export default emailNotificationSlice.reducer;
