import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Chip, FormControl, MenuItem, OutlinedInput } from "@mui/material";
// import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import Radio from "@mui/material/Radio";
import Select from "@mui/material/Select";
import _ from "lodash";
import React, { memo, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import CancelButton from "../../components/CancelButton";
import CategoryLoadComponent from "../../components/CategoryLoad/CategoryLoad";
import Header from "../../components/Header";
import SubmitButton from "../../components/SubmitButton";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import {
  addModules,
  getModuleforsearch,
  getModules,
  getWholeModules,
} from "../../services/moduleService";
import {
  alertMessageSelector,
  setErr,
  setErrors,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { RootState, useAppDispatch, useAppSelector } from "../../store/store";
import {
  defaultThemeColor,
  light_text_color,
  primary_color,
} from "../../utils/colors_values";
import { Sentencify } from "../../utils/sentencify";
import AddAssessments from "./AddAssessments";
import Style from "./AddModule.module.scss";
import AssessmentDialog from "./AssessmentDialog";
import ChooseModuleFiles from "./ChooseModules";

const moduleType = [
  { key: "assessment", value: "Assessment" },
  { key: "video", value: "Video" },
  { key: "scorm", value: "SCORM" },
  { key: "pdf", value: "PDF" },
];

const moduleLevel = [
  { key: "beginner", value: "Beginner" },
  { key: "intermediate", value: "Intermediate" },
  { key: "advance", value: "Advance" },
];

const moduleStatus = [
  { key: "draft", value: "Draft" },
  { key: "approved", value: "Approved" },
];

const ITEM_HEIGHT = 24;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const schema = yup
  .object({
    name: yup.string().required("Module Name is required"),
    description: yup.string().required("Description is required"),
    status: yup.string().required("Status is required"),
    category: yup.object().required("Category is required"),
    // scorm: yup.string().optional(),
    type: yup.string().required("Type is required"),
    level: yup.string().required("Level is required"),
    file_name: yup
      .string()
      .when("type", {
        is: (value: any) => ["Video", "PDF"]?.includes(value),
        // is: "Video" || "PDF",
        then: () => yup.string().required("File name is required"),
      })
      .when("type", {
        is: (value: any) => ["Assessment", "SCORM"]?.includes(value),
        // is: "Assessment" || "SCORM",
        then: () => yup.string().optional(),
      }),
    // file: yup.string(),
    file: yup
      .mixed()
      .when("type", {
        is: (value: any) => ["Video", "SCORM", "PDF"]?.includes(value),
        // is: "Video" || "SCORM" || "PDF",
        then: () => yup.mixed().required("File is required"),
      })
      .when("type", {
        is: "Assessment",
        then: () => yup.mixed().optional(),
      }),
  })
  .required();

function AddModule() {
  const {
    reset,
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    setError,
    watch,
    clearErrors,
    formState: { errors },
    formState: { submitCount },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const [status, setStatus] = React.useState("");
  const [level, setLevel] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [scorm, setScorm] = useState("");
  const [pdf, setPdf] = useState("");
  const [video, setVideo] = useState("");
  const [type, setType] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedTypeAssessment, setSelectedTypeAssessment] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [defaultAssessmentRows, setDefaultAssessmentRows] = React.useState<any>(
    []
  );
  const [assessmentError, setAssessmentError] = useState<any>(false);
  const dataList = useAppSelector((state) => state.moduleReducer.data);
  const wholedataList = useAppSelector(
    (state) => state.moduleReducer.wholedetailerrors
  );

  const [selectedAssessmentRows, setSelectedAssessmentRows] =
    React.useState<any>([]);
  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  const states = useSelector(alertMessageSelector).alertMessageReducer;
  const { id } = useParams();
  const [assessmentQuestions, setAssessmentQuestions] = React.useState<any[]>(
    []
  );

  useEffect(() => {
    if (!selectedAssessmentRows?.count && selectedAssessmentRows?.count == 0) {
      setAssessmentQuestions([]);
      setParentCheckedButNotAddedQuestions([]);
      setAssessmentQuestions([]);
      setDefaultAssessmentRows([]);
      setSelectedAssessmentRows([]);
      setAssessmentQuestions([]);
    }
  }, [selectedAssessmentRows]);
  const [
    parentCheckedButNotAddedQuestions,
    setParentCheckedButNotAddedQuestions,
  ] = React.useState<any[]>([]);

  useEffect(() => {
    if (dataList && dataList?.count == 0) {
      dispatch(getModules({ page: 1, page_size: 10, search: "" }));
    }
    if (Object.keys(wholedataList).length == 0) {
      dispatch(
        getWholeModules({
          page_size: 1,
          page: 1,
          search: "",
          for: "errors",
        })
      );
    }
  }, []);

  const allowedFileTypes = {
    zip: {
      maxSize: 5242880,
      errorMessage: t("Please select a ZIP file less than 5MB."),
    },
    video: {
      maxSize: 5242880,
      errorMessage: t("Please select a video file less than 5MB."),
    },
    pdf: {
      maxSize: 5242880,
      errorMessage: t("Please select a PDF file less than 5MB."),
    },
  };

  useEffect(() => {
    if (dataList && Object.keys(dataList).length > 0) {
      if (dataList?.results?.find((i: any) => i.name == watch("name"))) {
        setError("name", {
          type: "server",
          message: "Module with this name already exists",
        });
        return;
      } else {
        clearErrors("name");
      }
    }
  }, [dataList, watch("name")]);

  const formSubmit = (payload: any) => {
    if (dataList && Object.keys(dataList).length > 0) {
      if (dataList?.results?.find((i: any) => i.name == payload?.name)) {
        setError("name", {
          type: "server",
          message: "Module with this name already exists",
        });
        return;
      }
    }
    if (
      assessmentQuestions.length == 0 &&
      selectedTypeAssessment?.toLowerCase() === "assessment"
    ) {
      toast.error(String("Assessment is required"), {
        toastId: String("Assessment is required"),
      });
      return;
    }
    validate();
    if (Object.keys(errors).length > 0) {
      return;
    }
    let finalModule: any = null;
    finalModule = {
      name: payload.name,
      description: payload.description,
      status:
        payload?.status?.toLowerCase() == "approved" ? "Approved" : "Draft",
      type: payload.type,
      level: payload.level,
      file_name: payload?.file_name || undefined,
      file: pdf,
      category: (payload?.category && payload?.category.value) || undefined,
      assessment:
        assessmentQuestions?.length > 0 ? assessmentQuestions[0]?.id : [],
    };

    dispatch(addModules(finalModule));
  };
  const [acceptFiles, setAcceptFiles] = useState<any>("");

  const validate = () => {
    if (
      wholedataList &&
      Object.keys(wholedataList).length > 0 &&
      watch("file_name") &&
      !(watch("file_name") == "")
    ) {
      if (
        wholedataList?.results?.find(
          (i: any) => i?.file_name == watch("file_name")
        )
      ) {
        setError("file_name", {
          type: "server",
          message: "Module file with this name already exists",
        });
        return;
      } else {
        clearErrors("file_name");
      }
    }
  };

  useEffect(() => {
    validate();
  }, [submitCount, watch("file_name")]);

  useEffect(() => {
    if (selectedTypeAssessment == "Assessment") {
      setAcceptFiles("");
      setValue("file", undefined);
      setValue("file_name", undefined);
      setIsModalOpen(true);
    } else if (selectedTypeAssessment?.toLowerCase() === "video") {
      setValue("file", undefined);
      setValue("file_name", undefined);
      setAcceptFiles("video/mp4, video/avi, video/mkv");
    } else if (selectedTypeAssessment?.toLowerCase() === "pdf") {
      setValue("file", undefined);
      setValue("file_name", undefined);
      setAcceptFiles("application/pdf");
    } else if (selectedTypeAssessment?.toLowerCase() === "scorm") {
      setAcceptFiles("application/zip");
      setValue("file", undefined);
      setValue("file_name", undefined);
    } else {
      setAcceptFiles("");
      setValue("file", undefined);
      setValue("file_name", undefined);
    }
  }, [selectedTypeAssessment]);

  useEffect(() => {
    clearErrors();
    setParentCheckedButNotAddedQuestions([]);
    setAssessmentQuestions([]);
    setDefaultAssessmentRows([]);
    setSelectedAssessmentRows([]);
    setAssessmentQuestions([]);
    setType(watch("type"));
  }, [watch("type")]);

  useEffect(() => {
    if (states?.errors) {
      if (states?.errors?.category?.length > 0) {
        toast.error(states?.errors?.category?.toString(), {
          toastId: states?.errors?.category?.toString(),
        });
        setError("category", {
          type: "server",
          message: states?.errors?.category?.toString(),
        });
      }
      if (states?.errors?.description?.length > 0) {
        toast.error(states?.errors?.description?.toString(), {
          toastId: states?.errors?.description?.toString(),
        });
        setError("description", {
          type: "server",
          message: states?.errors?.description?.toString(),
        });
      }
      if (states?.errors?.level?.length > 0) {
        toast.error(states?.errors?.level?.toString(), {
          toastId: states?.errors?.level?.toString(),
        });
        setError("level", {
          type: "server",
          message: states?.errors?.level?.toString(),
        });
      }
      if (states?.errors?.name?.length > 0) {
        toast.error(states?.errors?.name?.toString(), {
          toastId: states?.errors?.name?.toString(),
        });
        setError("name", {
          type: "server",
          message: states?.errors?.name?.toString(),
        });
      }
      if (states?.errors?.file?.length > 0) {
        toast.error(states?.errors?.file?.toString(), {
          toastId: states?.errors?.file?.toString(),
        });
        setError("file", {
          type: "server",
          message: states?.errors?.file?.toString(),
        });
      }
      if (states?.errors?.file_name?.length > 0) {
        toast.error(states?.errors?.file_name?.toString(), {
          toastId: states?.errors?.file_name?.toString(),
        });
        setError("file_name", {
          type: "server",
          message: states?.errors?.file_name?.toString(),
        });
      }
      if (states?.errors?.status?.length > 0) {
        toast.error(states?.errors?.status?.toString(), {
          toastId: states?.errors?.status?.toString(),
        });
        setError("status", {
          type: "server",
          message: states?.errors?.status?.toString(),
        });
      }

      if (states?.errors?.type?.length > 0) {
        toast.error(states?.errors?.type?.toString(), {
          toastId: states?.errors?.type?.toString(),
        });
        setError("type", {
          type: "server",
          message: states?.errors?.type?.toString(),
        });
      }
      if (states?.errors) {
        dispatch(setErrors(null));
      }
    }
    if (states?.error) {
      toast.error(String(states?.error), {
        toastId: String(states?.error),
      });
      dispatch(setErr(null));
    }

    if (states?.success) {
      toast.success(String(states?.success), {
        toastId: String(states?.success),
      });
      dispatch(setSuccess(null));
      navigate("/module/module-listing?page=1&perpage=10");
    }
  }, [states]);

  const handleClose = () => {
    setIsModalOpen(false);
    setOpen(false);
    // setValue("type", "");
    setType("");
    // setSelectedTypeAssessment("");
    // reset({ type: '' });
  };

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;

  useEffect(() => {
    if (
      wholedataList &&
      Object.keys(wholedataList).length > 0 &&
      !(wholedataList?.count == wholedataList?.results.length)
    ) {
      dispatch(
        getWholeModules({
          page_size: wholedataList?.count,
          page: 1,
          search: "",
          for: "errors",
        })
      );
    }
  }, [wholedataList]);

  useEffect(() => {
    if (watch("name")) debouncedOnChange();
  }, [watch("name")]);

  useEffect(() => {
    validate();
  }, [watch("file_name")]);
  // const primaryButtonColor = useColor("button_primary") || defaultThemeColor.button_primary;
  // const primaryTextColor = useColor("button_primary") || defaultThemeColor.button_primary;
  const getWhole = () => {
    // if (
    //   dataList &&
    //   Object.keys(dataList).length > 0 &&
    //   !(dataList?.count == dataList?.results.length)
    // )
    dispatch(
      getModuleforsearch({
        page_size: 10,
        page: 1,
        search: watch("name"),
      })
    );
  };

  const debouncedOnChange = _.debounce(getWhole, 1000);

  const primaryButton =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;
  const primaryButtonBg =
    useColor("button_primary") || defaultThemeColor.button_primary;

  useEffect(() => {
    if (
      selectedTypeAssessment &&
      selectedTypeAssessment?.toLowerCase() === "assessment"
    ) {
      setParentCheckedButNotAddedQuestions([]);
      setAssessmentQuestions([]);
      setSelectedAssessmentRows([]);
    }
  }, [selectedTypeAssessment]);

  useEffect(() => {
    if (
      selectedAssessmentRows &&
      selectedAssessmentRows?.results &&
      selectedAssessmentRows?.results?.length > 0
    ) {
      setParentCheckedButNotAddedQuestions(
        selectedAssessmentRows?.results || []
      );
    }
  }, [isModalOpen, open]);

  return (
    <>
      <Header
        page={t("Create New Module")}
        mainHead={t("Module")}
        backLink="/module/module-listing"
      />
      <div
        className={`${Style.background} d-flex flex-wrap`}
        style={{
          background:
            useColor("interface_foreground_color") ||
            defaultThemeColor.interface_foreground_color,
          color:
            useColor("interface_foreground_text_color") ||
            defaultThemeColor.interface_foreground_text_color,
        }}
      >
        <div className={`col-12 my-4 mb-0`}>
          <div
            className={`${Style.textHeader} mb-1`}
            style={{
              color:
                useColor("interface_foreground_text_color") ||
                defaultThemeColor.interface_foreground_text_color,
              textAlign: lang === "ar" ? "right" : "left",
              paddingRight: lang === "ar" ? "2rem" : "0rem",
            }}
          >
            {t("Add New")}
          </div>
        </div>
        <div className={`col-12 my-3 mb-0`}>
          <form id="myform" onSubmit={handleSubmit(formSubmit)}>
            <div className={`row col-lg-12 px-0 mx-0`}>
              <div className={`${Style.col6} px-0 mt-3`}>
                <span className={Style.labelHeader}>
                  {t("Name")}
                  <span style={{ color: "red" }}> *</span>
                </span>
                <input
                  className={`${Style.inputfields} ${Style.fieldFont} ${
                    errors.name ? "is-invalid" : ""
                  }`}
                  style={{
                    color: interfaceTextColor,
                    background: interfaceBgColor,
                  }}
                  type="text"
                  placeholder={t("Name")}
                  autoComplete="off"
                  {...register("name")}
                />
                <p className="color-red">
                  {Sentencify(errors.name?.message) || ""}
                </p>
              </div>
              <div className={`${Style.col6} px-0 mt-3`}>
                <span className={Style.labelHeader}>
                  {t("Category")}
                  <span style={{ color: "red" }}> *</span>
                </span>
                <CategoryLoadComponent
                  control={control}
                  parentName={"category"}
                ></CategoryLoadComponent>
                <p className="color-red">
                  {Sentencify(errors.category?.message) || ""}
                </p>
              </div>
            </div>

            <div className={`row col-12 px-0 mx-0 mb-4`}>
              <div className={`${Style.col6} px-0 mt-3`}>
                <span className={`${Style.labelHeader} mb-2`}>
                  {t("Type")}
                  <span className="color-red"> *</span>
                </span>
                <FormControl fullWidth sx={{ mt: 1.3 }}>
                  <Controller
                    name="type"
                    control={control}
                    defaultValue={""}
                    render={({ field }) => (
                      <FormControl>
                        <InputLabel id="experience_levels">
                          {t("Type")}
                        </InputLabel>
                        <Select
                          {...field}
                          labelId="type"
                          label="Type"
                          defaultValue={""}
                          // MenuComponent={(props: any) => (
                          //   <Menu
                          //     {...props}
                          //     style={{
                          //       color: interfaceTextColor,
                          //       background: interfaceBgColor,
                          //     }}
                          //   >
                          //     {React.Children.map(props.children, (child) =>
                          //       React.cloneElement(child, {
                          //         style: {
                          //           color: interfaceTextColor,
                          //           background: interfaceBgColor,
                          //         },
                          //       })
                          //     )}
                          //   </Menu>
                          // )}
                          sx={{
                            height: "3.9em",
                            color: interfaceTextColor,
                            background: interfaceBgColor,
                            "& .MuiChip-root": {
                              color: primaryButton,
                              background: primaryButtonBg,
                            },
                          }}
                          MenuProps={{
                            ...MenuProps,
                            sx: {
                              "&& .MuiPopover-paper": {
                                color: interfaceTextColor,
                                background: interfaceBgColor,
                              },
                              "&& .Mui-selected": {
                                color: light_text_color,
                                background: primary_color,
                              },
                              "&& .Mui-selected:hover": {
                                // color: "black",
                                // background: "skyblue",
                                color: light_text_color,
                                background: primary_color,
                              },
                            },
                          }}
                          renderValue={(selected) => {
                            setSelectedTypeAssessment(selected);

                            // if (selected === "Assessment") {
                            //   setIsModalOpen(true);
                            // } else {
                            //   setIsModalOpen(false);
                            // }
                            return (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected && (
                                  <Chip key={selected} label={selected} />
                                )}
                              </Box>
                            );
                          }}
                        >
                          {moduleType.map((row) => (
                            <MenuItem
                              value={row.value}
                              key={row.key}
                              className="d-flex"
                            >
                              <Radio
                                color="default"
                                sx={{
                                  color:
                                    getValues() && getValues().type
                                      ? (getValues()?.type as any).indexOf(
                                          row.value
                                        ) > -1
                                        ? "white"
                                        : ""
                                      : "",
                                }}
                                checked={
                                  getValues() && getValues().type
                                    ? (getValues()?.type as any).indexOf(
                                        row.value
                                      ) > -1
                                    : false
                                }
                                name="radio-buttons"
                              />
                              <div> {row.value}</div>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </FormControl>
                <p className="error color-red">
                  {Sentencify(errors.type?.message)}
                </p>
              </div>
              <div className={`${Style.col6} px-0 mt-3`}>
                <span className={Style.labelHeader}>
                  {t("Description")}
                  <span style={{ color: "red" }}> *</span>
                </span>
                <input
                  className={`${Style.inputfields} ${Style.fieldFont} ${
                    errors.description ? "is-invalid" : ""
                  }`}
                  style={{
                    color: interfaceTextColor,
                    background: interfaceBgColor,
                  }}
                  type="text"
                  placeholder={t("Description")}
                  autoComplete="off"
                  {...register("description")}
                />
                <p className="color-red">{errors.description?.message}</p>
              </div>
            </div>

            <div className={`row col-12 px-0 mx-0 mb-4`}>
              <div className={`${Style.col6} px-0`}>
                <span className={Style.labelHeader}>
                  {t("Level")}
                  <span style={{ color: "red" }}> *</span>
                </span>
                <FormControl sx={{ m: 0, width: "100%", mt: 1 }}>
                  <InputLabel id="demo-multiple-name-label">
                    {t("Level")}
                  </InputLabel>
                  <Controller
                    control={control}
                    defaultValue={level}
                    name="level"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Select
                        labelId="level"
                        id="level"
                        value={value}
                        onChange={(event) => {
                          onChange(event);
                        }}
                        input={<OutlinedInput label="level" />}
                        sx={{
                          height: "3.9em",
                          color: interfaceTextColor,
                          background: interfaceBgColor,
                          "& .MuiChip-root": {
                            color: primaryButton,
                            background: primaryButtonBg,
                          },
                        }}
                        renderValue={(selected) => {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected && (
                                <Chip key={selected} label={selected} />
                              )}
                            </Box>
                          );
                        }}
                        MenuProps={{
                          ...MenuProps,
                          sx: {
                            "&& .MuiPopover-paper": {
                              color: interfaceTextColor,
                              background: interfaceBgColor,
                            },
                            "&& .Mui-selected": {
                              color: light_text_color,
                              background: primary_color,
                            },
                            "&& .Mui-selected:hover": {
                              color: "black",
                              background: "skyblue",
                            },
                          },
                        }}
                        placeholder={t("Select")}
                      >
                        {moduleLevel.map((row) => (
                          <MenuItem
                            value={row.value}
                            key={row.key}
                            className="d-flex"
                          >
                            <Radio
                              color="default"
                              sx={{
                                color:
                                  getValues() && getValues().level
                                    ? (getValues()?.level as any).indexOf(
                                        row.value
                                      ) > -1
                                      ? "white"
                                      : ""
                                    : "",
                              }}
                              checked={
                                getValues() && getValues().level
                                  ? (getValues()?.level as any).indexOf(
                                      row.value
                                    ) > -1
                                  : false
                              }
                              name="radio-buttons"
                            />
                            <div> {row.value}</div>
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <p className="color-red">
                  {Sentencify(errors.level?.message) || ""}
                </p>
              </div>
              <div className={`${Style.col6} px-0`}>
                <span className={Style.labelHeader}>
                  {t("Status")}
                  <span style={{ color: "red" }}> *</span>
                </span>
                <FormControl sx={{ m: 0, width: "100%", mt: 1 }}>
                  <InputLabel id="demo-multiple-name-label">
                    {t("Status")}
                  </InputLabel>
                  <Controller
                    control={control}
                    defaultValue={status}
                    name="status"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Select
                        labelId="status"
                        id="status"
                        value={value}
                        onChange={(event) => {
                          onChange(event);
                        }}
                        input={<OutlinedInput label="Status" />}
                        sx={{
                          height: "3.9em",
                          color: interfaceTextColor,
                          background: interfaceBgColor,
                          "& .MuiChip-root": {
                            color: primaryButton,
                            background: primaryButtonBg,
                          },
                        }}
                        renderValue={(selected) => {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected && (
                                <Chip key={selected} label={selected} />
                              )}
                            </Box>
                          );
                        }}
                        MenuProps={{
                          ...MenuProps,
                          sx: {
                            "&& .MuiPopover-paper": {
                              color: interfaceTextColor,
                              background: interfaceBgColor,
                            },
                            "&& .Mui-selected": {
                              color: light_text_color,
                              background: primary_color,
                            },
                            "&& .Mui-selected:hover": {
                              color: "black",
                              background: "skyblue",
                            },
                          },
                        }}
                        placeholder={t("Select")}
                      >
                        {moduleStatus.map((row) => (
                          <MenuItem
                            value={row.value}
                            key={row.key}
                            className="d-flex"
                          >
                            <Radio
                              color="default"
                              sx={{
                                color:
                                  getValues() && getValues().status
                                    ? (getValues()?.status as any).indexOf(
                                        row.value
                                      ) > -1
                                      ? "white"
                                      : ""
                                    : "",
                              }}
                              checked={
                                getValues() && getValues().status
                                  ? (getValues()?.status as any).indexOf(
                                      row.value
                                    ) > -1
                                  : false
                              }
                              name="radio-buttons"
                            />
                            <div> {row.value}</div>
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <p className="color-red">
                  {Sentencify(errors.status?.message) || ""}
                </p>
              </div>
            </div>
            {selectedTypeAssessment &&
              (selectedTypeAssessment?.toLowerCase() === "assessment" ? (
                <AssessmentDialog
                  setParentOpen={setIsModalOpen}
                  open={isModalOpen}
                  onClose={handleClose}
                  isLoading={loading}
                  setParentCheckedQuestions={setAssessmentQuestions}
                  parentCheckedQuestions={assessmentQuestions}
                  setParentCheckedButNotAddedQuestions={
                    setParentCheckedButNotAddedQuestions
                  }
                  parentCheckedButNotAddedQuestions={
                    parentCheckedButNotAddedQuestions
                  }
                  setType={() => {
                    // setValue("type", "");
                    setType("");
                  }}
                  setSelectedRows={setSelectedAssessmentRows}
                />
              ) : (
                <>
                  <div className={`row col-lg-12 px-0 mx-0 mb-4`}>
                    {selectedTypeAssessment?.toLowerCase() !== "scorm" && (
                      <div className={`${Style.col6} px-0`}>
                        <span className={Style.labelHeader}>
                          {t("File Name")}
                          <span style={{ color: "red" }}> *</span>
                        </span>
                        <input
                          className={`${Style.inputfields} ${Style.fieldFont} ${
                            errors.file_name ? "is-invalid" : ""
                          }`}
                          type="text"
                          placeholder={t("File Name")}
                          autoComplete="off"
                          {...register("file_name")}
                        />
                        <p className="color-red">
                          {Sentencify(errors.file_name?.message)}
                        </p>
                      </div>
                    )}

                    {/* <div className={`${Style.col6} px-0 mb-3`}>
                    <span className={Style.labelHeader}>Upload File</span>
                    <input
                      type="file"
                      {...register("file")}
                      accept={acceptFiles}
                    />
                    <p className="color-red">{errors.pdf_file?.message}</p>
                  </div> */}
                    <div className={`${Style.col6} px-0 mb-3`}>
                      <span className={Style.labelHeader}>
                        {t("Upload File")}{" "}
                        <span style={{ color: "red" }}> *</span>
                      </span>
                      <ChooseModuleFiles
                        size="small"
                        maxFileSizeinBytes={
                          selectedTypeAssessment?.toLowerCase() == "scorm"
                            ? 104857600
                            : selectedTypeAssessment?.toLowerCase() == "pdf"
                            ? 20971520
                            : 2147483648
                        } // 5mb
                        fileTypes={acceptFiles}
                        loading={loading}
                        errors={errors}
                        setValue={setValue}
                        imageName={"file"}
                        clearErrors={clearErrors}
                        acceptedImageTypesArray={acceptFiles?.split(",")}
                        setImageParent={setPdf}
                        allowedFileTypes={allowedFileTypes}
                      ></ChooseModuleFiles>
                      <p className="error color-red">
                        {Sentencify(errors.file?.message)}
                      </p>
                    </div>
                  </div>
                </>
              ))}
            <AssessmentDialog
              setParentOpen={setOpen}
              open={open}
              onClose={handleClose}
              isLoading={loading}
              setParentCheckedQuestions={setAssessmentQuestions}
              parentCheckedQuestions={assessmentQuestions}
              setParentCheckedButNotAddedQuestions={
                setParentCheckedButNotAddedQuestions
              }
              parentCheckedButNotAddedQuestions={
                parentCheckedButNotAddedQuestions
              }
              setType={() => {
                setType("");
              }}
              setSelectedRows={setSelectedAssessmentRows}
            />
            {!loading &&
              selectedTypeAssessment?.toLowerCase() === "assessment" && (
                <AddAssessments
                  setSelectedRows={setSelectedAssessmentRows}
                  selectedRows={selectedAssessmentRows}
                  setDefaultRows={setDefaultAssessmentRows}
                  defaultRows={defaultAssessmentRows}
                  setOpen={setOpen}
                />
              )}
            {selectedTypeAssessment?.toLowerCase() === "assessment" &&
              assessmentError &&
              assessmentQuestions.length == 0 && (
                <p className="mt-2 color-red">
                  {Sentencify("Assessments are required")}
                </p>
              )}
            <div
              className={`d-flex flex-wrap gap-3 w-100 mr-3 mt-5 align-items-center mb-5 pb-4 `}
              style={{
                paddingRight: "1rem",
                justifyContent: lang === "ar" ? "flex-start" : "flex-end",
              }}
            >
              <SubmitButton label={t("Save")} marginTop={false} />
              <CancelButton
                size="small"
                disabled={loading}
                outlined={true}
                onClick={() => {
                  navigate("/module/module-listing");
                }}
                textColor=""
                outlineColor=""
                text={t("Cancel")}
                className={``}
                bg={""}
              ></CancelButton>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default memo(AddModule);
