import React from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import Header from "../../components/Header";
import useColor from "../../hooks/useColor";
import { defaultThemeColor } from "../../utils/colors_values";
import AdminDashboard from "./AdminDashboard";
import TraineeDashboard from "./TraineeDashboard";
import TrainerDashboard from "./TrainerDashboard";

const Home: React.FC = () => {
  const userInfo = useSelector((state: any) => state.authReducer.loginData);

  const t = useTranslation();
  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  return (
    <>
      <Header
        page={`${t("Hi")} ${t(userInfo?.first_name)} ${t(
          userInfo?.last_name
        )}!`}
        mainHead={t("Dashboard")}
      />

      {userInfo?.role?.toLowerCase() === "trainer" && (
        <div
          style={{
            color: interfaceTextColor,
            fontSize: "15px",
            width: "200px",
          }}
        >
          {t("Overview")}
        </div>
      )}
      {userInfo?.role?.toLowerCase() === "trainee" && (
        <div
          style={{
            color: interfaceTextColor,
            fontSize: "15px",
            width: "200px",
          }}
        >
          {/* {t("Let's")} */}
        </div>
      )}

      {userInfo?.role?.toLowerCase() === "admin" && <AdminDashboard />}
      {userInfo?.role?.toLowerCase() === "trainer" && <TrainerDashboard />}
      {userInfo?.role?.toLowerCase() === "trainee" && <TraineeDashboard />}
    </>
  );
};

export default Home;
