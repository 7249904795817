import { yupResolver } from "@hookform/resolvers/yup";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { imageSelector } from "../../../store/reducers/imagesReducer";
import { RootState, useAppDispatch } from "../../../store/store";

import Stack from "@mui/material/Stack";
import { useTranslation } from "react-multi-lang";
import CancelButton from "../../../components/CancelButton";
import ChooseFile from "../../../components/ChooseFile";
import SubmitButton from "../../../components/SubmitButton";
import useCompanyLanguage from "../../../hooks/language";
import useColor from "../../../hooks/useColor";
import {
  addImage,
  searchImagesForCheck,
} from "../../../services/imageLibraryService";
import {
  alertMessageSelector,
  setErr,
  setErrors,
} from "../../../store/reducers/alertMessageReducer";
import { defaultThemeColor } from "../../../utils/colors_values";
import { Sentencify } from "../../../utils/sentencify";
import Style from "../ImageLibrary.module.scss";
const schema = yup
  .object({
    image: yup.string().required("Image is required"),
    filename: yup
      .string()
      .required("File name is required")
      .max(50, "File name must be at most 50 characters"),
  })
  .required();

export interface SimpleDialogProps {
  open: boolean;
  setParentOpen: any;
  selectedValue: string;
  isLoading: boolean;
  onClose: (value: string) => void;
}
function AddNewImage(props: SimpleDialogProps) {
  const { onClose, selectedValue, open, setParentOpen, isLoading } = props;
  const {
    setValue,
    watch,
    control,
    setError,
    register,
    clearErrors,
    handleSubmit,
    formState: { errors },
    formState: { submitCount },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const addImageData = useSelector(
    (state: RootState) => state.imageLibraryReducer.add_image_data
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const states = useSelector(imageSelector).imageLibraryReducer;

  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedImageBase64, setSelectedImageBase64] = useState<any>();
  const [image, setImage] = useState<any>();
  const [imageup, setImageUp] = React.useState(0);

  const imageData = useSelector(
    (state: RootState) => state.imageLibraryReducer.image_list_data_for_check
  );

  React.useEffect(() => {
    if (alertMessage?.errors) {
      if (alertMessage?.errors?.filename?.length > 0) {
        setError("filename", {
          type: "server",
          message: alertMessage?.errors?.filename?.toString(),
        });
      }
      if (alertMessage?.errors?.image?.length > 0) {
        setError("image", {
          type: "server",
          message: alertMessage?.errors?.image?.toString(),
        });
      }

      if (alertMessage?.errors) {
        dispatch(setErr(null));
        dispatch(setErrors(null));
      }
    }
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
    }
  }, [alertMessage]);

  useEffect(() => {
    if (addImageData && addImageData.filename) {
      setImage("");
      setValue("image", "");
      clearErrors("image");
      setValue("filename", "");
      clearErrors("filename");
      setImageUp(0);
    }
  }, [addImageData]);

  const handleClose = () => {
    if (isLoading) {
    } else {
      onClose(selectedValue);
      setImage("");
      setValue("image", "");
      clearErrors("image");
      setValue("filename", "");
      clearErrors("filename");
    }
  };

  useEffect(() => {
    if (watch("filename")) {
      dispatch(
        searchImagesForCheck({
          search: watch("filename"),
          page: 1,
          page_size: 5,
        })
      );
    }
  }, [watch("filename")]);

  const validate = () => {
    if (
      imageData &&
      Object.keys(imageData).length > 0 &&
      imageData?.results?.find((i: any) => i.filename == watch("filename"))
    ) {
      setError("filename", {
        type: "server",
        message: "Image with this filename already exists",
      });
      return;
    }
  };

  useEffect(() => {
    validate();
  }, [submitCount, imageData]);

  const imageSubmit = (payload: any) => {
    validate();
    if (errors?.filename) {
    } else if (image) {
      payload = {
        ...payload,
        image: image,
      };
      dispatch(addImage(payload));
    } else {
      setError("image", {
        type: "server",
        message: "Image is required!",
      });
    }
    setImageUp(imageup + 1);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open && isLoading ? true : open}
      // style={{
      //   background:
      //     useColor("interface_background_color") || defaultThemeColor.interface_background_color,
      //   color:
      //     useColor("interface_background_text_color") ||
      //     defaultThemeColor.interface_background_text_color,
      // }}
      style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
    >
      <DialogTitle
        style={{
          minWidth: "34em",
          paddingTop: "1em",
          color:
            useColor("interface_foreground_text_color") ||
            defaultThemeColor.interface_foreground_text_color,
          background:
            useColor("interface_foreground_color") ||
            defaultThemeColor.interface_foreground_color,
        }}
      >
        {t("Add New")}
      </DialogTitle>
      <form
        onSubmit={handleSubmit(imageSubmit)}
        autoComplete="off"
        style={{
          background:
            useColor("interface_foreground_color") ||
            defaultThemeColor.interface_foreground_color,
          color:
            useColor("interface_foreground_text_color") ||
            defaultThemeColor.interface_foreground_text_color,
        }}
      >
        <div
          className="col-lg-12"
          style={{
            paddingLeft: "24px",
            paddingRight: "24px",
          }}
        >
          <span className={Style.labelHeader}>
            {t("File name")}
            <span style={{ color: "red" }}> *</span>
          </span>
          <input
            className={`${Style.inputfields} ${Style.fieldFont} ${
              errors.filename ? "is-invalid" : ""
            }`}
            style={{
              background:
                useColor("interface_background_color") ||
                defaultThemeColor.interface_background_color,
              color:
                useColor("interface_background_text_color") ||
                defaultThemeColor.interface_background_text_color,
            }}
            type="text"
            placeholder={t("File name")}
            autoComplete="off"
            {...register("filename")}
          />
          <p className="color-red">{Sentencify(errors.filename?.message)}</p>
        </div>

        <div
          className="col-lg-12"
          style={{
            marginTop: "0.5em",
            paddingLeft: "24px",
            paddingRight: "24px",
          }}
        >
          <span className={Style.labelHeader}>
            {t("Attach file")}
            <span style={{ color: "red" }}> *</span>
          </span>

          <ChooseFile
            size="small"
            fileTypes="image/jpg, image/jpeg, image/png"
            loading={isLoading}
            errors={errors}
            setValue={setValue}
            imageName={"image"}
            clearErrors={clearErrors}
            acceptedImageTypesArray={["image/jpg", "image/jpeg", "image/png"]}
            setImageParent={setImage}
            maxFileSizeinBytes={5242880} // 5mb
          ></ChooseFile>

          <div
            className={`${Style.noteText}`}
            style={{
              color:
                useColor("interface_foreground_text_color") ||
                defaultThemeColor.interface_foreground_text_color,
              marginTop: "75px",
            }}
          >
            {t(
              "Note: Only PNG, JPG and JPEG files are allowed with a maximum file size of 5MB"
            )}
          </div>

          <p className="color-red">{Sentencify(errors?.image?.message)}</p>
        </div>

        <div className="col-lg-12">
          <Stack
            spacing={1}
            direction="row"
            className="float-end gap-4"
            sx={{ mr: 1, mb: 5, mt: 4 }}
          >
            {/* <Button
              style={{
                background:
                  useColor("button_primary") ||
                  defaultThemeColor.button_primary,
                color:
                  useColor("button_primary_text") ||
                  defaultThemeColor.button_primary_text,
              }}
              variant="contained"
              type="submit"
              disabled={isLoading}
            >
              {t("Save")}
            </Button> */}
            <SubmitButton label={t("Save")} marginTop={false} />

            {/* <Button
              variant="text"
              className={Style.cancelBtn}
              style={{
                background:
                  useColor("button_secondary") ||
                  defaultThemeColor.button_secondary,
                color:
                  useColor("button_secondary_text") ||
                  defaultThemeColor.button_secondary_text,
                paddingInline: 15,
                fontWeight: "600",
              }}
              disabled={isLoading}
              onClick={() => {
                setParentOpen(false);
                setImage("");
                setSelectedImage("");
                setValue("image", "");
                clearErrors("image");
                setValue("filename", "");
                clearErrors("filename");
              }}
            >
              {t("Cancel")}
            </Button> */}
            <CancelButton
              size="small"
              outlined={true}
              onClick={() => {
                setParentOpen(false);
                setImage("");
                setSelectedImage("");
                setValue("image", "");
                clearErrors("image");
                setValue("filename", "");
                clearErrors("filename");
              }}
              textColor=""
              outlineColor=""
              text={t("Cancel")}
              className={``}
              bg={""}
            ></CancelButton>
          </Stack>
        </div>
      </form>
    </Dialog>
  );
}

export default AddNewImage;
