// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.List_menu__X1gZA li {
  gap: 10px;
}
.List_menu__X1gZA svg {
  width: 25px;
}

.List_arrowBtn__37QZh:focus {
  outline: none;
}

.List_TreeViewStart__OwtJC {
  min-width: 600px;
}
.List_TreeViewStart__OwtJC .List_tableHead__z51\\+M {
  border: 1px solid #dee2e6;
  padding: 1rem;
  background: #fff;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.List_TreeViewStart__OwtJC .List_tableHead__z51\\+M .List_column__UqNBI {
  color: rgb(52, 58, 64);
  font-size: 13px;
  font-weight: 600;
  line-height: 1.375rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/department/List.module.scss"],"names":[],"mappings":"AACI;EACI,SAAA;AAAR;AAEI;EACI,WAAA;AAAR;;AAIG;EACC,aAAA;AADJ;;AAKA;EACG,gBAAA;AAFH;AAIA;EACI,yBAAA;EACA,aAAA;EACA,gBAAA;EACA,2BAAA;EACA,4BAAA;AAFJ;AAGI;EACI,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,qBAAA;AADR","sourcesContent":[".menu {\n    li {\n        gap: 10px;\n    }\n    svg {\n        width: 25px;\n    }\n}\n.arrowBtn {\n   &:focus {\n    outline: none;\n   }\n}\n\n.TreeViewStart {\n   min-width: 600px;\n\n.tableHead {\n    border: 1px solid #dee2e6;\n    padding: 1rem;\n    background: #fff;\n    border-top-left-radius: 7px;\n    border-top-right-radius: 7px;\n    .column {\n        color: rgb(52, 58, 64);\n        font-size: 13px;\n        font-weight: 600;\n        line-height: 1.375rem;\n    }\n\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": `List_menu__X1gZA`,
	"arrowBtn": `List_arrowBtn__37QZh`,
	"TreeViewStart": `List_TreeViewStart__OwtJC`,
	"tableHead": `List_tableHead__z51+M`,
	"column": `List_column__UqNBI`
};
export default ___CSS_LOADER_EXPORT___;
