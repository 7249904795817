/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import InfoIcon from "@mui/icons-material/Info";
import { Textarea } from "@mui/joy";
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "react-phone-input-2/lib/style.css";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import CancelButton from "../../components/CancelButton";
import CertificateBuilder from "../../components/CertificateBuilder";
import ChooseFile from "../../components/ChooseFile";
import Editor from "../../components/Editor/Editor";
import Header from "../../components/Header";
import SubmitButton from "../../components/SubmitButton";
import useColor from "../../hooks/useColor";
import axiosPrivate from "../../lib/axiosPrivate";
import { addCertificateData } from "../../services/certificateManagementService";
import {
  alertMessageSelector,
  setErrors,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import Style from "./CertificatesManagement.module.scss";
import { useTranslation } from "react-multi-lang";
import useCompanyLanguage from "../../hooks/language";

const schema = yup
  .object({
    name: yup
      .string()
      .required("Name is required")
      .max(100, "Name must be at most 100 characters"),
    description: yup
      .string()
      .required("Description is required")
      .max(2000, "Description must be at most 2000 characters"),
    logo: yup.string().required("Logo is required"),
    background_image: yup.string().required("Background image is required"),
    certificate_data: yup
      .string()
      .min(10, "Must atleast 10 characters are required!")
      .required("Certificate data is required"),
  })
  .required();

function AddCertificate() {
  const {
    reset,
    setError,
    setValue,
    clearErrors,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });
  const [image, setImage] = useState("");
  const [imageCertificate, setImageCertificate] = useState("");
  const [certificateData, setCertificateData] = useState<string>("");
  const [description, setDescription] = useState("");
  const [trainingName, setTrainingName] = useState<any>("");
  const [imageDisplayable, setImageParentDisplayableImage] = useState<any>();
  const [imageLogoDisplayable, setImageLogoParentDisplayableImage] =
    useState<any>();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const [visible, setVisible] = useState<any>(false);

  useEffect(() => {
    setValue("certificate_data", certificateData);
    setError("certificate_data", {
      message: "",
      type: "server",
    });
    if (
      certificateData == "<p><br></p>" ||
      certificateData == `<p class="ql-align-center"><br></p>`
    ) {
      setError("certificate_data", {
        message: "Certificate data is required!",
        type: "server",
      });
    }
  }, [certificateData]);
  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  const page = useSelector(
    (state: RootState) => state.certificateManagementReducer.page
  );
  const perPage = useSelector(
    (state: RootState) => state.certificateManagementReducer.perPage
  );
  const search = useSelector(
    (state: RootState) => state.certificateManagementReducer.search
  );
  const navigate = useNavigate();
  const urlWithQueryString = () => {
    let object: any = {};
    if (page) {
      object.page = page;
    }
    if (perPage) {
      object.perpage = perPage;
    }
    if (
      search !== null &&
      search !== "" &&
      search !== undefined &&
      search !== "null"
    ) {
      object.search = search;
    }

    const objString = "?" + new URLSearchParams(object).toString();
    navigate(`/certificates-management${objString}`);
  };
  const dispatch = useAppDispatch();
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;

  useEffect(() => {
    let defaultValues = {};
    reset({ ...defaultValues });
  }, []);
  useEffect(() => {
    if (alertMessage?.errors) {
      if (alertMessage?.errors?.name?.length > 0) {
        toast.error(alertMessage?.errors?.name?.toString(), {
          toastId: alertMessage?.errors?.name?.toString(),
        });
        setError("name", {
          type: "server",
          message: alertMessage?.errors?.name?.toString(),
        });
      }
      if (alertMessage?.errors?.description?.length > 0) {
        toast.error(alertMessage?.errors?.description?.toString(), {
          toastId: alertMessage?.errors?.description?.toString(),
        });
        setError("description", {
          type: "server",
          message: alertMessage?.errors?.description?.toString(),
        });
      }
      if (alertMessage?.errors?.logo?.length > 0) {
        toast.error(alertMessage?.errors?.logo?.toString(), {
          toastId: alertMessage?.errors?.logo?.toString(),
        });
        setError("logo", {
          type: "server",
          message: alertMessage?.errors?.logo?.toString(),
        });
      }
      if (alertMessage?.errors?.background_image?.length > 0) {
        toast.error(alertMessage?.errors?.background_image?.toString(), {
          toastId: alertMessage?.errors?.background_image?.toString(),
        });
        setError("background_image", {
          type: "server",
          message: alertMessage?.errors?.background_image?.toString(),
        });
      }
      if (alertMessage?.errors?.certificate_data?.length > 0) {
        toast.error(alertMessage?.errors?.certificate_data?.toString(), {
          toastId: alertMessage?.errors?.certificate_data?.toString(),
        });
        setError("certificate_data", {
          type: "server",
          message: alertMessage?.errors?.certificate_data?.toString(),
        });
      }
      if (alertMessage?.errors) {
        dispatch(setErrors(null));
      }
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      navigate(`/certificates-management?page=1&perpage=10`);
    }
  }, [alertMessage]);

  const Submit = (payload: any) => {
    if (
      certificateData == "<p><br></p>" ||
      certificateData == `<p class="ql-align-center"><br></p>`
    ) {
      setError("certificate_data", {
        message: "Certificate data is required!",
        type: "server",
      });
    } else {
      dispatch(
        addCertificateData({
          ...payload,
          image: image,
          background_image: imageCertificate,
        })
      );
    }
  };

  const handleCopyVariable = (variableName: any, variableValue: any) => {
    const tempElement = document.createElement("textarea");
    tempElement.value = variableValue;
    document.body.appendChild(tempElement);

    tempElement.select();
    document.execCommand("copy");
    document.body.removeChild(tempElement);
  };

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 700);
  }, []);
  return (
    <>
      <div
        className={`${Style.certificatesStart}`}
        style={{
          background:
            useColor("interface_background_color") ||
            defaultThemeColor.interface_background_color,
        }}
      >
        <Header
          page={t("Certificates Management")}
          mainHead={t("Certificates Management")}
          backLink="/certificates-management"
        />
        <div
          className="page-start"
          style={{
            background:
              useColor("interface_foreground_color") ||
              defaultThemeColor.interface_foreground_color,
            color:
              useColor("interface_foreground_text_color") ||
              defaultThemeColor.interface_foreground_text_color,
          }}
        >
          <h2
            className="page-heading mb-4"
            style={{
              color:
                useColor("interface_foreground_text_color") ||
                defaultThemeColor.interface_foreground_text_color,
              textAlign: lang === "ar" ? "right" : "left",
            }}
          >
            {t("Add Certificate")}
          </h2>
          <form onSubmit={handleSubmit(Submit)} autoComplete="off">
            <div className={`row`}>
              <div className="col-lg-6 mb-4">
                <span className="form-label">
                  {t("Certificate Name")}
                  <span className="color-red"> *</span>
                </span>
                <input
                  className={`form-input ${
                    errors.name?.message ? "is-invalid" : ""
                  }`}
                  style={{
                    background:
                      useColor("interface_background_color") ||
                      defaultThemeColor.interface_background_color,
                    color:
                      useColor("interface_background_text_color") ||
                      defaultThemeColor.interface_background_text_color,
                    outline: "none",
                    border: "none",
                  }}
                  type="text"
                  placeholder={t("Name")}
                  autoComplete="off"
                  {...register("name")}
                />
                <p className="error color-red">{errors.name?.message}</p>
              </div>
              <div className="col-lg-6 mb-4">
                <span className="form-label">
                  {t("Add Logo")}
                  <span className="color-red"> *</span>
                </span>

                <ChooseFile
                  size="small"
                  fileTypes="image/jpg, image/jpeg, image/png"
                  loading={loading}
                  errors={errors}
                  setValue={setValue}
                  imageName={"logo"}
                  maxFileSizeinBytes={5242880} // 5mb
                  clearErrors={clearErrors}
                  acceptedImageTypesArray={[
                    "image/jpg",
                    "image/jpeg",
                    "image/png",
                  ]}
                  setImageParent={setImage}
                  id={"logo"}
                  setImageParentDisplayable={setImageLogoParentDisplayableImage}
                ></ChooseFile>

                <p className="error color-red">{errors.logo?.message}</p>
              </div>
              <div className="col-lg-6 mb-4">
                <span className="form-label">
                  {t("Description")}
                  <span className="color-red"> *</span>
                </span>
                <Textarea
                  minRows={4}
                  placeholder={t("Description")}
                  onChange={(e) => {
                    setValue("description", e.target.value);
                    setDescription(e.target.value);
                    setError("description", {
                      message: "",
                      type: "server",
                    });
                    if (e.target.value == "") {
                      setError("description", {
                        message: t("Description is required!"),
                        type: "server",
                      });
                    }
                  }}
                  maxRows={4}
                  value={description}
                  className={`form-input no-outline ${
                    errors.description?.message ? "is-invalid" : ""
                  }`}
                  style={{
                    background:
                      useColor("interface_background_color") ||
                      defaultThemeColor.interface_background_color,
                    color:
                      useColor("interface_background_text_color") ||
                      defaultThemeColor.interface_background_text_color,
                    outline: "none",
                    border: "none",
                    boxShadow: "none",
                  }}
                />

                <p className="error color-red">{errors.description?.message}</p>
              </div>

              <div className="col-lg-6 mb-4">
                <span className="form-label">
                  {t("Background Image")}
                  <span className="color-red"> *</span>
                </span>

                <ChooseFile
                  size="small"
                  fileTypes="image/jpg, image/jpeg, image/png"
                  loading={loading}
                  errors={errors}
                  setValue={setValue}
                  imageName={"background_image"}
                  maxFileSizeinBytes={5242880} // 5mb
                  clearErrors={clearErrors}
                  acceptedImageTypesArray={[
                    "image/jpg",
                    "image/jpeg",
                    "image/png",
                  ]}
                  setImageParent={setImageCertificate}
                  setImageParentDisplayable={setImageParentDisplayableImage}
                ></ChooseFile>

                <p className="error color-red">
                  {errors.background_image?.message}
                </p>
              </div>
              <div className="col-12 mb-4">
                <span className="form-label">
                  {t("Certificate Data")}
                  <span className="color-red"> *</span>
                </span>
                {visible && (
                  <Editor
                    setCertificateData={setCertificateData}
                    prviousData={""}
                  />
                )}
                <p className="error color-red">
                  {errors.certificate_data?.message}
                </p>
                <p style={{ display: "flex", flexDirection: "column" }}>
                  <div>
                    <InfoIcon
                      style={{ fontSize: "15px", marginRight: "5px" }}
                    />
                    {t("Dynamically Variable")}:
                  </div>
                  <br />
                  <span>
                    &#123;{t("first_name")}&#125;{" "}
                    <IconButton
                      onClick={() =>
                        handleCopyVariable("first_name", "{first_name}")
                      }
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </span>
                  <span>
                    &#123;{t("last_name")}&#125;{" "}
                    <IconButton
                      onClick={() =>
                        handleCopyVariable("last_name", "{last_name}")
                      }
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </span>
                  <span>
                    &#123;{t("training_name")}&#125;{" "}
                    <IconButton
                      onClick={() =>
                        handleCopyVariable("training_name", "{training_name}")
                      }
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </span>
                  <span>
                    &#123;{t("certificate_date")}&#125;{" "}
                    <IconButton
                      onClick={() =>
                        handleCopyVariable(
                          "certificate_date",
                          "{certificate_date}"
                        )
                      }
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </span>
                  <span>
                    &#123;{t("certificate_logo")}&#125;{" "}
                    <IconButton
                      onClick={() =>
                        handleCopyVariable(
                          "certificate_logo",
                          "{certificate_logo}"
                        )
                      }
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </span>
                  <br />
                  <br />
                  {t("Example")}: &#123;{t("first_name")}&#125; = {t("John")}
                </p>
              </div>
              {/* Certificate view */}
              <div className="w-100 mt-3 col-lg-12 px-0 mx-0">
                <div
                  className={`w-100 ${Style.certificateGenericTemplate} px-2 mb-2`}
                  style={{
                    color:
                      useColor("interface_foreground_text_color") ||
                      defaultThemeColor.interface_foreground_text_color,
                  }}
                >
                  {t("Certificate Template")}
                </div>
                <CertificateBuilder
                  imageCertificate={imageCertificate}
                  imageDisplayable={imageDisplayable}
                  certificateData={certificateData}
                  varsToReplace={[
                    {
                      "{first_name}": JSON.parse(
                        localStorage.getItem("user_info") as any
                      ).first_name,
                    },
                    {
                      "{last_name}": JSON.parse(
                        localStorage.getItem("user_info") as any
                      ).last_name,
                    },
                    {
                      "{training_name}":
                        trainingName?.label || trainingName || "",
                    },
                    {
                      "{certificate_logo}":
                        `<img alt={"logo"} src=${imageLogoDisplayable} width="20%" height="20%"></img>` ||
                        "",
                    },
                  ]}
                ></CertificateBuilder>
              </div>
              <div
                className="d-flex flex-wrap gap-3 w-100 px-3 mt-5 align-items-center"
                style={{
                  justifyContent: lang === "ar" ? "flex-start" : "flex-end",
                }}
              >
                <SubmitButton label={t("Save")} marginTop={false} />
                <CancelButton
                  size="small"
                  outlined={true}
                  onClick={urlWithQueryString}
                  textColor=""
                  outlineColor=""
                  text={t("Cancel")}
                  className={``}
                  bg={""}
                ></CancelButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddCertificate;
