import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import DataTable from "../../components/table/DataTable";
import TableFilters from "../../components/table/TableFilters";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { RootState, useAppDispatch } from "../../store/store";
import Style from "./MyCertificateList.module.scss";

import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import { useTranslation } from "react-multi-lang";
import useCompanyLanguage from "../../hooks/language";
import DownloadSVG from "../../icons/DownloadSVG";
import ViewSVGAction from "../../icons/ViewSVGAction";
import { getMyCertificateListData } from "../../services/certificateManagementService";

function MyCertificates() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const [itemPerPage, setItemPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [searchTable, setSearchTable] = useState("");
  const [list, setList] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<any[]>([]);
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;
  const [rows, setRows] = useState([]);
  const [fetchID, setFetchID] = useState<any>(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dataList = useSelector(
    (state: RootState) => state.certificateManagementReducer.data
  );

  useEffect(() => {
    dispatch(
      getMyCertificateListData({
        page_size: Number(itemPerPage),
        page: Number(page),
        search: searchTable,
      })
    );
  }, [itemPerPage, page, searchTable]);

  useEffect(() => {
    let data: any = dataList.results;
    if (Object.keys(dataList).length === 0) {
      setRows([]);
    } else setRows(data);
  }, [dataList]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      setSelectedCheckBox([]);

      dispatch(
        getMyCertificateListData({
          page_size: Number(itemPerPage),
          page: Number(page),
          search: searchTable,
        })
      );
    }
  }, [alertMessage]);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setSelectedCheckBox([]);
    setPage(page);
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPage(1);
    setSelectedCheckBox([]);
    setItemPerPage(Number(rowsPerPage));
  };

  useEffect(() => {
    modifyRows();
  }, [rows, fetchID]);

  const modifyRows = () => {
    let f_rows: any = [];
    rows.forEach(
      (
        r: {
          id: string;
          detail: any;
          certificate: any;
          completion_date: string;
        },
        i: number
      ) => {
        f_rows = [
          ...f_rows,
          {
            id: r.id,
            name: r?.detail?.name,
            created_at:
              moment(new Date(r?.completion_date)).format("MMM Do, YYYY") || "",
            actions: (
              <>
                <div className={Style.inlineButtons}>
                  <Tooltip title={"View"} placement="top">
                    <button
                      onClick={() => {
                        navigate(
                          `/certificate/download/${r?.detail?.id}/false/my-certificates`
                        );
                      }}
                      className="btn-link"
                      type="button"
                    >
                      <ViewSVGAction />
                    </button>
                  </Tooltip>
                  <Tooltip title={"Download"} placement="top">
                    <button
                      onClick={() => {
                        navigate(
                          `/certificate/download/${r?.detail?.id}/true/my-certificates`
                        );
                      }}
                      className="btn-link"
                      type="button"
                    >
                      <DownloadSVG color="#2B3674" />
                    </button>
                  </Tooltip>
                </div>
              </>
            ),
          },
        ];
      }
    );
    setList(f_rows);
  };

  return (
    <>
      <Header page={t("My Certificates")} mainHead={t("My Certificates")} />

      <TableFilters
        dataList={dataList}
        itemPerPage={itemPerPage}
        setItemPerPage={setItemPerPage}
        selectedCheckBox={selectedCheckBox}
        setSearchTable={setSearchTable}
        handle="groups"
        isCheckboxes={false}
      />

      <DataTable
        columnData={[
          {
            id: "name",
            name: t("Name"),
            enableSort: true,
            align: lang === "ar" ? "center" : "left",
            disablePadding: "none",
          },

          {
            id: "created_at",
            name: t(`Date`),
            enableSort: true,
            align: lang === "ar" ? "center" : "left",
            disablePadding: "none",
          },

          {
            id: "actions",
            name: t("Actions"),
            enableSort: false,
            align: "center",
            disablePadding: "none",
          },
        ]}
        rows={list}
        isCheckboxes={false}
        selectedRowsPerPage={itemPerPage.toString()}
        totalRecordCount={Number(dataList.count)}
        handleChangePageParent={handleChangePage}
        pagenum={Number(page)}
        handleChangeRowsPerPageParent={handleChangeRowsPerPage}
        selectedCheckBox={selectedCheckBox}
        setSelectedCheckBox={setSelectedCheckBox}
      />
    </>
  );
}

export default MyCertificates;
