import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Chip, FormControl, MenuItem, OutlinedInput } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import Select from "@mui/material/Select";
import { Theme, useTheme } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import CancelButton from "../../components/CancelButton";
import CategoryLoadComponent from "../../components/CategoryLoad/CategoryLoad";
import Header from "../../components/Header";
import PolicyLoadComponent from "../../components/PolicyLoad/PolicyLoad";
import SubmitButton from "../../components/SubmitButton";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import {
  addPolicyAssessment,
  getPolicyAssessment,
  updatePolicyAssessment,
} from "../../services/policyAssessmentService";
import {
  alertMessageSelector,
  setErrors,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { RootState, useAppDispatch } from "../../store/store";
import {
  defaultThemeColor,
  light_text_color,
  primary_color,
} from "../../utils/colors_values";
import { assessment_status_values } from "../../utils/staticEntities";
import { DateToMatchWith, timeDiff } from "../../utils/time_difference";
import Style from "../Assessment/AddAssessment.module.scss";
import AssessmentQuestion from "../Assessment/AssessmentQuestions";
import Style2 from "./AddNewPolicy.module.scss";

const timeLimit = [
  { key: "none", value: "No time limit option" },
  { key: "each_question", value: "Set time limits for each question option" },
  { key: "full_quiz", value: "Full quiz time limit" },
];

const ITEM_HEIGHT = 24;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(array: string[], name: string, theme: Theme) {
  return {
    fontWeight:
      array.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const schema = yup
  .object({
    assessment_name: yup.string().required("Assessment Name is required"),
    description: yup.string().required("Description is required"),
    status: yup.string().required("Status is required"),
    category: yup.object().required("Category is required"),
    policy: yup.object().required("Policy is required"),
    scorm: yup.string().optional(),
    time_limit: yup.string().required("Time limit is required"),
    time_limit_difference: yup
      .mixed()
      .default("")
      .when("time_limit", {
        is: "No time limit option",
        then: () => yup.string().optional().nullable(),
      })
      .when("time_limit", {
        is:
          "Set time limits for each question option" || "Full quiz time limit",
        then: () => yup.string().default("").required("Time limit is required"),
      }),
    let_correct_answer: yup.bool().optional(),
    assessment_type: yup.bool().default(false),
    retake: yup.bool().optional(),
    shuffle: yup.bool().optional(),
    passing_criteria: yup
      .number()
      .typeError("Passing criteria must be a number")
      .min(1, "Passing criteria must be greater than or equal to 1")
      .max(100, "Passing criteria must be less than or equal to 100")
      .required("Passing criteria is required"),
  })
  .required();

function PolicyAssessmentAddNew() {
  const {
    reset,
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const [status, setStatus] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [scorm, setScorm] = useState("");
  const [selectedTimeLimit, setSelectedLimit] = useState("");
  const [assessmentQuestions, setAssessmentQuestions] = useState<any>([]);
  const [openCreateNewQuestionModal, setOpenCreateNewQuestionModal] =
    useState(false);
  const { id } = useParams();

  const states = useSelector(alertMessageSelector).alertMessageReducer;

  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );

  const assessmentData: any = useSelector(
    (state: RootState) => state.policyAssessmentReducer.assessmentData
  );

  useEffect(() => {
    if (watch("time_limit_difference") == null) {
      setError("time_limit_difference", {
        type: "server",
        message: "Please select time limit for quiz!",
      });
    } else {
      setError("time_limit_difference", {});
    }
  }, [watch("time_limit_difference")]);

  useEffect(() => {
    if (states?.errors) {
      if (states?.errors?.name?.length > 0) {
        setError("assessment_name", {
          type: "server",
          message: states?.errors?.name?.toString(),
        });
      }
      if (states?.errors?.description?.length > 0) {
        setError("description", {
          type: "server",
          message: states?.errors?.description?.toString(),
        });
      }
      if (states?.errors?.passing_criteria?.length > 0) {
        setError("passing_criteria", {
          type: "server",
          message: states?.errors?.passing_criteria?.toString(),
        });
      }
      if (states?.errors?.time_option?.length > 0) {
        setError("time_limit", {
          type: "server",
          message: states?.errors?.time_option?.toString(),
        });
      }
      if (states?.errors?.category?.length > 0) {
        setError("category", {
          type: "server",
          message: states?.errors?.category?.toString(),
        });
      }
      if (states?.errors?.policy?.length > 0) {
        setError("policy", {
          type: "server",
          message: states?.errors?.policy?.toString(),
        });
      }
      if (states?.errors?.time_limit?.length > 0) {
        setError("time_limit_difference", {
          type: "server",
          message: states?.errors?.time_limit?.toString(),
        });
      }

      if (states?.errors) {
        dispatch(setErrors(null));
      }
    }
    if (states?.error) {
      toast.error(String(states?.error), {
        toastId: String(states?.error),
      });
    }
    if (states?.success) {
      toast.success(String(states?.success), {
        toastId: String(states?.success),
      });
      dispatch(setSuccess(null));
      if (!openCreateNewQuestionModal) {
        navigate("/policy-assessment?page=1&perpage=10");
      }
    }
  }, [states]);

  useEffect(() => {
    if (id) {
      dispatch(getPolicyAssessment({ id: id }));
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      let time_difference = new Date(DateToMatchWith);
      time_difference.setHours(
        assessmentData?.time_limit?.split(":")[0],
        assessmentData?.time_limit?.split(":")[1],
        assessmentData?.time_limit?.split(":")[2]
      );
      let defaultValues = {
        assessment_name: assessmentData?.name,
        description: assessmentData?.description,
        passing_criteria: assessmentData?.passing_criteria,
        category: {
          value: assessmentData?.category?.id,
          label: assessmentData?.category?.name,
        },
        policy: {
          value: assessmentData?.policy?.id,
          label: assessmentData?.policy?.name,
        },
        status: assessmentData?.assessment_status,
        time_limit:
          assessmentData.time_option == "Each_Question_Limit"
            ? "Set time limits for each question option"
            : assessmentData.time_option == "Full_Quiz_Limit"
            ? "Full quiz time limit"
            : "No_Limit"
            ? "No time limit option"
            : "",

        time_limit_difference: assessmentData?.time_limit
          ? dayjs(time_difference)
          : dayjs(new Date(DateToMatchWith)),
        let_correct_answer: assessmentData.correct_answer_option,
        retake: assessmentData.retaking_assessment,
        assessment_type: assessmentData.individual_assessment,
        shuffle: assessmentData.shuffle_order,
      };
      setAssessmentQuestions(assessmentData?.questions);
      reset({ ...defaultValues });
    }
  }, [assessmentData]);

  const formSubmit = (payload: any) => {
    if (!payload?.policy?.value) {
      setError("policy", {
        type: "server",
        message: "Policy is required",
      });
      return;
    }
    if (assessmentQuestions.length == 0) {
      toast.error(String("Question(s) is required"), {
        toastId: String("Question(s) is required"),
      });
      return;
    }
    if (!openCreateNewQuestionModal) {
      if (
        !(watch("time_limit") == "No time limit option") &&
        watch("time_limit_difference") &&
        (watch("time_limit_difference") as any)["$d"]
          .toTimeString()
          .split(" ")[0] == "00:00:00"
      ) {
        setError("time_limit_difference", {
          type: "server",
          message: "Time limit cannot be 00:00:00",
        });
      } else if (
        !(watch("time_limit") == "No time limit option") &&
        watch("time_limit_difference") &&
        (watch("time_limit_difference") as any)["$d"]
          .toTimeString()
          .split(" ")[0] == "Invalid"
      ) {
        setError("time_limit_difference", {
          type: "server",
          message: "Invalid Time Limit selected!",
        });
      } else {
        let f_payload = {
          passing_criteria: payload.passing_criteria,
          name: payload.assessment_name,
          category: payload.category.value,
          policy: payload.policy.value,
          description: payload.description,
          assessment_status: payload.status,
          time_option:
            payload.time_limit == "Set time limits for each question option"
              ? "Each_Question_Limit"
              : payload.time_limit == "Full quiz time limit"
              ? "Full_Quiz_Limit"
              : "No_Limit",
          time_limit:
            watch("time_limit_difference") &&
            (watch("time_limit_difference") as any)["$d"]
              .toTimeString()
              .split(" ")[0],
          correct_answer_option: payload.let_correct_answer,
          retaking_assessment: payload.retake,
          individual_assessment: payload.assessment_type,
          shuffle_order: payload.shuffle,
          questions:
            assessmentQuestions && assessmentQuestions.map((i: any) => i.id),
          file: scorm,
        };

        if (id) {
          let groups = assessmentData?.groups.map((i: any) => i.id);
          let members = assessmentData?.members.map((i: any) => i.id);
          dispatch(
            updatePolicyAssessment({
              ...f_payload,
              id: id,
              remove: assessmentData?.remove_file || false,
              groups: groups,
              members: members,
            })
          );
        } else {
          dispatch(addPolicyAssessment(f_payload));
        }
      }
    }
  };

  const foregroundTextColor =
    useColor("interface_foreground_text_color") ||
    defaultThemeColor.interface_foreground_text_color;

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;

  const primaryButton =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;
  const primaryButtonBg =
    useColor("button_primary") || defaultThemeColor.button_primary;

  const handleTimeLimitChange = (selected: any) => {
    setSelectedLimit(selected);
  };

  return (
    <>
      <Header
        page={
          id ? t("Update Policy Assessment") : t("Create Policy Assessment")
        }
        mainHead={t("Policy Management / Create Policy Assessment")}
        backLink="/policy-assessment"
      />
      <div
        className={`${Style.background} d-flex flex-wrap`}
        style={{
          background:
            useColor("interface_foreground_color") ||
            defaultThemeColor.interface_foreground_color,
          color:
            useColor("interface_foreground_text_color") ||
            defaultThemeColor.interface_foreground_text_color,
        }}
      >
        <div className={`col-12 my-4 mb-0`}>
          <div
            className={`${Style.textHeader} mb-1`}
            style={{
              color:
                useColor("interface_foreground_text_color") ||
                defaultThemeColor.interface_foreground_text_color,
              textAlign: lang === "ar" ? "right" : "left",
              paddingRight: lang === "ar" ? "2rem" : "0rem",
            }}
          >
            {id ? t("Update Policy Assessment") : t("Add New")}
          </div>
        </div>
        <div className={`col-12 my-3 mb-0`}>
          <form id="myform" onSubmit={handleSubmit(formSubmit)}>
            <div className={`row col-lg-12 px-0 mx-0`}>
              <div
                className={`${Style.col6} px-0 mt-3`}
                // style={{
                //   marginLeft: lang === "ar" ? "2em" : "0em",
                //   marginRight: lang === "ar" ? "0em" : "2em",
                // }}
              >
                <span className={Style.labelHeader}>
                  {t("Assessment Name")}
                  <span style={{ color: "red" }}> *</span>
                </span>
                <input
                  className={`${Style.inputfields} ${Style.fieldFont} ${
                    errors.assessment_name ? "is-invalid" : ""
                  }`}
                  style={{
                    color: interfaceTextColor,
                    background: interfaceBgColor,
                  }}
                  type="text"
                  placeholder={t("Assessment Name")}
                  autoComplete="off"
                  {...register("assessment_name")}
                />
                <p className="color-red">{errors.assessment_name?.message}</p>
              </div>
              <div
                className={`${Style.col6} px-0 ${
                  lang === "ar" ? "mr-4" : "mx-0"
                } mt-3`}
                style={{ marginRight: lang === "ar" ? "2em" : "0em" }}
              >
                <span className={Style.labelHeader}>
                  {t("Description")}
                  <span style={{ color: "red" }}> *</span>
                </span>
                <input
                  className={`${Style.inputfields} ${Style.fieldFont} ${
                    errors.description ? "is-invalid" : ""
                  }`}
                  style={{
                    color: interfaceTextColor,
                    background: interfaceBgColor,
                  }}
                  type="text"
                  placeholder={t("Description")}
                  autoComplete="off"
                  {...register("description")}
                />
                <p className="color-red">{errors.description?.message}</p>
              </div>
            </div>

            <div className={`row col-12 px-0 mx-0 mb-4`}>
              <div className={`${Style.col6} px-0 mt-3`}>
                <span className={Style.labelHeader}>
                  {t("Status")}
                  <span style={{ color: "red" }}> *</span>
                </span>
                <FormControl sx={{ m: 0, width: "100%", mt: 1 }}>
                  {/* <InputLabel id="demo-multiple-name-label">{t("Status")}</InputLabel> */}
                  <Controller
                    control={control}
                    defaultValue={status || ""}
                    name="status"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Select
                        labelId="status"
                        id="status"
                        value={value || ""}
                        style={{
                          color: interfaceTextColor,
                          background: interfaceBgColor,
                        }}
                        onChange={(event) => {
                          onChange(event);
                        }}
                        input={<OutlinedInput label="" />}
                        sx={{
                          height: "3.9em",
                          "& .MuiChip-root": {
                            color: primaryButton,
                            background: primaryButtonBg,
                          },
                        }}
                        displayEmpty
                        renderValue={(selected) => {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {(selected && (
                                <Chip key={selected} label={selected} />
                              )) || (
                                <span style={{ color: "hsl(0, 0%, 50%)" }}>
                                  {t("Select Status")}
                                </span>
                              )}
                            </Box>
                          );
                        }}
                        MenuProps={{
                          ...MenuProps,
                          sx: {
                            // color: interfaceTextColor,
                            // background: interfaceBgColor,
                            "&& .MuiPopover-paper": {
                              color: interfaceTextColor,
                              background: interfaceBgColor,
                            },
                            "&& .Mui-selected": {
                              color: light_text_color,
                              background: primary_color,
                            },
                            "&& .Mui-selected:hover": {
                              color: "black",
                              background: "skyblue",
                            },
                          },
                        }}
                        placeholder="Select"
                      >
                        {assessment_status_values.map((status) => (
                          <MenuItem
                            key={status}
                            value={status}
                            style={getStyles(
                              assessment_status_values,
                              status,
                              theme
                            )}
                          >
                            {status}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <p className="color-red">{errors.status?.message || ""}</p>
              </div>
              <div className={`${Style.col6} px-0 mt-3`}>
                <span className={Style.labelHeader}>
                  {t("Category")}
                  <span style={{ color: "red" }}> *</span>
                </span>
                <CategoryLoadComponent
                  control={control}
                  parentName={"category"}
                ></CategoryLoadComponent>
                <p className="color-red">{errors.category?.message || ""}</p>
              </div>
            </div>

            <div className={`row col-12 px-0 mx-0 mb-4`}>
              <div className={`${Style.col6} px-0 mt-0`}>
                <span className={Style.labelHeader}>
                  {t("Passing Criteria")} (%)
                  <span style={{ color: "red" }}> *</span>
                </span>
                <input
                  className={`${Style.inputfields} ${Style.fieldFont} ${
                    errors.passing_criteria ? "is-invalid" : ""
                  }`}
                  style={{
                    color: interfaceTextColor,
                    background: interfaceBgColor,
                  }}
                  min={1}
                  max={100}
                  type="number"
                  placeholder="80%"
                  autoComplete="off"
                  // min="1"
                  // max="100"
                  {...register("passing_criteria")}
                />
                <div
                  className={`${Style2.noteText}`}
                  style={{
                    color:
                      useColor("interface_foreground_text_color") ||
                      defaultThemeColor.interface_foreground_text_color,
                  }}
                >
                  {t("Note: Type betwean 1 - 100")}!
                </div>
                <p className="color-red">{errors.passing_criteria?.message}</p>
              </div>

              <div className={`${Style.col6} px-0 mb-3`}>
                <span className={`${Style.labelHeader} mb-2`}>
                  {t("Time Limit")}
                  <span className="color-red"> *</span>
                </span>
                <FormControl fullWidth sx={{ mt: 1.3 }}>
                  <Controller
                    name="time_limit"
                    control={control}
                    defaultValue={""}
                    render={({ field }) => (
                      <FormControl>
                        {/* <InputLabel id="experience_levels">{t("Time Limit")}</InputLabel> */}
                        <Select
                          {...field}
                          labelId="time_limit"
                          label=""
                          style={{
                            color: interfaceTextColor,
                            background: interfaceBgColor,
                          }}
                          sx={{
                            height: "3.9em",
                            "& .MuiChip-root": {
                              color: primaryButton,
                              background: primaryButtonBg,
                            },
                          }}
                          MenuProps={{
                            sx: {
                              "&& .Mui-selected": {
                                color: light_text_color,
                                background: primary_color,
                              },
                              "&& .Mui-selected:hover": {
                                color: "black",
                                background: "skyblue",
                              },
                            },
                          }}
                          displayEmpty
                          renderValue={(selected) => {
                            return (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {(selected && (
                                  <Chip key={selected} label={selected} />
                                )) || (
                                  <span style={{ color: "hsl(0, 0%, 50%)" }}>
                                    {t("Select Time Limit")}
                                  </span>
                                )}
                              </Box>
                            );
                          }}
                        >
                          {timeLimit.map((row) => (
                            <MenuItem
                              value={row.value}
                              key={row.key}
                              className="d-flex"
                              onClick={() => handleTimeLimitChange(row?.value)}
                            >
                              <Radio
                                color="default"
                                sx={{
                                  color:
                                    getValues() && getValues().time_limit
                                      ? (
                                          getValues()?.time_limit as any
                                        ).indexOf(row.value) > -1
                                        ? "white"
                                        : ""
                                      : "",
                                }}
                                checked={
                                  getValues() && getValues().time_limit
                                    ? (getValues()?.time_limit as any).indexOf(
                                        row.value
                                      ) > -1
                                    : false
                                }
                                name="radio-buttons"
                              />
                              <div> {row.value}</div>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </FormControl>
                <p className="error color-red">{errors.time_limit?.message}</p>
              </div>

              {/* <div className={`${Style.col6} px-0 mb-3 mt-2`}>
                <span className={Style.labelHeader}>
                  {t("Upload SCORM file")}
                </span>
                <ChooseFile
                  size="small"
                  maxFileSizeinBytes={104857600} // 100mb
                  fileTypes="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                  loading={loading}
                  errors={errors}
                  setValue={setValue}
                  imageName={"scorm"}
                  clearErrors={clearErrors}
                  acceptedImageTypesArray={[
                    "zip",
                    "application/octet-stream",
                    "application/zip",
                    "application/x-zip",
                    "application/x-zip-compressed",
                  ]}
                  setImageParent={setScorm}
                ></ChooseFile>
                <div
                  className={`${Style.noteText}`}
                  style={{
                    color:
                      useColor("interface_foreground_text_color") ||
                      defaultThemeColor.interface_foreground_text_color,
                  }}
                >
                  {t("Note: Only Compressed (.zip) files are allowed")}!
                </div>
                {id && assessmentData?.file && (
                  <>
                    <div
                      className={`${Style.noteText2}`}
                      style={{
                        color:
                          defaultThemeColor.interface_foreground_text_color,
                      }}
                    >
                      {`File ${
                        assessmentData?.file_name.length > 20
                          ? "'" +
                            assessmentData?.file_name.slice(0, 20) +
                            "... .'"
                          : assessmentData?.file_name || ""
                      } has already uploaded `}
                      <a href={assessmentData?.file} target="_blank">
                        {t("Click here to View")}
                      </a>
                    </div>
                  </>
                )}
                <p className="error color-red">{errors.scorm?.message}</p>
              </div> */}
              <div className={`${Style.col6} px-0 mt-2`}>
                <span className={Style.labelHeader}>
                  {t("Policy")}
                  <span style={{ color: "red" }}> *</span>
                </span>
                <PolicyLoadComponent
                  control={control}
                  parentName={"policy"}
                ></PolicyLoadComponent>
                <p className="color-red">{errors.policy?.message || ""}</p>
              </div>
              {selectedTimeLimit ===
              "Set time limits for each question option" ? (
                <div
                  className={`${Style.col6} px-0 ${Style.timeLimitAnimation}`}
                >
                  <span className={`${Style.labelHeader}`}>
                    {t("Time Limit for each Question")}
                    <span className="color-red"> *</span>
                  </span>
                  <FormControl fullWidth sx={{ mt: 0 }}>
                    <Controller
                      name="time_limit_difference"
                      control={control}
                      defaultValue={
                        dayjs(new Date(DateToMatchWith)) || undefined
                      }
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormControl>
                          <div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DesktopTimePicker
                                key={"each"}
                                slotProps={{ toolbar: { hidden: true } }}
                                defaultValue={
                                  dayjs(new Date(DateToMatchWith)) || undefined
                                }
                                sx={{
                                  width: "100%",
                                  mt: 1.3,
                                  color: interfaceTextColor,
                                  background: interfaceBgColor,
                                  borderRadius: "4px",
                                }}
                                value={value}
                                onChange={onChange}
                                views={["hours", "minutes", "seconds"]}
                                format="HH:mm:ss"
                                ampm={false}
                              />
                            </LocalizationProvider>
                          </div>
                        </FormControl>
                      )}
                    />
                  </FormControl>
                  {/* <div className={Style.timeDiffPos}>
                    {timeDiff({
                      d1:
                        watch("time_limit_difference") &&
                        (watch("time_limit_difference") as any)["$d"],
                      d2: new Date(DateToMatchWith),
                    })}
                  </div> */}
                  <p
                    className="error color-red"
                    style={{
                      position: "relative",
                      top: "0em",
                      left: "0em",
                    }}
                  >
                    {errors.time_limit_difference?.message}
                  </p>
                </div>
              ) : selectedTimeLimit === "Full quiz time limit" ? (
                <div
                  className={`${Style.col6} px-0 ${Style.timeLimitAnimation}`}
                >
                  <span className={`${Style.labelHeader}`}>
                    {t("Time Limit for Full quiz")}
                    {/* <span className="color-red"> *</span> */}
                  </span>
                  <FormControl fullWidth sx={{ mt: 0 }}>
                    <Controller
                      name="time_limit_difference"
                      control={control}
                      defaultValue={
                        dayjs(new Date(DateToMatchWith)) || undefined
                      }
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormControl>
                          <div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DesktopTimePicker
                                key={"each"}
                                slotProps={{ toolbar: { hidden: true } }}
                                defaultValue={
                                  dayjs(new Date(DateToMatchWith)) || undefined
                                }
                                sx={{
                                  width: "100%",
                                  mt: 1.3,
                                  color: interfaceTextColor,
                                  background: interfaceBgColor,
                                  borderRadius: "4px",
                                }}
                                value={value}
                                onChange={onChange}
                                views={["hours", "minutes", "seconds"]}
                                format="HH:mm:ss"
                                ampm={false}
                              />
                            </LocalizationProvider>
                          </div>
                        </FormControl>
                      )}
                    />
                  </FormControl>
                  <div className={Style.timeDiffPos}>
                    {timeDiff({
                      d1:
                        watch("time_limit_difference") &&
                        (watch("time_limit_difference") as any)["$d"],
                      d2: new Date(DateToMatchWith),
                    })}
                  </div>
                  <p className="error color-red">
                    {errors.time_limit_difference?.message}
                  </p>
                </div>
              ) : null}

              {/* <div className={`${Style.col6} mb-3 px-0`}>
                <span className={`${Style.labelHeader} mb-2`}>
                  Assessment Type
                </span>
                <div className="mt-2 d-flex align-items-center">
                  <Controller
                    control={control}
                    name="assessment_type"
                    defaultValue={id ? true : false}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Checkbox
                        id="assessment_type"
                        inputProps={{ "aria-label": "Checkbox demo" }}
                        onChange={onChange}
                        checked={value}
                        sx={{
                          color: "grey",
                          "&.Mui-checked": {
                            color: primary_color,
                          },
                        }}
                      />
                    )}
                  />
                  <label
                    className={`${Style.textCheckbox} mb-0`}
                    style={{
                      color:
                        foregroundTextColor ||
                        defaultThemeColor.interface_foreground_text_color,
                    }}
                    htmlFor={"assessment_type"}
                  >
                    Make it Standalone Assessment
                  </label>
                </div>
                <p className="error color-red">
                  {errors.assessment_type?.message}
                </p>
              </div> */}

              <div className={`${Style.col6} mb-3 px-0`}>
                <span className={`${Style.labelHeader} mb-2`}>
                  {t("Question Order")}
                  {/* <span className="color-red"> *</span> */}
                </span>
                <div className="mt-2 d-flex align-items-center">
                  <Controller
                    control={control}
                    name="shuffle"
                    defaultValue={id ? true : false}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Checkbox
                        id={"shuffle"}
                        inputProps={{ "aria-label": "Checkbox demo" }}
                        onChange={onChange}
                        checked={value}
                        sx={{
                          color: "grey",
                          "&.Mui-checked": {
                            color: primary_color,
                          },
                        }}
                      />
                    )}
                  />
                  <label
                    className={`${Style.textCheckbox} mb-0`}
                    htmlFor={"shuffle"}
                    style={{
                      color:
                        useColor("interface_foreground_text_color") ||
                        defaultThemeColor.interface_foreground_text_color,
                    }}
                  >
                    {t("Shuffle questions order")}
                  </label>
                </div>
                <p className="error color-red">{errors.shuffle?.message}</p>
              </div>
            </div>

            <AssessmentQuestion
              id={id}
              setAssessmentQuestionsParent={setAssessmentQuestions}
              questions={assessmentQuestions}
              setOpenCreateNewQuestionModal={setOpenCreateNewQuestionModal}
              openCreateNewQuestionModal={openCreateNewQuestionModal}
            />

            <div
              className="d-flex flex-wrap gap-3 w-100 px-3 mt-5 align-items-center mb-5 pb-4"
              style={{
                justifyContent: lang === "ar" ? "flex-start" : "flex-end",
              }}
            >
              <SubmitButton
                label={id ? t("Update") : t("Save")}
                marginTop={false}
              />
              <CancelButton
                size="small"
                outlined={true}
                onClick={() => {
                  navigate("/policy-assessment");
                }}
                textColor=""
                outlineColor=""
                text={t("Cancel")}
                className={``}
                bg={""}
              ></CancelButton>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default PolicyAssessmentAddNew;
