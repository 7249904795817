// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LanguageButton_maskbox__BfPcP {
  display: flex;
  border-radius: 20px;
  position: relative;
  box-shadow: 1px 0px 1px 0px rgb(165, 165, 165) inset;
  margin-left: 1.3em;
  margin-right: 0.8em;
  height: 2.6em;
  width: 7em;
}
.LanguageButton_maskbox__BfPcP .LanguageButton_mask__xOClM {
  width: 4em;
  border-radius: 20px;
  position: absolute;
  box-shadow: 1px 1px 2px grey;
  transition: all 0.5s ease;
  height: 2.5em;
  left: 1em;
}
.LanguageButton_maskbox__BfPcP .LanguageButton_MuiButton-root__IiYiA {
  border-radius: 20px;
  width: 4em;
  height: 40px;
  font-weight: bold;
  transition: all 0.2s 0.1s ease;
}
.LanguageButton_maskbox__BfPcP .LanguageButton_MuiButton-root__IiYiA:hover {
  opacity: 0.8;
}`, "",{"version":3,"sources":["webpack://./src/components/LanguageButton.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,oDAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,UAAA;AACF;AACE;EACE,UAAA;EACA,mBAAA;EACA,kBAAA;EACA,4BAAA;EACA,yBAAA;EACA,aAAA;EACA,SAAA;AACJ;AAEE;EACE,mBAAA;EACA,UAAA;EACA,YAAA;EACA,iBAAA;EACA,8BAAA;AAAJ;AAGE;EACE,YAAA;AADJ","sourcesContent":[".maskbox {\n  display: flex;\n  border-radius: 20px;\n  position: relative;\n  box-shadow: 1px 0px 1px 0px rgb(165, 165, 165) inset;\n  margin-left: 1.3em;\n  margin-right: 0.8em;\n  height: 2.6em;\n  width: 7em;\n\n  .mask {\n    width: 4em;\n    border-radius: 20px;\n    position: absolute;\n    box-shadow: 1px 1px 2px grey;\n    transition: all 0.5s ease;\n    height: 2.5em;\n    left: 1em;\n  }\n\n  .MuiButton-root {\n    border-radius: 20px;\n    width: 4em;\n    height: 40px;\n    font-weight: bold;\n    transition: all 0.2s 0.1s ease;\n  }\n\n  .MuiButton-root:hover {\n    opacity: 0.8;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"maskbox": `LanguageButton_maskbox__BfPcP`,
	"mask": `LanguageButton_mask__xOClM`,
	"MuiButton-root": `LanguageButton_MuiButton-root__IiYiA`
};
export default ___CSS_LOADER_EXPORT___;
