// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SubmitButton_buttonStart__8cckN {
  min-width: 150px;
  min-height: 42px;
}
@media only screen and (max-width: 490px) {
  .SubmitButton_buttonStart__8cckN {
    min-width: 115px;
  }
}
.SubmitButton_buttonStart__8cckN:disabled {
  background-color: #e7e8e9 !important;
  border-color: #e7e8e9 !important;
  opacity: 1 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/SubmitButton.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gBAAA;AACF;AACE;EAJF;IAKI,gBAAA;EAEF;AACF;AAAE;EACE,oCAAA;EACA,gCAAA;EACA,qBAAA;AAEJ","sourcesContent":[".buttonStart {\n  min-width: 150px;\n  min-height: 42px;\n\n  @media only screen and (max-width: 490px) {\n    min-width: 115px;\n  }\n\n  &:disabled {\n    background-color: #e7e8e9 !important;\n    border-color: #e7e8e9 !important;\n    opacity: 1 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonStart": `SubmitButton_buttonStart__8cckN`
};
export default ___CSS_LOADER_EXPORT___;
