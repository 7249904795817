import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import { Theme, useTheme } from "@mui/material/styles";
import { useConfirm } from "material-ui-confirm";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CancelButton from "../../components/CancelButton";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import { RootState, useAppDispatch } from "../../store/store";
import {
  defaultThemeColor,
  light_text_color,
  primary_color,
} from "../../utils/colors_values";
import { roles_except_super_admin } from "../../utils/staticEntities";
import Style from "./ActiveDirectoryUser.module.scss";
export interface SimpleDialogProps {
  setParentOpen: any;
  open: boolean;
  onClose: () => void;

  selectedRows: any;
  save?: any;
  setSave?: any;
  setRolesState?: any;
  rolesState?: any;
}
const ITEM_HEIGHT = 24;
const ITEM_PADDING_TOP = 0;

function ActiveDirectoryUserAssignRoleModel(props: SimpleDialogProps) {
  const {
    setError,
    getValues,
    watch,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    // resolver: yupResolver(schema),
  });
  const {
    setParentOpen,
    open,
    onClose,

    selectedRows,
    save,
    setSave,
    setRolesState,
    rolesState,
  } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  const theme = useTheme();

  const handleAdd = () => {
    setSave(true);
    // handleClose();
  };

  function getStyles(array: string[], name: string, theme: Theme) {
    return {
      fontWeight:
        array.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleClose = () => {
    onClose();
    setParentOpen(false);
    setRolesState("");
  };

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;
  const primaryButton =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;
  const primaryButtonBg =
    useColor("button_primary") || defaultThemeColor.button_primary;

  const MenuPropsCountry = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 11.5 + ITEM_PADDING_TOP,
        width: 250,
        color: interfaceTextColor,
        background: interfaceBgColor,
      },
    },
  };
  const foregroundBgColor =
    useColor("interface_foreground_color") ||
    defaultThemeColor.interface_foreground_color;
  const foregroundTextColor =
    useColor("interface_foreground_text_color") ||
    defaultThemeColor.interface_foreground_text_color;

  return (
    <Dialog
      onClose={handleClose}
      maxWidth={"lg"}
      open={open && loading ? true : open}
      style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
      // PaperProps={{
      //   style: {
      //     backgroundColor: "rgb(246, 249, 255)",
      //   },
      // }}
    >
      <DialogContent
        // style={{ color: interfaceTextColor, background: foregroundBgColor }}
        className="mb-3"
        style={{
          background: foregroundBgColor,
          color: foregroundTextColor,
          border: "none",
          paddingRight: 10,
          paddingTop: 0,
          paddingBottom: 0,
          direction: lang === "ar" ? "rtl" : "ltr",
          minWidth: "500px",
        }}
      >
        <div
          className="d-flex justify-content-between align-items-center mt-4"
          style={{
            color: interfaceTextColor,
            // background: interfaceBgColor,
            // borderBottom: "1px solid lightgrey",
            // margin: "0rem 1.5rem",
          }}
        >
          <DialogTitle
            className=""
            style={{
              fontSize: "20px",
              padding: 0,
              color:
                useColor("interface_foreground_text_color") ||
                defaultThemeColor.interface_foreground_text_color,
              background:
                useColor("interface_foreground_color") ||
                defaultThemeColor.interface_foreground_color,
            }}
          >
            {t("Assign Role to Active Directory User(s)")}
          </DialogTitle>
          <div className="p-2 px-0">
            <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
          </div>
        </div>
        <div className="row">
          <div className={`col-12 mb-3 mt-3`} style={{ paddingRight: "24px" }}>
            {/* <label>{t("Roles")}</label> */}
            <FormControl sx={{ m: 0, width: "100%", mt: 1 }}>
              <InputLabel id="roles-label">Select Role</InputLabel>
              <Select
                id="demo-multiple-name"
                label=""
                labelId="roles"
                sx={{
                  "& .MuiChip-root": {
                    color: primaryButton,
                    background: primaryButtonBg,
                  },
                }}
                MenuProps={{
                  ...MenuPropsCountry,
                  sx: {
                    "&& .Mui-selected": {
                      color: light_text_color,
                      background: primary_color,
                    },
                    "&& .Mui-selected:hover": {
                      color: "black",
                      background: "skyblue",
                    },
                  },
                }}
                value={rolesState}
                onChange={(event) => {
                  setRolesState(event.target.value);
                }}
                defaultValue={""}
                input={<OutlinedInput label="Select Role" />}
              >
                {roles_except_super_admin.map((designation: any) => (
                  <MenuItem
                    key={designation}
                    value={designation}
                    style={getStyles(
                      roles_except_super_admin,
                      designation,
                      theme
                    )}
                  >
                    {designation}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="row">
          <div className="col-12" style={{ paddingRight: "24px" }}>
            <div className="d-flex flex-wrap gap-3 w-100  mb-2 align-items-center justify-content-end ">
              <Button
                type="button"
                className={`btn mt-3 ${Style.addBtn}`}
                disabled={
                  selectedRows?.length == 0 ||
                  rolesState == "" ||
                  !rolesState ||
                  loading
                    ? true
                    : false
                }
                style={{
                  color:
                    useColor("button_primary_text") ||
                    defaultThemeColor.button_secondary_text,
                  background:
                    useColor("button_primary") ||
                    defaultThemeColor.button_secondary,
                  border: "none",
                  outline: "none",
                }}
                onClick={handleAdd}
              >
                {t("Save")}
              </Button>
              <CancelButton
                disabled={loading}
                size="small"
                outlined={true}
                onClick={() => {
                  handleClose();
                }}
                textColor=""
                outlineColor=""
                text={t("Cancel")}
                className={`mt-3`}
                bg={""}
              ></CancelButton>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ActiveDirectoryUserAssignRoleModel;
