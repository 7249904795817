import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
const companyProfile = JSON.parse(
  localStorage.getItem("company_profile") || "{}"
);

export interface CompanyFormError {
  message: string;
}

export interface GetCompanyForm {
  id: number;
  company_logo: string;
  admin_name: string;
  admin_email: string;
  company_name: string;
  company_email: string;
  support_email: string;
  support_alias: string;
  company_language: string;
  is_report_card_enabled: boolean;
}

export interface UpdateCompanyForm {
  id: number;
  company_logo: any;
  image: any;
  imagename: any;

  admin_name: string;
  admin_email: string;
  company_name: string;
  company_email: string;
  support_email: string;
  support_alias: string;
  company_language: string;
  is_report_card_enabled: any;

  license_key: any;
  sidebar_text_color: any;
  sidebar_text_active: any;
  sidebar_text_active_background: any;
  sidebar_background_color: any;
  interface_background_color: any;
  interface_background_text_color: any;
  interface_foreground_text_color: any;
  interface_foreground_color: any;
  button_primary: any;
  button_primary_text: any;
  button_secondary: any;
  button_secondary_text: any;
}

export interface CompanyFormState {
  isLoading: boolean;
  error?: CompanyFormError;
  errors?: any;
  company_form_data: GetCompanyForm | any;
  up_company_form_data: UpdateCompanyForm | any;
}

export const initialState: CompanyFormState = {
  isLoading: false,
  company_form_data: companyProfile,
  up_company_form_data: {},
};

export const companyFormSlice = createSlice({
  name: "companyForm",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },

    setCompanyFormData: (state, { payload }) => {
      state.company_form_data = payload;
    },

    setCompanyFormDataFailed: (state, { payload }) => {
      state.errors = payload;
    },

    setUpdateCompanyFormData: (state, { payload }) => {
      state.up_company_form_data = payload;
    },

    setUpdateCompanyFormDataFailed: (state, { payload }) => {
      state.errors = payload;
    },
  },
});

export const {
  setCompanyFormData,
  setCompanyFormDataFailed,
  setUpdateCompanyFormData,
  setUpdateCompanyFormDataFailed,
} = companyFormSlice.actions;

export const companyFormSelector = (state: RootState) => state;

export default companyFormSlice.reducer;
