function ProgressSvg(props: { color: string }) {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.882812 32.1682C1.05675 37.0097 4.99838 40.9163 9.88228 41.0895L20.1877 28.4704L13.6126 21.9531L0.882812 32.1682Z"
        fill={props.color}
      />
      <path
        d="M18.1582 17.3244L24.857 23.9649L36.265 14.7124C34.2776 14.4174 32.2479 13.3565 30.5538 11.6772C28.8597 9.99779 27.7887 7.98498 27.4919 6.01562L18.1582 17.3244Z"
        fill={props.color}
      />
      <path
        d="M21.8795 26.3969C21.9104 26.3591 21.9449 26.3242 21.9837 26.2936L23.7403 24.8686L17.245 18.4297L15.8075 20.1718C15.7758 20.2095 15.7413 20.2437 15.7025 20.2744L14.7344 21.0517L21.096 27.3566L21.8795 26.3969Z"
        fill={props.color}
      />
      <path
        d="M41.1248 8.16869C41.1248 5.89581 39.2596 4.04688 36.9668 4.04688C35.4143 4.04688 34.0897 5.17476 33.9509 6.61473C33.8733 7.43125 34.1335 8.21215 34.6848 8.8135C35.2289 9.4063 36.0037 9.74688 36.8123 9.74688C37.1027 9.74688 37.3657 9.92073 37.4764 10.1865C37.5878 10.453 37.526 10.7593 37.3204 10.9631L35.2562 13.0101C36.1582 13.3108 37.0653 13.4298 37.922 13.3436L39.7017 11.5759C40.6195 10.666 41.1248 9.45618 41.1248 8.16869Z"
        fill={props.color}
      />
      <path
        d="M24.6156 28.8119C24.6795 28.5568 24.8808 28.3573 25.1388 28.2932L26.7819 27.8857L24.7615 25.8828L22.9502 27.352L22.1172 28.3716L24.2044 30.4407L24.6156 28.8119Z"
        fill={props.color}
      />
      <path
        d="M33.8488 12.3891L35.3374 10.9136C34.6869 10.6784 34.0947 10.2894 33.6203 9.77213C32.9626 9.05536 32.5803 8.13552 32.5156 7.17578L30.3945 9.27837C30.7367 9.75859 31.1248 10.2267 31.5697 10.6685C32.2798 11.3724 33.0525 11.951 33.8488 12.3891Z"
        fill={props.color}
      />
      <path
        d="M21 1.90937C22.8889 1.90937 24.7547 2.17727 26.5466 2.70666L26.9563 1.3408C25.0315 0.772225 23.0276 0.484375 21 0.484375C9.50719 0.484375 0.15625 9.75329 0.15625 21.1469C0.15625 23.1561 0.446625 25.1433 1.02019 27.0514L2.39731 26.6452C1.864 24.869 1.59375 23.0186 1.59375 21.1469C1.59375 10.5392 10.2993 1.90937 21 1.90937Z"
        fill={props.color}
      />
      <path
        d="M21.0009 40.3842C18.6118 40.3842 16.2795 39.9588 14.0686 39.1209L13.5547 40.4519C15.9302 41.3525 18.4357 41.8092 21.0009 41.8092C32.4937 41.8092 41.8447 32.5403 41.8447 21.1467C41.8447 19.1367 41.5543 17.1503 40.9808 15.2422L39.6036 15.6483C40.1369 17.4246 40.4072 19.2742 40.4072 21.1467C40.4072 31.7544 31.7017 40.3842 21.0009 40.3842Z"
        fill={props.color}
      />
    </svg>
  );
}

export default ProgressSvg;
