// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_layoutStart__uEq9l {
  width: 100%;
  min-height: 100vh;
}
@media only screen and (max-width: 850px) {
  .Layout_layoutStart__uEq9l {
    flex-direction: column;
  }
}
.Layout_layoutStart__uEq9l .Layout_rightSide__m1RoZ {
  background-color: #f6f9ff;
  padding: 30px 50px;
  height: 100vh;
  overflow: auto;
}
@media only screen and (max-width: 767px) {
  .Layout_layoutStart__uEq9l .Layout_rightSide__m1RoZ {
    padding: 30px 15px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/layout/Layout.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;AACF;AACE;EAJF;IAKI,sBAAA;EAEF;AACF;AAAE;EACE,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,cAAA;AAEJ;AAAI;EANF;IAOI,kBAAA;EAGJ;AACF","sourcesContent":[".layoutStart {\n  width: 100%;\n  min-height: 100vh;\n\n  @media only screen and (max-width: 850px) {\n    flex-direction: column;\n  }\n\n  .rightSide {\n    background-color: #f6f9ff;\n    padding: 30px 50px;\n    height: 100vh;\n    overflow: auto;\n\n    @media only screen and (max-width: 767px) {\n      padding: 30px 15px;\n    }\n  }\n}\n\n// .rightSide2:not(:empty(body:nth-child(4))) {\n//   overflow: hidden;\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layoutStart": `Layout_layoutStart__uEq9l`,
	"rightSide": `Layout_rightSide__m1RoZ`
};
export default ___CSS_LOADER_EXPORT___;
