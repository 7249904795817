/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import { memo, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import screenfull from "screenfull";
import ForwardSVG from "../icons/ForwardSVG";
import VideoFullScreenSVG from "../icons/VideoFullScreen";
import Style from "./PdfViewer.module.scss";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PDFViewer = ({
  link,
  moduleName,
  topicName,
  nextVideoClick,
  onFinished,
  setSaved,
  isOnlyView = false,
}: {
  link: any;
  moduleName?: any;
  topicName?: any;
  nextVideoClick?: any;
  onFinished?: any;
  setSaved?: any;
  isOnlyView?: boolean;
}) => {
  const [numPages, setNumPages] = useState<any>();
  const [pageNumber, setPageNumber] = useState<any>(1);
  const playerDivRef: any = useRef(null);
  const t = useTranslation();
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    // This effect runs after the component renders
    const timer = setTimeout(() => {
      setIsShow(true); // Update the message after the delay
    }, 2000); // 3000 milliseconds (3 seconds) delay

    // Clean up the timer when the component unmounts or when the effect runs again
    return () => clearTimeout(timer);
  }, []); // Empty dependency array ensures the effect runs only once after initial render

  const onDocumentLoadSuccess = ({ numPages }: { numPages: any }) => {
    console.log("numPagesnumPagesnumPages", numPages);
    setNumPages(numPages);
    if (numPages > 1 && !isOnlyView) {
      setSaved(true);
    }
  };

  useEffect(() => {
    if (pageNumber == numPages && !isOnlyView) {
      onFinished();
    }
  }, [numPages, pageNumber]);

  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
  const goToNextPage = () =>
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);
  const handleFullScreenMode = () => {
    screenfull.toggle(playerDivRef.current);
  };

  return (
    <div
      ref={playerDivRef}
      onDoubleClick={handleFullScreenMode}
      className={isShow ? "" : "hide"}
    >
      <div>
        <div className={Style.controls__div2} id="mydiv">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            style={{ paddingInline: 16, paddingTop: 0, paddingBottom: 0 }}
          >
            <Grid item sx={{ maxWidth: "20em" }}>
              <Tooltip title={t("Module Name")} placement="top">
                <span className={Style.module}>{moduleName} </span>
              </Tooltip>
              <Tooltip title={t("Topic Name")} placement="top">
                <span className={Style.topicName}> {topicName}</span>
              </Tooltip>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" direction="row">
                <Tooltip title={t("Previous Page")} placement="top">
                  <IconButton
                    className={Style.controls__icons2}
                    onClick={() => {
                      goToPrevPage();
                    }}
                    sx={{ borderRadius: "50px", background: "#2B3674" }}
                  >
                    <KeyboardArrowLeftIcon
                      sx={{
                        color: "#FFF",
                        fontSize: "30px",
                      }}
                    />
                  </IconButton>
                </Tooltip>

                <Tooltip title={t("Next Page")} placement="top">
                  <IconButton
                    className={Style.controls__icons2}
                    onClick={() => {
                      goToNextPage();
                    }}
                    sx={{ borderRadius: "50px", background: "#2B3674" }}
                  >
                    <KeyboardArrowRightIcon
                      sx={{
                        color: "#FFF",
                        fontSize: "30px",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>

            <Grid item>
              <Tooltip title={t("Page Number")} placement="top">
                <span
                  className={Style.module}
                  style={{ marginRight: "0.7em", color: "white" }}
                >
                  {numPages && pageNumber + "/"}
                  {numPages}
                </span>
              </Tooltip>
              {!isOnlyView && (
                <Tooltip title={t("Next Lecture")} placement="top">
                  <IconButton
                    className={Style.controls__icons}
                    sx={{
                      borderRadius: "50px",
                      background: "#2B3674",
                      marginRight: "5px",
                    }}
                    onClick={() => nextVideoClick()}
                  >
                    <ForwardSVG color="#e7e7e7" />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title={"Toggle Fullscreen"} placement="top">
                <IconButton
                  className={Style.controls__icons}
                  sx={{ borderRadius: "50px", background: "#2B3674" }}
                  onClick={handleFullScreenMode}
                >
                  <VideoFullScreenSVG color="#e7e7e7" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </div>
      </div>

      <Document className={Style.adjustScroll} file={link} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
    </div>
  );
};

export default memo(PDFViewer);
