import { useConfirm } from "material-ui-confirm";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DataTable from "../../components/table/DataTable";
import TableFilters from "../../components/table/TableFilters";
import useColor from "../../hooks/useColor";
import { bulkDeleteGroups } from "../../services/groupManagementService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import { confirmProps } from "../../utils/confirmProps";
import Style from "./AddNewPolicy.module.scss";

export interface SimpleDialogProps {
  setSelectedRows: any;
  selectedRows: any;
  setDefaultRows: any;
  defaultRows: any;
  id?: any;
}

function PolicyGroups(props: SimpleDialogProps) {
  const { setSelectedRows, selectedRows, setDefaultRows, defaultRows, id } =
    props;
  const dispatch = useAppDispatch();
  const confirm = useConfirm();

  const [itemPerPage, setItemPerPage] = useState<number>(10);
  const [searchTable, setSearchTable] = useState("");

  const [list, setList] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<any[]>([]);

  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;

  const [rows, setRows] = React.useState<any[]>([]);
  const [listCSV, setListCSV] = useState([]);

  const [page, setPage] = useState<number>(1);

  const handleSearchChange = (searchTerm: any) => {
    let data: any = defaultRows;
    if (!searchTerm) {
      setRows(data);
    } else {
      const filteredRows = data?.filter((row: any) =>
        row?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      );
      setRows(filteredRows);
    }
  };

  useEffect(() => {
    setPage(1);
  }, []);

  useEffect(() => {}, [dispatch, itemPerPage, page, searchTable]);

  useEffect(() => {
    let data: any = selectedRows.results;
    if (Object.keys(selectedRows).length === 0) {
      setRows([]);
    } else setRows(data);
  }, [selectedRows]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      setSelectedCheckBox([]);
    }
  }, [alertMessage]);

  useEffect(() => {
    handleSearchChange(searchTable);
  }, [searchTable]);

  useEffect(() => {
    if (defaultRows) {
      let data: any = defaultRows;
      if (Object.keys(defaultRows).length === 0) {
        setRows([]);
      } else setRows(data);
    }
  }, [defaultRows]);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setSelectedCheckBox([]);
    setPage(page);
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPage(1);
    setSelectedCheckBox([]);
    setItemPerPage(Number(rowsPerPage));
  };

  useEffect(() => {
    modifyRows();
  }, [rows]);

  const bulkDelete = (ids: any) => {
    // const sendSelected = selected.map((sel) => sel);
    confirm({
      title: "Are you sure!",
      description: `Do you want to delete selected groups?`,
      ...confirmProps,
    })
      .then(() => {
        dispatch(bulkDeleteGroups(ids));
        setSelectedCheckBox([]);
      })
      .catch(() => {});
  };

  const modifyRows = () => {
    let f_rows: any = [];
    rows &&
      rows.forEach((r: any, i: number) => {
        f_rows = [
          ...f_rows,
          {
            id: r?.id,
            name: r?.name,
          },
        ];
      });

    setList(f_rows);
  };

  const AddMember = () => {
    return <></>;
  };

  const handleClicked = () => {
    if (list.length == 0) {
      toast.error(String("Nothing to export..."), {
        toastId: String("Nothing to export..."),
      });
    }
    setListCSV(list);
  };

  return (
    <>
      <div className="mt-5">
        <div
          className={`${Style.header4}`}
          style={{
            color:
              useColor("interface_foreground_text_color") ||
              defaultThemeColor.interface_foreground_text_color,
          }}
        >
          Groups
        </div>
        <TableFilters
          dataList={selectedRows}
          itemPerPage={itemPerPage}
          setItemPerPage={setItemPerPage}
          selectedCheckBox={selectedCheckBox}
          bulkDelete={bulkDelete}
          setSearchTable={setSearchTable}
          createButton={<AddMember />}
          handle="addMembers"
          exportCSV={false}
          setParentData={() => setListCSV([])}
          handleClicked={handleClicked}
          rows={listCSV?.map((r: any, i: any) => ({
            // id: r.id,
            group: r?.name,
          }))}
          headers={["Group Name"]}
          filename={`policy_groups__of_policy_${id}.csv`}
        />

        <div style={{ marginRight: "0.7em" }}>
          <DataTable
            columnData={[
              {
                id: "group",
                name: "Group Name",
                enableSort: false,
                align: "left",
                disablePadding: "none",
              },
            ]}
            rows={
              list
                ? list?.slice(
                    (Number(page) - 1) * itemPerPage,
                    Number(page) * itemPerPage
                  )
                : []
            }
            isCheckboxes={false}
            selectedRowsPerPage={itemPerPage.toString()}
            totalRecordCount={list && list?.length}
            handleChangePageParent={handleChangePage}
            pagenum={Number(page)}
            handleChangeRowsPerPageParent={handleChangeRowsPerPage}
            bulkDelete={bulkDelete}
            selectedCheckBox={selectedCheckBox}
            setSelectedCheckBox={setSelectedCheckBox}
          />
        </div>
      </div>
    </>
  );
}

export default PolicyGroups;
