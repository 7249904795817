import { Outlet } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import getCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import { defaultThemeColor } from "../../utils/colors_values";
import Style from "./Layout.module.scss";

const Layout = () => {
  const lang =
    JSON.parse(localStorage.getItem("user_info") as any).preferred_language ==
    "Arabic - العربية"
      ? "ar"
      : "en" || getCompanyLanguage();
  const arabicFonts = {
    fontFamily:
      lang === "ar" ? `"Arial", "Times New Roman", sans-serif` : "dmSans",
  };

  return (
    <div
      className={`d-flex ${Style.layoutStart}`}
      style={{
        direction: lang === "ar" ? "rtl" : "ltr",
        ...arabicFonts,
        // fontFamily: lang === "ar" ? "monospace" : "dmSans",
        // fontWeight: lang === "ar" ? "bold" : "",
      }}
      // dir={lang === "Arabic - العربية" ? "rtl" : "ltr"}
      // dir={lang.startsWith("Arabic") ? "rtl" : "ltr"}
    >
      <Sidebar />
      <div
        id="layout"
        className={`flex-grow-1 ${Style.rightSide}`}
        style={{
          background:
            useColor("interface_background_color") ||
            defaultThemeColor.interface_background_color,
          // fontFamily: lang === "ar" ? "system-ui" : "dmSans",
          ...arabicFonts,
        }}
      >
        {/* <h4>{userInfo?.first_name + " " + userInfo?.last_name}</h4>
        <h4>{userInfo?.email}</h4> */}
        <div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
