import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import useColor from "../../hooks/useColor";
import SuccessPopup from "../../images/success.svg";
import {
  getTraining,
  updateModuleStatus,
} from "../../services/trainingsService";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import Style from "./TrainingList/ModulesMemberLists.module.scss";

function TrainingSuccessModal() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isOpen, training_id, module_id, assessment_id }: any = useParams();
  const [module, setModule] = useState<any>();
  useEffect(() => {
    dispatch(getTraining(Number(training_id)));
  }, [training_id]);
  const trainingDetail: any = useSelector(
    (state: RootState) => state.trainingsReducer.trainingData
  );
  const handleClose = () => {
    if (module?.module?.assessment?.correct_answer_option) {
      navigate(`/progress-report/${training_id}/${module_id}/${assessment_id}`);
    } else {
      navigate(`/my-training`);
    }
    // Navigate back to the previous route when the modal is closed
  };

  useEffect(() => {
    dispatch(
      updateModuleStatus({
        training_id: Number(training_id),
        module_id: Number(module_id),
        module_status: "Completed",
      })
    );
  }, [training_id, module_id]);

  useEffect(() => {
    const currentModule = trainingDetail?.ordered_modules?.filter(function (
      row: any
    ) {
      return (
        parseInt(row?.module?.id) === parseInt(module_id) &&
        parseInt(row?.module?.assessment?.id) === parseInt(assessment_id)
      );
    });
    setModule(currentModule?.[0]);
  }, [trainingDetail, training_id, module_id, assessment_id]);

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;

  return (
    <Dialog onClose={handleClose} maxWidth={"lg"} open={isOpen}>
      <div
        className=""
        style={{
          position: "absolute",
          background: "#0089cf",
          borderRadius: "50%",
          padding: "4px",
          color: "#fff",
          top: "10px",
          right: "10px",
        }}
      >
        <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
      </div>
      <DialogContent
        style={{
          color: interfaceTextColor,
          background: interfaceBgColor,
          padding: "0",
        }}
      >
        <div className={Style.successImgWrap}>
          <img src={SuccessPopup} alt="success-img" />
        </div>
        <div className={Style.successContainer}>
          <div className={Style.heading}>Success !</div>
          <div className={Style.desc}>
            Congratulations! You have successfully achieved a{" "}
            {trainingDetail?.name} certificate.
          </div>
          <div className={Style.flexWrapper}>
            <div className={Style.backLink}>
              <Link to="/my-training">BACK TO TRAININGS</Link>
            </div>
            <div className={Style.flexWrap}>
              {module?.module?.assessment?.correct_answer_option && (
                <div>
                  <Link
                    to={`/progress-report/${training_id}/${module_id}/${assessment_id}`}
                  >
                    <button className={Style.openBtn}>OPEN</button>
                  </Link>
                </div>
              )}
              {Number(trainingDetail?.progress) === 100 && (
                <div>
                  <button
                    className={Style.downloadBtn}
                    onClick={() => {
                      navigate(
                        `/certificate/download/${training_id}/true/my-training`
                      );
                    }}
                  >
                    DOWNLOAD
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default TrainingSuccessModal;
