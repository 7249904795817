import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface ProgressState {
  progress: boolean;
}

export const initialState: ProgressState = {
  progress: false,
};

export const progressSlice = createSlice({
  name: "progress",
  initialState,
  reducers: {
    setProgress: (state, { payload }) => {
      state.progress = payload;
    },
  },
});

export const { setProgress } = progressSlice.actions;

export const progressSelector = (state: RootState) => state;

export default progressSlice.reducer;
