/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { LinearProgress } from "@mui/material";
import Box from "@mui/material/Box";
import { linearProgressClasses } from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useConfirm } from "material-ui-confirm";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DashboardNotification from "../../components/DashboardNotification";
import DataTable from "../../components/table/DataTable";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import ResumeSVG from "../../icons/ResumeSVG";
import ResumeTrainingSVG from "../../icons/ResumeTraining";
import ViewSVGAction from "../../icons/ViewSVGAction";
import { getDashboardInsights } from "../../services/dashboradService";
import {
  addSelfEnroleInTraining,
  getTraining,
} from "../../services/trainingsService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { setTrainingData } from "../../store/reducers/trainingsReducer";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import { confirmProps } from "../../utils/confirmProps";
import Style from "./AdminDashboard.module.scss";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const TraineeDashboard: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const [id, setId] = useState<any>();

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;
  const primaryColor =
    useColor("button_primary") || defaultThemeColor.button_primary;

  const dataDashboard = useSelector(
    (state: RootState) => state.dashboardReducer.data
  );

  const trainingDetail: any = useSelector(
    (state: RootState) => state.trainingsReducer.trainingData
  );

  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      dispatch(
        getDashboardInsights({
          start_date: "2023-08-10",
          end_date: "2023-10-10",
        })
      );
    }
  }, [alertMessage]);

  useEffect(() => {
    dispatch(
      getDashboardInsights({
        start_date: "2023-08-10",
        end_date: "2023-10-10",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (id) dispatch(getTraining(Number(id)));
  }, [id]);

  useEffect(() => {
    if (Object.keys(trainingDetail).length > 0 && id) {
      let continueId = trainingDetail.ordered_modules?.find(
        (i: any) =>
          (i.module.module_status == "BackLog" ||
            i.module.module_status == "InProgress") &&
          (i.module.type == "Video" ||
            i.module.type == "PDF" ||
            i.module.type == "Assessment")
      );
      if (continueId) {
        setTrainingData({});
        navigate(
          `/training/${trainingDetail?.id}/lectures/${continueId?.module?.id}/true`
        );
      } else if (
        trainingDetail?.ordered_modules &&
        trainingDetail?.ordered_modules.length > 0
      ) {
        setTrainingData({});
        navigate(
          `/training/${trainingDetail?.id}/lectures/${trainingDetail?.ordered_modules[0]?.module?.id}`
        );
      }
    }
  }, [trainingDetail]);

  const handleSelfEnrole = (id: number) => {
    confirm({
      title: t("Are you sure!"),
      description: t(`Do you want to enroll in this training?`),
      ...confirmProps,
    })
      .then(() => {
        dispatch(addSelfEnroleInTraining({ training_id: id }));
      })
      .catch(() => {});
  };

  return (
    <>
      <div className={Style.listingViewContainer}>
        <DashboardNotification />
        <div className={Style.myTraining}>
          <div className={Style.trainingContainer}>
            <div
              className={Style.trainingHeading}
              style={{ color: interfaceTextColor }}
            >
              {t("My Trainings")}
            </div>
            <div className={`${Style.outer_cover2}`}>
              <DataTable
                rows={
                  (dataDashboard &&
                    dataDashboard?.my_trainings &&
                    dataDashboard?.my_trainings?.map((training: any) => ({
                      name: (
                        <div
                          className={`${Style.training_detail}`}
                          style={{
                            textAlign: lang === "ar" ? "center" : "unset",
                          }}
                        >
                          {training?.name}
                          <div className={`${Style.category_head}`}>
                            {t("Category")}:
                            <span className={`${Style.category}`}>
                              {" "}
                              {training?.category?.name}
                            </span>
                          </div>
                        </div>
                      ),
                      progress: (
                        <div
                          style={{
                            textAlign: lang === "ar" ? "center" : "center",
                            width: "100%",
                          }}
                        >
                          {Math.round(training.progress) === 100 ? (
                            <span
                              className={Style.statusSuccess}
                              style={{
                                textAlign: "center",
                                // marginLeft: "5.7em",
                              }}
                            >
                              {"Completed"}
                            </span>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: lang === "ar" ? "center" : "unset",
                              }}
                            >
                              <Box sx={{ minWidth: 35, pb: 1.3 }}>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >{`${Math.round(
                                  training.progress
                                )}%`}</Typography>
                              </Box>
                              <Box sx={{ width: "100%", mr: 1 }}>
                                <BorderLinearProgress
                                  variant="determinate"
                                  value={Math.round(training.progress)}
                                />
                              </Box>
                            </Box>
                          )}
                        </div>
                      ),
                      actions: (
                        <Tooltip title={"View Training"} placement="top">
                          <Link
                            aria-label="view"
                            to={"/training-details/" + training?.id}
                          >
                            <ViewSVGAction />
                          </Link>
                        </Tooltip>
                      ),
                    }))) ||
                  []
                }
                columnData={[
                  {
                    id: "training_name",
                    name: t("Training Name"),
                    enableSort: true,
                    align: lang === "ar" ? "center" : "left",
                    disablePadding: "checkbox",
                  },
                  {
                    id: "detail",
                    name: t("Training Details"),
                    enableSort: false,
                    align: lang === "ar" ? "center" : "center",
                    disablePadding: "none",
                  },
                  {
                    id: "actions",
                    name: t("Actions"),
                    enableSort: false,
                    align: "center",
                    disablePadding: "none",
                  },
                ]}
                isTopCheckbox={false}
                isCheckboxes={false}
                isSingleSelectRowOnly={false}
                bottomButtonsComponent={true}
                isSelectedRowsOnly={false}
                hideBottomRow={true}
                selectedRowsPerPage={""}
                totalRecordCount={1}
                handleChangePageParent={""}
                pagenum={1}
                handleChangeRowsPerPageParent={""}
                selectedCheckBox={[]}
                boxShadow={true}
                rowsScroll={true}
              />
            </div>
            <div
              className={Style.viewAllBtn}
              style={{ color: interfaceTextColor }}
            >
              {dataDashboard &&
                dataDashboard?.my_trainings &&
                dataDashboard?.my_trainings.length > 0 && (
                  <Link
                    to={`/my-training`}
                    style={{ color: interfaceTextColor }}
                  >
                    {t("View all")}
                  </Link>
                )}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-wrap below-flex">
        <div className={`${Style.topTraining} col-lg-6 px-0 mr-3`}>
          <div className={Style.trainingContainer}>
            <div
              className={Style.trainingHeading}
              style={{ color: interfaceTextColor }}
            >
              {t("Top Trainings")}
            </div>
            <div className={`${Style.outer_cover2}`}>
              <DataTable
                rows={
                  (dataDashboard &&
                    dataDashboard?.top_trainings &&
                    dataDashboard?.top_trainings?.map((training: any) => ({
                      name: (
                        <div
                          style={{
                            textAlign: lang === "ar" ? "center" : "unset",
                          }}
                        >
                          {training?.name}
                        </div>
                      ),
                      category: training?.category?.name,
                      level: training?.level,
                      count: (
                        <div
                          style={{
                            textAlign: lang === "ar" ? "center" : "unset",
                          }}
                        >
                          {training?.member_count}
                        </div>
                      ),
                      actions: (
                        <>
                          <Tooltip title={t("View Training")} placement="top">
                            <Link
                              aria-label="view"
                              to={"/training-details/" + training?.id}
                            >
                              <ViewSVGAction />
                            </Link>
                          </Tooltip>
                          {dataDashboard?.my_trainings
                            .map((i: any) => i.id)
                            .includes(training?.id) ? (
                            <Tooltip
                              title={t("Resume Training")}
                              placement="top"
                              arrow
                            >
                              <button
                                type="button"
                                className="btn-link"
                                onClick={() => {
                                  setId(training?.id);
                                }}
                              >
                                <ResumeTrainingSVG
                                  color={primaryColor}
                                ></ResumeTrainingSVG>
                              </button>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={t("Enroll to Training")}
                              placement="top"
                            >
                              <button
                                type="button"
                                className="btn-link"
                                onClick={() =>
                                  handleSelfEnrole(Number(training?.id))
                                }
                              >
                                <ResumeSVG color={primaryColor} />
                              </button>
                            </Tooltip>
                          )}
                        </>
                      ),
                    }))) ||
                  []
                }
                columnData={[
                  {
                    id: "training_name",
                    name: t("Training Name"),
                    enableSort: true,
                    align: lang === "ar" ? "center" : "left",
                    disablePadding: "checkbox",
                  },
                  {
                    id: "category",
                    name: t("Category"),
                    enableSort: true,
                    align: lang === "ar" ? "center" : "left",
                    disablePadding: "none",
                  },
                  {
                    id: "level",
                    name: t("Level"),
                    enableSort: true,
                    align: lang === "ar" ? "center" : "left",
                    disablePadding: "none",
                  },
                  {
                    id: "no_of_enrollment",
                    name: t("No of Enrollment"),
                    enableSort: true,
                    align: lang === "ar" ? "center" : "left",
                    disablePadding: "none",
                  },

                  {
                    id: "actions",
                    name: t("Actions"),
                    enableSort: false,
                    align: lang === "ar" ? "center" : "center",
                    disablePadding: "none",
                  },
                ]}
                isTopCheckbox={false}
                isCheckboxes={false}
                isSingleSelectRowOnly={false}
                bottomButtonsComponent={true}
                isSelectedRowsOnly={false}
                hideBottomRow={true}
                selectedRowsPerPage={""}
                totalRecordCount={1}
                handleChangePageParent={""}
                pagenum={1}
                handleChangeRowsPerPageParent={""}
                selectedCheckBox={[]}
                boxShadow={true}
                rowsScroll={true}
              />
            </div>
            <div
              className={Style.viewAllBtn}
              style={{ color: interfaceTextColor }}
            >
              {dataDashboard &&
                dataDashboard?.top_trainings &&
                dataDashboard?.top_trainings.length > 0 && (
                  <Link
                    to={`/browse-trainings`}
                    style={{ color: interfaceTextColor }}
                  >
                    {t("View all")}
                  </Link>
                )}
            </div>
          </div>
        </div>

        <div
          className={`${Style.topTraining} col-lg-6 ${
            lang === "ar" ? "pe-3" : "pe-0"
          }`}
        >
          <div className={Style.trainingContainer}>
            <div
              className={Style.trainingHeading}
              style={{ color: interfaceTextColor }}
            >
              {t("Completed Trainings")}
            </div>
            <div className={`${Style.outer_cover2}`}>
              <DataTable
                rows={
                  (dataDashboard &&
                    dataDashboard?.completed_trainings &&
                    dataDashboard?.completed_trainings?.map(
                      (training: any) => ({
                        name: (
                          <div
                            style={{
                              textAlign: lang === "ar" ? "center" : "unset",
                            }}
                          >
                            {training?.name}
                          </div>
                        ),
                        category: training?.category?.name,
                        level: training?.level,
                        action: (
                          <div style={{ textAlign: "center" }}>
                            <Tooltip title={"View Training"} placement="top">
                              <Link
                                aria-label="view"
                                to={"/training-details/" + training?.id}
                              >
                                <ViewSVGAction />
                              </Link>
                            </Tooltip>
                          </div>
                        ),
                      })
                    )) ||
                  []
                }
                columnData={[
                  {
                    id: "training_name",
                    name: t("Training Name"),
                    enableSort: true,
                    align: lang === "ar" ? "center" : "left",
                    disablePadding: "checkbox",
                  },
                  {
                    id: "category",
                    name: t("Category"),
                    enableSort: true,
                    align: lang === "ar" ? "center" : "center",
                    disablePadding: "none",
                  },
                  {
                    id: "level",
                    name: t("Level"),
                    enableSort: true,
                    align: lang === "ar" ? "center" : "center",
                    disablePadding: "none",
                  },

                  {
                    id: "actions",
                    name: t("Actions"),
                    enableSort: false,
                    align: lang === "ar" ? "center" : "center",
                    disablePadding: "none",
                  },
                ]}
                isTopCheckbox={false}
                isCheckboxes={false}
                isSingleSelectRowOnly={false}
                bottomButtonsComponent={true}
                isSelectedRowsOnly={false}
                hideBottomRow={true}
                selectedRowsPerPage={""}
                totalRecordCount={1}
                handleChangePageParent={""}
                pagenum={1}
                handleChangeRowsPerPageParent={""}
                selectedCheckBox={[]}
                boxShadow={true}
                rowsScroll={true}
              />
            </div>
            <div
              className={Style.viewAllBtn}
              style={{ color: interfaceTextColor }}
            >
              {dataDashboard &&
                dataDashboard?.completed_trainings &&
                dataDashboard?.completed_trainings?.length > 0 && (
                  <Link
                    to={`/my-training`}
                    style={{ color: interfaceTextColor }}
                  >
                    {t("View all")}
                  </Link>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TraineeDashboard;
