import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
export interface DataList {
  count: number;
  next: string | null;
  previous: String | null;
  results: any[];
}
export interface InitState {
  data: any[];
  wholedata: DataList | any;
  page?: number;
  perPage?: number;
  search?: string;
  detail?: any;
  wholedetail?: any;
}

export const initialState: InitState = {
  data: [],
  wholedata: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  wholedetail: {},
  page: 1,
  perPage: 10,
  search: "",
};

export const companyIntegrationSlice = createSlice({
  name: "companyIntegration",
  initialState,
  reducers: {
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setWholeData: (state, { payload }) => {
      state.wholedata = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setPerPage: (state, { payload }) => {
      state.perPage = payload;
    },
    setSearch: (state, { payload }) => {
      state.search = payload;
    },
    setDetail: (state, { payload }) => {
      state.detail = payload;
    },
    setWholeDetail: (state, { payload }) => {
      state.wholedetail = payload;
    },
  },
});

export const {
  setData,
  setWholeData,
  setDetail,
  setWholeDetail,
  setSearch,
  setPerPage,
  setPage,
} = companyIntegrationSlice.actions;

export const userSelector = (state: RootState) => state;

export default companyIntegrationSlice.reducer;
