import { useEffect } from "react";
import Style from "./DetailsGroup.module.scss";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import SubmitButton from "../../components/SubmitButton";
import { updateGroupName } from "../../services/groupManagementService";
import {
  alertMessageSelector,
  setErrors,
} from "../../store/reducers/alertMessageReducer";
import { useAppDispatch } from "../../store/store";

import { Box, Modal, Typography } from "@mui/material";
import useColor from "../../hooks/useColor";
import { defaultThemeColor } from "../../utils/colors_values";
import { useTranslation } from "react-multi-lang";
import useCompanyLanguage from "../../hooks/language";
import CancelButton from "../../components/CancelButton";

const schema = yup
  .object({
    name: yup.string().required("Group name is required"),
  })
  .required();

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #fff",
  boxShadow: 24,
  p: 4,
};
interface Params {
  openEditModal: boolean;
  CloseEditModal: any;
  detail: any;
  id: Number;
}
function EditGroupModal(props: Params) {
  const { openEditModal, CloseEditModal, detail, id } = props;
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;

  const {
    reset,
    control,
    setError,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  useEffect(() => {
    if (alertMessage?.errors) {
      if (alertMessage?.errors?.name?.length > 0) {
        toast.error(alertMessage?.errors?.name?.toString(), {
          toastId: alertMessage?.errors?.name?.toString(),
        });
        setError("name", {
          type: "server",
          message: alertMessage?.errors?.name?.toString(),
        });
      }

      if (alertMessage?.errors) {
        dispatch(setErrors(null));
      }
    }
  }, [alertMessage]);

  const Submit = (payload: any) => {
    dispatch(updateGroupName(Number(id), payload));
  };
  useEffect(() => {
    let defaultValues = { name: detail?.name };
    reset({ ...defaultValues });
  }, [openEditModal]);
  return (
    <>
      <Modal
        open={openEditModal}
        onClose={CloseEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{
            background:
              useColor("interface_foreground_color") ||
              defaultThemeColor.interface_foreground_color,
            border: "none",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{
              color:
                useColor("interface_foreground_text_color") ||
                defaultThemeColor.interface_foreground_text_color,
            }}
          >
            {t("Edit group Name")}
          </Typography>
          <Box id="modal-modal-description" sx={{ mt: 2 }}>
            <form onSubmit={handleSubmit(Submit)} autoComplete="off">
              <div className={`d-flex flex-wrap gap-5`}>
                <div className="w-100">
                  <span
                    className="form-label"
                    style={{
                      color:
                        useColor("interface_foreground_text_color") ||
                        defaultThemeColor.interface_foreground_text_color,
                    }}
                  >
                    {t("Group Name")}
                    <span className="color-red"> *</span>
                  </span>
                  <input
                    className={`form-input ${errors.name ? "is-invalid" : ""}`}
                    type="text"
                    placeholder={t("Name")}
                    autoComplete="off"
                    {...register("name")}
                  />
                  <p className="error color-red">{errors.name?.message}</p>
                </div>
              </div>
              <div className="d-flex gap-3 align-items-center float-end">
                <SubmitButton label={t("Update")} />
                {/* <button
                  type="button"
                  className={`btn mt-5 ${Style.cancelBtn}`}
                  style={{
                    color:
                      useColor("button_secondary_text") || defaultThemeColor.button_secondary_text,
                    background: useColor("button_secondary") || defaultThemeColor.button_secondary,
                    border: "none",
                  }}
                  onClick={CloseEditModal}
                >
                  {t("Cancel")}
                </button> */}
                <CancelButton
                  size="small"
                  outlined={true}
                  onClick={CloseEditModal}
                  textColor=""
                  outlineColor=""
                  text={t("Cancel")}
                  className={` mt-5`}
                  bg={""}
                ></CancelButton>
              </div>
            </form>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default EditGroupModal;
