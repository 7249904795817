/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import AddIcon from "@mui/icons-material/Add";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import UnarchiveOutlinedIcon from "@mui/icons-material/UnarchiveOutlined";
import {
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  MenuItem,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Menu from "@mui/material/Menu";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { useConfirm } from "material-ui-confirm";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import { toast } from "react-toastify";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import ExportCSV from "../../components/ExportCSV/ExportCSV";
import Header from "../../components/Header";
import Style from "./UserList.module.scss";

import Paginations from "../../components/table/Paginations";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import usePermission from "../../hooks/usePermission";
import DeleteSVG from "../../icons/DeleteSVG";
import EditSVG from "../../icons/EditSVG";
import SearchSVG from "../../icons/SearchSVG";
import ViewSVGAction from "../../icons/ViewSVGAction";
import HeadIcon from "../../images/head.png";
import axiosPrivate from "../../lib/axiosPrivate";
import {
  bulkDeleteUsers,
  deleteUser,
  getAllUsers,
  searchUsers,
  updateBulkADUserRole,
  updateBulkStatus,
  updateUser,
} from "../../services/userService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import {
  setDeleteUserData,
  setGetUserDataFailed,
  setUpdateUserData,
  setWholeUserListData,
  userSelector,
} from "../../store/reducers/userReducers";
import { RootState, useAppDispatch, useAppSelector } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import { confirmProps } from "../../utils/confirmProps";
import ActiveDirectoryUserModel from "./ActiveDirectoryUserModel";
const $ = require("jquery");

const SearchTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#A0AAB4",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& #custom-css-outlined-input": {
    zIndex: 1,
    padding: 7.5,
    paddingLeft: 2,
    fontSize: "12px",
    width: "14rem",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      backgroundColor: "white",
      borderColor: "white",
      borderRadius: 50,
      fontFamily: "dmSans",
      fontSize: "14px",
      fontWeight: 400,
      letterSpacing: "-0.0175rem",
      width: "14rem",
      zIndex: 0,
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6F7E8C",
    },
  },
});

function UserList() {
  const archiveUnarchivePermission = usePermission("archive_unarchive_user");
  const lockUnlockPermission = usePermission("lock_unlock_user");
  const editUpdatePermission = usePermission("change_user");
  const deletePermission = usePermission("delete_user");
  const viewPermission = usePermission("view_user");
  const userInfo = useSelector((state: any) => state.authReducer.loginData);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const states = useSelector(userSelector).userReducer;
  const confirm = useConfirm();
  const defaultDate = { startDate: undefined, endDate: undefined };
  const [openCreatedDate, setOpenCreatedDate] = React.useState(false);
  const [dateRangeCreated, setDateRangeCreated] = React.useState<any>();
  const [openUpdatedDate, setOpenUpdatedDate] = React.useState(false);
  const [dateRangeUpdated, setDateRangeUpdated] = React.useState<any>();
  const [status, setStatus] = React.useState<any>();
  const [openActiveDirectoryUserModel, setOpenActiveDirectoryUserModel] =
    React.useState<any>(false);
  const [saveActiveDirectoryUserModel, setSaveActiveDirectoryUserModel] =
    React.useState<any>(false);
  const [rolesStateActiveDirectoryUser, setRolesStateActiveDirectoryUser] =
    useState("");

  const [membersActiveDirectoryUserModel, setMembersActiveDirectoryUserModel] =
    useState<any>([]);
  console.log(
    "rolesStateActiveDirectoryUser",
    membersActiveDirectoryUserModel?.length,
    rolesStateActiveDirectoryUser,
    saveActiveDirectoryUserModel
  );
  useEffect(() => {
    console.log(
      "Submit",
      membersActiveDirectoryUserModel?.length,
      rolesStateActiveDirectoryUser,
      saveActiveDirectoryUserModel
    );
    if (
      saveActiveDirectoryUserModel &&
      membersActiveDirectoryUserModel?.length > 0 &&
      rolesStateActiveDirectoryUser != ""
    ) {
      dispatch(
        updateBulkADUserRole(
          membersActiveDirectoryUserModel,
          rolesStateActiveDirectoryUser
        )
      );
    }
  }, [
    saveActiveDirectoryUserModel,
    membersActiveDirectoryUserModel,
    rolesStateActiveDirectoryUser,
  ]);
  const t = useTranslation();
  const lang = useCompanyLanguage();

  const toggleCreated = () => {
    setDateRangeCreated(defaultDate);
    setOpenCreatedDate(true);
  };

  const toggleUpdated = () => {
    setDateRangeUpdated(defaultDate);
    setOpenUpdatedDate(true);
  };

  const handleClicked = () => {
    getWholeUsers();
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [singleRowData, setSingleRowData] = useState<any>();
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<any[]>([]);
  const [showRecordNotFound, setShowRecordNotFound] = React.useState(false);
  const isLoading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  const userListData = useSelector(
    (state: RootState) => state.userReducer.user_list_data
  );
  const userListWholeData = useSelector(
    (state: RootState) => state.userReducer.user_list_whole_data
  );
  const updateUserData = useSelector(
    (state: RootState) => state.userReducer.update_user_data
  );
  const deleteUserData = useSelector(
    (state: RootState) => state.userReducer.delete_user_data
  );
  const active_directory_users = useSelector(
    (state: RootState) => state.userReducer.active_directory_users
  );
  console.log("active_directory_users", active_directory_users);
  const [rows, setRows] = useState<any[]>([]);
  const alertMessage = useAppSelector(alertMessageSelector).alertMessageReducer;
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [valueDepartment, onChangeDepartment] = useState<any>();
  const [rolesState, setRolesState] = useState("");
  const [statusState, setStatusState] = useState("");
  const [searchTable, setSearchTable] = useState("");
  const departmentHandle = (e: any) => {
    setRowsPerPage(10);
    setPage(1);
    onChangeDepartment(e);
  };

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      setSelectedCheckBox([]);
      getUsers();
    }
  }, [alertMessage]);

  useEffect(() => {
    if (isLoading == false) {
      setTimeout(() => {
        setShowRecordNotFound(true);
      }, 1000);
    } else {
      setShowRecordNotFound(false);
    }
  }, [isLoading]);

  const handleClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setSingleRowData(row);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckboxClick = (event: any, id: number) => {
    const checked = event.target.checked;
    if (checked) {
      setSelectedCheckBox([
        ...(selectedCheckBox?.map((r: any) => r) ?? []),
        id,
      ]);
    } else {
      setSelectedCheckBox(
        selectedCheckBox?.filter(function (val) {
          return val !== id;
        })
      );
    }
  };

  useEffect(() => {
    if (openCreatedDate && dateRangeCreated)
      $("#layout").css("overflow", "hidden");
    else $("#layout").css("overflow", "auto");
  }, [dateRangeCreated, openCreatedDate]);

  useEffect(() => {
    if (openUpdatedDate && dateRangeUpdated)
      $("#layout").css("overflow", "hidden");
    else $("#layout").css("overflow", "auto");
  }, [dateRangeUpdated, openUpdatedDate]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n?.id);
      setSelectedCheckBox(newSelected);
      return;
    }
    setSelectedCheckBox([]);
  };

  useEffect(() => {
    let statuses: any = [];
    selectedCheckBox &&
      selectedCheckBox.length > 0 &&
      userListData?.results?.forEach((element: any) => {
        if (selectedCheckBox.includes(element?.id))
          statuses = [...statuses, element?.status];
      });

    if (statuses?.filter((i: any) => i == "Active").length == statuses.length) {
      setStatus("Active");
    } else if (
      statuses?.filter((i: any) => i == "Locked").length == statuses.length
    ) {
      setStatus("Locked");
    } else if (
      statuses?.filter((i: any) => i == "Archived").length == statuses.length
    ) {
      setStatus("Archived");
    } else if (
      statuses?.filter((i: any) => i == "Active").length > 0 &&
      statuses?.filter((i: any) => i == "Archived").length > 0 &&
      statuses?.filter((i: any) => i == "Locked").length > 0
    ) {
      setStatus("ShowAll");
    } else if (
      statuses?.filter((i: any) => i == "Active").length > 0 &&
      statuses?.filter((i: any) => i == "Archived").length > 0 &&
      statuses?.filter((i: any) => i == "Locked").length == 0
    ) {
      setStatus("ShowUnArchived");
    } else if (
      statuses?.filter((i: any) => i == "Active").length > 0 &&
      statuses?.filter((i: any) => i == "Archived").length == 0 &&
      statuses?.filter((i: any) => i == "Locked").length > 0
    ) {
      setStatus("ShowUnlocked");
    } else if (
      statuses?.filter((i: any) => i == "Active").length == 0 &&
      statuses?.filter((i: any) => i == "Archived").length > 0 &&
      statuses?.filter((i: any) => i == "Locked").length > 0
    ) {
      setStatus("ShowAll");
    }
  }, [selectedCheckBox]);

  const isSelected = (id: any) => selectedCheckBox?.indexOf(id) !== -1;

  const handleAddNewUser = () => {
    navigate("/add-user");
  };

  const handleRowAction = (row: any, action: string) => {
    setAnchorEl(null);
    if (action === "delete") {
      dispatch(deleteUser({ id: row?.id }));
    }

    if (
      action === "archive" ||
      action === "lock" ||
      action === "unlock" ||
      action === "unarchive"
    )
      dispatch(
        updateUser({
          id: row?.id,
          status:
            action === "archive"
              ? "Archived"
              : action === "unarchive"
              ? "Active"
              : action === "unlock"
              ? "Active"
              : "Locked",
          email: "",
          password: "",
        })
      );
  };

  const handleBulkAction = (action: string) => {
    let act = "";
    if (action === "archive") {
      act = "Archived";
    } else if (action === "unarchive") {
      act = "Active";
    } else if (action === "unlock") {
      act = "Active";
    } else if (action === "lock") {
      act = "Locked";
    }
    dispatch(updateBulkStatus(selectedCheckBox, act));
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setSelectedCheckBox([]);
    setPage(page);
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPage(1);
    setSelectedCheckBox([]);
    setRowsPerPage(rowsPerPage);
  };

  useEffect(() => {
    if (states?.errors) {
      if (states?.errors) {
        dispatch(setGetUserDataFailed(null));
      }
    }
    if (states?.errors) {
      toast.error(String(states?.errors), {
        toastId: String(states?.errors),
      });
    }
  }, [states]);

  useEffect(() => {
    let data = userListData.results;
    if (Object.keys(userListData).length === 0) {
      setRows([]);
    } else setRows(data);
  }, [userListData]);

  useEffect(() => {
    if (deleteUserData && Object.keys(deleteUserData).length === 0) {
    } else if (deleteUserData) {
      getUsers();
      if (deleteUserData?.message) {
        toast.success(String(deleteUserData?.message), {
          toastId: String(deleteUserData?.message),
        });
        dispatch(setDeleteUserData(null));
      }
    }
  }, [deleteUserData]);

  useEffect(() => {
    if (updateUserData && Object.keys(userListData).length === 0) {
    } else if (updateUserData) {
      getUsers();
      if (updateUserData?.status) {
        toast.success(
          t(
            `User ${
              updateUserData?.status.toLowerCase() == "active"
                ? "status changed to Active"
                : updateUserData?.status.toLowerCase()
            } successfully!`
          ),
          {
            toastId: t(
              `User updated ${updateUserData?.status.toLowerCase()} successfully!`
            ),
          }
        );
        dispatch(setUpdateUserData(null));
      }
    }
  }, [updateUserData]);

  useEffect(() => {
    if (dateRangeCreated?.startDate && dateRangeCreated?.endDate) {
      setOpenCreatedDate(false);
    }
    if (dateRangeUpdated?.startDate && dateRangeUpdated?.endDate) {
      setOpenUpdatedDate(false);
    }

    getUsers();
  }, [
    page,
    rowsPerPage,
    dateRangeCreated?.startDate,
    dateRangeCreated?.endDate,
    dateRangeUpdated?.startDate,
    dateRangeUpdated?.endDate,
    rolesState,
    statusState,
    valueDepartment?.value,
    searchTable,
  ]);

  // useEffect(() => {
  //   if (page === 1) {
  //     getUsers();
  //   } else {
  //     setPage(1);
  //   }
  // }, [searchTable, rolesState, statusState, valueDepartment?.value]);

  // useEffect(() => {

  //   if (
  //     dateRangeCreated?.startDate ||
  //     dateRangeUpdated?.startDate ||
  //     dateRangeCreated == null ||
  //     dateRangeUpdated == null
  //   ) {
  //     getUsers();
  //   }
  // }, [dateRangeCreated, dateRangeUpdated]);

  useEffect(() => {}, [dateRangeCreated]);

  const getUsers = () => {
    const createdStartDate = dateRangeCreated?.startDate
      ? moment(new Date(dateRangeCreated?.startDate)).format("YYYY-MM-DD")
      : "";
    const createdEndDate = dateRangeCreated?.endDate
      ? moment(new Date(dateRangeCreated?.endDate)).format("YYYY-MM-DD")
      : "";
    const updatedStartDate = dateRangeUpdated?.startDate
      ? moment(new Date(dateRangeUpdated?.startDate)).format("YYYY-MM-DD")
      : "";
    const updatedEndDate = dateRangeUpdated?.endDate
      ? moment(new Date(dateRangeUpdated?.endDate)).format("YYYY-MM-DD")
      : "";
    dispatch(
      searchUsers({
        search: searchTable,
        primary_role__in: rolesState || "Trainer,Trainee",
        is_superuser: "False",
        status: statusState,
        page: page,
        page_size: rowsPerPage,
        department__id: valueDepartment?.value ? valueDepartment?.value : "",
        created_at_from: createdStartDate,
        created_at_to: createdEndDate,
        updated_at_from: updatedStartDate,
        updated_at_to: updatedEndDate,
      })
    );
  };

  const getWholeUsers = () => {
    if (userListData)
      dispatch(
        getAllUsers({
          search: "",
          status: "",
          primary_role__in: rolesState || "Trainer,Trainee",
          page: 1,
          is_superuser: "False",
          page_size: userListData?.count,
        })
      );
  };

  const conveetNestedToFlatten = (accounts: any, showChild: boolean) => {
    var a: any = [];
    for (var i = 0; i < accounts.length; i++) {
      if (showChild) {
        if (accounts[i].children) {
          a = a.concat(conveetNestedToFlatten(accounts[i].children, showChild));
        }
      }
      a.push(accounts[i]);
    }
    return a;
  };

  const loadOptions = async (
    searchQuery: any,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await axiosPrivate.get(
      `accounts/department/?page=${page}&page_size=100&search=${searchQuery}`
    );
    const data = response.data;
    const showChild = searchQuery ? false : true;
    const dataFlatten = conveetNestedToFlatten(data?.results, showChild);
    const options = dataFlatten?.map((row: any) => {
      return {
        label: row.name || "N/A",
        value: row.id,
      };
    });

    const users = {
      options: options || [],
      hasMore: data?.next ? true : false,
      additional: {
        page: searchQuery ? 1 : page + 1,
      },
    };

    return users;
  };
  const bulkDelete = (ids: any) => {
    console.log("ids ids ids", ids);

    confirm({
      title: t("Are you sure!"),
      description: t(`Do you want to delete selected user(s)?`),
      ...confirmProps,
    })
      .then(() => {
        dispatch(bulkDeleteUsers(ids));
        setSelectedCheckBox([]);
      })
      .catch(() => {});
  };
  const BgTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;
  const BgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;
  const primaryButton =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;
  const primaryButtonBg =
    useColor("button_primary") || defaultThemeColor.button_primary;

  return (
    <div className={Style.manageSpace}>
      <div
        className={`${Style.background}`}
        style={{
          background:
            useColor("interface_background_color") ||
            defaultThemeColor.interface_background_color,
        }}
      >
        <div>
          <Header page={t("User Management")} mainHead={t("User Management")} />
          <div
            className={`d-flex justify-content-between align-items-center flex-wrap ${Style.userFilters}`}
            style={{ marginTop: 1 }}
          >
            <div className={``}>
              <FormControl
                variant="standard"
                sx={{ m: 1, mr: 3, minWidth: 67 }}
              >
                <InputLabel
                  id="demo-simple-select-autowidth-label"
                  style={{ fontSize: "12px" }}
                  disableAnimation={true}
                  shrink={false}
                ></InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  style={{ color: BgTextColor, background: BgColor }}
                  value={rowsPerPage}
                  onChange={(e) => {
                    setPage(1);
                    setRowsPerPage(Number(e.target.value));
                  }}
                  autoWidth
                  disableUnderline
                  MenuProps={{ PaperProps: { style: { background: BgColor } } }}
                  sx={{
                    ".MuiSvgIcon-root ": {
                      fill: BgTextColor,
                    },
                  }}
                >
                  <MenuItem
                    style={{ color: BgTextColor, background: BgColor }}
                    value={userListData.count}
                  >
                    {t("All")} ({userListData.count || 0})
                  </MenuItem>
                  <MenuItem
                    // style={{ color: BgTextColor, background: BgColor }}
                    value="10"
                  >
                    10 {t("Per Page")}
                  </MenuItem>
                  <MenuItem
                    // style={{ color: BgTextColor, background: BgColor }}
                    value="15"
                  >
                    15 {t("Per Page")}
                  </MenuItem>
                  <MenuItem
                    // style={{ color: BgTextColor, background: BgColor }}
                    value="25"
                  >
                    25 {t("Per Page")}
                  </MenuItem>
                </Select>
              </FormControl>
              {usePermission("add_user") && (
                <FormControl sx={{ mt: 2.4, mr: 3, minWidth: 80 }}>
                  <Button
                    variant="text"
                    color="inherit"
                    endIcon={
                      <AddIcon
                        style={{
                          fontSize: 16,
                          color: BgTextColor,
                          marginRight: lang === "ar" ? "10px" : "0px",
                        }}
                      />
                    }
                    style={{
                      fontSize: 13,
                      color: BgTextColor,
                      textTransform: "capitalize",
                    }}
                    onClick={() => handleAddNewUser()}
                  >
                    {t("Add New")}
                  </Button>
                </FormControl>
              )}
              {userInfo?.is_superadmin && userInfo?.primary_role == "Admin" && (
                <FormControl sx={{ mt: 2.4, mr: 3, minWidth: 80 }}>
                  <Button
                    variant="text"
                    color="inherit"
                    endIcon={
                      <GroupAddOutlinedIcon
                        style={{
                          fontSize: 16,
                          color: BgTextColor,
                          marginRight: lang === "ar" ? "10px" : "0px",
                        }}
                      />
                    }
                    style={{
                      fontSize: 13,
                      color: BgTextColor,
                      textTransform: "capitalize",
                    }}
                    onClick={(e: any) => {
                      navigate("/active-directory-users");
                      // setOpenActiveDirectoryUserModel(true);
                      // dispatch(syncActiveDirectoryUser());
                    }}
                    // className={`d-flex btn btn-primary gap-2 align-items-center justify-content-center `}
                  >
                    {t("Import Users")}
                  </Button>
                </FormControl>
              )}
            </div>

            <div
              className={`d-flex align-items-center gap-3 ${Style.fitersStart}`}
            >
              <>
                <div className="px-3">
                  <ExportCSV
                    setParentData={() => dispatch(setWholeUserListData({}))}
                    handleClicked={handleClicked}
                    rows={userListWholeData?.results?.map(
                      (rw: any, i: any) => ({
                        num: i + 1,
                        user_name: rw.first_name + " " + rw.last_name,
                        email: rw.email,
                        department: rw.department?.name || "N/A",
                        roles: rw.primary_role,
                        created_at:
                          moment(new Date(rw.created_at)).format(
                            "MMM Do, YYYY"
                          ) || "",
                        updated_at:
                          moment(new Date(rw.updated_at)).format(
                            "MMM Do, YYYY"
                          ) || "",
                        status: rw.status,
                        email_status: rw.is_email_verified
                          ? "Verified"
                          : "Not Verified",
                      })
                    )}
                    headers={[
                      t("No"),
                      t("Username"),
                      t("Email"),
                      t("Department"),
                      t("Roles"),
                      t("Created at"),
                      t("Updated at"),
                      t("Status"),
                      t("Email Status"),
                    ]}
                    filename={`user_managegemt_list-${moment().format()}.csv`}
                  />
                </div>
                {/* )} */}
                {usePermission("change_user") && (
                  <>
                    {status == "Active" ||
                    status == "Locked" ||
                    status == "ShowUnlocked" ? null : (
                      <IconButton
                        className={Style.userFilterButton}
                        sx={{ m: 0, p: 0, mr: 1 }}
                        disabled={selectedCheckBox.length > 0 ? false : true}
                        onClick={() => {
                          confirm({
                            title: "Are you sure!",
                            description: `Do you want to unarchive selected account's?`,
                            ...confirmProps,
                          })
                            .then(() => {
                              handleBulkAction("unarchive");
                            })
                            .catch((e) => {
                              // console.log(e);
                            });
                        }}
                      >
                        <UnarchiveOutlinedIcon
                          className={`mx-1 ${
                            selectedCheckBox.length > 0 && `${Style.svgIcon}`
                          }`}
                        />
                        {t("Unarchive")}
                      </IconButton>
                    )}{" "}
                    {status == "Locked" ||
                    status == "Active" ||
                    status == "ShowAll" ||
                    status == "ShowUnlocked" ||
                    status == "ShowUnArchived" ? (
                      <IconButton
                        className={Style.userFilterButton}
                        disabled={selectedCheckBox.length > 0 ? false : true}
                        sx={{ m: 0, p: 0, mr: 1 }}
                        onClick={() => {
                          confirm({
                            title: t("Are you sure!"),
                            description: t(
                              `Do you want to archive selected account's?`
                            ),
                            ...confirmProps,
                          })
                            .then(() => {
                              handleBulkAction("archive");
                            })
                            .catch((e) => {
                              // console.log(e);
                            });
                        }}
                      >
                        <ArchiveOutlinedIcon
                          className={`mx-1 ${
                            selectedCheckBox.length > 0 && `${Style.svgIcon}`
                          }`}
                        />
                        {t("Archive")}
                      </IconButton>
                    ) : null}
                    {status == "Active" ? null : status == "Locked" ||
                      status == "ShowAll" ||
                      status == "ShowUnlocked" ? (
                      <IconButton
                        className={Style.userFilterButton}
                        sx={{ m: 0, p: 0, mr: 1 }}
                        disabled={selectedCheckBox.length > 0 ? false : true}
                        onClick={() => {
                          confirm({
                            title: t("Are you sure!"),
                            description: t(
                              `Do you want to unlock selected account's?`
                            ),
                            ...confirmProps,
                          })
                            .then(() => {
                              handleBulkAction("unlock");
                            })
                            .catch((e) => {
                              // console.log(e);
                            });
                        }}
                      >
                        <LockOpenTwoToneIcon
                          className={`mx-1 ${
                            selectedCheckBox.length > 0 && `${Style.svgIcon}`
                          }`}
                        />
                        {t("Unlock")}
                      </IconButton>
                    ) : null}{" "}
                    {status == "Locked" ? null : (
                      <IconButton
                        className={Style.userFilterButton}
                        disabled={selectedCheckBox.length > 0 ? false : true}
                        sx={{ m: 0, p: 0, mr: 1 }}
                        onClick={() => {
                          confirm({
                            title: t("Are you sure!"),
                            description: t(
                              `Do you want to lock selected account's?`
                            ),
                            ...confirmProps,
                          })
                            .then(() => {
                              handleBulkAction("lock");
                            })
                            .catch((e) => {
                              // console.log(e);
                            });
                        }}
                      >
                        <LockOutlinedIcon
                          className={`mx-1 ${
                            selectedCheckBox.length > 0 && `${Style.svgIcon}`
                          }`}
                        />
                        {t("Lock")}
                      </IconButton>
                    )}
                  </>
                )}
                {deletePermission && (
                  <Button
                    variant="text"
                    color="inherit"
                    disabled={selectedCheckBox?.length > 0 ? false : true}
                    onClick={(e: any) => {
                      bulkDelete(selectedCheckBox);
                    }}
                    className={`d-flex gap-2 align-items-center justify-content-center ${
                      selectedCheckBox.length > 0
                        ? Style.removeBtn
                        : Style.disabledBorder
                    }`}
                  >
                    <DeleteSVG
                      color={
                        selectedCheckBox.length > 0
                          ? "#FF0000"
                          : "rgba(0, 0, 0, 0.26)"
                      }
                    />
                    {t("Remove")}
                  </Button>
                )}

                <SearchTextField
                  autoComplete="off"
                  sx={{ mt: 0.3, mr: 0 }}
                  style={{ width: "14rem" }}
                  placeholder={t("Search")}
                  onChange={(e) => {
                    setPage(1);
                    setSearchTable(e.target.value);
                  }}
                  id="custom-css-outlined-input"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ pl: 1 }}>
                        <SearchSVG />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: false,
                  }}
                />
              </>
            </div>
          </div>
        </div>
        <div className="" style={{ width: "100%" }}>
          <table className={`table ${Style.customTable} table-hover`}>
            <thead>
              <tr>
                {usePermission("change_user") && (
                  <th>
                    <Checkbox
                      className={Style.headerCheck}
                      color="primary"
                      indeterminate={
                        selectedCheckBox?.length > 0 &&
                        selectedCheckBox?.length < rows?.length
                      }
                      checked={
                        rows?.length > 0 &&
                        selectedCheckBox?.length === rows?.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  </th>
                )}

                <th className={Style.dataItemWidth}>{t("Username")}</th>
                <th className={Style.dataItemWidth}>{t("Email")}</th>
                <th>
                  <FormControl sx={{ minWidth: "161px" }}>
                    <AsyncPaginate
                      loadOptions={loadOptions}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      onChange={(e: any) => {
                        departmentHandle(e);
                      }}
                      value={valueDepartment}
                      getOptionValue={(option: any) => option.value}
                      getOptionLabel={(option: any) => option.label}
                      className="department-select"
                      additional={{
                        page: 1,
                      }}
                      isClearable
                      isSearchable={true}
                      placeholder={t("Department")}
                      debounceTimeout={500}
                    />
                  </FormControl>
                </th>
                <th>
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <React.Fragment>
                        <FormControl sx={{ minWidth: 80 }}>
                          <Button
                            {...bindTrigger(popupState)}
                            variant="text"
                            color="inherit"
                            endIcon={
                              rolesState === "asc" ? (
                                <ArrowDropUpIcon />
                              ) : (
                                <ArrowDropDownSharpIcon />
                              )
                            }
                            style={{
                              textTransform: "capitalize",
                              marginRight: lang === "ar" ? "10px" : "0px",
                            }}
                            className={Style.HeaderBtn}
                          >
                            {rolesState ? rolesState : t("Roles")}
                          </Button>
                          <Menu
                            {...bindMenu(popupState)}
                            style={{
                              minWidth: 80,
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                setRolesState("");
                                setPage(1);
                                popupState.close();
                              }}
                            >
                              {t("All")}
                            </MenuItem>

                            <MenuItem
                              onClick={() => {
                                setRolesState("Trainer");
                                setPage(1);
                                popupState.close();
                              }}
                            >
                              {t("Trainer")}
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setRolesState("Trainee");
                                setPage(1);
                                popupState.close();
                              }}
                            >
                              {t("Trainee")}
                            </MenuItem>
                          </Menu>
                        </FormControl>
                      </React.Fragment>
                    )}
                  </PopupState>
                </th>
                <th>
                  <div className="position-relative">
                    {dateRangeCreated ? (
                      <div className="color-picker-container">
                        <DateRangePicker
                          open={openCreatedDate}
                          value={[
                            dateRangeCreated.startDate,
                            dateRangeCreated.endDate,
                          ]}
                          onChange={(range: any) => {
                            if (range)
                              setDateRangeCreated({
                                startDate: range[0],
                                endDate: range[1],
                              });
                            else setDateRangeCreated(null);
                          }}
                          className={Style.menu}
                          menuStyle={{
                            color: "black",
                          }}
                          placement="autoVertical"
                          format="yyyy-MM-dd"
                          defaultCalendarValue={[new Date(), new Date()]}
                          onOk={() => {
                            setOpenCreatedDate(false);
                          }}
                          onClick={() => {
                            setOpenCreatedDate(true);
                          }}
                          onClean={() => {
                            setDateRangeCreated(null);
                          }}
                          shouldDisableDate={(date) => date > new Date()}
                        />
                      </div>
                    ) : (
                      <Button
                        variant="text"
                        color="inherit"
                        endIcon={<ArrowDropDownSharpIcon />}
                        className={Style.HeaderBtn}
                        onClick={toggleCreated}
                        style={{ textTransform: "capitalize" }}
                      >
                        {t("Created")}
                      </Button>
                    )}
                  </div>
                </th>
                <th>
                  <div className="position-relative">
                    {dateRangeUpdated ? (
                      <div className="color-picker-container">
                        <DateRangePicker
                          open={openUpdatedDate}
                          value={[
                            dateRangeUpdated.startDate,
                            dateRangeUpdated.endDate,
                          ]}
                          onChange={(range: any) => {
                            if (range)
                              setDateRangeUpdated({
                                startDate: range[0],
                                endDate: range[1],
                              });
                            else setDateRangeUpdated(null);
                          }}
                          placement="autoVertical"
                          className={Style.menu}
                          menuStyle={{
                            color: "black",
                          }}
                          format="yyyy-MM-dd"
                          defaultCalendarValue={[new Date(), new Date()]}
                          onOk={() => {
                            setOpenUpdatedDate(false);
                          }}
                          onClick={() => {
                            setOpenUpdatedDate(true);
                          }}
                          onClean={() => {
                            setDateRangeCreated(null);
                          }}
                          shouldDisableDate={(date) => date > new Date()}
                        />
                      </div>
                    ) : (
                      <Button
                        variant="text"
                        color="inherit"
                        endIcon={<ArrowDropDownSharpIcon />}
                        className={Style.HeaderBtn}
                        onClick={toggleUpdated}
                        style={{ textTransform: "capitalize" }}
                      >
                        {t("Updated")}
                      </Button>
                    )}
                  </div>
                </th>
                <th style={{ textAlign: "center" }}>
                  <PopupState variant="popover" popupId="demo-popup-menu2">
                    {(popupState) => (
                      <React.Fragment>
                        <FormControl sx={{ minWidth: 80 }}>
                          <Button
                            variant="text"
                            color="inherit"
                            endIcon={
                              statusState === "asc" ? (
                                <ArrowDropUpIcon />
                              ) : (
                                <ArrowDropDownSharpIcon />
                              )
                            }
                            {...bindTrigger(popupState)}
                            className={Style.HeaderBtn}
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                            {statusState ? statusState : t("Status")}
                          </Button>
                          <Menu
                            {...bindMenu(popupState)}
                            style={{
                              minWidth: 80,
                              marginRight: lang === "ar" ? "10px" : "0px",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                setStatusState("");
                                setPage(1);
                                popupState.close();
                              }}
                            >
                              {t("All")}
                            </MenuItem>
                            <MenuItem
                              style={{
                                minWidth: 110,
                              }}
                              onClick={() => {
                                setStatusState("Active");
                                setPage(1);
                                popupState.close();
                              }}
                            >
                              {t("Active")}
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setStatusState("Archived");
                                setPage(1);
                                popupState.close();
                              }}
                            >
                              {t("Archived")}
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setStatusState("Locked");
                                setPage(1);
                                popupState.close();
                              }}
                            >
                              {t("Locked")}
                            </MenuItem>
                          </Menu>
                        </FormControl>
                      </React.Fragment>
                    )}
                  </PopupState>
                </th>
                <th style={{ textAlign: "center" }}>{t("Email Status")}</th>
                <th>{t("Actions")}</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td className="text-center" colSpan={10}>
                    <CircularProgress size="2rem" color="primary" />
                  </td>
                </tr>
              ) : userListData?.results && userListData?.results.length > 0 ? (
                userListData?.results?.map((rw: any, index: number) => {
                  const isItemSelected = isSelected(rw.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <tr key={rw.id} style={{ lineHeight: "3.1" }}>
                      {editUpdatePermission && (
                        <td
                          onClick={(event) => handleCheckboxClick(event, rw.id)}
                        >
                          <Checkbox
                            className={Style.headerCheck}
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </td>
                      )}
                      <td className="align-middle">
                        <div className="d-flex gap-2 align-items-center">
                          <Avatar
                            src={rw.profile_picture || HeadIcon}
                            sx={{ width: 35, height: 35 }}
                            alt={rw.username}
                          />
                          <div>{rw.first_name + " " + rw.last_name}</div>
                        </div>
                      </td>
                      <td className="align-middle">{rw.email}</td>
                      <td className="align-middle">
                        {rw.department?.name || "N/A"}
                      </td>
                      <td
                        className="align-middle"
                        style={{ paddingLeft: "1.5em" }}
                      >
                        {rw.primary_role}
                      </td>
                      <td className="align-middle">
                        {moment(new Date(rw.created_at)).format(
                          "MMM Do, YYYY"
                        ) || ""}
                      </td>
                      <td className="align-middle">
                        {moment(new Date(rw.updated_at)).format(
                          "MMM Do, YYYY"
                        ) || ""}
                      </td>

                      <td
                        className={`align-middle`}
                        style={{ paddingLeft: "1.3em", textAlign: "center" }}
                      >
                        <span
                          className={
                            rw.status == t("Active")
                              ? Style.statusSuccess
                              : rw.status == t("Archived")
                              ? Style.statusArchived
                              : rw.status == t("Locked")
                              ? Style.statusLocked
                              : ""
                          }
                        >
                          {rw.status}
                        </span>
                      </td>
                      <td
                        className={`align-middle`}
                        style={{ paddingLeft: "1.3em", textAlign: "center" }}
                      >
                        <span
                          className={
                            rw.is_email_verified
                              ? Style.statusSuccess
                              : Style.statusInfo
                          }
                        >
                          {rw.is_email_verified ? "Verified" : "Not Verified"}
                        </span>
                      </td>
                      <td className="align-middle">
                        {(viewPermission ||
                          editUpdatePermission ||
                          archiveUnarchivePermission ||
                          lockUnlockPermission ||
                          deletePermission) && (
                          <>
                            <IconButton
                              aria-label="more"
                              id={`long-button-${rw?.id}`}
                              aria-controls={
                                open ? `long-menu-${rw?.id}` : undefined
                              }
                              style={{ paddingLeft: "0.5em" }}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(event) => handleClick(event, rw)}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              className={Style.menu}
                              id={`long-menu-${rw?.id}`}
                              MenuListProps={{
                                "aria-labelledby": `long-button-${rw?.id}`,
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              PaperProps={{
                                style: {
                                  width: "20ch",
                                  boxShadow:
                                    "0px 5px 5px -3px rgba(0,0,0,0.01), 0px 3px 6px 1px rgba(0,0,0,0.01), 0px 3px 6px 2px rgba(0,0,0,0.01)",
                                },
                              }}
                            >
                              {viewPermission && (
                                <MenuItem
                                  onClick={() => {
                                    navigate(
                                      "/user-profile-detail/outlook/" +
                                        singleRowData.id
                                    );
                                  }}
                                >
                                  <IconButton
                                    className="d-flex gap-2"
                                    sx={{ m: 0, p: 0 }}
                                  >
                                    <ViewSVGAction />
                                    <div>{t("View")}</div>
                                  </IconButton>
                                </MenuItem>
                              )}
                              {editUpdatePermission && (
                                <MenuItem
                                  onClick={() => {
                                    navigate(
                                      "/user-profile-edit/emend/" +
                                        singleRowData.id
                                    );
                                  }}
                                >
                                  <IconButton
                                    className="d-flex gap-2"
                                    sx={{ m: 0, p: 0 }}
                                  >
                                    <EditSVG />
                                    <div>{t("Edit")}</div>
                                  </IconButton>
                                </MenuItem>
                              )}
                              {archiveUnarchivePermission &&
                                (singleRowData?.status === "Archived" ? (
                                  <MenuItem
                                    onClick={() => {
                                      confirm({
                                        title: t("Are you sure!"),
                                        description: `Do you want to unarchive ${singleRowData.first_name} account?`,
                                        ...confirmProps,
                                      })
                                        .then(() => {
                                          handleRowAction(
                                            singleRowData,
                                            "unarchive"
                                          );
                                        })
                                        .catch((e) => {
                                          // console.log(e);
                                        });
                                    }}
                                  >
                                    <IconButton
                                      className="d-flex gap-2"
                                      sx={{ m: 0, p: 0, mr: 1 }}
                                    >
                                      <UnarchiveOutlinedIcon
                                        style={{
                                          fontSize: 20,
                                          color: "rgb(87, 88, 91)",
                                        }}
                                      />
                                      <div>{t("Unarchive")}</div>
                                    </IconButton>
                                  </MenuItem>
                                ) : (
                                  <MenuItem
                                    onClick={() => {
                                      confirm({
                                        title: t("Are you sure!"),
                                        description: `${t(
                                          "Do you want to archive"
                                        )} ${singleRowData.first_name} ${t(
                                          "account?"
                                        )}`,
                                        ...confirmProps,
                                      })
                                        .then(() => {
                                          handleRowAction(
                                            singleRowData,
                                            "archive"
                                          );
                                        })
                                        .catch((e) => {
                                          // console.log(e);
                                        });
                                    }}
                                  >
                                    <IconButton
                                      className="d-flex gap-2"
                                      sx={{ m: 0, p: 0, mr: 1 }}
                                    >
                                      <ArchiveOutlinedIcon
                                        style={{
                                          fontSize: 20,
                                          color: "rgb(87, 88, 91)",
                                        }}
                                      />
                                      <div>{t("Archive")}</div>
                                    </IconButton>
                                  </MenuItem>
                                ))}
                              {lockUnlockPermission &&
                                (singleRowData?.status === "Locked" ? (
                                  <MenuItem
                                    onClick={() => {
                                      confirm({
                                        title: t("Are you sure!"),
                                        description: `Do you want to unlock ${singleRowData.first_name} account?`,
                                        ...confirmProps,
                                      })
                                        .then(() => {
                                          handleRowAction(
                                            singleRowData,
                                            "unlock"
                                          );
                                        })
                                        .catch((e) => {
                                          // console.log(e);
                                        });
                                    }}
                                  >
                                    <IconButton
                                      className="d-flex gap-2"
                                      sx={{ m: 0, p: 0, mr: 1 }}
                                    >
                                      <LockOpenTwoToneIcon
                                        style={{
                                          fontSize: 20,
                                          color: "rgb(87, 88, 91)",
                                        }}
                                      />
                                      <div>{t("UnLock")}</div>
                                    </IconButton>
                                  </MenuItem>
                                ) : (
                                  <MenuItem
                                    onClick={() => {
                                      confirm({
                                        title: t("Are you sure!"),
                                        description: `Do you want to lock ${singleRowData.first_name} account?`,
                                        ...confirmProps,
                                      })
                                        .then(() => {
                                          handleRowAction(
                                            singleRowData,
                                            "lock"
                                          );
                                        })
                                        .catch((e) => {
                                          // console.log(e);
                                        });
                                    }}
                                  >
                                    <IconButton
                                      className="d-flex gap-2"
                                      sx={{ m: 0, p: 0, mr: 1 }}
                                    >
                                      <LockOutlinedIcon
                                        style={{
                                          fontSize: 20,
                                          color: "rgb(87, 88, 91)",
                                        }}
                                      />
                                      <div>{t("Lock")}</div>
                                    </IconButton>
                                  </MenuItem>
                                ))}
                              {deletePermission && (
                                <MenuItem
                                  onClick={() => {
                                    confirm({
                                      title: t("Are you sure!"),
                                      description: `Do you want to delete ${singleRowData.first_name} account?`,
                                      ...confirmProps,
                                    })
                                      .then(() => {
                                        handleRowAction(
                                          singleRowData,
                                          "delete"
                                        );
                                      })
                                      .catch((e) => {
                                        // console.log(e);
                                      });
                                  }}
                                >
                                  <IconButton
                                    className="d-flex gap-2"
                                    sx={{ m: 0, p: 0 }}
                                  >
                                    <DeleteSVG color="red" />
                                    <div>{t("Remove")}</div>
                                  </IconButton>
                                </MenuItem>
                              )}
                            </Menu>
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : showRecordNotFound ? (
                <tr style={{ cursor: "pointer" }}>
                  <td colSpan={9} className="text-center align-middle p-3">
                    {"No Data Found"}
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
        {userListData?.count > 0 && (
          <Paginations
            totalCount={Number(userListData?.count)}
            perpage={Number(rowsPerPage || 10)}
            handleChangePageNum={handleChangePage}
            pagenum={Number(page || 1)}
            handleChangeRowsPerPageParent={handleChangeRowsPerPage}
          />
        )}
      </div>
      <ActiveDirectoryUserModel
        setParentOpen={setOpenActiveDirectoryUserModel}
        open={openActiveDirectoryUserModel}
        onClose={handleClose}
        setSelectedRows={setMembersActiveDirectoryUserModel}
        selectedRows={membersActiveDirectoryUserModel}
        save={saveActiveDirectoryUserModel}
        setSave={setSaveActiveDirectoryUserModel}
        setRolesState={setRolesStateActiveDirectoryUser}
        rolesState={rolesStateActiveDirectoryUser}
      />
    </div>
  );
}

export default UserList;
