import { yupResolver } from "@hookform/resolvers/yup";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { maskSecret } from "../../../helpers/helpers";
import useCompanyLanguage from "../../../hooks/language";
import useColor from "../../../hooks/useColor";
import { RootState, useAppDispatch } from "../../../store/store";
import { defaultThemeColor } from "../../../utils/colors_values";

const tls = [
  { key: "True", value: "True" },
  { key: "False", value: "False" },
];
const schema = yup
  .object({
    smtp_host_name: yup.string().required("Host is required"),
    smtp_port: yup.number().required("Port is required"),
    smtp_host_user: yup.string().required("User is required"),
    smtp_host_password: yup.string().required("Password is required"),
    smtp_tls: yup.string().default("True").required("TLS is required"),
  })
  .required();
export interface PropsInterface {
  setIsEdit: any;
}
function ExchangeServerView(props: { row: any }) {
  const { row } = props;
  const {
    setValue,
    getValues,
    setError,
    reset,
    register,
    control,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const isLoading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  const onSubmit = (payload: any) => {
    console.log("payload payload", payload);
  };

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;
  const primaryButton =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;
  const primaryButtonBg =
    useColor("button_primary") || defaultThemeColor.button_primary;

  return (
    <>
      <div className={`row`}>
        <div className={`col-lg-6  px-2 py-3 `}>
          <span>{t("Host Name")}</span>
          <p>{row?.detail?.smtp_host_name || "N/A"}</p>
        </div>
        <div className={`col-lg-6  px-2 py-3 `}>
          <span>{t("Port")}</span>
          <p>{row?.detail?.smtp_port || "N/A"}</p>
        </div>

        <div className={`col-lg-6  px-2 py-3 `}>
          <span>{t("TLS")}</span>
          <p>{row?.detail?.smtp_tls || "N/A"}</p>
        </div>
        <div className={`col-lg-6  px-2 py-3 `}>
          <span>{t("Username")}</span>
          <p>{row?.detail?.smtp_host_user || "N/A"}</p>
        </div>
        <div className={`col-lg-6  px-2 py-3 `}>
          <span>{t("Password")}</span>
          <p>
            {row?.detail?.smtp_host_password
              ? maskSecret(row?.detail?.smtp_host_password)
              : "N/A"}
          </p>
        </div>
      </div>
    </>
  );
}

export default ExchangeServerView;
