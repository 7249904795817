import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CircularProgress, IconButton, Menu, MenuItem } from "@mui/material";
import moment from "moment";
import React, { useState, useEffect, useCallback} from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Collapse, ListGroup, ListGroupItem } from "reactstrap";
import useCompanyLanguage from "../../hooks/language";
import usePermission from "../../hooks/usePermission";
import AddSubSVG from "../../icons/AddSubSVG";
import ArrowDownSVG from "../../icons/ArrowDownSVG";
import DeleteSVG from "../../icons/DeleteSVG";
import EditSVG from "../../icons/EditSVG";
import ViewSVGAction from "../../icons/ViewSVGAction";
import { RootState } from "../../store/store";
import Style from "./List.module.scss";

interface Params {
  rows: any;
  handleDeleteAction?: any;
  setOpenEditModal?: any;
  setParentId?: any;
  setDefaultFormValues: any;
  setModalHeading: any;
  setModalButtonLable: any;
}

const TreeView = ({
  rows,
  handleDeleteAction,
  setOpenEditModal,
  setParentId,
  setDefaultFormValues,
  setModalHeading,
  setModalButtonLable,
}: Params) => {
  const addSubPermission = usePermission("add_department");
  const viewPermission = usePermission("view_department");
  const editUpdatePermission = usePermission("change_department");
  const deletePermission = usePermission("delete_department");
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [singleRowData, setSingleRowData] = useState();
  
  const handleClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setSingleRowData(row);
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isLoading = useSelector((state: RootState) => state.loaderReducer.isLoading);
  const handleDetailDep = (row: any) => {
    setAnchorEl(null);
    navigate(`/detail-department/${row?.id}`);
  };

  const handleAddSubDep = (row: any) => {
    setModalHeading(t("Add Sub Department"));
    setModalButtonLable(t("Save"));
    setAnchorEl(null);
    setDefaultFormValues({});
    setParentId(row?.id);
    setOpenEditModal(true);
  };
  const handleEditDep = (row: any) => {
    setModalHeading(t("Edit Department"));
    setModalButtonLable(t("Update"));
    setAnchorEl(null);
    setDefaultFormValues(row);
    setParentId("");
    setOpenEditModal(true);
  };
  const handleDeleteDep = (row: any) => {
    setAnchorEl(null);
    handleDeleteAction(row);
  };
  const toggle = (e: any, id: any) => {
    if (e.currentTarget.classList.contains("open")) {
      e.currentTarget.classList.remove("open");
    } else {
      e.currentTarget.classList.add("open");
    }
    if (document.getElementById(`id-${id}`)?.classList.contains("show")) {
      document.getElementById(`id-${id}`)?.classList.remove("show");
    } else {
      document.getElementById(`id-${id}`)?.classList.add("show");
    }
  };

  const countMembers = (node: any) => {
    let totalMembers = node.members || 0;

    if (node.children) {
      for (const child of node.children) {
        totalMembers += countMembers(child);
      }
    }

    return totalMembers;
  };

  
  const mapper = (
    nodes: any,
    parentId?: any,
    lvl?: any,
    handleDeleteDep?: any,
    setOpenEditModal?: any,
    setParentId?: any,
    setDefaultFormValues?: any,
    setModalHeading?: any,
    setModalButtonLable?: any
  ) => {
    if (!Array.isArray(nodes)) return null;
    return nodes.map((row: any, index: any) => {
      // console.log("Row Data>>>", row)
      const totalMembers = countMembers(row);
      const id = `${row.id + row.name}-${parentId ? parentId : "top"}`.replace(
        /[^a-zA-Z0-9-_]/g,
        ""
      );
      const item = (
        <React.Fragment key={row.id}>
          <ListGroupItem
            key={row.id}
            style={{ zIndex: 0, borderBottom: "1px solid black" }}
            className={`border-bottom ${
              parentId ? `rounded-0 ${lvl ? "border-bottom-0" : ""}` : ""
            }`}
          >
            {
              <div className="row g-0 align-items-center">
                <div
                  className="col-3"
                  style={{
                    paddingLeft: lang === "ar" ? `none` : `${25 * lvl}px`,
                    paddingRight: lang === "ar" ? `${25 * lvl}px` : `none`,
                  }}
                >
                  <div className="d-flex align-items-center">
                    {row?.children?.length > 0 && (
                      <div
                        className={`btn btn-link pl-0 ${Style.arrowBtn}`}
                        id={id}
                        onClick={(e) => {
                          toggle(e, id);
                        }}
                      >
                        <ArrowDownSVG />
                      </div>
                    )}
                    <div style={{padding: row?.children?.length > 0 ? "0rem":'0rem 0.75rem'}}>{row.name}</div>
                  </div>
                </div>
                <div className="col-3 text-center">{totalMembers}</div>
                <div className="col-3 text-center">
                  {moment(new Date(row.created_at)).format("MMM Do, YYYY") || ""}
                </div>
                <div className="col-3 text-center">
                  <IconButton
                    aria-label="more"
                    id={`long-button-${row?.id}`}
                    aria-controls={open ? `long-menu-${row?.id}` : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={(event) => handleClick(event, row)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    className={Style.menu}
                    id={`long-menu-${row?.id}`}
                    MenuListProps={{
                      "aria-labelledby": `long-button-${row?.id}`,
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        width: "20ch",
                        boxShadow:
                          "0px 5px 5px -3px rgba(0,0,0,0.01), 0px 3px 6px 1px rgba(0,0,0,0.01), 0px 3px 6px 2px rgba(0,0,0,0.01)",
                      },
                    }}
                  >
                    {viewPermission && (
                      <MenuItem
                        onClick={(e: any) => {
                          handleDetailDep(singleRowData);
                        }}
                      >
                        <ViewSVGAction />
                        {t("View")}
                      </MenuItem>
                    )}
                    {editUpdatePermission && (
                      <MenuItem
                        onClick={() => {
                          handleEditDep(singleRowData);
                        }}
                      >
                        <EditSVG /> {t("Edit")}
                      </MenuItem>
                    )}
                    {addSubPermission && (
                      <MenuItem onClick={(e) => handleAddSubDep(singleRowData)}>
                        <AddSubSVG /> {t("Add Sub")}
                      </MenuItem>
                    )}
                    {deletePermission && (
                      <MenuItem onClick={(e) => handleDeleteDep(singleRowData)}>
                        <DeleteSVG color="red" />
                        {t("Remove")}
                      </MenuItem>
                    )}
                  </Menu>
                </div>
              </div>
            }
          </ListGroupItem>
          {row?.children?.length > 0 && (
            <Collapse id={`id-${id}`}>
              {mapper(
                row?.children,
                id,
                (lvl || 0) + 1,
                handleDeleteDep,
                setOpenEditModal,
                setParentId,
                setDefaultFormValues,
                setModalHeading,
                setModalButtonLable
              )}
            </Collapse>
          )}
        </React.Fragment>
      );

      return item;
    });
  };

  return (
    <div className="overflow-x-auto">
      <div className={`${Style.TreeViewStart}`}>
        <div className={`row g-0 ${Style.tableHead}`}>
          <div className={`col-3 ${Style.column}`}>{t("Department")}</div>
          <div className={`col-3 text-center ${Style.column}`}>{t("No of members")}</div>
          <div className={`col-3 text-center ${Style.column}`}>{t("Created")}</div>
          <div className={`col-3 text-center ${Style.column}`}>{t("Actions")}</div>
        </div>
        <ListGroup>
          {isLoading ? (
            <div className="col-12 text-center">
              <CircularProgress size="2rem" color="primary" />
            </div>
          ) : rows.length > 0 ? (
            mapper(
              rows,
              "",
              0,
              handleDeleteDep,
              setOpenEditModal,
              setParentId,
              setDefaultFormValues,
              setModalHeading,
              setModalButtonLable
            )
          ) : (
            <ListGroupItem className="text-center">{t("Record not found")}</ListGroupItem>
          )}
        </ListGroup>
      </div>
    </div>
  );
};

export default TreeView;
