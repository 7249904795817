import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useConfirm } from "material-ui-confirm";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../../components/Header";
import DataTable from "../../../components/table/DataTable";
import TableFilters from "../../../components/table/TableFilters";
import useColor from "../../../hooks/useColor";
import {
  addSelfEnroleInTraining,
  bulkDeleteTrainings,
  deleteTraining,
  getBrowseTrainingListData,
} from "../../../services/trainingsService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../../store/reducers/alertMessageReducer";
import { groupManagementSelector } from "../../../store/reducers/groupManagementReducer";
import { RootState, useAppDispatch } from "../../../store/store";
import { defaultThemeColor } from "../../../utils/colors_values";
import { confirmProps } from "../../../utils/confirmProps";
import Style from "../TrainingList/ModulesMemberLists.module.scss";

import { useTranslation } from "react-multi-lang";
import useCompanyLanguage from "../../../hooks/language";
import CertificateSVG from "../../../icons/CertificateSVG";
import ResumeSVG from "../../../icons/ResumeSVG";
import ResumeTrainingSVG from "../../../icons/ResumeTraining";
import ViewSVGAction from "../../../icons/ViewSVGAction";
import { setPage } from "../../../store/reducers/trainingsReducer";

function BrowseTrainingLists() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const t = useTranslation();
  const lang = useCompanyLanguage();

  const [itemPerPage, setItemPerPage] = useState<number>(10);

  const [searchTable, setSearchTable] = useState("");

  const [list, setList] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<any[]>([]);

  // const [page, setPage] = useState(1);
  const states = useSelector(groupManagementSelector).departmentReducer;
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;
  const [rows, setRows] = useState([]);
  const [fetchID, setFetchID] = useState<any>(0);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [singleRowData, setSingleRowData] = useState<any>();

  const page = useSelector((state: RootState) => state.trainingsReducer.page);

  const dataList = useSelector(
    (state: RootState) => state.trainingsReducer.data
  );
  useEffect(() => {
    dispatch(setPage(1));
  }, []);
  useEffect(() => {
    dispatch(
      getBrowseTrainingListData({
        page_size: Number(itemPerPage),
        page: Number(page),
        search: searchTable,
      })
    );
  }, [dispatch, itemPerPage, page, searchTable]);
  useEffect(() => {
    let data: any = dataList.results;
    if (Object.keys(dataList).length === 0) {
      setRows([]);
    } else setRows(data);
  }, [dataList]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      setSelectedCheckBox([]);
      dispatch(
        getBrowseTrainingListData({
          page_size: Number(itemPerPage),
          page: Number(page),
          search: searchTable,
        })
      );
    }
  }, [alertMessage]);

  const handleDeleteAction = (row: any) => {
    confirm({
      title: t("Are you sure!"),
      description: `Do you want to delete ${row?.name} training?`,
      ...confirmProps,
    })
      .then(() => {
        dispatch(deleteTraining(row?.id));
      })
      .catch(() => {
        // toast.success("Your request canceled successfully!", {
        //   toastId: "Your request canceled successfully!",
        // });
      });
    return;
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setSelectedCheckBox([]);
    dispatch(setPage(page));
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    dispatch(setPage(1));
    setSelectedCheckBox([]);
    setItemPerPage(Number(rowsPerPage));
  };

  useEffect(() => {
    modifyRows();
  }, [rows, fetchID]);

  const bulkDelete = (ids: any) => {
    // const sendSelected = selected.map((sel) => sel);
    confirm({
      title: t("Are you sure!"),
      description: t(`Do you want to delete selected trainings?`),
      ...confirmProps,
    })
      .then(() => {
        dispatch(bulkDeleteTrainings(ids));
        setSelectedCheckBox([]);
      })
      .catch(() => {});
  };

  const fetchprogress = (id: any) => {
    setFetchID(id);
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setSingleRowData(row);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleTrainingDetail = (row: any) => {
    // localStorage.setItem("training_detail", JSON.stringify(row));
    // dispatch(setTrainingData(row));
    navigate(`/training-details/${row.id.toString()}`);
  };
  const handleSelfEnrole = (id: number) => {
    // const sendSelected = selected.map((sel) => sel);
    confirm({
      title: t("Are you sure!"),
      description: t(`Do you want to enroll in this training?`),
      ...confirmProps,
    })
      .then(() => {
        dispatch(addSelfEnroleInTraining({ training_id: id }));
      })
      .catch(() => {});
  };

  const modifyRows = () => {
    let f_rows: any = [];
    rows.forEach(
      (
        r: {
          id: string;
          name: string;
          level: string;
          category: any;
          progress: any;
          is_enrolled: any;
          ordered_modules: any;
        },
        i: number
      ) => {
        f_rows = [
          ...f_rows,
          {
            id: r.id,
            name: <div>{r.name}</div>,
            category: <div>{r?.category?.name}</div>,
            level: r.level,
            progress: r?.is_enrolled ? (
              r?.progress == 100 ? (
                <span className={Style.statusSuccess}>{"Completed"}</span>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: lang === "ar" ? "center" : "unset",
                  }}
                >
                  <Box sx={{ width: "37%", mr: 1 }}>
                    <LinearProgress
                      variant="determinate"
                      value={Math.round(r.progress)}
                    />
                  </Box>
                  <Box sx={{ minWidth: 25 }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                    >{`${Math.round(r?.progress)}%`}</Typography>
                  </Box>
                </Box>
              )
            ) : (
              "N/A"
            ),

            actions: (
              <>
                {/* <IconButton
                  aria-label="more"
                  id={`long-button-${r?.id}`}
                  aria-controls={open ? `long-menu-${r?.id}` : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={(event) => handleClick(event, r)}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  className={Style.menu}
                  id={`long-menu-${r?.id}`}
                  MenuListProps={{
                    "aria-labelledby": `long-button-${r?.id}`,
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      width: "20ch",
                      boxShadow:
                        "0px 5px 5px -3px rgba(0,0,0,0.01), 0px 3px 6px 1px rgba(0,0,0,0.01), 0px 3px 6px 2px rgba(0,0,0,0.01)",
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      navigate("/training-details/" + singleRowData.id);
                    }}
                  >
                    <IconButton className="d-flex gap-2" sx={{ m: 0, p: 0 }}>
                      <ViewSVG />
                      <div>View</div>
                    </IconButton>
                  </MenuItem>
                  <MenuItem>
                    <IconButton className="d-flex gap-2" sx={{ m: 0, p: 0 }}>
                      <ViewSVG />
                      <div>Enrole</div>
                    </IconButton>
                  </MenuItem>
                </Menu> */}

                <div className={Style.inlineButtons}>
                  <Tooltip title={t("View Training")} placement="top" arrow>
                    <button
                      onClick={() => handleTrainingDetail(r)}
                      className="btn-link"
                      type="button"
                    >
                      <ViewSVGAction />
                    </button>
                  </Tooltip>
                  {!r?.is_enrolled ? (
                    <Tooltip
                      title={t("Enroll to Training")}
                      placement="top"
                      arrow
                    >
                      <button
                        type="button"
                        className="btn-link"
                        onClick={() => handleSelfEnrole(Number(r?.id))}
                      >
                        <ResumeSVG color="#2B3674" />
                      </button>
                    </Tooltip>
                  ) : Number(r?.progress) === 100 ? (
                    <Tooltip title={t("Certificate")} placement="top" arrow>
                      <button
                        type="button"
                        className="btn-link"
                        onClick={() => {
                          navigate(
                            `/certificate/download/${r?.id}/false/my-training`
                          );
                        }}
                      >
                        <CertificateSVG color="#2B3674" />
                      </button>
                    </Tooltip>
                  ) : (
                    <Tooltip title={t("Resume Training")} placement="top" arrow>
                      <button
                        type="button"
                        className="btn-link"
                        onClick={() => {
                          let continueId = r.ordered_modules?.find(
                            (i: any) =>
                              (i.module.module_status == "BackLog" ||
                                i.module.module_status == "InProgress") &&
                              (i.module.type == "Video" ||
                                i.module.type == "PDF" ||
                                i.module.type == "Assessment")
                          );
                          if (continueId)
                            navigate(
                              `/training/${r?.id}/lectures/${continueId?.module?.id}/true`
                            );
                          else if (
                            r?.ordered_modules &&
                            r?.ordered_modules.length > 0
                          ) {
                            navigate(
                              `/training/${r?.id}/lectures/${r?.ordered_modules[0]?.module?.id}`
                            );
                          }
                        }}
                      >
                        {/* <ExitToAppOutlinedIcon
                          style={{ color: "#2B3674" }}
                        ></ExitToAppOutlinedIcon> */}
                        <ResumeTrainingSVG
                          color="#2B3674"
                          // style={{ color: "#2B3674" }}
                        ></ResumeTrainingSVG>
                        {/* <ResumeSVGCircled color="#2B3674" /> */}
                      </button>
                    </Tooltip>
                  )}

                  {/* {fetchID == r.id && (
                    <Box sx={{ position: "relative", display: "inline-flex" }}>
                      <CircularProgress
                        variant="determinate"
                        value={50}
                        size={30}
                      />
                      <Box
                        sx={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="caption"
                          component="div"
                          color="text.secondary"
                        >{`${Math.round(50)}%`}</Typography>
                      </Box>
                    </Box>
                  )} */}
                </div>
              </>
            ),
          },
        ];
      }
    );
    setList(f_rows);
  };

  const AddGroup = () => {
    const handleAddNew = () => {
      navigate("/add-training");
    };
    return (
      <div className="d-flex flex-row gap-3">
        {/* {addPermission && ( */}
        <>
          <Button
            variant="text"
            color="inherit"
            endIcon={
              <AddIcon
                style={{
                  fontSize: 16,
                  color:
                    useColor("interface_background_text_color") ||
                    defaultThemeColor.interface_background_text_color,
                  marginRight: lang === "ar" ? "10px" : "0px",
                }}
              />
            }
            style={{
              fontSize: 13,
              color:
                useColor("interface_background_text_color") ||
                defaultThemeColor.interface_background_text_color,
              textTransform: "capitalize",
            }}
            onClick={() => handleAddNew()}
          >
            {t("Add New")}
          </Button>
        </>
        {/* )} */}
      </div>
    );
  };

  return (
    <>
      <Header page={t("Browse Trainings")} mainHead={t("Trainings")} />

      <TableFilters
        dataList={dataList}
        itemPerPage={itemPerPage}
        setItemPerPage={setItemPerPage}
        selectedCheckBox={selectedCheckBox}
        bulkDelete={bulkDelete}
        setSearchTable={setSearchTable}
        handle="groups"
        isCheckboxes={false}
      />

      <DataTable
        columnData={[
          {
            id: "name",
            name: t("Name"),
            enableSort: true,
            align: lang === "ar" ? "center" : "left",
            disablePadding: "none",
          },

          {
            id: "category",
            name: t(`Category`),
            enableSort: true,
            align: lang === "ar" ? "center" : "left",
            disablePadding: "none",
          },
          {
            id: "level",
            name: t("Level"),
            enableSort: true,
            align: lang === "ar" ? "center" : "left",
            disablePadding: "none",
          },

          {
            id: "progress",
            name: t("Progress"),
            enableSort: true,
            align: lang === "ar" ? "center" : "left",
            disablePadding: "none",
          },
          {
            id: "actions",
            name: t("Actions"),
            enableSort: false,
            align: "center",
            disablePadding: "none",
          },
        ]}
        rows={list}
        isCheckboxes={false}
        selectedRowsPerPage={itemPerPage.toString()}
        totalRecordCount={Number(dataList.count)}
        handleChangePageParent={handleChangePage}
        pagenum={Number(page)}
        handleChangeRowsPerPageParent={handleChangeRowsPerPage}
        bulkDelete={bulkDelete}
        selectedCheckBox={selectedCheckBox}
        setSelectedCheckBox={setSelectedCheckBox}
      />
    </>
  );
}

export default BrowseTrainingLists;
