// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar_sidebarStart__WvIZz {
  flex: 0 0 300px;
  border-right: 1px solid #efefef;
  padding-left: 30px;
  padding-top: 30px;
}
@media only screen and (max-width: 850px) {
  .Sidebar_sidebarStart__WvIZz {
    flex: unset;
    height: 80px;
    padding: 0;
  }
}
.Sidebar_sidebarStart__WvIZz .Sidebar_sidebarDesk__8UjdJ {
  display: block;
}
@media only screen and (max-width: 850px) {
  .Sidebar_sidebarStart__WvIZz .Sidebar_sidebarDesk__8UjdJ {
    display: none;
  }
}
.Sidebar_sidebarStart__WvIZz .Sidebar_sideMenu__8CBYu {
  height: 80vh;
  overflow: auto;
}
.Sidebar_sidebarStart__WvIZz .Sidebar_sidebarMob__iQ1an {
  display: none;
}
@media only screen and (max-width: 850px) {
  .Sidebar_sidebarStart__WvIZz .Sidebar_sidebarMob__iQ1an {
    display: block;
  }
}
.Sidebar_sidebarStart__WvIZz .Sidebar_logo__wwwhS img {
  width: 76%;
  max-height: 90px;
  object-fit: contain;
  min-height: 4em;
}`, "",{"version":3,"sources":["webpack://./src/components/Sidebar.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,+BAAA;EACA,kBAAA;EACA,iBAAA;AACF;AACE;EANF;IAOI,WAAA;IACA,YAAA;IACA,UAAA;EAEF;AACF;AAAE;EACE,cAAA;AAEJ;AAAI;EAHF;IAII,aAAA;EAGJ;AACF;AAAE;EACE,YAAA;EACA,cAAA;AAEJ;AACE;EACE,aAAA;AACJ;AACI;EAHF;IAII,cAAA;EAEJ;AACF;AAEI;EACE,UAAA;EACA,gBAAA;EACA,mBAAA;EACA,eAAA;AAAN","sourcesContent":[".sidebarStart {\n  flex: 0 0 300px;\n  border-right: 1px solid #efefef;\n  padding-left: 30px;\n  padding-top: 30px;\n\n  @media only screen and (max-width: 850px) {\n    flex: unset;\n    height: 80px;\n    padding: 0;\n  }\n\n  .sidebarDesk {\n    display: block;\n\n    @media only screen and (max-width: 850px) {\n      display: none;\n    }\n  }\n\n  .sideMenu {\n    height: 80vh;\n    overflow: auto;\n  }\n\n  .sidebarMob {\n    display: none;\n\n    @media only screen and (max-width: 850px) {\n      display: block;\n    }\n  }\n\n  .logo {\n    img {\n      width: 76%;\n      max-height: 90px;\n      object-fit: contain;\n      min-height: 4em;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebarStart": `Sidebar_sidebarStart__WvIZz`,
	"sidebarDesk": `Sidebar_sidebarDesk__8UjdJ`,
	"sideMenu": `Sidebar_sideMenu__8CBYu`,
	"sidebarMob": `Sidebar_sidebarMob__iQ1an`,
	"logo": `Sidebar_logo__wwwhS`
};
export default ___CSS_LOADER_EXPORT___;
