import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "../pages/Dashboard/Home";
import Layout from "../pages/layout/Layout";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddAssessment from "../pages/Assessment/AddAssessment";
import AssessmentDetail from "../pages/Assessment/AssessmentDetail";
import AssessmentList from "../pages/Assessment/AssessmentList";
import CompanyCustomization from "../pages/CompanySettings/CompanyCustomization";
import CompanyLicense from "../pages/CompanySettings/CompanyLicense";
import EditColorTheme from "../pages/CompanySettings/EditColorTheme/EditColorTheme";
import ChangeCompanyPassword from "../pages/CompanySettings/changePassword/ChangeProflePassword";
import AddAdmin from "../pages/adminUsers/AddAdmin";
import AdminList from "../pages/adminUsers/AdminList";
import AddNewPermission from "../pages/adminUsers/AdminPermission/AddNewPermission";
import AdminPermissionList from "../pages/adminUsers/AdminPermission/AdminPermissionList";
import DetailPermission from "../pages/adminUsers/AdminPermission/DetailPermission";
import Login from "../pages/auth/Login";
import NewPassword from "../pages/auth/NewPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import Signup from "../pages/auth/Signup";
import VerificationSuccess from "../pages/auth/VerificationSuccess";
import Verify from "../pages/auth/Verify";
import Category from "../pages/category/Category";
import AddCertificate from "../pages/certificatesManagement/AddCertificate";
import CertificatesManagement from "../pages/certificatesManagement/CertificatesManagement";
import EditCertificate from "../pages/certificatesManagement/EditCertificate";
import DepartmentDetail from "../pages/department/DepartmentDetail";
import DepartmentList from "../pages/department/DepartmentList";
import NoPage from "../pages/error/NoPage";
import NotAuthorizedPage from "../pages/error/NotAuthorizedPage";
import AddAutoGroup from "../pages/groupManagement/AddAutoGroup";
import AddGroup from "../pages/groupManagement/AddGroup";
import ConfigAutoGroup from "../pages/groupManagement/ConfigAutoGroup";
import DetailGroup from "../pages/groupManagement/DetailGroup";
import GroupManagementList from "../pages/groupManagement/GroupManagementList";
import ImageLibrary from "../pages/imageLibrary/ImageLibrary";
import AddModule from "../pages/modules/AddModule";
import ModuleList from "../pages/modules/ModuleListing";
import MyProfile from "../pages/myProfile/MyProfile";
import ChangeProflePassword from "../pages/myProfile/changePassword/ChangeProflePassword";
import CreateQuestion from "../pages/questionsLibrary/CreateQuestionPage";
import QuestionsList from "../pages/questionsLibrary/QuestionsList";
import Registration from "../pages/registration/Registration";
import AddTraining from "../pages/training/CreateTraining/CreateTraining";

import moment from "moment";
import CompanyIntegration from "../pages/CompanySettings/CompanyIntegration";
import CompanyProfileManagement from "../pages/CompanySettings/companyProfile";
import Notifications from "../pages/Notifications/Notifications";
import AddNewPolicy from "../pages/PolicyManagement/AddNewPolicy";
import PolicyAssessmentResult from "../pages/PolicyManagement/AssessmentResult";
import EditPolicyDetails from "../pages/PolicyManagement/EditPolicyDetail";
import PolicyAssessment from "../pages/PolicyManagement/PolicyAssessment";
import PolicyAssessmentAddNew from "../pages/PolicyManagement/PolicyAssessmentAddNew";
import PolicyAssessmentDetail from "../pages/PolicyManagement/PolicyAssessmentDetail";
import PolicyListing from "../pages/PolicyManagement/PolicyListing";
import PolicyAssessmentProgressReport from "../pages/PolicyManagement/StartPolicyAssessment/PolicyAssessmentProgressReport";
import PolicyAssessmentStarted from "../pages/PolicyManagement/StartPolicyAssessment/PolicyAssessmentStarted";
import StartPolicyAssessment from "../pages/PolicyManagement/StartPolicyAssessment/StartPolicyAssessment";
import PolicyDetails from "../pages/PolicyManagement/policyDetails";
import Authorized from "../pages/authorized";
import CategoryDetail from "../pages/category/CategoryDetail";
import CertificateDownload from "../pages/certificatesManagement/CertificateDownload";
import AddEmailNotification from "../pages/certificatesManagement/EmailNotification/AddEmailNotification";
import EmailNotificationListing from "../pages/certificatesManagement/EmailNotification/EmailNotificationListing";
import ViewEmailNotification from "../pages/certificatesManagement/EmailNotification/ViewEmailNotification";
import MyCertificates from "../pages/certificatesManagement/MyCertificateList";
import CompanyPolicyAssessmentListing from "../pages/companyPolicy/CompanyPolicyAssessmentListing";
import CompanyPolicyListing from "../pages/companyPolicy/CompanyPolicyListing";
import EditModule from "../pages/modules/EditModule";
import ModuleDetail from "../pages/modules/ModuleDetail";
import QuestionDetail from "../pages/questionsLibrary/QuestionDetail";

import AssessmentLink from "../pages/Assessment/AssessmentLink";
import ModuleLink from "../pages/modules/ModuleLink";
import QuestionLink from "../pages/questionsLibrary/QuestionLink";
import AssessmentName from "../pages/training/AssessmentName/AssessmentName";
import StartAssessment from "../pages/training/AssessmentName/StartAssessment";
import BrowseTrainingLists from "../pages/training/BrowseTraining/BrowseTrainingLists";
import Lectures from "../pages/training/Lectures/Lectures";
import ScormStarted from "../pages/training/Lectures/ScormStarted";
import MyTrainingLists from "../pages/training/MyTraining/MyTrainingLists";
import ProgressReport from "../pages/training/ProgressReport";
import TrainingDetails from "../pages/training/TrainingDetails/TrainingDetails";
import ModulesMemberLists from "../pages/training/TrainingList/ModulesMemberLists";
import TrainingLists from "../pages/training/TrainingList/TrainingLists";
import TrainingSuccessModal from "../pages/training/TrainingSuccessModal";
import ActiveDirectoryUser from "../pages/users/ActiveDirectoryUser";
import AddUser from "../pages/users/AddUser";
import UserList from "../pages/users/UserList";
import UserProfileDetails from "../pages/users/UserProfile";
import { licenseData } from "../store/reducers/licenseReducer";
import { RootState } from "../store/store";
// import VideoPlayer4 from "../components/VideoPlayer/TestVideoPlayer/VideoPlayer4";

const Router = () => {
  const [permissionsList, setPermissionsList] = useState<any[]>();

  const userInfo = useSelector((state: any) => state.authReducer.loginData);
  const superAdminBlockUrl: any = [
    "/company-policy-list",
    "/company-policy-assessment-list",
  ];
  const permissionList = useSelector(
    (state: any) => state.authReducer.permissionsList
  );
  const [licensePolicy, setLicensePolicy] = useState(false);
  const [isPolicShow, setIsPolicyShow] = useState(false);
  const [licenseUploaded, setLicenseUploaded] = useState(true);

  const dataLicense: licenseData = useSelector(
    (state: RootState) => state.licenseReducer.data
  );

  const isPolicShow2 =
    Object.keys(dataLicense || {}).length > 0 &&
    dataLicense?.policy_management_expiry_date &&
    dataLicense?.policy_management_selected &&
    moment(dataLicense?.policy_management_expiry_date) >= moment();

  useEffect(() => {
    if (licensePolicy && licenseUploaded) {
      setIsPolicyShow(true);
    } else {
      setIsPolicyShow(false);
    }
  }, [licensePolicy, licenseUploaded]);

  useEffect(() => {
    if (Object.keys(dataLicense || {}).length > 0) {
      setLicenseUploaded(true);
    } else {
      setLicenseUploaded(false);
    }
    if (
      dataLicense?.policy_management_expiry_date &&
      dataLicense?.policy_management_selected &&
      moment(dataLicense?.policy_management_expiry_date) >= moment()
    ) {
      setLicensePolicy(true);
    } else {
      setLicensePolicy(false);
    }
  }, [
    dataLicense?.policy_management_expiry_date,
    dataLicense?.policy_management_selected,
  ]);

  useEffect(() => {
    if (permissionList?.length > 0) {
      let permissions: any = [];
      permissionList?.map((row: any) => {
        permissions.push(row?.codename);
      });
      setPermissionsList(permissions);
    } else {
      setPermissionsList([]);
    }
  }, [permissionList]);
  const RequireAuth = (props: any) => {
    const accessToken = localStorage.getItem("access_token") || "";
    if (!accessToken || accessToken === "undefined") {
      return <Navigate to="/login" replace />;
    } else {
      return props?.children;
    }
  };
  const UnAuth = (props: any) => {
    const accessToken = localStorage.getItem("access_token") || "";
    if (accessToken) {
      return <Navigate to="/" replace />;
    } else {
      return props?.children;
    }
  };

  const RolePermission = (props: any) => {
    if (!props?.isLicenseAllow) {
      return <Navigate to="/401" replace />;
    }

    var Roles = props?.role?.map((v: any) => v.toLowerCase());
    const basePath = window.location.pathname;

    if (
      userInfo?.is_superadmin &&
      userInfo?.role?.toLowerCase() === "admin" &&
      superAdminBlockUrl?.includes(basePath)
    ) {
      return <Navigate to="/401" replace />;
    } else if (
      !userInfo?.is_superadmin &&
      userInfo?.role?.toLowerCase() != userInfo?.primary_role?.toLowerCase() &&
      superAdminBlockUrl?.includes(basePath)
    ) {
      return <Navigate to="/401" replace />;
    } else if (
      Roles?.includes("superadmin") &&
      userInfo?.is_superadmin &&
      userInfo?.role?.toLowerCase() === "admin"
    ) {
      return props?.children;
    } else if (
      Roles?.includes(userInfo?.role?.toLowerCase()) &&
      !userInfo?.is_superadmin &&
      userInfo?.role?.toLowerCase() === "admin"
    ) {
      if (props?.permission?.toLowerCase() === "none") {
        return props?.children;
      } else if (
        (permissionList?.find((i: any) => i.codename == props?.permission) ||
          permissionsList?.includes(props?.permission)) &&
        props?.permission?.toLowerCase() !== "none"
      ) {
        return props?.children;
      } else {
        return <Navigate to="/401" replace />;
      }
    } else if (Roles?.includes(userInfo?.role?.toLowerCase())) {
      console.log("ggg");
      if (userInfo?.role?.toLowerCase() === "trainer") {
        return props?.children;
      } else if (props?.permission?.toLowerCase() === "none") {
        return props?.children;
      } else {
        return <Navigate to="/401" replace />;
      }
    }
    return <Navigate to="/401" replace />;
  };
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="login"
          element={
            <UnAuth>
              <Login />
            </UnAuth>
          }
        />

        <Route
          path="authorized"
          element={
            <UnAuth>
              <Authorized />
            </UnAuth>
          }
        />
        <Route
          path="verify"
          element={
            <UnAuth>
              <Verify />
            </UnAuth>
          }
        />
        <Route
          path="verification-success"
          element={
            <UnAuth>
              <VerificationSuccess />
            </UnAuth>
          }
        />
        <Route
          path="signup"
          element={
            <UnAuth>
              <Signup />
            </UnAuth>
          }
        />
        <Route
          path="reset-password"
          element={
            <UnAuth>
              <ResetPassword />
            </UnAuth>
          }
        />
        <Route
          path="new-password"
          element={
            <UnAuth>
              <NewPassword />
            </UnAuth>
          }
        />
        {/* </Route> */}
        <Route
          path="/"
          element={
            <RequireAuth>
              <Layout />
            </RequireAuth>
          }
        >
          <Route
            index
            element={
              <RolePermission
                role={["trainee", "admin", "trainer", "superadmin"]}
                permission="none"
                isLicenseAllow={licenseUploaded}
              >
                <Home />
              </RolePermission>
            }
          />

          <Route
            path="user-management"
            element={
              <RolePermission
                role={["admin", "superadmin"]}
                permission="view_user"
                isLicenseAllow={licenseUploaded}
              >
                <UserList />
              </RolePermission>
            }
          />

          <Route
            path="active-directory-users"
            element={
              <RolePermission
                role={["superadmin"]}
                permission="view_user"
                isLicenseAllow={licenseUploaded}
              >
                <ActiveDirectoryUser />
              </RolePermission>
            }
          />

          <Route
            path="admin-list"
            element={
              <RolePermission
                role={["superAdmin"]}
                isLicenseAllow={licenseUploaded}
              >
                <AdminList />
              </RolePermission>
            }
          />
          <Route
            path="add-admin"
            element={
              <RolePermission
                role={["superAdmin"]}
                isLicenseAllow={licenseUploaded}
              >
                <AddAdmin />
              </RolePermission>
            }
          />

          {/* <Route
            path="user-profile-detail/:edit/:id/:type?"
            element={
              <RolePermission
                role={["admin", "superadmin"]}
                permission="view_user"
                isLicenseAllow={licenseUploaded}
              >
                <UserProfileDetails />
              </RolePermission>
            }
          /> */}
          <Route
            path="admin-detail/:edit/:id/:type?"
            element={
              <RolePermission
                role={["superAdmin"]}
                isLicenseAllow={licenseUploaded}
              >
                {/* <AdminProfile /> */}
                <UserProfileDetails />
              </RolePermission>
            }
          />
          <Route
            path="admin-permission"
            element={
              <RolePermission
                role={["superAdmin"]}
                isLicenseAllow={licenseUploaded}
              >
                <AdminPermissionList />
              </RolePermission>
            }
          />
          <Route
            path="admin-permission/add-new"
            element={
              <RolePermission
                role={["superAdmin"]}
                isLicenseAllow={licenseUploaded}
              >
                <AddNewPermission />
              </RolePermission>
            }
          />
          <Route
            path="admin-permission/detail/:id"
            element={
              <RolePermission
                role={["superAdmin"]}
                isLicenseAllow={licenseUploaded}
              >
                <DetailPermission />
              </RolePermission>
            }
          />
          <Route
            path="company-setting/company-profile"
            element={
              <RolePermission role={["superAdmin"]} isLicenseAllow={true}>
                <CompanyProfileManagement />
              </RolePermission>
            }
          />
          <Route
            path="company-setting/change-company-password"
            element={
              <RolePermission role={["superAdmin"]} isLicenseAllow={true}>
                <ChangeCompanyPassword />
              </RolePermission>
            }
          />
          <Route
            path="company-setting/company-customization"
            element={
              <RolePermission role={["superAdmin"]} isLicenseAllow={true}>
                <CompanyCustomization />
              </RolePermission>
            }
          />
          <Route
            path="company-setting/company-customization/edit-color"
            element={
              <RolePermission role={["superAdmin"]} isLicenseAllow={true}>
                <EditColorTheme />
              </RolePermission>
            }
          />
          <Route
            path="company-setting/license"
            element={
              <RolePermission role={["superAdmin"]} isLicenseAllow={true}>
                <CompanyLicense />
              </RolePermission>
            }
          />
          <Route
            path="company-setting/integration"
            element={
              <RolePermission role={["superAdmin"]} isLicenseAllow={true}>
                <CompanyIntegration />
              </RolePermission>
            }
          />
          <Route
            path="add-user"
            element={
              <RolePermission
                role={["admin", "superadmin"]}
                permission="add_user"
                isLicenseAllow={licenseUploaded}
              >
                <AddUser />
              </RolePermission>
            }
          />

          <Route
            path="user-registration"
            element={
              <RolePermission
                role={["admin", "superadmin"]}
                permission="view_dynamicformfields"
                isLicenseAllow={licenseUploaded}
              >
                <Registration />
              </RolePermission>
            }
          />
          {/* <Route path="personal-registration" element={<PersonalForm />} /> */}
          <Route
            path="department-management"
            element={
              <RolePermission
                role={["admin", "superadmin"]}
                permission="view_department"
                isLicenseAllow={licenseUploaded}
              >
                <DepartmentList />
              </RolePermission>
            }
          />
          <Route
            path="detail-department/:id"
            element={
              <RolePermission
                role={["admin", "superadmin"]}
                permission="view_department"
                isLicenseAllow={licenseUploaded}
              >
                <DepartmentDetail />
              </RolePermission>
            }
          />

          <Route
            path="group-management"
            element={
              <RolePermission
                role={["admin", "superadmin"]}
                permission="view_customusergroup"
                isLicenseAllow={licenseUploaded}
              >
                <GroupManagementList />
              </RolePermission>
            }
          />
          <Route
            path="add-auto-group"
            element={
              <RolePermission
                role={["admin", "superadmin"]}
                permission="add_customusergroup"
                isLicenseAllow={licenseUploaded}
              >
                <AddAutoGroup />
              </RolePermission>
            }
          />
          <Route
            path="config-auto-group/:id"
            element={
              <RolePermission
                role={["admin", "superadmin"]}
                permission="change_customusergroup"
                isLicenseAllow={licenseUploaded}
              >
                <ConfigAutoGroup />
              </RolePermission>
            }
          />
          <Route
            path="add-group/:redirectTo"
            element={
              <RolePermission
                role={["admin", "superadmin"]}
                permission="add_customusergroup"
                isLicenseAllow={licenseUploaded}
              >
                <AddGroup />
              </RolePermission>
            }
          />
          <Route
            path="detail-group/:id"
            element={
              <RolePermission
                role={["admin", "superadmin"]}
                permission="view_customusergroup"
                isLicenseAllow={licenseUploaded}
              >
                <DetailGroup />
              </RolePermission>
            }
          />
          <Route
            path="image-library"
            element={
              <RolePermission
                role={["admin", "superadmin"]}
                permission="view_image"
                isLicenseAllow={licenseUploaded}
              >
                <ImageLibrary />
              </RolePermission>
            }
          />
          <Route
            path="certificates-management"
            element={
              <RolePermission
                role={["admin", "superadmin"]}
                permission="view_certificate"
                isLicenseAllow={licenseUploaded}
              >
                <CertificatesManagement />
              </RolePermission>
            }
          />
          <Route
            path="detail-certificate/:id"
            element={
              <RolePermission
                role={["admin", "superadmin"]}
                permission="view_certificate"
                isLicenseAllow={licenseUploaded}
              >
                <EditCertificate />
                {/* <DetailCertificate /> */}
              </RolePermission>
            }
          />
          <Route
            path="edit-certificate/:id"
            element={
              <RolePermission
                role={["admin", "superadmin"]}
                permission="change_certificate"
                isLicenseAllow={licenseUploaded}
              >
                <EditCertificate />
              </RolePermission>
            }
          />
          <Route
            path="add-certificate"
            element={
              <RolePermission
                role={["admin", "superadmin"]}
                permission="add_certificate"
                isLicenseAllow={licenseUploaded}
              >
                <AddCertificate />
              </RolePermission>
            }
          />

          <Route
            path="email-template-management"
            element={
              <RolePermission
                role={["superadmin"]}
                isLicenseAllow={licenseUploaded}
              >
                <EmailNotificationListing />
              </RolePermission>
            }
          />

          <Route
            path="email-template-management/edit/:id"
            element={
              <RolePermission
                role={["superadmin"]}
                isLicenseAllow={licenseUploaded}
              >
                <AddEmailNotification />
              </RolePermission>
            }
          />

          <Route
            path="email-template-management/view/:view/:id"
            element={
              <RolePermission
                role={["superadmin"]}
                isLicenseAllow={licenseUploaded}
              >
                <ViewEmailNotification />
              </RolePermission>
            }
          />

          {/* <Route
            path="company-profile-management"
            element={<ProfileManagement />}
          /> */}

          <Route
            path="user-profile-detail/:edit/:id/:type?"
            element={
              <RolePermission
                role={["admin", "superadmin"]}
                permission="view_user"
                isLicenseAllow={licenseUploaded}
              >
                <UserProfileDetails />
              </RolePermission>
            }
          />
          <Route
            path="user-profile-edit/:edit/:id/:type?"
            element={
              <RolePermission
                role={["admin", "superadmin"]}
                permission="change_user"
                isLicenseAllow={licenseUploaded}
              >
                <UserProfileDetails />
              </RolePermission>
            }
          />
          <Route
            path="my-profile/:type?"
            element={
              <RolePermission
                role={["superadmin", "admin", "trainer", "trainee"]}
                permission="none"
                isLicenseAllow={licenseUploaded}
              >
                <MyProfile />
              </RolePermission>
            }
          />
          <Route
            path="change-profile-password"
            element={
              <RolePermission
                role={["admin", "trainer", "trainee", "superadmin"]}
                permission="none"
                isLicenseAllow={licenseUploaded}
              >
                <ChangeProflePassword />
              </RolePermission>
            }
          />
          {/* 
Trainer Routes
*/}
          <Route
            path="assessments/assessment-library"
            element={
              <RolePermission
                role={["admin", "superAdmin", "trainer"]}
                permission="view_assessment"
                isLicenseAllow={licenseUploaded}
              >
                <AssessmentList />
              </RolePermission>
            }
          />
          <Route
            path="assessments/add-assessment"
            element={
              <RolePermission
                role={["admin", "superAdmin", "trainer"]}
                permission="add_assessment"
                isLicenseAllow={licenseUploaded}
              >
                <AddAssessment />
              </RolePermission>
            }
          />
          <Route
            path="assessments/update-assessment/:id"
            element={
              <RolePermission
                role={["admin", "superAdmin", "trainer"]}
                permission="change_assessment"
                isLicenseAllow={licenseUploaded}
              >
                <AddAssessment />
              </RolePermission>
            }
          />
          <Route
            path="assessments/assessment-link/:id"
            element={
              <RolePermission
                role={["admin", "superAdmin", "trainer"]}
                permission="view_assessment"
                isLicenseAllow={licenseUploaded}
              >
                <AssessmentLink />
              </RolePermission>
            }
          />
          <Route
            path="assessments/assessment-detail/:id/:isModule?"
            element={
              <RolePermission
                role={["admin", "superAdmin", "trainer"]}
                permission="view_assessment"
                isLicenseAllow={licenseUploaded}
              >
                <AssessmentDetail />
              </RolePermission>
            }
          />
          <Route
            path="module-assessments/module-assessment-detail/:id/:isModule"
            element={
              <RolePermission
                role={["admin", "superAdmin", "trainer"]}
                permission="view_assessment"
                isLicenseAllow={licenseUploaded}
              >
                <AssessmentDetail />
              </RolePermission>
            }
          />

          <Route
            path="questions-library"
            element={
              <RolePermission
                role={["admin", "superAdmin", "trainer"]}
                permission="view_question"
                isLicenseAllow={licenseUploaded}
              >
                <QuestionsList />
              </RolePermission>
            }
          />
          <Route
            path="create-question"
            element={
              <RolePermission
                role={["admin", "superAdmin"]}
                permission="add_question"
                isLicenseAllow={licenseUploaded}
              >
                <CreateQuestion />
              </RolePermission>
            }
          />
          <Route
            path="update-question/:id"
            element={
              <RolePermission
                role={["admin", "superAdmin"]}
                permission="change_question"
                isLicenseAllow={licenseUploaded}
              >
                <CreateQuestion />
              </RolePermission>
            }
          />
          <Route
            path="question-link/:id"
            element={
              <RolePermission
                role={["admin", "superAdmin", "trainer"]}
                permission="view_question"
                isLicenseAllow={licenseUploaded}
              >
                <QuestionLink />
              </RolePermission>
            }
          />
          <Route
            path="question-detail/:id"
            element={
              <RolePermission
                role={["admin", "superAdmin", "trainer"]}
                permission="view_question"
                isLicenseAllow={licenseUploaded}
              >
                <QuestionDetail />
              </RolePermission>
            }
          />
          <Route
            path="module/module-listing"
            element={
              <RolePermission
                role={["admin", "superAdmin", "trainer"]}
                permission="view_module"
                isLicenseAllow={licenseUploaded}
              >
                <ModuleList />
              </RolePermission>
            }
          />
          <Route
            path="module/add-module"
            element={
              <RolePermission
                role={["admin", "superAdmin", "trainer"]}
                permission="add_module"
                isLicenseAllow={licenseUploaded}
              >
                <AddModule />
              </RolePermission>
            }
          />
          <Route
            path="module-link/:id"
            element={
              <RolePermission
                role={["admin", "superAdmin", "trainer"]}
                permission="view_module"
                isLicenseAllow={licenseUploaded}
              >
                <ModuleLink />
              </RolePermission>
            }
          />
          <Route
            path="detail-module/:id"
            element={
              <RolePermission
                role={["admin", "superAdmin", "trainer"]}
                permission="view_module"
                isLicenseAllow={licenseUploaded}
              >
                <ModuleDetail />
              </RolePermission>
            }
          />
          <Route
            path="edit-module/:id"
            element={
              <RolePermission
                role={["admin", "superAdmin", "trainer"]}
                permission="change_module"
                isLicenseAllow={licenseUploaded}
              >
                <EditModule />
              </RolePermission>
            }
          />
          {/* <Route path="modules-members" element={<ModulesMemberLists />} /> */}

          <Route
            path="trainings"
            element={
              <RolePermission
                role={["admin", "superAdmin", "trainer"]}
                permission="view_training"
                isLicenseAllow={licenseUploaded}
              >
                <TrainingLists />
              </RolePermission>
            }
          />
          <Route
            path="add-training"
            element={
              <RolePermission
                role={["admin", "superAdmin", "trainer"]}
                permission="add_training"
                isLicenseAllow={licenseUploaded}
              >
                <AddTraining />
              </RolePermission>
            }
          />
          <Route
            path="update-training/:type/:id"
            element={
              <RolePermission
                role={["admin", "superAdmin", "trainer"]}
                permission="change_training"
                isLicenseAllow={licenseUploaded}
              >
                <AddTraining />
              </RolePermission>
            }
          />
          <Route
            path="detail-training/:type/:id"
            element={
              <RolePermission
                role={["admin", "superAdmin", "trainer"]}
                permission="view_training"
                isLicenseAllow={licenseUploaded}
              >
                <AddTraining />
              </RolePermission>
            }
          />
          <Route
            path="category"
            element={
              <RolePermission
                role={["admin", "superAdmin"]}
                permission="view_category"
                isLicenseAllow={licenseUploaded}
              >
                <Category />
              </RolePermission>
            }
          />
          <Route
            path="category-detail/:id"
            element={
              <RolePermission
                role={["admin", "superAdmin"]}
                permission="view_category"
                isLicenseAllow={licenseUploaded}
              >
                <CategoryDetail />
              </RolePermission>
            }
          />
          <Route
            path="policy-listing"
            element={
              <RolePermission
                role={["admin", "superAdmin"]}
                permission="view_policy"
                isLicenseAllow={isPolicShow2}
              >
                <PolicyListing />
              </RolePermission>
            }
          />

          <Route
            path="add-policy"
            element={
              <RolePermission
                role={["admin", "superadmin"]}
                permission="add_policy"
                isLicenseAllow={isPolicShow2}
              >
                <AddNewPolicy />
              </RolePermission>
            }
          />

          <Route
            path="policy-detail/:id"
            element={
              <RolePermission
                role={["admin", "superAdmin"]}
                permission="view_policy"
                isLicenseAllow={isPolicShow2}
              >
                <PolicyDetails />
              </RolePermission>
            }
          />

          <Route
            path="Edit-policy-detail/:id"
            element={
              <RolePermission
                role={["admin", "superAdmin"]}
                permission="change_policy"
                isLicenseAllow={isPolicShow2}
              >
                <EditPolicyDetails />
              </RolePermission>
            }
          />

          <Route
            path="policy-assessment"
            element={
              <RolePermission
                role={["admin", "superAdmin"]}
                permission="view_policyassessment"
                isLicenseAllow={isPolicShow2}
              >
                <PolicyAssessment />
              </RolePermission>
            }
          />
          <Route
            path="policy-assessment/add-new"
            element={
              <RolePermission
                role={["admin", "superAdmin"]}
                permission="add_policyassessment"
                isLicenseAllow={isPolicShow2}
              >
                <PolicyAssessmentAddNew />
              </RolePermission>
            }
          />

          <Route
            path="policy-assessment/update/:id"
            element={
              <RolePermission
                role={["admin", "superAdmin"]}
                permission="change_policyassessment"
                isLicenseAllow={isPolicShow2}
              >
                <PolicyAssessmentAddNew />
              </RolePermission>
            }
          />

          <Route
            path="policy-assessment-result/:assessment_id"
            element={
              <RolePermission
                role={["admin", "superAdmin"]}
                permission="view_policyassessment"
                isLicenseAllow={isPolicShow2}
              >
                <PolicyAssessmentResult />
              </RolePermission>
            }
          />

          <Route
            path="policy-assessment-detail/:assessment_id"
            element={
              <RolePermission
                role={["admin", "superAdmin"]}
                permission="view_policyassessment"
                isLicenseAllow={isPolicShow2}
              >
                <PolicyAssessmentDetail />
              </RolePermission>
            }
          />

          {/* Trainee Routes */}
          <Route
            path="browse-trainings"
            element={
              <RolePermission
                role={["trainee"]}
                permission="none"
                isLicenseAllow={licenseUploaded}
              >
                <BrowseTrainingLists />
              </RolePermission>
            }
          />
          <Route
            path="modules-members"
            element={
              <RolePermission
                role={["trainee"]}
                permission="none"
                isLicenseAllow={licenseUploaded}
              >
                <ModulesMemberLists />
              </RolePermission>
            }
          />

          <Route
            path="assessment-started/:training_id/:module_id/:assessment_id"
            element={
              <RolePermission
                role={["trainee"]}
                permission="none"
                isLicenseAllow={licenseUploaded}
              >
                <AssessmentName />
              </RolePermission>
            }
          />
          <Route
            path="assessment-start/:training_id/:module_id/:assessment_id"
            element={
              <RolePermission
                role={["trainee"]}
                permission="none"
                isLicenseAllow={licenseUploaded}
              >
                <StartAssessment />
              </RolePermission>
            }
          />
          <Route
            path="progress-report/:training_id/:module_id/:assessment_id"
            element={
              <RolePermission
                role={["trainee"]}
                permission="none"
                isLicenseAllow={licenseUploaded}
              >
                <ProgressReport />
              </RolePermission>
            }
          />
          <Route
            path="training-details/:id"
            element={
              <RolePermission
                role={["trainee"]}
                permission="none"
                isLicenseAllow={licenseUploaded}
              >
                <TrainingDetails />
              </RolePermission>
            }
          />
          <Route
            path="success-modal/:isOpen/:training_id/:module_id/:assessment_id"
            element={
              <RolePermission
                role={["trainee"]}
                permission="none"
                isLicenseAllow={licenseUploaded}
              >
                <TrainingSuccessModal />
              </RolePermission>
            }
          />
          <Route
            path="my-training"
            element={
              <RolePermission
                role={["trainee"]}
                permission="none"
                isLicenseAllow={licenseUploaded}
              >
                <MyTrainingLists />
              </RolePermission>
            }
          />
          <Route
            path="training/:training_id/lectures/:id"
            element={
              <RolePermission
                role={["trainee"]}
                permission="none"
                isLicenseAllow={licenseUploaded}
              >
                <Lectures />
              </RolePermission>
            }
          />
          <Route
            path="training/:training_id/lectures/:id/:resume"
            element={
              <RolePermission
                role={["trainee"]}
                permission="none"
                isLicenseAllow={licenseUploaded}
              >
                <Lectures />
              </RolePermission>
            }
          />
          <Route
            path="certificate/download/:training_id/:is_download/:redirectTo?"
            element={
              <RolePermission
                role={["trainee"]}
                permission="none"
                isLicenseAllow={licenseUploaded}
              >
                <CertificateDownload />
              </RolePermission>
            }
          />
          <Route
            path="my-certificates"
            element={
              <RolePermission
                role={["trainee"]}
                permission="none"
                isLicenseAllow={licenseUploaded}
              >
                <MyCertificates />
              </RolePermission>
            }
          />

          <Route
            path="notifications"
            element={
              <RolePermission
                role={["trainee", "admin", "trainer", "superadmin"]}
                permission="none"
                isLicenseAllow={licenseUploaded}
              >
                <Notifications />
              </RolePermission>
            }
          />
          <Route
            path="policy-assessment-start/:policy_assessment_id"
            element={
              <RolePermission
                role={["trainee", "admin", "trainer", "superadmin"]}
                permission="none"
                isLicenseAllow={licenseUploaded}
              >
                <StartPolicyAssessment />
              </RolePermission>
            }
          />
          {/* <Route path="test-video" element={<VideoPlayer5 />} /> */}

          <Route
            path="policy-assessment-started/:policy_assessment_id"
            element={
              <RolePermission
                role={["trainee", "admin", "trainer", "superadmin"]}
                permission="none"
                isLicenseAllow={licenseUploaded}
              >
                <PolicyAssessmentStarted />
              </RolePermission>
            }
          />
          <Route
            path="policy-assessment-progress-report/:policy_assessment_id"
            element={
              <RolePermission
                role={["trainee", "admin", "trainer", "superadmin"]}
                permission="none"
                isLicenseAllow={licenseUploaded}
              >
                <PolicyAssessmentProgressReport />
              </RolePermission>
            }
          />

          <Route
            path="company-policy-list"
            element={
              <RolePermission
                role={["trainee", "admin", "trainer"]}
                permission="none"
                isLicenseAllow={licenseUploaded}
              >
                <CompanyPolicyListing />
              </RolePermission>
            }
          />
          <Route
            path="company-policy-assessment-list"
            element={
              <RolePermission
                role={["trainee", "admin", "trainer"]}
                permission="none"
                isLicenseAllow={licenseUploaded}
              >
                <CompanyPolicyAssessmentListing />
              </RolePermission>
            }
          />
          <Route
            path="scorm-started/:training_id/:module_id"
            element={
              <RolePermission
                role={["trainee"]}
                permission="none"
                isLicenseAllow={licenseUploaded}
              >
                <ScormStarted />
              </RolePermission>
            }
          />

          <Route path="401" element={<NotAuthorizedPage />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
