import BlotFormatter from "quill-blot-formatter";
import "quill/dist/quill.snow.css";
import { useEffect } from "react";
import { useQuill } from "react-quilljs";
import useCompanyLanguage from "../../../hooks/language";

import "./styles.css";
const PolicyEditor = (props: any) => {
  const lang = useCompanyLanguage();
  const { setDescription, prviousData } = props;
  const { quill, quillRef, Quill } = useQuill({
    placeholder: "Write here ...",
    theme: "snow",

    modules: {
      blotFormatter: {},
      toolbar: [
        [
          { header: [1, 2, 3, 4, 5, 6, false] },
          // { header: "2" },
          { font: [] },
          { size: [] },
        ],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ align: [] }],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        // ["image"],
        [{ color: [] }], // dropdown with defaults from theme , { background: []}

        // ["clean"],
        // [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ],
      clipboard: {
        matchVisual: false,
      },
    },
  });

  if (Quill && !quill) {
    // const BlotFormatter = require('quill-blot-formatter');
    Quill.register("modules/blotFormatter", BlotFormatter);
  }
  useEffect(() => {
    if (quill) {
      quill.on("text-change", (delta: any, oldContents: any, source: any) => {
        // console.log("Text change!");
        // console.log(delta);
        // let currrentContents = quill.getContents();
        // console.log(currrentContents.diff(oldContents));
        // console.log("Text eeee!");
        // console.log("Text change!");
        // console.log("getText", quill.getText()); // Get text only
        // console.log(quill.getContents()); // Get delta contents
        // console.log(quill.root.innerHTML); // Get innerHTML using quill
        // console.log("innerHTML", quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
        // console.log(source);
        // if (quill.getText() && currrentContents.diff(oldContents)) {
        setDescription(quillRef.current.firstChild.innerHTML);
        // }
      });
      //   quill.setContents(`<div>${dd}</div>`);
      // if (prviousData) {
      quill.clipboard.dangerouslyPasteHTML(prviousData);
      // setCertificateData(prviousData);
      // }
    }
  }, [quill, Quill, prviousData]);

  return (
    <div className="row mx-0 mt-2" style={{ direction: lang === "ar" ? "ltr" : "unset" }}>
      <div ref={quillRef} />
    </div>
  );
};

export default PolicyEditor;
