import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import React from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface VerticalChartProps {
  verticalChartData: Chart.ChartData;
  verticalChartOptions: Chart.ChartOptions;
}

const VerticalChart: React.FC<VerticalChartProps> = ({
  verticalChartData,
  verticalChartOptions,
}) => {
  return <Bar options={verticalChartOptions as any} data={verticalChartData as any} />;
};

export default VerticalChart;
