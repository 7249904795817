import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
export interface DataList {
  count: number;
  next: string | null;
  previous: string | null;
  results: any[];
}
export interface DetailFiilter {
  departments_details: [];
  designations: [];
  experience_levels: [];
  roles: [];
}
export interface Detail {
  id: number;
  name: string;
  created_at: string;
  is_auto_created: boolean;
  members: [];
  filters?: any[];
}
export interface States {
  data: DataList;
  wholedata: DataList;
  userListData?: DataList;
  success?: string;
  error?: string;
  errors?: any;
  detailData?: Detail;
  membersData?: DataList;
  wholemembersData?: DataList;
  page?: number;
}
export const initialState: States = {
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  wholedata: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  membersData: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  wholemembersData: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  userListData: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  page: 1,
};

export const groupManagementSlice = createSlice({
  name: "groupManagement",
  initialState,
  reducers: {
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setWholeData: (state, { payload }) => {
      state.wholedata = payload;
    },
    setDetailData: (state, { payload }) => {
      state.detailData = payload;
    },
    setMembersData: (state, { payload }) => {
      state.membersData = payload;
    },
    setWholeMembersData: (state, { payload }) => {
      state.wholemembersData = payload;
    },
    setUserListData: (state, { payload }) => {
      state.userListData = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
  },
});

export const {
  setData,
  setWholeData,
  setUserListData,
  setDetailData,
  setPage,
  setMembersData,
  setWholeMembersData,
} = groupManagementSlice.actions;

export const groupManagementSelector = (state: RootState) => state;

export default groupManagementSlice.reducer;
