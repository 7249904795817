/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { languages } from "../utils/languages";

const useCompanyLanguage = () => {
  const userInfo = useSelector((state: any) => state.authReducer.loginData);
  const [language, setLanguage] = useState<string>("en");
  const companyFormData =
    useSelector((state: any) => state.profileManagementReducer.company_form_data) || [];

  useEffect(() => {
    if (userInfo?.preferred_language) {
      const lang = languages?.find((r: any) => {
        return r?.name?.toString() === userInfo?.preferred_language?.toString();
      });
      setLanguage(lang?.code?.toString() || "en");
    } else {
      const lang = languages?.find((r: any) => {
        return r?.name?.toString() === companyFormData?.company_language?.toString();
      });
      setLanguage(lang?.code?.toString() || "en");
    }
  }, [companyFormData?.company_language, languages, userInfo?.preferred_language]);

  return language;
};

export default useCompanyLanguage;
