import axiosPrivate from "../lib/axiosPrivate";
import { setLoading } from "../store/reducers/loaderReducer";
import { AppThunk } from "../store/store";

import {
  setErr,
  setErrors,
  setSuccess,
} from "../store/reducers/alertMessageReducer";
import {
  setAssessmentData,
  setWholeData,
  setWholeDetail,
  setdata,
} from "../store/reducers/assessmentReducer";
import { setProgress } from "../store/reducers/progressReducer";

export interface AddAssessment {}

export interface GetData {
  page: number;
  page_size: number;
  search: string;
}

export const getListData =
  (payload: GetData): AppThunk =>
  async (dispatch) => {
    dispatch(setdata({}));
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .get(
          `assessments/assessment/?page=${payload.page}&page_size=${payload.page_size}&search=${payload.search}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setdata(data));
          dispatch(setLoading(false));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getWholeListData =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setWholeData({}));
    // dispatch(setLoading(true));
    try {
      await axiosPrivate
        .get(
          `assessments/assessment/?page=${payload.page}&page_size=${payload.page_size}&search=${payload.search}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setWholeData(data));
          // dispatch(setLoading(false));
        })
        .catch((error) => {
          // dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      // dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const deleteAssessment =
  (id: Number): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .delete(`assessments/assessment/${id}/`)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (response.status == 204 || response.status == 200) {
            dispatch(setSuccess("Assessment deleted successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const bulkDeleteAssessments =
  (payload: any[]): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .delete(`assessments/assessment/bulk_delete/`, {
          data: JSON.stringify({ assessment_ids: payload }),
        })
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const addAssessment =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    let formData = new FormData();
    if (payload.assessment_status)
      formData.append("assessment_status", payload?.assessment_status);
    if (payload.category) formData.append("category", payload?.category);

    if (payload.description)
      formData.append("description", payload?.description);

    if (payload.name) formData.append("name", payload?.name);
    if (payload.passing_criteria)
      formData.append("passing_criteria", payload?.passing_criteria);

    formData.append("shuffle_order", payload?.shuffle_order);
    if (payload.time_limit) formData.append("time_limit", payload?.time_limit);
    if (payload.time_option)
      formData.append("time_option", payload?.time_option);
    if (payload?.questions.length > 0)
      formData.append("questions", payload?.questions);
    if (payload?.file)
      formData.append("file_name", payload?.file?.imagename + Math.random());
    if (payload.file)
      formData.append("file", payload?.file?.image, payload?.file?.imagename);

    try {
      await axiosPrivate
        .post(`assessments/assessment/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=myboundary",
            enctype: "multipart/form-data; boundary=myboundary",
          },
          onUploadProgress: (progressEvent: any) => {
            dispatch(
              setProgress({
                total: progressEvent?.total,
                loaded: progressEvent?.loaded,
              })
            );
          },
        })
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setSuccess("Assessment added successfully!"));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
    dispatch(
      setProgress({
        total: 0,
        loaded: 0,
      })
    );
  };

export const getAssessment =
  (payload: any): AppThunk =>
  async (dispatch) => {
    // dispatch(setLoading(true));
    dispatch(setAssessmentData({}));
    try {
      await axiosPrivate
        .get("assessments/assessment/" + payload.id + "/")
        .then((response) => {
          const data = response.data;
          // dispatch(setLoading(false));
          if (response.status === 200) {
            dispatch(setAssessmentData(data));
          } else {
            dispatch(setErr("Something went wrong, Please try again"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const updateAssessment =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    let formData = new FormData();
    if (payload.assessment_status)
      formData.append("assessment_status", payload?.assessment_status);
    if (payload.category) formData.append("category", payload?.category);

    if (payload.description)
      formData.append("description", payload?.description);

    if (payload.name) formData.append("name", payload?.name);
    if (payload.passing_criteria)
      formData.append("passing_criteria", payload?.passing_criteria);

    formData.append("shuffle_order", payload?.shuffle_order);
    if (payload.time_limit) formData.append("time_limit", payload?.time_limit);
    if (payload.time_option)
      formData.append("time_option", payload?.time_option);
    if (payload?.questions.length > 0)
      formData.append("questions", payload?.questions);
    if (payload?.file)
      formData.append("file_name", payload?.file?.imagename + Math.random());
    if (payload.file)
      formData.append("file", payload?.file?.image, payload?.file?.imagename);

    // if (payload?.remove) {

    //   formData.append("file_name", "");
    //   formData.append("file", );
    // }

    try {
      await axiosPrivate
        .patch(`assessments/assessment/${payload.id}/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=myboundary",
            enctype: "multipart/form-data; boundary=myboundary",
          },
          onUploadProgress: (progressEvent: any) => {
            dispatch(
              setProgress({
                total: progressEvent?.total,
                loaded: progressEvent?.loaded,
              })
            );
          },
        })
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setSuccess("Assessment updated successfully!"));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else if (error?.response?.data) {
            dispatch(setErrors(error?.response?.data));
          } else {
            dispatch(setErr("Something went wrong, Please try again"));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
    dispatch(
      setProgress({
        total: 0,
        loaded: 0,
      })
    );
  };
export const getDetailDataAssociation =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setdata({}));
    try {
      await axiosPrivate
        .get(
          `assessments/assessment/${payload?.id}/association/?page=${payload?.page}&page_size=${payload?.page_size}&search=${payload?.search}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setdata(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getWholeDetailDataAssociation =
  (payload: any): AppThunk =>
  async (dispatch) => {
    // dispatch(setLoading(true));
    dispatch(setWholeDetail({}));
    try {
      await axiosPrivate
        .get(
          `assessments/assessment/${payload?.id}/association/?page=${payload?.page}&page_size=${payload?.page_size}&search=${payload?.search}`
        )
        .then((response) => {
          const data = response.data;
          // dispatch(setLoading(false));
          dispatch(setWholeDetail(data));
        })
        .catch((error) => {
          // dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      // dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };
