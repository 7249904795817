import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import DataTable from "../../components/table/DataTable";
import TableFilters from "../../components/table/TableFilters";
import useCompanyLanguage from "../../hooks/language";
import usePermission from "../../hooks/usePermission";
import ViewSVGAction from "../../icons/ViewSVGAction";
import {
  getDetailDataAssociation,
  getModulesForView,
  getWholeDetailDataAssociation,
} from "../../services/moduleService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { setWholeDetail } from "../../store/reducers/moduleReducer";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { confirmProps } from "../../utils/confirmProps";
import Style from "../category/Category.module.scss";

function ModuleLink() {
  const { id } = useParams();

  const navigate = useNavigate();
  const confirm = useConfirm();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const addPermission = usePermission("add_certificate");
  const editUpdatePermission = usePermission("change_category");
  const deletePermission = usePermission("delete_category");
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<number[]>([]);
  const dataList: any = useAppSelector((state) => state.moduleReducer.data);
  const wholeData: any = useAppSelector(
    (state) => state.moduleReducer.wholedetail
  );
  const detail = useAppSelector((state) => state.moduleReducer.moduleData);
  const [pageNum, setPageNum] = useState(Number(1));
  const [perPageNum, setPerPageNum] = useState(10);
  const [pageSearch, setPageSearch] = useState("");

  const [list, setList] = useState([]);

  const alertMessage = useAppSelector(alertMessageSelector).alertMessageReducer;
  useEffect(() => {
    dispatch(getModulesForView(Number(id)));
  }, [id]);
  useEffect(() => {
    AllData();
  }, [pageNum, perPageNum, pageSearch]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }

    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));

      AllData();
    }
  }, [alertMessage]);

  const AllData = () => {
    let query: any = {};
    query.id = Number(id);
    query.page = Number(pageNum);

    query.page_size = Number(perPageNum);

    query.search = String(pageSearch);
    dispatch(getDetailDataAssociation(query));
  };

  const handleDeleteAction = (row: any) => {
    confirm({
      title: t("Are you sure!"),
      description: `Do you want to delete ${row?.name} category?`,
      ...confirmProps,
    })
      .then(() => {
        // dispatch(deleteCategory(Number(row?.id)));
      })
      .catch(() => {});
    return;
  };

  const handleNavigation = (row: any) => {
    navigate(`/detail-training/view/${row?.id}`);
    return;

    // alert("Pending...");
  };
  const modifyRows = () => {
    let f_rows: any = [];
    dataList?.results?.forEach(
      (
        r: {
          id: string;
          name: string;
          created_at: string;
        },
        i: number
      ) => {
        f_rows = [
          ...f_rows,
          {
            id: r.id,
            name: r.name,
            associated_to: "Training",
            created_at:
              moment(new Date(r.created_at)).format("MMM Do, YYYY") || "",

            actions: (
              <>
                <div style={{ textAlign: "center", paddingLeft: "1em" }}>
                  <button
                    className="btn-link"
                    type="button"
                    onClick={(e) => {
                      handleNavigation(r);
                    }}
                  >
                    <ViewSVGAction />
                  </button>
                </div>
              </>
            ),
          },
        ];
      }
    );
    setList(f_rows);
  };
  // useEffect(() => {
  //   let data: any = dataList.results;
  //   if (Object.keys(dataList).length === 0) {
  //     setRows([]);
  //   } else setRows(data);
  // }, [dataList]);
  useEffect(() => {
    modifyRows();
  }, [dataList]);

  const handleEditCategory = (row: any) => {
    alert("Pending...");
  };
  const handleChangePage = (event: React.ChangeEvent<unknown>, val: number) => {
    setPageNum(Number(val));
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPageNum(1);
    setPerPageNum(Number(rowsPerPage));
  };
  const handleChangeSearch = (val: string) => {
    setPageNum(1);
    setPageSearch(String(val));
    setPerPageNum(Number(10));
  };

  const handleClicked = () => {
    getWhole();
  };

  const getWhole = () => {
    if (dataList)
      dispatch(
        getWholeDetailDataAssociation({
          id: id,
          page_size: dataList.count,
          page: 1,
          search: "",
        })
      );
  };

  return (
    <div className={`${Style.categoryStart}`}>
      <div style={{ paddingBottom: "0.6em" }}>
        <Header
          page={detail?.name}
          mainHead={t("Module")}
          backLink="/module/module-listing"
        />
        <TableFilters
          dataList={dataList}
          itemPerPage={Number(perPageNum)}
          selectedCheckBox={selectedCheckBox}
          setItemPerPage={handleChangeRowsPerPage}
          setSearchTable={handleChangeSearch}
          handle="category"
          isCheckboxes={false}
          exportCSV={dataList?.count ? true : false}
          setParentData={() => dispatch(setWholeDetail({}))}
          handleClicked={handleClicked}
          rows={wholeData?.results?.map((r: any, i: any) => ({
            // id: r.id,
            name: r.name,
            associated_to: "Training",
            created_at:
              moment(new Date(r.created_at)).format("MMM Do, YYYY") || "",
          }))}
          headers={["Name", "Associated to", "Date Created"]}
          filename={`module_detail_of_${id}.csv`}
        />
        <DataTable
          columnData={[
            {
              id: "name",
              name: t("Name"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "checkbox",
            },
            {
              id: "associated_to",
              name: t("Associated To"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "created_at",
              name: t("Date Created"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "action",
              name: t("Actions"),
              enableSort: false,
              align: "center",
              disablePadding: "none",
            },
          ]}
          rows={list}
          isCheckboxes={false}
          selectedRowsPerPage={String(perPageNum)}
          totalRecordCount={dataList.count}
          handleChangePageParent={handleChangePage}
          pagenum={Number(pageNum)}
          handleChangeRowsPerPageParent={handleChangeRowsPerPage}
          selectedCheckBox={selectedCheckBox}
          setSelectedCheckBox={setSelectedCheckBox}
        />
      </div>
    </div>
  );
}

export default ModuleLink;
