// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonalForm_formStart__F5Lan {
  background-color: #fff;
}
.PersonalForm_formStart__F5Lan h2 {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #2b3674;
}
.PersonalForm_formStart__F5Lan form .PersonalForm_input__pIExE {
  border-radius: 4px;
  height: 50px;
  border: 2px solid #e2e8f0;
  margin-top: 3px;
  margin-bottom: 20px;
  padding: 5px 10px;
}
.PersonalForm_formStart__F5Lan form .PersonalForm_checkStart__k1tpF {
  text-align: center;
}
.PersonalForm_formStart__F5Lan form .PersonalForm_checkStart__k1tpF span {
  padding: 0;
}
.PersonalForm_formStart__F5Lan form .PersonalForm_btn__-sdc0 {
  width: 167px;
  height: 42px;
  border-radius: 4px;
  border: none;
}
.PersonalForm_formStart__F5Lan form .PersonalForm_buttuns__y1LFf {
  margin-top: 50px;
}
.PersonalForm_formStart__F5Lan form .PersonalForm_btnPrimary__UMPO9 {
  background-color: #0089cf;
  color: #fff;
}
.PersonalForm_formStart__F5Lan form .PersonalForm_btnSecond__CJLvu {
  background-color: transparent;
}
.PersonalForm_formStart__F5Lan form .PersonalForm_labelHeader__SCucQ {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #212b36;
}
.PersonalForm_formStart__F5Lan form .PersonalForm_subLabelHeader__eVSQ1 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #64748b;
}`, "",{"version":3,"sources":["webpack://./src/pages/registration/PersonalForm.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;AACE;EAEE,eAAA;EACA,gBAAA;EACA,uBAAA;EACA,cAAA;AAAJ;AAII;EACE,kBAAA;EACA,YAAA;EACA,yBAAA;EACA,eAAA;EACA,mBAAA;EACA,iBAAA;AAFN;AAKI;EACE,kBAAA;AAHN;AAKM;EACE,UAAA;AAHR;AAOI;EACE,YAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;AALN;AAQI;EACE,gBAAA;AANN;AASI;EACE,yBAAA;EACA,WAAA;AAPN;AAUI;EACE,6BAAA;AARN;AAWI;EAEE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;AAVN;AAaI;EAEE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;AAZN","sourcesContent":[".formStart {\n  background-color: #fff;\n\n  h2 {\n    // font-family: dmSans;\n    font-size: 24px;\n    font-weight: 700;\n    letter-spacing: -0.02em;\n    color: #2b3674;\n  }\n\n  form {\n    .input {\n      border-radius: 4px;\n      height: 50px;\n      border: 2px solid #e2e8f0;\n      margin-top: 3px;\n      margin-bottom: 20px;\n      padding: 5px 10px;\n    }\n\n    .checkStart {\n      text-align: center;\n\n      span {\n        padding: 0;\n      }\n    }\n\n    .btn {\n      width: 167px;\n      height: 42px;\n      border-radius: 4px;\n      border: none;\n    }\n\n    .buttuns {\n      margin-top: 50px;\n    }\n\n    .btnPrimary {\n      background-color: #0089cf;\n      color: #fff;\n    }\n\n    .btnSecond {\n      background-color: transparent;\n    }\n\n    .labelHeader {\n      // font-family: dmSans;\n      font-size: 16px;\n      font-weight: 400;\n      line-height: 22px;\n      letter-spacing: 0px;\n      text-align: left;\n      color: #212b36;\n    }\n\n    .subLabelHeader {\n      // font-family: dmSans;\n      font-size: 14px;\n      font-weight: 500;\n      line-height: 24px;\n      letter-spacing: 0px;\n      text-align: left;\n      color: #64748b;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formStart": `PersonalForm_formStart__F5Lan`,
	"input": `PersonalForm_input__pIExE`,
	"checkStart": `PersonalForm_checkStart__k1tpF`,
	"btn": `PersonalForm_btn__-sdc0`,
	"buttuns": `PersonalForm_buttuns__y1LFf`,
	"btnPrimary": `PersonalForm_btnPrimary__UMPO9`,
	"btnSecond": `PersonalForm_btnSecond__CJLvu`,
	"labelHeader": `PersonalForm_labelHeader__SCucQ`,
	"subLabelHeader": `PersonalForm_subLabelHeader__eVSQ1`
};
export default ___CSS_LOADER_EXPORT___;
