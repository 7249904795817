import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import IconButtonAdvance from "../../components/IconButtonAdvance";
import ImageSelect from "../../components/ImageSelect";
import useColor from "../../hooks/useColor";
import usePermission from "../../hooks/usePermission";
import BaseImage from "../../images/ph_image.png";
import { getCompanyForm } from "../../services/profileManagementService";
import { getUser, updateUserDetails } from "../../services/userService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { setUpdateUserDetails } from "../../store/reducers/userReducers";
import { RootState, useAppDispatch, useAppSelector } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import UserPermission from "../adminUsers/AdminPermission/UserPermission";
import Style from "./UserProfile.module.scss";
import UserProfileForm from "./UserProfileForm";

const $ = require("jquery");

function UserProfileDetails() {
  const editUpdatePermission = usePermission("change_user");
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { edit, type } = useParams();
  const [selectedImage, setSelectedImage] = useState<any>();
  const [userImage, setUserImage] = useState("");
  const [selectedImageBase64, setSelectedImageBase64] = useState<any>();
  const [image, setImage] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [edit2, setEdit2] = useState(true);
  const t = useTranslation();
  console.log("edit2", edit2);
  const userData =
    useSelector((state: RootState) => state.userReducer.user_data) || [];
  console.log("userData", userData);
  const updateUserData =
    useSelector((state: RootState) => state.userReducer.update_user_details) ||
    [];

  const alertMessage = useAppSelector(alertMessageSelector).alertMessageReducer;

  useEffect(() => {
    if (edit === "outlook") {
      setEdit2(false);
    } else {
      setEdit2(true);
    }
  }, [edit]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
    }
  }, [alertMessage]);

  useEffect(() => {
    setImage(null);
    setSelectedImage("");
    setSelectedImageBase64("");
    if (userData && userData.profile_picture) {
      setUserImage(userData.profile_picture);
    }
  }, [userData]);

  useEffect(() => {
    if (Object.entries(updateUserData).length > 0) {
      setEdit2(false);
      dispatch(setUpdateUserDetails(null));
    }
  }, [updateUserData]);

  const isLoading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );

  $(document).ready(function () {
    $("#OpenImgUpload").click(function () {
      $("#file").click();
    });
  });

  const updatePic = () => {
    dispatch(
      updateUserDetails({
        id: id,
        profile_picture: {
          ...selectedImage,
          image: selectedImage,
          imagename: selectedImage.name,
        },
        image: selectedImage,
        imagename: selectedImage.name,
      })
    );
    setSelectedImage("");
  };

  useEffect(() => {
    if (loading && !userImage) {
      setLoading(false);
    }
  }, [isLoading]);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    if (id) {
      dispatch(getUser({ id: id }));
    }
  };

  useEffect(() => {
    getCompanyFormData();
  }, []);

  const getCompanyFormData = () => {
    dispatch(getCompanyForm());
  };

  const formSubmit = (payload: any) => {
    let img = null;
    if (userImage && !selectedImage) {
      img = userImage;
    } else if (selectedImage) {
      img = {
        ...selectedImage,
        image: selectedImage,
        imagename: selectedImage?.name,
      };
    }
    if (img)
      payload = {
        ...payload,
        id: id,
        profile_picture: img,
        image: selectedImage,
        imagename: selectedImage?.name,
      };
    else {
      payload = {
        ...payload,
        id: id,
      };
    }
    payload.personal_detail_data = JSON.stringify({
      address: payload?.house_number,
      gender: payload?.gender,
      department: payload?.department,
      designation: payload?.designation,
      experience_level: payload?.experience,
      street_number: payload?.street_number,
      country: payload?.country,
      city: payload?.city,
      region: payload?.region,
      date_of_birth: payload?.date_of_birth,
    });
    dispatch(updateUserDetails(payload));
  };

  const primaryButtonText =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;
  const primaryButton =
    useColor("button_primary") || defaultThemeColor.button_primary;

  return (
    <>
      <div>
        <Header
          page={type == "admin" ? t("Admin Profile") : t("User Profile")}
          mainHead={
            type == "admin"
              ? t("Admin Management / Admin Profile")
              : t("User Management / User Profile")
          }
          backLink={type == "admin" ? "/admin-list" : "/user-management"}
        />
      </div>

      <div
        className={`${Style.background} d-flex flex-wrap`}
        style={{
          background:
            useColor("interface_foreground_color") ||
            defaultThemeColor.interface_foreground_color,
        }}
      >
        <div
          className={`${Style.widthWrapLeft}`}
          style={{
            borderRight: "2px solid #E2E8F0",
          }}
        >
          <div className={`col-12 my-5 mb-0`}>
            <div
              className={`${Style.textHeader} mb-5`}
              style={{
                color:
                  useColor("interface_foreground_text_color") ||
                  defaultThemeColor.interface_foreground_text_color,
              }}
            >
              {t("Profile Details")}
            </div>
          </div>

          <ImageSelect
            selectedImage={selectedImage}
            imageFromBackend={
              userData && userData.profile_picture ? userImage : ""
            }
            BaseImage={BaseImage}
            isLoading={isLoading}
            setSelectedImage={setSelectedImage}
            updatePicSubmit={updatePic}
            minDimensions={true}
            showButtons={true}
            uploadButtonComponent={""}
            maxFileSizeinBytes={5242880}
          />

          <div className="d-flex justify-content-center col-12 mt-4 mb-1">
            <div
              className={`${Style.userName}`}
              style={{
                color:
                  useColor("interface_foreground_text_color") ||
                  defaultThemeColor.interface_foreground_text_color,
              }}
            >
              {userData.username
                ? userData.username
                : (userData.first_name || "") +
                  " " +
                  (userData.last_name || "")}
            </div>
          </div>
          <div
            className={`col-12 ${Style.useremail} d-flex justify-content-center`}
            style={{
              color:
                useColor("interface_foreground_text_color") ||
                defaultThemeColor.interface_foreground_text_color,
            }}
          >
            {userData.email}
          </div>
        </div>

        <div className={`${Style.widthWrapRight} row`}>
          <div className="d-flex justify-content-between align-items-center col-12">
            <div
              className={`${Style.detailsHeader} my-5 mb-4`}
              style={{
                color:
                  useColor("interface_foreground_text_color") ||
                  defaultThemeColor.interface_foreground_text_color,
              }}
            >
              {t("Personal Details")}
            </div>
            <div className={`mx-4`}>
              {!edit2
                ? editUpdatePermission && (
                    <IconButtonAdvance
                      size="small"
                      outlined={false}
                      onClick={() => {
                        setEdit2(true);
                      }}
                      textColor={primaryButtonText}
                      outlineColor={primaryButton}
                      text={t("Edit")}
                      className=""
                      bg={primaryButton}
                      disabled={false}
                      icon={
                        <ModeEditIcon
                          style={{
                            fontSize: 19,
                            color: primaryButtonText,
                            cursor: "pointer",
                          }}
                        />
                      }
                    ></IconButtonAdvance>
                  )
                : null}
            </div>
          </div>
          <UserProfileForm
            edit={edit2}
            setEdit={setEdit2}
            formSubmit={formSubmit}
          />
        </div>
        {!edit2 && userData?.primary_role == "Admin" && (
          <>
            <div className="row">
              <div className={`${Style.leftSpace2} ${Style.topSpace} mb-4`}>
                <div
                  className={`${Style.textHeader}`}
                  style={{ paddingLeft: "1em" }}
                >
                  {t("Permissions")}
                </div>
              </div>
              {userData?.permission_list &&
              userData?.permission_list?.length > 0 ? (
                <UserPermission permissionList={userData?.permission_list} />
              ) : (
                <p style={{ paddingLeft: "40px" }}> {t("Record not found")}</p>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default UserProfileDetails;
