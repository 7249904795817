function AddSubSVG() {
  return (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.625 6.125C2.625 6.35706 2.71719 6.57962 2.88128 6.74372C3.04538 6.90781 3.26794 7 3.5 7C3.73206 7 3.95462 6.90781 4.11872 6.74372C4.28281 6.57962 4.375 6.35706 4.375 6.125V4.375H6.125C6.35706 4.375 6.57962 4.28281 6.74372 4.11872C6.90781 3.95462 7 3.73206 7 3.5C7 3.26794 6.90781 3.04538 6.74372 2.88128C6.57962 2.71719 6.35706 2.625 6.125 2.625H4.375V0.875C4.375 0.642936 4.28281 0.420376 4.11872 0.256282C3.95462 0.0921872 3.73206 0 3.5 0C3.26794 0 3.04538 0.0921872 2.88128 0.256282C2.71719 0.420376 2.625 0.642936 2.625 0.875V2.625H0.875C0.642936 2.625 0.420376 2.71719 0.256282 2.88128C0.0921872 3.04538 0 3.26794 0 3.5C0 3.73206 0.0921872 3.95462 0.256282 4.11872C0.420376 4.28281 0.642936 4.375 0.875 4.375H2.625V6.125Z"
        fill="#2B3674"
      />
      <path
        d="M15.1333 7H11.6667C11.4368 7 11.2164 7.09783 11.0538 7.27197C10.8913 7.44611 10.8 7.6823 10.8 7.92857V8.85714H3.86667C3.63681 8.85714 3.41637 8.95497 3.25384 9.12912C3.09131 9.30326 3 9.53944 3 9.78571V17.2143C3 17.4606 3.09131 17.6967 3.25384 17.8709C3.41637 18.045 3.63681 18.1429 3.86667 18.1429H10.8V19.0714C10.8 19.3177 10.8913 19.5539 11.0538 19.728C11.2164 19.9022 11.4368 20 11.6667 20H15.1333C15.3632 20 15.5836 19.9022 15.7462 19.728C15.9087 19.5539 16 19.3177 16 19.0714V15.3571C16 15.1109 15.9087 14.8747 15.7462 14.7005C15.5836 14.5264 15.3632 14.4286 15.1333 14.4286H11.6667C11.4368 14.4286 11.2164 14.5264 11.0538 14.7005C10.8913 14.8747 10.8 15.1109 10.8 15.3571V16.2857H4.73333V10.7143H10.8V11.6429C10.8 11.8891 10.8913 12.1253 11.0538 12.2995C11.2164 12.4736 11.4368 12.5714 11.6667 12.5714H15.1333C15.3632 12.5714 15.5836 12.4736 15.7462 12.2995C15.9087 12.1253 16 11.8891 16 11.6429V7.92857C16 7.6823 15.9087 7.44611 15.7462 7.27197C15.5836 7.09783 15.3632 7 15.1333 7ZM12.5333 16.2857H14.2667V18.1429H12.5333V16.2857ZM14.2667 10.7143H12.5333V8.85714H14.2667V10.7143Z"
        fill="#2B3674"
      />
    </svg>
  );
}

export default AddSubSVG;
