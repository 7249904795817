import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useConfirm } from "material-ui-confirm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import TableFilters from "../../../components/table/TableFilters";
import useCompanyLanguage from "../../../hooks/language";
import useColor from "../../../hooks/useColor";
import usePermission from "../../../hooks/usePermission";
import DeleteSVG from "../../../icons/DeleteSVG";
import ViewSVGAction from "../../../icons/ViewSVGAction";
import { bulkDeleteGroups } from "../../../services/groupManagementService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../../store/reducers/alertMessageReducer";
import { setPage } from "../../../store/reducers/departmentReducer";
import { groupManagementSelector } from "../../../store/reducers/groupManagementReducer";
import { RootState, useAppDispatch } from "../../../store/store";
import { defaultThemeColor } from "../../../utils/colors_values";
import { confirmProps } from "../../../utils/confirmProps";
import CreateModuleModal from "../CreateModuleModal";
import Style from "./AddModule.module.scss";
import TableWithSortableColumns from "./ModulesTable";
export interface SimpleDialogProps {
  setSelectedRows: any;
  selectedRows: any;
  setDefaultRows: any;
  defaultRows: any;
  setModules: any;
  modules: any;
  id?: any;
  view?: any;
}

function AddModule(props: SimpleDialogProps) {
  const {
    setSelectedRows,
    selectedRows,
    setDefaultRows,
    defaultRows,
    setModules,
    modules,
    id,
    view,
  } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const confirm = useConfirm();
  const { type } = useParams();

  const addPermission = usePermission("add_customusergroup");
  const editUpdatePermission = usePermission("change_customusergroup");
  const deletePermission = usePermission("delete_customusergroup");
  const viewPermission = usePermission("view_customusergroup");
  const [itemPerPage, setItemPerPage] = useState<number>(10);
  const [open, setOpen] = useState(false);
  const [searchTable, setSearchTable] = useState("");
  const [listCSV, setListCSV] = useState([]);
  const [list, setList] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<any[]>([]);
  // const [page, setPage] = useState(1);
  const states = useSelector(groupManagementSelector).departmentReducer;
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;
  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  const [rows, setRows] = React.useState<any[]>([]);

  const page = useSelector(
    (state: RootState) => state.groupManagementReducer.page
  );

  const dataList = useSelector(
    (state: RootState) => state.groupManagementReducer.data
  );

  const handleSearchChange = (searchTerm: any) => {
    let data: any = selectedRows?.results ? selectedRows?.results : defaultRows;
    if (!searchTerm) {
      setRows(data);
    } else {
      const filteredRows = data?.filter((row: any) =>
        row?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      );
      setRows(filteredRows);
    }
  };

  useEffect(() => {
    dispatch(setPage(1));
  }, []);

  useEffect(() => {
    // dispatch(
    //   getListData({
    //     page_size: Number(itemPerPage),
    //     page: Number(page),
    //     search: searchTable,
    //   })
    // );
  }, [dispatch, itemPerPage, page, searchTable]);

  useEffect(() => {
    let data: any = selectedRows.results;
    if (Object.keys(selectedRows).length === 0) {
      setRows([]);
    } else setRows(data);
  }, [selectedRows]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      setSelectedCheckBox([]);
      // dispatch(
      //   getListData({
      //     page_size: Number(itemPerPage),
      //     page: Number(page),
      //     search: searchTable,
      //   })
      // );
    }
  }, [alertMessage]);

  useEffect(() => {
    handleSearchChange(searchTable);
  }, [searchTable]);

  useEffect(() => {
    if (defaultRows) {
      let data: any = defaultRows;
      if (Object.keys(defaultRows).length === 0) {
        setRows([]);
      } else setRows(data);
    }
  }, [defaultRows]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteAction = (row: any) => {
    confirm({
      title: t("Are you sure!"),
      description: `Do you want to delete ${row?.name} module?`,
      ...confirmProps,
    })
      .then(() => {
        if (
          selectedRows &&
          selectedRows?.results &&
          selectedRows?.results?.length === 0
        ) {
          setDefaultRows(defaultRows.filter((item: any) => item.id != row?.id));
        } else {
          const updatedDataList = {
            ...selectedRows,
            count: selectedRows.count - 1,
            results: selectedRows.results.filter(
              (item: any) => item.id != row?.id
            ),
          };
          setSelectedRows(updatedDataList);
        }
      })
      .catch(() => {
        // toast.success("Your request canceled successfully!", {
        //   toastId: "Your request canceled successfully!",
        // });
      });
    return;
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setSelectedCheckBox([]);
    dispatch(setPage(page));
  };

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    dispatch(setPage(1));
    setSelectedCheckBox([]);
    setItemPerPage(Number(rowsPerPage));
  };

  const handleSelectCheckbox = (row: any, value: any) => {
    let f_rows: any = [];
    let selected: any = [];
    rows.forEach((r: any) => {
      if (r.id == row) {
        f_rows = [...f_rows, { ...r, is_compulsory: value }];
      } else {
        f_rows = [...f_rows, { ...r }];
      }
    });
    f_rows.forEach((element: any) => {
      selected = [...selected, element?.is_compulsory && element.id];
    });

    setRows(f_rows);

    if (
      selectedRows &&
      selectedRows?.results &&
      selectedRows?.results?.length > 0
    ) {
      let f: any = [];
      selectedRows?.results.forEach((r: any) => {
        f = [...f, { ...r, is_compulsory: selected.includes(r.id) }];
      });
      setSelectedRows({ count: selectedRows?.count, results: f });
    } else if (defaultRows && defaultRows.length > 0) {
      let f: any = [];
      defaultRows?.forEach((r: any) => {
        f = [...f, { ...r, is_compulsory: selected.includes(r.id) }];
      });
      setDefaultRows(f);
    }
  };

  useEffect(() => {
    modifyRows();
  }, [rows]);

  const bulkDelete = (ids: any) => {
    // const sendSelected = selected.map((sel) => sel);
    confirm({
      title: t("Are you sure!"),
      description: t(`Do you want to delete selected groups?`),
      ...confirmProps,
    })
      .then(() => {
        dispatch(bulkDeleteGroups(ids));
        setSelectedCheckBox([]);
      })
      .catch(() => {});
  };
  const handleViewModuleAction = (row: any) => {
    console.log("rowrow", row);
    navigate(`/detail-module/${row?.id}`);
  };

  const modifyRows = () => {
    let f_rows: any = [];
    type !== "view"
      ? rows.forEach(
          (
            r: {
              id: string;
              name: string;
              category: any;
              type: string;
              is_compulsory: boolean;
            },
            i: number
          ) => {
            f_rows = [
              ...f_rows,
              {
                id: r?.id,
                name: r?.name,
                category: r?.category?.name ? r?.category?.name : r?.category,
                type: r?.type,
                compulsory:
                  type !== "view" ? (
                    <Checkbox
                      className={Style.headerCheck}
                      color="primary"
                      indeterminate={false}
                      checked={!!r?.is_compulsory}
                      value={!!r?.is_compulsory}
                      onChange={() =>
                        handleSelectCheckbox(r.id, !r.is_compulsory)
                      }
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  ) : type == "view" ? (
                    r.is_compulsory ? (
                      "Yes"
                    ) : (
                      "No"
                    )
                  ) : null,
                actions: (
                  <div style={{ minWidth: "13em" }}>
                    <button
                      className="btn-link"
                      type="button"
                      onClick={() => {
                        handleDeleteAction(r);
                      }}
                    >
                      <DeleteSVG color="red" />
                    </button>
                  </div>
                ),
              },
            ];
          }
        )
      : rows.forEach(
          (
            r: {
              id: string;
              name: string;
              category: any;
              type: string;
              is_compulsory: boolean;
            },
            i: number
          ) => {
            f_rows = [
              ...f_rows,
              {
                id: r.id,
                name: r?.name,
                category: r?.category?.name ? r?.category?.name : r?.category,
                type: r?.type,
                compulsory: r.is_compulsory ? "Yes" : "No",
                actions: (
                  <div style={{ minWidth: "13em" }}>
                    <button
                      className="btn-link"
                      type="button"
                      onClick={() => {
                        handleViewModuleAction(r);
                      }}
                    >
                      <ViewSVGAction />
                    </button>
                  </div>
                ),
              },
            ];
          }
        );
    setList(f_rows);
  };

  const AddModule = () => {
    const handleAddNew = () => {
      setOpen(true);
    };

    const interfaceForegroundTextColor =
      useColor("interface_foreground_text_color") ||
      defaultThemeColor.interface_foreground_text_color;

    return (
      <div
        className="d-flex flex-row gap-3"
        style={{ marginRight: lang === "ar" ? "2rem" : "0rem" }}
      >
        {/* {addPermission && ( */}
        {type !== "view" && (
          <>
            <Button
              variant="text"
              color="inherit"
              endIcon={
                <AddIcon
                  style={{
                    fontSize: 16,
                    color: interfaceForegroundTextColor,
                    marginRight: lang === "ar" ? "10px" : "0px",
                  }}
                />
              }
              style={{
                fontSize: 13,
                color: interfaceForegroundTextColor,
                textTransform: "capitalize",
              }}
              onClick={() => handleAddNew()}
            >
              {t("Add New")}
            </Button>
          </>
        )}

        {/* )} */}
      </div>
    );
  };

  const handleClicked = () => {
    // getWhole();

    if (list.length == 0) {
      toast.error(String(t("Nothing to export...")), {
        toastId: String(t("Nothing to export...")),
      });
    }
    setListCSV(list);
  };

  return (
    <>
      <div
        className={`${Style.header} mb-3`}
        style={{
          color:
            useColor("interface_foreground_text_color") ||
            defaultThemeColor.interface_foreground_text_color,
          marginRight: lang === "ar" ? "2rem" : "0rem",
        }}
      >
        {t("Modules")}
      </div>
      <TableFilters
        dataList={selectedRows}
        itemPerPage={itemPerPage}
        setItemPerPage={setItemPerPage}
        selectedCheckBox={selectedCheckBox}
        bulkDelete={bulkDelete}
        setSearchTable={setSearchTable}
        createButton={<AddModule />}
        handle="addModules"
        exportCSV={false}
        setParentData={() => setListCSV([])}
        handleClicked={handleClicked}
        rows={listCSV?.map((r: any, i: any) => ({
          // id: r.id,
          name: r?.name,
          category: r?.category?.name ? r?.category?.name : r?.category,
          type: r?.type,
        }))}
        headers={[t("Name"), t("Category"), t("Type")]}
        filename={
          id ? `module_list_of_training_id_${id}.csv` : `module_list.csv`
        }
      />

      {/* <TableWithSortableColumns /> */}
      <div style={{ marginRight: "0.7em" }}>
        <TableWithSortableColumns
          columnData={
            type !== "view"
              ? [
                  {
                    id: "name",
                    name: t("Name"),
                    enableSort: true,
                    align: lang === "ar" ? "center" : "left",
                    disablePadding: "none",
                  },
                  {
                    id: "category",
                    name: t("Category"),
                    enableSort: true,
                    align: lang === "ar" ? "center" : "left",
                    disablePadding: "none",
                  },
                  {
                    id: "type",
                    name: t("Type"),
                    enableSort: true,
                    align: lang === "ar" ? "center" : "left",
                    disablePadding: "none",
                  },
                  {
                    id: "compulsory",
                    name: t("Compulsory"),
                    enableSort: false,
                    align: "center",
                    disablePadding: "none",
                  },
                  {
                    id: "actions",
                    name: t("Actions"),
                    enableSort: false,
                    align: "center",
                    disablePadding: "none",
                  },
                ]
              : [
                  {
                    id: "name",
                    name: t("Name"),
                    enableSort: true,
                    align: lang === "ar" ? "center" : "left",
                    disablePadding: "none",
                  },
                  {
                    id: "category",
                    name: t("Category"),
                    enableSort: true,
                    align: lang === "ar" ? "center" : "left",
                    disablePadding: "none",
                  },
                  {
                    id: "type",
                    name: t("Type"),
                    enableSort: true,
                    align: lang === "ar" ? "center" : "left",
                    disablePadding: "none",
                  },
                  {
                    id: "compulsory",
                    name: t("Compulsory"),
                    enableSort: false,
                    align: "center",
                    disablePadding: "none",
                  },
                  {
                    id: "actions",
                    name: t("Actions"),
                    enableSort: false,
                    align: "center",
                    disablePadding: "none",
                  },
                ]
          }
          showLoader={false}
          setRows={(m: any) => {
            let n: any = [];
            m.forEach((element: any) => {
              n = [
                ...n,
                {
                  ...element,
                  is_compulsory: element?.compulsory?.props?.checked,
                },
              ];
            });
            setRows(n);
            let moduleOrder = n?.map((module: any, index: any) => {
              return {
                module: module?.id,
                order: index + 1,
                is_compulsory: module?.compulsory,
              };
            });
            setModules(moduleOrder);
          }}
          rows={list}
          isCheckboxes={false}
          isDraggable={view ? false : true}
          selectedRowsPerPage={itemPerPage.toString()}
          totalRecordCount={Number(selectedRows.count)}
          handleChangePageParent={handleChangePage}
          pagenum={Number(page)}
          handleChangeRowsPerPageParent={handleChangeRowsPerPage}
          bulkDelete={bulkDelete}
          selectedCheckBox={selectedCheckBox}
          setSelectedCheckBox={setSelectedCheckBox}
        />
        <CreateModuleModal
          setParentOpen={setOpen}
          open={open}
          onClose={handleClose}
          isLoading={loading}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          defaultRows={defaultRows}
        />
      </div>
    </>
  );
}

export default AddModule;
