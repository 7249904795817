import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { t, useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import useCompanyLanguage from "../../hooks/language";
import Login from "../../images/no-login.svg";
import Training from "../../images/no-training.svg";
import { getDashboardInsights } from "../../services/dashboradService";
import { alertMessageSelector, setErr, setSuccess } from "../../store/reducers/alertMessageReducer";
import { RootState, useAppDispatch } from "../../store/store";
import Style from "./AdminDashboard.module.scss";
import PieChart from "./PieChart";
import VerticalChart from "./VerticalChart";
import HorizontalChart from "./horizontalChart";
const $ = require("jquery");

const chartOptions = {
  indexAxis: "y" as const,
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
      display: false,
    },
    title: {
      display: false,
      position: "top" as const,
      text: "Users on Platform",
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      display: true,
      grid: {
        display: false,
      },
      ticks: {
        stepSize: 1,
      },
    },
    y: {
      beginAtZero: true,
      display: true,
      grid: {
        display: false,
      },
    },
  },
  legend: {
    display: false,
  },
};

const verticalChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top" as const,
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          var label = context.dataset.label || "";
          if (context.parsed.y !== null) {
            label += "" + context.parsed.y + "%";
          }
          return label;
        },
      },
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      display: true,
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      display: true,
      ticks: {
        stepSize: 1,
        min: 0,
        callback: function (value: any) {
          return value + "%";
        },
      },
      scaleLabel: {
        display: true,
        labelString: "Percentage",
      },
      grid: {
        display: false,
      },
    },
  },
};

const verticalChartOptionsEnrollment = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top" as const,
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      display: true,
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      display: true,
      ticks: {
        stepSize: 1,
      },
      grid: {
        display: false,
      },
    },
  },
};

const AdminDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const lang = useCompanyLanguage();
  const [usersOnPlatform, setUsersOnPlatform] = useState<any>();
  const [traineesExperience, setTraineesExperience] = useState<any>();
  const [generalTrainingStatus, setGeneralTrainingStatus] = useState<any>();
  const [topTrainings, setTopTrainings] = useState<any>();
  const [depTrainings, setDepTrainings] = useState<any>();
  const [dataDashboard2, setdataDashboard2] = useState<any>();
  const [selectedRole, setSelectedRole] = useState("Roles");
  const [selectedType, setSelectedType] = useState("Enrollment");
  const t = useTranslation();
  const defaultDate = { startDate: undefined, endDate: undefined };
  const [openCreatedDate, setOpenCreatedDate] = React.useState(false);
  const [fetch, setFetch] = React.useState(0);
  const [dateRangeCreated, setDateRangeCreated] = React.useState<any>();
  const dataDashboard = useSelector((state: RootState) => state.dashboardReducer.data);

  useEffect(() => {
    if (openCreatedDate && dateRangeCreated) $("#layout").css("overflow", "hidden");
    else $("#layout").css("overflow", "scroll");
  }, [dateRangeCreated, openCreatedDate]);

  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      dispatch(
        getDashboardInsights({
          start_date: dateRangeCreated
            ? moment(new Date(dateRangeCreated?.startDate)).format("YYYY-MM-DD")
            : "",
          end_date: dateRangeCreated
            ? moment(new Date(dateRangeCreated?.endDate)).format("YYYY-MM-DD")
            : "",
        })
      );
    }
  }, [alertMessage]);

  useEffect(() => {
    dispatch(
      getDashboardInsights({
        start_date: dateRangeCreated
          ? moment(new Date(dateRangeCreated?.startDate)).format("YYYY-MM-DD")
          : "",
        end_date: dateRangeCreated
          ? moment(new Date(dateRangeCreated?.endDate)).format("YYYY-MM-DD")
          : "",
      })
    );
  }, [dispatch, fetch]);

  useEffect(() => {
    if (dataDashboard) {
      makeDataSet();
      setdataDashboard2(dataDashboard);
    }
  }, [dataDashboard, selectedRole, selectedType]);

  const toggleCreated = () => {
    setDateRangeCreated(defaultDate);
    setOpenCreatedDate(true);
  };

  const makeDataSet = () => {
    const chartData = {
      labels:
        dataDashboard &&
        (selectedRole == "Roles"
          ? (dataDashboard?.users_on_platform_on_role &&
              Object.keys(dataDashboard?.users_on_platform_on_role)) ||
            []
          : selectedRole == "Department"
          ? (dataDashboard?.users_on_platform_on_department &&
              Object.keys(dataDashboard?.users_on_platform_on_department)) ||
            []
          : ["Beginners", "Intermediate", "Advanced"]),
      datasets: [
        {
          data:
            (dataDashboard &&
              (selectedRole == "Roles"
                ? (dataDashboard?.users_on_platform_on_role &&
                    Object.values(dataDashboard?.users_on_platform_on_role)) ||
                  []
                : selectedRole == "Department"
                ? (dataDashboard?.users_on_platform_on_department &&
                    Object.values(dataDashboard?.users_on_platform_on_department)) ||
                  []
                : [
                    (dataDashboard?.trainees_experience_level &&
                      dataDashboard?.trainees_experience_level?.beginner_count) ||
                      0,
                    (dataDashboard?.trainees_experience_level &&
                      dataDashboard?.trainees_experience_level?.intermediate_count) ||
                      0,
                    (dataDashboard?.trainees_experience_level &&
                      dataDashboard?.trainees_experience_level?.advanced_count) ||
                      0,
                  ])) ||
            [],

          backgroundColor: "#0089CF",
          borderColor: "#0089CF",
        },
      ],
    };

    const pieChartData = {
      datasets: [
        {
          data: [
            (dataDashboard?.trainees_experience_level &&
              dataDashboard?.trainees_experience_level?.beginner_count) ||
              0,
            (dataDashboard?.trainees_experience_level &&
              dataDashboard?.trainees_experience_level?.intermediate_count) ||
              0,
            (dataDashboard?.trainees_experience_level &&
              dataDashboard?.trainees_experience_level?.advanced_count) ||
              0,
          ],
          backgroundColor: ["#0089CF", "#6AD2FF", "#FBBC05"],
          borderColor: ["#0089CF", "#6AD2FF", "#FBBC05"],
          borderWidth: 1,
        },
      ],
    };

    const pieChartDataGeneral = {
      datasets: [
        {
          data: [
            (dataDashboard?.general_training_status &&
              dataDashboard?.general_training_status?.completed) ||
              0,
            (dataDashboard?.general_training_status &&
              dataDashboard?.general_training_status?.in_progress) ||
              0,
            (dataDashboard?.general_training_status &&
              dataDashboard?.general_training_status?.backlog) ||
              0,
          ],
          backgroundColor: ["#0089CF", "#6AD2FF", "#FBBC05"],
          borderColor: ["#0089CF", "#6AD2FF", "#FBBC05"],
          borderWidth: 1,
        },
      ],
    };

    const verticalChartData = {
      labels: [
        ...(selectedType == "Enrollment"
          ? (dataDashboard?.top_trainings_per_enrollment &&
              dataDashboard?.top_trainings_per_enrollment.map((i: any) => i?.name)) ||
            []
          : (dataDashboard?.top_trainings_per_completion_rate &&
              dataDashboard?.top_trainings_per_completion_rate.map((i: any) => i?.name)) ||
            []),
      ],
      datasets: [
        {
          data:
            selectedType == "Enrollment"
              ? (dataDashboard?.top_trainings_per_enrollment &&
                  dataDashboard?.top_trainings_per_enrollment.map((i: any) => i?.user_count)) ||
                []
              : (dataDashboard?.top_trainings_per_completion_rate &&
                  dataDashboard?.top_trainings_per_completion_rate.map(
                    (i: any) => i?.completion_percentage
                  )) ||
                [],
          backgroundColor: "#6AD2FF",
        },
      ],
    };

    const verticalChartData2 = {
      labels: [
        ...((dataDashboard?.training_enrolments_per_department &&
          dataDashboard?.training_enrolments_per_department.map((i: any) => i?.name)) ||
          []),
      ],
      datasets: [
        {
          data:
            (dataDashboard?.training_enrolments_per_department &&
              dataDashboard?.training_enrolments_per_department.map(
                (i: any) => i?.training_count
              )) ||
            [],
          backgroundColor: "#6AD2FF",
        },
      ],
    };

    setUsersOnPlatform(chartData);
    setTraineesExperience(pieChartData);
    setGeneralTrainingStatus(pieChartDataGeneral);
    setTopTrainings(verticalChartData);
    setDepTrainings(verticalChartData2);
  };

  const filterDataByRole = (data: any, role: string) => {
    return data;
  };
  const filterDataByType = (data: any, role: string) => {
    return data;
  };
  const handleRoleFilterChange = (selectedRole: string) => {
    setSelectedRole(selectedRole);
  };

  const handleTypeFilterChange = (selectedType: string) => {
    setSelectedType(selectedType);
  };

  const filteredChartData = filterDataByRole(usersOnPlatform, selectedRole);

  const filteredVerticalChartData = filterDataByType(topTrainings, selectedType);

  const filteredVerticalChartData2 = filterDataByType(depTrainings, selectedType);

  return (
    <div className={Style.manageSpace}>
      <div
        className={Style.mainTopContainer}
        style={{ marginBottom: "3rem" }}
        id={"ParentContainer"}
      >
        <div className={Style.horizontalChart}>
          <div style={{}}>
            <span
              style={{
                color: "#1B2559",
                fontSize: "20px",
                fontWeight: "700",
                marginRight: "1rem",
                marginLeft: lang === "ar" ? "1rem" : "0rem",
              }}
            >
              {t("Users on Platform")}
            </span>
            <select
              style={{
                background: "#EFF4FA",
                width: "130px",
                height: "35px",
                borderRadius: "8px",
                outline: "none",
                border: "none",
                color: "#A3AED0",
                fontSize: "14px",
                paddingLeft: "5px",
              }}
              value={selectedRole}
              onChange={(e) => handleRoleFilterChange(e.target.value)}
            >
              <option style={{ color: "#A3AED0" }} value="Roles">
                {t("Roles")}
              </option>
              <option style={{ color: "#A3AED0" }} value="Department">
                {t("Department")}
              </option>
              <option style={{ color: "#A3AED0" }} value="Experience level">
                {t("Experience level")}
              </option>
            </select>
          </div>
          <div
            style={{
              height: "250px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {usersOnPlatform && (
              <HorizontalChart chartData={filteredChartData} chartOptions={chartOptions as any} />
            )}
          </div>
        </div>
        <div className={Style.TilesWrapper}>
          <div className={Style.noOfTraining}>
            <div>
              <img src={Training} alt={t("No of Trainings")} />
            </div>
            <div
              style={{
                paddingLeft: "10px",
                paddingRight: lang === "ar" ? "10px" : "0px",
              }}
            >
              <div className={Style.heading}>{t("No of Trainings")}</div>
              <div className={Style.num}>{dataDashboard2?.number_of_trainings || "0"}</div>
            </div>
          </div>
          <div className={Style.noOfLogin} style={{ justifyContent: "space-between" }}>
            <div className="d-flex align-items-center" style={{ flex: "0 0 50%" }}>
              <div>
                <img src={Login} alt={t("No of Login")} />
              </div>
              <div
                style={{
                  paddingLeft: "10px",
                  paddingRight: lang === "ar" ? "10px" : "0px",
                }}
              >
                <div className={Style.heading}>{t("No of Login")}</div>
                <div className={Style.num}>{dataDashboard?.no_of_login || "0"}</div>
              </div>
            </div>
            <div>
              {dateRangeCreated ? (
                <div className="color-picker-container">
                  <DateRangePicker
                    appearance="default"
                    placement="bottomStart"
                    open={openCreatedDate}
                    value={[dateRangeCreated.startDate, dateRangeCreated.endDate]}
                    onChange={(range: any) => {
                      if (range)
                        setDateRangeCreated({
                          startDate: range[0],
                          endDate: range[1],
                        });
                      else setDateRangeCreated(null);
                    }}
                    className={Style.menuStyle}
                    style={{ width: "70%" }}
                    character="~"
                    menuStyle={{
                      color: "black",
                    }}
                    preventOverflow
                    format="yyyy-MM-dd"
                    defaultCalendarValue={[new Date(), new Date()]}
                    onOk={() => {
                      setFetch(fetch + 1);
                      setOpenCreatedDate(false);
                    }}
                    onClick={() => {
                      setOpenCreatedDate(true);
                    }}
                    onClean={() => {
                      setDateRangeCreated(null);
                      setFetch(fetch + 1);
                    }}
                    shouldDisableDate={(date) => date > new Date()}
                  />
                </div>
              ) : (
                <IconButton
                  aria-label="more"
                  id={`long-button`}
                  aria-controls={`long-menu`}
                  onClick={toggleCreated}
                >
                  <MoreVertIcon />
                </IconButton>
              )}
            </div>
          </div>
        </div>
        <div className={Style.pieChartContainer}>
          <div
            style={{
              color: "#1B2559",
              fontSize: "20px",
              fontWeight: "700",
              marginRight: "1rem",
            }}
          >
            {t("Trainees Experience Level")}
          </div>
          <div
            style={{
              height: "170px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {traineesExperience && <PieChart pieChartData={traineesExperience} />}
          </div>
          <div
            style={{
              boxShadow: "0px 18px 40px 0px #7090B01F",
              background: "#fff",
              padding: "1rem",
              display: "flex",
              borderRadius: "16px",
              justifyContent: "space-evenly",
              marginTop: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                borderRight: "1px solid #F4F7FE",
                flex: "0 0 30%",
              }}
            >
              <div
                style={{
                  background: "#0089CF",
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                }}
              ></div>
              <div style={{ paddingLeft: "10px" }}>
                <div
                  style={{
                    color: "#A3AED0",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  {t("Beginners")}
                </div>
                <div
                  style={{
                    color: "#2B3674",
                    fontSize: "18px",
                    fontWeight: "700",
                  }}
                >
                  {" "}
                  {dataDashboard2?.trainees_experience_level?.beginner_percentage.toFixed(1) ||
                    0}%{" "}
                  <span
                    style={{
                      color: "#A3AED0",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    ({dataDashboard2?.trainees_experience_level?.beginner_count || 0})
                  </span>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                borderRight: "1px solid #F4F7FE",
                flex: "0 0 30%",
              }}
            >
              <div
                style={{
                  background: "#6AD2FF",
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                }}
              ></div>
              <div style={{ paddingLeft: "10px" }}>
                <div
                  style={{
                    color: "#A3AED0",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  {t("Intermediate")}
                </div>
                <div
                  style={{
                    color: "#2B3674",
                    fontSize: "18px",
                    fontWeight: "700",
                  }}
                >
                  {" "}
                  {dataDashboard2?.trainees_experience_level?.intermediate_percentage.toFixed(1) ||
                    0}
                  %{" "}
                  <span
                    style={{
                      color: "#A3AED0",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    ({dataDashboard2?.trainees_experience_level?.intermediate_count || 0})
                  </span>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                borderRight: "1px solid #F4F7FE",
                flex: "0 0 30%",
              }}
            >
              <div
                style={{
                  background: "#FBBC05",
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                }}
              ></div>
              <div style={{ paddingLeft: "10px" }}>
                <div
                  style={{
                    color: "#A3AED0",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  {t("Advanced")}
                </div>
                <div
                  style={{
                    color: "#2B3674",
                    fontSize: "18px",
                    fontWeight: "700",
                  }}
                >
                  {" "}
                  {dataDashboard2?.trainees_experience_level?.advanced_percentage.toFixed(1) ||
                    0}%{" "}
                  <span
                    style={{
                      color: "#A3AED0",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    ({dataDashboard2?.trainees_experience_level?.advanced_count || 0})
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={Style.topTrainingMainContainer}>
        <div className={Style.verticalChartContainer}>
          <div style={{ paddingBottom: "1rem" }}>
            <span
              style={{
                color: "#1B2559",
                fontSize: "20px",
                fontWeight: "700",
                marginRight: "1rem",
                marginLeft: lang === "ar" ? "1rem" : "0rem",
              }}
            >
              {t("Top Trainings")}
            </span>
            <select
              style={{
                background: "#EFF4FA",
                width: "100px",
                height: "35px",
                borderRadius: "8px",
                outline: "none",
                border: "none",
                color: "#A3AED0",
                fontSize: "14px",
                paddingLeft: "5px",
              }}
              value={selectedType}
              onChange={(e) => handleTypeFilterChange(e.target.value)}
            >
              <option style={{ color: "#A3AED0" }} value="Enrollment">
                {t("Enrollment")}
              </option>
              <option style={{ color: "#A3AED0" }} value="Completion rate">
                {t("Completion rate")}
              </option>
            </select>
          </div>
          <div style={{ width: "100%", height: "100%" }}>
            {topTrainings && (
              <VerticalChart
                verticalChartData={filteredVerticalChartData as any}
                verticalChartOptions={
                  selectedType === "Enrollment"
                    ? (verticalChartOptionsEnrollment as any)
                    : (verticalChartOptions as any)
                }
              />
            )}
          </div>
        </div>
      </div>
      <div className={Style.enrolPerDeptContainer}>
        <div className={Style.verticalDeptChart}>
          <div style={{ paddingBottom: "1rem" }}>
            <span
              style={{
                color: "#1B2559",
                fontSize: "20px",
                fontWeight: "700",
                marginRight: "1rem",
              }}
            >
              {t("Training Enrolments Per Department")}
            </span>
          </div>
          <div style={{ width: "100%", height: "100%" }}>
            {depTrainings && (
              <VerticalChart
                verticalChartData={filteredVerticalChartData2 as any}
                verticalChartOptions={verticalChartOptions as any}
              />
            )}
          </div>
        </div>
        <div className={Style.pieStatusChart}>
          <div
            style={{
              color: "#1B2559",
              fontSize: "20px",
              fontWeight: "700",
              marginRight: "1rem",
            }}
          >
            {t("General Training Status")}
          </div>
          <div
            style={{
              height: "170px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {generalTrainingStatus && <PieChart pieChartData={generalTrainingStatus} />}
          </div>
          <div
            style={{
              boxShadow: "0px 18px 40px 0px #7090B01F",
              background: "#fff",
              padding: "1rem",
              display: "flex",
              borderRadius: "16px",
              justifyContent: "space-evenly",
              marginTop: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                borderRight: "1px solid #F4F7FE",
                flex: "0 0 30%",
              }}
            >
              <div
                style={{
                  background: "#0089CF",
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                }}
              ></div>
              <div style={{ paddingLeft: "10px" }}>
                <div
                  style={{
                    color: "#A3AED0",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  {t("Completed")}
                </div>
                <div
                  style={{
                    color: "#2B3674",
                    fontSize: "18px",
                    fontWeight: "700",
                  }}
                >
                  {" "}
                  {dataDashboard2?.general_training_status?.completed_percentage.toFixed(1) ||
                    0}%{" "}
                  <span
                    style={{
                      color: "#A3AED0",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    ({dataDashboard2?.general_training_status?.completed || 0})
                  </span>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                borderRight: "1px solid #F4F7FE",
                flex: "0 0 30%",
              }}
            >
              <div
                style={{
                  background: "#6AD2FF",
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                }}
              ></div>
              <div style={{ paddingLeft: "10px" }}>
                <div
                  style={{
                    color: "#A3AED0",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  {t("In progress")}
                </div>
                <div
                  style={{
                    color: "#2B3674",
                    fontSize: "18px",
                    fontWeight: "700",
                  }}
                >
                  {" "}
                  {dataDashboard2?.general_training_status?.in_progress_percentage.toFixed(1) ||
                    0}%{" "}
                  <span
                    style={{
                      color: "#A3AED0",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    ({dataDashboard2?.general_training_status?.in_progress || 0})
                  </span>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                borderRight: "1px solid #F4F7FE",
                flex: "0 0 30%",
              }}
            >
              <div
                style={{
                  background: "#FBBC05",
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                }}
              ></div>
              <div style={{ paddingLeft: "10px" }}>
                <div
                  style={{
                    color: "#A3AED0",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  {t("Not Started")}
                </div>
                <div
                  style={{
                    color: "#2B3674",
                    fontSize: "18px",
                    fontWeight: "700",
                  }}
                >
                  {" "}
                  {dataDashboard2?.general_training_status?.backlog_percentage.toFixed(1) ||
                    0}%{" "}
                  <span
                    style={{
                      color: "#A3AED0",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    ({dataDashboard2?.general_training_status?.backlog || 0})
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
