import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import CancelButton from "../../../components/CancelButton";
import SubmitButton from "../../../components/SubmitButton";
import useColor from "../../../hooks/useColor";
import { updateCompanyForm } from "../../../services/profileManagementService";
import {
  alertMessageSelector,
  setErrors,
} from "../../../store/reducers/alertMessageReducer";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { defaultThemeColor } from "../../../utils/colors_values";
import Style from "./EditProfile.module.scss";
import { useTranslation } from "react-multi-lang";
import useCompanyLanguage from "../../../hooks/language";

interface Params {
  setEdit: any;
  detail: any;
  logo: any;
  setLogo: any;
}

const schema = yup
  .object({
    company_name: yup
      .string()
      .max(150, "Maximum 150 characters are allowed!")
      .matches(
        /[a-zA-Z][a-zA-Z ]+[a-zA-Z]$/,
        "Only allow alphabets with whitespace"
      )
      .required("Company name is required"),
    company_email: yup
      .string()
      .max(150, "Maximum 150 characters are allowed!")
      .required("Company email is required"),
  })
  .required();

const EditProfile = ({ setEdit, detail, logo, setLogo }: Params) => {
  const {
    setError,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const alertMessage = useAppSelector(alertMessageSelector).alertMessageReducer;

  useEffect(() => {
    let defaultValues = {
      company_name: detail?.company_name,
      company_email: detail?.company_email,
    };
    setLogo("");
    reset({ ...defaultValues });
  }, [detail]);

  const onSubmit = (payload: any) => {
    payload.id = detail?.id;
    payload.image = logo;
    dispatch(updateCompanyForm(payload));
  };

  useEffect(() => {
    if (alertMessage?.errors) {
      if (alertMessage?.errors?.company_name?.length > 0) {
        toast.error(alertMessage?.errors?.company_name?.toString(), {
          toastId: alertMessage?.errors?.company_name?.toString(),
        });
        setError("company_name", {
          type: "server",
          message: alertMessage?.errors?.company_name?.toString(),
        });
      }
      if (alertMessage?.errors?.company_email?.length > 0) {
        toast.error(alertMessage?.errors?.company_email?.toString(), {
          toastId: alertMessage?.errors?.company_email?.toString(),
        });
        setError("company_email", {
          type: "server",
          message: alertMessage?.errors?.company_email?.toString(),
        });
      }
      if (alertMessage?.errors) {
        dispatch(setErrors(null));
      }
    }
  }, [alertMessage]);

  return (
    <>
      <form
        id="myform"
        className="p-3"
        style={{
          background:
            useColor("interface_foreground_color") ||
            defaultThemeColor.interface_foreground_color,
          color:
            useColor("interface_foreground_text_color") ||
            defaultThemeColor.interface_foreground_text_color,
          borderRadius: "10px",
          direction: lang === "ar" ? "rtl" : "ltr",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div
          className={`pt-3 col-lg-12 d-flex align-items-center`}
          style={{
            borderBottom: "0px solid rgba(0,0,0,0)",
            paddingBottom: "0px",
          }}
        >
          <div
            className={`${Style.label} col-lg-6 px-4`}
            style={{
              color:
                useColor("interface_foreground_text_color") ||
                defaultThemeColor.interface_foreground_text_color,
            }}
          >
            {t("Company Name")} <span className="color-red">*</span>
          </div>

          <div className={`col-lg-6`}>
            <input
              id="company_name"
              className={`${Style.inputfields} ${Style.fieldFont} ${
                errors.company_name ? "is-invalid" : ""
              }`}
              placeholder={t("Enter Company Name")}
              type="text"
              {...register("company_name")}
            />
            <p className="color-red">{errors.company_name?.message}</p>
          </div>
        </div>
        <div
          className={`pt-3 col-lg-12 d-flex align-items-center`}
          style={{
            borderBottom: "0px solid rgba(0,0,0,0)",
            paddingBottom: "0px",
          }}
        >
          <div
            className={`${Style.label} col-lg-6 px-4`}
            style={{
              color:
                useColor("interface_foreground_text_color") ||
                defaultThemeColor.interface_foreground_text_color,
            }}
          >
            {t("Company Email")}
          </div>

          <div className={`col-lg-6`}>
            <input
              id="company_email"
              className={`${Style.inputfields} ${Style.fieldFont} ${
                errors.company_email ? "is-invalid" : ""
              }`}
              placeholder={t("Enter Company Email")}
              disabled={true}
              style={{ color: "rgb(125, 125, 125)" }}
              type="text"
              {...register("company_email")}
            />
            <p className="color-red">{errors.company_email?.message}</p>
          </div>
        </div>

        <div className="d-flex justify-content-end w-100 gap-4  p-4">
          <SubmitButton label={t("Update")} marginTop={false} />
          <CancelButton
            size="small"
            outlined={true}
            onClick={() => setEdit(false)}
            textColor=""
            outlineColor=""
            text={t("Cancel")}
            className={``}
            bg={""}
          ></CancelButton>
        </div>
      </form>
    </>
  );
};
export default EditProfile;
