/* eslint-disable react-hooks/exhaustive-deps */
import { PauseSharp, VolumeOff } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Popover from "@mui/material/Popover";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useRef, useState } from "react";
import usePictureInPicture from "react-use-pip";
import { VideoSeekSlider } from "react-video-seek-slider";
import { Slider as RSlider } from "rsuite";
import screenfull from "screenfull";
import useCompanyLanguage from "../../hooks/language";
import FastForwardSVG from "../../icons/FastForwardSVG";
import ForwardSVG from "../../icons/ForwardSVG";
import PipVideoSVG from "../../icons/PipVideoSVG";
import PlayBottomSVG from "../../icons/PlayBottomSVG";
import RewindSVG from "../../icons/RewindSVG";
import RingBellSVG from "../../icons/RingBellSVG";
import VideoFullScreenSVG from "../../icons/VideoFullScreen";
import ControlIcons from "./ControlIcons";
import Style2 from "./ControlIcons.module.scss";
import "./Seek.css";
import Style from "./VideoPlayer.module.scss";

const format = (seconds: any) => {
  if (isNaN(seconds)) {
    return "00:00";
  }

  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");

  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  } else {
    return `${mm}:${ss}`;
  }
};

function VideoPlayer({
  mainTitle,
  mainSubTitle,
  source,
  moduleName,
  topicName,
  timeCodes,
  nextVideoClick,
  onFinished,
  saveVideoStamp,
  video_resume_time,
  saveVideoStampForceToBE,
  setPlayed,
  isOnlyView = false,
}: {
  mainTitle?: any;
  mainSubTitle?: any;
  source?: any;
  moduleName?: any;
  topicName?: any;
  timeCodes?: any;
  nextVideoClick?: any;
  onFinished?: any;
  saveVideoStamp?: any;
  saveVideoStampForceToBE?: any;
  video_resume_time?: any;
  setPlayed?: any;
  isOnlyView?: boolean;
}) {
  const [playerstate, setPlayerState] = useState({
    playing: false,
    muted: false,
    volume: 0.5,
    playerbackRate: 1.0,
    played: 0,
    seeking: false,
  });
  const vidRef = useRef<any>();
  const bottomControlsRef = useRef<any>();

  let count = 0;
  //Destructure State in other to get the values in it
  const { playing, muted, volume, playerbackRate, played, seeking } =
    playerstate;
  const playerRef: any = useRef();
  const playerDivRef: any = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showVolume, setShowVolume] = React.useState(false);
  const [change, setChange] = React.useState(false);
  const [pipMode, setPipMode] = useState(false);
  const {
    isPictureInPictureActive,
    isPictureInPictureAvailable,
    togglePictureInPicture,
  } = usePictureInPicture(vidRef);

  const open = Boolean(anchorEl);
  const id = open ? "playbackrate-popover" : undefined;

  var video: any = document.getElementById("vid");

  const previewImage = useRef("");
  const interval = useRef<any>();
  const [currentTime, setCurrentTime] = useState(0);
  const [progress, setProgress] = useState(0);
  const [maxTime, setMaxTime] = useState(0);
  const [controlsShow, setControlsShow] = useState(true);
  const [hover, setHover] = useState(0);
  const [idclear, setIdClear] = useState<any>([]);

  document.onkeydown = checkKey;

  const saveVideo = () => {
    saveVideoStamp(currentTime);
  };

  React.useEffect(() => {
    if (video_resume_time) {
      var video: any = document.getElementById("vid");
      video.currentTime = Number(video_resume_time) / 1000;
      setCurrentTime(Number(video_resume_time));
    }
  }, []);

  function checkKey(e: any) {
    e = e || window.event;
    setHover(hover + 1);
    if (e.keyCode == "38") {
      // up arrow
      if (video.volume >= 0.9) {
        video.volume = parseFloat(1 as any);
      } else video.volume = (parseFloat(video.volume as any) + 0.1).toFixed(0);
      setPlayerState({
        ...playerstate,
        volume: parseFloat(video.volume as any),
        muted: video.volume === 0 ? true : false,
      });
    } else if (e.keyCode == "40") {
      // down arrow
      if (video.volume <= 0.1) {
        video.volume = parseFloat(0 as any);
      } else video.volume = (parseFloat(video.volume as any) - 0.1).toFixed(0);
      setPlayerState({
        ...playerstate,
        volume: parseFloat(video.volume as any),
        muted: video.volume === 0 ? true : false,
      });
    } else if (e.keyCode == "37") {
      video.currentTime -= 15;
      // left arrow
    } else if (e.keyCode == "39") {
      video.currentTime += 15;
      // right arrow
    } else if (e.keyCode == "32") {
      handlePlayVideo();
    }
  }

  React.useEffect(() => {
    window.setTimeout(function () {}, 0);
    idclear.forEach((idm: any) => {
      window.clearTimeout(idm);
    });
    var id = null;
    if (hover) {
      setControlsShow(true);
      id = setTimeout(() => {
        if (vidRef?.current?.paused) {
        } else setControlsShow(false);
      }, 3500);
      setIdClear([...idclear, id]);
    }
  }, [hover]);

  React.useEffect(() => {
    saveVideo();
    setPlayerState({ ...playerstate, playing: !vidRef?.current?.paused });
  }, [currentTime]);

  const handleMouseMove = () => {
    setHover(hover + 1);
    setControlsShow(true);
    count = 0;
  };

  const handleTimeChange = React.useCallback((time: any, offsetTime: any) => {
    if (!vidRef.current?.currentTime) {
      return;
    }
    saveVideoStampForceToBE(time);
    vidRef.current.currentTime = time / 1000;
    setCurrentTime(time);
  }, []);

  const handlePlay = () => {
    setPlayed(true);
    interval.current = setInterval(() => {
      setCurrentTime((vidRef.current?.currentTime || 0) * 1000);
    }, 1000);
  };

  const handlePause = () => {
    clearInterval(interval.current);
  };

  const handleDataLoaded = () => {
    setMaxTime((vidRef.current?.duration || 0) * 1000);
  };

  const handleProgress = () => {
    const buffer = vidRef?.current?.buffered;

    if (((buffer?.length > 0 && vidRef.current?.duration) || 0) > 0) {
      let currentBuffer = 0;
      const inSeconds = vidRef.current?.currentTime || 0;

      for (let i = 0; i < buffer.length; i++) {
        if (buffer.start(i) <= inSeconds && inSeconds <= buffer.end(i)) {
          currentBuffer = i;
          break;
        }
      }

      const isHover = (e: any) => e.parentElement.querySelector(":hover") === e;
      const myDiv = document.getElementById("mydiv");
      const myDiv2 = document.getElementById("mydiv2");

      if (
        count > 10 &&
        !vidRef?.current?.paused &&
        !isHover(myDiv) &&
        !isHover(myDiv2)
      ) {
        setControlsShow(false);
        count = 0;
      }

      if (controlsShow) {
        count += 1;
      }

      setProgress(buffer.end(currentBuffer) * 1000 || 0);
    }
  };

  React.useEffect(() => {
    if (!vidRef) {
      return;
    }

    vidRef.current?.addEventListener("play", handlePlay);
    vidRef.current?.addEventListener("pause", handlePause);
    vidRef.current?.addEventListener("loadeddata", handleDataLoaded);
    vidRef.current?.addEventListener("progress", handleProgress);
  }, [vidRef]);

  const handlePlayVideo = () => {
    saveVideoStampForceToBE(currentTime);
    if (vidRef?.current?.paused) {
      vidRef && vidRef?.current && vidRef?.current?.play();
      setPlayerState({ ...playerstate, playing: !playerstate.playing });
      handleMouseMove();
    } else {
      setControlsShow(true);
      vidRef && vidRef?.current && vidRef?.current?.pause();
      setPlayerState({ ...playerstate, playing: !playerstate.playing });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMuting = () => {
    setPlayerState({
      ...playerstate,
      muted: !playerstate.muted,
      volume: playerstate.muted ? parseFloat(1 as any) : parseFloat(0 as any),
    });
  };

  const handleRewind = () => {
    var video: any = document.getElementById("vid");
    video.currentTime -= 15;
    setCurrentTime(currentTime - 15);
    saveVideoStampForceToBE(currentTime);
  };

  const handleFastForward = () => {
    var video: any = document.getElementById("vid");
    video.currentTime += 15;
    setCurrentTime(currentTime + 15);
    saveVideoStampForceToBE(currentTime);
  };

  const handleVolumeChange = (newValue: any) => {
    var video: any = document.getElementById("vid");
    video.volume = parseFloat((newValue / 100) as any);
    setPlayerState({
      ...playerstate,
      volume: parseFloat((newValue / 100) as any),
      muted: newValue === 0 ? true : false,
    });
  };

  React.useEffect(() => {
    setChange(true);
    setTimeout(() => {
      setChange(false);
    }, 10);
  }, [playerstate.muted]);

  const handlePopOver = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleVolumeSeek = ({ e, newValue }: { e: any; newValue: any }) => {
    if (typeof newValue === "number") {
      setPlayerState({
        ...playerstate,
        volume: parseFloat((newValue / 100) as any),
        muted: newValue === 0 ? true : false,
      });
    }
  };

  const handlePlayerRate = (rate: any) => {
    var video: any = document.getElementById("vid");
    video.playbackRate = rate;
    setPlayerState({ ...playerstate, playerbackRate: rate });
  };

  const handleFullScreenMode = () => {
    screenfull.toggle(playerDivRef.current);
  };

  const handlePlayerSeek = (e: any, newValue: any) => {
    setPlayerState({
      ...playerstate,
      played: parseFloat(newValue as any),
    });
  };

  const handlePlayerMouseSeekDown = () => {
    setPlayerState({ ...playerstate, seeking: true });
  };

  // var video: any = document.getElementById("vid");

  const handlePlayerMouseSeekUp = (e: any, newValue: any) => {
    setPlayerState({ ...playerstate, seeking: false, played: newValue });
    video.currentTime = parseFloat(newValue as any);
  };

  var vid: any = document.getElementById("vid");

  const currentPlayerTime = playerRef.current
    ? playerRef.current.getCurrentTime()
    : "00:00";
  const movieDuration = vid ? vid.duration : "00:00";
  const playedTime = format(currentPlayerTime);
  const fullMovieTime = format(movieDuration);

  if (vid)
    vid.ontimeupdate = function () {
      var percentage = (vid?.currentTime / vid?.duration) * 100;
      setPlayerState({
        ...playerstate,
        played: parseFloat((percentage / 100) as any),
      });
    };

  const handlePip = () => {
    setPipMode(!pipMode);
    togglePictureInPicture(!isPictureInPictureActive);
  };

  const lang = useCompanyLanguage();

  return (
    <>
      <Container
        sx={{
          margin: 0,
          minWidth: "633px",
        }}
        disableGutters={true}
      >
        <div
          className={Style.playerDiv}
          ref={playerDivRef}
          onMouseMove={handleMouseMove}
          onDoubleClick={handleFullScreenMode}
        >
          <video
            id={"vid"}
            ref={vidRef}
            width="100%"
            height="100%"
            controls={false}
            muted={playerstate.muted}
            onClick={handlePlayVideo}
            onEnded={onFinished}
          >
            <source src={source} type="video/mp4" />
          </video>

          {controlsShow && (
            <ControlIcons
              mainTitle={mainTitle}
              mainSubTitle={mainSubTitle}
              screenfull={screenfull.isFullscreen}
              videoRef={vidRef}
              key={volume.toString()}
              playandpause={handlePlayVideo}
              playing={playing}
              rewind={handleRewind}
              fastForward={handleFastForward}
              muting={handleMuting}
              muted={muted}
              volumeChange={handleVolumeChange}
              volumeSeek={handleVolumeSeek}
              volume={volume}
              playerbackRate={playerbackRate}
              playRate={handlePlayerRate}
              fullScreenMode={handleFullScreenMode}
              played={played}
              onSeek={handlePlayerSeek}
              onSeekMouseUp={handlePlayerMouseSeekUp}
              onSeekMouseDown={handlePlayerMouseSeekDown}
              playedTime={playedTime}
              fullMovieTime={fullMovieTime}
              seeking={seeking}
            />
          )}
          {controlsShow && (
            <div
              className={Style.controls__div2}
              ref={bottomControlsRef}
              id="mydiv"
            >
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                style={{ paddingInline: 16, paddingTop: 0, paddingBottom: 8 }}
              >
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{
                    padding: 16,
                    paddingBottom: 0,
                    paddingLeft: 7,
                    paddingTop: 10,
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                      paddingBottom: "0px",
                      width: "100%",
                    }}
                  >
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "row",
                        paddingBottom: "0px",
                        width: "100%",
                      }}
                      onMouseEnter={() => {
                        setControlsShow(true);
                      }}
                    >
                      <VideoSeekSlider
                        max={maxTime}
                        currentTime={currentTime}
                        bufferTime={progress}
                        onChange={handleTimeChange}
                        limitTimeTooltipBySides={true}
                        secondsPrefix="00:"
                        minutesPrefix="0:"
                        timeCodes={timeCodes}
                      />
                    </span>

                    <Typography
                      style={{
                        color: "white",
                        paddingLeft: lang === "ar" ? "0em" : "1em",
                        paddingRight: lang === "ar" ? "1em" : "0em",
                      }}
                    >
                      {fullMovieTime}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center" direction="row">
                    <Tooltip title={playing ? "Pause" : "Play"} placement="top">
                      <IconButton
                        className={Style2.controls__icons}
                        aria-label="reqind"
                        onClick={handlePlayVideo}
                        sx={{
                          paddingLeft: "2px",
                          paddingRight: "8px",
                          paddingTop: "2px",
                        }}
                      >
                        {playing ? (
                          <PauseSharp
                            fontSize="large"
                            style={{ color: "white" }}
                          />
                        ) : (
                          <PlayBottomSVG color="#e7e7e7" />
                        )}
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Rewind"} placement="top">
                      <IconButton
                        className={Style2.controls__icons}
                        aria-label="reqind"
                        sx={{ paddingRight: "0px", paddingTop: "2px" }}
                        onClick={handleRewind}
                      >
                        <RewindSVG color="#e7e7e7" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Fast Forward"} placement="top">
                      <IconButton
                        className={Style2.controls__icons}
                        aria-label="reqind"
                        onClick={handleFastForward}
                        sx={{ paddingLeft: "4px", paddingTop: "2px" }}
                      >
                        <FastForwardSVG color="#e7e7e7" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Volume"} placement="top">
                      <IconButton
                        className={Style2.controls__icons}
                        aria-label="reqind"
                        style={{
                          paddingLeft: 0,
                          paddingTop: "5px",
                        }}
                        onClick={handleMuting}
                        onMouseEnter={() => setShowVolume(true)}
                        onMouseLeave={() => setShowVolume(false)}
                      >
                        {muted ? (
                          <VolumeOff
                            style={{ color: "white", fontSize: "27px" }}
                          />
                        ) : (
                          <RingBellSVG color="#e7e7e7" />
                        )}
                      </IconButton>
                    </Tooltip>
                    <Box
                      sx={{
                        width: 100,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        opacity: showVolume ? "100%" : 0,
                        height: "20px",
                      }}
                      onMouseEnter={() => setShowVolume(true)}
                      onMouseLeave={() => setShowVolume(false)}
                    >
                      {!change && (
                        <RSlider
                          progress
                          defaultValue={playerstate.volume * 100 || 0}
                          // value={playerstate.volume}
                          onChange={handleVolumeChange}
                          min={0}
                          max={100}
                          style={{
                            width: "100px",
                          }}
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid>

                <Tooltip title={"Topic Name"} placement="top">
                  <Grid
                    item
                    style={{
                      marginLeft: "-7em",
                    }}
                  >
                    <div className={Style2.module}>
                      {moduleName}{" "}
                      <span className={Style2.topicName}> {topicName}</span>
                    </div>
                  </Grid>
                </Tooltip>
                <Grid item>
                  <span id={"mydiv2"}>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      onMouseEnter={() => setControlsShow(true)}
                      onMouseMove={() => setControlsShow(true)}
                      onMouseOver={() => {
                        // console.log("mouse ovr")
                      }}
                    >
                      <Grid container direction="column-reverse">
                        {[0.5, 1, 1.5, 2].map((rate) => (
                          <Button
                            variant="text"
                            onClick={() => handlePlayerRate(rate)}
                          >
                            <Typography
                              color={
                                rate === playerbackRate
                                  ? "secondary"
                                  : "default"
                              }
                            >
                              {rate}
                            </Typography>
                          </Button>
                        ))}
                      </Grid>
                    </Popover>
                  </span>

                  <Tooltip title={"Next Video"} placement="top">
                    <IconButton
                      className={Style2.controls__icons}
                      aria-label="reqind"
                      sx={{ paddingRight: "0px", paddingTop: "2px" }}
                      onClick={() => nextVideoClick()}
                    >
                      <ForwardSVG color="#e7e7e7" />
                    </IconButton>
                  </Tooltip>

                  {isPictureInPictureAvailable && (
                    <Tooltip title={"Picture-in-picture mode"} placement="top">
                      <IconButton
                        className={Style2.controls__icons}
                        aria-label="reqind"
                        sx={{ paddingRight: "0px", paddingTop: "2px" }}
                        onClick={handlePip}
                      >
                        <PipVideoSVG color="#e7e7e7" />
                      </IconButton>
                    </Tooltip>
                  )}

                  <Tooltip title={"Toggle Fullscreen"} placement="top">
                    <IconButton
                      className={Style2.controls__icons}
                      aria-label="reqind"
                      sx={{ paddingRight: "0px", paddingTop: "2px" }}
                      onClick={handleFullScreenMode}
                    >
                      <VideoFullScreenSVG color="#e7e7e7" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      </Container>
    </>
  );
}

export default VideoPlayer;
