import { yupResolver } from "@hookform/resolvers/yup";
import { DialogContent } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import * as yup from "yup";
import CancelButton from "../../../components/CancelButton";
import SubmitButton from "../../../components/SubmitButton";
import useCompanyLanguage from "../../../hooks/language";
import { userSelector } from "../../../store/reducers/userReducers";
import { RootState, useAppDispatch } from "../../../store/store";
import Style from "./AddAnswer.module.scss";

export interface SimpleDialogProps {
  open: boolean;
  setParentOpen: any;
  selectedValue?: string;
  isLoading: boolean;
  onClose: () => void;
  handleAddOption: any;
  edit?: any;
  editId?: any;
  text?: any;
  handleUpdateOption: any;
  setValueParent?: any;
  getValueParent?: any;
}

const schema = yup
  .object({
    answer_option: yup
      .string()
      .required("Answer option is required")
      .max(100, "Answer option must be at most 100 characters"),
  })
  .required();

function AddAnswer(props: SimpleDialogProps) {
  const {
    onClose,
    selectedValue,
    open,
    setParentOpen,
    isLoading,
    handleAddOption,
    edit,
    editId,
    handleUpdateOption,
    setValueParent,
    getValueParent,
  } = props;

  const {
    register,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const states = useSelector(userSelector).userReducer;
  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );

  useEffect(() => {
    if (edit && editId) {
      setValue("answer_option", editId.answer);
    }
  }, [editId]);

  const handleClose = () => {
    if (isLoading) {
    } else {
      onClose();
      setValue("answer_option", "");
    }
  };

  // useEffect(() => {

  //   if (
  //     getValueParent &&
  //     getValueParent("answer_options") &&
  //     getValueParent("answer_options").find(
  //       (i: any) => i.answer == watch("answer_option")
  //     )?.id
  //   ) {
  //     setError("answer_option", {
  //       type: "custom",
  //       message: "Option already added!",
  //     });
  //   } else {
  //     clearErrors("answer_option");
  //   }
  // }, [watch("answer_option")]);
  useEffect(() => {
    clearErrors("answer_option");
  }, []);
  const onSubmit = (payload: any) => {
    if (edit && editId) {
      if (
        getValueParent &&
        getValueParent("answer_options") &&
        getValueParent("answer_options").find(
          (i: any) => i.answer == watch("answer_option") && i.id !== editId.id
        )?.id
      ) {
        setError("answer_option", {
          type: "custom",
          message: "Option already added!",
        });
        return;
      } else if (!/\S/.test(payload.answer_option)) {
        setError("answer_option", {
          type: "custom",
          message: "Please input character other than space!!",
        });
        return;
        // Didn't find something other than a space which means it's empty
      }
      handleUpdateOption({
        answer_option: {
          checked: editId.checked,
          id: editId.id,
          answer: payload.answer_option,
        },
        id: editId,
      });
    } else {
      if (
        getValueParent &&
        getValueParent("answer_options") &&
        getValueParent("answer_options").find(
          (i: any) => i.answer == watch("answer_option")
        )?.id
      ) {
        setError("answer_option", {
          type: "custom",
          message: "Option already added!",
        });
        return;
      } else if (!/\S/.test(payload.answer_option)) {
        setError("answer_option", {
          type: "custom",
          message: "Please input character other than space!!",
        });
        return;
        // Didn't find something other than a space which means it's empty
      } else {
        clearErrors("answer_option");
        handleAddOption(payload);
      }
    }
    setValue("answer_option", "");
    // setParentOptionAdd()
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        maxWidth={"md"}
        open={open && isLoading ? true : open}
      >
        <DialogTitle style={{ paddingTop: "1em", fontSize: 22 }}>
          {t("Add Answer")}
        </DialogTitle>
        <DialogContent>
          <form
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
            id="formadd_answer"
          >
            <div className={`${Style.col6} px-0 mx-0 mt-3`}>
              <span className={Style.labelHeader}>
                {t("Answer")}
                <span style={{ color: "red" }}> *</span>
              </span>
              <input
                className={`${Style.inputfields} ${Style.fieldFont} ${
                  errors.answer_option ? "is-invalid" : ""
                }`}
                autoFocus
                type="text"
                placeholder={t("Answer Option")}
                autoComplete="off"
                {...register("answer_option")}
              />
              <p className="color-red">{errors.answer_option?.message}</p>
            </div>

            <div
              className="d-flex flex-wrap gap-3 w-100 px-3 align-items-center my-5"
              style={{
                justifyContent: lang === "ar" ? "flex-start" : "flex-end",
              }}
            >
              <SubmitButton
                label={edit ? t("Update") : t("Save")}
                marginTop={false}
              />
              <CancelButton
                size="small"
                outlined={true}
                onClick={() => {
                  clearErrors("answer_option");
                  setParentOpen(false);
                  setValue("answer_option", "");
                }}
                textColor="#2196F3"
                outlineColor=""
                text={t("Cancel")}
                bg={"rgba(33, 150, 243, 0.04)"}
                className={``}
              ></CancelButton>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AddAnswer;
