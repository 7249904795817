/* eslint-disable eqeqeq */
/* eslint-disable no-self-assign */
// import Axios from "axios";

// const BASE_URL = process.env.REACT_APP_BASE_API_URL;

// const axiosPrivate = Axios.create({
//   baseURL: BASE_URL,
//   headers: {
//     "Content-Type": "application/json",
//     accept: "application/json",
//   },
// });

// axiosPrivate.interceptors.request.use(
//   (config) => {
//     const accessToken = localStorage.getItem("access_token") || "";
//     if (!config.headers["Authorization"]) {
//       config.headers["Authorization"] = `Bearer ${accessToken}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// const SessionExpired = (res: any, err: any) => {
//   if (
//     res.response.status === 401 &&
//     res.response &&
//     (res.response?.data?.code === "user_not_found" ||
//       res.response?.data?.code === "token_not_valid")
//   ) {

//     localStorage.removeItem("access_token");
//     localStorage.removeItem("refresh_token");
//     localStorage.removeItem("user_info");
//     localStorage.removeItem("video_info");
//     localStorage.removeItem("permissions_list");
//     localStorage.removeItem("signup_data");
//     localStorage.setItem("logout_error", err);
//     window.location.href = window.location.href;
//     return;
//   }
// };

// axiosPrivate.interceptors.response.use(
//   (res) => {
//     return res;
//   },
//   async (err) => {
//     const originalConfig = err.config;

//     if (err.response.status === 401 && !originalConfig._retry && err.response) {
//       originalConfig._retry = true;

//       const refreshToken = localStorage.getItem("refresh_token") || "";
//       try {
//         await Axios.post(
//           `${BASE_URL}accounts/token/refresh/`,
//           {
//             refresh: refreshToken,
//           },
//           {
//             headers: {
//               "Content-Type": "application/json",
//               accept: "application/json",
//             },
//           }
//         )
//           .then((response) => {

//             const { access } = response.data;
//             localStorage.setItem("access_token", access);
//             return axiosPrivate(originalConfig);
//           })
//           .catch((error) => {
//             SessionExpired(error, err.response?.data?.detail);
//             return Promise.reject(error);
//           });
//       } catch (_error) {
//         SessionExpired(
//           _error,
//           err.response?.data?.code == "token_not_valid"
//             ? "Your session has expired, Please login again."
//             : err.response?.data?.detail
//         );
//         return Promise.reject(_error);
//       }
//     }

//     return Promise.reject(err);
//   }
// );

// export default axiosPrivate;
import axios from "axios";
import Cookies from "js-cookie";

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

const handleSessionExpired = (error: any, detail: any) => {
  localStorage.removeItem("access_token");
  Cookies.remove("refresh_token"); // Remove refresh token from cookie
  localStorage.removeItem("user_info");
  localStorage.removeItem("video_info");
  localStorage.removeItem("permissions_list");
  localStorage.removeItem("signup_data");
  localStorage.setItem("logout_error", detail);
  window.location.href = window.location.href;
};

axiosPrivate.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("access_token") || "";
    if (!config.headers["Authorization"]) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosPrivate.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalConfig = error.config;

    if (error.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;
      const refreshToken = Cookies.get("refresh_token") || ""; // Retrieve refresh token from cookie

      try {
        const refreshResponse = await axios.post(
          `${BASE_URL}accounts/token/refresh/`,
          { refresh: refreshToken },
          {
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
            },
          }
        );

        const { access, refresh } = refreshResponse.data;
        localStorage.setItem("access_token", access);
        Cookies.set("refresh_token", refresh, { expires: 1 }); // Set refresh token in cookie with expiration
        return axiosPrivate(originalConfig);
      } catch (refreshError) {
        handleSessionExpired(
          refreshError,
          error.response?.data?.code === "token_not_valid"
            ? "Your session has expired. Please log in again."
            : error.response?.data?.detail
        );
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosPrivate;
