import { useEffect, useState } from "react";
import Header from "../../components/Header";
import Style from "./DetailsGroup.module.scss";

import { useConfirm } from "material-ui-confirm";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteGroupMember, getGroup } from "../../services/groupManagementService";
import { RootState, useAppDispatch } from "../../store/store";

import { alertMessageSelector, setErr, setSuccess } from "../../store/reducers/alertMessageReducer";

import useColor from "../../hooks/useColor";
import usePermission from "../../hooks/usePermission";
import EditSVG from "../../icons/EditSVG";
import { defaultThemeColor } from "../../utils/colors_values";
import { confirmProps } from "../../utils/confirmProps";
import EditGroupModal from "./EditGroupModal";
import GroupMemberList from "./GroupMemberList";
import { useTranslation } from "react-multi-lang";
import useCompanyLanguage from "../../hooks/language";

function DetailGroup() {
  const { id } = useParams();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const [checkedValues, setCheckedValues] = useState<any>([]);
  const [isNameEdit, setIsNameEdit] = useState(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const CloseEditModal = () => setOpenEditModal(false);
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;

  const isLoading = useSelector((state: RootState) => state.loaderReducer.isLoading);
  const detail = useSelector((state: RootState) => state.groupManagementReducer.detailData);
  useEffect(() => {
    dispatch(getGroup(Number(id)));
  }, [id]);

  const handleMultipleDelete = () => {
    if (checkedValues?.length > 0) {
      confirm({
        title: t("Are you sure!"),
        description: t(`Do you want to delete selected members?`),
        ...confirmProps,
      })
        .then(() => {
          dispatch(deleteGroupMember(Number(id), checkedValues));
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      setIsNameEdit(false);
      setOpenEditModal(false);
      setCheckedValues([]);
      dispatch(setSuccess(null));
      dispatch(getGroup(Number(id)));
    }
  }, [alertMessage]);

  const handleIsEdit = () => {
    setOpenEditModal(true);
    setIsNameEdit(!isNameEdit);
  };

  const primaryButton = useColor("button_primary") || defaultThemeColor.button_primary;
  const primaryButtonText =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;
  return (
    <>
      <div>
        <Header
          page={t("Group Management")}
          mainHead={t("Group Management")}
          backLink="/group-management"
        />
      </div>

      <div
        className="page-start"
        style={{
          color:
            useColor("interface_foreground_text_color") ||
            defaultThemeColor.interface_foreground_text_color,
          background:
            useColor("interface_foreground_color") || defaultThemeColor.interface_foreground_color,
        }}
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center gap-3 mb-5">
            <h2 className="page-heading">{detail?.name} </h2>
            {detail?.is_auto_created && (
              <span
                className={`badge ${Style.badge}`}
                style={{
                  background: primaryButton,
                  color: primaryButtonText,
                }}
              >
                {t("Auto")}
              </span>
            )}
            {!isLoading && (
              <button
                type="button"
                className={`d-flex align-items-center justify-content-center ${Style.editButton}`}
                onClick={handleIsEdit}
                style={{
                  background: primaryButton,
                  color: primaryButtonText,
                  border: "none",
                }}
              >
                <EditSVG color="#fff" />
              </button>
            )}
          </div>
          {usePermission("change_customusergroup") && detail?.is_auto_created && (
            <div>
              <button
                className={`btn d-flex justify-content-center align-items-center ${Style.buttonStart}`}
                style={{
                  background: primaryButton,
                  color: primaryButtonText,
                }}
                type="button"
                disabled={isLoading}
                onClick={() => {
                  navigate(`/config-auto-group/${id}`);
                }}
              >
                {t("Auto Group Configuration")}
              </button>
            </div>
          )}
        </div>

        <GroupMemberList />

        {checkedValues?.length > 0 && (
          <button className="btn btn-primary" onClick={handleMultipleDelete}>
            {t("Delete")}
          </button>
        )}
      </div>
      <EditGroupModal
        openEditModal={openEditModal}
        CloseEditModal={CloseEditModal}
        detail={detail}
        id={Number(id)}
      />
    </>
  );
}

export default DetailGroup;
