import React, { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DashboardNotification from "../../components/DashboardNotification";
import DataTable from "../../components/table/DataTable";
import useColor from "../../hooks/useColor";
import ViewSVG from "../../icons/ViewSVG";
import { getDashboardInsights } from "../../services/dashboradService";
import { alertMessageSelector, setErr, setSuccess } from "../../store/reducers/alertMessageReducer";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import Style from "./AdminDashboard.module.scss";
import PieChart from "./PieChart";
import VerticalChart from "./VerticalChart";
import { useTranslation } from "react-multi-lang";
import useCompanyLanguage from "../../hooks/language";

const verticalChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top" as const,
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      display: true,
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      display: true,
      ticks: {
        stepSize: 1,
      },
      grid: {
        display: false,
      },
    },
  },
};

const verticalChartOptionsEnrollment = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top" as const,
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          var label = context.dataset.label || "";
          if (context.parsed.y !== null) {
            label += "" + context.parsed.y + "%";
          }
          return label;
        },
      },
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      display: true,
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      display: true,
      ticks: {
        stepSize: 1,
        min: 0,
        callback: function (value: any) {
          return value + "%";
        },
      },
      grid: {
        display: false,
      },
    },
  },
};

const TrainerDashboard: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const [generalTrainingStatus, setGeneralTrainingStatus] = useState<any>();
  const [topTrainings, setTopTrainings] = useState<any>();
  const [depTrainings, setDepTrainings] = useState<any>();
  const [selectedRole, setSelectedRole] = useState("Roles");
  const [selectedType, setSelectedType] = useState("Enrollment");
  const dataDashboard = useSelector((state: RootState) => state.dashboardReducer.data);
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      dispatch(
        getDashboardInsights({
          start_date: "2023-08-10",
          end_date: "2023-10-10",
        })
      );
    }
  }, [alertMessage]);

  useEffect(() => {
    dispatch(
      getDashboardInsights({
        start_date: "2023-08-10",
        end_date: "2023-10-10",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    makeDataSet();
  }, [dataDashboard, selectedRole, selectedType]);

  const makeDataSet = () => {
    const pieChartDataGeneral = {
      datasets: [
        {
          data: [
            (dataDashboard?.general_training_status &&
              dataDashboard?.general_training_status?.completed) ||
              0,
            (dataDashboard?.general_training_status &&
              dataDashboard?.general_training_status?.in_progress) ||
              0,
            (dataDashboard?.general_training_status &&
              dataDashboard?.general_training_status?.backlog) ||
              0,
          ],
          backgroundColor: ["#0089CF", "#6AD2FF", "#FBBC05"],
          borderColor: ["#0089CF", "#6AD2FF", "#FBBC05"],
          borderWidth: 1,
        },
      ],
    };

    const verticalChartData = {
      labels: [
        ...(selectedType == "Enrollment"
          ? (dataDashboard?.top_trainings_per_enrollment &&
              dataDashboard?.top_trainings_per_enrollment.map((i: any) => i?.name)) ||
            []
          : (dataDashboard?.top_trainings_per_completion_rate &&
              dataDashboard?.top_trainings_per_completion_rate.map((i: any) => i?.name)) ||
            []),
      ],
      datasets: [
        {
          data:
            selectedType == "Enrollment"
              ? (dataDashboard?.top_trainings_per_enrollment &&
                  dataDashboard?.top_trainings_per_enrollment.map((i: any) => i?.user_count)) ||
                []
              : (dataDashboard?.top_trainings_per_completion_rate &&
                  dataDashboard?.top_trainings_per_completion_rate.map(
                    (i: any) => i?.completion_percentage
                  )) ||
                [],
          backgroundColor: "#6AD2FF",
        },
      ],
    };

    const verticalChartData2 = {
      labels: [
        ...((dataDashboard?.training_enrolments_per_department &&
          dataDashboard?.training_enrolments_per_department.map((i: any) => i?.name)) ||
          []),
      ],
      datasets: [
        {
          data:
            (dataDashboard?.training_enrolments_per_department &&
              dataDashboard?.training_enrolments_per_department.map(
                (i: any) => i?.training_count
              )) ||
            [],
          backgroundColor: "#6AD2FF",
        },
      ],
    };

    setGeneralTrainingStatus(pieChartDataGeneral);
    setTopTrainings(verticalChartData);
    setDepTrainings(verticalChartData2);
  };

  const filterDataByType = (data: any, role: string) => {
    return data;
  };

  const handleTypeFilterChange = (selectedType: string) => {
    setSelectedType(selectedType);
  };

  const filteredVerticalChartData = filterDataByType(topTrainings, selectedType);

  const filteredVerticalChartData2 = filterDataByType(depTrainings, selectedType);

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;
  const primaryColor = useColor("button_primary") || defaultThemeColor.button_primary;

  return (
    <>
      <div className={Style.listingViewContainer}>
        <DashboardNotification />
        <div className={Style.myTraining}>
          <div className={Style.trainingContainer}>
            <div className={Style.trainingHeading} style={{ color: interfaceTextColor }}>
              {t("My Trainings")}
            </div>
            <div className={`${Style.outer_cover2}`}>
              <DataTable
                rows={
                  (dataDashboard &&
                    dataDashboard?.my_trainings &&
                    dataDashboard?.my_trainings.map((training: any) => ({
                      name: training?.name,
                      category: training?.category?.name,
                      level: training?.level,
                      actions: (
                        <>
                          <Tooltip title={"View Training"} placement="top">
                            <Link aria-label="view" to={"/detail-training/view/" + training?.id}>
                              <ViewSVG color={primaryColor} />
                            </Link>
                          </Tooltip>
                        </>
                      ),
                    }))) ||
                  []
                }
                columnData={[
                  {
                    id: "training_name",
                    name: t("Training Name"),
                    enableSort: true,
                    align: lang === "ar" ? "center" : "left",
                    disablePadding: "checkbox",
                  },
                  {
                    id: "category",
                    name: t("Category"),
                    enableSort: true,
                    align: lang === "ar" ? "center" : "left",
                    disablePadding: "none",
                  },
                  {
                    id: "level",
                    name: t("Level"),
                    enableSort: true,
                    align: lang === "ar" ? "center" : "left",
                    disablePadding: "none",
                  },

                  {
                    id: "actions",
                    name: t("Action"),
                    enableSort: false,
                    align: lang === "ar" ? "center" : "left",
                    disablePadding: "none",
                  },
                ]}
                isTopCheckbox={false}
                isCheckboxes={false}
                isSingleSelectRowOnly={false}
                bottomButtonsComponent={true}
                isSelectedRowsOnly={false}
                hideBottomRow={true}
                selectedRowsPerPage={""}
                totalRecordCount={1}
                handleChangePageParent={""}
                pagenum={1}
                handleChangeRowsPerPageParent={""}
                selectedCheckBox={[]}
                boxShadow={true}
                rowsScroll={true}
              />
            </div>
            {dataDashboard &&
              dataDashboard?.my_trainings &&
              dataDashboard?.my_trainings.length > 0 && (
                <div
                  className={Style.viewAllBtn}
                  style={{ color: interfaceTextColor }}
                  onClick={() => {
                    navigate("/trainings");
                  }}
                >
                  {t("View all")}
                </div>
              )}
          </div>
        </div>
      </div>
      <div className={Style.topTrainingMainContainer}>
        <div className={Style.verticalChartContainer}>
          <div style={{ paddingBottom: "1rem" }}>
            <span
              style={{
                color: "#1B2559",
                fontSize: "20px",
                fontWeight: "700",
                marginRight: "1rem",
                marginLeft: lang === "ar" ? "1rem" : "0rem",
              }}
            >
              {t("Top Trainings")}
            </span>
            <select
              style={{
                background: "#EFF4FA",
                width: "100px",
                height: "35px",
                borderRadius: "8px",
                outline: "none",
                border: "none",
                color: "#A3AED0",
                fontSize: "14px",
                paddingLeft: "5px",
              }}
              value={selectedType}
              onChange={(e) => handleTypeFilterChange(e.target.value)}
            >
              <option style={{ color: "#A3AED0" }} value="Enrollment">
                {t("Enrollment")}
              </option>
              <option style={{ color: "#A3AED0" }} value="Completion rate">
                {t("Completion rate")}
              </option>
            </select>
          </div>
          <div style={{ width: "100%", height: "100%" }}>
            {topTrainings && (
              <VerticalChart
                verticalChartData={filteredVerticalChartData as any}
                verticalChartOptions={
                  selectedType == "Enrollment"
                    ? (verticalChartOptions as any)
                    : verticalChartOptionsEnrollment
                }
              />
            )}
          </div>
        </div>
      </div>
      <div className={Style.enrolPerDeptContainer}>
        <div className={Style.pieStatusChart}>
          <div
            style={{
              color: "#1B2559",
              fontSize: "20px",
              fontWeight: "700",
              marginRight: "1rem",
            }}
          >
            {t("General Training status")}
          </div>
          <div
            style={{
              height: "170px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {generalTrainingStatus && <PieChart pieChartData={generalTrainingStatus} />}
          </div>
          <div
            style={{
              boxShadow: "0px 18px 40px 0px #7090B01F",
              background: "#fff",
              padding: "1rem",
              display: "flex",
              borderRadius: "16px",
              justifyContent: "space-evenly",
              marginTop: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                borderRight: "1px solid #F4F7FE",
                flex: "0 0 30%",
              }}
            >
              <div
                style={{
                  background: "#0089CF",
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                  marginLeft: lang === "ar" ? "8px" : "0px",
                }}
              ></div>
              <div style={{ paddingLeft: "10px" }}>
                <div
                  style={{
                    color: "#A3AED0",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  {t("Completed")}
                </div>
                <div
                  style={{
                    color: "#2B3674",
                    fontSize: "18px",
                    fontWeight: "700",
                  }}
                >
                  {" "}
                  {dataDashboard?.general_training_status?.completed_percentage.toFixed(1) ||
                    0}%{" "}
                  <span
                    style={{
                      color: "#A3AED0",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    ({dataDashboard?.general_training_status?.completed || 0})
                  </span>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                borderRight: "1px solid #F4F7FE",
                flex: "0 0 30%",
              }}
            >
              <div
                style={{
                  background: "#6AD2FF",
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                  marginLeft: lang === "ar" ? "8px" : "0px",
                }}
              ></div>
              <div style={{ paddingLeft: "10px" }}>
                <div
                  style={{
                    color: "#A3AED0",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  {t("In progress")}
                </div>
                <div
                  style={{
                    color: "#2B3674",
                    fontSize: "18px",
                    fontWeight: "700",
                  }}
                >
                  {" "}
                  {dataDashboard?.general_training_status?.in_progress_percentage.toFixed(1) ||
                    0}%{" "}
                  <span
                    style={{
                      color: "#A3AED0",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    ({dataDashboard?.general_training_status?.in_progress || 0})
                  </span>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                borderRight: "1px solid #F4F7FE",
                flex: "0 0 30%",
              }}
            >
              <div
                style={{
                  background: "#FBBC05",
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                  marginLeft: lang === "ar" ? "8px" : "0px",
                }}
              ></div>
              <div style={{ paddingLeft: "10px" }}>
                <div
                  style={{
                    color: "#A3AED0",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  {t("Not Started")}
                </div>
                <div
                  style={{
                    color: "#2B3674",
                    fontSize: "18px",
                    fontWeight: "700",
                  }}
                >
                  {" "}
                  {dataDashboard?.general_training_status?.backlog_percentage.toFixed(1) || 0}%{" "}
                  <span
                    style={{
                      color: "#A3AED0",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    ({dataDashboard?.general_training_status?.backlog || 0})
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={Style.verticalDeptChart}>
          <div style={{ paddingBottom: "1rem" }}>
            <span
              style={{
                color: "#1B2559",
                fontSize: "20px",
                fontWeight: "700",
                marginRight: "1rem",
              }}
            >
              {t("Training enrolments per department")}
            </span>
          </div>
          <div style={{ width: "100%", height: "100%" }}>
            {depTrainings && (
              <VerticalChart
                verticalChartData={filteredVerticalChartData2 as any}
                verticalChartOptions={verticalChartOptionsEnrollment as any}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TrainerDashboard;
