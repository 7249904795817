// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditColorTheme_background__SfFKA {
  padding: 2rem;
  border-radius: 10px;
}
.EditColorTheme_background__SfFKA .EditColorTheme_flexHeading__BJ1Jl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
}
.EditColorTheme_background__SfFKA .EditColorTheme_flexHeading__BJ1Jl .EditColorTheme_head__zUY07 {
  font-size: 24px;
  font-weight: 700;
  line-height: 38px;
  color: #14276f;
}
.EditColorTheme_background__SfFKA .EditColorTheme_flexHeading__BJ1Jl .EditColorTheme_resetBtn__pzNcw {
  background: #0089cf;
  border: none;
  outline: none;
  color: #fff;
  padding: 0.5rem 2rem;
  border-radius: 4px;
}
.EditColorTheme_background__SfFKA .EditColorTheme_sideBarButtonWrapper__FcqrV {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 991px) {
  .EditColorTheme_background__SfFKA .EditColorTheme_sideBarButtonWrapper__FcqrV {
    flex-direction: column;
    align-items: flex-start;
  }
}
.EditColorTheme_background__SfFKA .EditColorTheme_sideBarButtonWrapper__FcqrV .EditColorTheme_sidebarColor__yEUgX {
  flex: 0 0 50%;
}
@media screen and (max-width: 991px) {
  .EditColorTheme_background__SfFKA .EditColorTheme_sideBarButtonWrapper__FcqrV .EditColorTheme_sidebarColor__yEUgX {
    margin-top: 3rem;
    flex: 0 0 100%;
  }
}
.EditColorTheme_background__SfFKA .EditColorTheme_sideBarButtonWrapper__FcqrV .EditColorTheme_buttonColor__VEXwe {
  flex: 0 0 50%;
}
@media screen and (max-width: 991px) {
  .EditColorTheme_background__SfFKA .EditColorTheme_sideBarButtonWrapper__FcqrV .EditColorTheme_buttonColor__VEXwe {
    margin-top: 3rem;
    flex: 0 0 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/CompanySettings/EditColorTheme/EditColorTheme.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AAAE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AAEJ;AADI;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AAGN;AADI;EACE,mBAAA;EACA,YAAA;EACA,aAAA;EACA,WAAA;EACA,oBAAA;EACA,kBAAA;AAGN;AAAE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAEJ;AADI;EAJF;IAKI,sBAAA;IACA,uBAAA;EAIJ;AACF;AAHI;EACE,aAAA;AAKN;AAJM;EAFF;IAGI,gBAAA;IACA,cAAA;EAON;AACF;AALI;EACE,aAAA;AAON;AANM;EAFF;IAGI,gBAAA;IACA,cAAA;EASN;AACF","sourcesContent":[".background {\n  padding: 2rem;\n  border-radius: 10px;\n  .flexHeading {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 3rem;\n    .head {\n      font-size: 24px;\n      font-weight: 700;\n      line-height: 38px;\n      color: #14276f;\n    }\n    .resetBtn {\n      background: #0089cf;\n      border: none;\n      outline: none;\n      color: #fff;\n      padding: 0.5rem 2rem;\n      border-radius: 4px;\n    }\n  }\n  .sideBarButtonWrapper {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    @media screen and (max-width: 991px) {\n      flex-direction: column;\n      align-items: flex-start;\n    }\n    .sidebarColor {\n      flex: 0 0 50%;\n      @media screen and (max-width: 991px) {\n        margin-top: 3rem;\n        flex: 0 0 100%;\n      }\n    }\n    .buttonColor {\n      flex: 0 0 50%;\n      @media screen and (max-width: 991px) {\n        margin-top: 3rem;\n        flex: 0 0 100%;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": `EditColorTheme_background__SfFKA`,
	"flexHeading": `EditColorTheme_flexHeading__BJ1Jl`,
	"head": `EditColorTheme_head__zUY07`,
	"resetBtn": `EditColorTheme_resetBtn__pzNcw`,
	"sideBarButtonWrapper": `EditColorTheme_sideBarButtonWrapper__FcqrV`,
	"sidebarColor": `EditColorTheme_sidebarColor__yEUgX`,
	"buttonColor": `EditColorTheme_buttonColor__VEXwe`
};
export default ___CSS_LOADER_EXPORT___;
