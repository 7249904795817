import axiosPrivate from "../lib/axiosPrivate";
import { setLoading } from "../store/reducers/loaderReducer";
import { AppThunk } from "../store/store";

import {
  setErr,
  setErrors,
  setSuccess,
} from "../store/reducers/alertMessageReducer";

import {
  setData,
  setImageData,
  setQuestionData,
  setWholeData,
  setWholeDetail,
} from "../store/reducers/questionReducer";
import { setProgress } from "../store/reducers/progressReducer";

export interface AddQuestion {}

export interface GetData {
  page: number;
  page_size: number;
  search: string;
  question_status?: string;
}

export const getListData =
  (payload: GetData): AppThunk =>
  async (dispatch) => {
    dispatch(setData({}));
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .get(
          `assessments/questions/?page=${payload.page}&page_size=${payload.page_size}` +
            `${
              payload.question_status
                ? `&question_status=${payload.question_status}`
                : ""
            }` +
            `${payload.search ? `&search=${payload.search}` : ""}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setData(data));
          dispatch(setLoading(false));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getWholeListData =
  (payload: GetData): AppThunk =>
  async (dispatch) => {
    dispatch(setWholeData({}));
    // dispatch(setLoading(true));
    try {
      await axiosPrivate
        .get(
          `assessments/questions/?page=${payload.page}&page_size=${payload.page_size}` +
            `${
              payload.question_status
                ? `&question_status=${payload.question_status}`
                : ""
            }` +
            `${payload.search ? `&search=${payload.search}` : ""}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setWholeData(data));
          // dispatch(setLoading(false));
        })
        .catch((error) => {
          // dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      // dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const addQuestion =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await axiosPrivate
        .post(`assessments/questions/`, payload)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setSuccess("Question added successfully!"));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const updateQuestion =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await axiosPrivate
        .patch(`assessments/questions/${payload.id}/`, payload)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setSuccess("Question updated successfully!"));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else if (error?.response?.data) {
            dispatch(setErrors(error?.response?.data));
          } else {
            dispatch(setErr("Something went wrong, Please try again"));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const addQuestionWithImage =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    let formData = new FormData();
    formData.append("filename", payload.image.imagename + Math.random());
    formData.append("file", payload.image.image, payload.image.imagename);

    try {
      await axiosPrivate
        .post("imaging/images/", formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=myboundary",
            enctype: "multipart/form-data; boundary=myboundary",
          },
          onUploadProgress: (progressEvent: any) => {
            dispatch(
              setProgress({
                total: progressEvent?.total,
                loaded: progressEvent?.loaded,
              })
            );
          },
        })
        .then(async (response) => {
          const data = response.data;
          await axiosPrivate
            .post(`assessments/questions/`, { ...payload, image: data.id })
            .then((response) => {
              const data = response.data;
              dispatch(setLoading(false));
              dispatch(setSuccess("Question added successfully!"));
            })
            .catch((error) => {
              dispatch(setLoading(false));
              if (error?.response?.data?.detail) {
                dispatch(setErr(error?.response?.data?.detail));
              } else {
                dispatch(setErrors(error?.response?.data));
              }
            });
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else if (error?.response?.data?.filename) {
            dispatch(setErr(error?.response?.data?.filename));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
    dispatch(
      setProgress({
        total: 0,
        loaded: 0,
      })
    );
  };

export const updateQuestionWithImage =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    let formData = new FormData();
    formData.append("filename", payload.image.imagename + Math.random());
    formData.append("file", payload.image.image, payload.image.imagename);

    try {
      await axiosPrivate
        .post("imaging/images/", formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=myboundary",
            enctype: "multipart/form-data; boundary=myboundary",
          },
          onUploadProgress: (progressEvent: any) => {
            dispatch(
              setProgress({
                total: progressEvent?.total,
                loaded: progressEvent?.loaded,
              })
            );
          },
        })
        .then(async (response) => {
          const data = response.data;
          await axiosPrivate
            .patch(`assessments/questions/${payload.id}/`, {
              ...payload,
              image: data.id,
            })
            .then((response) => {
              const data = response.data;
              dispatch(setLoading(false));
              dispatch(setSuccess("Question added successfully!"));
            })
            .catch((error) => {
              dispatch(setLoading(false));
              if (error?.response?.data?.detail) {
                dispatch(setErr(error?.response?.data?.detail));
              } else if (error?.response?.data) {
                dispatch(setErrors(error?.response?.data));
              } else {
                dispatch(setErr("Something went wrong, Please try again"));
              }
            });
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else if (error?.response?.data?.filename) {
            dispatch(setErr(error?.response?.data?.filename));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
    dispatch(
      setProgress({
        total: 0,
        loaded: 0,
      })
    );
  };

export const deleteQuestion =
  (id: Number): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .delete(`assessments/questions/${id}/`)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const bulkDeleteQuestions =
  (payload: any[]): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .post(`assessments/questions/bulk_delete/`, {
          question_ids: payload,
        })
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getQuestion =
  (payload: any): AppThunk =>
  async (dispatch) => {
    // dispatch(setLoading(true));
    dispatch(setQuestionData({}));
    try {
      await axiosPrivate
        .get("assessments/questions/" + payload.id + "/")
        .then((response) => {
          const data = response.data;
          // dispatch(setLoading(false));
          if (response.status === 200) {
            dispatch(setQuestionData(data));
          } else {
            dispatch(setErr("Something went wrong, Please try again"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getImage =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setImageData({}));
    try {
      await axiosPrivate
        .get("imaging/images/" + payload.id + "/")
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (response.status === 200) {
            dispatch(setImageData(data));
          } else {
            dispatch(setErr("Something went wrong, Please try again"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getDetailData =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setData({}));
    try {
      await axiosPrivate
        .get(
          `assessments/questions/${payload?.id}/association/?page=${payload?.page}&page_size=${payload?.page_size}&search=${payload?.search}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setData(data));
          setTimeout(() => {
            dispatch(setLoading(false));
          }, 500);
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getWholeDetailData =
  (payload: any): AppThunk =>
  async (dispatch) => {
    // dispatch(setLoading(true));
    dispatch(setWholeDetail({}));
    try {
      await axiosPrivate
        .get(
          `assessments/questions/${payload?.id}/association/?page=${payload?.page}&page_size=${payload?.page_size}&search=${payload?.search}`
        )
        .then((response) => {
          const data = response.data;
          // dispatch(setLoading(false));
          dispatch(setWholeDetail(data));
        })
        .catch((error) => {
          // dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      // dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };
