import { yupResolver } from "@hookform/resolvers/yup";
import { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import "react-phone-input-2/lib/style.css";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import CancelButton from "../../../components/CancelButton";
import Header from "../../../components/Header";
import useCompanyLanguage from "../../../hooks/language";
import useColor from "../../../hooks/useColor";
import {
  addEmailNotification,
  getEmailNotificationByID,
  updateEmailNotification,
} from "../../../services/emailNotificationService";
import {
  alertMessageSelector,
  setErrors,
  setSuccess,
} from "../../../store/reducers/alertMessageReducer";
import { setEmailByIdData } from "../../../store/reducers/emailNotificationReducer";
import { RootState, useAppDispatch } from "../../../store/store";
import { defaultThemeColor } from "../../../utils/colors_values";
import Style from "./AddEmailNotification.module.scss";

const schema = yup
  .object({
    name: yup
      .string()
      .required("Name is required")
      .max(100, "Name must be at most 100 characters"),
    status: yup.string().required("Status is required"),
    event: yup.string().required("Event type is required"),
    subject: yup.string().required("Subject is required"),
    certificate_data: yup
      .string()
      .min(10, "Must atleast 10 characters are required!")
      .required("Email text is required"),
  })
  .required();

function ViewEmailNotification() {
  const {
    reset,
    setError,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });
  const { id } = useParams();
  const { view } = useParams();
  const [certificateData, setCertificateData] = useState<string>("");
  const [certificateEditData, setCertificateEditData] = useState<string>("");
  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  useState<any>();
  const t = useTranslation();
  const lang = useCompanyLanguage();

  useEffect(() => {
    setValue("certificate_data", certificateData);
    setError("certificate_data", {
      message: "",
      type: "server",
    });
    if (
      certificateData == "<p><br></p>" ||
      certificateData == `<p class="ql-align-center"><br></p>`
    ) {
      setError("certificate_data", {
        message: "Email text is required!",
        type: "server",
      });
    }
  }, [certificateData]);

  const emailTemplateData = useSelector(
    (state: RootState) => state.emailNotificationReducer.emailByIdData
  );

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;

  useEffect(() => {
    let defaultValues = {};
    reset({ ...defaultValues });
  }, []);

  useEffect(() => {
    if (emailTemplateData && Object.keys(emailTemplateData).length > 0) {
      let defaultValues = {
        name: emailTemplateData?.template_name,
        status: emailTemplateData?.status,
        event: emailTemplateData?.select_event?.replaceAll("_", " "),
        subject: emailTemplateData?.subject,
        certificate_data: emailTemplateData?.body,
      };

      reset({ ...defaultValues });
      setCertificateEditData(emailTemplateData?.body);
    }
  }, [emailTemplateData]);

  useEffect(() => {
    setCertificateEditData("");
    setValue("name", "");
    setValue("event", "");
    setValue("status", "");
    setValue("subject", "");
    dispatch(setEmailByIdData({}));
    dispatch(
      getEmailNotificationByID({
        id: id,
      })
    );
  }, [id]);

  useEffect(() => {
    if (alertMessage?.errors) {
      if (alertMessage?.errors?.name?.length > 0) {
        toast.error(alertMessage?.errors?.name?.toString(), {
          toastId: alertMessage?.errors?.name?.toString(),
        });
        setError("name", {
          type: "server",
          message: alertMessage?.errors?.name?.toString(),
        });
      }
      if (alertMessage?.errors?.event?.length > 0) {
        setError("event", {
          type: "server",
          message: alertMessage?.errors?.event?.toString(),
        });
      }
      if (alertMessage?.errors?.status?.length > 0) {
        setError("status", {
          type: "server",
          message: alertMessage?.errors?.status?.toString(),
        });
      }
      if (alertMessage?.errors?.subject?.length > 0) {
        setError("subject", {
          type: "server",
          message: alertMessage?.errors?.subject?.toString(),
        });
      }
      if (alertMessage?.errors?.certificate_data?.length > 0) {
        toast.error(alertMessage?.errors?.certificate_data?.toString(), {
          toastId: alertMessage?.errors?.certificate_data?.toString(),
        });
        setError("certificate_data", {
          type: "server",
          message: alertMessage?.errors?.certificate_data?.toString(),
        });
      }
      if (alertMessage?.errors) {
        dispatch(setErrors(null));
      }
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      navigate(`/email-template-management?page=1&perpage=10`);
    }
  }, [alertMessage]);

  const Submit = (payload: any) => {
    let body = {
      template_name: payload?.name,
      select_event: payload?.event.replaceAll(" ", "_"),
      status: payload?.status,
      subject: payload?.subject,
      body: payload?.certificate_data,
    };
    if (
      certificateData == "<p><br></p>" ||
      certificateData == `<p class="ql-align-center"><br></p>`
    ) {
      setError("certificate_data", {
        message: "Email template text is required!",
        type: "server",
      });
    } else if (id) {
      dispatch(
        updateEmailNotification({
          ...body,
          id: id,
        })
      );
    } else {
      dispatch(
        addEmailNotification({
          ...body,
        })
      );
    }
  };
  const foregroundBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;
  const foregroundTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  return (
    <>
      <div
        className={`${Style.certificatesStart}`}
        style={{
          background:
            useColor("interface_background_color") ||
            defaultThemeColor.interface_background_color,
        }}
      >
        <Header
          page={t("Email Template")}
          mainHead={t("Email Templates Management")}
          backLink={view == "false" ? "" : "/email-template-management"}
        />

        <form
          className="row col-12 page-start"
          onSubmit={handleSubmit(Submit)}
          style={{
            background:
              useColor("interface_foreground_color") ||
              defaultThemeColor.interface_foreground_color,
            color:
              useColor("interface_foreground_text_color") ||
              defaultThemeColor.interface_foreground_text_color,
          }}
        >
          <div className="">
            <div className="d-flex justify-content-between">
              <h2
                className="page-heading mb-4"
                style={{
                  color:
                    useColor("interface_foreground_text_color") ||
                    defaultThemeColor.interface_foreground_text_color,
                  textAlign: lang === "ar" ? "right" : "left",
                }}
              >
                {emailTemplateData?.template_name}
              </h2>
              {!loading && (
                <span
                  style={{ padding: "0px 26px" }}
                  className={`mb-4 ${
                    emailTemplateData?.send_email
                      ? Style.statusSuccess
                      : Style.statusDraft
                  }`}
                >
                  {emailTemplateData?.send_email ? "Enabled" : "Disabled"}
                </span>
              )}
            </div>

            {
              <div>
                <div
                  className={`w-100 ${Style.certificateGenericTemplate} mb-2`}
                  style={{
                    color:
                      useColor("interface_foreground_text_color") ||
                      defaultThemeColor.interface_foreground_text_color,
                  }}
                >
                  {t("Subject")}
                </div>
                <div
                  className={`${Style.secondaryText} mb-4`}
                  style={{
                    color: foregroundTextColor,
                    backgroundColor: foregroundBgColor,
                    minHeight: "3.5em",
                  }}
                >
                  {emailTemplateData?.subject}
                </div>
                <div
                  className={`w-100 ${Style.certificateGenericTemplate} mb-2`}
                  style={{
                    color:
                      useColor("interface_foreground_text_color") ||
                      defaultThemeColor.interface_foreground_text_color,
                  }}
                >
                  {t("Email Template")}
                </div>

                <p
                  className={Style.secondaryText}
                  style={{
                    color: foregroundTextColor,
                    backgroundColor: foregroundBgColor,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: emailTemplateData ? emailTemplateData?.body : null,
                  }}
                ></p>
              </div>
            }

            <div className={`col-12 row mt-3`}>
              {view == "true" ? null : (
                <div className="d-flex flex-wrap gap-3 w-100 px-3 mt-3 align-items-center justify-content-between">
                  {
                    <CancelButton
                      size="small"
                      outlined={true}
                      onClick={() => {
                        navigate(
                          `/email-template-management/edit/${emailTemplateData?.id.toString()}`
                        );
                      }}
                      textColor=""
                      outlineColor=""
                      text={view == "false" ? t("Back") : t("Cancel")}
                      className={``}
                      bg={""}
                    ></CancelButton>
                  }
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default memo(ViewEmailNotification);
