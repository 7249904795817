import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import { toast } from "react-toastify";
import * as yup from "yup";
import Header from "../../components/Header";
import SubmitButton from "../../components/SubmitButton";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import loadOptions, { addGroup } from "../../services/groupManagementService";
import {
  alertMessageSelector,
  setErr,
  setErrors,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import Style from "./DetailsGroup.module.scss";

const schema = yup
  .object({
    name: yup.string().required("Group name is required"),
    user_ids: yup
      .array()
      .min(1, "At least one member must be required")
      .required("Member is required"),
  })
  .required();

function AddGroup() {
  const {
    control,
    setError,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const states = useSelector(alertMessageSelector).alertMessageReducer;

  const { redirectTo } = useParams();

  const Submit = (payload: any) => {
    let ids: any[] = [];
    payload?.user_ids.map((row: any) => {
      ids.push(row.value);
    });
    const payloadData = {
      name: payload.name,
      user_ids: ids,
    };
    dispatch(addGroup(payloadData));
  };

  useEffect(() => {
    if (states?.errors) {
      if (states?.errors?.name?.length > 0) {
        toast.error(states?.errors?.name?.toString(), {
          toastId: states?.errors?.name?.toString(),
        });
        setError("name", {
          type: "server",
          message: states?.errors?.name?.toString(),
        });
      }

      if (states?.errors) {
        dispatch(setErrors(null));
      }
    }
    if (states?.error) {
      toast.error(String(states?.error), {
        toastId: String(states?.error),
      });
      dispatch(setErr(null));
    }
    if (states?.success) {
      toast.success(String(states?.success), {
        toastId: String(states?.success),
      });
      dispatch(setSuccess(null));
      navigate(redirectTo ? "/" + redirectTo : "/group-management");
    }
  }, [states]);

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;
  const interfaceBgColor =
    useColor("interface_background_color") || defaultThemeColor.interface_background_color;

  const primaryButton = useColor("button_primary_text") || defaultThemeColor.button_primary_text;
  const primaryButtonBg = useColor("button_primary") || defaultThemeColor.button_primary;
  return (
    <>
      <div style={{ paddingBottom: "4em" }}>
        <Header
          page={t("Group Management")}
          mainHead={t("Group Management")}
          backLink={redirectTo ? "/" + redirectTo : "/group-management"}
        />
      </div>

      <div
        className="page-start"
        style={{
          background:
            useColor("interface_foreground_color") || defaultThemeColor.interface_foreground_color,
          color:
            useColor("interface_foreground_text_color") ||
            defaultThemeColor.interface_foreground_text_color,
        }}
      >
        <h2
          className="page-heading mb-4"
          style={{
            color:
              useColor("interface_foreground_text_color") ||
              defaultThemeColor.interface_foreground_text_color,
            textAlign: lang === "ar" ? "right" : "left",
          }}
        >
          {t("Add Group")}
        </h2>

        <form onSubmit={handleSubmit(Submit)} autoComplete="off">
          <div className={`d-flex flex-wrap gap-5 mb-3`}>
            <div className={Style.leftSide}>
              <span className="form-label">
                {t("Group Name")}
                <span className="color-red"> *</span>
              </span>
              <input
                className={`form-input ${errors.name ? "is-invalid" : ""}`}
                style={{
                  color:
                    useColor("interface_background_text_color") ||
                    defaultThemeColor.interface_background_text_color,
                  background:
                    useColor("interface_background_color") ||
                    defaultThemeColor.interface_background_color,
                }}
                type="text"
                placeholder={t("Name")}
                autoComplete="off"
                {...register("name")}
              />
              <p className="color-red">{errors.name?.message}</p>
            </div>
          </div>
          <div className={`d-flex flex-wrap gap-5`}>
            <div className={Style.leftSide}>
              <span className="form-label">
                {t("Select Members")}
                <span className="color-red"> *</span>
              </span>
              <Controller
                control={control}
                name="user_ids"
                render={({ field: { value, onChange, ref } }) => (
                  <AsyncPaginate
                    // id="companyId"
                    loadOptions={loadOptions}
                    onChange={onChange}
                    selectRef={ref}
                    value={value}
                    getOptionValue={(option: any) => option.value}
                    getOptionLabel={(option: any) => option.label}
                    className="add-member-select"
                    additional={{
                      page: 1,
                    }}
                    // styles={{
                    //   option: (provided, state) => ({
                    //     ...provided,
                    //     backgroundColor: state.isFocused ? primary_color : "",
                    //     color: state.isFocused ? light_text_color : "",
                    //   }),
                    //   valueContainer: (provided, state) => ({
                    //     ...provided,
                    //     height: "4em",
                    //     overflow: "scroll",
                    //   }),
                    // }}
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused ? interfaceBgColor : "",
                        color: state.isFocused ? interfaceTextColor : "",
                      }),
                      valueContainer: (provided, state) => ({
                        ...provided,
                        // height: "4em",
                        padding: "0.9em",
                        overflow: "auto",
                        backgroundColor: interfaceBgColor,
                        color: interfaceTextColor,
                      }),
                      multiValue: (baseStyles) => ({
                        ...baseStyles,
                        backgroundColor: primaryButtonBg,
                      }),
                      multiValueLabel: (baseStyles) => ({
                        ...baseStyles,
                        color: primaryButton,
                      }),
                      multiValueRemove: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: primaryButtonBg,
                        color: primaryButton,
                        ":hover": {
                          backgroundColor: primaryButtonBg,
                          color: primaryButton,
                        },
                      }),
                    }}
                    isMulti
                    isClearable
                    isSearchable={true}
                    placeholder={t("Select Members")}
                    debounceTimeout={500}
                  />
                )}
              />
              <p className="color-red">{errors.user_ids?.message}</p>
            </div>
          </div>
          <SubmitButton label={t("Save")} />
        </form>
      </div>
    </>
  );
}

export default AddGroup;
