import { useConfirm } from "material-ui-confirm";
import { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../components/Header";
import useCompanyLanguage from "../../../hooks/language";
import { getTraining } from "../../../services/trainingsService";
import { setTrainingData } from "../../../store/reducers/trainingsReducer";
import { RootState, useAppDispatch } from "../../../store/store";
import TrainingLectureSuccessModal from "../TrainingLectureSuccessModal";

function fileToScormURL(url: any) {
  // Get the last part of the URL after the last slash
  const fileNameWithExtension = url.substring(url.lastIndexOf("/") + 1);

  // Get the file name without the extension
  const fileNameWithoutExtension = fileNameWithExtension
    .split(".")
    .slice(0, -1)
    .join(".");
  return (
    process.env.REACT_APP_SCORM_BASE_URL +
    "Content/module/" +
    fileNameWithoutExtension +
    "/index_lms.html"
  );
  console.log(fileNameWithoutExtension);
  const lastDotIndex = url?.lastIndexOf(".");
  // console.log("lastDotIndex", lastDotIndex);
  if (lastDotIndex === -1) {
    return url; // If no dot is found, return the original URL
  }
  return url?.substring(0, lastDotIndex)
    ? url?.substring(0, lastDotIndex) + "/index_lms.html"
    : "";
}
const setLocal = (name: any, value: any) => {
  localStorage.setItem(name, JSON.stringify(value));
};

const getLocal = (name: any) => {
  return JSON.parse(localStorage.getItem(name) || "{}");
};
function ScormStarted() {
  console.log("location.host", window.location.origin.toString());
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const { training_id, module_id } = useParams();
  const userInfo = useSelector((state: any) => state.authReducer.loginData);
  const { resume } = useParams();
  const [scormUrl, setScormUrl] = useState<string>("");
  const [currentModule, setCurrentModule] = useState<any>({});
  const trainingDetail: any = useSelector(
    (state: RootState) => state.trainingsReducer.trainingData
  );
  useEffect(() => {
    const handleMessage = (event: any) => {
      console.log("Message received from iframe:", event?.data);
      // Trigger your desired action based on the received data
      // For example, update UI, call a function, etc.
      if (event?.data == "completed") {
        setScormUrl("");
        setCurrentModule("");
        // navigate(`/training/${training_id}/lectures/${module_id}`);
        dispatch(setTrainingData([]));
        dispatch(getTraining(Number(training_id)));
        // dispatch(
        // updateModuleStatus({
        //   training_id: Number(training_id),
        //   module_id: Number(module_id),
        //   module_status: "Completed",
        // });
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      // Cleanup by removing the event listener when the component unmounts
      window.removeEventListener("message", handleMessage);
    };
  }, []); // Empty dependency array ensures this effect runs only once on component mount

  const isLoading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );

  useEffect(() => {
    dispatch(setTrainingData([]));
    dispatch(getTraining(Number(training_id)));
    // }
  }, [training_id]);

  useEffect(() => {
    if (currentModule?.module?.file) {
      setScormUrl(fileToScormURL(currentModule?.module?.file));
      // window.location.href = `${
      //   process.env.REACT_APP_SCORM_BASE_URL
      // }index.html?courseUrl=${fileToScormURL(
      //   currentModule?.module?.file
      // )}&training_id=${training_id}&module_id=${module_id}&access_token=${localStorage.getItem(
      //   "access_token"
      // )}`;
      // const courseUrl = `http://miltechlms.local/Content/Scorm_Course/Oil_Assessment/index_lms.html`;
      // window.location.href = `http://miltechlms.local/index.html?courseUrl=${courseUrl}&training_id=${training_id}&module_id=${module_id}&access_token=${localStorage.getItem(
      //   "access_token"
      // )}`;
      // getData();
    } else {
      setScormUrl("");
    }
    // dispatch(
    //   updateModuleStatus({
    //     training_id: Number(training_id),
    //     module_id: Number(module_id),
    //     module_status: "InProgress",
    //   })
    // );
  }, [currentModule]);

  useEffect(() => {
    const current_module = trainingDetail?.ordered_modules?.find(function (
      row: any
    ) {
      return parseInt(row?.module?.id) === Number(module_id);
    });
    setCurrentModule(current_module);
    console.log("currentModule", current_module);
  }, [trainingDetail]);

  return (
    <div className="overflow-auto">
      <Header
        page={isLoading ? "" : trainingDetail?.name}
        mainHead={t("Trainings")}
        backLink={`/training/${training_id}/lectures/${module_id}`}
      />
      {/* <p>SCORM View</p>
      <p>
        <strong>URL: </strong>
        {currentModule?.module?.file}
        <br />
        {process.env.REACT_APP_SCORM_BASE_URL}
        <br />
        {scormUrl}
      </p> */}
      {scormUrl &&
      currentModule?.module?.module_status &&
      training_id &&
      module_id ? (
        <iframe
          // src="http://tushar1.com/Content/PLAC_SCORM_2004/res/index.html"
          // src={`${window.location.origin.toString()}/load-page?courseUrl=${scormUrl}&courseId=${
          //   userInfo?.id + "-" + training_id + "-" + module_id
          // }&type=iSpring`}
          style={{ width: "100%", height: "700px" }}
          className="main-iframe"
          src={`${
            process.env.REACT_APP_SCORM_BASE_URL
          }index.html?module_status=${
            currentModule?.module?.module_status
          }&training_id=${training_id}&module_id=${module_id}&courseUrl=${scormUrl}&access_token=${localStorage.getItem(
            "access_token"
          )}`}
          // src={`http://tushar1.com/index.html?courseUrl=${url}&courseId=${courseId}&type=${type}&token=${token}`}
          // src="https://tushar1.com/index.html?courseUrl=${url}&courseId=1234&type=iSpring`}"
          // src={`https://api-miltech.invo.zone/media/admin/module/20240229053022_Airport_Process_1/index_lms.html`}
          width={1080}
          height={800}
        />
      ) : null}
      {trainingDetail?.compulsory_completed &&
        Object.keys(trainingDetail?.certificate).length > 0 &&
        !trainingDetail?.is_certificate_viewed && (
          <TrainingLectureSuccessModal trainingDetail={trainingDetail} />
        )}
    </div>
  );
}

export default ScormStarted;
