function ScormSVG(props: { color: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="21" viewBox="0 0 24 24" width="21">
      <g fill="#2B3674">
        <path d="m20.9 4h-17.8c-1.7 0-3.1 1.4-3.1 3.1 0 .9.4 1.8 1.1 2.4v7.4c0 2.2 1.9 4.1 4.2 4.1h13.3c2.3 0 4.2-1.9 4.2-4.2v-7.3c.8-.6 1.2-1.5 1.2-2.4 0-1.7-1.4-3.1-3.1-3.1zm0 12.8c0 1.2-1 2.2-2.2 2.2h-13.4c-1.2 0-2.2-1-2.2-2.2v-6.6h17.8zm0-8.6h-17.8c-.6 0-1.1-.5-1.1-1.1s.5-1.1 1.1-1.1h17.8c.6 0 1.1.5 1.1 1.1s-.5 1.1-1.1 1.1z" />
        <path d="m10 16.2h-4c-.6 0-1-.4-1-1v-2c0-.6.4-1 1-1h4c.6 0 1 .4 1 1v2c0 .5-.4 1-1 1z" />
      </g>
    </svg>
  );
}

export default ScormSVG;
