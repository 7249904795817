import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import Header from "../../components/Header";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import { getDepartment } from "../../services/departmentService";
import {
  alertMessageSelector,
  setErr,
  setErrors,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import DetailList from "./DetailList";
import MemberList from "./MemberList";

const schema = yup
  .object({
    name: yup.string().required("Name is required"),
  })
  .required();

function DepartmentDetail() {
  const { id } = useParams();

  const {
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;
  const page = useSelector((state: RootState) => state.departmentReducer.page);
  const perPage = useSelector(
    (state: RootState) => state.departmentReducer.perPage
  );
  const search = useSelector(
    (state: RootState) => state.departmentReducer.search
  );
  const detail = useSelector(
    (state: RootState) => state.departmentReducer.detailData
  );

  const urlWithQueryString = () => {
    let object: any = {};
    if (page) {
      object.page = page;
    }
    if (perPage) {
      object.perpage = perPage;
    }
    if (
      search !== null &&
      search !== "" &&
      search !== undefined &&
      search !== "null"
    ) {
      object.search = search;
    }

    const objString = "?" + new URLSearchParams(object).toString();

    navigate(`/department-management${objString}`);
  };
  useEffect(() => {
    dispatch(getDepartment(Number(id)));
  }, [id]);

  useEffect(() => {
    if (alertMessage?.errors) {
      if (alertMessage?.errors?.name?.length > 0) {
        setError("name", {
          type: "server",
          message: alertMessage?.errors?.name?.toString(),
        });
      }

      if (alertMessage?.errors) {
        dispatch(setErrors(null));
      }
    }
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      urlWithQueryString();
    }
  }, [alertMessage]);

  const arabicFonts = {
    fontFamily:
      lang === "ar" ? `"Arial", "Times New Roman", sans-serif` : "dmSans",
  };

  return (
    <>
      <div style={{ paddingBottom: "4em" }}>
        <Header
          page={t("Department Management")}
          mainHead={t("Department Management")}
          backLink="/department-management"
        />
      </div>

      <div
        className="page-start"
        style={{
          background:
            useColor("interface_foreground_color") ||
            defaultThemeColor.interface_foreground_color,
          color:
            useColor("interface_foreground_text_color") ||
            defaultThemeColor.interface_foreground_text_color,
        }}
      >
        <h2
          style={{
            ...arabicFonts,
          }}
        >
          {t("Department Detail")}
        </h2>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>{t("Name")}</th>
              <th>{t("No of members")}</th>
              <th>{t("Created")}</th>
            </tr>
          </thead>
          <tbody>
            <DetailList row={detail} count={1} num={1} />
          </tbody>
        </table>
        <h2
          style={{
            ...arabicFonts,
          }}
        >
          {t("Department Members")}
        </h2>
        <MemberList />
      </div>
    </>
  );
}

export default DepartmentDetail;
