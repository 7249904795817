import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DataTable from "../../components/table/DataTable";
import TableFilters from "../../components/table/TableFilters";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import DeleteSVG from "../../icons/DeleteSVG";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import { confirmProps } from "../../utils/confirmProps";
import CreateQuestionModal from "./CreateQuestionModal";

function AssessmentQuestion({
  id,
  setAssessmentQuestionsParent,
  questions,
  setOpenCreateNewQuestionModal,
  openCreateNewQuestionModal,
}: {
  id: any;
  setAssessmentQuestionsParent: any;
  questions: any;
  setOpenCreateNewQuestionModal: any;
  openCreateNewQuestionModal: boolean;
}) {
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;
  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  const [page, setPage] = useState<number>(1);
  const [itemPerPage, setItemPerPage] = useState<number>(10);
  const [searchTable, setSearchTable] = useState<string>("");
  const [rows, setRows] = useState([]);
  const [list, setList] = useState<any>();
  const [open, setOpen] = useState(false);
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<any[]>([]);
  const [assessmentQuestions, setAssessmentQuestions] = React.useState<any[]>(
    []
  );
  const [
    parentCheckedButNotAddedQuestions,
    setParentCheckedButNotAddedQuestions,
  ] = React.useState<any[]>([]);

  useEffect(() => {
    const res: any = assessmentQuestions.filter(
      (obj: any) =>
        !obj.question_text.toLowerCase().search(searchTable.toLowerCase())
    );
    setRows(res);
  }, [searchTable]);

  useEffect(() => {
    if (questions && questions.length > 0) {
      setAssessmentQuestions(questions);
      if (!(questions.length == parentCheckedButNotAddedQuestions.length))
        setParentCheckedButNotAddedQuestions(questions);
    }
  }, [questions]);

  useEffect(() => {
    let data: any = assessmentQuestions;
    if (Object.keys(data)?.length === 0) {
      setRows([]);
    } else setRows(data);
    setAssessmentQuestionsParent(assessmentQuestions);
    if (!(itemPerPage == 10 || itemPerPage == 15 || itemPerPage == 25)) {
      setItemPerPage(assessmentQuestions?.length);
    }
  }, [assessmentQuestions]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
    }
  }, [alertMessage]);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setSelectedCheckBox([]);
    setPage(page);
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPage(1);
    setSelectedCheckBox([]);
    setItemPerPage(Number(rowsPerPage));
  };

  useEffect(() => {
    modifyRows();
  }, [rows]);

  const modifyRows = () => {
    let f_rows: any = [];
    rows?.forEach(
      (
        r: {
          id: string;
          question_text: string;
          category: any;
          question_type: string;
          question_status: string;
          created_at: string;
          updated_at: string;
        },
        i: number
      ) => {
        f_rows = [
          ...f_rows,
          {
            id: r?.id,
            question: <div style={{ minWidth: "19em" }}>{r.question_text}</div>,
            type: r.question_type == "TF" ? "T/F" : "MCQs",
            category: r?.category?.name || "N/A",
            actions: (
              <div style={{ minWidth: "13em" }}>
                <button
                  className="btn-link"
                  type="button"
                  onClick={() => {
                    handleDeleteAction(r);
                  }}
                >
                  <DeleteSVG color="red" />
                </button>
              </div>
            ),
          },
        ];
      }
    );
    setList(f_rows);
  };

  const handleDeleteAction = (row: any) => {
    return confirm({
      title: t("Are you sure!"),
      description: t(`Do you want to remove this question?`),
      ...confirmProps,
    })
      .then(() => {
        setParentCheckedButNotAddedQuestions(
          parentCheckedButNotAddedQuestions.filter(
            (i: any) => !(i.id == row.id)
          )
        );
        setAssessmentQuestions(
          assessmentQuestions.filter((i: any) => !(i.id == row.id))
        );
      })
      .catch(() => {});
  };

  const handleClose = (open: any) => {
    setOpen(open);
  };

  useEffect(() => {
    if (assessmentQuestions && assessmentQuestions.length > 0) {
      setParentCheckedButNotAddedQuestions(assessmentQuestions || []);
    }
  }, [openCreateNewQuestionModal, open]);

  const AddQuestion = () => {
    const handleAddNew = () => {
      setOpen(true);
    };

    return (
      <div className="d-flex flex-row gap-3">
        <>
          <Button
            variant="text"
            color="inherit"
            endIcon={
              <AddIcon
                style={{
                  fontSize: 16,
                  color:
                    useColor("interface_foreground_text_color") ||
                    defaultThemeColor.interface_foreground_text_color,
                  marginRight: lang === "ar" ? "10px" : "0px",
                }}
              />
            }
            style={{
              fontSize: 13,
              color:
                useColor("interface_foreground_text_color") ||
                defaultThemeColor.interface_foreground_text_color,
              textTransform: "capitalize",
            }}
            onClick={() => handleAddNew()}
          >
            {t("Add New")}
          </Button>
        </>
      </div>
    );
  };

  const handleClicked = () => {};

  return (
    <>
      <TableFilters
        dataList={{
          count: list && list?.results ? list?.results?.length : list?.length,
        }}
        itemPerPage={itemPerPage}
        setItemPerPage={setItemPerPage}
        selectedCheckBox={selectedCheckBox}
        bulkDelete={() => {}}
        setSearchTable={setSearchTable}
        createButton={<AddQuestion />}
        handle="groups"
        isCheckboxes={false}
        exportCSV={
          id
            ? list && list?.results && list?.results?.length
              ? true
              : false
            : false
        }
        setParentData={() => {}}
        handleClicked={handleClicked}
        rows={list?.results?.map((r: any, i: any) => ({
          question: <div style={{ minWidth: "19em" }}>{r.question_text}</div>,
          type: r.question_type == "TF" ? "T/F" : "MCQs",
          category: r?.category?.name || "N/A",
        }))}
        headers={["Name", "Associated To", "Date Created"]}
        filename={`assessment_questions.csv`}
      />

      <DataTable
        columnData={[
          {
            id: "question",
            name: t("Questions"),
            enableSort: true,
            align: lang === "ar" ? "center" : "left",
            disablePadding: "none",
          },
          {
            id: "type",
            name: t("Type"),
            enableSort: true,
            align: lang === "ar" ? "center" : "left",
            disablePadding: "none",
          },
          {
            id: "category",
            name: t("Category"),
            enableSort: true,
            align: lang === "ar" ? "center" : "left",
            disablePadding: "none",
          },

          {
            id: "actions",
            name: t("Actions"),
            enableSort: false,
            align: "center",
            disablePadding: "none",
          },
        ]}
        rows={
          list ? list?.slice((page - 1) * itemPerPage, page * itemPerPage) : []
        }
        isCheckboxes={false}
        selectedRowsPerPage={itemPerPage?.toString()}
        totalRecordCount={
          list && list?.results ? list?.results?.length : list?.length
        }
        handleChangePageParent={handleChangePage}
        pagenum={Number(page)}
        handleChangeRowsPerPageParent={handleChangeRowsPerPage}
        bulkDelete={() => {}}
        selectedCheckBox={selectedCheckBox}
        setSelectedCheckBox={setSelectedCheckBox}
        showLoader={false}
      />

      <CreateQuestionModal
        setParentOpen={setOpen}
        open={open}
        onClose={handleClose}
        isLoading={loading}
        setParentCheckedQuestions={setAssessmentQuestions}
        parentCheckedQuestions={assessmentQuestions}
        setParentCheckedButNotAddedQuestions={
          setParentCheckedButNotAddedQuestions
        }
        parentCheckedButNotAddedQuestions={parentCheckedButNotAddedQuestions}
        setOpenCreateNewQuestionModal={setOpenCreateNewQuestionModal}
        openCreateNewQuestionModal={openCreateNewQuestionModal}
      />
    </>
  );
}

export default AssessmentQuestion;
