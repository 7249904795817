import { CircularProgress } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useConfirm } from "material-ui-confirm";
import React from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import Paginations from "../../../components/table/Paginations";
import useCompanyLanguage from "../../../hooks/language";
import useColor from "../../../hooks/useColor";
import usePermission from "../../../hooks/usePermission";
import DeleteSVG from "../../../icons/DeleteSVG";
import { deleteImage } from "../../../services/imageLibraryService";
import { RootState, useAppDispatch } from "../../../store/store";
import { defaultThemeColor } from "../../../utils/colors_values";
import { confirmProps } from "../../../utils/confirmProps";
import Style from "../ImageLibrary.module.scss";
import ViewImage from "./ViewImage";

interface IDataTableColumn {
  id: string;
  name: string;
}

interface IDataTableProps {
  rows: any[];
  columnData?: IDataTableColumn[];
  selectedRowsPerPage: number;
  totalRecordCount: number;
  pagenum: number;
  handleAction: any;
  handleChangePageParent: any;
  handleChangeRowsPerPageParent: any;
  selectedCheckBox: any;
  setSelectedCheckBox: any;
}

const GridCover: React.FC<IDataTableProps> = ({
  columnData,
  rows,
  selectedRowsPerPage,
  pagenum,
  totalRecordCount,
  handleAction,
  handleChangePageParent,
  handleChangeRowsPerPageParent,
  selectedCheckBox,
  setSelectedCheckBox,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const deletePermission = usePermission("delete_image");
  let internalColumnData: IDataTableColumn[] = [
    {
      id: "",
      name: "",
    },
  ];
  if (!columnData) {
    if (rows.length) {
      internalColumnData.length = 0;
      Object.keys(rows[0]).map((key) => {
        internalColumnData.push({
          id: String(key),
          name: String(key),
        });
      });
    }
  } else {
    internalColumnData = columnData;
  }

  const [selected, setSelected] = React.useState<readonly string[]>([]);

  const [openImage, setOpenImage] = React.useState(false);
  const [image, setImage] = React.useState("");
  const [imageName, setImageName] = React.useState("");

  const isLoading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );

  const handleCloseImage = () => {
    setOpenImage(false);
  };

  const handleDeleteAction = (row: any) => {
    confirm({
      title: "Are you sure!",
      description: `Do you want to delete "${row?.filename || "file"}" image?`,
      ...confirmProps,
    })
      .then(() => {
        dispatch(deleteImage(row.id));
      })
      .catch(() => {});
    return;
  };

  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;
  const foregroundBgColor =
    useColor("interface_foreground_color") ||
    defaultThemeColor.interface_foreground_color;

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelectedCheckBox(newSelected);
      return;
    }
    setSelectedCheckBox([]);
  };

  const handleClick = (event: any, id: any) => {
    if (event.target.checked) {
      setSelectedCheckBox([
        ...(selectedCheckBox?.map((r: any) => r) ?? []),
        id,
      ]);
    } else {
      setSelectedCheckBox(
        selectedCheckBox?.filter(function (val: any) {
          return val !== id;
        })
      );
    }
  };

  return (
    <React.Fragment>
      <div className={Style.root}>
        <div className={`row g-0`}>
          {isLoading ? (
            <span
              className={`col-lg-12 d-flex justify-content-center`}
              style={{
                padding: 0,
                margin: "14px",
                marginLeft: 0,
                marginRight: 30,
              }}
            >
              <CircularProgress size="3em" color="primary" />
            </span>
          ) : rows?.length > 0 ? (
            rows.map((row, index) => {
              return (
                <div key={index} className={`col-lg-3 mb-2 p-3`}>
                  <div key={index} className={`${Style.wholeCover}`}>
                    {deletePermission && (
                      <Checkbox
                        color="primary"
                        value={row}
                        checked={selectedCheckBox?.includes(row?.id)}
                        onChange={(e) => {
                          handleClick(e, row?.id);
                        }}
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                      />
                    )}

                    <div
                      className={Style.deleteButtonGridView}
                      style={{ background: foregroundBgColor }}
                    >
                      <button
                        className="btn-link"
                        type="button"
                        onClick={(e) => {
                          handleDeleteAction(row);
                        }}
                      >
                        <DeleteSVG color="red" />
                      </button>
                    </div>
                    <div
                      className={`d-flex justify-content-center align-items-center p-2 ${Style.backgroundimg}`}
                      style={{
                        background: interfaceBgColor,
                      }}
                    >
                      <img
                        src={row.imageSrcLibrary}
                        alt={row.filename}
                        onClick={() => {
                          setOpenImage(true);
                          setImage(row.imageSrcLibrary);
                          setImageName(row.filename);
                        }}
                      />
                    </div>
                    <div
                      className={`d-flex flex-wrap justify-content-between align-items-center gap-4 p-3`}
                    >
                      <div className={Style.widthCover}>
                        <div
                          className={`${Style.header}`}
                          style={{
                            color: interfaceTextColor,
                          }}
                        >
                          {t("File name")}:
                        </div>
                        <div
                          className={`${Style.val}`}
                          style={{
                            color: interfaceTextColor,
                            display: "flex",
                            maxWidth: "150px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {t(row.filename)}
                        </div>
                      </div>
                      <div>
                        <div
                          className={`${Style.header}`}
                          style={{ color: interfaceTextColor }}
                        >
                          {t("Date created")}:
                        </div>
                        <div
                          className={`${Style.val}`}
                          style={{ color: interfaceTextColor }}
                        >
                          {t(row.created_at)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                textAlign: "center",
                background: "rgb(255, 255, 255)",
                height: "50px",
              }}
            >
              <p style={{ paddingTop: "15px" }}>Record not found</p>
            </div>
          )}
          {/* <TablePaginationFull
            pageSize={rowsPerPage}
            pageIndex={page}
            data={rows || []}
            totalRecordCount={totalRecordCount}
            setPageIndex={setPage}
            setPageSize={setRowsPerPage}
          /> */}
          {rows.length > 0 && (
            <Paginations
              totalCount={Number(totalRecordCount)}
              perpage={Number(selectedRowsPerPage)}
              pagenum={Number(pagenum)}
              handleChangePageNum={handleChangePageParent}
              handleChangeRowsPerPageParent={handleChangeRowsPerPageParent}
            />
          )}
        </div>
        <ViewImage
          setParentOpen={setOpenImage}
          open={openImage}
          onClose={handleCloseImage}
          image={image}
          imageName={imageName}
        />
      </div>
    </React.Fragment>
  );
};

export default GridCover;
