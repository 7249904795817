/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import "yup-phone-lite";
import Header from "../../components/Header";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import { addAdmin } from "../../services/adminUserService";
import {
  alertMessageSelector,
  setErr,
  setErrors,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { RootState, useAppDispatch, useAppSelector } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import Style from "./AddAdmin.module.scss";

let EMAIL_REGX: any = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/;
let EMAIL_REGX_ERROR: any = /^[a-zA-Z0-9_.+-]+$/;

const schema = yup
  .object({
    first_name: yup
      .string()
      .matches(
        /[a-zA-Z][a-zA-Z ]+[a-zA-Z]$/,
        "Only allow alphabets with whitespace"
      )
      .required("First name is required"),
    last_name: yup
      .string()
      .matches(
        /[a-zA-Z][a-zA-Z ]+[a-zA-Z]$/,
        "Only allow alphabets with whitespace"
      )
      .required("Last name is required"),
    email: yup
      .string()
      .matches(EMAIL_REGX, "Invalid email address")
      .required("Email is required"),
    phone: yup
      .string()
      .phone(
        "US",
        "Phone is invalid. Please enter with country code like: +112345678910"
      )
      .required("Phone number is required"),
    password: yup
      .string()
      .min(8, "Password must contain 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must contain 8 characters, One uppercase, one lowercase, One number and one special case character"
      )
      .required("Password is required"),
    confirm_password: yup
      .string()
      .min(8, "Confirm password must contain 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must contain 8 characters, One uppercase, one lowercase, One number and one special case character"
      )
      .required("Confirm Password is required")
      .oneOf([yup.ref("password"), ""], "Confirm Password does not match"),
  })
  .required();

function AddAdmin() {
  const {
    watch,
    setValue,
    trigger,
    control,
    setError,
    clearErrors,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const alertMessage = useAppSelector(alertMessageSelector).alertMessageReducer;

  const [phoneValue, setPhoneValue] = useState<any>("");
  const [fieldTypePassword, setFieldTypePassword] = useState("password");
  const [fieldTypeConfirmPassword, setFieldTypeConfirmPassword] =
    useState("password");

  const isLoading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );

  useEffect(() => {
    if (watch("email")) {
      trigger("email");
    }
  }, [watch("email")]);

  const userSubmit = (payload: any) => {
    payload.role = "Admin";
    dispatch(addAdmin(payload));
  };

  useEffect(() => {
    if (watch("phone")) {
      clearErrors("phone");
    }
  }, [watch("phone")]);

  useEffect(() => {
    if (alertMessage?.errors) {
      if (alertMessage?.errors?.first_name?.length > 0) {
        setError("first_name", {
          type: "server",
          message: alertMessage?.errors?.first_name?.toString(),
        });
      }
      if (alertMessage?.errors?.last_name?.length > 0) {
        setError("last_name", {
          type: "server",
          message: alertMessage?.errors?.last_name?.toString(),
        });
      }
      if (alertMessage?.errors?.email?.length > 0) {
        setError("email", {
          type: "server",
          message: alertMessage?.errors?.email?.toString(),
        });
      }
      if (alertMessage?.errors?.phone?.length > 0) {
        setError("phone", {
          type: "server",
          message: alertMessage?.errors?.phone?.toString(),
        });
      }
      if (alertMessage?.errors?.password?.length > 0) {
        setError("password", {
          type: "server",
          message: alertMessage?.errors?.password?.toString(),
        });
      }
      if (alertMessage?.errors?.password2?.length > 0) {
        setError("confirm_password", {
          type: "server",
          message: alertMessage?.errors?.password2?.toString(),
        });
      }
      if (alertMessage?.errors) {
        dispatch(setErrors(null));
      }
    }
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      navigate("/admin-list");
    }
  }, [alertMessage]);

  useEffect(() => {
    if (phoneValue) {
      setValue("phone", "+" + String(phoneValue));
    } else {
      setValue("phone", "");
    }
  }, [phoneValue, setValue]);

  const showHidePassword = () => {
    if (fieldTypePassword === "password") {
      setFieldTypePassword("text");
    } else {
      setFieldTypePassword("password");
    }
  };

  const showHideConfirmPassword = () => {
    if (fieldTypeConfirmPassword === "password") {
      setFieldTypeConfirmPassword("text");
    } else {
      setFieldTypeConfirmPassword("password");
    }
  };

  const foregroundBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;

  const foregroundTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  return (
    <>
      <div
        className={`${Style.background}`}
        style={{
          background:
            useColor("interface_background_color") ||
            defaultThemeColor.interface_background_color,
        }}
      >
        <div style={{ paddingBottom: "4em" }}>
          <Header
            page={t("Add Admin")}
            mainHead={t("Admin List")}
            backLink="/admin-list"
          />
        </div>

        <div>
          <div
            style={{
              background:
                useColor("interface_foreground_color") ||
                defaultThemeColor.interface_foreground_color,
              color:
                useColor("interface_foreground_text_color") ||
                defaultThemeColor.interface_foreground_text_color,
              borderRadius: 10,
              minHeight: "45em",
            }}
          >
            <div className="p-5">
              <div
                className={`${Style.textHeader}`}
                style={{
                  color:
                    useColor("interface_foreground_text_color") ||
                    defaultThemeColor.interface_foreground_text_color,
                  textAlign: lang === "ar" ? "right" : "left",
                }}
              >
                {t("Add Admin")}
              </div>

              <form onSubmit={handleSubmit(userSubmit)} autoComplete="off">
                <div className={`row col-lg-12 px-0 mx-0`}>
                  <div
                    className={`col-lg-6 px-0 ${Style.widthOptimize}`}
                    style={{
                      marginTop: "2em",
                      marginRight: lang === "ar" ? "0rem" : "2.3em",
                    }}
                  >
                    <span className={Style.labelHeader}>
                      {t("First Name")}
                      <span style={{ color: "red" }}> *</span>
                    </span>
                    <input
                      className={`${Style.inputfields} ${Style.fieldFont} ${
                        errors.first_name ? "is-invalid" : ""
                      }`}
                      style={{
                        color:
                          useColor("interface_background_text_color") ||
                          defaultThemeColor.interface_background_text_color,
                        background:
                          useColor("interface_background_color") ||
                          defaultThemeColor.interface_background_color,
                        border: "none",
                        outline: "none",
                      }}
                      type="text"
                      placeholder={t("First Name")}
                      autoComplete="off"
                      {...register("first_name")}
                    />
                    <p className="color-red">{errors.first_name?.message}</p>
                  </div>
                  <div
                    className={`col-lg-6 px-0 ${
                      lang === "ar" ? "mx-4" : "mx-0"
                    } ${Style.widthOptimize}`}
                    style={{
                      marginTop: "2em",
                    }}
                  >
                    <span className={Style.labelHeader}>
                      {t("Last Name")}
                      <span style={{ color: "red" }}> *</span>
                    </span>
                    <input
                      className={`${Style.inputfields} ${Style.fieldFont} ${
                        errors.last_name ? "is-invalid" : ""
                      }`}
                      style={{
                        color:
                          useColor("interface_background_text_color") ||
                          defaultThemeColor.interface_background_text_color,
                        background:
                          useColor("interface_background_color") ||
                          defaultThemeColor.interface_background_color,
                        border: "none",
                        outline: "none",
                      }}
                      type="text"
                      placeholder={t("Last Name")}
                      autoComplete="off"
                      {...register("last_name")}
                    />
                    <p className="color-red">{errors.last_name?.message}</p>
                  </div>
                </div>

                <div className={`row col-lg-12 px-0 mx-0`}>
                  <div
                    className={`col-lg-6 px-0 ${Style.widthOptimize}`}
                    style={{
                      marginTop: "2em",
                      marginRight: lang === "ar" ? "0rem" : "2.3em",
                    }}
                  >
                    <span className={Style.labelHeader}>
                      {t("Email Address")}
                      <span style={{ color: "red" }}> *</span>
                    </span>
                    <input
                      className={`${Style.inputfields} ${Style.fieldFont} ${
                        errors.email ? "is-invalid" : ""
                      }`}
                      style={{
                        color:
                          useColor("interface_background_text_color") ||
                          defaultThemeColor.interface_background_text_color,
                        background:
                          useColor("interface_background_color") ||
                          defaultThemeColor.interface_background_color,
                        border: "none",
                        outline: "none",
                      }}
                      placeholder={t("Email Address")}
                      autoComplete="off"
                      {...register("email")}
                    />
                    <p className="color-red">
                      {watch("email") && watch("email")?.match(EMAIL_REGX_ERROR)
                        ? "Valid email required"
                        : errors.email?.message}
                    </p>{" "}
                  </div>
                  <div
                    className={`col-lg-6 px-0 ${
                      lang === "ar" ? "mx-4" : "mx-0"
                    } ${Style.widthOptimize}`}
                    style={{
                      marginTop: "2em",
                    }}
                  >
                    <span className={Style.labelHeader}>
                      {t("Phone no")}.<span style={{ color: "red" }}> *</span>
                    </span>
                    <label
                      className={`w-100 countryPhone ${Style.phoneLabel} ${Style.input}  ${Style.minWidthinput}`}
                      style={{ direction: lang === "ar" ? "ltr" : "unset" }}
                    >
                      <Controller
                        control={control}
                        name="phone"
                        rules={{ required: true }}
                        render={({ field: { ref, ...field } }) => (
                          <PhoneInput
                            {...field}
                            country={"us"}
                            enableSearch={true}
                            value={phoneValue}
                            onChange={setPhoneValue}
                            countryCodeEditable={false}
                            inputClass={`${errors.phone ? "is-invalid" : ""}`}
                            inputStyle={{
                              background: foregroundBgColor,
                              color: foregroundTextColor,
                            }}
                            placeholder={t("Enter phone number")}
                            defaultErrorMessage={t("Invalid Phone Number.")}
                            isValid={(value, country: any) => {
                              if (value.match(/12345/)) {
                                return (
                                  "Invalid phone number: " +
                                  value +
                                  ", " +
                                  country.name
                                );
                              } else if (value.match(/1234/)) {
                                return false;
                              } else {
                                return true;
                              }
                            }}
                            inputProps={{
                              name: "phone",
                              required: true,
                              autoFocus: false,
                            }}
                          />
                        )}
                      />

                      <p className="color-red font400">
                        <ErrorMessage errors={errors} name="phone" />
                      </p>
                    </label>
                  </div>
                </div>

                <div className={`row col-lg-12 px-0 mx-0`}>
                  <div
                    className={`col-lg-6 px-0 ${Style.widthOptimize}`}
                    style={{
                      marginTop: "2em",
                      marginRight: lang === "ar" ? "0rem" : "2.3em",
                    }}
                  >
                    <span className={Style.labelHeader}>
                      {t("Password")}
                      <span style={{ color: "red" }}> *</span>
                    </span>
                    <div className="position-relative">
                      <input
                        id="password"
                        className={`${Style.inputfields} ${Style.fieldFont} ${
                          errors.password ? "is-invalid" : ""
                        }`}
                        style={{
                          color:
                            useColor("interface_background_text_color") ||
                            defaultThemeColor.interface_background_text_color,
                          background:
                            useColor("interface_background_color") ||
                            defaultThemeColor.interface_background_color,
                          border: "none",
                          outline: "none",
                          paddingRight: lang === "ar" ? "3rem" : "1rem",
                        }}
                        placeholder={t("Password")}
                        type={fieldTypePassword}
                        {...register("password")}
                        autoComplete="new-password"
                      />
                      <button
                        className="btn eye-btn"
                        type="button"
                        onClick={showHidePassword}
                      >
                        {fieldTypePassword === "password" ? (
                          <VisibilityOutlined />
                        ) : (
                          <VisibilityOffOutlined />
                        )}
                      </button>
                    </div>
                    <p className="color-red">{errors.password?.message}</p>
                  </div>
                  <div
                    className={`col-lg-6 px-0 ${
                      lang === "ar" ? "mx-4" : "mx-0"
                    } ${Style.widthOptimize}`}
                    style={{
                      marginTop: "2em",
                    }}
                  >
                    <span className={Style.labelHeader}>
                      {t("Confirm Password")}
                      <span style={{ color: "red" }}> *</span>
                    </span>
                    <div className="position-relative">
                      <input
                        id="confirm_password"
                        className={`${Style.inputfields} ${Style.fieldFont} ${
                          errors.confirm_password ? "is-invalid" : ""
                        }`}
                        style={{
                          color:
                            useColor("interface_background_text_color") ||
                            defaultThemeColor.interface_background_text_color,
                          background:
                            useColor("interface_background_color") ||
                            defaultThemeColor.interface_background_color,
                          border: "none",
                          outline: "none",
                          paddingRight: lang === "ar" ? "3rem" : "1rem",
                        }}
                        placeholder={t("Confirm Password")}
                        type={fieldTypeConfirmPassword}
                        {...register("confirm_password")}
                        autoComplete="new-confirm-password"
                      />
                      <button
                        className="btn eye-btn"
                        type="button"
                        onClick={showHideConfirmPassword}
                      >
                        {fieldTypeConfirmPassword === "password" ? (
                          <VisibilityOutlined />
                        ) : (
                          <VisibilityOffOutlined />
                        )}
                      </button>
                    </div>
                    <p className="color-red">
                      {errors.confirm_password?.message}
                    </p>
                  </div>
                </div>

                <label id="message" className="error"></label>

                <div
                  className="d-flex px-0 mx-0 mt-5 w-100"
                  style={{
                    justifyContent: lang === "ar" ? "flex-start" : "flex-end",
                  }}
                >
                  <button
                    className={`btn ${
                      useColor("button_primary") ||
                      defaultThemeColor.button_primary
                    } ${Style.loginBtn} ${Style.mt24}`}
                    style={{
                      background:
                        useColor("button_primary") ||
                        defaultThemeColor.button_primary,
                      color:
                        useColor("button_primary_text") ||
                        defaultThemeColor.button_primary_text,
                    }}
                    type="submit"
                    disabled={isLoading}
                  >
                    <span className={`${Style.alignItemsInside}`}>
                      <span
                        className={`p-1 ${Style.hThree} ${Style.buttonFont} ${Style.white}`}
                        style={{
                          background:
                            useColor("button_primary") ||
                            defaultThemeColor.button_primary,
                          color:
                            useColor("button_primary_text") ||
                            defaultThemeColor.button_primary_text,
                        }}
                      >
                        {t("Save")}
                      </span>
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddAdmin;
