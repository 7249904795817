// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TableFilters_tableFiltersStart__9JQsi .TableFilters_removeBtn__JW76F {
  border: 1px solid #ff0000;
  color: #ff0000;
  border-radius: 36px;
  padding: 0px 8px;
  width: 115px;
  height: 35px;
  text-transform: capitalize;
}
.TableFilters_tableFiltersStart__9JQsi .TableFilters_removeBtn__JW76F:hover {
  border: 1px solid #ff0000;
  color: #ff0000;
}
.TableFilters_tableFiltersStart__9JQsi .TableFilters_disabledBorder__nbvQo {
  border: 1px solid rgba(0, 0, 0, 0.26);
  color: rgba(0, 0, 0, 0.26);
  border-radius: 36px;
  padding: 0px 8px;
  width: 115px;
  height: 35px;
  text-transform: capitalize;
}
.TableFilters_tableFiltersStart__9JQsi .TableFilters_disabledBorder__nbvQo:hover {
  border: 1px solid grey;
  color: grey;
}`, "",{"version":3,"sources":["webpack://./src/components/table/TableFilters.module.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;EACA,cAAA;EACA,mBAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;EACA,0BAAA;AAAJ;AAEI;EACE,yBAAA;EACA,cAAA;AAAN;AAIE;EACE,qCAAA;EACA,0BAAA;EACA,mBAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;EACA,0BAAA;AAFJ;AAII;EACE,sBAAA;EACA,WAAA;AAFN","sourcesContent":[".tableFiltersStart {\n  .removeBtn {\n    border: 1px solid #ff0000;\n    color: #ff0000;\n    border-radius: 36px;\n    padding: 0px 8px;\n    width: 115px;\n    height: 35px;\n    text-transform: capitalize;\n\n    &:hover {\n      border: 1px solid #ff0000;\n      color: #ff0000;\n    }\n  }\n\n  .disabledBorder {\n    border: 1px solid rgba(0, 0, 0, 0.26);\n    color: rgba(0, 0, 0, 0.26);\n    border-radius: 36px;\n    padding: 0px 8px;\n    width: 115px;\n    height: 35px;\n    text-transform: capitalize;\n\n    &:hover {\n      border: 1px solid grey;\n      color: grey;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableFiltersStart": `TableFilters_tableFiltersStart__9JQsi`,
	"removeBtn": `TableFilters_removeBtn__JW76F`,
	"disabledBorder": `TableFilters_disabledBorder__nbvQo`
};
export default ___CSS_LOADER_EXPORT___;
