import { useTranslation } from "react-multi-lang";
import Header from "../../components/Header";
const NotAuthorizedPage = () => {
  const t = useTranslation();
  return (
    <>
      <div style={{ paddingBottom: "0.6em" }}>
        <Header page={t("Unauthorized")} mainHead={t("Unauthorized")} />
        <h1>401: {t("Unauthorized")} </h1>
      </div>
    </>
  );
};

export default NotAuthorizedPage;
