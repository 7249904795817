// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Category_categoryStart__khNSF {
  width: 100%;
  overflow: auto !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/category/Category.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,yBAAA;AACF","sourcesContent":[".categoryStart {\n  width: 100%;\n  overflow: auto !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"categoryStart": `Category_categoryStart__khNSF`
};
export default ___CSS_LOADER_EXPORT___;
