import { toast } from "react-toastify";
import axiosPrivate from "../lib/axiosPrivate";
import {
  setErr,
  setErrors,
  setSuccess,
} from "../store/reducers/alertMessageReducer";
import {
  AddImage,
  DeleteImage,
  GetImages,
  setAddImageData,
  setDeleteImageData,
  setImageListData,
  setImageListDataForCheck,
  setWholeImageListData,
} from "../store/reducers/imagesReducer";
import { setLoading } from "../store/reducers/loaderReducer";
import { AppThunk } from "../store/store";
import { setProgress } from "../store/reducers/progressReducer";

export const addImage =
  (payload: AddImage): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    let formData = new FormData();
    if (payload?.filename) formData.append("filename", payload.filename);
    if (payload.image && payload.image.image && payload.image.imagename)
      formData.append("file", payload.image.image, payload.image.imagename);

    try {
      await axiosPrivate
        .post("imaging/images/", formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=myboundary",
            enctype: "multipart/form-data; boundary=myboundary",
          },
          onUploadProgress: (progressEvent: any) => {
            dispatch(
              setProgress({
                total: progressEvent?.total,
                loaded: progressEvent?.loaded,
              })
            );
          },
        })
        .then((response) => {
          const data = response.data;
          toast.success(String("Image added Successfully"), {
            toastId: String("Image added Successfully"),
          });
          dispatch(setLoading(false));
          dispatch(setAddImageData(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
    dispatch(
      setProgress({
        total: 0,
        loaded: 0,
      })
    );
  };

export const searchImages =
  (payload: GetImages): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .get(
          "imaging/images/?" +
            `${payload.page >= 0 ? `page=${payload.page}` : ""}` +
            `${
              payload.page_size >= 0 ? `&page_size=${payload.page_size}` : ""
            }` +
            `${payload.search ? `&search=${payload.search}` : ""}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (response.status === 200) {
            dispatch(setImageListData(data));
          } else {
            dispatch(setErr("Something went wrong, Please try again"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const searchImagesForCheck =
  (payload: GetImages): AppThunk =>
  async (dispatch) => {
    // dispatch(setLoading(true));
    try {
      await axiosPrivate
        .get(
          "imaging/images/?" +
            `${payload.page >= 0 ? `page=${payload.page}` : ""}` +
            `${
              payload.page_size >= 0 ? `&page_size=${payload.page_size}` : ""
            }` +
            `${payload.search ? `&search=${payload.search}` : ""}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (response.status === 200) {
            dispatch(setImageListDataForCheck(data));
          } else {
            dispatch(setErr("Something went wrong, Please try again"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getAllImages =
  (payload: any): AppThunk =>
  async (dispatch) => {
    try {
      await axiosPrivate
        .get(
          "imaging/images/?" +
            `${payload.page >= 0 ? `page=${payload.page}` : ""}` +
            `${
              payload.page_size >= 0 ? `&page_size=${payload.page_size}` : ""
            }` +
            `${payload.search ? `&search=${payload.search}` : ""}`
        )
        .then((response) => {
          const data = response.data;
          if (response.status === 200) {
            dispatch(setWholeImageListData(data));
          } else {
            dispatch(setErr("Something went wrong, Please try again"));
          }
        })
        .catch((error) => {
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const deleteImage =
  (payload: DeleteImage): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .delete("imaging/images/" + payload + "/")
        .then((response) => {
          if (response.status == 204) {
            dispatch(
              setDeleteImageData({ detail: "Image deleted successfully!" })
            );
          }
          dispatch(setLoading(false));
        })
        .catch((error) => {
          dispatch(setLoading(false));

          if (error?.response?.data?.message) {
            dispatch(setErr(error?.response?.data?.message));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const bulkDeleteImages =
  (payload: any[]): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    let formData = new FormData();
    formData.append("image_ids", payload as any);

    try {
      await axiosPrivate
        .post(`imaging/images/bulk_delete/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=myboundary",
            enctype: "multipart/form-data; boundary=myboundary",
          },
        })
        .then((response) => {
          dispatch(setLoading(false));
          if (response?.status == 204) {
            dispatch(setSuccess("Images have been deleted successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };
