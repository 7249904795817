import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Theme,
  useTheme,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import { toast } from "react-toastify";
import * as yup from "yup";
import CancelButton from "../../components/CancelButton";
import Header from "../../components/Header";
import SubmitButton from "../../components/SubmitButton";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import DatePickerSVG from "../../icons/DatePickerSVG";
import axiosPrivate from "../../lib/axiosPrivate";
import { getGroup, updateAutoGroup } from "../../services/groupManagementService";
import {
  alertMessageSelector,
  setErr,
  setErrors,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor, light_text_color, primary_color } from "../../utils/colors_values";
import { designations_values, experience_values, roles_values } from "../../utils/staticEntities";
import Style from "./AddAutoGroup.module.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const designations = designations_values;
const roles = roles_values;
const experience = experience_values;
function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const schema = yup
  .object({
    group_name: yup.string().required("Group name is required"),
    departments: yup.array(),
    // .min(1, "At least one department must be required")
    // .required("Department is required"),
    designations: yup.array(),
    // .min(1, "At least one designation must be required")
    // .required("designation is required"),
    experience_levels: yup.array(),
    // .min(1, "At least one experience level must be required")
    // .required("experience level is required"),
    roles: yup.array(),
    // .min(1, "At least one role must be required")
    // .required("role is required"),
    min_date: yup.date().typeError("Please input valid date or select from date picker"),
    // .default(() => new Date())
    // .required("From date is required")
    max_date: yup.date().typeError("Please input valid date or select from date picker"),
    // .required("To date is required")
    // .when("min_date", (min_date, schema) => min_date && schema.min(min_date))
  })
  .required();
function ConfigAutoGroup() {
  const {
    reset,
    setError,
    getValues,
    watch,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });
  const { id } = useParams();

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const lang = useCompanyLanguage();
  const t = useTranslation();
  const states = useSelector(alertMessageSelector).alertMessageReducer;

  const [personName, setPersonName] = React.useState<string[]>([]);
  const [minDat, setMinDate] = React.useState<any>();
  const [maxDat, setMaxDate] = React.useState<any>();

  const [checked, setChecked] = React.useState(true);
  const [fromValue, setFromValue] = React.useState<any>();
  const [toValue, setToValue] = React.useState<any>();
  const detail = useSelector((state: RootState) => state.groupManagementReducer.detailData);
  useEffect(() => {
    const filters = detail?.filters?.[0];
    const departments_details = filters?.departments_details?.map((row: any) => {
      return {
        label: row?.name || "N/A",
        value: row.id,
      };
    });

    let defaultValues: any = {
      group_name: detail?.name,
      departments: departments_details,
      designations: filters?.designations,
      experience_levels: filters?.experience_levels,
      roles: filters?.roles,
    };
    if (filters?.min_date) {
      setMinDate(filters?.min_date);
      defaultValues.min_date = new Date(filters?.min_date);
    }
    if (filters?.max_date) {
      setMaxDate(filters?.max_date);
      defaultValues.max_date = new Date(filters?.max_date);
    }
    reset({ ...defaultValues });
  }, [detail]);

  useEffect(() => {
    dispatch(getGroup(Number(id)));
  }, [id]);

  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const handleChangeDesignation = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const conveetNestedToFlatten = (accounts: any, showChild: boolean) => {
    var a: any = [];
    for (var i = 0; i < accounts.length; i++) {
      if (showChild) {
        if (accounts[i].children) {
          a = a.concat(conveetNestedToFlatten(accounts[i].children, showChild));
        }
      }
      a.push(accounts[i]);
    }
    return a;
  };

  const loadOptions = async (searchQuery: any, loadedOptions: any, { page }: any) => {
    const response = await axiosPrivate.get(
      `accounts/department/?page=${page}&page_size=100&search=${searchQuery}`
    );
    const data = response.data;
    const showChild = searchQuery ? false : true;
    const dataFlatten = conveetNestedToFlatten(data?.results, showChild);
    const options = dataFlatten?.map((row: any) => {
      return {
        label: row.name || "N/A",
        value: row.id,
      };
    });

    const users = {
      options: options || [],
      hasMore: data?.next ? true : false,
      additional: {
        page: searchQuery ? 1 : page + 1,
      },
    };

    return users;
  };
  const Submit = (payload: any) => {
    if (
      (payload?.departments?.length === 0 || !payload?.departments) &&
      payload?.designations?.length === 0 &&
      payload?.experience_levels?.length === 0 &&
      payload?.roles?.length === 0 &&
      !payload.max_date &&
      !payload.min_date
    ) {
      toast.info(
        t(
          "Please select at least one option from Designation, Role, Experience, Department or Created Date."
        ),
        {
          toastId: t(
            "Please select at least one option from Designation, Role, Experience, Department or Created Date."
          ),
        }
      );
      return;
    }
    if ((payload?.min_date && !payload?.max_date) || (!payload?.min_date && payload?.max_date)) {
      toast.error(t("From & To date are requireed."), {
        toastId: t("From & To date are requireed."),
      });
      return;
    }
    if (payload.max_date && payload.min_date) {
      let mStart = moment(payload.min_date);
      let mEnd = moment(payload.max_date);
      if (!mStart.isBefore(mEnd)) {
        toast.error(t("To date should be after from date."), {
          toastId: t("To date should be after from date."),
        });
        return;
      }
    }
    if (payload.max_date && payload.min_date) {
      let mStart = moment(payload.min_date);
      let mEnd = moment(payload.max_date);
      if (!mStart.isBefore(mEnd)) {
        toast.error(t("To date should be after from date."), {
          toastId: t("To date should be after from date."),
        });
        return;
      }
    }
    let depart: any = [];
    payload?.departments?.map((row: any) => depart.push(row.value));

    let query: any = { group_name: payload.group_name };
    if (depart?.length > 0) {
      query.departments = depart;
    } else {
      query.departments = [];
    }
    if (payload?.designations?.length > 0) {
      query.designations = payload.designations;
    } else {
      query.designations = [];
    }
    if (payload?.experience_levels?.length > 0) {
      query.experience_levels = payload.experience_levels;
    } else {
      query.experience_levels = [];
    }
    if (payload?.roles?.length > 0) {
      query.roles = payload.roles;
    } else {
      query.roles = [];
    }
    if (payload?.min_date) {
      query.min_date = moment(payload.min_date).format("YYYY-MM-DD");
    } else {
      query.min_date = null;
    }
    if (payload?.max_date) {
      query.max_date = moment(payload.max_date).format("YYYY-MM-DD");
    } else {
      query.max_date = null;
    }

    const filters = detail?.filters?.[0];
    dispatch(updateAutoGroup(Number(filters?.id), query));
  };

  useEffect(() => {
    if (states?.errors) {
      if (states?.errors?.group_name?.length > 0) {
        toast.error(states?.errors?.group_name?.toString(), {
          toastId: states?.errors?.group_name?.toString(),
        });
        setError("group_name", {
          type: "server",
          message: states?.errors?.group_name?.toString(),
        });
      }
      if (states?.errors?.departments?.length > 0) {
        toast.error(states?.errors?.departments?.toString(), {
          toastId: states?.errors?.departments?.toString(),
        });
        setError("departments", {
          type: "server",
          message: states?.errors?.departments?.toString(),
        });
      }
      if (states?.errors?.designations?.length > 0) {
        toast.error(states?.errors?.designations?.toString(), {
          toastId: states?.errors?.designations?.toString(),
        });
        setError("designations", {
          type: "server",
          message: states?.errors?.designations?.toString(),
        });
      }
      if (states?.errors?.experience_levels?.length > 0) {
        toast.error(states?.errors?.experience_levels?.toString(), {
          toastId: states?.errors?.experience_levels?.toString(),
        });
        setError("experience_levels", {
          type: "server",
          message: states?.errors?.experience_levels?.toString(),
        });
      }
      if (states?.errors?.roles?.length > 0) {
        toast.error(states?.errors?.roles?.toString(), {
          toastId: states?.errors?.roles?.toString(),
        });
        setError("roles", {
          type: "server",
          message: states?.errors?.roles?.toString(),
        });
      }
      if (states?.errors?.min_date?.length > 0) {
        toast.error(states?.errors?.min_date?.toString(), {
          toastId: states?.errors?.min_date?.toString(),
        });
        setError("min_date", {
          type: "server",
          message: states?.errors?.min_date?.toString(),
        });
      }
      if (states?.errors?.max_date?.length > 0) {
        toast.error(states?.errors?.max_date?.toString(), {
          toastId: states?.errors?.max_date?.toString(),
        });
        setError("max_date", {
          type: "server",
          message: states?.errors?.max_date?.toString(),
        });
      }
      if (states?.errors) {
        dispatch(setErrors(null));
      }
    }
    if (states?.error) {
      toast.error(String(states?.error), {
        toastId: String(states?.error),
      });
      dispatch(setErr(null));
    }
    if (states?.success) {
      toast.success(String(states?.success), {
        toastId: String(states?.success),
      });
      dispatch(setSuccess(null));
      setTimeout(() => {
        navigate(`/detail-group/${detail?.id}`);
      }, 2000);
    }
  }, [states]);

  const textColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;
  const bgColor =
    useColor("interface_background_color") || defaultThemeColor.interface_background_color;

  const chipColor =
    useColor("interface_foreground_text_color") ||
    defaultThemeColor.interface_foreground_text_color;
  const chipBgColor =
    useColor("interface_foreground_color") || defaultThemeColor.interface_foreground_color;

  const primaryButton = useColor("button_primary_text") || defaultThemeColor.button_primary_text;
  const primaryButtonBg = useColor("button_primary") || defaultThemeColor.button_primary;

  return (
    <>
      <div>
        <div style={{ paddingBottom: "4em" }}>
          <Header
            page={t("Group Management")}
            mainHead={t("Group Management")}
            backLink="/group-management"
          />
        </div>
        <div
          className="page-start"
          style={{
            background:
              useColor("interface_foreground_color") ||
              defaultThemeColor.interface_foreground_color,
            color:
              useColor("interface_foreground_text_color") ||
              defaultThemeColor.interface_foreground_text_color,
          }}
        >
          <h2
            className="page-heading mb-4"
            style={{
              color:
                useColor("interface_foreground_text_color") ||
                defaultThemeColor.interface_foreground_text_color,
            }}
          >
            {t("Update Auto Group")}
          </h2>

          <form onSubmit={handleSubmit(Submit)} autoComplete="off">
            <div className="row align-items-baseline">
              <div className="col-md-6">
                <span className="form-label" style={{ lineHeight: "40px" }}>
                  {t("Group Name")}
                  <span className="color-red"> *</span>
                </span>
                <TextField
                  placeholder={t("Name")}
                  autoComplete="off"
                  {...register("group_name")}
                  variant="outlined"
                  className={`form-input`}
                  style={{
                    color:
                      useColor("interface_background_text_color") ||
                      defaultThemeColor.interface_background_text_color,
                    background:
                      useColor("interface_background_color") ||
                      defaultThemeColor.interface_background_color,
                    border: "none",
                    outline: "none",
                  }}
                />

                <p className="color-red mt-3">{errors.group_name?.message}</p>
              </div>
              <div className="col-md-6">
                <span className="form-label" style={{ lineHeight: "40px" }}>
                  {t("Designation")}
                  {/* <span className="color-red"> *</span> */}
                </span>
                <FormControl fullWidth>
                  <Controller
                    name="designations"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                      <FormControl>
                        {/* <InputLabel id="designations">{t("Designation")}</InputLabel> */}
                        <Select
                          {...field}
                          multiple
                          labelId="designations"
                          label=""
                          placeholder={t("Designation")}
                          style={{ color: textColor, background: bgColor }}
                          sx={{
                            // minHeight: "5em",
                            "& .MuiChip-root": {
                              // color: textColor,
                              // background: bgColor,
                              color: primaryButton,
                              background: primaryButtonBg,
                            },
                          }}
                          MenuProps={{
                            sx: {
                              "&& .Mui-selected": {
                                // color: textColor,
                                // background: bgColor,
                                color: light_text_color,
                                background: primary_color,
                              },
                              "&& .Mui-selected:hover": {
                                color: "black",
                                background: "skyblue",
                              },
                            },
                          }}
                          renderValue={(selected) => {
                            return (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.slice(0, 2).map((value) => (
                                  <Chip
                                    key={value}
                                    label={value}
                                    // style={{ color: textColor, background: bgColor }}
                                  />
                                ))}
                                {selected.length > 2 && (
                                  <span
                                    className={`badge ${Style.badge}`}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      color: primaryButton,
                                      backgroundColor: primaryButtonBg,
                                    }}
                                  >
                                    + {selected.length - 2}
                                  </span>
                                )}
                              </Box>
                            );
                          }}
                          // defaultValue={[]}
                          // displayEmpty
                          // style={{ marginTop: "10px" }}
                        >
                          {designations.map((row) => (
                            <MenuItem
                              value={row}
                              key={row}
                              className="d-flex justify-content-between"
                            >
                              <div> {row}</div>
                              <div>
                                <Checkbox
                                  checked={
                                    getValues() && getValues()?.designations
                                      ? (getValues()?.designations as any).indexOf(row) > -1
                                      : false
                                  }
                                  color="default"
                                  sx={{
                                    color:
                                      getValues() && getValues()?.designations
                                        ? (getValues()?.designations as any).indexOf(row) > -1
                                          ? "white"
                                          : ""
                                        : "",
                                  }}
                                />
                              </div>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </FormControl>
                <p className="color-red mt-3">{errors.designations?.message}</p>
              </div>
              <div className="col-md-6">
                <span className="form-label" style={{ lineHeight: "40px" }}>
                  {t("Roles")}
                  {/* <span className="color-red"> *</span> */}
                </span>
                <FormControl fullWidth>
                  <Controller
                    name="roles"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                      <FormControl>
                        {/* <InputLabel id="designarolestions">{t("Roles")}</InputLabel> */}
                        <Select
                          {...field}
                          multiple
                          labelId="roles"
                          label=""
                          style={{ color: textColor, background: bgColor }}
                          defaultValue={[]}
                          // style={{ marginTop: "10px" }}
                          sx={{
                            // minHeight: "5em",
                            "& .MuiChip-root": {
                              // color: textColor,
                              // background: bgColor,
                              color: primaryButton,
                              background: primaryButtonBg,
                            },
                          }}
                          MenuProps={{
                            sx: {
                              "&& .Mui-selected": {
                                // color: textColor,
                                // background: bgColor,
                                color: light_text_color,
                                background: primary_color,
                              },
                              "&& .Mui-selected:hover": {
                                color: "black",
                                background: "skyblue",
                              },
                            },
                          }}
                          renderValue={(selected) => {
                            return (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.slice(0, 2).map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                                {selected.length > 2 && (
                                  <span
                                    className={`badge ${Style.badge}`}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      color: primaryButton,
                                      backgroundColor: primaryButtonBg,
                                    }}
                                  >
                                    + {selected.length - 2}
                                  </span>
                                )}
                              </Box>
                            );
                          }}
                        >
                          {roles.map((row) => (
                            <MenuItem
                              value={row}
                              key={row}
                              className="d-flex justify-content-between"
                            >
                              <div>{row}</div>
                              <div>
                                <Checkbox
                                  checked={
                                    getValues() && getValues().roles
                                      ? (getValues()?.roles as any).indexOf(row) > -1
                                      : false
                                  }
                                  color="default"
                                  sx={{
                                    color:
                                      getValues() && getValues().roles
                                        ? (getValues()?.roles as any).indexOf(row) > -1
                                          ? "white"
                                          : ""
                                        : "",
                                  }}
                                />
                              </div>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </FormControl>
                <p className="color-red mt-3">{errors.roles?.message}</p>
              </div>
              <div className="col-md-6">
                <span className="form-label" style={{ lineHeight: "40px" }}>
                  {t("Experience Level")}
                  {/* <span className="color-red"> *</span> */}
                </span>
                <FormControl fullWidth>
                  <Controller
                    name="experience_levels"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                      <FormControl>
                        {/* <InputLabel id="experience_levels"> {t("Experience Level")}</InputLabel> */}
                        <Select
                          {...field}
                          multiple
                          labelId="experience_levels"
                          label=""
                          defaultValue={[]}
                          style={{ color: textColor, background: bgColor }}
                          sx={{
                            // minHeight: "5em",
                            "& .MuiChip-root": {
                              // color: textColor,
                              // background: bgColor,
                              color: primaryButton,
                              background: primaryButtonBg,
                              // height: "25px",
                            },
                          }}
                          MenuProps={{
                            sx: {
                              "&& .Mui-selected": {
                                // color: textColor,
                                // background: bgColor,
                                color: light_text_color,
                                background: primary_color,
                              },
                              "&& .Mui-selected:hover": {
                                color: "black",
                                background: "skyblue",
                              },
                            },
                          }}
                          renderValue={(selected) => {
                            return (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.slice(0, 2).map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                                {selected.length > 2 && (
                                  <span
                                    className={`badge ${Style.badge}`}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      color: primaryButton,
                                      backgroundColor: primaryButtonBg,
                                    }}
                                  >
                                    + {selected.length - 2}
                                  </span>
                                )}
                              </Box>
                            );
                          }}
                          // style={{ marginTop: "10px" }}
                        >
                          {experience.map((row) => (
                            <MenuItem
                              value={row}
                              key={row}
                              className="d-flex justify-content-between"
                            >
                              <div> {row}</div>
                              <div>
                                <Checkbox
                                  color="default"
                                  sx={{
                                    color:
                                      getValues() && getValues().experience_levels
                                        ? (getValues()?.experience_levels as any).indexOf(row) > -1
                                          ? "white"
                                          : ""
                                        : "",
                                  }}
                                  checked={
                                    getValues() && getValues().experience_levels
                                      ? (getValues()?.experience_levels as any).indexOf(row) > -1
                                      : false
                                  }
                                />
                              </div>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </FormControl>
                <p className="color-red mt-3">{errors.experience_levels?.message}</p>
              </div>
              <div className="col-md-6">
                <span className="form-label">
                  {t("Department")}
                  {/* <span className="color-red"> *</span> */}
                </span>
                <Controller
                  control={control}
                  name="departments"
                  render={({ field: { value, onChange, ref } }) => (
                    <AsyncPaginate
                      id="departments"
                      name="departments"
                      // id="companyId"
                      loadOptions={loadOptions}
                      onChange={onChange}
                      selectRef={ref}
                      value={value}
                      getOptionValue={(option: any) => option.value}
                      getOptionLabel={(option: any) => option.label}
                      className="add-member-select"
                      additional={{
                        page: 1,
                      }}
                      // styles={{
                      //   control: (baseStyles, state) => ({
                      //     ...baseStyles,
                      //     // borderRadius: "5px",
                      //     // border: "2px solid #ccc",
                      //     height: "3.8em",
                      //     overflow: "scroll",
                      //   }),
                      //   option: (provided, state) => ({
                      //     ...provided,
                      //     backgroundColor: state.isFocused ? primary_color : "",
                      //     color: state.isFocused ? light_text_color : "",
                      //   }),
                      // }}
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? bgColor : "",
                          color: state.isFocused ? textColor : "",
                        }),
                        valueContainer: (provided, state) => ({
                          ...provided,
                          height: "3.8em",
                          overflow: "scroll",
                          backgroundColor: bgColor,
                          color: textColor,
                        }),
                        multiValue: (baseStyles) => ({
                          ...baseStyles,
                          backgroundColor: primaryButtonBg,
                        }),
                        multiValueLabel: (baseStyles) => ({
                          ...baseStyles,
                          color: primaryButton,
                        }),
                        multiValueRemove: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: primaryButtonBg,
                          color: primaryButton,
                          ":hover": {
                            backgroundColor: primaryButtonBg,
                            color: primaryButton,
                          },
                        }),
                      }}
                      isMulti
                      isClearable
                      isSearchable={true}
                      placeholder={t("Select Departments")}
                      debounceTimeout={500}
                    />
                  )}
                />
                <p className="color-red mt-3">{errors.departments?.message}</p>
              </div>
              <div className="col-md-6">
                <div className="form-label" style={{ marginBottom: "0.7rem" }}>
                  {t("Created Date")}
                  {/* <span className="color-red"> *</span> */}
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Controller
                      name="min_date"
                      control={control}
                      render={({ field }) => (
                        <FormControl fullWidth>
                          {/* <InputLabel id="From">From</InputLabel> */}
                          <DatePicker
                            {...field}
                            label={lang === "ar" ? "" : t("From")}
                            // value={fromValue}
                            value={minDat ? dayjs(minDat) : null}
                            className="col-12"
                            slots={{
                              openPickerIcon: DatePickerSVG,
                            }}
                            // style={{ color: textColor, background: bgColor }}
                            format="YYYY-MM-DD"

                            // onChange={(newValue) => setFromValue(newValue)}
                          />
                        </FormControl>
                      )}
                    />
                    <p className="color-red mt-1">{errors.min_date?.message}</p>
                  </div>
                  <div className="col-md-6">
                    <Controller
                      name="max_date"
                      control={control}
                      render={({ field }) => (
                        <FormControl fullWidth>
                          {/* <InputLabel id="From">From</InputLabel> */}
                          <DatePicker
                            {...field}
                            label={lang === "ar" ? "" : t("To")}
                            // minDate={watch("min_date")}
                            value={maxDat ? dayjs(maxDat) : null}
                            // value={fromValue}
                            className="col-12"
                            slots={{
                              openPickerIcon: DatePickerSVG,
                            }}
                            // style={{ color: textColor, background: bgColor }}
                            format="YYYY-MM-DD"

                            // onChange={(newValue) => setFromValue(newValue)}
                          />
                        </FormControl>
                      )}
                    />
                    <p className="color-red mt-1">{errors.max_date?.message}</p>
                  </div>
                </div>
              </div>

              <div
                className="d-flex flex-wrap gap-3 w-100 px-3 mt-5 align-items-center"
                style={{
                  justifyContent: lang === "ar" ? "flex-start" : "flex-end",
                }}
              >
                <SubmitButton label={t("Update")} marginTop={false} />
                <CancelButton
                  size="small"
                  outlined={true}
                  onClick={() => navigate(`/detail-group/${detail?.id}`)}
                  textColor="#2196F3"
                  outlineColor=""
                  text={t("Cancel")}
                  bg={"rgba(33, 150, 243, 0.04)"}
                  className={``}
                ></CancelButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ConfigAutoGroup;
