function PlayListSVG(props: { color: string }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.3122 10.0312L10.8122 7.03125C10.7274 6.9747 10.6289 6.94223 10.5271 6.93732C10.4253 6.93241 10.3241 6.95524 10.2343 7.00337C10.1445 7.05151 10.0694 7.12313 10.0172 7.2106C9.96488 7.29807 9.93735 7.3981 9.9375 7.5V13.5C9.93735 13.6019 9.96488 13.7019 10.0172 13.7894C10.0694 13.8769 10.1445 13.9485 10.2343 13.9966C10.3241 14.0448 10.4253 14.0676 10.5271 14.0627C10.6289 14.0578 10.7274 14.0253 10.8122 13.9688L15.3122 10.9688C15.3895 10.9174 15.453 10.8478 15.4969 10.766C15.5408 10.6842 15.5638 10.5928 15.5638 10.5C15.5638 10.4072 15.5408 10.3158 15.4969 10.234C15.453 10.1522 15.3895 10.0826 15.3122 10.0312ZM11.0625 12.4491V8.55094L13.9856 10.5L11.0625 12.4491ZM20.25 3.9375H3.75C3.4019 3.9375 3.06806 4.07578 2.82192 4.32192C2.57578 4.56806 2.4375 4.9019 2.4375 5.25V15.75C2.4375 16.0981 2.57578 16.4319 2.82192 16.6781C3.06806 16.9242 3.4019 17.0625 3.75 17.0625H20.25C20.5981 17.0625 20.9319 16.9242 21.1781 16.6781C21.4242 16.4319 21.5625 16.0981 21.5625 15.75V5.25C21.5625 4.9019 21.4242 4.56806 21.1781 4.32192C20.9319 4.07578 20.5981 3.9375 20.25 3.9375ZM20.4375 15.75C20.4375 15.7997 20.4177 15.8474 20.3826 15.8826C20.3474 15.9177 20.2997 15.9375 20.25 15.9375H3.75C3.70027 15.9375 3.65258 15.9177 3.61742 15.8826C3.58225 15.8474 3.5625 15.7997 3.5625 15.75V5.25C3.5625 5.20027 3.58225 5.15258 3.61742 5.11742C3.65258 5.08225 3.70027 5.0625 3.75 5.0625H20.25C20.2997 5.0625 20.3474 5.08225 20.3826 5.11742C20.4177 5.15258 20.4375 5.20027 20.4375 5.25V15.75ZM21.5625 19.5C21.5625 19.6492 21.5032 19.7923 21.3977 19.8977C21.2923 20.0032 21.1492 20.0625 21 20.0625H3C2.85082 20.0625 2.70774 20.0032 2.60225 19.8977C2.49676 19.7923 2.4375 19.6492 2.4375 19.5C2.4375 19.3508 2.49676 19.2077 2.60225 19.1023C2.70774 18.9968 2.85082 18.9375 3 18.9375H21C21.1492 18.9375 21.2923 18.9968 21.3977 19.1023C21.5032 19.2077 21.5625 19.3508 21.5625 19.5Z"
        fill="#2B3674"
      />
    </svg>
  );
}

export default PlayListSVG;
