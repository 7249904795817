/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Chip, FormControl, MenuItem, OutlinedInput } from "@mui/material";
import Radio from "@mui/material/Radio";
import Select from "@mui/material/Select";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import CancelButton from "../../components/CancelButton";
import CategoryLoadComponent from "../../components/CategoryLoad/CategoryLoad";
import ChooseFile from "../../components/ChooseFile";
import Header from "../../components/Header";
import SubmitButton from "../../components/SubmitButton";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import axiosPrivate from "../../lib/axiosPrivate";
import {
  getModuleforsearch,
  getModules,
  getModulesForView,
  getWholeModules,
  updateModuleData,
} from "../../services/moduleService";
import {
  alertMessageSelector,
  setErr,
  setErrors,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { RootState, useAppDispatch, useAppSelector } from "../../store/store";
import {
  defaultThemeColor,
  light_text_color,
  primary_color,
} from "../../utils/colors_values";
import AddAssessments from "./AddAssessments";
import Style from "./AddModule.module.scss";
import AssessmentDialog from "./AssessmentDialog";

const moduleType = [
  { key: "assessment", value: "Assessment" },
  { key: "video", value: "Video" },
  { key: "scorm", value: "SCORM" },
  { key: "pdf", value: "PDF" },
];

const moduleLevel = [
  { key: "beginner", value: "Beginner" },
  { key: "intermediate", value: "Intermediate" },
  { key: "advance", value: "Advance" },
];

const moduleStatus = [
  { key: "draft", value: "Draft" },
  { key: "approved", value: "Approved" },
];

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
    },
  },
};

const schema = yup
  .object({
    name: yup.string().required("Assessment Name is required"),
    description: yup.string().required("Description is required"),
    status: yup.string().required("Status is required"),
    category: yup.object().required("Category is required"),
    type: yup.string().required("Type is required"),
    level: yup.string().required("Level is required"),
    file_name: yup
      .string()
      .when("type", {
        is: (value: any) => ["Video", "PDF"]?.includes(value),
        then: () => yup.string().required("File name is required"),
      })
      .when("type", {
        is: (value: any) => ["Assessment", "SCORM"]?.includes(value),
        then: () => yup.string().optional(),
      }),
    file: yup
      .mixed()
      .when("type", {
        is: (value: any) => ["Video", "SCORM", "PDF"]?.includes(value),
        then: () => yup.mixed().optional(),
      })
      .when("type", {
        is: "Assessment",
        then: () => yup.mixed().optional(),
      }),
  })
  .required();
function EditModule() {
  const {
    reset,
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    clearErrors,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const [status, setStatus] = React.useState("");
  const [level, setLevel] = React.useState("");
  const [pdf, setPdf] = useState("");
  const [selectedTypeAssessment, setSelectedTypeAssessment] = useState("PDF");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const [assessmentError, setAssessmentError] = useState<any>(false);
  const [defaultAssessmentRows, setDefaultAssessmentRows] = React.useState<any>(
    []
  );
  const dataList = useAppSelector((state) => state.moduleReducer.data);
  const data = useSelector(
    (state: RootState) => state.moduleReducer.moduleData
  );

  const [selectedAssessmentRows, setSelectedAssessmentRows] =
    React.useState<any>([]);
  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  const page = useSelector((state: RootState) => state.moduleReducer.page);
  const perPage = useSelector(
    (state: RootState) => state.moduleReducer.perPage
  );
  const search = useSelector((state: RootState) => state.moduleReducer.search);
  const wholedataList = useAppSelector(
    (state) => state.moduleReducer.wholedetailerrors
  );

  useEffect(() => {
    dispatch(getModules({ page: 1, page_size: 10, search: "" }));
    if (Object.keys(wholedataList).length == 0) {
      dispatch(
        getWholeModules({
          page_size: 1,
          page: 1,
          search: "",
          for: "errors",
        })
      );
    }
  }, []);

  useEffect(() => {
    setDefaultAssessmentRows([]);
    setSelectedAssessmentRows([]);
    setAssessmentQuestions([]);
    let defaultValues = {};
    reset({ ...defaultValues });
    dispatch(getModulesForView(Number(id)));
  }, [id]);
  const [assessmentQuestions, setAssessmentQuestions] = React.useState<any[]>(
    []
  );
  const states = useSelector(alertMessageSelector).alertMessageReducer;
  const [
    parentCheckedButNotAddedQuestions,
    setParentCheckedButNotAddedQuestions,
  ] = React.useState<any[]>([]);

  console.log(
    "mkk:",
    defaultAssessmentRows,
    selectedAssessmentRows,
    parentCheckedButNotAddedQuestions,
    assessmentQuestions,
    selectedTypeAssessment?.toLowerCase()
  );
  useEffect(() => {
    if (
      defaultAssessmentRows.length == 0 &&
      selectedAssessmentRows.length == 0
    ) {
      setParentCheckedButNotAddedQuestions([]);
      setAssessmentQuestions([]);
    } else if (
      selectedAssessmentRows &&
      selectedAssessmentRows?.results &&
      selectedAssessmentRows?.results?.length > 0
    ) {
      setParentCheckedButNotAddedQuestions(
        selectedAssessmentRows?.results || []
      );
    } else {
      setAssessmentQuestions([data?.assessment] || []);
    }
  }, [isModalOpen, open]);

  useEffect(() => {}, [isModalOpen, open]);

  useEffect(() => {
    if (dataList && Object.keys(dataList).length > 0) {
      if (
        dataList?.results?.find((i: any) => i.name == watch("name")) &&
        !dataList?.results?.find((i: any) => i.id == id)
      ) {
        setError("name", {
          type: "server",
          message: "Module with this name already exists!",
        });
        return;
      } else {
        clearErrors("name");
      }
    }
  }, [dataList]);

  useEffect(() => {
    if (
      wholedataList &&
      Object.keys(wholedataList).length > 0 &&
      !(wholedataList?.count == wholedataList?.results.length)
    ) {
      dispatch(
        getWholeModules({
          page_size: wholedataList?.count,
          page: 1,
          search: "",
          for: "errors",
        })
      );
    }
  }, [wholedataList]);

  useEffect(() => {
    if (
      wholedataList &&
      Object.keys(wholedataList).length > 0 &&
      watch("file_name") &&
      !(watch("file_name") == "")
    ) {
      if (
        wholedataList?.results?.find(
          (i: any) => i?.file_name == watch("file_name") && !(id == i?.id)
        )
      ) {
        setError("file_name", {
          type: "server",
          message: "Module file with this name already exists",
        });
        return;
      } else {
        clearErrors("file_name");
      }
    } else {
      clearErrors("file_name");
    }
  }, [watch("file_name")]);

  useEffect(() => {
    if (states?.errors) {
      if (states?.errors?.category?.length > 0) {
        toast.error(states?.errors?.category?.toString(), {
          toastId: states?.errors?.category?.toString(),
        });
        setError("category", {
          type: "server",
          message: states?.errors?.category?.toString(),
        });
      }
      if (states?.errors?.description?.length > 0) {
        toast.error(states?.errors?.description?.toString(), {
          toastId: states?.errors?.description?.toString(),
        });
        setError("description", {
          type: "server",
          message: states?.errors?.description?.toString(),
        });
      }
      if (states?.errors?.level?.length > 0) {
        toast.error(states?.errors?.level?.toString(), {
          toastId: states?.errors?.level?.toString(),
        });
        setError("level", {
          type: "server",
          message: states?.errors?.level?.toString(),
        });
      }
      if (states?.errors?.name?.length > 0) {
        toast.error(states?.errors?.name?.toString(), {
          toastId: states?.errors?.name?.toString(),
        });
        setError("name", {
          type: "server",
          message: states?.errors?.name?.toString(),
        });
      }
      if (states?.errors?.status?.length > 0) {
        toast.error(states?.errors?.status?.toString(), {
          toastId: states?.errors?.status?.toString(),
        });
        setError("status", {
          type: "server",
          message: states?.errors?.status?.toString(),
        });
      }
      if (states?.errors?.file?.length > 0) {
        toast.error(states?.errors?.file?.toString(), {
          toastId: states?.errors?.file?.toString(),
        });
        setError("file", {
          type: "server",
          message: states?.errors?.file?.toString(),
        });
      }
      if (states?.errors?.file_name?.length > 0) {
        toast.error(states?.errors?.file_name?.toString(), {
          toastId: states?.errors?.file_name?.toString(),
        });
        setError("file_name", {
          type: "server",
          message: states?.errors?.file_name?.toString(),
        });
      }
      if (states?.errors?.type?.length > 0) {
        toast.error(states?.errors?.type?.toString(), {
          toastId: states?.errors?.type?.toString(),
        });
        setError("type", {
          type: "server",
          message: states?.errors?.type?.toString(),
        });
      }
      if (states?.errors) {
        dispatch(setErrors(null));
      }
    }
    if (states?.error) {
      toast.error(String(states?.error), {
        toastId: String(states?.error),
      });
      dispatch(setErr(null));
    }

    if (states?.success) {
      toast.success(String(states?.success), {
        toastId: String(states?.success),
      });
      dispatch(setSuccess(null));
      navigate("/module/module-listing?page=1&perpage=10");
    }
  }, [states]);

  const urlWithQueryString = () => {
    let object: any = {};
    if (page) {
      object.page = page;
    }
    if (perPage) {
      object.perpage = perPage;
    }
    if (
      search !== null &&
      search !== "" &&
      search !== undefined &&
      search !== "null"
    ) {
      object.search = search;
    }

    const objString = "?" + new URLSearchParams(object).toString();
    navigate(`/module/module-listing${objString}`);
  };

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      let defaultValues = {
        name: data?.name,
        category: { value: data?.category?.id, label: data?.category?.name },
        type: data?.type,
        description: data?.description,
        level: data?.level,
        status: data?.status,
        file_name: data?.file_name || "",
      };
      setSelectedTypeAssessment(data?.type);
      setAssessmentQuestions([data?.assessment] || []);
      if (data?.assessment) {
        setDefaultAssessmentRows([data?.assessment]);
      }
      if (data?.file) {
        setValue("file", data?.file);
        clearErrors("file");
      }
      reset({ ...defaultValues });
    }
  }, [data]);
  console.log(
    "errors hi errors",
    watch("type"),
    data?.file,
    errors,
    getValues()
  );
  useEffect(() => {
    setDefaultAssessmentRows([]);
    setSelectedAssessmentRows([]);
    setAssessmentQuestions([]);
    if (data && Object.keys(data).length > 0) {
      if (watch("type") == "Assessment") {
        setDefaultAssessmentRows(data?.assessment ? [data?.assessment] : []);
        setAssessmentQuestions(data?.assessment ? [data?.assessment] : []);
      } else {
        setDefaultAssessmentRows([]);
        setAssessmentQuestions([]);
      }
      if (watch("type") == data?.type) {
        let defaultValues = {
          name: data?.name,
          category: { value: data?.category?.id, label: data?.category?.name },
          type: data?.type,
          description: data?.description,
          level: data?.level,
          status: data?.status,
          file_name: data?.file_name || "",
        };
        reset({ ...defaultValues });
      }
    }
    clearErrors("file_name");
  }, [watch("type")]);

  const loadOptions = async (
    searchQuery: any,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await axiosPrivate.get(
      `assessments/category/?page=${page}&page_size=100&search=${searchQuery}`
    );
    const data = response.data;

    const options = data?.results?.map((row: any) => {
      return {
        label: row?.name || "N/A",
        value: row.id,
      };
    });

    const users = {
      options: options || [],
      hasMore: data?.next ? true : false,
      additional: {
        page: searchQuery ? 1 : page + 1,
      },
    };

    return users;
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setOpen(false);
  };
  const Submit = (payload: any) => {
    console.log("submit", pdf);
    if (
      wholedataList &&
      Object.keys(wholedataList).length > 0 &&
      !(watch("file_name") == "") &&
      watch("type") != "Assessment" &&
      watch("type") != "SCORM"
    ) {
      if (
        wholedataList?.results?.find(
          (i: any) => i?.file_name == watch("file_name") && !(id == i?.id)
        )
      ) {
        setError("file_name", {
          type: "server",
          message: "Module file with this name already exists",
        });
        return;
      } else {
        clearErrors("file_name");
      }
    }

    if (
      selectedAssessmentRows.length == 0 &&
      defaultAssessmentRows?.length == 0 &&
      selectedTypeAssessment?.toLowerCase() == "assessment"
    ) {
      toast.error(String("Assessment is required"), {
        toastId: String("Assessment is required"),
      });
      return;
    }

    if (data?.id) {
      if (
        !data?.file &&
        Object.keys(pdf).length == 0 &&
        payload.type != "Assessment"
      ) {
        setError("file", {
          type: "server",
          message: "File is required",
        });
        return;
      }
      let finalModule: any = null;
      finalModule = {
        name: payload.name,
        description: payload.description,
        status:
          payload?.status?.toLowerCase() == "approved" ? "Approved" : "Draft",
        type: payload.type,
        level: payload.level,
        file_name: payload?.file_name || undefined,
        file: pdf,
        category: (payload?.category && payload?.category.value) || undefined,
        assessment:
          assessmentQuestions?.length > 0
            ? assessmentQuestions[0]?.id
            : undefined,
      };
      dispatch(updateModuleData(Number(data?.id), finalModule));
    }
    return;
  };

  const [acceptFiles, setAcceptFiles] = useState<any>("");
  useEffect(() => {
    if (selectedTypeAssessment?.toLowerCase() == "assessment") {
      setAcceptFiles("");
      setValue("file", undefined);
      setValue("file_name", undefined);
      !id && setIsModalOpen(true);
    } else if (selectedTypeAssessment?.toLowerCase() === "video") {
      setValue("file", undefined);
      setValue("file_name", undefined);
      setAcceptFiles("video/mp4, video/avi, video/mkv");
    } else if (selectedTypeAssessment?.toLowerCase() === "pdf") {
      setValue("file", undefined);
      setValue("file_name", undefined);
      setAcceptFiles("application/pdf");
    } else if (selectedTypeAssessment?.toLowerCase() === "scorm") {
      setValue("file", undefined);
      setValue("file_name", undefined);
      setAcceptFiles("application/zip");
    } else {
      setAcceptFiles("");
      setValue("file", undefined);
      setValue("file_name", undefined);
    }
  }, [selectedTypeAssessment]);

  useEffect(() => {
    debouncedOnChange();
  }, [watch("name")]);

  const getWhole = () => {
    dispatch(
      getModuleforsearch({
        page_size: 10,
        page: 1,
        search: watch("name"),
      })
    );
  };

  const debouncedOnChange = _.debounce(getWhole, 1000);

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;

  const primaryButton =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;
  const primaryButtonBg =
    useColor("button_primary") || defaultThemeColor.button_primary;

  return (
    <>
      <Header
        page={id ? t("Edit Module") : t("View Module")}
        mainHead={t("Module")}
        backLink="/module/module-listing"
      />
      <div
        className={`${Style.background} d-flex flex-wrap`}
        style={{
          background:
            useColor("interface_foreground_color") ||
            defaultThemeColor.interface_foreground_color,
          color:
            useColor("interface_foreground_text_color") ||
            defaultThemeColor.interface_foreground_text_color,
        }}
      >
        <div className={`col-12 my-4 mb-0`}>
          <div
            className={`${Style.textHeader} mb-1`}
            style={{
              color:
                useColor("interface_foreground_text_color") ||
                defaultThemeColor.interface_foreground_text_color,
              textAlign: lang === "ar" ? "right" : "left",
              paddingRight: lang === "ar" ? "2rem" : "0rem",
            }}
          >
            {t("Edit Module")}
          </div>
        </div>
        <div className={`col-12 my-3 mb-0`}>
          <form id="myform" onSubmit={handleSubmit(Submit)}>
            <div className={`row col-lg-12 px-0 mx-0`}>
              <div className={`${Style.col6} px-0 mt-3`}>
                <span className={Style.labelHeader}>
                  {t("Name")}
                  <span style={{ color: "red" }}> *</span>
                </span>
                <input
                  className={`${Style.inputfields} ${Style.fieldFont} ${
                    errors.name ? "is-invalid" : ""
                  }`}
                  style={{
                    color: interfaceTextColor,
                    background: interfaceBgColor,
                  }}
                  type="text"
                  placeholder={t("Name")}
                  autoComplete="off"
                  {...register("name")}
                />
                <p className="color-red">{errors.name?.message}</p>
              </div>
              <div className={`${Style.col6} px-0 mt-3`}>
                <span className={Style.labelHeader}>
                  {t("Category")}
                  <span style={{ color: "red" }}> *</span>
                </span>
                <Controller
                  name="category"
                  control={control}
                  render={({ field }) => (
                    <CategoryLoadComponent
                      control={control}
                      parentName={"category"}
                      loadOptions={loadOptions}
                    />
                  )}
                />
                <p className="color-red">{errors.category?.message || ""}</p>
              </div>
            </div>

            <div className={`row col-12 px-0 mx-0 mb-4`}>
              <div className={`${Style.col6} px-0 mt-3`}>
                <span className={`${Style.labelHeader} mb-2`}>
                  {t("Type")}
                  <span className="color-red"> *</span>
                </span>
                <FormControl fullWidth sx={{ mt: 1.3 }}>
                  <Controller
                    name="type"
                    control={control}
                    defaultValue={data?.type || "PDF"}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormControl>
                        <Select
                          labelId="type"
                          label=""
                          value={value}
                          onChange={(e: any) => {
                            onChange(e);
                            setSelectedTypeAssessment(e.target.value);
                          }}
                          style={{
                            color: interfaceTextColor,
                            background: interfaceBgColor,
                          }}
                          defaultValue={data?.type}
                          sx={{
                            height: "3.9em",
                            "& .MuiChip-root": {
                              color: primaryButton,
                              background: primaryButtonBg,
                            },
                          }}
                          MenuProps={{
                            sx: {
                              "&& .Mui-selected": {
                                color: light_text_color,
                                background: primary_color,
                              },
                              "&& .Mui-selected:hover": {
                                color: "black",
                                background: "skyblue",
                              },
                            },
                          }}
                          renderValue={(selected) => {
                            return (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected && (
                                  <Chip key={selected} label={selected} />
                                )}
                              </Box>
                            );
                          }}
                        >
                          {moduleType.map((row) => (
                            <MenuItem
                              value={row.value}
                              key={row.key}
                              className="d-flex"
                            >
                              <Radio
                                color="default"
                                sx={{
                                  color:
                                    getValues() && getValues().type
                                      ? (getValues()?.type as any).indexOf(
                                          row.value
                                        ) > -1
                                        ? "white"
                                        : ""
                                      : "",
                                }}
                                checked={
                                  getValues() && getValues().type
                                    ? (getValues()?.type as any).indexOf(
                                        row.value
                                      ) > -1
                                    : false
                                }
                                name="radio-buttons"
                              />
                              <div> {row.value}</div>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </FormControl>
                <p className="error color-red">{errors.type?.message}</p>
              </div>
              <div className={`${Style.col6} px-0 mt-3`}>
                <span className={Style.labelHeader}>
                  {t("Description")}
                  <span style={{ color: "red" }}> *</span>
                </span>
                <input
                  className={`${Style.inputfields} ${Style.fieldFont} ${
                    errors.description ? "is-invalid" : ""
                  }`}
                  style={{
                    color: interfaceTextColor,
                    background: interfaceBgColor,
                  }}
                  type="text"
                  placeholder={t("Description")}
                  autoComplete="off"
                  {...register("description")}
                />
                <p className="color-red">{errors.description?.message}</p>
              </div>
            </div>

            <div className={`row col-12 px-0 mx-0 mb-4`}>
              <div className={`${Style.col6} px-0`}>
                <span className={Style.labelHeader}>
                  {t("Level")}
                  <span style={{ color: "red" }}> *</span>
                </span>
                <FormControl sx={{ m: 0, width: "100%", mt: 1 }}>
                  <Controller
                    control={control}
                    defaultValue={level}
                    name="level"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Select
                        labelId="level"
                        id="level"
                        value={value}
                        style={{
                          color: interfaceTextColor,
                          background: interfaceBgColor,
                        }}
                        onChange={(event) => {
                          onChange(event);
                        }}
                        input={<OutlinedInput label="" />}
                        sx={{
                          height: "3.9em",
                          "& .MuiChip-root": {
                            color: primaryButton,
                            background: primaryButtonBg,
                          },
                        }}
                        renderValue={(selected) => {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected && (
                                <Chip key={selected} label={selected} />
                              )}
                            </Box>
                          );
                        }}
                        MenuProps={{
                          ...MenuProps,
                          sx: {
                            "&& .Mui-selected": {
                              color: light_text_color,
                              background: primary_color,
                            },
                            "&& .Mui-selected:hover": {
                              color: "black",
                              background: "skyblue",
                            },
                          },
                        }}
                        placeholder="Select"
                      >
                        {moduleLevel.map((row) => (
                          <MenuItem
                            value={row.value}
                            key={row.key}
                            className="d-flex"
                          >
                            <Radio
                              color="default"
                              sx={{
                                color:
                                  getValues() && getValues().level
                                    ? (getValues()?.level as any).indexOf(
                                        row.value
                                      ) > -1
                                      ? "white"
                                      : ""
                                    : "",
                              }}
                              checked={
                                getValues() && getValues().level
                                  ? (getValues()?.level as any).indexOf(
                                      row.value
                                    ) > -1
                                  : false
                              }
                              name="radio-buttons"
                            />
                            <div> {row.value}</div>
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <p className="color-red">{errors.level?.message || ""}</p>
              </div>
              <div className={`${Style.col6} px-0`}>
                <span className={Style.labelHeader}>
                  {t("Status")}
                  <span style={{ color: "red" }}> *</span>
                </span>
                <FormControl sx={{ m: 0, width: "100%", mt: 1 }}>
                  <Controller
                    control={control}
                    defaultValue={status}
                    name="status"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Select
                        labelId="status"
                        id="status"
                        value={value}
                        style={{
                          color: interfaceTextColor,
                          background: interfaceBgColor,
                        }}
                        onChange={(event) => {
                          onChange(event);
                        }}
                        input={<OutlinedInput label="" />}
                        sx={{
                          height: "3.9em",
                          "& .MuiChip-root": {
                            color: primaryButton,
                            background: primaryButtonBg,
                          },
                        }}
                        renderValue={(selected) => {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected && (
                                <Chip key={selected} label={selected} />
                              )}
                            </Box>
                          );
                        }}
                        MenuProps={{
                          ...MenuProps,
                          sx: {
                            "&& .Mui-selected": {
                              color: light_text_color,
                              background: primary_color,
                            },
                            "&& .Mui-selected:hover": {
                              color: "black",
                              background: "skyblue",
                            },
                          },
                        }}
                        placeholder={t("Select")}
                      >
                        {moduleStatus.map((row) => (
                          <MenuItem
                            value={row.value}
                            key={row.key}
                            className="d-flex"
                          >
                            <Radio
                              color="default"
                              sx={{
                                color:
                                  getValues() && getValues().status
                                    ? (getValues()?.status as any).indexOf(
                                        row.value
                                      ) > -1
                                      ? "white"
                                      : ""
                                    : "",
                              }}
                              checked={
                                getValues() && getValues().status
                                  ? (getValues()?.status as any).indexOf(
                                      row.value
                                    ) > -1
                                  : false
                              }
                              name="radio-buttons"
                            />
                            <div> {row.value}</div>
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <p className="color-red">{errors.status?.message || ""}</p>
              </div>
            </div>
            {selectedTypeAssessment &&
              (selectedTypeAssessment?.toLowerCase() === "assessment" ? (
                <AssessmentDialog
                  setParentOpen={setIsModalOpen}
                  open={isModalOpen}
                  onClose={handleClose}
                  isLoading={loading}
                  setParentCheckedQuestions={setAssessmentQuestions}
                  parentCheckedQuestions={assessmentQuestions}
                  setParentCheckedButNotAddedQuestions={
                    setParentCheckedButNotAddedQuestions
                  }
                  parentCheckedButNotAddedQuestions={
                    parentCheckedButNotAddedQuestions
                  }
                />
              ) : (
                <>
                  <div className={`row col-lg-12 px-0 mx-0 mb-4`}>
                    {selectedTypeAssessment?.toLowerCase() !== "scorm" && (
                      <div className={`${Style.col6} px-0`}>
                        <span className={Style.labelHeader}>
                          {t("File Name")}
                          <span style={{ color: "red" }}> *</span>
                        </span>
                        <input
                          className={`${Style.inputfields} ${Style.fieldFont} ${
                            errors.file_name ? "is-invalid" : ""
                          }`}
                          style={{
                            color: interfaceTextColor,
                            background: interfaceBgColor,
                          }}
                          type="text"
                          placeholder={t("File Name")}
                          autoComplete="off"
                          {...register("file_name")}
                        />
                        <p className="color-red">{errors.file_name?.message}</p>
                      </div>
                    )}

                    <div className={`${Style.col6} px-0 mb-3`}>
                      <span className={Style.labelHeader}>
                        {t("Upload File")}{" "}
                        <span style={{ color: "red" }}> *</span>
                      </span>

                      <ChooseFile
                        size="small"
                        maxFileSizeinBytes={
                          selectedTypeAssessment?.toLowerCase() == "scorm"
                            ? 104857600
                            : selectedTypeAssessment?.toLowerCase() == "pdf"
                            ? 20971520
                            : 2147483648
                        } // 5mb
                        fileTypes={acceptFiles}
                        loading={loading}
                        errors={errors}
                        setValue={setValue}
                        imageName={"file"}
                        clearErrors={clearErrors}
                        acceptedImageTypesArray={acceptFiles?.split(",")}
                        setImageParent={setPdf}
                      ></ChooseFile>
                      {id && data?.file && data?.type == watch("type") && (
                        <>
                          <div
                            className={`${Style.noteText2}`}
                            style={{
                              color:
                                defaultThemeColor.interface_foreground_text_color,
                            }}
                          >
                            {`${t("File")} ${
                              data?.file_name
                                ? data?.file_name?.length > 20
                                  ? "'" +
                                    data?.file_name.slice(0, 20) +
                                    "... .'"
                                  : "'" + data?.file_name + "'" || ""
                                : ""
                            } ${t("has already uploaded")} `}
                            <a href={data?.file}>{t("Click here to View")}</a>
                          </div>
                        </>
                      )}
                      <p className="error color-red">{errors.file?.message}</p>
                    </div>
                  </div>
                </>
              ))}
            <AssessmentDialog
              setParentOpen={setOpen}
              open={open}
              onClose={handleClose}
              isLoading={loading}
              setParentCheckedQuestions={setAssessmentQuestions}
              parentCheckedQuestions={assessmentQuestions}
              setParentCheckedButNotAddedQuestions={
                setParentCheckedButNotAddedQuestions
              }
              parentCheckedButNotAddedQuestions={
                parentCheckedButNotAddedQuestions
              }
              setType={() => {
                setType("");
              }}
              setSelectedRows={setSelectedAssessmentRows}
            />
            {!loading &&
              selectedTypeAssessment?.toLowerCase() == "assessment" && (
                <AddAssessments
                  id={id}
                  setSelectedRows={setSelectedAssessmentRows}
                  selectedRows={selectedAssessmentRows}
                  setDefaultRows={setDefaultAssessmentRows}
                  defaultRows={defaultAssessmentRows}
                  setOpen={setOpen}
                />
              )}

            <div
              className="d-flex flex-wrap gap-3 w-100 px-3 mt-5 align-items-center mb-5 pb-4"
              style={{
                justifyContent: lang === "ar" ? "flex-start" : "flex-end",
              }}
            >
              <SubmitButton label={t("Update")} marginTop={false} />
              <CancelButton
                size="small"
                outlined={true}
                onClick={urlWithQueryString}
                textColor=""
                outlineColor=""
                text={t("Cancel")}
                className={``}
                bg={""}
              ></CancelButton>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EditModule;
