import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import { useConfirm } from "material-ui-confirm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CancelButton from "../../components/CancelButton";
import DataTable from "../../components/table/DataTable";
import TableFilters from "../../components/table/TableFilters";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import usePermission from "../../hooks/usePermission";
import {
  bulkDeleteGroups,
  deleteGroup,
} from "../../services/groupManagementService";
import {
  getModuleListData,
  getWholeModuleListData,
} from "../../services/trainingsService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { groupManagementSelector } from "../../store/reducers/groupManagementReducer";
import { setWholeDataModule } from "../../store/reducers/trainingsReducer";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import { confirmProps } from "../../utils/confirmProps";
import Style from "./TrainingList/ModulesMemberLists.module.scss";

export interface SimpleDialogProps {
  setParentOpen: any;
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
  setSelectedRows: any;
  selectedRows: any;
  defaultRows: any;
}

function CreateModuleModal(props: SimpleDialogProps) {
  const {
    setParentOpen,
    open,
    onClose,
    isLoading,
    setSelectedRows,
    selectedRows,
    defaultRows,
  } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  const addPermission = usePermission("add_customusergroup");
  const editUpdatePermission = usePermission("change_customusergroup");
  const deletePermission = usePermission("delete_customusergroup");
  const viewPermission = usePermission("view_customusergroup");
  const [itemPerPage, setItemPerPage] = useState<number>(10);
  const [page, setPage] = useState(1);
  const [searchTable, setSearchTable] = useState("");

  const [list, setList] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<any[]>([]);
  const [selectedCheckBoxData, setSelectedCheckBoxData] = React.useState<any[]>(
    []
  );
  // const [page, setPage] = useState(1);
  const states = useSelector(groupManagementSelector).departmentReducer;
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;
  const [rows, setRows] = React.useState<any[]>([]);

  const dataList = useSelector(
    (state: RootState) => state.trainingsReducer.dataModule
  );

  const wholeData = useSelector(
    (state: RootState) => state.trainingsReducer.wholedataModule
  );
  const handleChangeSearch = (val: string) => {
    setPage(1);
    console.log("search ", val);
    setItemPerPage(Number(10));
    setSearchTable(String(val));
  };

  useEffect(() => {
    if (!open) {
      setItemPerPage(10);
      setPage(1);
    }
  }, [open]);

  useEffect(() => {
    let selected =
      selectedRows?.results?.length === 0 ? defaultRows : selectedRows?.results;
    let selectedIds = selected?.map((item: any) => item?.id) || [];
    setSelectedCheckBox([...selectedIds, ...selectedCheckBox]);
  }, [selectedRows, defaultRows, open]);

  useEffect(() => {
    modifyRows();
  }, [selectedRows, defaultRows]);

  useEffect(() => {
    if (selectedCheckBox?.length > 0) {
      setSelectedCheckBoxData([...selectedCheckBoxData, ...selectedCheckBox]);
      const updatedData = selectedCheckBox
        ?.map((selectedId) => {
          const foundItem = dataList?.results?.find(
            (item: any) => item.id === selectedId
          );
          return foundItem || null;
        })
        ?.filter(Boolean);

      setSelectedCheckBoxData((prevSelectedObjects) => {
        const uniqueUpdatedData = updatedData?.filter(
          (item) =>
            !prevSelectedObjects?.some((prevItem) => prevItem?.id === item.id)
        );

        return [...prevSelectedObjects, ...uniqueUpdatedData].filter(
          (i: any) => typeof i == "object"
        );
      });
    } else {
      setSelectedCheckBoxData([]);
    }
  }, [selectedCheckBox, dataList]);

  useEffect(() => {
    dispatch(
      getModuleListData({
        page_size: Number(itemPerPage),
        page: Number(page),
        search: searchTable,
        status: "Approved",
      })
    );
  }, [dispatch, itemPerPage, page, searchTable]);

  useEffect(() => {
    let data: any = dataList?.results;
    if (Object.keys(dataList).length === 0) {
      setRows([]);
    } else setRows(data);
  }, [dataList]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      setSelectedCheckBox([]);
      dispatch(
        getModuleListData({
          page_size: Number(itemPerPage),
          page: Number(page),
          search: searchTable,
          status: "Approved",
        })
      );
    }
  }, [alertMessage]);

  const handleDeleteAction = (row: any) => {
    confirm({
      title: t("Are you sure!"),
      description: `Do you want to delete ${row?.name} group?`,
      ...confirmProps,
    })
      .then(() => {
        dispatch(deleteGroup(row.id));
      })
      .catch(() => {
        // toast.success("Your request canceled successfully!", {
        //   toastId: "Your request canceled successfully!",
        // });
      });
    return;
  };

  useEffect(() => {
    if (selectedRows.count > selectedCheckBoxData.length) {
      setSelectedCheckBoxData(selectedRows.results);
    }
  }, [selectedRows, open]);

  const handleAdd = () => {
    let f_rows: any = [];

    selectedCheckBoxData?.forEach((element: any) => {
      if (defaultRows && defaultRows.length > 0) {
        f_rows = [
          ...f_rows,
          defaultRows?.find((i: any) => i?.id == element?.id) || element,
        ];
      } else if (
        selectedRows &&
        selectedRows?.results &&
        selectedRows?.results?.length > 0
      ) {
        f_rows = [
          ...f_rows,
          selectedRows?.results?.find((i: any) => i?.id == element?.id) ||
            element,
        ];
      } else {
        f_rows = selectedCheckBoxData;
      }
    });

    const updatedDataList = {
      // ...dataList,
      count: f_rows?.length,
      results: f_rows,
    };
    setSelectedRows(updatedDataList);
    handleClose();
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPage(1);
    setItemPerPage(Number(rowsPerPage));
  };

  // const handleClose = () => {
  //   setParentOpen(false);
  // };

  useEffect(() => {
    modifyRows();
  }, [rows]);

  const bulkDelete = (ids: any) => {
    confirm({
      title: t("Are you sure!"),
      description: t(`Do you want to delete selected groups?`),
      ...confirmProps,
    })
      .then(() => {
        dispatch(bulkDeleteGroups(ids));
        setSelectedCheckBox([]);
      })
      .catch(() => {});
  };

  const modifyRows = () => {
    let f_rows: any = [];
    rows.forEach(
      (
        r: {
          id: string;
          name: string;
          category: any;
          type: string;
          status: string;
          is_compulsory: string;
        },
        i: number
      ) => {
        f_rows = [
          ...f_rows,
          {
            id: r.id,
            name: r?.name,
            category: r?.category?.name ? r?.category?.name : r?.category,
            type: r?.type,
            status: (
              <span
                className={
                  r?.status?.toLowerCase() === "approved"
                    ? Style.statusSuccess
                    : Style.statusDraft
                }
              >
                {r.status}
              </span>
            ),
            // compulsory:
            //   selectedRows?.results?.find((i: any) => i?.id == r?.id)
            //     ?.is_compulsory ||
            //   defaultRows?.find((i: any) => i?.id == r?.id)?.is_compulsory
            //     ? "Yes"
            //     : "No",
          },
        ];
      }
    );
    setList(f_rows);
  };

  const handleClose = () => {
    if (isLoading) {
    } else {
      onClose();
      setSelectedCheckBox([]);
    }
  };

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;

  const handleClicked = () => {
    getWhole();
  };

  const getWhole = () => {
    if (dataList)
      dispatch(
        getWholeModuleListData({
          status: "Approved",
          page_size: dataList.count || 1,
          page: 1,
          search: "",
        })
      );
  };

  return (
    <Dialog
      onClose={handleClose}
      maxWidth={"lg"}
      open={open && isLoading ? true : open}
      style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
    >
      <div
        className="d-flex justify-content-between"
        style={{ color: interfaceTextColor, background: interfaceBgColor }}
      >
        <DialogTitle
          style={{
            paddingTop: "1em",
            fontSize: 22,
          }}
        >
          {t("Add Modules")}
        </DialogTitle>
        <div className="p-3">
          <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
        </div>
      </div>
      <DialogContent
        style={{ color: interfaceTextColor, background: interfaceBgColor }}
      >
        <TableFilters
          dataList={dataList}
          itemPerPage={itemPerPage}
          selectedCheckBox={selectedCheckBox}
          bulkDelete={() => {}}
          setItemPerPage={handleChangeRowsPerPage}
          setSearchTable={handleChangeSearch}
          createButton={<></>}
          handle="groups"
          isCheckboxes={false}
          exportCSV={false}
          setParentData={() => dispatch(setWholeDataModule({}))}
          handleClicked={handleClicked}
          rows={wholeData?.results?.map((r: any, i: any) => ({
            // id: r.id,
            name: r?.name,
            category: r?.category?.name ? r?.category?.name : r?.category,
            type: r?.type,
            status: r.status,
            compulsory: r.compulsory,
          }))}
          headers={[t("Name"), t("Category"), t("Type"), t("Status")]}
          filename={`modules_list.csv`}
        />
        <DataTable
          columnData={[
            {
              id: "name",
              name: t("Name"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "category",
              name: t("Category"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "type",
              name: t("Type"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "status",
              name: t("Status"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            // {
            //   id: "compulsory",
            //   name: "Compulsory",
            //   enableSort: false,
            //   align: "center",
            //   disablePadding: "none",
            // },
          ]}
          rows={list}
          rowsScroll={true}
          minHeight={"25em"}
          isCheckboxes={true}
          selectedRowsPerPage={itemPerPage.toString()}
          totalRecordCount={Number(dataList.count)}
          handleChangePageParent={handleChangePage}
          pagenum={Number(page)}
          displayForceBottomComponent={true}
          handleChangeRowsPerPageParent={handleChangeRowsPerPage}
          bulkDelete={bulkDelete}
          selectedCheckBox={selectedCheckBox}
          setSelectedCheckBox={setSelectedCheckBox}
          bottomButtonsComponent={
            <div className="d-flex flex-wrap gap-3 w-100 px-3 mb-2 align-items-center justify-content-end ">
              <button
                type="button"
                className={`btn mt-4 ${Style.addBtn}`}
                style={{
                  color:
                    useColor("button_primary_text") ||
                    defaultThemeColor.button_secondary_text,
                  background:
                    useColor("button_primary") ||
                    defaultThemeColor.button_secondary,
                  border: "none",
                  outline: "none",
                }}
                onClick={handleAdd}
                disabled={selectedCheckBox?.length == 0}
              >
                {t("Add to Training")}
              </button>
              <CancelButton
                size="small"
                outlined={true}
                onClick={() => {
                  setSelectedCheckBox([]);
                  handleClose();
                }}
                textColor=""
                outlineColor=""
                text={t("Cancel")}
                className={`mt-4`}
                bg={""}
                // disabled={selectedCheckBox?.length == 0}
              ></CancelButton>
            </div>
          }
        />
      </DialogContent>
    </Dialog>
  );
}

export default CreateModuleModal;
