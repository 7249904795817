import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import { getPersonalForm } from "../../store/reducers/personalFormReducer";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import UserPermission from "../adminUsers/AdminPermission/UserPermission";
import Style from "./MyProfile.module.scss";

const $ = require("jquery");
interface Params {
  detail: any;
  role?: any;
}

const ViewProfile = ({ detail, role }: Params) => {
  const t = useTranslation();
  const lang = useCompanyLanguage();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPersonalForm({ is_personal: 1 }));
  }, []);

  const personalFormData: any = useSelector(
    (state: RootState) => state.personalFormReducer.personal_form_data
  );

  const FieldCover = (props: {
    header: string;
    asterisk: boolean;
    value: string;
  }) => {
    return (
      <div
        className={`${Style.fieldBackgroundCover} mb-4 mx-4 d-flex flex-wrap`}
      >
        <div
          className={`${Style.label} ${Style.wfifty}`}
          style={{
            color:
              useColor("interface_foreground_text_color") ||
              defaultThemeColor.interface_foreground_text_color,
          }}
        >
          {props.header}{" "}
          {props.asterisk ? <span className="color-red">*</span> : null}
        </div>
        <div
          className={`${Style.fieldValue} ${Style.wfifty}`}
          style={{
            color:
              useColor("interface_foreground_text_color") ||
              defaultThemeColor.interface_foreground_text_color,
          }}
        >
          {props.value}
        </div>
      </div>
    );
  };

  return (
    <div className={"pb-3"}>
      {role == "trainee" && (
        <FieldCover
          header={t("Company/ Business Name")}
          asterisk={false}
          value={detail?.sign_up_data?.company_business_name || "N/A"}
        ></FieldCover>
      )}
      <FieldCover
        header={t("First Name")}
        asterisk={false}
        value={detail?.first_name || "N/A"}
      ></FieldCover>
      <FieldCover
        header={t("Last Name")}
        asterisk={false}
        value={detail?.last_name || "N/A"}
      ></FieldCover>
      <FieldCover
        header={t("Email")}
        asterisk={false}
        value={detail?.email || "N/A"}
      ></FieldCover>
      <FieldCover
        header={t("Phone Number")}
        asterisk={false}
        value={
          (detail?.sign_up_data && detail?.sign_up_data?.phone_no) ||
          (typeof detail?.sign_up_data == "string" &&
            JSON.parse(detail?.sign_up_data).phone_no) ||
          "N/A"
        }
      ></FieldCover>
      {personalFormData &&
      Array.isArray(personalFormData) &&
      personalFormData.length > 0 &&
      personalFormData.find(
        (i: any) => i?.field_label == "gender" && !i?.is_disable
      ) ? (
        <FieldCover
          header={t("Gender")}
          asterisk={false}
          value={
            detail?.personal_detail_data?.gender == "male"
              ? "Male"
              : detail?.personal_detail_data?.gender == "female"
              ? "Female"
              : "N/A"
          }
        ></FieldCover>
      ) : null}
      {personalFormData &&
      Array.isArray(personalFormData) &&
      personalFormData.length > 0 &&
      personalFormData.find(
        (i: any) => i?.field_label == "date_of_birth" && !i?.is_disable
      ) ? (
        <FieldCover
          header={t("Date of Birth")}
          asterisk={false}
          value={
            detail?.personal_detail_data?.date_of_birth
              ? moment(
                  new Date(detail?.personal_detail_data?.date_of_birth)
                ).format("MMM Do, YYYY")
              : "N/A"
          }
        ></FieldCover>
      ) : null}
      {personalFormData &&
      Array.isArray(personalFormData) &&
      personalFormData.length > 0 &&
      personalFormData.find(
        (i: any) => i?.field_label == "address" && !i?.is_disable
      ) ? (
        <FieldCover
          header={t("Address")}
          asterisk={false}
          value={
            (detail?.personal_detail_data?.address
              ? "H-No. " + detail?.personal_detail_data?.address
              : "") +
              (detail?.personal_detail_data?.street_number
                ? "/ St.No. " +
                  detail?.personal_detail_data?.street_number +
                  ", "
                : "") +
              (detail?.personal_detail_data?.city
                ? detail?.personal_detail_data?.city + ", "
                : "") +
              (detail?.personal_detail_data?.region
                ? detail?.personal_detail_data?.region + ", "
                : "") +
              (detail?.personal_detail_data?.country
                ? detail?.personal_detail_data?.country + ", "
                : "") || "N/A"
          }
        ></FieldCover>
      ) : null}
      {personalFormData &&
      Array.isArray(personalFormData) &&
      personalFormData.length > 0 &&
      personalFormData.find(
        (i: any) => i?.field_label == "country" && !i?.is_disable
      ) ? (
        <FieldCover
          header={t("Country")}
          asterisk={false}
          value={
            (detail?.personal_detail_data?.country
              ? detail?.personal_detail_data?.country
              : "") || "N/A"
          }
        ></FieldCover>
      ) : null}
      {personalFormData &&
      Array.isArray(personalFormData) &&
      personalFormData.length > 0 &&
      personalFormData.find(
        (i: any) => i?.field_label == "department" && !i?.is_disable
      ) ? (
        <FieldCover
          header={t("Department")}
          asterisk={false}
          value={detail?.department?.name || "N/A"}
        ></FieldCover>
      ) : null}
      {personalFormData &&
      Array.isArray(personalFormData) &&
      personalFormData.length > 0 &&
      personalFormData.find(
        (i: any) => i?.field_label == "designation" && !i?.is_disable
      ) ? (
        <FieldCover
          header={t("Designation")}
          asterisk={false}
          value={detail?.personal_detail_data?.designation || "N/A"}
        ></FieldCover>
      ) : null}

      {personalFormData &&
      Array.isArray(personalFormData) &&
      personalFormData.length > 0 &&
      personalFormData.find(
        (i: any) => i?.field_label == "experience_level" && !i?.is_disable
      ) ? (
        <FieldCover
          header={t("Experience Level")}
          asterisk={false}
          value={detail?.personal_detail_data?.experience_level || "N/A"}
        ></FieldCover>
      ) : null}
      <div
        className={`col-lg-12`}
        style={{
          paddingBottom: "1em",
        }}
      >
        <div className={`${Style.changePass} col-lg-6`}>
          <Link to="/change-profile-password">{t("Change password")}</Link>
        </div>
      </div>
      {detail?.role?.toLowerCase() === "admin" && (
        <>
          <div className={`${Style.leftSpace2} ${Style.topSpace} mb-4`}>
            <div className={`${Style.textHeader}`} style={{}}>
              {t("Permissions")}
            </div>
          </div>
          {detail?.permission_list && detail?.permission_list?.length > 0 ? (
            <UserPermission permissionList={detail?.permission_list} />
          ) : (
            <p style={{ paddingLeft: "40px" }}> {t("Record not found")}</p>
          )}
        </>
      )}
    </div>
  );
};
export default ViewProfile;
