function DeleteSVG(props: { color: string }) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 24 24"
      fill={props.color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 9H10.5V18H9V9ZM13.5 9H15V18H13.5V9Z" fill={props.color} />
      <path
        d="M3 4.85714V6.28571H4.5V20.5714C4.5 20.9503 4.65804 21.3137 4.93934 21.5816C5.22064 21.8495 5.60218 22 6 22H18C18.3978 22 18.7794 21.8495 19.0607 21.5816C19.342 21.3137 19.5 20.9503 19.5 20.5714V6.28571H21V4.85714H3ZM6 20.5714V6.28571H18V20.5714H6ZM9 2H15V3.42857H9V2Z"
        fill={props.color}
      />
    </svg>
  );
}

export default DeleteSVG;
