import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface UserError {
  message: string;
}

export interface GetUsers {
  search: string;
  role: string;
  primary_role: string;
  status: string;
  page: number;
  page_size: number;
  department__id?: string;
  created_at_from?: string;
  created_at_to?: string;
  updated_at_from?: string;
  updated_at_to?: string;
}

export interface AddUser {
  roles: string;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export interface UpdateUser {
  id: string;
  status: string;
  email: string;
  password: string;
}

export interface UpdateUserDetails {
  image: any;
  imagename: string;
  id: number | any;
  details: any;
}

export interface UpdateUserPic {
  image: any;
  imagename: string;
  id: number | any;
}

export interface DeleteUser {
  id: string;
}

export interface UserState {
  isLoading: boolean;
  error?: UserError;
  errors?: any;
  user_data: GetUser | any;
  update_user_details: UpdateUserDetails | any;
  update_user_pic: UpdateUserPic | any;
  add_user_data: AddUser | any;
  user_list_data: GetUsers | any;
  update_user_data: UpdateUser | any;
  delete_user_data: DeleteUser | any;
  user_list_whole_data: GetUsers | any;
  active_directory_users: any;
}

export const initialState: UserState = {
  isLoading: false,
  user_data: {},
  add_user_data: {},
  user_list_data: {},
  update_user_data: {},
  delete_user_data: {},
  update_user_details: {},
  update_user_pic: {},
  user_list_whole_data: {},
  active_directory_users: {},
};

export interface GetUser {
  id: any;
}
export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },

    setAuthFailed: (state, { payload }) => {
      state.error = payload;
    },

    setAuthSignUpFailed: (state, { payload }) => {
      state.errors = payload;
    },

    setAddUserData: (state, { payload }) => {
      state.add_user_data = payload;
    },

    setUserListData: (state, { payload }) => {
      state.user_list_data = payload;
    },
    setWholeUserListData: (state, { payload }) => {
      state.user_list_whole_data = payload;
    },
    setActiveDirectoryUsers: (state, { payload }) => {
      state.active_directory_users = payload;
    },

    setAddUserFailed: (state, { payload }) => {
      state.errors = payload;
    },

    setUpdateUserData: (state, { payload }) => {
      state.update_user_data = payload;
    },

    setUpdateUserFailed: (state, { payload }) => {
      state.errors = payload;
    },

    setDeleteUserData: (state, { payload }) => {
      state.delete_user_data = payload;
    },

    setDeleteUserFailed: (state, { payload }) => {
      state.errors = payload;
    },

    setGetUserDataFailed: (state, { payload }) => {
      state.errors = payload;
    },

    setUserData: (state, { payload }) => {
      state.user_data = payload;
    },

    setUpdateUserDetails: (state, { payload }) => {
      state.update_user_details = payload;
    },
  },
});

export const {
  setAuthFailed,
  setAddUserFailed,
  setAddUserData,
  setUserListData,
  setWholeUserListData,
  setGetUserDataFailed,
  setUpdateUserData,
  setUpdateUserFailed,
  setDeleteUserData,
  setDeleteUserFailed,
  setUserData,
  setUpdateUserDetails,
  setActiveDirectoryUsers,
} = usersSlice.actions;

export const userSelector = (state: RootState) => state;

export default usersSlice.reducer;
