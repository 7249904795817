import AddIcon from "@mui/icons-material/Add";
import DatasetLinkedOutlinedIcon from "@mui/icons-material/DatasetLinkedOutlined";
import { Button } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import DataTable from "../../components/table/DataTable";
import TableFilters from "../../components/table/TableFilters";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import usePermission from "../../hooks/usePermission";
import DeleteSVG from "../../icons/DeleteSVG";
import EditSVG from "../../icons/EditSVG";
import ViewSVGAction from "../../icons/ViewSVGAction";
import {
  bulkDeleteQuestions,
  deleteQuestion,
  getListData,
  getWholeListData,
} from "../../services/questionsService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { setWholeData } from "../../store/reducers/questionReducer";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import { confirmProps } from "../../utils/confirmProps";
import Style from "./QuestionsList.module.scss";
function QuestionsList() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const addPermission = usePermission("add_question");
  const editUpdatePermission = usePermission("change_question");
  const deletePermission = usePermission("delete_question");
  const viewPermission = usePermission("view_question");
  const searchParams = new URLSearchParams(document.location.search);
  const [pageNum, setPageNum] = useState(Number(searchParams.get("page") || 1));
  const [perPageNum, setPerPageNum] = useState(
    Number(searchParams.get("perpage")) || 10
  );
  const [pageSearch, setPageSearch] = useState(
    searchParams.get("search") || ""
  );
  const [list, setList] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<any[]>([]);

  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;

  const dataList: any = useSelector(
    (state: RootState) => state.questionReducer.data
  );
  const wholedata: any = useSelector(
    (state: RootState) => state.questionReducer.wholedata
  );
  const userInfo = useSelector((state: any) => state.authReducer.loginData);
  useEffect(() => {
    AllData();
  }, [
    searchParams.get("page"),
    searchParams.get("perpage"),
    searchParams.get("search"),
  ]);
  // useEffect(() => {
  //   let data: any = dataList.results;
  //   if (Object.keys(dataList).length === 0) {
  //     setRows([]);
  //   } else {
  //     setRows(data);
  //     if (!(itemPerPage == 10 || itemPerPage == 15 || itemPerPage == 25)) {
  //       setItemPerPage(dataList?.count);
  //     }
  //   }
  // }, [dataList]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      setSelectedCheckBox([]);
      AllData();
    }
  }, [alertMessage]);
  const AllData = () => {
    let query: any = {};
    query.page = Number(searchParams.get("page") || 1);

    query.page_size = Number(searchParams.get("perpage") || 10);

    query.search = String(searchParams.get("search") || "");
    if (userInfo?.primary_role == "Trainer") {
      query.question_status = "Approved";
    }
    dispatch(getListData(query));
  };
  const handleDeleteAction = (row: any) => {
    return confirm({
      title: t("Are you sure!"),
      description: t(`Do you want to delete this question?`),
      ...confirmProps,
    })
      .then(() => {
        dispatch(deleteQuestion(row.id));
        if (dataList?.results?.length === 1) {
          urlWithQueryString(1, 10, pageSearch);
        }
      })
      .catch(() => {});
  };
  const urlWithQueryString = (
    pageVal = Number(pageNum),
    perPageVal = Number(perPageNum),
    searchVal = String(pageSearch)
  ) => {
    let object: any = {};
    if (pageVal) {
      object.page = pageVal;
    }
    if (perPageVal) {
      object.perpage = perPageVal;
    }
    if (searchVal) {
      object.search = searchVal;
    }

    const objString = "?" + new URLSearchParams(object).toString();

    navigate(`/questions-library${objString}`);
  };
  const handleChangePage = (event: React.ChangeEvent<unknown>, val: number) => {
    setPageNum(Number(val));
    urlWithQueryString(Number(val), Number(perPageNum), pageSearch);
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPageNum(1);
    setPerPageNum(Number(rowsPerPage));
    urlWithQueryString(1, Number(rowsPerPage), pageSearch);
  };
  const handleChangeSearch = (val: string) => {
    setPageNum(1);
    setPageSearch(String(val));
    setPerPageNum(Number(10));
    urlWithQueryString(1, perPageNum, String(val));
  };
  useEffect(() => {
    modifyRows();
  }, [dataList]);

  const bulkDelete = (ids: any) => {
    confirm({
      title: t("Are you sure!"),
      description: t(`Do you want to delete selected questions?`),
      ...confirmProps,
    })
      .then(() => {
        dispatch(bulkDeleteQuestions(ids));
        setSelectedCheckBox([]);
        urlWithQueryString(1, 10, pageSearch);
      })
      .catch(() => {
        setSelectedCheckBox([]);
      });
  };

  const modifyRows = () => {
    let f_rows: any = [];
    dataList?.results?.forEach(
      (
        r: {
          id: string;
          question_text: string;
          category: any;
          question_type: string;
          question_status: string;
          created_at: string;
          updated_at: string;
          is_editable: boolean;
          created_by: any;
        },
        i: number
      ) => {
        f_rows =
          userInfo?.primary_role == "Admin"
            ? [
                ...f_rows,
                {
                  id: r.id,
                  question: (
                    <div style={{ minWidth: "19em" }}>{r.question_text}</div>
                  ),
                  type: r.question_type == "TF" ? "T/F" : "MCQs",
                  category: r?.category?.name || "N/A",
                  created_by: r?.created_by?.first_name
                    ? r?.created_by?.first_name + " " + r?.created_by?.last_name
                    : "N/A",
                  date_created:
                    moment(new Date(r.created_at)).format("MMM Do, YYYY") || "",
                  date_modified:
                    moment(new Date(r.updated_at)).format("MMM Do, YYYY") || "",
                  status: (
                    <span
                      className={
                        r.question_status?.toLowerCase() == "approved"
                          ? Style.statusSuccess
                          : Style.statusDraft
                      }
                    >
                      {r.question_status?.toLowerCase() == "approved"
                        ? "Approved"
                        : "Draft"}
                    </span>
                  ),
                  actions: (
                    <div style={{ minWidth: "13em" }}>
                      {viewPermission && (
                        <Link
                          className=""
                          to={`/question-link/${r.id.toString()}`}
                        >
                          <button className="btn-link" type="button">
                            <DatasetLinkedOutlinedIcon htmlColor="#424F97" />
                          </button>
                        </Link>
                      )}
                      {viewPermission && (
                        <Link
                          className=""
                          to={`/question-detail/${r.id.toString()}`}
                        >
                          <button className="btn-link" type="button">
                            <ViewSVGAction />
                          </button>
                        </Link>
                      )}
                      {editUpdatePermission && (
                        <Link
                          className=""
                          to={`/update-question/${r.id.toString()}`}
                        >
                          <button className="btn-link" type="button">
                            <EditSVG />
                          </button>
                        </Link>
                      )}
                      {deletePermission && (
                        <button
                          className="btn-link"
                          type="button"
                          onClick={() => {
                            handleDeleteAction(r);
                          }}
                        >
                          <DeleteSVG color="red" />
                        </button>
                      )}
                    </div>
                  ),
                },
              ]
            : [
                ...f_rows,
                {
                  id: r.id,
                  question: (
                    <div style={{ minWidth: "19em" }}>{r.question_text}</div>
                  ),
                  type: r.question_type == "TF" ? "T/F" : "MCQs",
                  category: r?.category?.name || "N/A",

                  date_created:
                    moment(new Date(r.created_at)).format("MMM Do, YYYY") || "",
                  date_modified:
                    moment(new Date(r.updated_at)).format("MMM Do, YYYY") || "",
                  status: (
                    <span
                      className={
                        r.question_status?.toLowerCase() == "approved"
                          ? Style.statusSuccess
                          : Style.statusDraft
                      }
                    >
                      {r.question_status?.toLowerCase() == "approved"
                        ? "Approved"
                        : "Draft"}
                    </span>
                  ),
                  actions: (
                    <div style={{ minWidth: "13em" }}>
                      {viewPermission && (
                        <Link
                          className=""
                          to={`/question-detail/${r.id.toString()}`}
                        >
                          <button className="btn-link" type="button">
                            <ViewSVGAction />
                          </button>
                        </Link>
                      )}
                      {editUpdatePermission && (
                        <Link
                          className=""
                          to={`/update-question/${r.id.toString()}`}
                        >
                          <button className="btn-link" type="button">
                            <EditSVG />
                          </button>
                        </Link>
                      )}
                      {deletePermission && (
                        <button
                          className="btn-link"
                          type="button"
                          onClick={() => {
                            handleDeleteAction(r);
                          }}
                        >
                          <DeleteSVG color="red" />
                        </button>
                      )}
                    </div>
                  ),
                },
              ];
      }
    );
    setList(f_rows);
  };

  const AddQuestion = () => {
    const handleAddNew = () => {
      navigate("/create-question");
    };

    const interfaceBgTextColor =
      useColor("interface_background_text_color") ||
      defaultThemeColor.interface_background_text_color;

    return (
      <div className="d-flex flex-row gap-3">
        {addPermission && (
          <>
            <Button
              variant="text"
              color="inherit"
              endIcon={
                <AddIcon
                  style={{
                    fontSize: 16,
                    color: interfaceBgTextColor,
                    marginRight: lang === "ar" ? "10px" : "0px",
                  }}
                />
              }
              style={{
                fontSize: 13,
                color: interfaceBgTextColor,
                textTransform: "capitalize",
              }}
              onClick={() => handleAddNew()}
            >
              {t("Add New")}
            </Button>
          </>
        )}
      </div>
    );
  };

  const handleClicked = () => {
    getWhole();
  };

  const getWhole = () => {
    if (dataList)
      dispatch(
        getWholeListData({
          page_size: dataList.count,
          page: 1,
          search: "",
        })
      );
  };

  return (
    <>
      <Header
        page={t("Questions")}
        mainHead={t("Training Management / Questions Library")}
      />
      <TableFilters
        dataList={dataList}
        itemPerPage={perPageNum}
        selectedCheckBox={selectedCheckBox}
        bulkDelete={bulkDelete}
        setItemPerPage={handleChangeRowsPerPage}
        setSearchTable={handleChangeSearch}
        createButton={<AddQuestion />}
        handle="groups"
        isCheckboxes={deletePermission}
        exportCSV={dataList?.count ? true : false}
        setParentData={() => dispatch(setWholeData({}))}
        handleClicked={handleClicked}
        rows={wholedata?.results?.map((r: any, i: any) => ({
          // id: r.id,
          question: r.question_text,
          type: r.question_type == "TF" ? "T/F" : "MCQs",
          category: r?.category?.name || "N/A",
          date_created:
            moment(new Date(r.created_at)).format("MMM Do, YYYY") || "",
          date_modified:
            moment(new Date(r.updated_at)).format("MMM Do, YYYY") || "",
          status:
            r.question_status?.toLowerCase() == "approved"
              ? "Approved"
              : "Draft",
        }))}
        headers={[
          // "ID",
          t("Question"),
          t("Type"),
          t("Category"),
          t("Date Created"),
          t("Date Modified"),
          t("Status"),
        ]}
        filename={`questions_list.csv`}
      />

      <DataTable
        columnData={
          userInfo?.primary_role == "Admin"
            ? [
                {
                  id: "question",
                  name: t("Question"),
                  enableSort: true,
                  align: lang === "ar" ? "center" : "left",
                  disablePadding: "none",
                },
                {
                  id: "type",
                  name: t("Type"),
                  enableSort: true,
                  align: lang === "ar" ? "center" : "left",
                  disablePadding: "none",
                },
                {
                  id: "category",
                  name: t("Category"),
                  enableSort: true,
                  align: lang === "ar" ? "center" : "left",
                  disablePadding: "none",
                },
                {
                  id: "created_by",
                  name: t("Created By"),
                  enableSort: true,
                  align: lang === "ar" ? "center" : "left",
                  disablePadding: "none",
                },
                {
                  id: "date_created",
                  name: t("Date Created"),
                  enableSort: true,
                  align: lang === "ar" ? "center" : "left",
                  disablePadding: "none",
                },
                {
                  id: "date_modified",
                  name: t("Date Modified"),
                  enableSort: true,
                  align: lang === "ar" ? "center" : "left",
                  disablePadding: "none",
                },
                {
                  id: "status",
                  name: t("Status"),
                  enableSort: false,
                  align: "center",
                  disablePadding: "checkbox",
                },
                {
                  id: "actions",
                  name: t("Actions"),
                  enableSort: false,
                  align: "center",
                  disablePadding: "none",
                },
              ]
            : [
                {
                  id: "question",
                  name: t("Question"),
                  enableSort: true,
                  align: lang === "ar" ? "center" : "left",
                  disablePadding: "none",
                },
                {
                  id: "type",
                  name: t("Type"),
                  enableSort: true,
                  align: lang === "ar" ? "center" : "left",
                  disablePadding: "none",
                },
                {
                  id: "category",
                  name: t("Category"),
                  enableSort: true,
                  align: lang === "ar" ? "center" : "left",
                  disablePadding: "none",
                },

                {
                  id: "date_created",
                  name: t("Date Created"),
                  enableSort: true,
                  align: lang === "ar" ? "center" : "left",
                  disablePadding: "none",
                },
                {
                  id: "date_modified",
                  name: t("Date Modified"),
                  enableSort: true,
                  align: lang === "ar" ? "center" : "left",
                  disablePadding: "none",
                },
                {
                  id: "status",
                  name: t("Status"),
                  enableSort: false,
                  align: "center",
                  disablePadding: "checkbox",
                },
                {
                  id: "actions",
                  name: t("Actions"),
                  enableSort: false,
                  align: "center",
                  disablePadding: "none",
                },
              ]
        }
        rows={list}
        isCheckboxes={deletePermission}
        selectedRowsPerPage={String(searchParams.get("perpage") || 10)}
        totalRecordCount={Number(dataList.count)}
        handleChangePageParent={handleChangePage}
        pagenum={Number(searchParams.get("page") || 1)}
        handleChangeRowsPerPageParent={handleChangeRowsPerPage}
        bulkDelete={bulkDelete}
        selectedCheckBox={selectedCheckBox}
        setSelectedCheckBox={setSelectedCheckBox}
        // bottomButtonsComponent={
        //   <div className="d-flex flex-wrap gap-3 w-100 px-3 mt-4 mb-5 align-items-center justify-content-end ">
        //     <SubmitButton
        //       label="ADD TO ASSESSMENT"
        //       marginTop={false}
        //     ></SubmitButton>
        //     <CancelButton
        //       size="large"
        //       outlined={true}
        //       onClick={() => {
        //         setSelectedCheckBox([]);
        //       }}
        //       textColor=""
        //       outlineColor=""
        //       text="Cancel"
        //       className=""
        //       bg={""}
        //     ></CancelButton>
        //   </div>
        // }
      />
    </>
  );
}

export default QuestionsList;
