function EditSVG(props: { color?: string }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={props.color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1809 2.92708C16.9074 2.63945 16.5791 2.40947 16.2153 2.25067C15.8516 2.09187 15.4597 2.00747 15.0628 2.00244C14.6659 1.9974 14.2721 2.07184 13.9044 2.22137C13.5367 2.37089 13.2027 2.59248 12.9219 2.87308L3.54693 12.2481C3.21925 12.5757 2.9917 12.9898 2.89093 13.4421L2.01393 17.3921C1.99563 17.4742 1.99833 17.5595 2.02179 17.6403C2.04524 17.7211 2.08868 17.7946 2.1481 17.8541C2.20752 17.9137 2.28099 17.9572 2.36172 17.9808C2.44245 18.0044 2.52782 18.0072 2.60993 17.9891L6.53693 17.1161C7.00437 17.012 7.43244 16.7768 7.77093 16.4381L17.1289 7.08008C17.6774 6.53132 17.9899 5.78998 17.9996 5.01415C18.0093 4.23832 17.7155 3.4894 17.1809 2.92708ZM13.6289 3.58008C13.8118 3.39452 14.0297 3.24699 14.2698 3.14599C14.51 3.04499 14.7678 2.99251 15.0284 2.99158C15.2889 2.99064 15.5471 3.04127 15.788 3.14055C16.0289 3.23983 16.2477 3.38579 16.432 3.57003C16.6162 3.75427 16.7622 3.97314 16.8615 4.21404C16.9607 4.45493 17.0114 4.71309 17.0104 4.97364C17.0095 5.23419 16.957 5.49199 16.856 5.73216C16.755 5.97234 16.6075 6.19016 16.4219 6.37308L15.7509 7.04408L12.9579 4.25208L13.6289 3.58008ZM12.2509 4.96008L15.0439 7.75208L7.06393 15.7321C6.85988 15.9364 6.60179 16.0783 6.31993 16.1411L3.15993 16.8431L3.86793 13.6601C3.92693 13.3931 4.06093 13.1491 4.25393 12.9561L12.2509 4.96008Z"
        fill={props.color}
        stroke={props.color}
        strokeWidth="0.5"
      />
    </svg>
  );
}

export default EditSVG;
