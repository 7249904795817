import moment from "moment";

interface Params {
  row: any;
  count: number;
  num: number;
}
const DetailList = ({ row, count, num }: Params) => {
  const html = (r: any, n: number, nn: number) => {
    return <DetailList row={r} count={n} num={nn} key={r.id * n} />;
  };

  const handleNum = (n: number) => {
    num = n + 1;
  };

  return (
    <>
      <tr key={row?.id}>
        <td>
          <p style={{ marginLeft: `${Number(num) * 25}px` }}>{row?.name}</p>
        </td>
        <td>{row?.members}</td>
        <td>
          {moment(new Date(row?.created_at)).format("MMM Do, YYYY") || ""}
        </td>
      </tr>
      {row?.children?.length > 0 && handleNum(num)}
      {row?.children?.length > 0 &&
        row?.children?.map((rr: any, index: number) =>
          html(rr, index + 1, num)
        )}
    </>
  );
};

export default DetailList;
