function ViewSVGAction() {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3334 5C18.5834 5 22.3334 12 22.3334 12C22.3334 12 18.5834 19 12.3334 19C6.08337 19 2.33337 12 2.33337 12C2.33337 12 6.08337 5 12.3334 5ZM12.3334 6.27273C7.93337 6.27273 4.83087 10.4104 3.80212 12C4.82962 13.5884 7.93212 17.7273 12.3334 17.7273C16.7334 17.7273 19.8359 13.5896 20.8646 12C19.8371 10.4116 16.7346 6.27273 12.3334 6.27273ZM12.3334 7.54545C13.4937 7.54545 14.6065 8.01477 15.427 8.85016C16.2474 9.68555 16.7084 10.8186 16.7084 12C16.7084 13.1814 16.2474 14.3144 15.427 15.1498C14.6065 15.9852 13.4937 16.4545 12.3334 16.4545C11.1731 16.4545 10.0603 15.9852 9.23978 15.1498C8.41931 14.3144 7.95837 13.1814 7.95837 12C7.95837 10.8186 8.41931 9.68555 9.23978 8.85016C10.0603 8.01477 11.1731 7.54545 12.3334 7.54545ZM12.3334 8.81818C11.5049 8.81919 10.7106 9.15474 10.1248 9.75123C9.53893 10.3477 9.20937 11.1564 9.20837 12C9.20837 13.7538 10.6096 15.1818 12.3334 15.1818C14.0571 15.1818 15.4584 13.7538 15.4584 12C15.4584 10.2462 14.0571 8.81818 12.3334 8.81818Z"
        fill="#2B3674"
      />
    </svg>
  );
}

export default ViewSVGAction;
