function ViewSVG(props: { color: string }) {
  return (
    <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.3334 0C16.5834 0 20.3334 7 20.3334 7C20.3334 7 16.5834 14 10.3334 14C4.08337 14 0.333374 7 0.333374 7C0.333374 7 4.08337 0 10.3334 0ZM10.3334 1.27273C5.93337 1.27273 2.83087 5.41036 1.80212 7C2.82962 8.58836 5.93212 12.7273 10.3334 12.7273C14.7334 12.7273 17.8359 8.58964 18.8646 7C17.8371 5.41164 14.7346 1.27273 10.3334 1.27273ZM10.3334 2.54545C11.4937 2.54545 12.6065 3.01477 13.427 3.85016C14.2474 4.68555 14.7084 5.81858 14.7084 7C14.7084 8.18142 14.2474 9.31445 13.427 10.1498C12.6065 10.9852 11.4937 11.4545 10.3334 11.4545C9.17305 11.4545 8.06025 10.9852 7.23978 10.1498C6.41931 9.31445 5.95837 8.18142 5.95837 7C5.95837 5.81858 6.41931 4.68555 7.23978 3.85016C8.06025 3.01477 9.17305 2.54545 10.3334 2.54545ZM10.3334 3.81818C9.50488 3.81919 8.7106 4.15474 8.12476 4.75123C7.53893 5.34772 7.20937 6.15644 7.20837 7C7.20837 8.75382 8.60962 10.1818 10.3334 10.1818C12.0571 10.1818 13.4584 8.75382 13.4584 7C13.4584 5.24618 12.0571 3.81818 10.3334 3.81818Z"
        fill={props.color}
      />
    </svg>
  );
}

export default ViewSVG;
