import axiosPrivate from "../lib/axiosPrivate";
import { setLoading } from "../store/reducers/loaderReducer";
import { AppThunk } from "../store/store";

import {
  setData,
  setDetail,
  setWholeData,
} from "../store/reducers/adminUserReducer";
import {
  setErr,
  setErrors,
  setSuccess,
} from "../store/reducers/alertMessageReducer";
import { setLoginData } from "../store/reducers/authReducer";
import { getUserData } from "./userService";
export interface AddUser {
  role: string;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  password: string;
  confirmPassword: string;
}
export interface GetUsers {
  search: string;
  role: string;
  status: string;
  page: number;
  page_size: number;
  department__id?: string;
  created_at_from?: string;
  created_at_to?: string;
  updated_at_from?: string;
  updated_at_to?: string;
}

export const getUsers =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setData({}));
    try {
      await axiosPrivate
        .get(
          "accounts/users/?" +
            `${payload.page >= 0 ? `page=${payload.page}` : ""}` +
            `${
              payload.page_size >= 0 ? `&page_size=${payload.page_size}` : ""
            }` +
            `${payload.search ? `&search=${payload.search}` : ""}` +
            `${payload.role ? `&role__in=${payload.role}` : ""}` +
            `${
              payload?.is_superuser
                ? `&is_superuser=${payload?.is_superuser}`
                : ``
            }` +
            `${
              payload.primary_role__in
                ? `&primary_role__in=${payload.primary_role__in}`
                : ""
            }` +
            `${payload.status ? `&status=${payload.status}` : ""}` +
            `${
              payload.department__id
                ? `&department__id=${payload.department__id}`
                : ""
            }` +
            `${
              payload.created_at_from
                ? `&created_at_from=${payload.created_at_from}`
                : ""
            }` +
            `${
              payload.created_at_to
                ? `&created_at_to=${payload.created_at_to}`
                : ""
            }` +
            `${
              payload.updated_at_from
                ? `&updated_at_from=${payload.updated_at_from}`
                : ""
            }` +
            `${
              payload.updated_at_to
                ? `&updated_at_to=${payload.updated_at_to}`
                : ""
            }`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setData(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getWholeUsers =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setWholeData({}));
    try {
      await axiosPrivate
        .get(
          "accounts/users/?" +
            `${payload.page >= 0 ? `page=${payload.page}` : ""}` +
            `${
              payload.page_size >= 0 ? `&page_size=${payload.page_size}` : ""
            }` +
            `${payload.search ? `&search=${payload.search}` : ""}` +
            `${payload.role ? `&role__in=${payload.role}` : ""}` +
            `${
              payload.primary_role__in
                ? `&primary_role__in=${payload.primary_role__in}`
                : ""
            }` +
            `${
              payload?.is_superuser
                ? `&is_superuser=${payload?.is_superuser}`
                : ``
            }` +
            `${payload.status ? `&status=${payload.status}` : ""}` +
            `${
              payload.department__id
                ? `&department__id=${payload.department__id}`
                : ""
            }` +
            `${
              payload.created_at_from
                ? `&created_at_from=${payload.created_at_from}`
                : ""
            }` +
            `${
              payload.created_at_to
                ? `&created_at_to=${payload.created_at_to}`
                : ""
            }` +
            `${
              payload.updated_at_from
                ? `&updated_at_from=${payload.updated_at_from}`
                : ""
            }` +
            `${
              payload.updated_at_to
                ? `&updated_at_to=${payload.updated_at_to}`
                : ""
            }`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setWholeData(data));
        })
        .catch((error) => {
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const updateUserBulkStatus =
  (ids: number[], status: string): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .post("accounts/users/bulk_update/", {
          user_ids: ids,
          new_status: status,
        })
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (status == "Active") {
            dispatch(setSuccess("Admin status changed to Active"));
          } else if (status) {
            dispatch(setSuccess(`Admin ${status.toLowerCase()} successfully`));
          } else if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Status updated successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const deleteUser =
  (payload: { id: number }): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .delete(`accounts/users/${payload.id}/`)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (data?.message) {
            dispatch(setSuccess(data?.message));
          } else {
            dispatch(setSuccess("User deleted successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));

          if (error?.response?.data?.message) {
            dispatch(setErr(error?.response?.data?.message));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const updateUser =
  (payload: any, sshowMessage = true): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    let formData = new FormData();
    if (payload?.status) {
      formData.append("status", payload.status);
    }
    if (payload?.role) {
      formData.append("role", payload.role);
    }
    if (payload?.profile_picture) {
      formData.append("profile_picture", payload.profile_picture.image);
    }
    if (payload?.first_name) {
      formData.append("first_name", payload.first_name);
    }
    if (payload?.last_name) {
      formData.append("last_name", payload.last_name);
    }
    if (payload?.username) {
      formData.append("username", payload.username);
    }
    if (payload?.personal_detail_data) {
      formData.append("personal_detail_data", payload?.personal_detail_data);
    }
    try {
      await axiosPrivate
        .patch("accounts/users/" + payload.id + "/", formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=myboundary",
            enctype: "multipart/form-data; boundary=myboundary",
          },
        })
        .then((response) => {
          const data = response.data;
          const loginData: any = JSON.parse(
            localStorage.getItem("user_info") || "{}"
          );
          localStorage.setItem("user_info", JSON.stringify(loginData));
          if (Number(payload?.id) === Number(loginData?.id)) {
            dispatch(getCurrentUserDetail());
          }
          dispatch(setLoading(false));
          if (sshowMessage) {
            if (payload?.status) {
              if (payload?.status == "Active") {
                dispatch(setSuccess("Admin status changed to Active"));
              } else if (payload?.status) {
                dispatch(
                  setSuccess(
                    `Admin ${payload?.status.toLowerCase()} successfully`
                  )
                );
              }
            } else if (data?.message) {
              dispatch(setSuccess(data?.detail));
            } else {
              dispatch(setSuccess("User updated successfully!"));
            }
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));

          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getUserDetail =
  (id: number): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .get(`accounts/users/${id}/`)
        .then((response) => {
          const data = response.data;

          dispatch(setLoading(false));
          dispatch(setDetail(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));

          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };
export const getCurrentUserDetail = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  const loginData: any = JSON.parse(localStorage.getItem("user_info") || "{}");
  try {
    await axiosPrivate
      .get(`accounts/users/${loginData?.id}/`)
      .then((response) => {
        const data = response.data;
        const loginData: any = JSON.parse(
          localStorage.getItem("user_info") || "{}"
        );
        let userNewData = {
          email: data?.email,
          first_name: data?.first_name,
          id: data?.id,
          is_email_verified: loginData?.is_email_verified,
          is_superadmin: loginData?.is_superadmin,
          last_name: data?.last_name,
          profile_picture: data?.profile_picture,
          role: data?.role,
          status: data?.status,
          sign_up_data: data?.sign_up_data || "",
          username: data?.username,
          personal_detail_data: data?.personal_detail_data || "",
          permission_list: loginData?.permissions,
          primary_role: data?.primary_role,
          preferred_language: loginData?.preferred_language,
        };
        localStorage.setItem("user_info", JSON.stringify(userNewData));
        dispatch(setLoginData(userNewData));

        dispatch(setLoading(false));
        dispatch(setDetail(data));
      })
      .catch((error) => {
        dispatch(setLoading(false));

        if (error?.response?.data?.detail) {
          dispatch(setErr(error?.response?.data?.detail));
        } else {
          dispatch(setErrors(error?.response?.data));
        }
      });
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setErr("Something went wrong, Please try again"));
  }
};
// export const updateUserProfilePicture =
//   (payload: { profile_picture: any; id: number }): AppThunk =>
//   async (dispatch) => {
//     dispatch(setLoading(true));
//     let formData = new FormData();

//     formData.append("profile_picture", payload.profile_picture);

//     try {
//       await axiosPrivate
//         .patch("accounts/users/" + payload.id + "/", formData, {
//           headers: {
//             "Content-Type": "multipart/form-data; boundary=myboundary",
//             enctype: "multipart/form-data; boundary=myboundary",
//           },
//         })
//         .then((response) => {
//           const data = response.data;
//           dispatch(setLoading(false));
//           if (data?.message) {
//             dispatch(setSuccess(data?.detail));
//           } else {
//             dispatch(setSuccess("User updated successfully!"));
//           }
//         })
//         .catch((error) => {
//           dispatch(setLoading(false));

//           if (error?.response?.data?.detail) {
//             dispatch(setErr(error?.response?.data?.detail));
//           } else {
//             dispatch(setErrors(error?.response?.data));
//           }
//         });
//     } catch (error: any) {
//       dispatch(setLoading(false));
//       dispatch(setErr("Something went wrong, Please try again"));
//     }
//   };
export const addAdmin =
  (payload: AddUser): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    let formData = new FormData();
    formData.append("role", payload.role);
    formData.append("first_name", payload.first_name);
    formData.append("last_name", payload.last_name);
    // formData.append("phone_number", payload.phone);
    formData.append(
      "sign_up_data",
      JSON.stringify({ phone_no: payload.phone })
    );
    formData.append("email", payload.email);
    formData.append("password", payload.password);
    // formData.append("personal_detail_data", {});
    // formData.append("sign_up_data", formData);

    let postData = {
      role: payload.role,
      first_name: payload.first_name,
      last_name: payload.last_name,
      sign_up_data: JSON.stringify({ phone_no: payload.phone }),
      email: payload.email,
      password: payload.password,
    };
    try {
      await axiosPrivate
        .post("accounts/users/", postData)
        .then((response) => {
          const data = response.data;
          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("User added successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const updateProfilePassword =
  (payload: {
    current_password: string;
    new_password: string;
    confirm_password: string;
  }): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .post(`accounts/users/change_password_with_login/`, payload)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Password changed successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const userRoleSwitch =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await axiosPrivate
        .post("accounts/users/switch_role/", payload)
        .then((response) => {
          const data = response.data;

          dispatch(getUserData());

          dispatch(setLoading(false));
        })
        .catch((error) => {
          dispatch(setLoading(false));

          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };
