import { yupResolver } from "@hookform/resolvers/yup";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import SignInImage from "../../images/Signin.svg";
import {
  getMicrosoftLoginURL,
  login,
  reSendOtp,
} from "../../services/authService";
import {
  isForgotVerified,
  isVerified,
  setAuthFailed,
  setAuthSignUpData,
  updatedPassword,
} from "../../store/reducers/authReducer";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import Footer from "./Footer";
import Header from "./Header";
import Style from "./Login.module.scss";

const schema = yup
  .object({
    email: yup.string().required("Email is required").email("Email is invalid"),
    password: yup.string().required("Password is required"),
  })
  .required();

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const error = useSelector((state: any) => state.authReducer.error);
  const isAuth = useSelector((state: any) => state.authReducer.isAuth);
  const microsoftLoginUrl = useSelector(
    (state: any) => state.authReducer.microsoftLoginUrl
  );
  const singUpData = useSelector((state: any) => state.authReducer.singup_data);
  const [emailVerify, setEmailVerify] = useState<boolean>(false);
  const [fieldType, setFieldType] = useState<any>("password");
  console.log("microsoftLoginUrl", microsoftLoginUrl);
  const isLoading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  useEffect(() => {
    dispatch(getMicrosoftLoginURL());
  }, []);
  const onSubmit = (payload: any) => {
    toast.dismiss();
    dispatch(login(payload));
  };
  useEffect(() => {
    localStorage.removeItem("access_token");
    Cookies.remove("refresh_token");

    localStorage.removeItem("user_info");
    localStorage.removeItem("video_info");
    localStorage.removeItem("signup_data");
    localStorage.removeItem("reset_password_token");
    dispatch(isForgotVerified(false));
    dispatch(updatedPassword(false));
    dispatch(isVerified(false));
    dispatch(setAuthSignUpData(null));
    const logoutErr = localStorage.getItem("logout_error") || "";
    if (logoutErr) {
      toast.error(logoutErr.toString(), {
        toastId: logoutErr.toString(),
        autoClose: 30000,
      });
      localStorage.removeItem("logout_error");
    }
  }, [dispatch]);
  useEffect(() => {
    if (error) {
      toast.error(String(error), {
        toastId: String(error),
      });
      dispatch(setAuthFailed(null));
    }
  }, [error, dispatch]);
  useEffect(() => {
    if (singUpData?.is_email_verified === false) {
      setEmailVerify(true);
    } else {
      setEmailVerify(false);
    }
  }, [singUpData]);
  useEffect(() => {
    if (isAuth) {
      navigate("/");
    }
  }, [isAuth, navigate]);
  const reSendHandler = () => {
    dispatch(reSendOtp());
    setTimeout((): any => {
      navigate("/verify");
    }, 2000);
  };
  const showHidePassword = () => {
    if (fieldType === "password") {
      setFieldType("text");
    } else {
      setFieldType("password");
    }
  };
  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;
  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;
  const foregroundBgColor =
    useColor("interface_foreground_color") ||
    defaultThemeColor.interface_foreground_color;
  const foregroundTextColor =
    useColor("interface_foreground_text_color") ||
    defaultThemeColor.interface_foreground_text_color;
  const primaryButton =
    useColor("button_primary") || defaultThemeColor.button_primary;
  const primaryButtonText =
    useColor("button_primary_text") ||
    defaultThemeColor.button_button_primary_text;
  return (
    <div
      className={`d-flex flex-wrap ${Style.loginStart}`}
      style={{
        direction: lang === "ar" ? "rtl" : "ltr",
        overflowY: "auto",
        height: "100vh",
      }}
    >
      <div
        className={`position-relative ${Style.leftSide}`}
        style={{ background: interfaceBgColor, color: interfaceTextColor }}
      >
        <Header />
        <div className={`${Style.signInFormSection} `}>
          <h2>{t("Sign in")}</h2>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <input
              className={`${Style.inputfields} ${Style.fieldFont} ${
                errors.email ? "is-invalid" : ""
              }`}
              style={{
                background: foregroundBgColor,
                color: foregroundTextColor,
              }}
              placeholder={t("Email Address *")}
              {...register("email")}
              autoComplete="off"
            />
            <p className="color-red">{errors.email?.message}</p>
            <div />
            <div className="position-relative">
              <input
                className={`${Style.inputfields} ${Style.fieldFont} ${
                  errors.password ? "is-invalid" : ""
                }`}
                style={{
                  paddingRight: lang === "ar" ? "3rem" : "0rem",
                  background: foregroundBgColor,
                  color: foregroundTextColor,
                }}
                placeholder={t("Password *")}
                type={fieldType}
                autoComplete="new-password"
                {...register("password")}
              />
              <button
                className="float-end btn btn-link eye-btn"
                type="button"
                onClick={showHidePassword}
              >
                {fieldType === "password" ? (
                  <VisibilityOutlined />
                ) : (
                  <VisibilityOffOutlined />
                )}
              </button>
            </div>
            <p className="color-red">{errors.password?.message}</p>
            {emailVerify && (
              <div className={`${Style.verifyEmailMain}`}>
                <span className="color-red">
                  {t("Your email is not verified.")}
                </span>
                <br></br>
                <span
                  className={`${Style.linksC} ${Style.verifyEmailText}`}
                  onClick={reSendHandler}
                >
                  {t("Click here to verify your email")}
                </span>
              </div>
            )}

            <div className="d-flex justify-content-between align-items-center">
              <button
                type="submit"
                className={`btn btn-primary ${Style.mt24} ${Style.loginBtn}`}
                disabled={isLoading}
              >
                <span className={Style.alignItemsInside}>
                  <span
                    className={`p-1 ${Style.hThree} ${Style.buttonFont} ${Style.white}`}
                  >
                    {t("LOGIN")}
                  </span>
                  <span className={Style.iconButton}>
                    <ArrowForwardOutlinedIcon fontSize="small" />
                  </span>
                </span>
              </button>

              <Link
                to="/reset-password"
                className={`${Style.linksC} ${Style.mt24}`}
              >
                {t("Forgot your password")}?
              </Link>
            </div>
          </form>
          {/* {microsoftLoginUrl && (
            <div className={`d-flex  ${Style.LinkD} ${Style.mt24}`}>
              <a href={microsoftLoginUrl} style={{ cursor: "pointer" }}>
                {t("Login with Microsoft")}
              </a>
            </div>
          )} */}
          <div className={`d-flex ${Style.LinkD} ${Style.mt24}`}>
            {t("Don't have an account?")}
          </div>
          <Link
            to="/signup"
            className={`btn btn-primary w-100 ${Style.CreateAccountBtn} ${Style.mt24}`}
          >
            <span className={Style.alignItemsInside}>
              <span
                className={`p-1 ${Style.hThree} ${Style.buttonFont} ${Style.white}`}
              >
                {t("Create New Account")}
              </span>
            </span>
          </Link>
        </div>
        <Footer />
      </div>
      <div className={`bg-light ${Style.rightSide}`}>
        <h2
          className={`${Style.headerGreeting} ${Style.headerMargins}`}
          style={{ paddingRight: lang === "ar" ? "4rem" : "0rem" }}
        >
          {t("Hello! Welcome Back.")}
        </h2>
        <div
          className={Style.subHead}
          style={{ paddingRight: lang === "ar" ? "4rem" : "0rem" }}
        >
          {t("Enter your details to login")}
        </div>
        <img
          className={`w-100 ${Style.imgCover}`}
          src={SignInImage}
          alt={"Greeting"}
        ></img>
      </div>
    </div>
  );
};

export default Login;
