import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import Style from "./ExportCSV.module.scss";
import useColor from "../../hooks/useColor";
import { defaultThemeColor } from "../../utils/colors_values";
import { useTranslation } from "react-multi-lang";

function ExportCSV({
  filename,
  headers,
  rows,
  handleClicked,
  setParentData,
}: {
  filename?: any;
  headers?: any;
  rows?: any;
  handleClicked?: any;
  setParentData?: any;
}) {
  const csvLink: any = useRef();
  const [csvData, setCsvData] = useState<any>([]);
  const t = useTranslation();

  useEffect(() => {
    if (rows && rows.length > 0 && headers) prepareCSVdata();
  }, [headers, rows]);

  useEffect(() => {
    if (csvData && csvData.length > 1) csvLink?.current?.link.click();
  }, [csvData]);

  const prepareCSVdata = () => {
    setCsvData([headers, ...rows?.map((r: any) => Object.values(r))]);
    setParentData({});
  };

  const iconColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  return (
    <div id={"csvcompo"}>
      <div
        className={`d-flex justify-content-center align-content-center align-items-center ${Style.downloadbtn}
          `}
        style={{
          fontSize: 13,
          textTransform: "capitalize",
          cursor: "pointer",
        }}
        onClick={() => handleClicked()}
      >
        <DownloadForOfflineOutlinedIcon
          color={iconColor}
          style={{ color: iconColor }}
        ></DownloadForOfflineOutlinedIcon>
        <div
          className="px-2"
          style={{
            color:
              useColor("interface_background_text_color") ||
              defaultThemeColor.interface_background_text_color,
          }}
        >
          {" "}
          {t("Export CSV")}
        </div>
      </div>
      <CSVLink
        id="csvfile"
        className={Style.downloadbtn}
        filename={filename}
        data={csvData}
        ref={csvLink}
      ></CSVLink>
    </div>
  );
}

export default ExportCSV;
