import { combineReducers } from "@reduxjs/toolkit";
import adminPermissionReducer from "./reducers/adminPermissionReducer";
import adminUserReducer from "./reducers/adminUserReducer";
import alertMessageReducer from "./reducers/alertMessageReducer";
import assessmentReducer from "./reducers/assessmentReducer";
import authReducer from "./reducers/authReducer";
import categoryReducer from "./reducers/categoryReducer";
import certificateManagementReducer from "./reducers/certificateManagementReducer";
import profileManagementReducer from "./reducers/companyFormReducer";
import companyIntegrationReducer from "./reducers/companyIntegrationReducer";
import dashboardReducer from "./reducers/dashboardReducer";
import departmentReducer from "./reducers/departmentReducer";
import emailNotificationReducer from "./reducers/emailNotificationReducer";
import groupManagementReducer from "./reducers/groupManagementReducer";
import imageLibraryReducer from "./reducers/imagesReducer";
import licenseReducer from "./reducers/licenseReducer";
import loaderReducer from "./reducers/loaderReducer";
import moduleReducer from "./reducers/moduleReducer";
import notificationReducer from "./reducers/notificationReducer";
import personalFormReducer from "./reducers/personalFormReducer";
import policyAssessmentReducer from "./reducers/policyAssessmentReducer";
import policyReducer from "./reducers/policyReducer";
import progressReducer from "./reducers/progressReducer";
import questionReducer from "./reducers/questionReducer";
import trainingsReducer from "./reducers/trainingsReducer";
import userReducer from "./reducers/userReducers";

const rootReducer = combineReducers({
  loaderReducer,
  authReducer,
  userReducer,
  personalFormReducer,
  departmentReducer,
  profileManagementReducer,
  groupManagementReducer,
  certificateManagementReducer,
  alertMessageReducer,
  imageLibraryReducer,
  adminUserReducer,
  adminPermissionReducer,
  notificationReducer,
  categoryReducer,
  questionReducer,
  assessmentReducer,
  trainingsReducer,
  moduleReducer,
  policyReducer,
  policyAssessmentReducer,
  dashboardReducer,
  licenseReducer,
  progressReducer,
  emailNotificationReducer,
  companyIntegrationReducer,
});

export default rootReducer;
