import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import useColor from "../../hooks/useColor";
import { RootState } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import { formatBytes } from "../../utils/formatBytesToDelimeters";
import { formatBytesWithoutDM } from "../../utils/formatBytesWithoutDelimeter";
import Style from "./../../components/ChooseFile.module.scss";
const $ = require("jquery");

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 9,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#14276F" : "#14276F",
  },
}));

interface ChooseFileProps {
  size: string;
  fileTypes: string;
  loading: boolean;
  errors: any;
  setValue: any;
  imageName: string;
  clearErrors: any;
  acceptedImageTypesArray: any;
  setImageParent: any;
  maxFileSizeinBytes: number;
  allowedFileTypes: Record<string, { maxSize?: number; errorMessage?: string }>;
}

function ChooseModuleFiles({
  size,
  fileTypes,
  loading,
  errors,
  setValue,
  imageName,
  clearErrors,
  acceptedImageTypesArray,
  setImageParent,
  maxFileSizeinBytes,
  allowedFileTypes,
}: ChooseFileProps) {
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedImageBase64, setSelectedImageBase64] = useState<any>();
  const [image, setImage] = useState<any>();
  const [fileSize, setFileSize] = useState<any>(0);
  const [fileSizeBytes, setFileSizeBytes] = useState<any>(0);
  const [fileName, setFileName] = useState<any>("");
  const [fileType, setFileType] = useState<any>("");
  const [progress, setProgress] = useState<any>();
  const [imageup, setImageUp] = useState(0);
  const t = useTranslation();

  const progressInfo = useSelector(
    (state: RootState) => state.progressReducer.progress
  );

  useEffect(() => {
    setProgress(progressInfo);
  }, [progressInfo]);

  useEffect(() => {
    setProgress({
      loaded: 0,
      total: 100,
    });
  }, [loading]);

  $(document).ready(function () {
    $("#OpenImgUpload").unbind("click");
    $("#OpenImgUpload").click(function (event: any) {
      $("#file").click();
    });
    $("#file").click(function (event: any) {
      event.stopPropagation();
    });
  });

  const readURL = (input: any) => {
    input = document?.getElementById("file");
    if (input.files && input.files[0]) {
      let file = input.files[0];
      const fileType = file["type"];
      const validImageTypes = acceptedImageTypesArray;
      if (!validImageTypes.includes(fileType)) {
        toast.error(String(t("Please select valid image file")), {
          toastId: String(t("Please select valid image file")),
        });
        setTimeout(() => {
          setImage(null);
          setSelectedImageBase64("");
          setSelectedImage("");
          setImageParent("");
        }, 1000);
      } else {
        const fileTypeKey = fileType.toLowerCase();
        if (file.size > maxFileSizeinBytes) {
          toast.error(
            String(
              t("Please select file with size less than ") +
                formatBytes(maxFileSizeinBytes).toLocaleUpperCase()
            ),
            {
              toastId: String(
                t("Please select file with size less than ") +
                  formatBytes(maxFileSizeinBytes).toLocaleUpperCase()
              ),
            }
          );
          setTimeout(() => {
            setImage(null);
            setSelectedImageBase64("");
            setSelectedImage("");
            setImageParent("");
          }, 1000);
        } else {
          if (input.files && input.files[0]) {
            setFileName(file.name);
            setFileType(
              fileType.split("/").length >= 2 ? fileType.split("/")[1] : ""
            );
            setFileSize(formatBytes(file.size));
            setFileSizeBytes(file.size);
            var reader: { onload: any; readAsDataURL: any } = new FileReader();
            reader.onload = function (e: { target: { result: string } }) {
              setSelectedImageBase64({
                ...selectedImageBase64,
                image: input.files[0],
                imagename: input.files[0].name,
              });
              setImageParent({
                ...selectedImageBase64,
                image: input.files[0],
                imagename: input.files[0].name,
              });
              $("#blah").attr("src", e?.target?.result).width(142).height(142);
            };
            reader.readAsDataURL(input.files[0]);
          }
        }
      }
    }
  };

  React.useEffect(() => {}, [loading]);

  return (
    <div className="col-lg-12 mx-0 px-0">
      <div
        id="OpenImgUpload"
        className={`${Style.minWidth} ${Style.choosefileCover}`}
        style={{
          border: errors[imageName]
            ? "1px solid red"
            : "1px solid rgba(0, 0, 0, 0.23)",
        }}
      >
        <span
          className="d-flex flex-wrap"
          style={{
            background:
              useColor("interface_background_color") ||
              defaultThemeColor.interface_background_color,
            color:
              useColor("interface_background_text_color") ||
              defaultThemeColor.interface_background_text_color,
          }}
        >
          <div
            className={`${Style.chooseFile} w-25`}
            style={{
              color:
                useColor("interface_foreground_text_color") ||
                defaultThemeColor.interface_foreground_text_color,
            }}
          >
            {t("Choose File")}
          </div>
          <div
            className={`${Style.choosenFileText} w-75`}
            style={{
              alignItems: image ? "" : "center",
              marginTop: loading ? "0.5em" : image ? "0.1em" : "0",
            }}
          >
            {image ? (
              <div className={`${loading ? "" : "d-flex align-items-center"}`}>
                <div
                  className="row col-lg-11 d-flex justify-content-center align-items-center px-0 mx-0"
                  style={{
                    height: "2.7em",
                  }}
                >
                  <Box sx={{ width: "90%", pl: 0, ml: 0 }}>
                    <div
                      className={`${Style.logohead}  d-flex justify-content-start ${Style.fileNameCover}`}
                      style={{
                        minWidth: size == "large" ? "35em" : "23em",
                      }}
                    >
                      {fileName && size == "large" && fileName.length > 60
                        ? fileName.slice(0, 60) + "... ." + fileType
                        : fileName && size == "small" && fileName.length > 25
                        ? fileName.slice(0, 25) + "... ." + fileType
                        : fileName}
                    </div>

                    {loading ? (
                      <span>
                        <BorderLinearProgress
                          variant="determinate"
                          value={
                            Math.round(
                              (progress?.loaded * 100) / progress?.total
                            ) || 0
                          }
                        />
                        <span className="row col-lg-12 row-lg-4 mx-0 px-0">
                          <span
                            className={`${Style.bottomProgressMB} col-lg-6 justify-content-start ${Style.textLeft}`}
                          >
                            {formatBytesWithoutDM(progress?.loaded)}
                          </span>
                          <span
                            className={`${Style.bottomProgressMB} col-lg-6 ${Style.textRight}`}
                          >
                            {(
                              Math.round(
                                (progress?.loaded * 100) / progress?.total
                              ) || 0
                            ).toFixed(0)}
                            %
                          </span>
                        </span>
                      </span>
                    ) : null}
                  </Box>
                </div>
              </div>
            ) : (
              <span>{t("No file chosen")}</span>
            )}
          </div>
        </span>
      </div>
      {image ? (
        <IconButton
          sx={{ m: 0, p: 0, mr: 0 }}
          className={Style.positionIconButton}
          style={{ position: "absolute" }}
          disabled={loading}
          onClick={() => {
            setImage("");
            setSelectedImage("");
            setImageParent("");
            setProgress(0);
            setValue(imageName, "");
            clearErrors(imageName);
          }}
        >
          <CloseIcon className={Style.iconSize}></CloseIcon>
        </IconButton>
      ) : null}
      <input
        id="file"
        type="file"
        className={`${Style.inputfields} ${Style.fieldFont} ${
          errors.file_name ? "is-invalid" : ""
        }`}
        accept={fileTypes}
        onChange={(e) => {
          readURL(e);
          setImage(e.target.value);
          setImageParent(e.target.value);
          setSelectedImage(e.target.value);
          setValue(imageName, e.target.value);
          clearErrors(imageName);
        }}
        style={{
          position: "absolute",
          top: 0,
          width: 20,
          opacity: 0,
          background:
            useColor("interface_background_color") ||
            defaultThemeColor.interface_background_color,
          color:
            useColor("interface_background_text_color") ||
            defaultThemeColor.interface_background_text_color,
          outline: "borderColorInput",
          border: "1px solid borderColorInput",
        }}
      ></input>
    </div>
  );
}
export default ChooseModuleFiles;
