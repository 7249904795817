function ListViewSVG(props: { bg: string; color: string; width: number; height: number }) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 39 38"
      fill={props.color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.535156 0H34.5352C36.7443 0 38.5352 1.79086 38.5352 4V34C38.5352 36.2091 36.7443 38 34.5352 38H0.535156V0Z"
        fill={props.bg}
      />
      <path
        d="M11.6185 14.2507V11.084H33.7852V14.2507H11.6185ZM11.6185 20.584V17.4173H33.7852V20.584H11.6185ZM11.6185 26.9173V23.7507H33.7852V26.9173H11.6185ZM6.8685 14.2507C6.41988 14.2507 6.04358 14.0987 5.73958 13.7947C5.43558 13.4907 5.28411 13.1149 5.28516 12.6673C5.28516 12.2187 5.43716 11.8424 5.74116 11.5384C6.04516 11.2344 6.42094 11.0829 6.8685 11.084C7.31711 11.084 7.69341 11.236 7.99741 11.54C8.30141 11.844 8.45288 12.2198 8.45183 12.6673C8.45183 13.1159 8.29983 13.4922 7.99583 13.7962C7.69183 14.1002 7.31605 14.2517 6.8685 14.2507ZM6.8685 20.584C6.41988 20.584 6.04358 20.432 5.73958 20.128C5.43558 19.824 5.28411 19.4482 5.28516 19.0007C5.28516 18.552 5.43716 18.1757 5.74116 17.8717C6.04516 17.5677 6.42094 17.4163 6.8685 17.4173C7.31711 17.4173 7.69341 17.5693 7.99741 17.8733C8.30141 18.1773 8.45288 18.5531 8.45183 19.0007C8.45183 19.4493 8.29983 19.8256 7.99583 20.1296C7.69183 20.4336 7.31605 20.585 6.8685 20.584ZM6.8685 26.9173C6.41988 26.9173 6.04358 26.7653 5.73958 26.4613C5.43558 26.1573 5.28411 25.7815 5.28516 25.334C5.28516 24.8854 5.43716 24.5091 5.74116 24.2051C6.04516 23.9011 6.42094 23.7496 6.8685 23.7507C7.31711 23.7507 7.69341 23.9027 7.99741 24.2067C8.30141 24.5107 8.45288 24.8864 8.45183 25.334C8.45183 25.7826 8.29983 26.1589 7.99583 26.4629C7.69183 26.7669 7.31605 26.9184 6.8685 26.9173Z"
        fill={props.color}
      />
    </svg>
  );
}

export default ListViewSVG;
