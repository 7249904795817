import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import { Theme, useTheme } from "@mui/material/styles";
import { useConfirm } from "material-ui-confirm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CancelButton from "../../components/CancelButton";
import DataTable from "../../components/table/DataTable";
import TableFilters from "../../components/table/TableFilters";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import usePermission from "../../hooks/usePermission";
import { getActiveDirectoryUser } from "../../services/userService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { RootState, useAppDispatch } from "../../store/store";
import {
  defaultThemeColor,
  light_text_color,
  primary_color,
} from "../../utils/colors_values";
import { roles_except_super_admin } from "../../utils/staticEntities";
import Style from "./ActiveDirectoryUser.module.scss";
export interface SimpleDialogProps {
  setParentOpen: any;
  open: boolean;
  onClose: () => void;
  setSelectedRows: any;
  selectedRows: any;
  save?: any;
  setSave?: any;
  setRolesState?: any;
  rolesState?: any;
}
const ITEM_HEIGHT = 24;
const ITEM_PADDING_TOP = 0;

function ActiveDirectoryUserModel(props: SimpleDialogProps) {
  const {
    setParentOpen,
    open,
    onClose,
    setSelectedRows,
    selectedRows,
    save,
    setSave,
    setRolesState,
    rolesState,
  } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  const theme = useTheme();
  const addPermission = usePermission("add_customusergroup");
  const editUpdatePermission = usePermission("change_customusergroup");
  const deletePermission = usePermission("delete_customusergroup");
  const viewPermission = usePermission("view_customusergroup");
  const [itemPerPage, setItemPerPage] = useState<number>(10);

  const [searchTable, setSearchTable] = useState("");
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<any[]>([]);
  // const [page, setPage] = useState(1);

  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;
  const [rows, setRows] = React.useState<any[]>([]);

  const dataList = useSelector(
    (state: RootState) => state.userReducer.active_directory_users
  );

  useEffect(() => {
    let selected = selectedRows?.length === 0 ? [] : selectedRows?.results;
    let selectedIds = selected?.map((item: any) => item?.id);
    setSelectedCheckBox(selectedIds);
  }, [selectedRows, open]);

  useEffect(() => {
    setPage(1);
  }, [searchTable]);

  useEffect(() => {
    dispatch(
      getActiveDirectoryUser({
        page_size: Number(itemPerPage),
        page: Number(page),
        search: searchTable,
      })
    );
  }, [itemPerPage, page, searchTable]);

  useEffect(() => {
    let data: any = dataList.results;
    if (Object.keys(dataList).length === 0) {
      setRows([]);
    } else {
      setRows(data);
      if (!(itemPerPage == 10 || itemPerPage == 15 || itemPerPage == 25)) {
        setItemPerPage(dataList?.count);
      }
    }
  }, [dataList]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      setSelectedCheckBox([]);
      dispatch(
        getActiveDirectoryUser({
          page_size: Number(itemPerPage),
          page: Number(page),
          search: searchTable,
        })
      );
    }
  }, [alertMessage]);

  const handleAdd = () => {
    console.log("submitting");

    setSelectedRows(selectedCheckBox);
    setSave(true);
    // handleClose();
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    // setSelectedCheckBox([]);
    setPage(page);
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPage(1);
    // setSelectedCheckBox([]);
    setItemPerPage(Number(rowsPerPage));
  };

  // const handleClose = () => {
  //   setParentOpen(false);
  // };

  useEffect(() => {
    modifyRows();
  }, [rows]);

  function getStyles(array: string[], name: string, theme: Theme) {
    return {
      fontWeight:
        array.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const modifyRows = () => {
    let f_rows: any = [];
    rows.forEach(
      (
        r: {
          id: string;
          first_name: string;
          last_name: string;
          email: any;
          phone_no: string;
        },
        i: number
      ) => {
        f_rows = [
          ...f_rows,
          {
            id: r.id,
            first_name: r?.first_name ? r?.first_name + " " + r?.last_name : "",
            email: r?.email,
            role: r?.phone_no,
          },
        ];
      }
    );
    setList(f_rows);
  };

  const handleClose = () => {
    onClose();
    setParentOpen(false);
    setSelectedCheckBox([]);
    setSearchTable("");
    setRolesState("");
  };

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;
  const primaryButton =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;
  const primaryButtonBg =
    useColor("button_primary") || defaultThemeColor.button_primary;

  const MenuPropsCountry = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 11.5 + ITEM_PADDING_TOP,
        width: 250,
        color: interfaceTextColor,
        background: interfaceBgColor,
      },
    },
  };
  return (
    <Dialog
      onClose={handleClose}
      maxWidth={"lg"}
      open={open && loading ? true : open}
      style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
    >
      <div
        className="d-flex justify-content-between"
        style={{ color: interfaceTextColor, background: interfaceBgColor }}
      >
        <DialogTitle style={{ paddingTop: "1em", fontSize: 22 }}>
          {t("Import Active Directory User(s)")}
        </DialogTitle>
        <div className="p-3">
          <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
        </div>
      </div>
      <DialogContent
        style={{ color: interfaceTextColor, background: interfaceBgColor }}
      >
        <div className="row">
          <div className={"col-3"}>{t("Roles")}</div>
          <div className={`col-9 mb-3`}>
            <FormControl sx={{ m: 0, width: "100%", mt: 1 }}>
              <InputLabel id="demo-multiple-name-label">
                {t("Roles")}
              </InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                sx={{
                  "& .MuiChip-root": {
                    color: primaryButton,
                    background: primaryButtonBg,
                    // height: "25px",
                  },
                }}
                MenuProps={{
                  ...MenuPropsCountry,
                  sx: {
                    "&& .Mui-selected": {
                      color: light_text_color,
                      background: primary_color,
                    },
                    "&& .Mui-selected:hover": {
                      color: "black",
                      background: "skyblue",
                    },
                  },
                }}
                value={rolesState}
                onChange={(event) => {
                  setRolesState(event.target.value);
                }}
                defaultValue={""}
                input={<OutlinedInput label="Experience Level" />}
                placeholder="Select"
              >
                {roles_except_super_admin.map((designation: any) => (
                  <MenuItem
                    key={designation}
                    value={designation}
                    style={getStyles(
                      roles_except_super_admin,
                      designation,
                      theme
                    )}
                  >
                    {designation}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <TableFilters
          dataList={dataList}
          itemPerPage={itemPerPage}
          selectedCheckBox={selectedCheckBox}
          bulkDelete={() => {}}
          setItemPerPage={handleChangeRowsPerPage}
          setSearchTable={setSearchTable}
          createButton={<></>}
          handle="activeDirectoryUser"
          isCheckboxes={false}
          exportCSV={false}
        />
        <DataTable
          columnData={[
            {
              id: "first_name",
              name: t("Username"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },

            {
              id: "email",
              name: t("Email"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
            {
              id: "phone_no",
              name: t("Phone No"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "none",
            },
          ]}
          rows={list}
          isCheckboxes={true}
          selectedRowsPerPage={itemPerPage.toString()}
          totalRecordCount={Number(dataList.count)}
          handleChangePageParent={handleChangePage}
          pagenum={Number(page)}
          handleChangeRowsPerPageParent={handleChangeRowsPerPage}
          selectedCheckBox={selectedCheckBox}
          setSelectedCheckBox={setSelectedCheckBox}
          rowsScroll={true}
          minHeight={"20em"}
          displayForceBottomComponent={true}
          bottomButtonsComponent={
            <div className="d-flex flex-wrap gap-3 w-100 px-3 mb-2 align-items-center justify-content-end ">
              <button
                type="button"
                className={`btn mt-3 ${Style.addBtn}`}
                disabled={
                  selectedCheckBox?.length == 0 ||
                  rolesState == "" ||
                  !rolesState
                    ? true
                    : false
                }
                style={{
                  color:
                    useColor("button_primary_text") ||
                    defaultThemeColor.button_secondary_text,
                  background:
                    useColor("button_primary") ||
                    defaultThemeColor.button_secondary,
                  border: "none",
                  outline: "none",
                }}
                onClick={handleAdd}
              >
                {t("Save")}
              </button>
              <CancelButton
                size="small"
                outlined={true}
                onClick={() => {
                  setSelectedCheckBox([]);
                  handleClose();
                }}
                textColor=""
                outlineColor=""
                text={t("Cancel")}
                className={`mt-3`}
                bg={""}
              ></CancelButton>
            </div>
          }
        />
      </DialogContent>
    </Dialog>
  );
}

export default ActiveDirectoryUserModel;
