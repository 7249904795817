import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { RootState } from "../store";

const signupData = JSON.parse(localStorage.getItem("signup_data") || "{}");
const permissionsList = JSON.parse(
  localStorage.getItem("permissions_list") || "{}"
);
const loginData = JSON.parse(localStorage.getItem("user_info") || "{}");
const redirect = localStorage.getItem("redirect");

export interface AuthError {
  message: string;
}

export interface FieldsFormSignUp {
  field_label: string;
  field_name: string;
  is_mandatory: boolean;
}
export interface SinUpData {
  email: string;
  first_name: string;
  last_name: string;
}
export interface AuthState {
  isAuth: boolean;

  error?: AuthError;
  errors?: any;
  success?: string;
  refresh_token?: string;
  access_token?: string;
  singup_data?: any;
  isVerified?: boolean;
  isReSentOtp?: boolean;
  isForgotVerified?: boolean;
  updatedPassword?: boolean;
  signUpFields?: any;
  permissionsList?: any[];
  loginData?: any;
  redirect?: any;
  microsoftLoginUrl?: string;
}
export const initialState: AuthState = {
  isAuth: false,
  isReSentOtp: false,
  isForgotVerified: false,

  isVerified: false,
  singup_data: signupData,
  permissionsList: permissionsList,
  loginData: loginData,
  redirect: redirect,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    isReSentOtp: (state, { payload }) => {
      state.isReSentOtp = payload;
    },
    isForgotVerified: (state, { payload }) => {
      state.isForgotVerified = payload;
    },
    updatedPassword: (state, { payload }) => {
      state.updatedPassword = payload;
    },
    isVerified: (state, { payload }) => {
      state.isVerified = payload;
    },
    setAuthLoginSuccess: (state, payload) => {
      state.access_token = payload.payload.access;
      state.refresh_token = payload.payload.refresh;
      state.permissionsList = payload?.payload?.user?.permission_list;
      state.loginData = payload?.payload?.user;
      state.isAuth = true;
    },
    setAuthFailed: (state, { payload }) => {
      state.error = payload;
      state.isAuth = false;
    },
    setSuccess: (state, { payload }) => {
      state.success = payload;
    },
    setRedirect: (state, { payload }) => {
      state.redirect = payload;
    },
    setMicrosoftLoginUrl: (state, { payload }) => {
      state.microsoftLoginUrl = payload;
    },
    setLogOut: (state) => {
      state.isAuth = false;
      localStorage.removeItem("access_token");
      Cookies.remove("refresh_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("user_info");
      localStorage.removeItem("signup_data");
      localStorage.removeItem("license_info");
      localStorage.removeItem("permissions_list");
      localStorage.removeItem("secondary_role");
      localStorage.removeItem("loglevel");
      localStorage.removeItem("redirect");
      localStorage.clear();
      state.loginData = null;
    },
    setAuthSignUpFailed: (state, { payload }) => {
      state.errors = payload;
    },
    setAuthSignUpData: (state, { payload }) => {
      state.singup_data = payload;
    },
    signUpFields: (state, { payload }) => {
      state.signUpFields = payload;
    },
    setLoginData: (state, { payload }) => {
      state.loginData = payload;
    },
    setPermissions: (state, { payload }) => {
      state.permissionsList = payload;
    },
  },
});

export const {
  isVerified,
  isReSentOtp,
  setAuthLoginSuccess,
  setAuthFailed,
  setLogOut,
  setAuthSignUpFailed,
  setAuthSignUpData,
  isForgotVerified,
  updatedPassword,
  signUpFields,
  setSuccess,
  setLoginData,
  setPermissions,
  setRedirect,
  setMicrosoftLoginUrl,
} = authSlice.actions;

export const authSelector = (state: RootState) => state;

export default authSlice.reducer;
