import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { maskSecret } from "../../../helpers/helpers";
import useCompanyLanguage from "../../../hooks/language";
import { RootState, useAppDispatch } from "../../../store/store";

export interface PropsInterface {
  row: any;
}
function ActiveDirectoryView(props: PropsInterface) {
  const { row } = props;
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const isLoading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );

  return (
    <>
      <div className={`row`}>
        <div className={`col-lg-6 px-2 py-3`}>
          <span>{t("Client ID")}</span>
          <p>{row?.detail?.client_id || "N/A"}</p>
        </div>
        <div className={`col-lg-6 px-2 py-3`}>
          <span>{t("Client Secret")}</span>
          <p>
            {row?.detail?.client_secret
              ? maskSecret(row?.detail?.client_secret)
              : "N/A"}
          </p>
        </div>
        <div className={`col-lg-6 px-2 py-3`}>
          <span>{t("Tenant ID")}</span>
          <p>{row?.detail?.tenant_id || "N/A"}</p>
        </div>
      </div>
    </>
  );
}

export default ActiveDirectoryView;
