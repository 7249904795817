import { useEffect } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useCompanyLanguage from "../../hooks/language";
import Success from "../../images/success.png";
import {
  authSelector,
  setAuthSignUpData,
} from "../../store/reducers/authReducer";
import { useAppDispatch } from "../../store/store";
import Footer from "./Footer";
import Header from "./Header";
import useColor from "../../hooks/useColor";
import { defaultThemeColor } from "../../utils/colors_values";
import Style from "./VerificationSuccess.module.scss";

function VerificationSuccess() {
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const states = useSelector(authSelector).authReducer;
  useEffect(() => {
    localStorage.removeItem("signup_data");
    localStorage.removeItem("reset_password_token");
    dispatch(setAuthSignUpData(null));
  }, [dispatch]);

  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;
  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  return (
    <div
      style={{
        direction: lang === "ar" ? "rtl" : "ltr",
        overflowY: "auto",
        height: "100vh",
      }}
    >
      <div>
        <Header />
      </div>
      <div
        className={`${Style.verifySuccess}`}
        style={{ color: interfaceTextColor, background: interfaceBgColor }}
      >
        <div className="d-flex justify-content-center ">
          <img
            className={`${Style.imgCover}`}
            src={Success}
            alt={"Greeting"}
          ></img>
        </div>
        <div
          className={`${Style.enterOTP} ${Style.mtV}`}
          style={{ color: interfaceTextColor }}
        >
          {states?.updatedPassword
            ? t("Password reset successful")
            : t("Verification Successful")}
        </div>
        <div
          className={`${Style.registered} ${Style.mt9}`}
          style={{ color: interfaceTextColor }}
        >
          {states?.updatedPassword
            ? t("You can now login with your new password.")
            : t("Registered Email/Phone number Verified.")}
        </div>
        <div className="d-flex justify-content-center">
          <Link
            to="/login"
            className={`btn btn-dark ${Style.CreateAccountBtn} ${Style.mtV2}`}
          >
            <span className={Style.alignItemsInside}>
              <span
                className={`p-1 ${Style.hThree} ${Style.buttonFont} ${Style.white}`}
              >
                {t("BACK TO LOGIN")}
              </span>
            </span>
          </Link>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default VerificationSuccess;
