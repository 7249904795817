import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import useCompanyLanguage from "../../../hooks/language";
import useColor from "../../../hooks/useColor";
import { getAll } from "../../../services/companyIntegrationService";
import {
  alertMessageSelector,
  setSuccess,
} from "../../../store/reducers/alertMessageReducer";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import { defaultThemeColor } from "../../../utils/colors_values";
import Style from "../../companyProfileManagement/profileManagement.module.scss";
import ActiveDirectoryEdit from "./ActiveDirectoryEdit";
import ActiveDirectoryView from "./ActiveDirectoryView";

function ExchangeServer() {
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const isLoading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const handleEdit = () => {
    setIsEdit(!isEdit);
  };
  const [row, setRow] = useState<any>();
  const dataList: any = useSelector(
    (state: RootState) => state.companyIntegrationReducer.data
  );
  console.log("data data data Exchange", row, typeof dataList);
  useEffect(() => {
    const foundData = dataList.find(
      (obj: any) => obj.type === "ActiveDirectory"
    );

    setRow(foundData);
  }, [dataList]);
  const alertMessage = useAppSelector(alertMessageSelector).alertMessageReducer;
  useEffect(() => {
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      setIsEdit(false);
      dispatch(getAll());
    }
  }, [alertMessage]);

  return (
    <>
      <div
        style={{
          background:
            useColor("interface_foreground_color") ||
            defaultThemeColor.interface_foreground_color,
          color:
            useColor("interface_foreground_text_color") ||
            defaultThemeColor.interface_foreground_text_color,
          borderRadius: 10,
          padding: "1rem 2rem",
        }}
        className={Style.bottomSpace}
      >
        <div>
          <div
            className={`${Style.textHeader}`}
            style={{
              color:
                useColor("interface_foreground_text_color") ||
                defaultThemeColor.interface_foreground_text_color,
              textAlign: lang === "ar" ? "right" : "left",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "1.5rem",
            }}
          >
            {t("Active Directory Setup")}
            <Button
              className="btn"
              style={{
                backgroundColor:
                  useColor("button_primary") ||
                  defaultThemeColor.button_primary,
                color:
                  useColor("button_primary_text") ||
                  defaultThemeColor.button_primary_text,
                textTransform: "capitalize",
              }}
              variant="text"
              color="primary"
              onClick={handleEdit}
            >
              {isEdit ? "View" : "Edit"}
            </Button>
          </div>
        </div>
        <div>
          {isEdit ? (
            <ActiveDirectoryEdit setIsEdit={setIsEdit} row={row} />
          ) : (
            <ActiveDirectoryView row={row} />
          )}
        </div>
      </div>
    </>
  );
}

export default ExchangeServer;
