import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../../components/Header";
import DataTable from "../../../components/table/DataTable";
import TableFilters from "../../../components/table/TableFilters";
import useCompanyLanguage from "../../../hooks/language";
import useColor from "../../../hooks/useColor";
import usePermission from "../../../hooks/usePermission";
import AssignToGroupSVG from "../../../icons/AssignToGroup";
import AssignToUserSVG from "../../../icons/AssignToUserSVG";
import DeleteSVG from "../../../icons/DeleteSVG";
import EditSVG from "../../../icons/EditSVG";
import ViewSVGAction from "../../../icons/ViewSVGAction";
import {
  bulkDeleteTrainings,
  deleteTraining,
  getListData,
  getWholeListData,
  updateTraining,
} from "../../../services/trainingsService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../../store/reducers/alertMessageReducer";
import { groupManagementSelector } from "../../../store/reducers/groupManagementReducer";
import { setWholeData } from "../../../store/reducers/trainingsReducer";
import { RootState, useAppDispatch } from "../../../store/store";
import { defaultThemeColor } from "../../../utils/colors_values";
import { confirmProps } from "../../../utils/confirmProps";
import Style from "../../Assessment/AssessmentList.module.scss";
import AssignToGroupModal from "./AssignToGroup";
import AssignToUserModal from "./AssignToUser";

function TrainingLists() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const userInfo = useSelector((state: any) => state.authReducer.loginData);
  const addPermission = usePermission("add_training");
  const editUpdatePermission = usePermission("change_training");
  const deletePermission = usePermission("delete_training");
  const viewPermission = usePermission("view_training");
  const [itemPerPage, setItemPerPage] = useState<number>(10);

  const [searchTable, setSearchTable] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState<any>(false);
  const [disableCheckboxes, setDisableCheckboxes] = React.useState<any>([]);
  const [disableCheckboxes2, setDisableCheckboxes2] = React.useState<any>([]);
  const [save, setSave] = React.useState<any>(false);
  const [openGroup, setOpenGroup] = React.useState<any>(false);
  const [saveGroup, setSaveGroup] = React.useState<any>(false);
  const [members, setMembers] = useState<any>([]);
  const [groups, setGroups] = useState<any>([]);

  const [list, setList] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<any[]>([]);

  const [id, setID] = useState<any>();
  const [isEditablwe, setIsEditable] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>();

  const [page, setPage] = useState(1);
  const states = useSelector(groupManagementSelector).departmentReducer;
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;
  const [rows, setRows] = useState([]);
  const [fetchID, setFetchID] = useState<any>(0);

  const isLoading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );

  // const page = useSelector(
  //   (state: RootState) => state.groupManagementReducer.page
  // );

  const dataList = useSelector(
    (state: RootState) => state.trainingsReducer.data
  );

  const wholeData = useSelector(
    (state: RootState) => state.trainingsReducer.wholeData
  );

  // useEffect(() => {
  //   dispatch(setPage(1));
  // }, []);
  useEffect(() => {
    dispatch(
      getListData({
        page_size: Number(itemPerPage),
        page: Number(page),
        search: searchTable,
      })
    );
  }, [itemPerPage, page, searchTable]);
  useEffect(() => {
    let data: any = dataList.results;
    if (Object.keys(dataList).length === 0) {
      setRows([]);
    } else setRows(data);
  }, [dataList]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      setSelectedCheckBox([]);
      dispatch(
        getListData({
          page_size: Number(itemPerPage),
          page: Number(page),
          search: searchTable,
        })
      );
    }
    setAnchorEl(null);
  }, [alertMessage]);

  const handleDeleteAction = (row: any) => {
    confirm({
      title: t("Are you sure!"),
      description: `Do you want to delete ${selectedRow?.name} training?`,
      ...confirmProps,
    })
      .then(() => {
        setAnchorEl(null);
        dispatch(deleteTraining(id));
      })
      .catch(() => {
        // toast.success("Your request canceled successfully!", {
        //   toastId: "Your request canceled successfully!",
        // });
      });
    return;
  };

  const handleClose = () => {
    setOpen(false);
    setOpenGroup(false);
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setID(row?.id);
    setIsEditable(!row?.is_assigned);
    setSelectedRow(row);
    setAnchorEl(event.currentTarget);
    setDisableCheckboxes2(row?.members || []);
    setDisableCheckboxes(row?.groups || []);
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setSelectedCheckBox([]);
    setPage(page);
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPage(page);
    setSelectedCheckBox([]);
    setItemPerPage(Number(rowsPerPage));
  };

  useEffect(() => {
    modifyRows();
  }, [rows, fetchID]);

  const bulkDelete = (ids: any) => {
    // const sendSelected = selected.map((sel) => sel);
    confirm({
      title: t("Are you sure!"),
      description: t(`Do you want to delete selected trainings?`),
      ...confirmProps,
    })
      .then(() => {
        dispatch(bulkDeleteTrainings(ids));
        setSelectedCheckBox([]);
      })
      .catch(() => {});
  };

  const fetchprogress = (id: any) => {
    setFetchID(id);
  };

  useEffect(() => {
    modifyRows();
  }, [anchorEl]);

  const modifyRows = () => {
    let f_rows: any = [];

    rows.forEach(
      (
        r: {
          id: string;
          name: string;
          level: string;
          type: string;
          member_count: string;
          created_at: string;
          created_by: any;
        },
        i: number
      ) => {
        f_rows =
          userInfo?.primary_role == "Admin"
            ? [
                ...f_rows,

                {
                  id: r.id,
                  name: r.name,
                  type:
                    r?.type == "Self_Paced"
                      ? "Self-Paced"
                      : r?.type == "Not_Scheduled"
                      ? "Not Scheduled"
                      : r.type,
                  level: r.level,
                  member_count: r.member_count,
                  created_by: r?.created_by?.first_name
                    ? r?.created_by?.first_name + " " + r?.created_by?.last_name
                    : "N/A",
                  created_at:
                    moment(new Date(r.created_at)).format("MMM Do, YYYY") || "",
                  actions: (
                    <div className="col-3 text-center">
                      <IconButton
                        aria-label="more"
                        aria-controls={anchorEl ? "long-menu" : undefined}
                        aria-haspopup="true"
                        onClick={(e) => handleClick(e, r)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        className={Style.menu}
                        MenuListProps={{
                          "aria-labelledby": `long-button-${r?.id}`,
                        }}
                        id="long-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            width: "20ch",
                            boxShadow:
                              "0px 5px 5px -3px rgba(0,0,0,0.01), 0px 3px 6px 1px rgba(0,0,0,0.01), 0px 3px 6px 2px rgba(0,0,0,0.01)",
                          },
                        }}
                      >
                        {viewPermission && (
                          <MenuItem
                            onClick={(e: any) => {
                              navigate(
                                `/detail-training/view/${id.toString()}`
                              );
                            }}
                          >
                            <ViewSVGAction />
                            <span className="px-2">{t("View")}</span>
                          </MenuItem>
                        )}
                        {editUpdatePermission && isEditablwe && (
                          <MenuItem
                            onClick={() => {
                              navigate(
                                `/update-training/edit/${id.toString()}`
                              );
                            }}
                          >
                            <EditSVG />{" "}
                            <span className="px-2">{t("Edit")}</span>
                          </MenuItem>
                        )}
                        {editUpdatePermission && (
                          <MenuItem
                            onClick={(e) => {
                              setAnchorEl(null);
                              setOpen(true);
                            }}
                          >
                            <span>
                              <AssignToUserSVG color="#2B3674" />{" "}
                            </span>
                            <span className="px-1">{t("Assign to user")}</span>
                          </MenuItem>
                        )}
                        {editUpdatePermission &&
                          selectedRow?.type == "Self_Paced" && (
                            <MenuItem
                              onClick={(e) => {
                                setAnchorEl(null);
                                setOpenGroup(true);
                              }}
                            >
                              <span>
                                <AssignToGroupSVG color="#2B3674" />
                              </span>
                              <span className="px-1">
                                {t("Assign to group")}
                              </span>
                            </MenuItem>
                          )}

                        {deletePermission && isEditablwe && (
                          <MenuItem
                            onClick={() => {
                              handleDeleteAction(id);
                            }}
                          >
                            <DeleteSVG color="red" />
                            <span className="px-2">{t("Remove")}</span>
                          </MenuItem>
                        )}
                      </Menu>
                    </div>
                  ),
                },
              ]
            : [
                ...f_rows,
                {
                  id: r.id,
                  name: <div>{r.name}</div>,
                  type: r?.type == "Self_Paced" ? "Self-Paced" : r.type,
                  level: r.level,

                  member_count: r.member_count,

                  created_at:
                    moment(new Date(r.created_at)).format("MMM Do, YYYY") || "",
                  actions: (
                    <div className="col-3 text-center">
                      <IconButton
                        aria-label="more"
                        aria-controls={anchorEl ? "long-menu" : undefined}
                        aria-haspopup="true"
                        onClick={(e) => handleClick(e, r)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        className={Style.menu}
                        MenuListProps={{
                          "aria-labelledby": `long-button-${r?.id}`,
                        }}
                        id="long-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            width: "20ch",
                            boxShadow:
                              "0px 5px 5px -3px rgba(0,0,0,0.01), 0px 3px 6px 1px rgba(0,0,0,0.01), 0px 3px 6px 2px rgba(0,0,0,0.01)",
                          },
                        }}
                      >
                        {viewPermission && (
                          <MenuItem
                            onClick={(e: any) => {
                              navigate(
                                `/detail-training/view/${id.toString()}`
                              );
                            }}
                          >
                            <ViewSVGAction />
                            <span className="px-2">{t("View")}</span>
                          </MenuItem>
                        )}
                        {editUpdatePermission && isEditablwe && (
                          <MenuItem
                            onClick={() => {
                              navigate(
                                `/update-training/edit/${id.toString()}`
                              );
                            }}
                          >
                            <EditSVG />{" "}
                            <span className="px-2">{t("Edit")}</span>
                          </MenuItem>
                        )}
                        {editUpdatePermission && (
                          <MenuItem
                            onClick={(e) => {
                              setAnchorEl(null);
                              setOpen(true);
                            }}
                          >
                            <span>
                              <AssignToUserSVG color="#2B3674" />{" "}
                            </span>
                            <span className="px-1">{t("Assign to user")}</span>
                          </MenuItem>
                        )}
                        {editUpdatePermission &&
                          selectedRow?.type == "Self_Paced" && (
                            <MenuItem
                              onClick={(e) => {
                                setAnchorEl(null);
                                setOpenGroup(true);
                              }}
                            >
                              <span>
                                {" "}
                                <AssignToGroupSVG color="#2B3674" />
                              </span>
                              <span className="px-1">
                                {t("Assign to group")}
                              </span>
                            </MenuItem>
                          )}

                        {deletePermission && isEditablwe && (
                          <MenuItem
                            onClick={() => {
                              handleDeleteAction(id);
                            }}
                          >
                            <DeleteSVG color="red" />
                            <span className="px-2">{t("Remove")}</span>
                          </MenuItem>
                        )}
                      </Menu>
                    </div>
                  ),
                },
              ];
      }
    );

    setList(f_rows);
  };

  const AddGroup = () => {
    const handleAddNew = () => {
      navigate("/add-training");
    };

    const interfaceBgTextColor =
      useColor("interface_background_text_color") ||
      defaultThemeColor.interface_background_text_color;

    return (
      <div className="d-flex flex-row gap-3">
        {addPermission && (
          <>
            <Button
              variant="text"
              color="inherit"
              endIcon={
                <AddIcon
                  style={{
                    fontSize: 16,
                    color: interfaceBgTextColor,
                    marginRight: lang === "ar" ? "10px" : "0px",
                  }}
                />
              }
              style={{
                fontSize: 13,
                color: interfaceBgTextColor,
                textTransform: "capitalize",
              }}
              onClick={() => handleAddNew()}
            >
              {t("Add New")}
            </Button>
          </>
        )}
      </div>
    );
  };

  const handleClicked = () => {
    getWhole();
  };

  const getWhole = () => {
    if (dataList)
      dispatch(
        getWholeListData({
          page_size: dataList.count,
          page: 1,
          search: "",
        })
      );
  };

  useEffect(() => {
    setMembers({
      results:
        dataList && dataList?.results?.find((i: any) => i.id == id)?.members,
    });

    setGroups({
      results:
        dataList && dataList?.results?.find((i: any) => i.id == id)?.groups,
    });
  }, [id, dataList]);

  useEffect(() => {
    if (save && members && members?.results && members?.results.length > 0) {
      let assess = dataList.results.find((i: any) => i.id == id);
      let groups = assess?.groups.map((i: any) => i.id);
      let ids = members?.results.map((i: any) => Number(i.id));
      let f_payload = {
        members: ids,
        groups: groups,
      };

      let modulesArray =
        assess.ordered_modules?.length > 0
          ? assess.ordered_modules?.map((item: any, index: any) => {
              return {
                module: item?.module?.id,
                order: index + 1,
                is_compulsory: item?.is_compulsory ? "True" : "False",
              };
            })
          : [];

      if (id) {
        const formData = new FormData();
        formData.append("name", assess.name);
        formData.append("description", assess.description);
        formData.append("level", assess.level);
        formData.append("category", assess?.category?.id);
        formData.append("certificate", assess?.certificate?.id);
        formData.append(
          "modules_with_order",
          JSON.stringify(modulesArray)
            .replaceAll(`"False"`, `False`)
            .replaceAll(`"True"`, `True`)
        );
        ids && ids.length > 0 && formData.append("members", ids);
        if (selectedRow?.type == "Self_Paced") {
          groups && groups.length > 0 && formData.append("groups", groups);
        }

        dispatch(updateTraining(formData, parseInt(id)));
      }
      setSave(false);
    }
  }, [save]);

  useEffect(() => {
    if (saveGroup && groups && groups?.results && groups?.results.length > 0) {
      let assess = dataList.results.find((i: any) => i.id == id);
      let ids = groups?.results.map((i: any) => i.id);
      let f_payload = {
        groups: ids,
      };
      let members = assess?.members.map((i: any) => i.id);

      let modulesArray =
        assess.ordered_modules?.length > 0
          ? assess.ordered_modules?.map((item: any, index: any) => {
              return {
                module: item?.module?.id,
                order: index + 1,
                is_compulsory: item?.is_compulsory ? "True" : "False",
              };
            })
          : [];

      if (id) {
        const formData = new FormData();
        formData.append("name", assess.name);
        formData.append("description", assess.description);
        formData.append("level", assess.level);
        formData.append("category", assess?.category?.id);
        formData.append("certificate", assess?.certificate?.id);
        formData.append(
          "modules_with_order",
          JSON.stringify(modulesArray)
            .replaceAll(`"False"`, `False`)
            .replaceAll(`"True"`, `True`)
        );
        members && members.length > 0 && formData.append("members", members);
        if (selectedRow?.type == "Self_Paced") {
          groups &&
            groups?.results?.length > 0 &&
            formData.append("groups", ids);
        }
        dispatch(updateTraining(formData, parseInt(id)));
      }
      setSaveGroup(false);
    }
  }, [saveGroup]);

  return (
    <>
      <Header
        page={t("Training List")}
        mainHead={t("Training Management / Training")}
      />

      <TableFilters
        dataList={dataList}
        itemPerPage={itemPerPage}
        setItemPerPage={setItemPerPage}
        selectedCheckBox={selectedCheckBox}
        bulkDelete={bulkDelete}
        setSearchTable={setSearchTable}
        createButton={<AddGroup />}
        handle="groups"
        isCheckboxes={deletePermission}
        exportCSV={dataList?.count ? true : false}
        setParentData={() => dispatch(setWholeData({}))}
        handleClicked={handleClicked}
        rows={wholeData?.results?.map((r: any, i: any) => ({
          // id: r.id,
          name: r.name,
          type: r?.type == "Self_Paced" ? "Self-Paced" : r.type,
          member_count: r.member_count,
          level: r.level,
          created_at:
            moment(new Date(r.created_at)).format("MMM Do, YYYY") || "",
        }))}
        headers={[
          // "ID",
          "Name",
          "Type",
          "No. of Enrolled Users",
          "Level",
          "Date Created",
        ]}
        filename={`tarining_list-${moment().format()}.csv`}
      />

      <DataTable
        columnData={
          userInfo?.primary_role == "Admin"
            ? [
                {
                  id: "name",
                  name: t("Name"),
                  enableSort: true,
                  align: lang === "ar" ? "center" : "left",
                  disablePadding: "none",
                },
                {
                  id: "type",
                  name: t("Type"),
                  enableSort: true,
                  align: lang === "ar" ? "center" : "left",
                  disablePadding: "none",
                },
                {
                  id: "level",
                  name: t("Level"),
                  enableSort: true,
                  align: lang === "ar" ? "center" : "left",
                  disablePadding: "none",
                },
                {
                  id: "member_count",
                  name: t(`No of Enrolled Users`),
                  enableSort: true,
                  align: lang === "ar" ? "center" : "center",
                  disablePadding: "none",
                },
                {
                  id: "created_by",
                  name: t("Created By"),
                  enableSort: true,
                  align: lang === "ar" ? "center" : "left",
                  disablePadding: "none",
                },
                {
                  id: "created_at",
                  name: t("Date Created"),
                  enableSort: true,
                  align: lang === "ar" ? "center" : "left",
                  disablePadding: "none",
                },
                {
                  id: "actions",
                  name: t("Actions"),
                  enableSort: false,
                  align: lang === "ar" ? "right" : "left",
                  disablePadding: "none",
                },
              ]
            : [
                {
                  id: "name",
                  name: t("Name"),
                  enableSort: true,
                  align: lang === "ar" ? "center" : "left",
                  disablePadding: "none",
                },
                {
                  id: "type",
                  name: t("Type"),
                  enableSort: true,
                  align: lang === "ar" ? "center" : "left",
                  disablePadding: "none",
                },
                {
                  id: "level",
                  name: t("Level"),
                  enableSort: true,
                  align: lang === "ar" ? "center" : "left",
                  disablePadding: "none",
                },
                {
                  id: "member_count",
                  name: t(`No of Enrolled Users`),
                  enableSort: true,
                  align: lang === "ar" ? "center" : "left",
                  disablePadding: "none",
                },

                {
                  id: "created_at",
                  name: t("Date Created"),
                  enableSort: true,
                  align: lang === "ar" ? "center" : "left",
                  disablePadding: "none",
                },
                {
                  id: "actions",
                  name: t("Actions"),
                  enableSort: false,
                  align: lang === "ar" ? "right" : "left",
                  disablePadding: "none",
                },
              ]
        }
        rows={list}
        isCheckboxes={deletePermission}
        selectedRowsPerPage={itemPerPage.toString()}
        totalRecordCount={Number(dataList.count)}
        handleChangePageParent={handleChangePage}
        pagenum={Number(page)}
        handleChangeRowsPerPageParent={handleChangeRowsPerPage}
        bulkDelete={bulkDelete}
        selectedCheckBox={selectedCheckBox}
        setSelectedCheckBox={setSelectedCheckBox}
        // order2={"DESC"}
        // orderBy2={"created_at"}
      />

      {id && open && (
        <AssignToUserModal
          disableCheckboxes={disableCheckboxes2}
          setParentOpen={setOpen}
          open={open}
          onClose={handleClose}
          isLoading={isLoading}
          setSelectedRows={setMembers}
          selectedRows={members}
          defaultRows={members}
          save={save}
          setSave={setSave}
        />
      )}
      {id && openGroup && (
        <AssignToGroupModal
          disableCheckboxes={disableCheckboxes}
          setParentOpen={setOpenGroup}
          open={openGroup}
          onClose={handleClose}
          isLoading={isLoading}
          setSelectedRows={setGroups}
          selectedRows={groups}
          defaultRows={groups}
          save={saveGroup}
          setSave={setSaveGroup}
        />
      )}
    </>
  );
}

export default TrainingLists;
