import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DataTable from "../../components/table/DataTable";
import useCompanyLanguage from "../../hooks/language";
import SearchSVG from "../../icons/SearchSVG";
import { getDepMembers } from "../../services/departmentService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { RootState, useAppDispatch } from "../../store/store";

const SearchTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#A0AAB4",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& #custom-css-outlined-input": {
    zIndex: 1,
    padding: 7.5,
    paddingLeft: 2,
    fontSize: "12px",
    width: "14rem",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      backgroundColor: "white",
      borderColor: "white",
      borderRadius: 50,
      fontFamily: "dmSans",
      fontSize: "14px",
      fontWeight: 400,
      letterSpacing: "-0.0175rem",
      width: "14rem",
      zIndex: 0,
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6F7E8C",
    },
  },
});

const MemberList = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const members = useSelector(
    (state: RootState) => state.departmentReducer.membersData
  );
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;
  const [page, setPage] = useState<number>(1);
  const [itemPerPage, setItemPerPage] = useState<number>(10);
  const [searchTable, setSearchTable] = useState<string>("");
  const [rows, setRows] = useState([]);
  const [list, setList] = useState([]);
  useEffect(() => {
    dispatch(
      getDepMembers(Number(id), {
        page_size: Number(itemPerPage),
        page: Number(page),
        search: searchTable,
      })
    );
  }, [id, dispatch, itemPerPage, page, searchTable]);
  useEffect(() => {
    let data: any = members?.results || [];
    if (Object.keys(data)?.length === 0) {
      setRows([]);
    } else setRows(data);
  }, [members]);
  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });

      dispatch(setSuccess(null));
      dispatch(
        getDepMembers(Number(id), {
          page_size: Number(itemPerPage),
          page: Number(page),
          search: searchTable,
        })
      );
    }
  }, [alertMessage]);
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPage(page);
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    if (!(page === 1)) setPage(1);
    setItemPerPage(Number(rowsPerPage));
  };

  useEffect(() => {
    modifyRows();
  }, [rows]);

  const modifyRows = () => {
    let f_rows: any = [];
    rows.forEach(
      (
        r: {
          id: string;
          first_name: string;
          last_name: string;
          email: string;
          created_at: string;
        },
        i: number
      ) => {
        f_rows = [
          ...f_rows,
          {
            id: r?.id,
            name: r?.first_name + " " + r?.last_name,
            email: r?.email || "",
            created_at:
              moment(new Date(r.created_at)).format("MMM Do, YYYY") || "",
          },
        ];
      }
    );
    setList(f_rows);
  };

  return (
    <>
      <div style={{ paddingBottom: "0.6em" }}>
        <div
          className="d-flex justify-content-between flex-wrap"
          style={{ marginTop: 3 }}
        >
          <div className="col-12">
            <FormControl variant="standard" sx={{ m: 1, mr: 3, minWidth: 67 }}>
              <InputLabel
                id="demo-simple-select-autowidth-label"
                style={{ fontSize: "12px" }}
                disableAnimation={true}
                shrink={false}
              ></InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={itemPerPage}
                onChange={(e) => {
                  setPage(1);
                  setItemPerPage(Number(e.target.value));
                }}
                autoWidth
                disableUnderline
              >
                <MenuItem value={Number(members?.count)}>
                  {t("All")} ({Number(members?.count) || 0})
                </MenuItem>
                <MenuItem value="10">10 {t("Per Page")}</MenuItem>
                <MenuItem value="15">15 {t("Per Page")}</MenuItem>
                <MenuItem value="25">25 {t("Per Page")}</MenuItem>
              </Select>
            </FormControl>

            <SearchTextField
              className="float-end mt-4"
              autoComplete="off"
              sx={{ mt: 0.3, ml: 5, mr: 0 }}
              style={{ width: "14rem" }}
              placeholder={t("Search")}
              onChange={(e) => {
                setPage(1);
                setSearchTable(e.target.value);
              }}
              id="custom-css-outlined-input"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ pl: 1 }}>
                    <SearchSVG />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: false,
              }}
            />
          </div>
        </div>
      </div>
      <DataTable
        columnData={[
          {
            id: "name",
            name: t("Name"),
            enableSort: true,
            align: lang === "ar" ? "center" : "left",
            disablePadding: "none",
          },

          {
            id: "email",
            name: t("Email"),
            enableSort: true,
            align: lang === "ar" ? "center" : "left",
            disablePadding: "none",
          },

          {
            id: "created_at",
            name: t("Created"),
            enableSort: true,
            align: lang === "ar" ? "center" : "left",
            disablePadding: "none",
          },
        ]}
        rows={list}
        isCheckboxes={false}
        selectedRowsPerPage={String(itemPerPage)}
        totalRecordCount={Number(members?.count)}
        handleChangePageParent={handleChangePage}
        pagenum={Number(page)}
        handleChangeRowsPerPageParent={handleChangeRowsPerPage}
      />
    </>
  );
};

export default MemberList;
