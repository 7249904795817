import { toast } from "react-toastify";
import axios from "../lib/axios";
import axiosPrivate from "../lib/axiosPrivate";
import {
  UpdateCompanyForm,
  setCompanyFormData,
  setCompanyFormDataFailed,
  setUpdateCompanyFormData,
  setUpdateCompanyFormDataFailed,
} from "../store/reducers/companyFormReducer";
import { setLoading } from "../store/reducers/loaderReducer";
import { AppThunk } from "../store/store";
import { defaultThemeColor } from "../utils/colors_values";

export const getCompanyForm = (): AppThunk => async (dispatch) => {
  // dispatch(setLoading(true));
  try {
    await axios
      .get("/commom/company/")
      .then((response) => {
        let data = response.data;
        // dispatch(setLoading(false));

        if (response.status === 200) {
          localStorage.setItem("company_profile", JSON.stringify(data?.[0]));
          dispatch(setCompanyFormData(data?.[0] ? data?.[0] : null));
        } else {
          dispatch(
            setCompanyFormDataFailed("Something went wrong, Please try again")
          );
        }
      })
      .catch((error) => {
        dispatch(setLoading(false));
        dispatch(setCompanyFormDataFailed(error.response.data.detail));
      });
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(
      setCompanyFormDataFailed("Something went wrong, Please try again")
    );
  }
};

export const updateCompanyForm =
  (payload: UpdateCompanyForm, resetColors: boolean = false): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      let body = payload;
      let formData = new FormData();

      if (body.image) {
        formData.append("company_logo", body.image, body.imagename);
      }
      if (body.admin_name) {
        formData.append("admin_name", body.admin_name);
      }
      if (body.admin_email) {
        formData.append("admin_email", body.admin_email);
      }
      if (body.is_report_card_enabled) {
        formData.append("is_report_card_enabled", body.is_report_card_enabled);
      }
      if (body.company_name) {
        formData.append("company_name", body.company_name);
      }
      if (body.company_email) {
        formData.append("company_email", body.company_email);
      }
      if (body.support_email) {
        formData.append("support_email", body.support_email);
      }
      if (body.support_alias) {
        formData.append("support_alias", body.support_alias);
      }
      if (body.company_language) {
        formData.append("company_language", body.company_language);
      }
      if (body.license_key) {
        formData.append("license_key", body.license_key);
      }
      if (resetColors) {
        formData.append(
          "sidebar_text_color",
          defaultThemeColor.sidebar_text_color
        );
        formData.append(
          "sidebar_text_active",
          defaultThemeColor.sidebar_text_active
        );
        formData.append(
          "sidebar_text_active_background",
          defaultThemeColor.sidebar_text_active_background
        );
        formData.append(
          "sidebar_background_color",
          defaultThemeColor.sidebar_background_color
        );
        formData.append(
          "interface_background_color",
          defaultThemeColor.interface_background_color
        );
        formData.append(
          "interface_background_text_color",
          defaultThemeColor.interface_background_text_color
        );
        formData.append(
          "interface_foreground_text_color",
          defaultThemeColor.interface_foreground_text_color
        );
        formData.append(
          "interface_foreground_color",
          defaultThemeColor.interface_foreground_color
        );
        formData.append("button_primary", defaultThemeColor.button_primary);
        formData.append(
          "button_primary_text",
          defaultThemeColor.button_primary_text
        );
        formData.append("button_secondary", defaultThemeColor.button_secondary);
        formData.append(
          "button_secondary_text",
          defaultThemeColor.button_secondary_text
        );
      } else {
        if (body.sidebar_text_color) {
          formData.append("sidebar_text_color", body.sidebar_text_color);
        }
        if (body.sidebar_text_active) {
          formData.append("sidebar_text_active", body.sidebar_text_active);
        }
        if (body.sidebar_text_active_background) {
          formData.append(
            "sidebar_text_active_background",
            body.sidebar_text_active_background
          );
        }
        if (body.sidebar_background_color) {
          formData.append(
            "sidebar_background_color",
            body.sidebar_background_color
          );
        }
        if (body.interface_background_color) {
          formData.append(
            "interface_background_color",
            body.interface_background_color
          );
        }
        if (body.interface_background_text_color) {
          formData.append(
            "interface_background_text_color",
            body.interface_background_text_color
          );
        }
        if (body.interface_foreground_text_color) {
          formData.append(
            "interface_foreground_text_color",
            body.interface_foreground_text_color
          );
        }
        if (body.interface_foreground_color) {
          formData.append(
            "interface_foreground_color",
            body.interface_foreground_color
          );
        }
        if (body.button_primary) {
          formData.append("button_primary", body.button_primary);
        }
        if (body.button_primary_text) {
          formData.append("button_primary_text", body.button_primary_text);
        }
        if (body.button_secondary) {
          formData.append("button_secondary", body.button_secondary);
        }
        if (body.button_secondary_text) {
          formData.append("button_secondary_text", body.button_secondary_text);
        }
      }
      await axiosPrivate
        .patch("/commom/company/create_or_update/", formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=myboundary",
            enctype: "multipart/form-data; boundary=myboundary",
          },
        })
        .then((response) => {
          dispatch(setUpdateCompanyFormData({}));
          let data = response.data;

          dispatch(setLoading(false));
          if (response.status === 200) {
            dispatch(setUpdateCompanyFormData(data));
            toast.success(
              String(data?.detail || "Company profile updated successfully"),
              {
                toastId: String(
                  data?.detail || "Company profile updated successfully"
                ),
              }
            );
            dispatch(getCompanyForm());
          } else {
            toast.success(
              String(data?.detail || "Company profile updated successfully"),
              {
                toastId: String(
                  data?.detail || "Company profile updated successfully"
                ),
              }
            );
            dispatch(
              setUpdateCompanyFormDataFailed(
                "Something went wrong, Please try again"
              )
            );
          }
        })
        .catch((error) => {
          let errormsg = error.response.data
            ? error.response.data.detail
              ? error.response.data.detail
              : error?.response.data?.company_logo &&
                error?.response.data?.company_logo[0]
              ? error?.response.data?.company_logo[0]
              : error.response.data?.company_language &&
                error.response.data?.company_language[0]
              ? error.response.data?.company_language[0]
              : error.response.data?.is_report_card_enabled &&
                error.response.data?.is_report_card_enabled[0]
              ? error.response.data?.is_report_card_enabled[0]
              : error.response.data?.company_email &&
                error.response.data?.company_email[0]
              ? "Enter a valid company email address"
              : error.response.data?.support_email &&
                error.response.data?.support_email[0]
              ? "Enter a valid support email address"
              : error.response.data?.admin_email &&
                error.response.data?.admin_email[0]
              ? "Enter a valid admin email address"
              : error.response.data?.admin_name &&
                error.response.data?.admin_name[0]
              ? error.response.data?.admin_name[0]
              : error.response.data?.company_name[0] &&
                error.response.data?.company_name[0]
              ? error.response.data?.company_name[0]
              : error.response.data?.support_alias[0] &&
                error.response.data?.support_alias[0]
              ? error.response.data?.support_alias[0]
              : "Something went wrong"
            : "";

          toast.error(String(errormsg), {
            toastId: String(errormsg),
          });
          dispatch(setLoading(false));
          dispatch(setUpdateCompanyFormDataFailed(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        setUpdateCompanyFormDataFailed("Something went wrong, Please try again")
      );
    }
  };
