// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* body {
    background: #f3f1f2;
    font-family: sans-serif;
  } */
  
  /* #root { */
    /* margin: 1rem 4rem; */
  /* } */
  
  .ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
  }
  
  /* Snow Theme */
.ql-snow.ql-toolbar {
    display: block;
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }
  
.ql-editor {
    min-height: 18em;
  }
  
  /* .quill > .ql-toolbar:first-child {
    display: none !important;
  } */`, "",{"version":3,"sources":["webpack://./src/pages/PolicyManagement/Editor/styles.css"],"names":[],"mappings":"AAAA;;;KAGK;;EAEH,YAAY;IACV,uBAAuB;EACzB,MAAM;;EAEN;IACE,gCAAgC;IAChC,iCAAiC;IACjC,mBAAmB;EACrB;;EAEA,eAAe;AACjB;IACI,cAAc;IACd,mBAAmB;IACnB,6BAA6B;IAC7B,8BAA8B;EAChC;;AAEF;IACI,gBAAgB;EAClB;;EAEA;;KAEG","sourcesContent":["/* body {\n    background: #f3f1f2;\n    font-family: sans-serif;\n  } */\n  \n  /* #root { */\n    /* margin: 1rem 4rem; */\n  /* } */\n  \n  .ql-container {\n    border-bottom-left-radius: 0.5em;\n    border-bottom-right-radius: 0.5em;\n    background: #fefcfc;\n  }\n  \n  /* Snow Theme */\n.ql-snow.ql-toolbar {\n    display: block;\n    background: #eaecec;\n    border-top-left-radius: 0.5em;\n    border-top-right-radius: 0.5em;\n  }\n  \n.ql-editor {\n    min-height: 18em;\n  }\n  \n  /* .quill > .ql-toolbar:first-child {\n    display: none !important;\n  } */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
