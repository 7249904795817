import { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../../components/Header";
import useCompanyLanguage from "../../../hooks/language";
import useColor from "../../../hooks/useColor";
import AssessImg from "../../../images/startAssessment.svg";
import { getTraining } from "../../../services/trainingsService";
import { RootState, useAppDispatch } from "../../../store/store";
import { defaultThemeColor } from "../../../utils/colors_values";
import { assessmentTimeOptionsLabel } from "../../../utils/staticEntities";
import Style from "./AssessmentName.module.scss";
// const trainingDetail = JSON.parse(
//   localStorage.getItem("training_detail") || "{}"
// );
function StartAssessment() {
  const { training_id, module_id, assessment_id }: any = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const trainingDetail: any = useSelector(
    (state: RootState) => state.trainingsReducer.trainingData
  );
  useEffect(() => {
    dispatch(getTraining(Number(training_id)));
  }, [training_id]);
  const [module, setModule]: any = useState<any>();

  useEffect(() => {
    const currentModule = trainingDetail?.ordered_modules?.filter(function (
      row: any
    ) {
      return (
        parseInt(row?.module?.id) === parseInt(module_id) &&
        parseInt(row?.module?.assessment?.id) === parseInt(assessment_id)
      );
    });
    setModule(currentModule?.[0]);
  }, [trainingDetail, training_id, module_id, assessment_id]);
  useEffect(() => {
    if (
      module?.module?.module_status?.toLowerCase() === "completed" &&
      !module?.module?.assessment?.retaking_assessment
    ) {
      toast.error(String(t("You are not allow to retake this assessment")), {
        toastId: String(t("You are not allow to retake this assessment")),
      });
      // navigate("/my-training");
      return;
    }
  }, [module]);
  return (
    <div
      className={`${Style.background}`}
      style={{
        background:
          useColor("interface_background_color") ||
          defaultThemeColor.interface_background_color,
        color:
          useColor("interface_foreground_text_color") ||
          defaultThemeColor.interface_foreground_text_color,
      }}
    >
      <div style={{ paddingBottom: "2.4em" }}>
        <Header
          page={module?.module?.assessment?.name}
          mainHead={t("Assessment")}
          backLink={`/training-details/${training_id}`}
        />
      </div>
      <div>
        <div
          style={{
            background:
              useColor("interface_foreground_color") ||
              defaultThemeColor.interface_foreground_color,

            borderRadius: 10,
            padding: "3rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
          className={Style.bottomSpace}
        >
          <div className={`${Style.mainWrapper}`}>
            <div className={Style.assessImgWrap}>
              <img src={AssessImg} alt="assess-img" />
            </div>
            <div className={Style.assessHeading}>
              {module?.module?.assessment?.name}
            </div>
            <div
              className={Style.flexWrapper}
              style={{
                minWidth: "22em",
                justifyContent:
                  module?.module?.assessment?.time_option !== "No_Limit"
                    ? "space-between"
                    : "center",
              }}
            >
              <div className={Style.questionWrap}>
                <div className={Style.heading}>{t("Questions")}</div>
                <div className={Style.count}>
                  {module?.module?.assessment?.questions?.length}{" "}
                  {t("Questions")}
                </div>
              </div>
              {/* <div className={Style.secWrap}>
                <div className={Style.heading}>Sections</div>
                <div className={Style.count}>2 Sections</div>
              </div> */}
              {module?.module?.assessment?.time_option !== "No_Limit" && (
                <div className={Style.durationWrap}>
                  <div className={Style.heading}>{t("Time Duration")}</div>
                  <div className={Style.count}>
                    {module?.module?.assessment?.time_limit}{" "}
                    {
                      assessmentTimeOptionsLabel[
                        module?.module?.assessment?.time_option
                      ]
                    }
                  </div>
                </div>
              )}
            </div>
            {module?.module?.module_status?.toLowerCase() === "completed" ? (
              <button
                className={Style.startBtn}
                type="button"
                onClick={() => {
                  navigate(
                    `/progress-report/${training_id}/${module_id}/${assessment_id}`
                  );
                }}
              >
                {t("Result")}
              </button>
            ) : (
              <button
                className={Style.startBtn}
                onClick={() =>
                  navigate(
                    `/assessment-started/${training_id}/${module_id}/${assessment_id}`
                  )
                }
              >
                {module?.module?.module_status?.toLowerCase() ===
                t("inprogress")
                  ? t("Retake")
                  : t("Start")}
              </button>
            )}
            {/* <button
              className={Style.startBtn}
              onClick={() =>
                navigate(
                  `/assessment-started/${training_id}/${module_id}/${assessment_id}`
                )
              }
            >
              Start
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StartAssessment;
