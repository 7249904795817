/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import NotificationsNoneSharpIcon from "@mui/icons-material/NotificationsNoneSharp";
import { Badge, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Tooltip from "@mui/material/Tooltip";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getLanguage, setLanguage, useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import useCompanyLanguage from "../hooks/language";
import useColor from "../hooks/useColor";
import BackArrowSVG from "../icons/BackArrowSVG";
import BlankImage from "../images/head.png";
import PolicyAcceptReject from "../pages/PolicyManagement/PolicyAcceptRejectDialogue";
import { userRoleSwitch } from "../services/adminUserService";
import { updateNotificationStatus } from "../services/notificationService";
import { getUserData } from "../services/userService";
import { setLogOut } from "../store/reducers/authReducer";
import { RootState, useAppDispatch } from "../store/store";
import { defaultThemeColor } from "../utils/colors_values";
import { confirmProps } from "../utils/confirmProps";
import { languages } from "../utils/languages";
import Style from "./Header.module.scss";
import LanguageButton from "./LanguageButton";

interface HeaderProps {
  page: string;
  mainHead: string;
  backLink?: string;
  confirm?: any;
}

function Header({ page, mainHead, backLink, confirm }: HeaderProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const userInfo = useSelector((state: any) => state.authReducer.loginData);
  const redirect = useSelector((state: any) => state.authReducer.redirect);

  useEffect(() => {
    const redirctProfile = localStorage.getItem("redirect");

    if (
      !JSON.parse(localStorage.getItem("user_info") as any)?.is_superadmin &&
      redirctProfile == "true"
    ) {
      navigate("/my-profile/edit");
      return;
    }
  }, [userInfo, redirect]);
  useEffect(() => {
    const intervalLogout = setInterval(() => {
      const isAccessToken = localStorage.getItem("access_token");
      if (!isAccessToken) {
        dispatch(setLogOut());
        window.location.href = window.location.href;
        return;
      }
    }, 2000);
    return () => {
      clearInterval(intervalLogout);
    };
  }, []);
  const companyFormData =
    useSelector(
      (state: RootState) => state.profileManagementReducer.company_form_data
    ) || [];
  useEffect(() => {
    if (userInfo?.preferred_language) {
      const lang = languages?.find((r: any) => {
        return r?.name?.toString() === userInfo?.preferred_language?.toString();
      });
      setLanguage(lang?.code?.toString() || "en");
    } else {
      const lang = languages?.find((r: any) => {
        return (
          r?.name?.toString() === companyFormData?.company_language?.toString()
        );
      });
      setLanguage(lang?.code?.toString() || "en");
    }
  }, [
    companyFormData?.company_language,
    languages,
    userInfo?.preferred_language,
  ]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const ownNotifications = useSelector(
    (state: any) => state.notificationReducer.ownNotificationData
  );
  const unReadNotificationCount = useSelector(
    (state: any) => state.notificationReducer.unread_notifications_count
  );

  const [notificationMenuOpen, setNotificationMenuOpen] = useState(false);
  const [anchorNotificationEl, setAnchorNotificationEl] =
    React.useState<HTMLButtonElement | null>(null);

  const confirmMe = useConfirm();
  const [open2, setOpen2] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState<any>();

  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );

  const handleClose2 = (open: any) => {
    setOpen2(false);
    setSelectedNotification("");
  };

  useEffect(() => {
    dispatch(getUserData());
    // if (window.location.hostname.toString() !== "localhost") {
    const interval = setInterval(() => {
      dispatch(getUserData());
    }, 20000);
    return () => {
      clearInterval(interval);
    };
    // }
  }, []);
  const [image, setImage] = React.useState<any>();

  useEffect(() => {
    if (userInfo?.is_superadmin) {
      setImage(companyFormData?.company_logo);
    } else {
      setImage(userInfo?.profile_picture);
    }
  }, [userInfo?.profile_picture, companyFormData?.company_logo]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const logout = () => {
    dispatch(setLogOut());
    navigate("/login");
  };

  const handleNotificationClicked = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorNotificationEl(e.currentTarget);
    setNotificationMenuOpen(true);
  };

  const handleCloseNotificationMenu = () => {
    setAnchorNotificationEl(null);
    setNotificationMenuOpen(false);
  };

  const handleBackIconClick = (backLink: string) => {
    confirm ? handleConfirm() : navigate(`${backLink}`);
  };

  const myProfile = () => {
    // if (userInfo?.is_superadmin && userInfo?.role == "Admin") {
    //   navigate("/company-setting/company-profile");
    // } else {
    navigate("/my-profile");
    // }
  };

  const handleConfirm = () => {
    confirmMe({
      title: "Are you sure!",
      description: confirm,
      ...confirmProps,
    })
      .then(() => {
        navigate(`${backLink}`);
      })
      .catch(() => {});
    return;
  };

  const primaryButton =
    useColor("button_primary") || defaultThemeColor.button_primary;
  const primaryButtonText =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;

  const handleNotificationDetail = (row: any) => {
    if (row?.notification_type === "NEW_POLICY_ASSESSMENT_ASSIGNED") {
      navigate(`/policy-assessment-start/${row?.instance?.id}`);
      return;
    } else if (
      (row?.notification_type === "NEW_PERMISSION" ||
        row?.notification_type === "UPDATE_PERMISSION") &&
      !userInfo?.is_superadmin &&
      userInfo?.primary_role === "Admin"
    ) {
      if (!row?.read) {
        handleReadNotificationStatus(row);
      }
      navigate(`/my-profile`);
      return;
    } else if (row?.notification_type == "NEW_POLICY_ASSIGNED") {
      // show popup to accept or reject
      setSelectedNotification(row);
      setOpen2(true);
    } else if (!row?.read) {
      handleReadNotificationStatus(row);
    }
    return;
  };

  const handleReadNotificationStatus = (noti: any) => {
    dispatch(
      updateNotificationStatus({
        id: noti?.id,
        read: true,
      })
    );
  };
  const handleChangeRole = () => {
    if (userInfo?.primary_role !== "Trainee") {
      if (userInfo?.primary_role === userInfo?.role) {
        dispatch(
          userRoleSwitch({
            role: "Trainee",
          })
        );
        // localStorage.setItem("secondary_role", "Trainee".toString());
        navigate("/");
      } else {
        dispatch(
          userRoleSwitch({
            role: userInfo?.primary_role?.toString(),
          })
        );
        navigate("/");
      }
    }
    return;
  };
  const arabicFonts = {
    fontFamily:
      lang === "ar" ? `"Arial", "Times New Roman", sans-serif` : "dmSans",
  };
  return (
    <div>
      <div
        className={`d-flex justify-content-between align-items-center gap-3 ${Style.headerStart}`}
        style={{
          ...arabicFonts,
        }}
      >
        <div>
          <div
            className={Style.textBreadCrumb}
            style={{
              color:
                useColor("interface_background_text_color") ||
                defaultThemeColor.interface_background_text_color,
              textAlign: lang === "ar" ? "right" : "left",
            }}
          >
            <Link
              style={{
                color:
                  useColor("interface_background_text_color") ||
                  defaultThemeColor.interface_background_text_color,
              }}
              to="/"
            >
              {userInfo?.is_superadmin && userInfo?.role === "Admin"
                ? t("Super Admin")
                : t(userInfo?.role)}
            </Link>{" "}
            / {mainHead}
          </div>
          <div
            className="d-flex align-items-center gap-3"
            style={{ minHeight: "47px" }}
          >
            {backLink && (
              <Button
                className={`btn ${Style.backArrow}`}
                style={{
                  background: primaryButton,
                  transform: lang === "ar" ? "rotateY(180deg)" : "unset",
                }}
                onClick={() => handleBackIconClick(backLink)}
              >
                <BackArrowSVG color={primaryButtonText} />
              </Button>
            )}
            <div
              className={Style.textHeader}
              style={{
                color:
                  useColor("interface_background_text_color") ||
                  defaultThemeColor.interface_background_text_color,
              }}
            >
              {page}
            </div>
          </div>
        </div>
        <div
          className="d-flex align-items-center"
          style={{
            background:
              useColor("sidebar_background_color") ||
              defaultThemeColor.sidebar_background_color,
            color:
              useColor("sidebar_text_color") ||
              defaultThemeColor.sidebar_text_color,
            padding: "8px",
            borderRadius: "50px",
          }}
        >
          <LanguageButton />

          <IconButton
            onClick={(e) => handleNotificationClicked(e)}
            sx={{
              marginTop: "10px",
              ml: lang === "ar" ? 1 : 0,
              mr: lang === "ar" ? 0 : 1,
            }}
          >
            <Badge color="primary" badgeContent={unReadNotificationCount}>
              <NotificationsNoneSharpIcon
                style={{
                  fontSize: 18,
                  color:
                    useColor("sidebar_text_color") ||
                    defaultThemeColor.sidebar_text_color,
                }}
              ></NotificationsNoneSharpIcon>
            </Badge>
          </IconButton>
          <Popover
            open={notificationMenuOpen}
            anchorEl={anchorNotificationEl}
            onClose={handleCloseNotificationMenu}
            anchorPosition={{
              top: 50, // Adjust this value to specify the top position
              left: 100, // Adjust this value to specify the left position
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div className={Style.notificationPopover}>
              <div className={Style.notificationHeading}>
                <div className={Style.notiText}>{t("Notifications")}</div>
                <div className={Style.notificationCount}>
                  {unReadNotificationCount} New
                </div>
              </div>
              <div className={Style.overflowWrap}>
                {ownNotifications.length > 0 ? (
                  ownNotifications?.map((row: any) => {
                    return (
                      <div
                        className={
                          row?.read
                            ? Style.notificationItem
                            : Style.notificationItem +
                              " " +
                              Style.unreadNotification
                        }
                        key={row?.id}
                        onClick={() => {
                          handleNotificationDetail(row);
                        }}
                      >
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              getLanguage() === "ar"
                                ? row?.instance?.arabic_content
                                : row?.instance?.content,
                          }}
                        ></p>

                        <div style={{ textAlign: "right" }}>
                          <Tooltip
                            title={moment(new Date(row.created_at)).format(
                              "MMM Do, YYYY HH:mm:ss"
                            )}
                            placement="top"
                          >
                            <p>{moment(row.created_at).fromNow()}</p>
                          </Tooltip>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className={Style.notificationItem}>
                    {t("Record not found")}
                  </div>
                )}
              </div>
              <div>
                {ownNotifications.length > 5 && (
                  <Link className="float-end m-3" to="/notifications">
                    View All
                  </Link>
                )}
              </div>
            </div>
          </Popover>

          <Typography
            className="d-flex align-items-center"
            onClick={handleClick}
            style={{ cursor: "pointer" }}
          >
            <img
              alt=""
              height={41}
              width={41}
              src={image || BlankImage}
              className={Style.imageCover}
            />
          </Typography>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            {userInfo?.primary_role == userInfo?.role && (
              <Typography
                sx={{ p: 2 }}
                style={{ cursor: "pointer" }}
                onClick={myProfile}
                className={Style.hoverPop}
              >
                {t("Profile")}
              </Typography>
            )}

            {(userInfo?.primary_role === "Admin" ||
              userInfo?.primary_role === "Trainer") && (
              <Typography
                sx={{ p: 2 }}
                style={{ cursor: "pointer" }}
                className={Style.hoverPop}
                onClick={handleChangeRole}
              >
                {userInfo?.primary_role === userInfo?.role
                  ? t("Login as Trainee")
                  : userInfo?.is_superadmin &&
                    userInfo?.primary_role === "Admin"
                  ? t("Login as Super Admin")
                  : t("Login as " + userInfo?.primary_role)}
              </Typography>
            )}

            <Typography
              sx={{ p: 2 }}
              onClick={logout}
              style={{ cursor: "pointer" }}
              className={Style.hoverPop}
            >
              {t("Logout")}
            </Typography>
          </Popover>

          <PolicyAcceptReject
            setParentOpen={setOpen2}
            open={open2}
            onClose={handleClose2}
            isLoading={loading}
            policy={selectedNotification && selectedNotification?.instance}
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
