import { useConfirm } from "material-ui-confirm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import usePermission from "../../hooks/usePermission";
import { getAssessment } from "../../services/assessmentService";
import { getPolicyAssessment } from "../../services/policyAssessmentService";
import { alertMessageSelector, setErr, setSuccess } from "../../store/reducers/alertMessageReducer";
import { RootState, useAppDispatch, useAppSelector } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import { assessmentTimeOptionsLabel } from "../../utils/staticEntities";
import AssessmentMembers from "./AssessmentMembers";
import Style from "./AssessmentResult.module.scss";

function PolicyAssessmentDetail() {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const addPermission = usePermission("add_module");
  const editUpdatePermission = usePermission("change_module");
  const deletePermission = usePermission("delete_module");
  const viewPermission = usePermission("view_module");

  const [selectedCheckBox, setSelectedCheckBox] = React.useState<number[]>([]);
  const dataList = useAppSelector((state) => state.moduleReducer.data);
  const wholeData = useAppSelector((state) => state.moduleReducer.wholeData);
  const searchParams = new URLSearchParams(document.location.search);

  const [pageNum, setPageNum] = useState(Number(searchParams.get("page") || 1));
  const [perPageNum, setPerPageNum] = useState(Number(searchParams.get("perpage")) || 10);
  const [selectedMemberRows, setSelectedMemberRows] = React.useState<any>([]);
  const [defaultMemberRows, setDefaultMemberRows] = React.useState<any>([]);

  const { assessment_id } = useParams();
  const [pageSearch, setPageSearch] = useState(searchParams.get("search") || "");
  const [rows, setRows] = useState([]);
  const [list, setList] = useState([]);
  const [searchTable, setSearchTable] = useState("");
  //   const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  //   const [defaultFormValues, setDefaultFormValues] = useState({});
  //   const [modalHeading, setModalHeading] = useState("");
  //   const [modalButtonLable, setModalButtonLable] = useState("");
  const alertMessage = useAppSelector(alertMessageSelector).alertMessageReducer;

  const assessmentData: any = useSelector(
    (state: RootState) => state.policyAssessmentReducer.assessmentData
  );

  useEffect(() => {
    setDefaultMemberRows(assessmentData?.members);
  }, [assessmentData]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }

    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      dispatch(getAssessment(assessment_id));
    }
  }, [alertMessage]);

  useEffect(() => {
    if (assessment_id) {
      dispatch(getPolicyAssessment({ id: assessment_id }));
    }
  }, [assessment_id]);

  return (
    <div className={`${Style.categoryStart}`}>
      <div style={{ paddingBottom: "0.6em" }}>
        <Header
          page={assessmentData?.name}
          mainHead={t("Policy Management / Assessments List/ Assessment Detail")}
          backLink="/policy-assessment"
        />
      </div>

      <div className={Style.assessmentHeader}>
        <div className={Style.assessmentName}>{t("Assessment Detail")}</div>
      </div>

      <div className={Style.bg} style={{ marginTop: "2em", paddingBottom: "10px" }}>
        <div className={Style.descriptionBox}>
          <div>
            <div className={Style.descName}>{t("Assessment Name")}</div>
            <div className={Style.descActualVal}>{assessmentData?.name}</div>
          </div>
          <div>
            <div className={Style.descName}>{t("Total Time")}:</div>
            <div className={Style.descActualVal}>
              {assessmentTimeOptionsLabel[assessmentData?.time_option]}{" "}
              {assessmentData?.time_limit || ""}
            </div>
          </div>
          <div>
            <div className={Style.descName}>{t("Category")}:</div>
            <div className={Style.descActualVal}>{assessmentData?.category?.name || "N/A"}</div>
          </div>
        </div>
        <div className={Style.descriptionBox}>
          <div>
            <div className={Style.descName}>{t("Description")}</div>
            <div className={Style.descActualVal}>{assessmentData?.description}</div>
          </div>
        </div>
      </div>

      <div
        className={`${Style.header} mb-3 mt-5`}
        style={{
          color:
            useColor("interface_foreground_text_color") ||
            defaultThemeColor.interface_foreground_text_color,
        }}
      >
        {t("Questions")}
      </div>
      <div className={Style.assessmentHeader} style={{ padding: "32px" }}>
        {assessmentData?.questions?.map((i: any) => (
          <div className={Style.question} style={{ direction: lang === "ar" ? "ltr" : "unset" }}>
            Q . {i?.question_text}
          </div>
        ))}
      </div>

      {assessment_id && (
        <AssessmentMembers
          assessment={assessmentData}
          id={assessment_id}
          setSelectedRows={setSelectedMemberRows}
          selectedRows={selectedMemberRows}
          setDefaultRows={setDefaultMemberRows}
          defaultRows={defaultMemberRows}
        />
      )}
    </div>
  );
}

export default PolicyAssessmentDetail;
