function SearchSVG() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ color: "rgb(87, 88, 91)", zIndex: 1 }}
    >
      <circle cx="9.16122" cy="9.09091" r="8.39091" stroke="#2B3674" strokeWidth="1.4" />
      <line
        x1="19.0804"
        y1="20"
        x2="14.6158"
        y2="15.5354"
        stroke="#2B3674"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SearchSVG;
