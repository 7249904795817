// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QuestionsList_statusSuccess__QB97g {
  border-radius: 25px;
  background: #00c366;
  display: inline-flex;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.QuestionsList_statusDraft__LjKdZ {
  border-radius: 25px;
  background: yellow;
  display: inline-flex;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: black;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  min-width: 6em;
}`, "",{"version":3,"sources":["webpack://./src/pages/questionsLibrary/QuestionsList.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,mBAAA;EACA,oBAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,WAAA;EAEA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAAF;;AAGA;EACE,mBAAA;EACA,kBAAA;EACA,oBAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,YAAA;EAEA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AADF","sourcesContent":[".statusSuccess {\n  border-radius: 25px;\n  background: #00c366;\n  display: inline-flex;\n  padding: 2px 10px;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n  color: #fff;\n  // font-family: Roboto;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 22px;\n}\n\n.statusDraft {\n  border-radius: 25px;\n  background: yellow;\n  display: inline-flex;\n  padding: 2px 10px;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n  color: black;\n  // font-family: Roboto;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 22px;\n  min-width: 6em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statusSuccess": `QuestionsList_statusSuccess__QB97g`,
	"statusDraft": `QuestionsList_statusDraft__LjKdZ`
};
export default ___CSS_LOADER_EXPORT___;
