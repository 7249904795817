import axiosPrivate from "../lib/axiosPrivate";
import {
  setErr,
  setErrors,
  setSuccess,
} from "../store/reducers/alertMessageReducer";
import { setLoading } from "../store/reducers/loaderReducer";
import { setProgress } from "../store/reducers/progressReducer";
import {
  setData,
  setDataMember,
  setDataModule,
  setTrainingData,
  setWholeData,
  setWholeDataMember,
  setWholeDataModule,
} from "../store/reducers/trainingsReducer";
import { AppThunk } from "../store/store";

export interface GetData {
  page: number;
  page_size: number;
  search: string;
  status?: string;
}

export const getListData =
  (payload: GetData): AppThunk =>
  async (dispatch) => {
    dispatch(setData({}));

    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .get(
          `assessments/trainings/?page=${payload.page}&page_size=${payload.page_size}&search=${payload.search}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setData(data));
          dispatch(setLoading(false));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getWholeListData =
  (payload: GetData): AppThunk =>
  async (dispatch) => {
    dispatch(setWholeData({}));

    // dispatch(setLoading(true));
    try {
      await axiosPrivate
        .get(
          `assessments/trainings/?page=${payload.page}&page_size=${payload.page_size}&search=${payload.search}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setWholeData(data));
          // dispatch(setLoading(false));
        })
        .catch((error) => {
          // dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      // dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getBrowseTrainingListData =
  (payload: GetData): AppThunk =>
  async (dispatch) => {
    dispatch(setData({}));

    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .get(
          `assessments/trainings/browse/?page=${payload.page}&page_size=${payload.page_size}&search=${payload.search}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setData(data));
          dispatch(setLoading(false));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };
export const getMyTrainingListData =
  (payload: GetData): AppThunk =>
  async (dispatch) => {
    dispatch(setData({}));

    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .get(
          `assessments/trainings/my/?page=${payload.page}&page_size=${payload.page_size}&search=${payload.search}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setData(data));
          dispatch(setLoading(false));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };
export const getModuleListData =
  (payload: GetData): AppThunk =>
  async (dispatch) => {
    dispatch(setDataModule({}));

    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .get(
          `assessments/modules/?page=${payload.page}&page_size=${payload.page_size}&search=${payload.search}` +
            `&status=${payload.status}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setDataModule(data));
          dispatch(setLoading(false));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getWholeModuleListData =
  (payload: GetData): AppThunk =>
  async (dispatch) => {
    dispatch(setWholeDataModule({}));

    // dispatch(setLoading(true));
    try {
      await axiosPrivate
        .get(
          `assessments/modules/?page=${payload.page}&page_size=${payload.page_size}&search=${payload.search}` +
            `&status=${payload.status}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setWholeDataModule(data));
          // dispatch(setLoading(false));
        })
        .catch((error) => {
          // dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      // dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getMembersListData =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setDataMember({}));

    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .get(
          `accounts/users/?page=${payload.page}&page_size=${payload.page_size}` +
            `${payload?.search ? `&search=${payload?.search}` : ""}` +
            `${
              payload?.is_superuser
                ? `&is_superuser=${payload?.is_superuser}`
                : ""
            }` +
            `${
              payload?.department__id
                ? `&department__id=${payload?.department__id}`
                : ""
            }` +
            `${
              payload?.primary_role__in
                ? `&primary_role__in=${payload?.primary_role__in}`
                : ""
            }`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setDataMember(data));
          dispatch(setLoading(false));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getWholeMembersListData =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setWholeDataMember({}));

    // dispatch(setLoading(true));
    try {
      await axiosPrivate
        .get(
          `accounts/users/?page=${payload.page}&page_size=${
            payload.page_size
          }&search=${payload?.search || ""}` +
            `${
              payload?.is_superuser == undefined || payload?.is_superuser == ""
                ? ""
                : `&is_superuser=${payload?.is_superuser}`
            }`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setWholeDataMember(data));
          // dispatch(setLoading(false));
        })
        .catch((error) => {
          // dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      // dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getWholeMembersListData2 =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setWholeDataMember({}));

    // dispatch(setLoading(true));
    try {
      await axiosPrivate
        .get(
          `accounts/users/?page=${payload.page}&page_size=${
            payload.page_size
          }&search=${payload?.search || ""}` +
            `${
              payload?.is_superuser
                ? `&is_superuser=${payload?.is_superuser}`
                : ""
            }`
        )
        .then(async (response) => {
          await axiosPrivate
            .get(`accounts/users/?page=${1}&page_size=${response?.data?.count}`)
            .then((response) => {
              const data = response.data;
              dispatch(setWholeDataMember(data));
            });
        })
        .catch((error) => {
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const addNewTraining =
  (formData: FormData): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .post(`assessments/trainings/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type to form data
          },
          onUploadProgress: (progressEvent: any) => {
            dispatch(
              setProgress({
                total: progressEvent?.total,
                loaded: progressEvent?.loaded,
              })
            );
          },
        })
        .then((response) => {
          const data = response.data;

          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Training added successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
    dispatch(
      setProgress({
        total: 0,
        loaded: 0,
      })
    );
  };

export const updateVideoRecord =
  (payload: any): AppThunk =>
  async (dispatch) => {
    // dispatch(setLoading(true));

    if (Number(payload?.record) >= 0) {
      let formData = new FormData();
      formData.append("record", payload.record.toString());
      try {
        await axiosPrivate
          .post(
            `assessments/trainings/update_video_record/${payload?.training_id}/module/${payload?.module_id}/`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data", // Set the content type to form data
              },
            }
          )
          .then((response) => {
            const data = response.data;

            if (data?.detail) {
              // dispatch(setSuccess(data?.detail));
            } else {
              // dispatch(setSuccess("Video added successfully!"));
            }
          })
          .catch((error) => {
            // dispatch(setLoading(false));
            if (error?.response?.data?.detail) {
              dispatch(setErr(error?.response?.data?.detail));
            } else {
              dispatch(setErrors(error?.response?.data));
            }
          });
      } catch (error: any) {
        // dispatch(setLoading(false));
        dispatch(setErr("Something went wrong, Please try again"));
      }
    }
  };

export const updateTraining =
  (formData: FormData, id: Number): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .put(`assessments/trainings/${id}/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type to form data
          },
          onUploadProgress: (progressEvent: any) => {
            dispatch(
              setProgress({
                total: progressEvent?.total,
                loaded: progressEvent?.loaded,
              })
            );
          },
        })
        .then((response) => {
          const data = response.data;

          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Training updated successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
    dispatch(
      setProgress({
        total: 0,
        loaded: 0,
      })
    );
  };

export const updateTrainingPatch =
  (formData: FormData, id: Number): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .patch(`assessments/trainings/${id}/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type to form data
          },
        })
        .then((response) => {
          const data = response.data;
          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Training updated successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const deleteTraining =
  (id: Number): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .delete(`assessments/trainings/${id}/`)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Training deleted successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const bulkDeleteTrainings =
  (payload: any[]): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await axiosPrivate
        .delete(`assessments/trainings/bulk_delete/`, {
          data: JSON.stringify({ training_ids: payload }),
        })
        .then((response) => {
          const data = response.data;

          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Trainings deleted successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getTraining =
  (id: number): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setTrainingData({}));
    try {
      await axiosPrivate
        .get("assessments/trainings/" + id + "/")
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (response.status === 200) {
            dispatch(setTrainingData(data));
          } else {
            dispatch(setErr("Something went wrong, Please try again"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const addSelfEnroleInTraining =
  (payload: { training_id: number }): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    let formData = new FormData();
    formData.append("training_id", payload.training_id.toString());
    try {
      let formData = new FormData();
      if (payload?.training_id) {
        formData.append("training_id", JSON.stringify(payload?.training_id));
      }
      await axiosPrivate
        .post(`assessments/trainings/self_enrolment_to_training/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=myboundary",
            enctype: "multipart/form-data; boundary=myboundary",
          },
        })
        .then((response) => {
          const data = response.data;

          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Training assigned successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const submitTrainingScore =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .post(`assessments/training_assessment_detail/`, payload)
        .then((response) => {
          const data = response.data;

          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(setSuccess("Assessment score saved successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const updateModuleStatus =
  (payload: {
    training_id: any;
    module_id: any;
    module_status: any;
  }): AppThunk =>
  async (dispatch) => {
    // dispatch(setLoading(true));
    try {
      await axiosPrivate
        .post(`assessments/training_detail/update_module_status/`, payload)
        .then((response) => {
          // dispatch(getTraining(Number(payload.training_id)));
          const data = response.data;

          if (data?.detail) {
          } else {
            // dispatch(setSuccess("Training added successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            // dispatch(setErr(error?.response?.data?.detail));
          } else {
            // dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const updateModuleStatusVideoPDF =
  (payload: {
    training_id: any;
    module_id: any;
    module_status: any;
  }): AppThunk =>
  async (dispatch) => {
    // dispatch(setLoading(true));
    try {
      await axiosPrivate
        .post(`assessments/training_detail/update_module_status/`, payload)
        .then((response) => {
          dispatch(getTraining(Number(payload.training_id)));
          const data = response.data;

          if (data?.detail) {
          } else {
            // dispatch(setSuccess("Training added successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            // dispatch(setErr(error?.response?.data?.detail));
          } else {
            // dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const updateCertificateView =
  (payload: { training_id: number }): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .post(`assessments/training_detail/update_certificate_view/`, payload)
        .then((response) => {
          // dispatch(getTraining(Number(payload.training_id)));
          const data = response.data;

          if (data?.detail) {
          } else {
            // dispatch(setSuccess("Training added successfully!"));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            // dispatch(setErr(error?.response?.data?.detail));
          } else {
            // dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };
export const getWholeMembersListData3 = (): AppThunk => async (dispatch) => {
  dispatch(setWholeDataMember({}));

  // dispatch(setLoading(true));
  try {
    await axiosPrivate
      .get(`accounts/users/?page=1&page_size=10`)
      .then(async (response) => {
        await axiosPrivate
          .get(`accounts/users/?page=${1}&page_size=${response?.data?.count}`)
          .then((response) => {
            const data = response.data;
            dispatch(setWholeDataMember(data));
          });
      })
      .catch((error) => {
        dispatch(setErr(error.response.data.detail));
      });
  } catch (error: any) {
    dispatch(setErr("Something went wrong, Please try again"));
  }
};
export const getWholeModulesListData3 = (): AppThunk => async (dispatch) => {
  dispatch(setWholeDataModule({}));

  // dispatch(setLoading(true));
  try {
    await axiosPrivate
      .get(`assessments/modules/?page=${1}&page_size=${10}&status=Approved`)
      .then(async (response) => {
        await axiosPrivate
          .get(
            `assessments/modules/?page=${1}&page_size=${
              response?.data?.count
            }&status=Approved`
          )
          .then((response) => {
            const data = response.data;
            dispatch(setWholeDataModule(data));
          });
      })
      .catch((error) => {
        dispatch(setErr(error.response.data.detail));
      });
  } catch (error: any) {
    dispatch(setErr("Something went wrong, Please try again"));
  }
};
