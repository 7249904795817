/* eslint-disable eqeqeq */
import { IconButton } from "@mui/material";
import Style from "./IconButtonAdvance.module.scss";

interface IconButtonProps {
  size: string;
  outlined: boolean;
  textColor: string;
  outlineColor: string;
  text: string;
  onClick: any;
  className: string;
  bg: string;
  disabled: boolean;
  icon: any;
}

function IconButtonAdvance({
  size,
  outlined,
  textColor,
  outlineColor,
  text,
  onClick,
  className,
  bg,
  disabled,
  icon,
}: IconButtonProps) {
  return (
    <button
      className={`btn ${Style.cancelBtn}`}
      style={{
        height: size == "large" ? "3.1em" : "3.1em",
        minWidth: size == "large" ? "167px" : "150px",
        border: outlined ? `1px solid ${outlineColor ? outlineColor : "#0089cf"}` : "",
        backgroundColor: bg ? bg : "white",
      }}
      type={"button"}
      onClick={onClick}
      disabled={disabled}
    >
      <IconButton sx={{ m: 0, p: 0, mr: 0, color: "white" }} onClick={() => {}}>
        {icon}
      </IconButton>
      <span
        className={`p-2 ${Style.hThree} ${Style.buttonFont}`}
        style={{ color: textColor ? textColor : "#2B3674" }}
      >
        {text}
      </span>
    </button>
  );
}

export default IconButtonAdvance;
