import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import DataTable from "../../components/table/DataTable";
import TableFilters from "../../components/table/TableFilters";
import useCompanyLanguage from "../../hooks/language";
import ViewSVGAction from "../../icons/ViewSVGAction";
import { getCompanyPolicy } from "../../services/policyService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { RootState, useAppDispatch, useAppSelector } from "../../store/store";
import PolicyAcceptReject from "../PolicyManagement/PolicyAcceptRejectDialogue";
import Style from "./CompanyPolicyListing.module.scss";

function CompanyPolicyListing() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const [open2, setOpen2] = useState(false);
  const dataList = useAppSelector((state) => state.policyReducer.data);
  const [selectedData, setSelectedData] = useState<any>();
  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  const searchParams = new URLSearchParams(document.location.search);
  const [pageNum, setPageNum] = useState(Number(searchParams.get("page") || 1));
  const [perPageNum, setPerPageNum] = useState(
    Number(searchParams.get("perpage")) || 10
  );
  const [pageSearch, setPageSearch] = useState(
    searchParams.get("search") || ""
  );
  const [list, setList] = useState([]);

  const alertMessage = useAppSelector(alertMessageSelector).alertMessageReducer;
  const handleClose2 = (open: any) => {
    setOpen2(false);
    setSelectedData("");
  };
  useEffect(() => {
    AllData();
  }, [
    searchParams.get("page"),
    searchParams.get("perpage"),
    searchParams.get("search"),
  ]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }

    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      AllData();
    }
  }, [alertMessage]);

  const AllData = () => {
    let query: any = {};
    query.page = Number(searchParams.get("page") || 1);
    query.page_size = Number(searchParams.get("perpage") || 10);
    query.search = String(searchParams.get("search") || "");
    dispatch(getCompanyPolicy(query));
  };

  const urlWithQueryString = (
    pageVal = Number(pageNum),
    perPageVal = Number(perPageNum),
    searchVal = String(pageSearch)
  ) => {
    let object: any = {};
    if (pageVal) {
      object.page = pageVal;
    }
    if (perPageVal) {
      object.perpage = perPageVal;
    }
    if (searchVal) {
      object.search = searchVal;
    }

    const objString = "?" + new URLSearchParams(object).toString();

    navigate(`/company-policy-list${objString}`);
  };
  const handleDetail = (row: any) => {
    setSelectedData(row?.policy);
    setOpen2(true);
  };
  const modifyRows = () => {
    let f_rows: any = [];
    dataList?.results?.forEach(
      (
        r: {
          id: string;
          policy: any;

          status: string;
        },
        i: number
      ) => {
        f_rows = [
          ...f_rows,
          {
            id: r.id,
            name: r?.policy?.name,

            status: (
              <span
                className={
                  r?.status == "Accepted"
                    ? Style.statusSuccess
                    : r?.status == "Not Responded"
                    ? Style.statusDraft
                    : Style.statusDecline
                }
              >
                {r?.status}
              </span>
            ),
            actions: (
              <>
                <div style={{ textAlign: "center", paddingLeft: "1em" }}>
                  <button
                    className="btn-link"
                    type="button"
                    onClick={() => {
                      handleDetail(r);
                    }}
                  >
                    <ViewSVGAction />
                  </button>
                </div>
              </>
            ),
          },
        ];
      }
    );
    setList(f_rows);
  };

  useEffect(() => {
    modifyRows();
  }, [dataList]);

  const handleChangePage = (event: React.ChangeEvent<unknown>, val: number) => {
    setPageNum(Number(val));
    urlWithQueryString(Number(val), Number(perPageNum), pageSearch);
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPageNum(1);
    setPerPageNum(Number(rowsPerPage));
    urlWithQueryString(1, Number(rowsPerPage), pageSearch);
  };
  const handleChangeSearch = (val: string) => {
    setPageNum(1);
    setPageSearch(String(val));
    setPerPageNum(Number(10));
    urlWithQueryString(1, perPageNum, String(val));
  };

  return (
    <div className={`${Style.categoryStart}`}>
      <div style={{ paddingBottom: "0.6em" }}>
        <Header
          page={t("Assigned Policies")}
          mainHead={t("Company Policies / Assigned Policies")}
        />
        <TableFilters
          dataList={dataList}
          itemPerPage={Number(searchParams.get("perpage") || 10)}
          setItemPerPage={handleChangeRowsPerPage}
          setSearchTable={handleChangeSearch}
          handle="category"
          isCheckboxes={false}
        />
        <DataTable
          columnData={[
            {
              id: "name",
              name: t("Name"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "checkbox",
            },

            {
              id: "status",
              name: t("Status"),
              enableSort: true,
              align: "center",
              disablePadding: "none",
            },
            {
              id: "action",
              name: t("Actions"),
              enableSort: false,
              align: "center",
              disablePadding: "none",
            },
          ]}
          rows={list}
          isCheckboxes={false}
          selectedRowsPerPage={String(searchParams.get("perpage") || 10)}
          totalRecordCount={dataList.count}
          handleChangePageParent={handleChangePage}
          pagenum={Number(searchParams.get("page") || 1)}
          handleChangeRowsPerPageParent={handleChangeRowsPerPage}
        />
      </div>
      <PolicyAcceptReject
        setParentOpen={setOpen2}
        open={open2}
        onClose={handleClose2}
        isLoading={loading}
        policy={selectedData && selectedData}
      />
    </div>
  );
}

export default CompanyPolicyListing;
