function MyTrainingsSVG(props: { color: string }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5671_6045)">
        <path
          d="M11.9999 9C14.2099 9 15.9999 7.21 15.9999 5C15.9999 2.79 14.2099 1 11.9999 1C9.78994 1 7.99994 2.79 7.99994 5C7.99994 7.21 9.78994 9 11.9999 9ZM11.9999 3C13.0999 3 13.9999 3.9 13.9999 5C13.9999 6.1 13.0999 7 11.9999 7C10.8999 7 9.99994 6.1 9.99994 5C9.99994 3.9 10.8999 3 11.9999 3ZM11.9999 11.55C9.63994 9.35 6.47994 8 2.99994 8V19C6.47994 19 9.63994 20.35 11.9999 22.55C14.3599 20.36 17.5199 19 20.9999 19V8C17.5199 8 14.3599 9.35 11.9999 11.55ZM18.9999 17.13C16.4699 17.47 14.0699 18.43 11.9999 19.95C9.93994 18.43 7.52994 17.46 4.99994 17.12V10.17C7.09994 10.55 9.04994 11.52 10.6399 13L11.9999 14.28L13.3599 13.01C14.9499 11.53 16.8999 10.56 18.9999 10.18V17.13Z"
          fill={props.color ?? "#656565"}
          stroke="white"
          strokeWidth="0.6"
        />
      </g>
      <defs>
        <clipPath id="clip0_5671_6045">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default MyTrainingsSVG;
