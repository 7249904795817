function PinSVG(props: { color: string }) {
  return (
    <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.97818 22C5.71326 22 4.52857 21.4337 3.67355 20.5306C2.01537 18.7795 1.55362 15.7233 3.87801 13.2692L13.4143 3.19754C14.3808 2.17711 15.6105 1.7822 16.7874 2.11588C17.9447 2.44242 18.8917 3.44346 19.2018 4.66492C19.5168 5.90985 19.1441 7.20885 18.1785 8.22929L9.058 17.8622C8.53756 18.4122 7.94863 18.7377 7.35775 18.803C6.77176 18.8683 6.21316 18.6703 5.82185 18.2571C5.11358 17.506 5.01184 16.0968 6.19164 14.8519L12.5974 8.08642C12.8606 7.80887 13.2871 7.80887 13.5503 8.08642C13.8134 8.36398 13.8134 8.81501 13.5503 9.09257L7.14351 15.859C6.5898 16.4427 6.53893 17.0009 6.7747 17.2509C6.87839 17.3591 7.03492 17.4091 7.2159 17.3877C7.49275 17.3581 7.80874 17.1672 8.10516 16.856L17.2257 7.22416C17.8518 6.56292 18.0914 5.78535 17.9007 5.03534C17.8053 4.66857 17.6207 4.33371 17.3649 4.06322C17.109 3.79273 16.7904 3.59577 16.4401 3.49142C15.7299 3.2904 14.9922 3.54449 14.3661 4.20573L4.82988 14.2774C3.05332 16.154 3.47887 18.3142 4.62542 19.5255C5.77294 20.7367 7.81657 21.1877 9.5941 19.3091L19.1304 9.23747C19.1926 9.17151 19.2667 9.11912 19.3485 9.08335C19.4303 9.04759 19.5181 9.02917 19.6068 9.02917C19.6955 9.02917 19.7833 9.04759 19.8651 9.08335C19.9469 9.11912 20.021 9.17151 20.0832 9.23747C20.2098 9.37182 20.2808 9.55267 20.2808 9.74105C20.2808 9.92944 20.2098 10.1103 20.0832 10.2446L10.5469 20.3163C9.4229 21.502 8.16385 22 6.97818 22Z"
        fill={props.color}
      />
    </svg>
  );
}

export default PinSVG;
