// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PdfViewer_controls__icons2__v5ZoA {
  color: white;
  transform: scale(0.9);
  padding: 0px;
  padding-inline: 8px;
}

.PdfViewer_controls__div2__NYFXG {
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
  background: rgb(0, 0, 0);
}

.PdfViewer_header__m2\\+x\\+ {
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.64px;
}

.PdfViewer_subTopic__TF-gD {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.4px;
}

.PdfViewer_module__umqSN {
  color: #ffffff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.PdfViewer_topicName__Sp4c4 {
  color: #ffffff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.PdfViewer_adjustScroll__DF-XZ {
  overflow: auto;
  height: 650px;
}`, "",{"version":3,"sources":["webpack://./src/components/PdfViewer.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,qBAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAEA;EACE,SAAA;EACA,OAAA;EACA,QAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,UAAA;EACA,wBAAA;AACF;;AAEA;EACE,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;AACF;;AAEA;EACE,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,sBAAA;AACF;;AACA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAEF;;AACA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAEF;;AAAC;EACC,cAAA;EACA,aAAA;AAGF","sourcesContent":[".controls__icons2 {\n  color: white;\n  transform: scale(0.9);\n  padding: 0px;\n  padding-inline: 8px;\n}\n\n.controls__div2 {\n  bottom: 0;\n  left: 0;\n  right: 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  z-index: 2;\n  background: rgba(0, 0, 0, 1);\n}\n\n.header {\n  color: #fff;\n  font-size: 32px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 42px;\n  letter-spacing: -0.64px;\n}\n\n.subTopic {\n  color: #fff;\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  letter-spacing: -0.4px;\n}\n.module {\n  color: #ffffff;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n}\n\n.topicName {\n  color: #ffffff;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}\n .adjustScroll {\n  overflow: auto;\n  height: 650px;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controls__icons2": `PdfViewer_controls__icons2__v5ZoA`,
	"controls__div2": `PdfViewer_controls__div2__NYFXG`,
	"header": `PdfViewer_header__m2+x+`,
	"subTopic": `PdfViewer_subTopic__TF-gD`,
	"module": `PdfViewer_module__umqSN`,
	"topicName": `PdfViewer_topicName__Sp4c4`,
	"adjustScroll": `PdfViewer_adjustScroll__DF-XZ`
};
export default ___CSS_LOADER_EXPORT___;
