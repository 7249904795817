import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import ChooseFile from "../../components/ChooseFile";
import Header from "../../components/Header";
import SubmitButton from "../../components/SubmitButton";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import CorrectAnswer from "../../images/correctans.svg";
import WrongAnswer from "../../images/wrong-ans.svg";
import { addLicense, getLicenseData } from "../../services/licenseService";
import { getCompanyForm } from "../../services/profileManagementService";
import { alertMessageSelector, setErrors } from "../../store/reducers/alertMessageReducer";
import { companyFormSelector } from "../../store/reducers/companyFormReducer";
import { setData } from "../../store/reducers/licenseReducer";
import { RootState, useAppDispatch, useAppSelector } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import Style from "../companyProfileManagement/profileManagement.module.scss";

const schema = yup
  .object({
    image: yup
      .string()
      .max(150, "Maximum 150 characters are allowed!")
      .required("License File is required"),
  })
  .required();

function CompanyLicense() {
  const {
    setValue,
    setError,
    reset,
    register,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const isLoading = useSelector((state: RootState) => state.loaderReducer.isLoading);
  const [image, setImage] = useState<any>();
  const [lic, setLic] = useState<any>();
  const [clearFile, setClearFile] = useState(false);
  const alertMessage = useAppSelector(alertMessageSelector).alertMessageReducer;
  const states = useSelector(companyFormSelector).profileManagementReducer;
  const data: any = useSelector((state: RootState) => state.licenseReducer.data);

  useEffect(() => {
    let defaultValues = {
      image: data ? data?.image : "",
    };
    reset({ ...defaultValues });
  }, [data]);

  const onSubmit = (payload: any) => {
    let f_payload = {
      license_key: lic,
    };
    dispatch(addLicense(f_payload));
  };

  useEffect(() => {
    if (alertMessage?.errors) {
      if (alertMessage?.errors?.license_key?.length > 0) {
        toast.error(alertMessage?.errors?.license_key?.toString(), {
          toastId: alertMessage?.errors?.license_key?.toString(),
        });
        setError("image", {
          type: "server",
          message: alertMessage?.errors?.license_key?.toString(),
        });
      }
      if (alertMessage?.errors) {
        dispatch(setErrors(null));
      }
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(getLicenseData({}));
      setValue("image", "");
      setImage(null);
      setClearFile(true);
      setTimeout(() => {
        setClearFile(false);
      }, 500);
    }
  }, [alertMessage]);

  useEffect(() => {
    if (states?.errors) {
      if (states?.errors?.image?.length > 0) {
        setError("image", {
          type: "server",
          message: states?.errors?.image?.toString(),
        });
      }
      if (states?.errors) {
        dispatch(setData(null));
      }
    }
    if (states?.error) {
      toast.error(String(states?.error), {
        toastId: String(states?.error),
      });
    }
  }, [states]);

  useEffect(() => {
    getCompanyFormData();
  }, []);

  const getCompanyFormData = () => {
    dispatch(getCompanyForm());
  };

  useEffect(() => {
    if (image && image?.image) {
      var reader = new FileReader();
      reader.onload = function (e) {
        var content: any = reader.result;
        setLic(content);
      };
      reader.readAsText(image.image);
    }
  }, [image]);

  return (
    <div
      className={`${Style.background}`}
      style={{
        background:
          useColor("interface_background_color") || defaultThemeColor.interface_background_color,
        color:
          useColor("interface_background_text_color") ||
          defaultThemeColor.interface_background_text_color,
      }}
    >
      <div style={{ paddingBottom: "1.6em" }}>
        <Header page={t("Company Profile Management")} mainHead={t("Company Settings / License")} />
      </div>
      <div>
        <div
          style={{
            background:
              useColor("interface_foreground_color") ||
              defaultThemeColor.interface_foreground_color,
            color:
              useColor("interface_foreground_text_color") ||
              defaultThemeColor.interface_foreground_text_color,
            borderRadius: 10,
          }}
          className={Style.bottomSpace}
        >
          <div className={`mx-5 px-3 ${Style.topSpace} `} style={{ paddingRight: "3rem" }}>
            <div
              className={`${Style.textHeader}`}
              style={{
                color:
                  useColor("interface_foreground_text_color") ||
                  defaultThemeColor.interface_foreground_text_color,
                textAlign: lang === "ar" ? "right" : "left",
              }}
            >
              {t("License")}
            </div>
            <form className="d-flex flex-column" onSubmit={handleSubmit(onSubmit)}>
              <div className="col-lg-6 p-0 mt-4 mb-3">
                <span className={Style.labelHeader}>{t("Upload License File")}</span>

                <ChooseFile
                  size="small"
                  fileTypes=".lic"
                  loading={isLoading}
                  errors={errors}
                  setValue={setValue}
                  imageName={"image"}
                  clearErrors={clearErrors}
                  acceptedImageTypesArray={[".lic"]}
                  setImageParent={setImage}
                  maxFileSizeinBytes={5242880} // 5mb
                  clearFile={clearFile}
                ></ChooseFile>
              </div>
              {image && (
                <div className="d-flex flex-wrap gap-3 w-100 px-3 mt-5 align-items-center justify-content-end px-5">
                  <SubmitButton label={t("Upload File")} marginTop={false} />
                </div>
              )}
              <div
                className={`${Style.textHeader} mt-4`}
                style={{
                  color:
                    useColor("interface_foreground_text_color") ||
                    defaultThemeColor.interface_foreground_text_color,
                  textAlign: lang === "ar" ? "right" : "left",
                }}
              >
                {t("License Details")}
              </div>
              <div
                style={{
                  minHeight: "13em",
                  background:
                    useColor("interface_foreground_color") ||
                    defaultThemeColor.interface_foreground_color,
                  color:
                    useColor("interface_foreground_text_color") ||
                    defaultThemeColor.interface_foreground_text_color,
                  borderRadius: "10px",
                }}
              >
                <div
                  className={`${Style.topSpace} col-lg-12`}
                  style={{
                    borderBottom: "1px solid rgba(0,0,0,0.1)",
                    paddingBottom: "1em",
                    display: lang === "ar" ? "flex" : "unset",
                  }}
                >
                  <div
                    className={`${Style.label} col-lg-6 px-5`}
                    style={{
                      color:
                        useColor("interface_foreground_text_color") ||
                        defaultThemeColor.interface_foreground_text_color,
                    }}
                  >
                    {t("Name")}
                  </div>

                  <div
                    className={`${Style.fieldValue} col-lg-6 px-5`}
                    style={{ wordBreak: "break-word" }}
                  >
                    {data && data?.name}
                  </div>
                </div>
                <div
                  className={`${Style.topSpace} col-lg-12`}
                  style={{
                    borderBottom: "1px solid rgba(0,0,0,0.1)",
                    paddingBottom: "1em",
                    display: lang === "ar" ? "flex" : "unset",
                  }}
                >
                  <div
                    className={`${Style.label} col-lg-6 px-5`}
                    style={{
                      color:
                        useColor("interface_foreground_text_color") ||
                        defaultThemeColor.interface_foreground_text_color,
                    }}
                  >
                    {t("Email")}
                  </div>

                  <div className={`${Style.fieldValue} col-lg-6 px-5`}>{data && data?.email}</div>
                </div>
                <div
                  className={`${Style.topSpace} col-lg-12`}
                  style={{
                    borderBottom: "1px solid rgba(0,0,0,0.1)",
                    paddingBottom: "1em",
                    display: lang === "ar" ? "flex" : "unset",
                  }}
                >
                  <div
                    className={`${Style.label} col-lg-6 px-5`}
                    style={{
                      color:
                        useColor("interface_foreground_text_color") ||
                        defaultThemeColor.interface_foreground_text_color,
                    }}
                  >
                    {t("Type of License")}
                  </div>

                  {data && (
                    <div className={`${Style.fieldValue} col-lg-6 px-5`}>
                      <div style={{ display: "flex", marginBottom: "10px" }}>
                        <span
                          className="d-flex align-content-center"
                          style={{
                            paddingRight: "1rem",
                            paddingLeft: lang === "ar" ? "1rem" : "0rem",
                          }}
                        >
                          <img
                            src={
                              data && data?.policy_management_selected ? CorrectAnswer : WrongAnswer
                            }
                            alt=""
                          />
                        </span>
                        <span>
                          <div>{t("Policy Management")}</div>
                          <div
                            style={{
                              fontSize: "13px",
                              fontWeight: 500,
                              color: "#2F2F2F87",
                            }}
                          >
                            {data?.policy_management_expiry_date &&
                              moment(new Date(data?.policy_management_expiry_date)).format(
                                "MMM Do, YYYY"
                              )}{" "}
                            <strong style={{ color: "red" }}>
                              {" "}
                              {data?.policy_management_expiry_date &&
                              moment(data?.policy_management_expiry_date) < moment()
                                ? "( Expired )"
                                : ""}
                            </strong>
                          </div>
                        </span>
                      </div>
                      <div style={{ display: "flex", marginBottom: "10px" }}>
                        <span
                          className="d-flex align-content-center"
                          style={{
                            paddingRight: "1rem",
                            paddingLeft: lang === "ar" ? "1rem" : "0rem",
                          }}
                        >
                          <img
                            src={
                              data && data?.phishing_management_selected
                                ? CorrectAnswer
                                : WrongAnswer
                            }
                            alt=""
                          />
                        </span>
                        <span>
                          <div>{t("Phishing Management")}</div>
                          <div
                            style={{
                              fontSize: "13px",
                              fontWeight: 500,
                              color: "#2F2F2F87",
                            }}
                          >
                            {data?.phishing_management_expiry_date &&
                              moment(new Date(data?.phishing_management_expiry_date)).format(
                                "MMM Do, YYYY"
                              )}
                          </div>
                        </span>
                      </div>
                      <div style={{ display: "flex", marginBottom: "10px" }}>
                        <span
                          className="d-flex align-content-center"
                          style={{
                            paddingRight: "1rem",
                            paddingLeft: lang === "ar" ? "1rem" : "0rem",
                          }}
                        >
                          <img
                            src={data && data?.gamification_selected ? CorrectAnswer : WrongAnswer}
                            alt=""
                          />
                        </span>
                        <span>
                          <div>{t("Gamification")}</div>
                          <div
                            style={{
                              fontSize: "13px",
                              fontWeight: 500,
                              color: "#2F2F2F87",
                            }}
                          >
                            {data?.gamification_expiry_date &&
                              moment(new Date(data?.gamification_expiry_date)).format(
                                "MMM Do, YYYY"
                              )}
                          </div>
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={`${Style.topSpace} col-lg-12`}
                  style={{
                    borderBottom: "1px solid rgba(0,0,0,0.1)",
                    paddingBottom: "1em",
                    display: lang === "ar" ? "flex" : "unset",
                  }}
                >
                  <div
                    className={`${Style.label} col-lg-6 px-5`}
                    style={{
                      color:
                        useColor("interface_foreground_text_color") ||
                        defaultThemeColor.interface_foreground_text_color,
                    }}
                  >
                    {t("No of Users")}
                  </div>

                  <div className={`${Style.fieldValue} col-lg-6 px-5`}>
                    {data && data?.number_of_users}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyLicense;
