import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useNavigate, useParams } from "react-router-dom";
import useCompanyLanguage from "../../../hooks/language";
import useColor from "../../../hooks/useColor";
import AssessmentsSVG from "../../../icons/AssessmentSVG";
import CheckedCheckMarkSVG from "../../../icons/CheckedCheckMark";
import PdfSVG from "../../../icons/PdfSVG";
import ScormSVG from "../../../icons/ScormSVG";
import VideoSvg from "../../../icons/VideoSvg";
import { defaultThemeColor } from "../../../utils/colors_values";
import Style from "./TrainingBar.module.scss";

function TrainingBar({
  index,
  number,
  header,
  type,
  secondaryText,
  checked,
  video,
  cursor,
  handleNavigation,
  row,
}: {
  index: any;
  number: any;
  header: any;
  type: any;
  secondaryText?: any;
  checked: any;
  video?: any;
  cursor: any;
  handleNavigation: any;
  row: any;
}) {
  const [duration, setDuration] = useState<any>();
  const navigate = useNavigate();
  const { id } = useParams();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  useEffect(() => {
    let video: any = document.getElementById("myVideo");
    if (video)
      video.addEventListener(
        "loadeddata",
        function () {
          setDuration(format(video.duration));
        },
        false
      );
  }, []);

  const format = (seconds: any) => {
    if (isNaN(seconds)) {
      return "00:00";
    }
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, "0");

    if (hh) {
      return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
    } else {
      return `${mm}:${ss}`;
    }
  };

  const primaryButtonColor =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;
  const primaryButtonBg =
    useColor("button_primary") || defaultThemeColor.button_primary;
  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;
  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;
  const interfaceForegroundTextColor =
    useColor("interface_foreground_text_color") ||
    defaultThemeColor.interface_foreground_text_color;
  const interfaceForegroundColor =
    useColor("interface_foreground_color") ||
    defaultThemeColor.interface_foreground_color;

  return (
    <div className={Style.bar}>
      {type == "Video" && (
        <video
          id="myVideo"
          width="320"
          height="176"
          controls
          style={{ display: "none" }}
        >
          <source src={video} type="video/mp4" />
        </video>
      )}
      <div
        className={` d-flex justify-content-between ${Style.listItem} ${
          index == 1 && number == 1
            ? Style.onlyBar
            : index == 1
            ? Style.firstBar
            : index == number
            ? Style.lastBar
            : index != 1 && index != number
            ? Style.insideBar
            : ""
        }`}
        style={{
          cursor: cursor,
          background: interfaceBgColor,
          color: interfaceTextColor,
        }}
      >
        <div
          className="d-flex justify-content-center"
          style={{ paddingTop: "1rem", width: "100%" }}
        >
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex w-100">
              {checked ? (
                <Tooltip
                  title={checked ? t("Finished") : t("Not finished")}
                  placement="top"
                >
                  <IconButton
                    className={Style.iconChecked}
                    style={{ padding: "0px" }}
                  >
                    <CheckedCheckMarkSVG color="" />
                  </IconButton>
                </Tooltip>
              ) : (
                <div
                  className={Style.count}
                  style={{
                    background: interfaceForegroundColor,
                    color: interfaceForegroundTextColor,
                  }}
                >
                  {Number(index)}
                </div>
              )}
              <div
                className={`${Style.rightContainer} `}
                onClick={() => handleNavigation(row)}
              >
                <div
                  className={`${Style.listImgHeading} d-flex align-items-center`}
                >
                  {type?.toLowerCase() === "assessment" && (
                    <AssessmentsSVG color={interfaceTextColor} />
                  )}
                  {type?.toLowerCase() === "video" && (
                    <VideoSvg color={interfaceTextColor} />
                  )}
                  {type?.toLowerCase() === "pdf" && (
                    <PdfSVG color={interfaceTextColor} />
                  )}
                  {type?.toLowerCase() === "scorm" && (
                    <ScormSVG color={interfaceTextColor} />
                  )}
                  <span
                    className={Style.listHeading}
                    style={{ color: interfaceTextColor }}
                  >
                    {header}
                  </span>
                  <span style={{ marginLeft: "5px" }}>
                    <small>
                      <i>{row?.is_compulsory ? t("(Compulsory)") : ""}</i>
                    </small>
                  </span>
                </div>
                <div
                  className={Style.listDesc}
                  style={{ color: interfaceTextColor }}
                >
                  {secondaryText}
                </div>
              </div>
            </div>
            <div className="d-flex float-end align-items-center justify-content-end w-25 text-end px-2">
              {type == "Video" ? duration : null}
              {type == "Assessment" &&
                (row?.module?.module_status?.toLowerCase() === "completed" ||
                  row?.module?.module_status?.toLowerCase() ===
                    "inprogress") && (
                  <button
                    className="btn"
                    style={{
                      background: primaryButtonBg,
                      color: primaryButtonColor,
                    }}
                    type="button"
                    onClick={() => {
                      navigate(
                        `/progress-report/${id}/${row?.module?.id}/${row?.module?.assessment?.id}`
                      );
                    }}
                  >
                    {t("Result")}
                  </button>
                )}

              {type == "SCORM" &&
                (row?.module?.module_status?.toLowerCase() === "completed" ||
                  row?.module?.module_status?.toLowerCase() ===
                    "inprogress") && (
                  <div className="col-12">
                    {/* {(row?.module?.scorm_result?.["cmi.completion_status"]?.toLowerCase()=="completed" || row?.module?.scorm_result?.["cmi.completion_status"]?.toLowerCase()=="incomplete") || (row?.module?.scorm_result?.["cmi.core.lesson_status"]?.toLowerCase()=="incomplete" || row?.module?.scorm_result?.["cmi.core.lesson_status"]?.toLowerCase()=="complete") && (
                      <p
                        style={{
                          textTransform: "capitalize",
                          textAlign: "left",
                        }}
                      >
                        {row?.module?.scorm_result?.["cmi.completion_status"]}
                      </p>
                    )} */}
                    {row?.module?.scorm_result?.[
                      "cmi.core.lesson_status"
                    ]?.toLowerCase() == "passed" ||
                    row?.module?.scorm_result?.[
                      "cmi.core.lesson_status"
                    ]?.toLowerCase() == "incomplete" ? (
                      <p
                        style={{
                          textTransform: "capitalize",
                          textAlign: "left",
                        }}
                      >
                        <div style={{ paddingRight: "8px", fontWeight: 700 }}>
                          Status
                        </div>
                        {row?.module?.scorm_result?.["cmi.core.lesson_status"]}
                      </p>
                    ) : row?.module?.scorm_result?.[
                        "cmi.completion_status"
                      ]?.toLowerCase() == "completed" ||
                      row?.module?.scorm_result?.[
                        "cmi.completion_status"
                      ]?.toLowerCase() == "incomplete" ? (
                      <p
                        style={{
                          textTransform: "capitalize",
                          textAlign: "left",
                        }}
                      >
                        <div style={{ paddingRight: "8px", fontWeight: 700 }}>
                          Status
                        </div>
                        {row?.module?.scorm_result?.["cmi.completion_status"]}
                      </p>
                    ) : null}
                    {/* {row?.module?.scorm_result?.["cmi.success_status"] && (
                      <p
                        style={{
                          textTransform: "capitalize",
                          textAlign: "left",
                        }}
                      >
                        <b>Success Status: </b>
                        {row?.module?.scorm_result?.["cmi.success_status"]}
                      </p>
                    )} */}
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrainingBar;
