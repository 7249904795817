import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DataTable from "../../../components/table/DataTable";
import TableFilters from "../../../components/table/TableFilters";
import useColor from "../../../hooks/useColor";
import usePermission from "../../../hooks/usePermission";
import DeleteSVG from "../../../icons/DeleteSVG";
import EditSVG from "../../../icons/EditSVG";
import ViewSVG from "../../../icons/ViewSVG";
import { bulkDeleteGroups, deleteGroup } from "../../../services/groupManagementService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../../store/reducers/alertMessageReducer";
import { setPage } from "../../../store/reducers/departmentReducer";
import { groupManagementSelector } from "../../../store/reducers/groupManagementReducer";
import { RootState, useAppDispatch } from "../../../store/store";
import { defaultThemeColor } from "../../../utils/colors_values";
import { confirmProps } from "../../../utils/confirmProps";
import Style from "./ModulesMemberLists.module.scss";

function MembersList() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();

  const addPermission = usePermission("add_customusergroup");
  const editUpdatePermission = usePermission("change_customusergroup");
  const deletePermission = usePermission("delete_customusergroup");
  const viewPermission = usePermission("view_customusergroup");
  const [itemPerPage, setItemPerPage] = useState<number>(10);

  const [searchTable, setSearchTable] = useState("");

  const [list, setList] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<any[]>([]);

  // const [page, setPage] = useState(1);
  const states = useSelector(groupManagementSelector).departmentReducer;
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;
  const [rows, setRows] = useState([]);

  const page = useSelector((state: RootState) => state.groupManagementReducer.page);

  const dataList = useSelector((state: RootState) => state.groupManagementReducer.data);
  useEffect(() => {
    dispatch(setPage(1));
  }, []);
  useEffect(() => {
    // dispatch(
    //   getListData({
    //     page_size: Number(itemPerPage),
    //     page: Number(page),
    //     search: searchTable,
    //   })
    // );
  }, [dispatch, itemPerPage, page, searchTable]);
  useEffect(() => {
    let data: any = dataList.results;
    if (Object.keys(dataList).length === 0) {
      setRows([]);
    } else setRows(data);
  }, [dataList]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      setSelectedCheckBox([]);
      // dispatch(
      //   getListData({
      //     page_size: Number(itemPerPage),
      //     page: Number(page),
      //     search: searchTable,
      //   })
      // );
    }
  }, [alertMessage]);

  const handleDeleteAction = (row: any) => {
    return;
    confirm({
      title: "Are you sure!",
      description: `Do you want to delete ${row?.name} group?`,
      ...confirmProps,
    })
      .then(() => {
        dispatch(deleteGroup(row.id));
      })
      .catch(() => {
        // toast.success("Your request canceled successfully!", {
        //   toastId: "Your request canceled successfully!",
        // });
      });
    return;
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setSelectedCheckBox([]);
    dispatch(setPage(page));
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    dispatch(setPage(1));
    setSelectedCheckBox([]);
    setItemPerPage(Number(rowsPerPage));
  };

  useEffect(() => {
    modifyRows();
  }, [rows]);
  const bulkDelete = (ids: any) => {
    // const sendSelected = selected.map((sel) => sel);
    confirm({
      title: "Are you sure!",
      description: `Do you want to delete selected groups?`,
      ...confirmProps,
    })
      .then(() => {
        dispatch(bulkDeleteGroups(ids));
        setSelectedCheckBox([]);
      })
      .catch(() => {});
  };
  const modifyRows = () => {
    let f_rows: any = [];
    rows.forEach(
      (
        r: {
          id: string;
          name: string;
          category: string;
          type: string;
          status: string;
        },
        i: number
      ) => {
        f_rows = [
          ...f_rows,
          {
            id: r.id,
            name: r.name,
            category: r.category,
            type: r.type,
            status: (
              <span className={r.status == "Approved" ? Style.statusSuccess : ""}>{r.status}</span>
            ),
            actions: (
              <div style={{ minWidth: "13em" }}>
                {/* {viewPermission && ( */}
                <button className="btn-link" type="button">
                  <ViewSVG color={""} />
                </button>

                {/* )} */}
                {/* {editUpdatePermission && ( */}
                <button className="btn-link" type="button">
                  <EditSVG />
                </button>
                {/* // )} */}
                {/* {deletePermission && ( */}
                <button
                  className="btn-link"
                  type="button"
                  onClick={() => {
                    handleDeleteAction(r);
                  }}
                >
                  <DeleteSVG color="red" />
                </button>
                {/* )} */}
              </div>
            ),
          },
        ];
      }
    );
    setList(f_rows);
  };

  const AddGroup = () => {
    const handleAddNew = () => {
      //   navigate("/assessments/add-assessment");
    };

    return (
      <div className="d-flex flex-row gap-3">
        {/* {addPermission && ( */}
        <>
          <Button
            variant="text"
            color="inherit"
            endIcon={
              <AddIcon
                style={{
                  fontSize: 16,
                  color:
                    useColor("interface_background_text_color") ||
                    defaultThemeColor.interface_background_text_color,
                }}
              />
            }
            style={{
              fontSize: 13,
              color:
                useColor("interface_background_text_color") ||
                defaultThemeColor.interface_background_text_color,
              textTransform: "capitalize",
            }}
            onClick={() => handleAddNew()}
          >
            Add New
          </Button>
        </>
        {/* )} */}
      </div>
    );
  };

  return (
    <>
      <div className={`${Style.header} mb-3 mt-4`}>Members</div>
      <TableFilters
        dataList={dataList}
        itemPerPage={itemPerPage}
        setItemPerPage={setItemPerPage}
        selectedCheckBox={selectedCheckBox}
        bulkDelete={bulkDelete}
        setSearchTable={setSearchTable}
        createButton={<AddGroup />}
        handle="groups"
        isCheckboxes={deletePermission}
      />

      <DataTable
        columnData={[
          {
            id: "username",
            name: "Username",
            enableSort: true,
            align: "left",
            disablePadding: "none",
          },

          {
            id: "email",
            name: "Email",
            enableSort: true,
            align: "left",
            disablePadding: "none",
          },
          {
            id: "roles",
            name: "Roles",
            enableSort: true,
            align: "left",
            disablePadding: "none",
          },

          {
            id: "department",
            name: "Department",
            enableSort: false,
            align: "left",
            disablePadding: "checkbox",
          },

          {
            id: "actions",
            name: "Action",
            enableSort: false,
            align: "center",
            disablePadding: "none",
          },
        ]}
        rows={list}
        isCheckboxes={true}
        selectedRowsPerPage={itemPerPage.toString()}
        totalRecordCount={Number(dataList.count)}
        handleChangePageParent={handleChangePage}
        pagenum={Number(page)}
        handleChangeRowsPerPageParent={handleChangeRowsPerPage}
        bulkDelete={bulkDelete}
        selectedCheckBox={selectedCheckBox}
        setSelectedCheckBox={setSelectedCheckBox}
      />
    </>
  );
}

export default MembersList;
