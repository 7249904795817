import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
export interface DataList {
  count: number;
  next: string | null;
  previous: String | null;
  results: [];
}
export interface ModuleState {
  data: DataList;
  wholeData: DataList | any;
  page?: number;
  perPage?: number;
  search?: string;
  detail?: any;
  wholedetail?: any;
  moduleData?: any;
  wholeAssessmentData?: any;
  wholedetailerrors?: any;
  videoData?: any;
  assessmentModulesData?: any;
}

export const initialState: ModuleState = {
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  wholeData: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },

  wholeAssessmentData: {},
  page: 1,
  perPage: 10,
  search: "",
  wholedetail: {},
  wholedetailerrors: {},
  videoData: null,
  assessmentModulesData: {},
};

export const moduleSlice = createSlice({
  name: "module",
  initialState,
  reducers: {
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setWholeData: (state, { payload }) => {
      state.wholeData = payload;
    },
    setWholeAssessmentsData: (state, { payload }) => {
      state.wholeAssessmentData = payload;
    },
    setDetail: (state, { payload }) => {
      state.detail = payload;
    },
    setWholeDetail: (state, { payload }) => {
      state.wholedetail = payload;
    },
    setModuleData: (state, { payload }) => {
      state.moduleData = payload;
    },
    setWholeDetailforErrors: (state, { payload }) => {
      state.wholedetailerrors = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setPerPage: (state, { payload }) => {
      state.perPage = payload;
    },
    setSearch: (state, { payload }) => {
      state.search = payload;
    },
    setVideoData: (state, { payload }) => {
      state.videoData = payload;
    },
    setAssessmentModulesData: (state, { payload }) => {
      state.assessmentModulesData = payload;
    },
  },
});

export const {
  setData,
  setWholeData,
  setWholeAssessmentsData,
  setDetail,
  setWholeDetail,
  setWholeDetailforErrors,
  setPage,
  setPerPage,
  setSearch,
  setModuleData,
  setVideoData,
  setAssessmentModulesData,
} = moduleSlice.actions;

export const moduleSelector = (state: RootState) => state;

export default moduleSlice.reducer;
