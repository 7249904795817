function ForwardSVG(props: { color: string }) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.71729 6.93085C5.18052 6.90474 4.6665 7.3226 4.6665 7.93085V16.0715C4.6665 16.8825 5.57968 17.3559 6.24268 16.8879L12.0103 12.8176C12.5743 12.4196 12.5743 11.5828 12.0103 11.1848L6.24268 7.11444C6.07693 6.99744 5.89621 6.93955 5.71729 6.93085ZM14.7173 6.93085C14.1805 6.90474 13.6665 7.3226 13.6665 7.93085V16.0715C13.6665 16.8825 14.5797 17.3559 15.2427 16.8879L21.0103 12.8176C21.5743 12.4196 21.5743 11.5828 21.0103 11.1848L15.2427 7.11444C15.0769 6.99744 14.8962 6.93955 14.7173 6.93085Z"
        fill={props.color ?? "#E7E7E7"}
      />
    </svg>
  );
}

export default ForwardSVG;
