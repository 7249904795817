import React, { useEffect, useState } from "react";
import Style from "./GroupManagementList.module.scss";

import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import DataTable from "../../components/table/DataTable";

import { RootState, useAppDispatch } from "../../store/store";

import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useConfirm } from "material-ui-confirm";
import DeleteSVG from "../../icons/DeleteSVG";

import {
  deleteGroupMember,
  getGroupMembers,
  getWholeGroupMembers,
} from "../../services/groupManagementService";

import { useTranslation } from "react-multi-lang";
import TableFilters from "../../components/table/TableFilters";
import useCompanyLanguage from "../../hooks/language";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { setWholeMembersData } from "../../store/reducers/groupManagementReducer";
import { confirmProps } from "../../utils/confirmProps";
import AddMember from "./AddMember";

const SearchTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#A0AAB4",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& #custom-css-outlined-input": {
    zIndex: 1,
    padding: 7.5,
    paddingLeft: 2,
    fontSize: "12px",
    width: "14rem",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      backgroundColor: "white",
      borderColor: "white",
      borderRadius: 50,
      fontFamily: "dmSans",
      fontSize: "14px",
      fontWeight: 400,
      letterSpacing: "-0.0175rem",
      width: "14rem",
      zIndex: 0,
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6F7E8C",
    },
  },
});

const GroupMemberList = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const members = useSelector(
    (state: RootState) => state.groupManagementReducer.membersData
  );

  const wholemembers = useSelector(
    (state: RootState) => state.groupManagementReducer.wholemembersData
  );
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;

  const [page, setPage] = useState<number>(1);
  const [itemPerPage, setItemPerPage] = useState<number>(10);
  const [searchTable, setSearchTable] = useState<string>("");
  const [rows, setRows] = useState([]);
  const [list, setList] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<any[]>([]);

  useEffect(() => {
    dispatch(
      getGroupMembers(Number(id), {
        page_size: Number(itemPerPage),
        page: Number(page),
        search: searchTable,
      })
    );
  }, [id, dispatch, itemPerPage, page, searchTable]);
  useEffect(() => {
    let data: any = members?.results;
    if (Object.keys(data)?.length === 0) {
      setRows([]);
    } else setRows(data);
  }, [members]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });

      dispatch(setSuccess(null));
      setItemPerPage(10);
      setPage(1);
      dispatch(
        getGroupMembers(Number(id), {
          page_size: Number(10),
          page: Number(1),
          search: searchTable,
        })
      );
    }
  }, [alertMessage]);
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setSelectedCheckBox([]);
    setPage(page);
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPage(1);
    setSelectedCheckBox([]);
    setItemPerPage(Number(rowsPerPage));
  };

  useEffect(() => {
    modifyRows();
  }, [rows]);

  const modifyRows = () => {
    let f_rows: any = [];
    rows.forEach(
      (
        r: {
          id: string;
          user: any;
          created_at: string;
        },
        i: number
      ) => {
        f_rows = [
          ...f_rows,
          {
            id: r?.user?.id.toString(),
            name: r?.user?.first_name + " " + r?.user?.last_name,
            email: r?.user?.email || "",
            created_at:
              moment(new Date(r.created_at)).format("MMM Do, YYYY") || "",

            actions: (
              <>
                <button
                  className="btn-link"
                  type="button"
                  onClick={() => {
                    handleDeleteAction(r?.user);
                  }}
                >
                  <DeleteSVG color="red" />
                </button>
              </>
            ),
          },
        ];
      }
    );

    setList(f_rows);
  };

  const handleDeleteAction = (row: any) => {
    confirm({
      title: t("Are you sure!"),
      description: `Do you want to delete member with ${
        row?.username ? "username - '" + row?.username + "' and " : ""
      } ${row?.email ? "email - '" + row.email + "'" : ""}?`,
      ...confirmProps,
    })
      .then(() => {
        dispatch(deleteGroupMember(Number(id), [String(row?.id)]));
      })
      .catch(() => {});
  };

  const bulkDelete = (ids: string[]) => {
    confirm({
      title: t("Are you sure!"),
      description: t(`Do you want to delete selected members?`),
      ...confirmProps,
    })
      .then(() => {
        dispatch(deleteGroupMember(Number(id), ids.join().split(",")));
        setSelectedCheckBox([]);
      })
      .catch(() => {});
  };

  const handleClicked = () => {
    getWholeMembers();
  };

  const getWholeMembers = () => {
    if (members)
      dispatch(
        getWholeGroupMembers(Number(id), {
          page_size: members.count,
          page: 1,
          search: "",
        })
      );
  };

  return (
    <>
      <div
        className={Style.pageStart}
        style={{
          paddingBottom: "0.6em",
        }}
      >
        <TableFilters
          dataList={members}
          itemPerPage={itemPerPage}
          setItemPerPage={handleChangeRowsPerPage}
          createButton={<AddMember />}
          selectedCheckBox={selectedCheckBox}
          bulkDelete={bulkDelete}
          setSearchTable={setSearchTable}
          handle="group-member"
          isCheckboxes={true}
          exportCSV={members?.count ? true : false}
          setParentData={() => dispatch(setWholeMembersData({}))}
          handleClicked={handleClicked}
          rows={wholemembers?.results?.map((r: any, i: any) => ({
            // num: i + 1,
            name: r?.user?.first_name + " " + r?.user?.last_name,
            email: r?.user?.email || "",
            created_at:
              moment(new Date(r.created_at)).format("MMM Do, YYYY") || "",
          }))}
          headers={["Name", "Email", "Start Date"]}
          filename={`group_member_list_${id}.csv`}
        />
      </div>
      <DataTable
        columnData={[
          {
            id: "name",
            name: t("Name"),
            enableSort: true,
            align: "left",
            disablePadding: "none",
          },

          {
            id: "email",
            name: t("Email"),
            enableSort: true,
            align: "left",
            disablePadding: "none",
          },

          {
            id: "created_at",
            name: t("Created Date"),
            enableSort: true,
            align: "left",
            disablePadding: "none",
          },

          {
            id: "actions",
            name: t("Actions"),
            enableSort: false,
            align: "left",
            disablePadding: "none",
          },
        ]}
        rows={list}
        isCheckboxes={true}
        selectedRowsPerPage={String(itemPerPage)}
        totalRecordCount={Number(members?.count)}
        handleChangePageParent={handleChangePage}
        pagenum={Number(page)}
        handleChangeRowsPerPageParent={handleChangeRowsPerPage}
        bulkDelete={bulkDelete}
        selectedCheckBox={selectedCheckBox}
        setSelectedCheckBox={setSelectedCheckBox}
      />
    </>
  );
};

export default GroupMemberList;
