import { useConfirm } from "material-ui-confirm";
import { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../../components/Header";
import TrainingDetail from "../../../components/TrainingDetail";
import { CustomProgressbar } from "../../../helpers/helpers";
import useCompanyLanguage from "../../../hooks/language";
import useColor from "../../../hooks/useColor";
import {
  addSelfEnroleInTraining,
  getTraining,
} from "../../../services/trainingsService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../../store/reducers/alertMessageReducer";
import { RootState, useAppDispatch } from "../../../store/store";
import { defaultThemeColor } from "../../../utils/colors_values";
import { confirmProps } from "../../../utils/confirmProps";
import { trainingTypeLabel } from "../../../utils/staticEntities";
import TrainingBar from "./TrainingBar";
import Style from "./TrainingDetails.module.scss";

function TrainingDetails() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const { id } = useParams();

  const confirm = useConfirm();
  const [isShowProgress, setShowProgress] = useState<boolean>(false);
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;
  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  const trainingDetail: any = useSelector(
    (state: RootState) => state.trainingsReducer.trainingData
  );

  const handleNavigation = (row: any) => {
    if (
      (trainingDetail?.type == "Scheduled" ||
        trainingDetail?.type == "Duration" ||
        trainingDetail?.type == "Not_Scheduled") &&
      trainingDetail?.completion_status == "Incomplete"
    ) {
      toast.error(String(t("This training has been expired")), {
        toastId: String(t("This training has been expired")),
      });

      return;
    }
    if (trainingDetail && trainingDetail?.is_enrolled) {
      if (row?.module?.type?.toLowerCase() === "assessment") {
        if (row?.module?.module_status?.toLowerCase() === "completed") {
          toast.error(
            String(t("You are not allow to retake this assessment")),
            {
              toastId: String(t("You are not allow to retake this assessment")),
            }
          );
          // navigate("/my-training");
          return;
        }
        navigate(`/training/${id}/lectures/${row?.module?.id}/true`);

        // navigate(
        //   `/assessment-start/${id}/${row?.module?.id}/${row?.module?.assessment?.id}`
        // );
      } else if (row?.module?.type?.toLowerCase() === "scorm") {
        // alert("Pending SCORM");
        navigate(`/training/${id}/lectures/${row?.module?.id}/true`);
      } else if (
        row?.module?.type?.toLowerCase() === "video" ||
        row?.module?.type?.toLowerCase() === "pdf"
      ) {
        navigate(`/training/${id}/lectures/${row?.module?.id}/true`);
      }
    }
    return;
  };

  useEffect(() => {
    dispatch(getTraining(Number(id)));
  }, [id]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      dispatch(getTraining(Number(id)));
    }
  }, [alertMessage]);

  const handleSelfEnrole = (id: number) => {
    // const sendSelected = selected.map((sel) => sel);
    confirm({
      title: t("Are you sure!"),
      description: t(`Do you want to enroll in this training?`),
      ...confirmProps,
    })
      .then(() => {
        dispatch(addSelfEnroleInTraining({ training_id: id }));
      })
      .catch(() => {});
  };
  useEffect(() => {
    // setFilteredEmployees(
    //   trainingDetail.filter(employee => {
    //     return employee.department === department
    //   })
    // )
  }, [trainingDetail]);

  const interfaceForegroundTextColor =
    useColor("interface_foreground_text_color") ||
    defaultThemeColor.interface_foreground_text_color;
  const interfaceForegroundColor =
    useColor("interface_foreground_color") ||
    defaultThemeColor.interface_foreground_color;

  return (
    <div
      className={`${Style.background}`}
      style={{
        background:
          useColor("interface_background_color") ||
          defaultThemeColor.interface_background_color,
        color:
          useColor("interface_foreground_text_color") ||
          defaultThemeColor.interface_foreground_text_color,
      }}
    >
      <div style={{ paddingBottom: "2.4em" }}>
        {/* {Object.keys(trainingDetail).length > 0 && ( */}
        <Header
          page={t("Training Detail")}
          mainHead={t("Training / Detail page")}
          backLink={
            trainingDetail?.is_enrolled
              ? "/my-training"
              : trainingDetail?.is_enrolled == false
              ? "/browse-trainings"
              : ""
          }
        />
        {/* )} */}
      </div>
      <div>
        <div
          style={{
            background:
              useColor("interface_foreground_color") ||
              defaultThemeColor.interface_foreground_color,

            borderRadius: 10,
            padding: "2rem",
          }}
          className={Style.bottomSpace}
        >
          <div className="d-flex justify-content-between">
            <div className="w-100">
              <div
                className={Style.detailHeading}
                style={{ color: interfaceForegroundTextColor }}
              >
                {trainingDetail?.name}
              </div>
              <div
                className={Style.subHeading}
                style={{ color: interfaceForegroundTextColor }}
              >
                <span className={Style.subHeadingAssessment}>
                  {trainingDetail &&
                    trainingDetail?.ordered_modules &&
                    trainingDetail?.ordered_modules.filter(
                      (i: any) =>
                        (i?.module?.type).toLowerCase() == "assessment"
                    ).length}{" "}
                  {t("Assessments")}.{" "}
                  {trainingDetail &&
                    trainingDetail?.ordered_modules &&
                    trainingDetail?.ordered_modules.filter(
                      (i: any) =>
                        !((i?.module?.type).toLowerCase() == "assessment")
                    ).length}{" "}
                  {t("Lectures")}
                </span>
              </div>
              <div className="col-12">
                <TrainingDetail trainingData={trainingDetail} />
              </div>
              <div
                className={Style.desc}
                style={{
                  paddingTop: "2em",
                  maxWidth: "90%",
                }}
              >
                {trainingDetail?.description}
              </div>
            </div>
            {!loading &&
              (trainingDetail &&
              trainingDetail?.is_enrolled &&
              trainingDetail?.progress == 100 ? (
                <div className={`${Style.descButtonWrapper}`}>
                  <button
                    disabled
                    style={{ minWidth: "13em" }}
                    className={Style.trainingBtn}
                  >
                    {t("Training Completed")!}
                  </button>
                </div>
              ) : trainingDetail && trainingDetail?.is_enrolled ? (
                trainingDetail?.type != "Self_Paced" &&
                trainingDetail?.completion_status != "Incomplete" &&
                trainingDetail?.completion_status != "Not Started" ? (
                  <div
                    style={{
                      height: "120px",
                      marginBottom: "15px",
                      marginRight: "2em",
                    }}
                  >
                    <h6 style={{ textAlign: "center", marginBottom: "15px" }}>
                      {t("Progress")}
                    </h6>
                    <CustomProgressbar
                      value={trainingDetail?.progress}
                      color={
                        // defaultThemeColor.interface_foreground_text_color ||
                        "#00CF3A"
                      }
                    >
                      <div> {trainingDetail?.progress.toFixed(0)}%</div>
                    </CustomProgressbar>
                  </div>
                ) : (
                  // Incomplete button
                  <div className={`${Style.descButtonWrapper}`}>
                    <button
                      disabled
                      style={{ minWidth: "13em" }}
                      className={
                        trainingDetail?.completion_status == "In Progress" ||
                        trainingDetail?.completion_status == "Completed"
                          ? Style.trainingBtn
                          : Style.trainingBtnCancel
                      }
                    >
                      {t(trainingTypeLabel[trainingDetail?.completion_status])}
                    </button>
                  </div>
                )
              ) : (
                <div className={`${Style.descButtonWrapper}`}>
                  <button
                    onClick={() => {
                      handleSelfEnrole(Number(id));
                    }}
                    className={Style.trainingBtn}
                  >
                    {t("Enroll Training")}
                  </button>
                </div>
              ))}
          </div>
          <div style={{ marginTop: "3em" }}>
            {trainingDetail?.ordered_modules?.map((row: any, i: any) => (
              <div key={i} className={`w-100`}>
                <TrainingBar
                  index={i + 1}
                  number={trainingDetail?.ordered_modules.length}
                  header={row?.module?.name || ""}
                  secondaryText={row?.module?.description || ""}
                  type={row?.module.type}
                  checked={row?.module?.module_status == "Completed" || false}
                  video={row?.module.type == "Video" ? row?.module?.file : null}
                  cursor={
                    trainingDetail && trainingDetail?.is_enrolled
                      ? "pointer"
                      : ""
                  }
                  handleNavigation={handleNavigation}
                  row={row}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrainingDetails;
