import { useConfirm } from "material-ui-confirm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import usePermission from "../../hooks/usePermission";
import { getQuestion } from "../../services/questionsService";
import { alertMessageSelector } from "../../store/reducers/alertMessageReducer";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import Style from "./QuestionDetail.module.scss";

function QuestionDetail() {
  const { id } = useParams();

  const navigate = useNavigate();
  const confirm = useConfirm();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const addPermission = usePermission("add_certificate");
  const editUpdatePermission = usePermission("change_category");
  const deletePermission = usePermission("delete_category");
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<number[]>([]);
  const dataList: any = useAppSelector((state) => state.questionReducer.data);
  const detail = useAppSelector((state) => state.questionReducer.questionData);
  console.log("detail", detail);
  const wholeData = useAppSelector(
    (state) => state.questionReducer.wholeDetail
  );
  const [pageNum, setPageNum] = useState(Number(1));
  const [perPageNum, setPerPageNum] = useState(10);
  const [pageSearch, setPageSearch] = useState("");

  const [list, setList] = useState([]);

  const alertMessage = useAppSelector(alertMessageSelector).alertMessageReducer;
  useEffect(() => {
    dispatch(getQuestion({ id: id }));
  }, [id]);

  const foregroundTextColor =
    useColor("interface_foreground_text_color") ||
    defaultThemeColor.interface_foreground_text_color;

  return (
    <div className={`${Style.categoryStart} ${Style.background}`}>
      <div style={{ paddingBottom: "0.6em" }}>
        <Header
          page={t("Question Detail")}
          mainHead={t("Question Library")}
          backLink="/questions-library"
        />

        <div
          style={{
            background:
              useColor("interface_foreground_color") ||
              defaultThemeColor.interface_foreground_color,
            color: foregroundTextColor,
            borderRadius: 10,
            marginTop: "1.5rem",
          }}
          className={Style.bottomSpace}
        >
          <div
            className={`${Style.question}  px-3 py-4`}
            style={{
              color: foregroundTextColor,
            }}
          >
            {t("Q")} . {detail?.question_text}
          </div>
          <div className="d-flex justify-content-between px-3 pb-5">
            <div className={Style.mcqOptions}>
              {detail?.options?.map((option: any, index: any) => (
                <div key={index} className="option">
                  <input
                    type="radio"
                    name={`${detail?.question_text.replaceAll(" ", "")}`}
                    id={`option1-${detail?.id}-${index}`}
                    value={option}
                    // checked={topping === option}
                    // onChange={onOptionChange}
                    style={{ cursor: "pointer" }}
                  />
                  <label
                    className={Style.optionLabel}
                    htmlFor={`option1-${detail?.id}-${index}`}
                  >
                    {option}
                  </label>
                </div>
              ))}
            </div>
            <div className={Style.imageSection}>
              {detail?.image?.file && (
                <img
                  height="150px"
                  width="250px"
                  src={detail?.image?.file}
                  alt="image loading..."
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuestionDetail;
