import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import DataTable from "../../components/table/DataTable";
import TableFilters from "../../components/table/TableFilters";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import usePermission from "../../hooks/usePermission";
import DeleteSVG from "../../icons/DeleteSVG";
import EditSVG from "../../icons/EditSVG";
import ViewSVGAction from "../../icons/ViewSVGAction";
import {
  bulkDeletePolicyData,
  deletePolicy,
  getAll,
} from "../../services/policyService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import { confirmProps } from "../../utils/confirmProps";
import Style from "./PolicyListing.module.scss";

function PolicyListing() {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const addPermission = usePermission("add_policy");
  const detailPermission = usePermission("view_policy");
  const editUpdatePermission = usePermission("change_policy");
  const deletePermission = usePermission("delete_policy");
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<number[]>([]);
  const dataList = useAppSelector((state) => state.policyReducer.data);

  const searchParams = new URLSearchParams(document.location.search);
  const [pageNum, setPageNum] = useState(Number(searchParams.get("page") || 1));
  const [perPageNum, setPerPageNum] = useState(
    Number(searchParams.get("perpage")) || 10
  );
  const [pageSearch, setPageSearch] = useState(
    searchParams.get("search") || ""
  );
  const [list, setList] = useState([]);

  const alertMessage = useAppSelector(alertMessageSelector).alertMessageReducer;

  useEffect(() => {
    AllData();
  }, [
    searchParams.get("page"),
    searchParams.get("perpage"),
    searchParams.get("search"),
  ]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }

    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      AllData();
    }
  }, [alertMessage]);

  const AllData = () => {
    let query: any = {};
    query.page = Number(searchParams.get("page") || 1);

    query.page_size = Number(searchParams.get("perpage") || 10);

    query.search = String(searchParams.get("search") || "");
    dispatch(getAll(query));
  };

  const urlWithQueryString = (
    pageVal = Number(pageNum),
    perPageVal = Number(perPageNum),
    searchVal = String(pageSearch)
  ) => {
    let object: any = {};
    if (pageVal) {
      object.page = pageVal;
    }
    if (perPageVal) {
      object.perpage = perPageVal;
    }
    if (searchVal) {
      object.search = searchVal;
    }

    const objString = "?" + new URLSearchParams(object).toString();

    navigate(`/policy-listing${objString}`);
  };
  const handleDeleteAction = (row: any) => {
    confirm({
      title: t("Are you sure!"),
      description: `Do you want to delete ${row?.name} policy?`,
      ...confirmProps,
    })
      .then(() => {
        dispatch(deletePolicy(row.id));
      })
      .catch(() => {});
    return;
  };
  const modifyRows = () => {
    let f_rows: any = [];
    dataList?.results?.forEach(
      (
        r: {
          id: string;
          name: string;
          details: any;
          status: string;
        },
        i: number
      ) => {
        f_rows = [
          ...f_rows,
          {
            id: r.id,
            name: r.name,
            // description: (
            //   <div
            //     dangerouslySetInnerHTML={{
            //       __html:
            //         JSON.parse(r?.description).length > 100
            //           ? JSON.parse(r?.description).substring(0, 100) + "..."
            //           : JSON.parse(r?.description),
            //     }}
            //   ></div>
            // ),
            users_accepted: r?.details?.users?.filter((row: any) => {
              return row?.status?.toLowerCase() === "accepted";
            })?.length,

            status: (
              <span
                className={
                  r.status == "Approved"
                    ? Style.statusSuccess
                    : Style.statusDraft
                }
              >
                {r.status == "Approved" ? "Approved" : "Draft"}
              </span>
              // <button
              //   style={{
              //     backgroundColor: "#00C366",
              //     color: "white",
              //     border: "none",
              //     padding: "5px 10px",
              //     borderRadius: "20px",
              //   }}
              // >
              //   {r?.status}
              // </button>
            ),
            actions: (
              <>
                <div style={{ textAlign: "center", paddingLeft: "1em" }}>
                  {detailPermission && (
                    <Link className="" to={`/policy-detail/${r.id.toString()}`}>
                      <button className="btn-link" type="button">
                        <ViewSVGAction />
                      </button>
                    </Link>
                  )}
                  {editUpdatePermission && (
                    <Link
                      className=""
                      to={`/Edit-policy-detail/${r.id.toString()}`}
                    >
                      <button className="btn-link" type="button">
                        <EditSVG />
                      </button>
                    </Link>
                  )}
                  {deletePermission && (
                    <button
                      className="btn-link"
                      type="button"
                      onClick={(e) => {
                        handleDeleteAction(r);
                      }}
                    >
                      <DeleteSVG color="red" />
                    </button>
                  )}
                </div>
              </>
            ),
          },
        ];
      }
    );
    setList(f_rows);
  };

  useEffect(() => {
    modifyRows();
  }, [dataList]);
  const handleAddNew = () => {
    navigate("/add-policy");
  };
  const AddCategoryButton = () => {
    const interfaceTextColor =
      useColor("interface_background_text_color") ||
      defaultThemeColor.interface_background_text_color;
    return (
      <>
        {addPermission && (
          <Button
            sx={{ textTransform: "none" }}
            variant="text"
            color="inherit"
            endIcon={
              <AddIcon
                style={{
                  fontSize: 16,
                  color: interfaceTextColor,
                  marginRight: lang === "ar" ? "10px" : "0px",
                }}
              />
            }
            style={{
              fontSize: 13,
              color: interfaceTextColor,
            }}
            onClick={() => {
              handleAddNew();
            }}
          >
            {t("Add New")}
          </Button>
        )}
      </>
    );
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, val: number) => {
    setPageNum(Number(val));
    urlWithQueryString(Number(val), Number(perPageNum), pageSearch);
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPageNum(1);
    setPerPageNum(Number(rowsPerPage));
    urlWithQueryString(1, Number(rowsPerPage), pageSearch);
  };
  const handleChangeSearch = (val: string) => {
    setPageNum(1);
    setPageSearch(String(val));
    setPerPageNum(Number(10));
    urlWithQueryString(1, perPageNum, String(val));
  };
  const bulkDelete = (ids: any) => {
    // const sendSelected = selected.map((sel) => sel);
    confirm({
      title: t("Are you sure!"),
      description: t(`Do you want to delete selected policy's?`),
      ...confirmProps,
    })
      .then(() => {
        dispatch(bulkDeletePolicyData(ids));
        setSelectedCheckBox([]);

        urlWithQueryString(1, 10, pageSearch);
      })
      .catch(() => {
        setSelectedCheckBox([]);
      });
  };
  return (
    <div className={`${Style.categoryStart}`}>
      <div style={{ paddingBottom: "0.6em" }}>
        <Header
          page={t("Policy List")}
          mainHead={t("Policy Management / Policy List")}
        />
        <TableFilters
          dataList={dataList}
          itemPerPage={Number(searchParams.get("perpage") || 10)}
          selectedCheckBox={selectedCheckBox}
          bulkDelete={bulkDelete}
          setItemPerPage={handleChangeRowsPerPage}
          createButton={<AddCategoryButton />}
          setSearchTable={handleChangeSearch}
          handle="category"
          isCheckboxes={deletePermission}
        />
        <DataTable
          columnData={[
            {
              id: "name",
              name: t("Name"),
              enableSort: true,
              align: lang === "ar" ? "center" : "left",
              disablePadding: "checkbox",
            },
            // {
            //   id: "description",
            //   name: "Description",
            //   enableSort: true,
            //   align: "left",
            //   disablePadding: "none",
            // },
            {
              id: "users_accepted",
              name: t("No of User Acceptance"),
              enableSort: true,
              align: lang === "ar" ? "center" : "center",
              disablePadding: "none",
            },
            {
              id: "status",
              name: t("Status"),
              enableSort: false,
              align: lang === "ar" ? "left" : "center",
              disablePadding: "none",
            },
            {
              id: "actions",
              name: t("Actions"),
              enableSort: false,
              align: "center",
              disablePadding: "none",
            },
          ]}
          rows={list}
          isCheckboxes={deletePermission}
          selectedRowsPerPage={String(searchParams.get("perpage") || 10)}
          totalRecordCount={dataList.count}
          handleChangePageParent={handleChangePage}
          pagenum={Number(searchParams.get("page") || 1)}
          handleChangeRowsPerPageParent={handleChangeRowsPerPage}
          bulkDelete={bulkDelete}
          selectedCheckBox={selectedCheckBox}
          setSelectedCheckBox={setSelectedCheckBox}
        />
      </div>
    </div>
  );
}

export default PolicyListing;
