import moment from "moment";
import { memo, useEffect, useRef, useState } from "react";
import { exportComponentAsJPEG } from "react-component-export-image";
import { useTranslation } from "react-multi-lang";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CertificateBuilder from "../../components/CertificateBuilder";
import Header from "../../components/Header";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import { getTraining } from "../../services/trainingsService";
import { setTrainingData } from "../../store/reducers/trainingsReducer";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import Style from "./CertificatesManagement.module.scss";

const CertificateDownload = () => {
  const dispatch = useAppDispatch();
  const { training_id, is_download, redirectTo } = useParams();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const [certificateEditData, setCertificateEditData] = useState<string>("");
  const [width, setWidht] = useState<any>();

  const ref = useRef<any>();

  useEffect(() => {
    return () => {
      // Child unmounted
      dispatch(setTrainingData({}));
    };
  }, []);

  useEffect(() => {
    dispatch(getTraining(Number(training_id)));
  }, [training_id]);

  const trainingDetail: any = useSelector(
    (state: RootState) => state.trainingsReducer.trainingData
  );

  const handleDownloadImage = async () => {
    exportComponentAsJPEG(ref, {
      fileName: `Certificate_for_${trainingDetail?.name}_Downloaded at_${moment(
        new Date()
      ).format("MMM Do, YYYY HH:mm:ss")}`,
    });
  };

  useEffect(() => {
    if (
      !(Object.keys(trainingDetail).length == 0) &&
      trainingDetail?.certificate?.certificate_data
    ) {
      let f = JSON.parse(trainingDetail?.certificate?.certificate_data);
      f = f.replace(/&nbsp;&nbsp;/g, "\u00A0\u00A0");
      f = f.replace(/&nbsp;/g, " ");
      // f = f.replace(/style=/g, "\nstyle='");
      f = f.replace(/;/g, ";'");
      setCertificateEditData(f);
      if (is_download === "true") {
        setTimeout(() => {
          handleDownloadImage();
        }, 1000);
      }
    }
  }, [trainingDetail?.certificate?.id]);

  const foregroundBgColor =
    useColor("interface_foreground_color") ||
    defaultThemeColor.interface_foreground_color;

  const getMeta = (url: any, cb: any) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  useEffect(() => {
    // Use like:
    if (
      trainingDetail &&
      trainingDetail?.certificate &&
      trainingDetail?.certificate?.background_image
    ) {
      getMeta(
        trainingDetail?.certificate?.background_image,
        (err: any, img: any) => {
          console.log(img.naturalWidth, img.naturalHeight);
          // let myImg: any = document.querySelector("#backimage");
          let realWidth = img.naturalWidth;
          // let realHeight = img.naturalHeight;

          setWidht(realWidth);
        }
      );
    }
  }, [trainingDetail]);

  return (
    <div className={`${Style.certificatesStart}`}>
      <Header
        page="Certificate"
        mainHead="Certificate"
        backLink={redirectTo ? "/" + redirectTo : "/my-training"}
      />
      <div
        className="page-start w-100 mt-3 col-lg-12 px-0 mx-0 overflow-hidden"
        style={{
          background: foregroundBgColor,
          display: "flex",
          justifyContent: "center",
        }}
      >
        {(trainingDetail?.compulsory_completed ||
          trainingDetail?.detail?.compulsory_completed) &&
          trainingDetail?.certificate?.certificate_data && (
            <div id="print" ref={ref} style={{ width: '100%' + 30 || "70em" }}>
              <CertificateBuilder
                imageCertificate={trainingDetail?.certificate?.background_image}
                imageDisplayable={trainingDetail?.certificate?.background_image}
                certificateData={certificateEditData}
                varsToReplace={[
                  {
                    "{first_name}": JSON.parse(
                      localStorage.getItem("user_info") as any
                    ).first_name,
                  },
                  {
                    "{last_name}": JSON.parse(
                      localStorage.getItem("user_info") as any
                    ).last_name,
                  },
                  {
                    "{training_name}": trainingDetail?.name || "",
                  },
                  {
                    "{certificate_date}": trainingDetail?.completion_date
                      ? moment(
                          new Date(trainingDetail?.completion_date)
                        ).format("MMMM Do, YYYY")
                      : "",
                  },
                  {
                    "{certificate_logo}":
                      `<img alt={"logo"} src=${trainingDetail?.certificate?.logo} width="20%" height="20%"></img>` ||
                      "",
                  },
                ]}
              ></CertificateBuilder>
            </div>
          )}
      </div>
    </div>
  );
};

export default memo(CertificateDownload);
