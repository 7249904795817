import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { arrayMoveImmutable, arrayMoveMutable } from "array-move";
import { useConfirm } from "material-ui-confirm";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
  SortableHandle,
} from "react-sortable-hoc";
import Style from "../../../components/table/TableComponent.module.scss";
import { RootState, useAppDispatch } from "../../../store/store";

import { useTranslation } from "react-multi-lang";
import useCompanyLanguage from "../../../hooks/language";
import DraggableSVG from "../../../icons/DraggableSVG";

interface IDataTableColumn {
  id: string;
  name: string;
  enableSort?: boolean;
  align?: "center" | "inherit" | "justify" | "left" | "right";
  disablePadding: "checkbox" | "normal" | "none";
  numeric?: boolean;
}

interface IDataTableHeadProps {
  isCheckbox: boolean;
  numSelected: number;
  isDraggable: boolean;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  columns: IDataTableColumn[];
  order: Order;
  orderBy: keyof any;
  rowCount: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof any
  ) => void;
}

interface IDataTableProps {
  rows: any[];
  setRows: any;
  isDraggable?: boolean;
  isCheckboxes?: boolean;
  columnData?: IDataTableColumn[];
  selectedRowsPerPage: string;
  totalRecordCount: number;
  pagenum: number;
  handleChangePageParent: any;
  handleChangeRowsPerPageParent: any;
  bulkDelete?: any;
  selectedCheckBox?: any[];
  setSelectedCheckBox?: any;
  bottomButtonsComponent?: any;
  showLoader?: any;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  // console.warn(a, b, orderBy);
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  // console.warn(stabilizedThis);
  return stabilizedThis.map((el) => el[0]);
}

const DragHandle = SortableHandle(() => (
  <TableCell
    className={Style.helper}
    padding="checkbox"
    sx={{
      borderBottomWidth: 0,
    }}
  >
    <DraggableSVG color={"#64748B"} />
  </TableCell>
));

let container: any;

const SortableItem: React.ComponentClass<
  SortableElementProps & { value: any } & { columnData: any } & {
    isSelected: any;
  } & {
    index: any;
  } & { isCheckboxes: any } & { handleClick: any } & { isDraggable: any }
> = SortableElement(
  ({
    value,
    columnData,
    isSelected,
    index,
    isCheckboxes,
    handleClick,
    isDraggable,
  }: {
    value: any;
    columnData: any;
    isSelected: any;
    index: any;
    isCheckboxes: any;
    handleClick: any;
    isDraggable?: any;
  }) => {
    const isItemSelected = isSelected(value.id);
    const labelId = `enhanced-table-checkbox-${index}`;
    const lang = useCompanyLanguage();

    const columnsValues = Object.keys(value).map((key: string, index1) => {
      if (key == "id") {
        return;
      } else {
        let align = columnData?.find((item: any) => item.id === key)?.align;
        let padding = columnData?.find(
          (item: any) => item.id === key
        )?.disablePadding;
        return (
          <TableCell
            key={`key-${index}-${index1} `}
            align={align ? align : "left"}
            style={{
              paddingLeft:
                padding == "normal"
                  ? "4em"
                  : padding == "checkbox"
                  ? "1.5em"
                  : "1em",
              width: "20%",
            }}
            sx={{
              borderBottomWidth: 0,
            }}
          >
            {value[key]}
          </TableCell>
        );
      }
    });

    return (
      <TableRow
        hover
        // onClick={(event) => handleClick(event, row.name)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={`key-${index}`}
        selected={isItemSelected}
        sx={{ cursor: "pointer", direction: lang === "ar" ? "rtl" : "ltr" }}
      >
        {isDraggable && <DragHandle />}
        {isCheckboxes && (
          <TableCell
            padding="checkbox"
            sx={{
              borderBottomWidth: 0,
            }}
            onClick={(event) => handleClick(event, value.id)}
          >
            <Checkbox
              className={Style.headerCheck}
              color="primary"
              checked={isItemSelected}
              inputProps={{
                "aria-labelledby": labelId,
              }}
            />
          </TableCell>
        )}
        {columnsValues}
      </TableRow>
    );
  }
);

const SortableList: React.ComponentClass<
  SortableContainerProps & { items: any } & { children: any[] } & {
    columnData: any;
  } & { isLoading: any } & { items: any } & { isCheckboxes: any } & {
    showRecordNotFound: any;
  } & { isNotFound: any } & { onSortStart: any } & { showLoader?: any },
  any
> = SortableContainer(
  ({
    children,
    columnData,
    isLoading,
    isCheckboxes,
    items,
    showRecordNotFound,
    isNotFound,
    showLoader,
  }: {
    children: any;
    columnData: any;
    isLoading: any;
    items: any;
    isCheckboxes: any;
    showRecordNotFound: any;
    isNotFound: any;
    showLoader?: any;
  }) => {
    const t = useTranslation();

    return (
      <TableBody ref={(el) => (container = el)}>
        {isLoading && showLoader ? (
          <TableRow hover tabIndex={-1} sx={{ cursor: "pointer" }}>
            <TableCell
              colSpan={
                isCheckboxes
                  ? Number(columnData?.length) + 1
                  : columnData?.length
              }
              className="text-center"
            >
              <CircularProgress size="2rem" color="primary" />
            </TableCell>
          </TableRow>
        ) : items.length > 0 ? (
          children
        ) : showRecordNotFound && isNotFound ? (
          <TableRow hover tabIndex={-1} sx={{ cursor: "pointer" }}>
            <TableCell
              colSpan={columnData?.length + 1}
              className={`text-center align-middle ${Style.fadinAnimation}`}
            >
              {isNotFound && t("Record not found.")}
            </TableCell>
          </TableRow>
        ) : (
          <TableRow hover tabIndex={-1} sx={{ cursor: "pointer" }}>
            <TableCell
              colSpan={
                isCheckboxes
                  ? Number(columnData?.length) + 1
                  : columnData?.length
              }
              className="text-center"
            ></TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  }
);

// const SortableContainer = sortableContainer(({ children }) => {
//   return <ul>{children}</ul>;
// });

const EnhancedTableHead: React.FC<IDataTableHeadProps> = ({
  isCheckbox,
  isDraggable,
  order,
  orderBy,
  numSelected,
  rowCount,
  onSelectAllClick,
  onRequestSort,
  columns,
}): JSX.Element => {
  const createSortHandler =
    (property: keyof any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead
      sx={{
        borderRadius: "10px",
        border: "2px solid #DFDFDF",
        background: "#FFF",
      }}
    >
      <TableRow>
        {isDraggable && (
          <TableCell padding="checkbox">
            <div className={Style.headerCheck} color="primary" />
          </TableCell>
        )}
        {isCheckbox && (
          <TableCell padding="checkbox">
            <Checkbox
              className={Style.headerCheck}
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
        )}
        {columns.map((headCell, i) => (
          <TableCell
            sx={{
              p: 2,
            }}
            onClick={createSortHandler(headCell.id)}
            key={headCell.id}
            className={Style.theader}
            style={{
              color: "#343a40",
              fontSize: 12,
              fontWeight: 600,
              lineHeight: "1.375rem",
            }}
            align={headCell.align ? headCell.align : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.enableSort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                IconComponent={
                  orderBy === headCell.id ? ArrowDropDown : ArrowDropUp
                }
                sx={{ pr: headCell.align === "center" ? 0 : 0 }}
              >
                {headCell.name}
                <Box sx={{ pr: headCell.align === "center" ? 1 : 8 }}></Box>
                {headCell.enableSort ? null : orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.name
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const TableWithSortableColumns: React.FC<IDataTableProps> = ({
  columnData,
  rows,
  setRows,
  isCheckboxes = true,
  isDraggable,
  selectedRowsPerPage,
  pagenum,
  totalRecordCount,
  handleChangePageParent,
  handleChangeRowsPerPageParent,
  bulkDelete,
  selectedCheckBox,
  setSelectedCheckBox,
  bottomButtonsComponent,
  showLoader = true,
}): JSX.Element => {
  let internalColumnData: IDataTableColumn[] = [
    {
      id: "",
      name: "",
      disablePadding: "checkbox",
      numeric: false,
      align: "inherit",
      enableSort: false,
    },
  ];
  if (!columnData) {
    if (rows.length) {
      internalColumnData.length = 0;
      Object.keys(rows[0]).map((key) => {
        internalColumnData.push({
          id: String(key),
          name: String(key),
          disablePadding: "checkbox",
          numeric: false,
          align: "inherit",
          enableSort: false,
        });
      });
    }
  } else {
    internalColumnData = columnData;
  }
  const isLoading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof any>("");
  const [showRecordNotFound, setShowRecordNotFound] = React.useState(false);
  // const [page, setPage] = React.useState(pagenum - 1);
  // const [rowsPerPage, setRowsPerPage] = React.useState(
  //   Number(selectedRowsPerPage) || 10
  // );
  // const [selectedCheckBox, setSelectedCheckBox] = React.useState<any[]>([]);
  const [isNotFound, setIsNotFound] = React.useState(false);
  const dispatch = useAppDispatch();
  const confirm = useConfirm();

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsNotFound(false);
      }, 2500);
    } else {
      setTimeout(() => {
        setIsNotFound(true);
      }, 2500);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isLoading == false) {
      setTimeout(() => {
        setShowRecordNotFound(true);
      }, 2500);
    } else {
      setTimeout(() => {
        setShowRecordNotFound(false);
      }, 2500);
    }
  }, [isLoading]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick: any = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelectedCheckBox(newSelected);
      return;
    }
    setSelectedCheckBox([]);
  };

  const handleClick = (event: any, id: any) => {
    const checked = event.target.checked;
    if (event.target.checked) {
      setSelectedCheckBox([
        ...(selectedCheckBox?.map((r: any) => r) ?? []),
        id,
      ]);
    } else {
      setSelectedCheckBox(
        selectedCheckBox?.filter(function (val) {
          return val !== id;
        })
      );
    }
  };

  const isSelected = (id: any) => selectedCheckBox?.indexOf(id) !== -1;

  useEffect(() => {
    setTimeout(() => {
      setIsNotFound(true);
    }, 1000);
  }, []);

  const [items, setItems] = useState<any>(rows || []);
  const [i, setI] = useState<any>(0);
  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: any;
    newIndex: any;
  }) => {
    let m = arrayMoveImmutable(items, oldIndex, newIndex);
    setRows(m);
    arrayMoveMutable(items, oldIndex, newIndex);
    setI(i + 1);
  };

  const onSortStart = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: any;
    newIndex: any;
  }) => {
    container.classList.add("helper");
    // container.classList.add("inheritCursors");
    container.style.cursor = "grab";
  };

  useEffect(() => {
    setItems(rows);
  }, [rows]);

  return (
    <React.Fragment>
      <div className={Style.dataTableStart}>
        <div className={Style.root}>
          <Paper className={`${Style.paper}`}>
            <TableContainer>
              <Table
                className={Style.table}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
                sx={{
                  mb: 0,
                  borderRadius: "10px",
                  border: "2px solid #DFDFDF",
                  background: "#FFF",
                }}
              >
                <EnhancedTableHead
                  isDraggable={isDraggable as boolean}
                  isCheckbox={isCheckboxes}
                  columns={internalColumnData}
                  numSelected={selectedCheckBox?.length || 0}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />

                <SortableList
                  helperClass={`${Style.helper}`}
                  items={stableSort(rows, getComparator(order, orderBy)) ?? []}
                  onSortStart={onSortStart}
                  onSortEnd={onSortEnd}
                  useDragHandle
                  columnData={columnData}
                  isLoading={isLoading}
                  isCheckboxes={isCheckboxes}
                  showRecordNotFound={showRecordNotFound}
                  isNotFound={isNotFound}
                  showLoader={showLoader}
                >
                  {items &&
                    items.map((value: any, index: any) => (
                      <SortableItem
                        key={`item-${index}`}
                        index={index}
                        value={value}
                        columnData={columnData}
                        isSelected={isSelected}
                        isCheckboxes={isCheckboxes}
                        handleClick={handleClick}
                        isDraggable={isDraggable as boolean}
                      />
                    ))}
                </SortableList>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TableWithSortableColumns;
