/* eslint-disable eqeqeq */
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  MenuItem,
  OutlinedInput,
  RadioGroup,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { Theme, useTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { City, Country, State } from "country-state-city";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { AsyncPaginate } from "react-select-async-paginate";
import * as yup from "yup";
import BpRadioButton from "../../components/BpRadioButton";
import CancelButton from "../../components/CancelButton";
import SubmitButton from "../../components/SubmitButton";
import Virtualize from "../../components/VirtualizedList";
import useColor from "../../hooks/useColor";
import DatePickerSVG from "../../icons/DatePickerSVG";
import axiosPrivate from "../../lib/axiosPrivate";
import { getPersonalForm } from "../../store/reducers/personalFormReducer";
import { RootState, useAppDispatch } from "../../store/store";
import {
  defaultThemeColor,
  light_text_color,
  primary_color,
} from "../../utils/colors_values";
import {
  designations_values,
  experience_values_with_level_text,
} from "../../utils/staticEntities";
import Style from "./UserDetailsEdit.module.scss";

const ITEM_HEIGHT = 24;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(array: string[], name: string, theme: Theme) {
  return {
    fontWeight:
      array.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const schema = yup
  .object({
    gender: yup.string().required("Gender is required"),
    house_number: yup.string().required("House number is required"),
    street_number: yup.string().required("Street number is required"),
    city: yup.string().required("City is required"),
    region: yup.string().required("Region is required"),
    country: yup.string().required("Country is required"),
    first_name: yup.string().required("First Name is required"),
    last_name: yup.string().required("Last Name is required"),
    date_of_birth: yup.date().required("Date of Birth is required"),
    department: yup.object().required("Department is required"),
    designation: yup.string().required("Designation is required"),
    experience: yup.string().required("Experience level is required"),
  })
  .required();

function UserDetailsEdit(props: { setEdit: any; formSubmit: any }) {
  const personalFormData: any = useSelector(
    (state: RootState) => state.personalFormReducer.personal_form_data
  );
  let CommonSchema = yup
    .object()
    .shape({
      first_name: yup
        .string()
        .max(150, "Maximum 150 characters are allowed!")
        .required("First name is required"),
      last_name: yup
        .string()
        .max(150, "Maximum 150 characters are allowed!")
        .required("Last name is required"),
      gender:
        personalFormData &&
        Array.isArray(personalFormData) &&
        personalFormData.length > 0 &&
        personalFormData.find(
          (i: any) =>
            i?.field_label == "gender" && i?.is_mandatory && !i?.is_disable
        )
          ? yup.string().required("Gender is required")
          : yup.string().optional(),
      house_number:
        personalFormData &&
        Array.isArray(personalFormData) &&
        personalFormData.length > 0 &&
        personalFormData.find(
          (i: any) =>
            i?.field_label == "address" && i?.is_mandatory && !i?.is_disable
        )
          ? yup.string().required("House Number is required")
          : yup.string().optional(),
      street_number:
        personalFormData &&
        Array.isArray(personalFormData) &&
        personalFormData.length > 0 &&
        personalFormData.find(
          (i: any) =>
            i?.field_label == "address" && i?.is_mandatory && !i?.is_disable
        )
          ? yup.string().required("Street Number is required")
          : yup.string().optional(),
      city:
        personalFormData &&
        Array.isArray(personalFormData) &&
        personalFormData.length > 0 &&
        personalFormData.find(
          (i: any) =>
            i?.field_label == "address" && i?.is_mandatory && !i?.is_disable
        )
          ? yup.string().required("City is required")
          : yup.string().optional().nullable(),
      region:
        personalFormData &&
        Array.isArray(personalFormData) &&
        personalFormData.length > 0 &&
        personalFormData.find(
          (i: any) =>
            i?.field_label == "address" && i?.is_mandatory && !i?.is_disable
        )
          ? yup.string().required("Region is required")
          : yup.string().optional().nullable(),
      country:
        personalFormData &&
        Array.isArray(personalFormData) &&
        personalFormData.length > 0 &&
        personalFormData.find(
          (i: any) =>
            i?.field_label == "country" && i?.is_mandatory && !i?.is_disable
        )
          ? yup.string().required("Country is required")
          : yup.string().optional(),
      date_of_birth:
        personalFormData &&
        Array.isArray(personalFormData) &&
        personalFormData.length > 0 &&
        personalFormData.find(
          (i: any) =>
            i?.field_label == "date_of_birth" &&
            i?.is_mandatory &&
            !i?.is_disable
        )
          ? yup
              .date()
              .required("Date of Birth is required")
              .typeError("Please enter correct date of birth")
          : yup.date().optional().nullable(),
      department:
        personalFormData &&
        Array.isArray(personalFormData) &&
        personalFormData.length > 0 &&
        personalFormData.find(
          (i: any) =>
            i?.field_label == "department" && i?.is_mandatory && !i?.is_disable
        )
          ? yup.object().required("Department is required")
          : yup.object().optional().nullable(),
      designation:
        personalFormData &&
        Array.isArray(personalFormData) &&
        personalFormData.length > 0 &&
        personalFormData.find(
          (i: any) =>
            i?.field_label == "designation" && i?.is_mandatory && !i?.is_disable
        )
          ? yup.string().required("Designation is required")
          : yup.string().optional().nullable(),
      experience:
        personalFormData &&
        Array.isArray(personalFormData) &&
        personalFormData.length > 0 &&
        personalFormData.find(
          (i: any) =>
            i?.field_label == "experience_level" &&
            i?.is_mandatory &&
            !i?.is_disable
        )
          ? yup.string().required("Experience Level is required")
          : yup.string().optional().nullable(),
    })
    .required();

  const {
    reset,
    register,
    getValues,
    watch,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CommonSchema),
  });

  const t = useTranslation();
  const theme = useTheme();
  const [designation, setDesignation] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [city, setCity] = React.useState("");
  const [cities, setCities] = React.useState<{ name: string }[]>();
  const [region, setRegion] = React.useState("");
  const [allRegions, setAllRegion] = React.useState<{ name: string }[]>();
  const [experience, setExperience] = React.useState("");
  const [dob, setDOB] = useState<any>();
  console.log("allRegions", allRegions);
  const [experienceLevel, setExperienceLevel] = useState(
    experience_values_with_level_text
  );
  const [designationValue, setdesignationValue] = useState(designations_values);

  const userData = useSelector(
    (state: RootState) => state.userReducer.user_data
  );

  const dispatch = useAppDispatch();

  const isLoading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );

  useEffect(() => {
    dispatch(getPersonalForm({ is_personal: 1 }));
  }, []);

  const conveetNestedToFlatten = (accounts: any, showChild: boolean) => {
    var a: any = [];
    for (var i = 0; i < accounts.length; i++) {
      if (showChild) {
        if (accounts[i].children) {
          a = a.concat(conveetNestedToFlatten(accounts[i].children, showChild));
        }
      }
      a.push(accounts[i]);
    }
    return a;
  };

  const loadOptions = async (
    searchQuery: any,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await axiosPrivate.get(
      `accounts/department/?page=${page}&page_size=100&search=${searchQuery}`
    );
    const data = response.data;
    const showChild = searchQuery ? false : true;
    const dataFlatten = conveetNestedToFlatten(data?.results, showChild);
    const options = dataFlatten?.map((row: any) => {
      return {
        label: row.name || "N/A",
        value: row.id,
      };
    });

    const users = {
      options: options || [],
      hasMore: data?.next ? true : false,
      additional: {
        page: searchQuery ? 1 : page + 1,
      },
    };

    return users;
  };

  useEffect(() => {
    if (country) {
      let c = Country.getAllCountries().find(
        (countrym) => countrym.name == country
      );
      setCities(City.getCitiesOfCountry(c?.isoCode as string));
      setAllRegion(State.getStatesOfCountry(c?.isoCode as string));
    }
  }, [country]);

  useEffect(() => {
    let defaultValues = {
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      email: userData?.email,
      department: userData?.department
        ? {
            value: userData?.department?.id,
            label: userData?.department?.name,
          }
        : null,
      gender: userData?.personal_detail_data?.gender,
      date_of_birth:
        userData?.personal_detail_data?.date_of_birth &&
        dayjs(new Date(userData?.personal_detail_data?.date_of_birth)),
      address: userData?.personal_detail_data?.address,
      country: userData?.personal_detail_data?.country,
      designation: userData?.personal_detail_data?.designation,
      experience: userData?.personal_detail_data?.experience_level,
      street_number: userData?.personal_detail_data?.street_number,
      city: userData?.personal_detail_data?.city,
      region: userData?.personal_detail_data?.region,
      house_number: userData?.personal_detail_data?.address,
    };

    reset({ ...defaultValues });

    if (userData?.personal_detail_data?.country) {
      setCountry(userData?.personal_detail_data?.country);
    }
  }, [userData]);

  const Head = (props: { head: string; asterrisk: boolean; class: string }) => {
    return (
      <div
        className={`${Style.col3} ${Style.labelHeader} d-flex align-items-center ${props.class}`}
        style={{
          color:
            useColor("interface_foreground_text_color") ||
            defaultThemeColor.interface_foreground_text_color,
        }}
      >
        {props.head}&nbsp;
        {props.asterrisk && <span className="color-red">*</span>}
      </div>
    );
  };

  const foregroundTextColor =
    useColor("interface_foreground_text_color") ||
    defaultThemeColor.interface_foreground_text_color;

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;
  const interfaceBgColor =
    useColor("interface_background_color") ||
    defaultThemeColor.interface_background_color;

  const primaryButton =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;
  const primaryButtonBg =
    useColor("button_primary") || defaultThemeColor.button_primary;

  const MenuPropsCountry = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 11.5 + ITEM_PADDING_TOP,
        width: 250,
        color: interfaceTextColor,
        background: interfaceBgColor,
      },
    },
  };

  return (
    <form
      id="myform"
      style={{
        background:
          useColor("interface_foreground_color") ||
          defaultThemeColor.interface_foreground_color,
        color:
          useColor("interface_foreground_text_color") ||
          defaultThemeColor.interface_foreground_text_color,
        borderRadius: "10px",
      }}
      onSubmit={handleSubmit(props.formSubmit)}
    >
      <div className="col-12">
        <div className={Style.bottomSpace}>
          {isLoading ? (
            <div
              className="col-12 d-flex justify-content-center mt-5 pt-5"
              style={{ marginBottom: "35em" }}
            >
              <CircularProgress size="3rem" color="primary" />
            </div>
          ) : (
            <div className={`d-flex flex-wrap ${Style.justify}`}>
              {personalFormData &&
              Array.isArray(personalFormData) &&
              personalFormData.length > 0 &&
              personalFormData.find(
                (i: any) => i?.field_label == "gender" && !i?.is_disable
              ) ? (
                <>
                  <Head
                    head={t("Gender")}
                    asterrisk={
                      personalFormData.find(
                        (i: any) => i?.field_label == "gender" && !i?.is_disable
                      )?.is_mandatory
                    }
                    class="mb-2"
                  />
                  <FormControl
                    className={`${Style.col8} d-flex align-content-center`}
                  >
                    {userData && Object.keys(userData).length > 0 && (
                      <Controller
                        control={control}
                        name="gender"
                        defaultValue={userData?.personal_detail_data?.gender}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <RadioGroup
                            row
                            aria-labelledby="gender-row-radio-buttons-group-label"
                            className="d-flex col-12"
                            defaultValue={
                              userData?.personal_detail_data?.gender
                            }
                            onChange={(event) => onChange(event)}
                          >
                            <FormControlLabel
                              value="male"
                              control={<BpRadioButton />}
                              label={t("Male")}
                              className="col-6 mx-1"
                            />
                            <FormControlLabel
                              value="female"
                              control={<BpRadioButton />}
                              label={t("Female")}
                              className="col-5"
                            />
                          </RadioGroup>
                        )}
                      />
                    )}
                    <p className="color-red" style={{ marginInline: "0.5rem" }}>
                      {errors.gender?.message || ""}
                    </p>
                  </FormControl>
                </>
              ) : null}
              <Head head={t("Name")} asterrisk={true} class="mb-2 mt-4" />
              <div
                className={`${Style.col6} ${Style.labelHeader} mt-4`}
                style={{
                  color: foregroundTextColor,
                }}
              >
                {t("First Name")}
              </div>
              <div
                className={`${Style.col6} ${Style.labelHeader} mt-4`}
                style={{
                  color: foregroundTextColor,
                }}
              >
                {t("Last Name")}
              </div>
              <div
                className={`${Style.col3} d-flex align-items-center mx-4`}
              ></div>
              <div className={`${Style.col6} mb-4 `}>
                <input
                  id={"first_name"}
                  key={"first_name"}
                  className={`${Style.inputfields} ${Style.fieldFont} ${""}`}
                  style={{
                    color: interfaceTextColor,
                    background: interfaceBgColor,
                  }}
                  placeholder={t("First Name")}
                  type={"text"}
                  defaultValue={""}
                  {...register("first_name")}
                />
                <p className="color-red">{errors.first_name?.message || ""}</p>
              </div>

              <div className={`${Style.col6} mb-4 `}>
                <input
                  id={"last_name"}
                  key={"last_name"}
                  className={`${Style.inputfields} ${Style.fieldFont} ${""}`}
                  style={{
                    color: interfaceTextColor,
                    background: interfaceBgColor,
                  }}
                  placeholder={t("Last Name")}
                  type={"text"}
                  defaultValue={""}
                  {...register("last_name")}
                />
                <p className="color-red">{errors.last_name?.message || ""}</p>
              </div>

              {personalFormData &&
              Array.isArray(personalFormData) &&
              personalFormData.length > 0 &&
              personalFormData.find(
                (i: any) => i?.field_label == "date_of_birth" && !i?.is_disable
              ) ? (
                <>
                  <Head
                    head={t("Date of Birth")}
                    asterrisk={
                      personalFormData.find(
                        (i: any) =>
                          i?.field_label == "date_of_birth" && !i?.is_disable
                      )?.is_mandatory
                    }
                    class="mb-2 mt-4"
                  />
                  <div className={`${Style.col8} mt-4`}>
                    <Controller
                      name="date_of_birth"
                      defaultValue={dob}
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="pt-br"
                        >
                          <DatePicker
                            label=""
                            className="col-12"
                            format="DD/MM/YYYY"
                            value={value}
                            onChange={(event) => {
                              onChange(event);
                              setDOB(event);
                            }}
                            disableFuture={true}
                            sx={{
                              color: interfaceTextColor,
                              background: interfaceBgColor,
                            }}
                            slots={{
                              openPickerIcon: DatePickerSVG,
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                    <p className="color-red">
                      {errors?.date_of_birth?.message}
                    </p>
                  </div>
                </>
              ) : null}

              {personalFormData &&
              Array.isArray(personalFormData) &&
              personalFormData.length > 0 &&
              personalFormData.find(
                (i: any) =>
                  (i?.field_label == "address" && !i?.is_disable) ||
                  (i?.field_label == "country" && !i?.is_disable)
              ) ? (
                <div
                  className={`${Style.col3} mb-2 ${Style.labelHeader} mt-4`}
                  style={{
                    color: foregroundTextColor,
                  }}
                >
                  {t("Address")}{" "}
                  {personalFormData.find(
                    (i: any) =>
                      (i?.field_label == "address" && i?.is_mandatory) ||
                      (i?.field_label == "country" && i?.is_mandatory)
                  ) && <span className="color-red">*</span>}
                </div>
              ) : null}
              {personalFormData &&
              Array.isArray(personalFormData) &&
              personalFormData.length > 0 &&
              personalFormData.find(
                (i: any) => i?.field_label == "address" && !i?.is_disable
              ) ? (
                <>
                  <div
                    className={`${Style.col8} mb-2 ${Style.labelHeader} mt-4`}
                    style={{
                      color: foregroundTextColor,
                    }}
                  >
                    {t("House number")}
                  </div>
                  <div
                    className={`${Style.col3} d-flex align-items-center mx-4`}
                  ></div>
                  <div className={`${Style.col8}`}>
                    <input
                      id={"house_number"}
                      key={"house_number"}
                      className={`${Style.inputfields} ${
                        Style.fieldFont
                      } ${""}`}
                      style={{
                        color: interfaceTextColor,
                        background: interfaceBgColor,
                      }}
                      placeholder={t("House number")}
                      type={"text"}
                      defaultValue={""}
                      {...register("house_number")}
                    />
                    <p className="color-red">
                      {errors.house_number?.message || ""}
                    </p>
                  </div>
                </>
              ) : null}

              {personalFormData &&
              Array.isArray(personalFormData) &&
              personalFormData.length > 0 &&
              personalFormData.find(
                (i: any) => i?.field_label == "address" && !i?.is_disable
              ) ? (
                <>
                  <div
                    className={`${Style.col3} d-flex align-items-center mx-4`}
                  ></div>
                  <div
                    className={`${Style.col8} mb-2 ${Style.labelHeader} mt-4`}
                    style={{
                      color: foregroundTextColor,
                    }}
                  >
                    {t("Street number")}
                  </div>
                  <div
                    className={`${Style.col3} d-flex align-items-center mx-4`}
                  ></div>
                  <div className={`${Style.col8}`}>
                    <input
                      id={"street_number"}
                      key={"street_number"}
                      className={`${Style.inputfields} ${
                        Style.fieldFont
                      } ${""}`}
                      style={{
                        color: interfaceTextColor,
                        background: interfaceBgColor,
                      }}
                      placeholder={t("Street number")}
                      type={"text"}
                      defaultValue={""}
                      {...register("street_number")}
                    />
                    <p className="color-red">
                      {errors.street_number?.message || ""}
                    </p>
                  </div>
                </>
              ) : null}

              {personalFormData &&
              Array.isArray(personalFormData) &&
              personalFormData.length > 0 &&
              personalFormData.find(
                (i: any) => i?.field_label == "country" && !i?.is_disable
              ) ? (
                <>
                  {personalFormData.find(
                    (i: any) => i?.field_label == "address" && !i?.is_disable
                  ) && <div className={Style.col3}></div>}
                  <div
                    className={`${Style.col8} mb-2 ${Style.labelHeader} mt-4`}
                    style={{
                      color: foregroundTextColor,
                    }}
                  >
                    {t("Country")}{" "}
                    {personalFormData.find(
                      (i: any) => i?.field_label == "country" && !i?.is_disable
                    )?.is_mandatory && <span className="color-red">*</span>}
                  </div>
                  <div className={Style.col3}></div>
                  <div className={`${Style.col8}`}>
                    <FormControl sx={{ m: 0, width: "100%" }}>
                      <InputLabel id="demo-multiple-name-label">
                        {/* {t("Country")} */}
                      </InputLabel>

                      <Controller
                        control={control}
                        defaultValue={country}
                        name="country"
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <Virtualize
                            id="country"
                            options={Country.getAllCountries().map(
                              (i) => i?.name
                            )}
                            inputLabel={"Country"}
                            // onChange={onChange}
                            onChange={(event: any) => {
                              console.log("event", event);
                              onChange(event);
                              setCountry(event);
                            }}
                            value={value || ""}
                          />
                        )}
                      />
                      {/* <Controller
                        control={control}
                        defaultValue={country}
                        name="country"
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            value={value}
                            onChange={(event, value) => {
                              onChange(event);
                              setCountry(event.target.value);
                            }}
                            input={<OutlinedInput label="Country" />}
                            MenuProps={MenuPropsCountry}
                            placeholder={t("Select")}
                          >
                            {Country.getAllCountries().map((country) => (
                              <MenuItem
                                key={country.name}
                                value={country.name}
                                style={getStyles(
                                  Country.getAllCountries().map((i) => i.name),
                                  country.name,
                                  theme
                                )}
                              >
                                {country.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      /> */}
                    </FormControl>
                    <p className="color-red">{errors.country?.message || ""}</p>
                  </div>
                </>
              ) : null}

              {personalFormData &&
              Array.isArray(personalFormData) &&
              personalFormData.length > 0 &&
              personalFormData.find(
                (i: any) => i?.field_label == "address" && !i?.is_disable
              ) ? (
                <>
                  <div className={Style.col3}></div>
                  <div
                    className={`${Style.col6} ${Style.labelHeader} mt-4`}
                    style={{
                      color: foregroundTextColor,
                    }}
                  >
                    {t("Region")}
                  </div>
                  <div
                    className={`${Style.col6} ${Style.labelHeader} mt-4`}
                    style={{
                      color: foregroundTextColor,
                    }}
                  >
                    {t("City")}
                  </div>

                  <div
                    className={`${Style.col3} d-flex align-items-center mx-4`}
                  ></div>
                  <div className={`${Style.col6} mb-4`}>
                    <FormControl sx={{ mt: 1 }} className="col-12">
                      <InputLabel id="region-name-label"></InputLabel>
                      <Controller
                        control={control}
                        defaultValue={region}
                        name="region"
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <Virtualize
                            id="region"
                            options={allRegions?.map((i) => i?.name)}
                            inputLabel={"Region"}
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </FormControl>
                    <p className="color-red">{errors.region?.message || ""}</p>
                  </div>
                  <div className={`${Style.col6} mb-4`}>
                    <FormControl sx={{ mt: 1 }} className="col-12">
                      <InputLabel id="demo-multiple-name-label"></InputLabel>
                      <Controller
                        control={control}
                        defaultValue={city}
                        name="city"
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <Virtualize
                            id="city"
                            options={cities?.map((i) => i?.name)}
                            inputLabel={"City"}
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </FormControl>
                    <p className="color-red">{errors.city?.message || ""}</p>
                  </div>
                </>
              ) : null}

              {personalFormData &&
              Array.isArray(personalFormData) &&
              personalFormData.length > 0 &&
              personalFormData.find(
                (i: any) => i?.field_label == "department" && !i?.is_disable
              ) ? (
                <>
                  <Head
                    head={t("Department")}
                    asterrisk={
                      personalFormData.find(
                        (i: any) =>
                          i?.field_label == "department" && !i?.is_disable
                      )?.is_mandatory
                    }
                    class="mb-2 mt-3"
                  />
                  <div className={`${Style.col8} mb-3 mt-3`}>
                    <Controller
                      control={control}
                      name="department"
                      render={({ field: { value, onChange, ref } }) => (
                        <AsyncPaginate
                          loadOptions={loadOptions}
                          styles={{
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isFocused
                                ? primary_color
                                : undefined,
                              color: state.isFocused ? "white" : "black",
                            }),
                            multiValue: (baseStyles) => ({
                              ...baseStyles,
                              backgroundColor: primaryButtonBg,
                            }),
                            multiValueLabel: (baseStyles) => ({
                              ...baseStyles,
                              color: primaryButton,
                            }),
                            multiValueRemove: (baseStyles, state) => ({
                              ...baseStyles,
                              backgroundColor: primaryButtonBg,
                              color: primaryButton,
                              ":hover": {
                                backgroundColor: primaryButtonBg,
                                color: primaryButton,
                              },
                            }),
                          }}
                          onChange={onChange}
                          selectRef={ref}
                          value={value}
                          getOptionValue={(option: any) => option.value}
                          getOptionLabel={(option: any) => option.label}
                          className="add-member-select"
                          additional={{
                            page: 1,
                          }}
                          isMulti={false}
                          isClearable
                          isSearchable={true}
                          placeholder={t("Select Department")}
                          debounceTimeout={500}
                        />
                      )}
                    />
                    <p className="color-red">
                      {errors.department?.message || ""}
                    </p>
                  </div>
                </>
              ) : null}

              {personalFormData &&
              Array.isArray(personalFormData) &&
              personalFormData.length > 0 &&
              personalFormData.find(
                (i: any) => i?.field_label == "designation" && !i?.is_disable
              ) ? (
                <>
                  <Head
                    head={t("Designation")}
                    asterrisk={
                      personalFormData.find(
                        (i: any) =>
                          i?.field_label == "designation" && !i?.is_disable
                      )?.is_mandatory
                    }
                    class="mb-2 mt-3"
                  />
                  <div className={`${Style.col8} mb-3 mt-3`}>
                    <FormControl sx={{ m: 0, width: "100%", mt: 1 }}>
                      <InputLabel id="demo-multiple-name-label">
                        {t("Designation")}
                      </InputLabel>
                      <Controller
                        control={control}
                        defaultValue={designation}
                        name="designation"
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            value={value}
                            onChange={(event) => {
                              onChange(event);
                            }}
                            input={<OutlinedInput label={t("Designation")} />}
                            sx={{
                              "& .MuiChip-root": {
                                color: primaryButton,
                                background: primaryButtonBg,
                                // height: "25px",
                              },
                            }}
                            MenuProps={{
                              ...MenuProps,
                              sx: {
                                "&& .Mui-selected": {
                                  color: light_text_color,
                                  background: primary_color,
                                },
                                "&& .Mui-selected:hover": {
                                  color: "black",
                                  background: "skyblue",
                                },
                              },
                            }}
                            placeholder={t("Select")}
                          >
                            {designationValue.map((designation) => (
                              <MenuItem
                                key={designation}
                                value={designation}
                                style={getStyles(
                                  designationValue,
                                  designation,
                                  theme
                                )}
                              >
                                {designation}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                    <p className="color-red">
                      {errors.designation?.message || ""}
                    </p>
                  </div>
                </>
              ) : null}

              {personalFormData &&
              Array.isArray(personalFormData) &&
              personalFormData.length > 0 &&
              personalFormData.find(
                (i: any) =>
                  i?.field_label == "experience_level" && !i?.is_disable
              ) ? (
                <>
                  <Head
                    head={t("Experience Level")}
                    asterrisk={
                      personalFormData.find(
                        (i: any) =>
                          i?.field_label == "experience_level" && !i?.is_disable
                      )?.is_mandatory
                    }
                    class={"mb-3 mt-3"}
                  />
                  <div className={`${Style.col8} mb-3 mt-3`}>
                    <FormControl sx={{ m: 0, width: "100%", mt: 1 }}>
                      <InputLabel id="demo-multiple-name-label">
                        {t("Experience Level")}
                      </InputLabel>
                      <Controller
                        control={control}
                        defaultValue={experience}
                        name="experience"
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            sx={{
                              "& .MuiChip-root": {
                                color: primaryButton,
                                background: primaryButtonBg,
                                // height: "25px",
                              },
                            }}
                            MenuProps={{
                              ...MenuPropsCountry,
                              sx: {
                                "&& .Mui-selected": {
                                  color: light_text_color,
                                  background: primary_color,
                                },
                                "&& .Mui-selected:hover": {
                                  color: "black",
                                  background: "skyblue",
                                },
                              },
                            }}
                            value={value}
                            onChange={(event) => {
                              onChange(event);
                            }}
                            defaultValue={experience}
                            input={<OutlinedInput label="Experience Level" />}
                            placeholder="Select"
                          >
                            {experienceLevel.map((designation) => (
                              <MenuItem
                                key={designation}
                                value={designation}
                                style={getStyles(
                                  experienceLevel,
                                  designation,
                                  theme
                                )}
                              >
                                {designation}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                    <p className="color-red">
                      {errors.experience?.message || ""}
                    </p>
                  </div>
                </>
              ) : null}
              <div className="d-flex flex-wrap gap-3 w-100 px-3 mt-5 align-items-center justify-content-end">
                <SubmitButton label={t("Save")} marginTop={false} />
                <CancelButton
                  size="small"
                  outlined={true}
                  onClick={() => props.setEdit(false)}
                  textColor=""
                  outlineColor=""
                  text={t("Cancel")}
                  className=""
                  bg={""}
                ></CancelButton>
              </div>
              <div className="my-5"></div>
            </div>
          )}
        </div>
      </div>
    </form>
  );
}
export default UserDetailsEdit;
