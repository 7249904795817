// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Registration_formStart__YuRRO {
  margin-top: 50px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #e2e8ef;
  padding: 30px;
}
@media only screen and (max-width: 767px) {
  .Registration_formStart__YuRRO {
    padding: 10px;
  }
}
.Registration_formStart__YuRRO h2 {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #2b3674;
}
.Registration_formStart__YuRRO form .Registration_input__6lYpT {
  border-radius: 4px;
  height: 50px;
  border: 2px solid #e2e8f0;
  margin-top: 3px;
  margin-bottom: 20px;
  padding: 5px 10px;
}
.Registration_formStart__YuRRO form .Registration_checkStart__FCOAv {
  text-align: center;
}
.Registration_formStart__YuRRO form .Registration_checkStart__FCOAv span {
  padding: 0;
}
.Registration_formStart__YuRRO form .Registration_btn__haHXu {
  width: 167px;
  height: 42px;
  border-radius: 4px;
  border: none;
}
.Registration_formStart__YuRRO form .Registration_buttuns__BvU-R {
  margin-top: 50px;
}
.Registration_formStart__YuRRO form .Registration_btnPrimary__\\+9cRC {
  background-color: #0089cf;
  color: #fff;
}
.Registration_formStart__YuRRO form .Registration_btnSecond__5onmI {
  background-color: transparent;
}
.Registration_formStart__YuRRO .Registration_tabsStart__amUF7 li button {
  color: #0089cf;
}

@media only screen and (max-width: 700px) {
  .Registration_manageSpace__bbvDA {
    margin-bottom: 4em;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/registration/Registration.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,sBAAA;EACA,mBAAA;EACA,yBAAA;EACA,aAAA;AACF;AACE;EAPF;IAQI,aAAA;EAEF;AACF;AAAE;EAEE,eAAA;EACA,gBAAA;EACA,uBAAA;EACA,cAAA;AACJ;AAGI;EACE,kBAAA;EACA,YAAA;EACA,yBAAA;EACA,eAAA;EACA,mBAAA;EACA,iBAAA;AADN;AAII;EACE,kBAAA;AAFN;AAIM;EACE,UAAA;AAFR;AAMI;EACE,YAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;AAJN;AAOI;EACE,gBAAA;AALN;AAQI;EACE,yBAAA;EACA,WAAA;AANN;AASI;EACE,6BAAA;AAPN;AAYM;EACE,cAAA;AAVR;;AAiBE;EADF;IAEI,kBAAA;EAbF;AACF","sourcesContent":[".formStart {\n  margin-top: 50px;\n  background-color: #fff;\n  border-radius: 10px;\n  border: 1px solid #e2e8ef;\n  padding: 30px;\n\n  @media only screen and (max-width: \"767px\") {\n    padding: 10px;\n  }\n\n  h2 {\n    // font-family: dmSans;\n    font-size: 24px;\n    font-weight: 700;\n    letter-spacing: -0.02em;\n    color: #2b3674;\n  }\n\n  form {\n    .input {\n      border-radius: 4px;\n      height: 50px;\n      border: 2px solid #e2e8f0;\n      margin-top: 3px;\n      margin-bottom: 20px;\n      padding: 5px 10px;\n    }\n\n    .checkStart {\n      text-align: center;\n\n      span {\n        padding: 0;\n      }\n    }\n\n    .btn {\n      width: 167px;\n      height: 42px;\n      border-radius: 4px;\n      border: none;\n    }\n\n    .buttuns {\n      margin-top: 50px;\n    }\n\n    .btnPrimary {\n      background-color: #0089cf;\n      color: #fff;\n    }\n\n    .btnSecond {\n      background-color: transparent;\n    }\n  }\n  .tabsStart {\n    li {\n      button {\n        color: #0089cf;\n      }\n    }\n  }\n}\n\n.manageSpace {\n  @media only screen and (max-width: 700px) {\n    margin-bottom: 4em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formStart": `Registration_formStart__YuRRO`,
	"input": `Registration_input__6lYpT`,
	"checkStart": `Registration_checkStart__FCOAv`,
	"btn": `Registration_btn__haHXu`,
	"buttuns": `Registration_buttuns__BvU-R`,
	"btnPrimary": `Registration_btnPrimary__+9cRC`,
	"btnSecond": `Registration_btnSecond__5onmI`,
	"tabsStart": `Registration_tabsStart__amUF7`,
	"manageSpace": `Registration_manageSpace__bbvDA`
};
export default ___CSS_LOADER_EXPORT___;
