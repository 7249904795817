import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
export interface DataList {
  count: number;
  next: string | null;
  previous: String | null;
  results: any[];
}
export interface InitState {
  data?: DataList;
  wholedata?: DataList | any;
  categoryData?: DataList;
  questionData?: any;
  imageData?: any;
  page?: number;
  perPage?: number;
  search?: string;
  detail?: any;
  wholeDetail?: any;
}

export const initialState: InitState = {
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  wholedata: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  categoryData: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  questionData: {},
  wholeDetail: {},
  imageData: {},
  page: 1,
  perPage: 10,
  search: "",
};

export const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setWholeData: (state, { payload }) => {
      state.wholedata = payload;
    },
    setCategoryData: (state, { payload }) => {
      state.categoryData = payload;
    },
    setQuestionData: (state, { payload }) => {
      state.questionData = payload;
    },
    setImageData: (state, { payload }) => {
      state.imageData = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setPerPage: (state, { payload }) => {
      state.perPage = payload;
    },
    setSearch: (state, { payload }) => {
      state.search = payload;
    },
    setDetail: (state, { payload }) => {
      state.detail = payload;
    },
    setWholeDetail: (state, { payload }) => {
      state.wholeDetail = payload;
    },
  },
});

export const {
  setData,
  setWholeData,
  setCategoryData,
  setQuestionData,
  setImageData,
  setPage,
  setWholeDetail,
} = questionSlice.actions;

export const userSelector = (state: RootState) => state;

export default questionSlice.reducer;
