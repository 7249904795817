// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddModule_header__ENze3 {
  color: #212b36;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
}

.AddModule_headerCheck__PTWYy {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/training/CreateTraining/AddModule.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EAEA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;AAAF;;AAGA;EACE,UAAA;AAAF","sourcesContent":[".header {\n  color: #212b36;\n  // font-family: dmSans;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 22px; /* 137.5% */\n}\n\n.headerCheck {\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `AddModule_header__ENze3`,
	"headerCheck": `AddModule_headerCheck__PTWYy`
};
export default ___CSS_LOADER_EXPORT___;
