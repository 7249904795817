import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../lib/axios";
import { getUserData } from "../../services/userService";
import { AppThunk, RootState } from "../store";
import { setLoading } from "./loaderReducer";

const personalFormData = JSON.parse(
  localStorage.getItem("personal_form_data") || "{}"
);
const updatePersonalFormData = JSON.parse(
  localStorage.getItem("up_personal_form_data") || "{}"
);

export interface PersonalFormError {
  message: string;
}

export interface PersonalFormState {
  isLoading: boolean;
  error?: PersonalFormError;
  errors?: any;
  personal_form_data: object | any;
  up_personal_form_data: object | any;
}

export interface GetPersonalForm {
  is_personal: number;
}

export interface UpdatePersonalForm {
  updateData: any;
}

export const initialState: PersonalFormState = {
  isLoading: false,
  personal_form_data: personalFormData,
  up_personal_form_data: updatePersonalFormData,
};

export const personalFormSlice = createSlice({
  name: "personalForm",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },

    setPersonalFormData: (state, { payload }) => {
      state.personal_form_data = payload;
    },

    setPersonalFormDataFailed: (state, { payload }) => {
      state.errors = payload;
    },

    setUpdatePersonalFormData: (state, { payload }) => {
      state.up_personal_form_data = payload;
    },

    setUpdatePersonalFormDataFailed: (state, { payload }) => {
      state.errors = payload;
    },
  },
});

export const getPersonalForm =
  (payload: GetPersonalForm): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      let token = "Bearer " + localStorage.getItem("access_token") || "";
      token = token.replace(/['"]+/g, "");
      const config = {
        headers: { Authorization: token },
      };

      await axios
        .get(
          "/accounts/registration-forms/?is_personal=" + payload.is_personal,
          config
        )
        .then((response) => {
          let data = response.data;
          dispatch(setLoading(false));

          if (response.status === 200) {
            dispatch(setPersonalFormData(data));
          } else {
            dispatch(
              setPersonalFormDataFailed(
                "Something went wrong, Please try again"
              )
            );
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setPersonalFormDataFailed(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        setPersonalFormDataFailed("Something went wrong, Please try again")
      );
    }
  };

export const updatePersonalForm =
  (payload: UpdatePersonalForm): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      let token = "Bearer " + localStorage.getItem("access_token") || "";
      token = token.replace(/['"]+/g, "");
      const config = {
        headers: { Authorization: token },
      };

      let body = payload;

      await axios
        .patch(
          "/accounts/registration-forms-bulk-update/",
          body.updateData,
          config
        )
        .then((response) => {
          let data = response.data;
          dispatch(setLoading(false));
          if (response.status === 200) {
            dispatch(setUpdatePersonalFormData(data));
            toast.success(String(data?.detail || "Updated Successfully"), {
              toastId: String(data?.detail || "Updated Successfully"),
            });
            dispatch(getPersonalForm({ is_personal: 1 }));
            dispatch(getUserData());
          } else {
            dispatch(
              setUpdatePersonalFormDataFailed(
                "Something went wrong, Please try again"
              )
            );
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setUpdatePersonalFormDataFailed(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        setUpdatePersonalFormDataFailed(
          "Something went wrong, Please try again"
        )
      );
    }
  };

export const {
  setPersonalFormData,
  setPersonalFormDataFailed,
  setUpdatePersonalFormData,
  setUpdatePersonalFormDataFailed,
} = personalFormSlice.actions;

export const personalFormSelector = (state: RootState) => state;

export default personalFormSlice.reducer;
