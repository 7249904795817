import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, Dialog, DialogContent } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CancelButton from "../../components/CancelButton";
import CreateButton from "../../components/CreateButton";
import useCompanyLanguage from "../../hooks/language";
import usePermission from "../../hooks/usePermission";
import { acceptRejectPolicy, getPolicyData } from "../../services/policyService";
import { alertMessageSelector, setErr, setSuccess } from "../../store/reducers/alertMessageReducer";
import { groupManagementSelector } from "../../store/reducers/groupManagementReducer";
import { RootState, useAppDispatch } from "../../store/store";
import Style from "./AddNewPolicy.module.scss";

export interface SimpleDialogProps {
  open: boolean;
  setParentOpen?: any;
  isLoading?: boolean;
  onClose: any;
  policy?: any;
}

function PolicyAcceptReject(props: SimpleDialogProps) {
  const { onClose, open, setParentOpen, isLoading, policy } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [showButtons, setShowButtons] = useState(false);
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const addPermission = usePermission("add_customusergroup");
  const editUpdatePermission = usePermission("change_customusergroup");
  const deletePermission = usePermission("delete_customusergroup");
  const viewPermission = usePermission("view_customusergroup");

  const states = useSelector(groupManagementSelector).departmentReducer;
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;
  const userInfo = useSelector((state: any) => state.authReducer.loginData);
  const data: any = useSelector((state: RootState) => state.policyReducer.detail);
  useEffect(() => {
    if (policy && policy?.id) dispatch(getPolicyData(Number(policy?.id)));
  }, [policy?.id]);

  const loading = useSelector((state: RootState) => state.loaderReducer.isLoading);

  const policyData: any = useSelector((state: RootState) => state.policyReducer.updatedData);
  useEffect(() => {
    const c_user = data?.details?.users?.find((row: any) => {
      return row?.status === "Not Responded" && userInfo?.id === row?.user?.id;
    });
    if (c_user) {
      setShowButtons(true);
    } else {
      setShowButtons(false);
    }
  }, [data, data?.details?.users]);

  useEffect(() => {
    onClose();
  }, [policyData]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
    }
  }, [alertMessage]);

  const handleClose = () => {
    onClose();
  };

  const handleAcceptPolicy = () => {
    dispatch(
      acceptRejectPolicy({
        id: policy?.id,
        status: "Accepted",
      })
    );
  };

  const handleRejectPolicy = () => {
    dispatch(
      acceptRejectPolicy({
        id: policy?.id,
        status: "Declined",
      })
    );
  };

  return (
    <Dialog
      onClose={handleClose}
      maxWidth={"lg"}
      open={open !== undefined ? open : false}
      style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
    >
      <div
        className="d-flex justify-content-between align-items-center pb-2"
        style={{ borderBottom: "1px solid #cccccc", margin: "0rem 1.7rem", paddingTop: "1rem" }}
      >
        <DialogTitle
          style={{ paddingTop: "0em", fontSize: 24, fontWeight: "bold" }}
          className="ml-3 mt-0 mb-0 pb-0 px-0"
        >
          {(data && data?.name) || t("Policy Loading...")}
        </DialogTitle>
        <div className="">
          <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
        </div>
      </div>
      <DialogContent>
        <div className="pb-2" style={{ borderBottom: "1px solid #cccccc" }}>
          <p
            className={Style.value}
            dangerouslySetInnerHTML={{
              __html: data?.description ? JSON.parse(data?.description) : "",
            }}
          ></p>
          {loading && (
            <div className="w-100 text-align-center d-flex justify-content-center mt-3 mb-3">
              <CircularProgress size="2rem" color="primary" />
            </div>
          )}
        </div>
        {showButtons && (
          <div className="d-flex flex-wrap gap-3 w-100 px-0 mt-2 mb-2 align-items-center justify-content-end mt-4">
            <CreateButton
              disabled={loading}
              size="large"
              outlined={false}
              onClick={() => {
                handleAcceptPolicy();
              }}
              text={t("ACCEPT")}
            ></CreateButton>
            <CancelButton
              size="large"
              outlined={true}
              onClick={() => {
                handleRejectPolicy();
              }}
              disabled={loading}
              textColor="#2196F3"
              outlineColor=""
              text={t("REJECT")}
              className={` ${Style.cancelBtn}`}
              bg={""}
            ></CancelButton>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default PolicyAcceptReject;
