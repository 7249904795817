import {
  button_bg_primary_color,
  error_color,
  light_text_color,
  primary_color,
} from "./colors_values";

export const confirmProps = {
  cancellationButtonProps: {
    // Customize styles for the cancel button
    style: {
      color: primary_color,
      backgroundColor: button_bg_primary_color,
      padding: "6px 20px",
    },
  },
  confirmationButtonProps: {
    // Customize styles for the confirm button
    style: {
      backgroundColor: error_color,
      color: light_text_color,
      padding: "6px 20px",
    },
  },
};
