import React, { useState } from "react";
import { ChromePicker, ColorResult } from "react-color";
import useColor from "../../../hooks/useColor";
import { defaultThemeColor } from "../../../utils/colors_values";
import useVisible from "../../../utils/useVisibleCustomHook";
import Style from "./ColorPicker.module.scss";

interface ColorPickerProps {
  onChange: (color: ColorResult) => void;
  color: string;
  name: string;
  icon: React.ReactNode;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  onChange,
  color,
  icon,
  name,
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const { ref, isVisible, setIsVisible } = useVisible(false);
  const togglePicker = () => {
    setShowPicker(!showPicker);
    setIsVisible(!isVisible);
  };

  const handleColorChange = (selectedColor: ColorResult) => {
    onChange(selectedColor?.hex as any);
  };

  return (
    <div>
      <button
        id={"palletebutton"}
        type={"button"}
        className={Style.editColorButton}
        onClick={togglePicker}
        style={{
          background:
            useColor("button_primary") || defaultThemeColor.button_primary,
          color:
            useColor("button_primary_text") ||
            defaultThemeColor.button_primary_text,
        }}
      >
        {icon}
        <span
          className={Style.editText}
          style={{
            color:
              useColor("button_primary_text") ||
              defaultThemeColor.button_primary_text,
          }}
        >
          {name}
        </span>
      </button>
      {isVisible && (
        <div
          className="color-picker-container"
          style={{ position: "absolute", zIndex: 1 }}
          ref={ref}
        >
          <ChromePicker color={color} onChange={handleColorChange} />
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
