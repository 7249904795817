import { useConfirm } from "material-ui-confirm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DataTable from "../../components/table/DataTable";
import TableFilters from "../../components/table/TableFilters";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import usePermission from "../../hooks/usePermission";
import DeleteSVG from "../../icons/DeleteSVG";
import { bulkDeleteGroups } from "../../services/groupManagementService";
import { updatePolicyAssessment } from "../../services/policyAssessmentService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { groupManagementSelector } from "../../store/reducers/groupManagementReducer";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import { confirmProps } from "../../utils/confirmProps";
import Style from "./PolicyListing.module.scss";

export interface SimpleDialogProps {
  setSelectedRows: any;
  selectedRows: any;
  setDefaultRows: any;
  defaultRows: any;
  id?: any;
  assessment?: any;
}

function AssessmentMembers(props: SimpleDialogProps) {
  const {
    setSelectedRows,
    selectedRows,
    setDefaultRows,
    defaultRows,
    id,
    assessment,
  } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const { type } = useParams();

  const addPermission = usePermission("add_customusergroup");
  const editUpdatePermission = usePermission("change_customusergroup");
  const deletePermission = usePermission("delete_customusergroup");
  const viewPermission = usePermission("view_customusergroup");
  const [itemPerPage, setItemPerPage] = useState<number>(10);
  const [open, setOpen] = useState(false);
  const [searchTable, setSearchTable] = useState("");

  const [list, setList] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<any[]>([]);
  // const [page, setPage] = useState(1);
  const states = useSelector(groupManagementSelector).departmentReducer;
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;
  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  const [rows, setRows] = React.useState<any[]>([]);
  const [listCSV, setListCSV] = useState([]);

  const [page, setPage] = useState<number>(1);

  const dataList = useSelector(
    (state: RootState) => state.groupManagementReducer.data
  );

  const handleSearchChange = (searchTerm: any) => {
    let data: any = selectedRows?.results ? selectedRows?.results : defaultRows;
    if (!searchTerm) {
      setRows(data);
    } else {
      const filteredRows = data?.filter(
        (row: any) =>
          row?.first_name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
          row?.last_name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
          row?.email?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
          row?.primary_role
            ?.toLowerCase()
            ?.includes(searchTerm?.toLowerCase()) ||
          row?.department?.name
            ?.toLowerCase()
            ?.includes(searchTerm?.toLowerCase())
      );
      setRows(filteredRows);
    }
  };

  useEffect(() => {
    setPage(1);
  }, []);

  useEffect(() => {
    // dispatch(
    //   getListData({
    //     page_size: Number(itemPerPage),
    //     page: Number(page),
    //     search: searchTable,
    //   })
    // );
  }, [dispatch, itemPerPage, page, searchTable]);

  useEffect(() => {
    let data: any = selectedRows.results;
    if (Object.keys(selectedRows).length === 0) {
      setRows([]);
    } else setRows(data);
  }, [selectedRows]);

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      setSelectedCheckBox([]);
      // dispatch(
      //   getListData({
      //     page_size: Number(itemPerPage),
      //     page: Number(page),
      //     search: searchTable,
      //   })
      // );
    }
  }, [alertMessage]);

  useEffect(() => {
    handleSearchChange(searchTable);
  }, [searchTable]);

  useEffect(() => {
    if (defaultRows) {
      let data: any = defaultRows;
      if (Object.keys(defaultRows).length === 0) {
        setRows([]);
      } else setRows(data);
    }
  }, [defaultRows]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteAction = (row: any) => {
    confirm({
      title: t("Are you sure!"),
      description: t(`Do you want to delete member?`),
      ...confirmProps,
    })
      .then(() => {
        if (selectedRows?.length === 0) {
          setDefaultRows(defaultRows.filter((item: any) => item.id != row?.id));
          let up = defaultRows.filter((item: any) => item.id != row?.id);
          let ids = up?.map((i: any) => i.id);
          let f_payload = {
            shuffle_order: assessment?.shuffle_order,
            members: ids,
          };
          dispatch(
            updatePolicyAssessment({
              ...f_payload,
              id: id,
            })
          );
        } else {
          const updatedDataList = {
            ...selectedRows,
            count: selectedRows.count - 1,
            results: selectedRows.results.filter(
              (item: any) => item.id != row?.id
            ),
          };

          let ids = updatedDataList?.results.map((i: any) => i.id);
          let f_payload = {
            shuffle_order: assessment?.shuffle_order,
            members: ids,
          };
          dispatch(
            updatePolicyAssessment({
              ...f_payload,
              id: id,
            })
          );
          setSelectedRows(updatedDataList);
        }
      })
      .catch(() => {
        // toast.success("Your request canceled successfully!", {
        //   toastId: "Your request canceled successfully!",
        // });
      });
    return;
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setSelectedCheckBox([]);
    setPage(page);
  };
  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPage(1);
    setSelectedCheckBox([]);
    setItemPerPage(Number(rowsPerPage));
  };

  useEffect(() => {
    modifyRows();
  }, [rows]);

  const bulkDelete = (ids: any) => {
    // const sendSelected = selected.map((sel) => sel);
    confirm({
      title: t("Are you sure!"),
      description: t(`Do you want to delete selected groups?`),
      ...confirmProps,
    })
      .then(() => {
        dispatch(bulkDeleteGroups(ids));
        setSelectedCheckBox([]);
      })
      .catch(() => {});
  };

  const modifyRows = () => {
    let f_rows: any = [];
    type !== "view"
      ? rows.forEach(
          (
            r: {
              id: string;
              first_name: string;
              last_name: string;
              email: string;
              primary_role: string;
              department: any;
            },
            i: number
          ) => {
            f_rows = [
              ...f_rows,
              {
                id: r.id,
                first_name: r?.first_name + " " + r?.last_name,
                email: r?.email,
                role: r?.primary_role,
                departmnet: r?.department?.name || "N/A",
                actions: (
                  <div style={{ minWidth: "13em" }}>
                    {type !== "view" && (
                      <button
                        className="btn-link"
                        type="button"
                        onClick={() => {
                          handleDeleteAction(r);
                        }}
                      >
                        <DeleteSVG color="red" />
                      </button>
                    )}
                  </div>
                ),
              },
            ];
          }
        )
      : rows.forEach(
          (
            r: {
              id: string;
              first_name: string;
              last_name: string;
              email: string;
              primary_role: string;
              department: any;
            },
            i: number
          ) => {
            f_rows = [
              ...f_rows,
              {
                id: r.id,
                first_name: r?.first_name + " " + r?.last_name,
                email: r?.email,
                role: r?.primary_role,
                departmnet: r?.department?.name || "N/A",
              },
            ];
          }
        );
    setList(f_rows);
  };

  const AddMember = () => {
    const handleAddNew = () => {
      setOpen(true);
    };

    const interfaceForegroundTextColor =
      useColor("interface_foreground_text_color") ||
      defaultThemeColor.interface_foreground_text_color;

    return <div className="d-flex flex-row gap-3"></div>;
  };

  const handleClicked = () => {
    if (list.length == 0) {
      toast.error(String("Nothing to export..."), {
        toastId: String("Nothing to export..."),
      });
    }
    setListCSV(list);
  };

  return (
    <>
      <div className="mt-5">
        <div
          className={`${Style.header} mb-3`}
          style={{
            color:
              useColor("interface_foreground_text_color") ||
              defaultThemeColor.interface_foreground_text_color,
          }}
        >
          {t("Members")}
        </div>
        <TableFilters
          dataList={selectedRows}
          itemPerPage={itemPerPage}
          setItemPerPage={setItemPerPage}
          selectedCheckBox={selectedCheckBox}
          bulkDelete={bulkDelete}
          setSearchTable={setSearchTable}
          createButton={<AddMember />}
          handle="addgroup"
          exportCSV={false}
          setParentData={() => setListCSV([])}
          handleClicked={handleClicked}
          rows={listCSV?.map((r: any, i: any) => ({
            // id: r.id,
            first_name: r?.first_name,
            email: r?.email,
            role: r?.primary_role,
            departmnet: r?.department?.name || "N/A",
          }))}
          headers={["Username", "Email", "Roles", "Department"]}
          filename={`members_list_of_training_id_${id}.csv`}
        />

        <div style={{ marginRight: "0.7em" }}>
          <DataTable
            columnData={
              type !== "view"
                ? [
                    {
                      id: "first_name",
                      name: t("Username"),
                      enableSort: true,
                      align: lang === "ar" ? "center" : "left",
                      disablePadding: "none",
                    },

                    {
                      id: "email",
                      name: t("Email"),
                      enableSort: true,
                      align: lang === "ar" ? "center" : "left",
                      disablePadding: "none",
                    },
                    {
                      id: "roles",
                      name: t("Roles"),
                      enableSort: true,
                      align: lang === "ar" ? "center" : "left",
                      disablePadding: "none",
                    },
                    {
                      id: "department",
                      name: t("Department"),
                      enableSort: false,
                      align: "left",
                      disablePadding: "checkbox",
                    },
                    {
                      id: "actions",
                      name: t("Actions"),
                      enableSort: false,
                      align: "center",
                      disablePadding: "none",
                    },
                  ]
                : [
                    {
                      id: "first_name",
                      name: "Username",
                      enableSort: true,
                      align: "left",
                      disablePadding: "none",
                    },

                    {
                      id: "email",
                      name: "Email",
                      enableSort: true,
                      align: "left",
                      disablePadding: "none",
                    },
                    {
                      id: "roles",
                      name: "Roles",
                      enableSort: true,
                      align: "left",
                      disablePadding: "none",
                    },
                    {
                      id: "department",
                      name: "Department",
                      enableSort: false,
                      align: "left",
                      disablePadding: "checkbox",
                    },
                  ]
            }
            rows={
              list
                ? list?.slice(
                    (Number(page) - 1) * itemPerPage,
                    Number(page) * itemPerPage
                  )
                : []
            }
            isCheckboxes={false}
            selectedRowsPerPage={itemPerPage.toString()}
            totalRecordCount={list && list?.length}
            handleChangePageParent={handleChangePage}
            pagenum={Number(page)}
            handleChangeRowsPerPageParent={handleChangeRowsPerPage}
            bulkDelete={bulkDelete}
            selectedCheckBox={selectedCheckBox}
            setSelectedCheckBox={setSelectedCheckBox}
            // isSelectedRowsOnly={true}
          />
          {/* <CreateMemberModal
            setParentOpen={setOpen}
            open={open}
            onClose={handleClose}
            isLoading={loading}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            defaultRows={defaultRows}
          /> */}
        </div>
      </div>
    </>
  );
}

export default AssessmentMembers;
