// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddAutoGroup_badge__Cm0HL {
  padding: 8px !important;
  background-color: #0089cf;
  border-radius: 7px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/groupManagement/AddAutoGroup.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,yBAAA;EACA,6BAAA;AACF","sourcesContent":[".badge {\n  padding: 8px !important;\n  background-color: #0089cf;\n  border-radius: 7px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": `AddAutoGroup_badge__Cm0HL`
};
export default ___CSS_LOADER_EXPORT___;
