import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

const videoData = JSON.parse(localStorage.getItem("video_info") || "{}");

export interface DataList {
  count: number;
  next: string | null;
  previous: string | null;
  results: any[];
}

export interface States {
  data: DataList;
  wholeData: DataList | any;
  dataModule: DataList;
  wholedataModule: DataList | any;
  dataMember: DataList;
  success?: string;
  error?: string;
  errors?: any;
  page?: number;
  trainingData?: any;
  imageData?: any;
  progressReportData?: any;
  wholedataMember?: any;
  recordData?: any;
  videoData?: any;
}

export const initialState: States = {
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  wholeData: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  dataModule: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  wholedataModule: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  dataMember: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  wholedataMember: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  trainingData: {},
  recordData: {},
  imageData: {},
  page: 1,
  videoData: videoData,
};

export const trainingsSlice = createSlice({
  name: "trainings",
  initialState,
  reducers: {
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setWholeData: (state, { payload }) => {
      state.wholeData = payload;
    },
    setRecordData: (state, { payload }) => {
      state.recordData = payload;
    },
    setDataModule: (state, { payload }) => {
      state.dataModule = payload;
    },
    setWholeDataModule: (state, { payload }) => {
      state.wholedataModule = payload;
    },
    setTrainingData: (state, { payload }) => {
      state.trainingData = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setImageData: (state, { payload }) => {
      state.imageData = payload;
    },
    setDataMember: (state, { payload }) => {
      state.dataMember = payload;
    },
    setWholeDataMember: (state, { payload }) => {
      state.wholedataMember = payload;
    },
    setProgressReportData: (state, { payload }) => {
      state.progressReportData = payload;
    },
    setVideoData: (state, { payload }) => {
      localStorage.setItem("video_info", JSON.stringify(payload));
      state.videoData = payload;
    },
  },
});

export const {
  setData,
  setWholeData,
  setRecordData,
  setPage,
  setDataModule,
  setWholeDataModule,
  setDataMember,
  setWholeDataMember,
  setTrainingData,
  setImageData,
  setProgressReportData,
  setVideoData,
} = trainingsSlice.actions;

export const trainingsSelector = (state: RootState) => state;

export default trainingsSlice.reducer;
