// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExportCSV_downloadbtn__gJVoj:hover {
  color: #2b3674;
}

.ExportCSV_downloadbtn__gJVoj {
  color: #4b4b4b;
}`, "",{"version":3,"sources":["webpack://./src/components/ExportCSV/ExportCSV.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":[".downloadbtn:hover {\n  color: #2b3674;\n}\n\n.downloadbtn {\n  color: #4b4b4b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downloadbtn": `ExportCSV_downloadbtn__gJVoj`
};
export default ___CSS_LOADER_EXPORT___;
