import axiosPrivate from "../lib/axiosPrivate";
import { setLoading } from "../store/reducers/loaderReducer";
import { AppThunk } from "../store/store";

import { setErr } from "../store/reducers/alertMessageReducer";
import { setData, setUpdatedData } from "../store/reducers/notificationReducer";
import { getUserData } from "./userService";

export interface GetAllData {
  page: number;
  page_size: number;
}

export const getAllNotifications =
  (payload: GetAllData): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setData({}));
    try {
      await axiosPrivate
        .get(
          `notifications/all/?page=${payload.page}&page_size=${payload.page_size}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setData(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getReadNotifications =
  (payload: GetAllData): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setData({}));
    try {
      await axiosPrivate
        .get(
          `notifications/all/?page=${payload.page}&page_size=${payload.page_size}&read=true`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setData(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getUnreadNotifications =
  (payload: GetAllData): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setData({}));
    try {
      await axiosPrivate
        .get(
          `notifications/all/?page=${payload.page}&page_size=${payload.page_size}&read=false`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setData(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const updateNotificationStatus =
  (payload: any): AppThunk =>
  async (dispatch) => {
    // dispatch(setLoading(true));
    try {
      await axiosPrivate
        .patch(`notifications/${payload?.id}/`, { read: payload?.read })
        .then((response) => {
          dispatch(getUserData());
          dispatch(setLoading(false));
          const data = response.data;
          dispatch(setUpdatedData(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error.response.data.detail));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const markAllAsRead =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .post(`notifications/mark_all_as_read/`, { read: payload?.read })
        .then((response) => {
          dispatch(setLoading(false));
          const data = response.data;
          dispatch(setUpdatedData(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error.response.data.detail));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };
