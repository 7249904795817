import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import DataTable from "../../components/table/DataTable";
import {
  deleteCertifiicate,
  getCertificateListData,
  getWholeCertificateListData,
} from "../../services/certificateManagementService";
import { RootState, useAppDispatch } from "../../store/store";
import Style from "./CertificatesManagement.module.scss";

import AddIcon from "@mui/icons-material/Add";
import React from "react";
import { toast } from "react-toastify";
import DeleteSVG from "../../icons/DeleteSVG";
import EditSVG from "../../icons/EditSVG";
import {
  alertMessageSelector,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";

import { Button } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { useTranslation } from "react-multi-lang";
import TableFilters from "../../components/table/TableFilters";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import usePermission from "../../hooks/usePermission";
import ViewSVGAction from "../../icons/ViewSVGAction";
import {
  setPage,
  setPerPage,
  setSearch,
  setWholeData,
} from "../../store/reducers/certificateManagementReducer";
import { defaultThemeColor } from "../../utils/colors_values";
import { confirmProps } from "../../utils/confirmProps";

const CertificatesManagement = () => {
  const addPermission = usePermission("add_certificate");
  const editUpdatePermission = usePermission("change_certificate");
  const deletePermission = usePermission("delete_certificate");
  const viewPermission = usePermission("view_certificate");
  const searchParams = new URLSearchParams(document.location.search);
  const perPage = useSelector(
    (state: RootState) => state.certificateManagementReducer.perPage
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const confirm = useConfirm();
  const [rows, setRows] = useState([]);
  const [list, setList] = useState([]);
  const [pageNum, setPageNum] = useState(Number(searchParams.get("page") || 1));
  const [perPageNum, setPerPageNum] = useState(
    Number(searchParams.get("perpage")) || 10
  );
  const [pageSearch, setPageSearch] = useState(
    searchParams.get("search") || ""
  );
  const dataList = useSelector(
    (state: RootState) => state.certificateManagementReducer.data
  );
  const wholedataList = useSelector(
    (state: RootState) => state.certificateManagementReducer.wholedata
  );
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;
  useEffect(() => {
    dispatch(setPage(Number(searchParams.get("page") || 1)));
    dispatch(setPerPage(Number(searchParams.get("perpage") || 10)));
    dispatch(setSearch(String(searchParams.get("search"))));
    let query: any = {};
    query.page = Number(searchParams.get("page") || 1);
    query.page_size = Number(searchParams.get("perpage") || 10);
    query.search = String(searchParams.get("search") || "");
    dispatch(getCertificateListData(query));
  }, [
    dispatch,
    searchParams.get("page"),
    searchParams.get("perpage"),
    searchParams.get("search"),
  ]);

  useEffect(() => {
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      dispatch(
        getCertificateListData({
          page_size: Number(perPageNum),
          page: Number(pageNum),
          search: pageSearch,
        })
      );
    }
  }, [alertMessage]);
  const urlWithQueryString = (
    pageVal = Number(pageNum),
    perPageVal = Number(perPageNum),
    searchVal = String(pageSearch)
  ) => {
    let object: any = {};
    if (pageVal) {
      object.page = pageVal;
    }
    if (perPageVal) {
      object.perpage = perPageVal;
    }
    if (searchVal) {
      object.search = searchVal;
    }

    const objString = "?" + new URLSearchParams(object).toString();

    navigate(`/certificates-management${objString}`);
  };
  useEffect(() => {
    let data: any = dataList.results;
    if (Object.keys(dataList).length === 0) {
      setRows([]);
    } else setRows(data);
  }, [dataList]);

  useEffect(() => {
    modifyRows();
  }, [rows]);

  const handleChangePage = (event: React.ChangeEvent<unknown>, val: number) => {
    setPageNum(Number(val));
    urlWithQueryString(Number(val), Number(perPage), pageSearch);
  };

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPageNum(1);
    setPerPageNum(Number(rowsPerPage));
    urlWithQueryString(1, Number(rowsPerPage), pageSearch);
  };
  const handleChangeSearch = (val: string) => {
    setPageNum(1);
    setPageSearch(String(val));
    setPerPageNum(Number(10));
    urlWithQueryString(1, perPageNum, String(val));
  };

  const handleDeleteAction = (row: any) => {
    confirm({
      title: t("Are you sure!"),
      description: `Do you want to delete ${row?.name} certificate?`,
      ...confirmProps,
    })
      .then(() => {
        dispatch(deleteCertifiicate(row.id));
      })
      .catch(() => {});
    return;
  };

  const modifyRows = () => {
    let f_rows: any = [];
    rows.forEach(
      (
        r: {
          id: string;
          name: string;
          created_at: string;
          training: any;
        },
        i: number
      ) => {
        f_rows = [
          ...f_rows,
          {
            id: r.id,
            name: r.name,

            created_at:
              moment(new Date(r.created_at)).format("MMM Do, YYYY") || "",

            actions: (
              <>
                <div style={{ textAlign: "center", paddingLeft: "1em" }}>
                  {viewPermission && (
                    <Link
                      className="btn-link"
                      to={`/detail-certificate/${r.id}`}
                    >
                      <ViewSVGAction />
                    </Link>
                  )}{" "}
                  {editUpdatePermission && (
                    <Link className="btn-link" to={`/edit-certificate/${r.id}`}>
                      <EditSVG />
                    </Link>
                  )}
                  {deletePermission && (
                    <button
                      className="btn-link"
                      type="button"
                      onClick={(e) => {
                        handleDeleteAction(r);
                      }}
                    >
                      <DeleteSVG color="red" />
                    </button>
                  )}
                </div>
              </>
            ),
          },
        ];
      }
    );
    setList(f_rows);
  };
  const handleAddNew = () => {
    navigate("/add-certificate");
  };
  const interfaceBgColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;
  const AddCertificateButton = () => {
    return addPermission ? (
      <Button
        sx={{ textTransform: "none" }}
        variant="text"
        color="inherit"
        endIcon={
          <AddIcon
            style={{
              fontSize: 16,
              color: interfaceBgColor,
              marginRight: lang === "ar" ? "10px" : "0px",
            }}
          />
        }
        style={{ fontSize: 13, color: interfaceBgColor }}
        onClick={() => handleAddNew()}
      >
        {t("Add New")}
      </Button>
    ) : (
      <></>
    );
  };

  const handleClicked = () => {
    getWholeData();
  };

  const getWholeData = () => {
    if (dataList)
      dispatch(
        getWholeCertificateListData({
          page_size: dataList.count,
          page: 1,
          search: "",
        })
      );
  };

  return (
    <div className={`${Style.certificatesStart}`}>
      <Header
        page={t("Certificates Management")}
        mainHead={t("Certificates Management")}
      />

      <TableFilters
        dataList={dataList}
        itemPerPage={perPageNum}
        selectedCheckBox={[]}
        bulkDelete={(e: any) => function bulkDelete(e: any) {}}
        setItemPerPage={handleChangeRowsPerPage}
        createButton={<AddCertificateButton />}
        setSearchTable={handleChangeSearch}
        handle="certificates"
        exportCSV={false}
        setParentData={() => dispatch(setWholeData({}))}
        handleClicked={handleClicked}
        rows={wholedataList?.results?.map((r: any, i: any) => ({
          name: r.name,
          training: r?.training?.name || "N/A",
          created_at:
            moment(new Date(r.created_at)).format("MMM Do, YYYY") || "",
        }))}
        headers={["Name", "Associated Training", "Created At"]}
        filename={`certificates_list.csv`}
      />

      <DataTable
        columnData={[
          {
            id: "name",
            name: t("Name"),
            enableSort: true,
            align: lang === "ar" ? "center" : "left",
            disablePadding: "checkbox",
          },

          {
            id: "created_at",
            name: t("Created"),
            enableSort: true,
            align: lang === "ar" ? "center" : "left",
            disablePadding: "none",
          },

          {
            id: "actions",
            name: t("Actions"),
            enableSort: false,
            align: "center",
            disablePadding: "none",
          },
        ]}
        rows={list}
        isCheckboxes={false}
        selectedRowsPerPage={String(perPageNum)}
        totalRecordCount={dataList?.count}
        handleChangePageParent={handleChangePage}
        pagenum={pageNum}
        handleChangeRowsPerPageParent={handleChangeRowsPerPage}
        selectedCheckBox={[]}
      />
    </div>
  );
};

export default CertificatesManagement;
