import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface NotificationState {
  ownNotificationData?: any[];
  unread_notifications_count?: number;
  data: DataList;
  page?: number;
  perPage?: number;
  updateddata?: any;
}
export interface DataList {
  count: number;
  next: string | null;
  previous: String | null;
  results: any[];
}

export const initialState: NotificationState = {
  ownNotificationData: [],
  unread_notifications_count: 0,
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  updateddata: null,
  page: 1,
  perPage: 10,
};
export interface GetUser {
  id: any;
}

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setOwnNotification: (state, { payload }) => {
      state.ownNotificationData = payload;
    },
    setUnReadNotificationCount: (state, { payload }) => {
      state.unread_notifications_count = payload;
    },
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setUpdatedData: (state, { payload }) => {
      state.updateddata = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setPerPage: (state, { payload }) => {
      state.perPage = payload;
    },
  },
});

export const {
  setOwnNotification,
  setUnReadNotificationCount,
  setData,
  setPage,
  setPerPage,
  setUpdatedData,
} = notificationSlice.actions;

export const userSelector = (state: RootState) => state;

export default notificationSlice.reducer;
