import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Modal, Typography } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import SubmitButton from "../../../components/SubmitButton";
import useColor from "../../../hooks/useColor";
import { addData, updateData } from "../../../services/categoryService";
import {
  alertMessageSelector,
  setErrors,
} from "../../../store/reducers/alertMessageReducer";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { defaultThemeColor } from "../../../utils/colors_values";
import Style from "./CategoryModal.module.scss";
import CancelButton from "../../../components/CancelButton";
import { Dialog, DialogContent } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-multi-lang";
import useCompanyLanguage from "../../../hooks/language";

const schema = yup
  .object({
    name: yup
      .string()
      .required("Category name is required")
      .max(50, "Category name must be at most 50 characters"),
  })
  .required();

// const style = {
//   position: "absolute" as "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 500,
//   bgcolor: "background.paper",
//   border: "2px solid #fff",
//   boxShadow: 24,
//   p: 4,
// };
interface Params {
  openEditModal: boolean;
  CloseEditModal: any;
  defaultFormValues: any;
  modalHeading: string;
  modalButtonLable: string;
}
function CategoryModal(props: Params) {
  const {
    openEditModal,
    CloseEditModal,
    defaultFormValues,
    modalHeading,
    modalButtonLable,
  } = props;

  const {
    reset,
    setError,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const alertMessage = useAppSelector(alertMessageSelector).alertMessageReducer;
  const urlWithQueryString = (pageVal = 1, perPageVal = 10, searchVal = "") => {
    let object: any = {};
    if (pageVal) {
      object.page = pageVal;
    }
    if (perPageVal) {
      object.perpage = perPageVal;
    }
    if (searchVal) {
      object.search = searchVal;
    }

    const objString = "?" + new URLSearchParams(object).toString();

    navigate(`/category${objString}`);
  };
  const Submit = (payload: any) => {
    if (defaultFormValues?.id) {
      dispatch(updateData(Number(defaultFormValues?.id), payload));
      urlWithQueryString();
      return;
    }
    dispatch(addData(payload));
    urlWithQueryString();
    return;
  };

  useEffect(() => {
    if (alertMessage?.errors) {
      if (alertMessage?.errors?.name?.length > 0) {
        toast.error(alertMessage?.errors?.name?.toString(), {
          toastId: alertMessage?.errors?.name?.toString(),
        });
        setError("name", {
          type: "server",
          message: alertMessage?.errors?.name?.toString(),
        });
      }

      if (alertMessage?.errors) {
        dispatch(setErrors(null));
      }
    }
  }, [alertMessage]);

  useEffect(() => {
    let defaultValues = {
      name: defaultFormValues?.name,
    };
    reset({ ...defaultValues });
  }, [defaultFormValues]);

  const foregroundBgColor =
    useColor("interface_foreground_color") ||
    defaultThemeColor.interface_foreground_color;
  const foregroundTextColor =
    useColor("interface_foreground_text_color") ||
    defaultThemeColor.interface_foreground_text_color;
  return (
    <>
      <Dialog onClose={CloseEditModal} maxWidth={"lg"} open={openEditModal}>
        <DialogContent
          className="mb-3"
          style={{
            background: foregroundBgColor,
            color: foregroundTextColor,
            border: "none",
            paddingRight: 10,
            paddingTop: 0,
            paddingBottom: 0,
            direction: lang === "ar" ? "rtl" : "ltr",
          }}
        >
          <div className="d-flex justify-content-between ">
            <DialogTitle
              className="mt-4"
              style={{
                fontSize: "20px",
                padding: 0,
                color:
                  useColor("interface_foreground_text_color") ||
                  defaultThemeColor.interface_foreground_text_color,
                background:
                  useColor("interface_foreground_color") ||
                  defaultThemeColor.interface_foreground_color,
              }}
            >
              {t(modalHeading)}
            </DialogTitle>{" "}
            <div className="p-2 px-0">
              <CloseIcon sx={{ cursor: "pointer" }} onClick={CloseEditModal} />
            </div>
          </div>

          <form
            onSubmit={handleSubmit(Submit)}
            autoComplete="off"
            className="mb-5"
            style={{ background: foregroundBgColor, border: "none" }}
          >
            <div
              className={`${Style.col6} px-0 mt-3`}
              style={{
                paddingRight: "1rem",
                marginRight: lang === "ar" ? "0rem" : "2em",
              }}
            >
              <span className={Style.labelHeader}>
                {t("Category Name")}
                <span style={{ color: "red" }}> *</span>
              </span>
              <input
                className={`form-input ${errors.name ? "is-invalid" : ""}`}
                style={{
                  background:
                    useColor("interface_background_color") ||
                    defaultThemeColor.interface_background_color,
                  color:
                    useColor("interface_background_text_color") ||
                    defaultThemeColor.interface_background_text_color,
                }}
                type="text"
                placeholder={t("Name")}
                autoComplete="off"
                {...register("name")}
              />
              <p className="error color-red">{errors.name?.message}</p>
            </div>

            <div className="d-flex flex-wrap gap-3 w-100 px-3 align-items-center justify-content-end mb-5 mt-5">
              <SubmitButton label={t(modalButtonLable)} marginTop={false} />
              <CancelButton
                size="large"
                outlined={true}
                onClick={CloseEditModal}
                textColor=""
                outlineColor={""}
                text={t("Cancel")}
                className={` ${Style.cancelBtn}`}
                bg={""}
              ></CancelButton>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CategoryModal;
