import axiosPrivate from "../lib/axiosPrivate";
import { setLoading } from "../store/reducers/loaderReducer";
import { AppThunk } from "../store/store";

import {
  setErr,
  setErrors,
  setSuccess,
} from "../store/reducers/alertMessageReducer";
import {
  setData,
  setDetailData,
  setMembersData,
  setWholeData,
  setWholeMembersData,
} from "../store/reducers/groupManagementReducer";

export interface AddGroup {
  name: string;
  user_ids?: [];
}

export interface GetData {
  page: number;
  page_size: number;
  search: string;
}

export const getListData =
  (payload: GetData): AppThunk =>
  async (dispatch) => {
    dispatch(setData({}));
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .get(
          `groups/?page=${payload.page}&page_size=${payload.page_size}&search=${payload.search}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setData(data));
          dispatch(setLoading(false));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getWholeGroupListData =
  (payload: GetData): AppThunk =>
  async (dispatch) => {
    dispatch(setWholeData({}));
    try {
      await axiosPrivate
        .get(
          `groups/?page=${payload.page}&page_size=${payload.page_size}&search=${payload.search}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setWholeData(data));
        })
        .catch((error) => {
          dispatch(setErr(error.response.data.detail));
        });
    } catch (error: any) {
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const addGroup =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .post(`groups/`, payload)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setSuccess("Group saved successfully!"));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErrors(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErrors("Something went wrong, Please try again"));
    }
  };

const loadOptions = async (
  searchQuery: any,
  loadedOptions: any,

  { page }: any,
  excludeIds?: any
) => {
  const response = await axiosPrivate.get(
    `accounts/users/?page=${page}&page_size=100&search=${searchQuery}`
  );
  const data = response.data;
  const options = data?.results.map((row: any) => {
    return {
      label:
        row?.first_name + " " + row?.last_name + " (" + row.email + ")" ||
        "N/A",
      value: row.id,
    };
  });
  const users = {
    options: options || [],
    hasMore: data?.next ? true : false,
    additional: {
      page: searchQuery ? 1 : page + 1,
    },
  };

  return users;
};

export default loadOptions;

export const deleteGroup =
  (id: Number): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .delete(`groups/${id}/`)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getGroup =
  (id: Number): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setDetailData({}));
    try {
      await axiosPrivate
        .get(`groups/${id}/`)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setDetailData(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getGroupMembers =
  (id: number, payload: GetData): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(
      setMembersData({
        count: 0,
        next: "",
        previous: "",
        results: [],
      })
    );
    try {
      await axiosPrivate
        .get(
          `groups/${id}/members/?page=${payload.page}&page_size=${payload.page_size}&search=${payload.search}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setMembersData(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(
            setMembersData({
              count: 0,
              next: "",
              previous: "",
              results: [],
            })
          );
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(
        setMembersData({
          count: 0,
          next: "",
          previous: "",
          results: [],
        })
      );
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getWholeGroupMembers =
  (id: number, payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(
      setWholeMembersData({
        count: 0,
        next: "",
        previous: "",
        results: [],
      })
    );
    try {
      await axiosPrivate
        .get(
          `groups/${id}/members/?page=${payload.page}&page_size=${payload.page_size}&search=${payload.search}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setWholeMembersData(data));
        })
        .catch((error) => {
          dispatch(
            setWholeMembersData({
              count: 0,
              next: "",
              previous: "",
              results: [],
            })
          );
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(
        setWholeMembersData({
          count: 0,
          next: "",
          previous: "",
          results: [],
        })
      );
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const bulkDeleteGroups =
  (payload: any[]): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .delete(`groups/bulk/`, {
          data: JSON.stringify({ group_ids: payload }),
        })
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const deleteGroupMember =
  (id: Number, payload: string[]): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .post(`groups/${id}/remove_users/`, { user_ids: payload })
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const updateGroupName =
  (id: Number, payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .put(`groups/${id}/`, payload)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setSuccess("Group name updated successfully!"));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const updateGroupMember =
  (id: Number, payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .post(`groups/${id}/add_user/`, payload)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const addAutoGroup =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .post(`groups_auto/`, payload)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setSuccess("Group saved successfully!"));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErrors("Something went wrong, Please try again"));
    }
  };
export const updateAutoGroup =
  (id: number, payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosPrivate
        .put(`groups_auto/${id}/`, payload)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setSuccess("Group updated successfully!"));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErrors("Something went wrong, Please try again"));
    }
  };
export const getWholeGroupListData3 = (): AppThunk => async (dispatch) => {
  dispatch(setWholeData({}));

  // dispatch(setLoading(true));
  try {
    await axiosPrivate
      .get(`groups/?page=1&page_size=10`)
      .then(async (response) => {
        await axiosPrivate
          .get(`groups/?page=${1}&page_size=${response?.data?.count}`)
          .then((response) => {
            const data = response.data;
            dispatch(setWholeData(data));
          });
      })
      .catch((error) => {
        dispatch(setErr(error.response.data.detail));
      });
  } catch (error: any) {
    dispatch(setErr("Something went wrong, Please try again"));
  }
};
