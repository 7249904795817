// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rs-picker-toggle {
  height: 3.45em;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.rs-picker-default .rs-btn,
.rs-picker-default .rs-picker-toggle,
.rs-picker-input .rs-btn,
.rs-picker-input .rs-picker-toggle {
  background-color: rgb(246, 249, 255);
}

.rs-picker-toggle-textbox {
  border: 1px solid rgba(0, 0, 0, 0.23);
}

.rs-btn-default {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.rs-stack-item {
  margin-top: 6px;
  font-size: 14px;
  color: grey;
}

.rs-picker-toggle-caret {
  margin-top: 6px;
}

.rs-picker-toggle-clean {
  margin-top: 6px;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #4a4a4a;
}
`, "",{"version":3,"sources":["webpack://./src/pages/training/CreateTraining/rsuite.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,8BAA8B;EAC9B,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;;;;EAIE,oCAAoC;AACtC;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;;EAEE,cAAc;AAChB","sourcesContent":[".rs-picker-toggle {\n  height: 3.45em;\n  display: flex;\n  justify-content: space-between;\n  align-content: center;\n  align-items: center;\n}\n\n.rs-picker-default .rs-btn,\n.rs-picker-default .rs-picker-toggle,\n.rs-picker-input .rs-btn,\n.rs-picker-input .rs-picker-toggle {\n  background-color: rgb(246, 249, 255);\n}\n\n.rs-picker-toggle-textbox {\n  border: 1px solid rgba(0, 0, 0, 0.23);\n}\n\n.rs-btn-default {\n  display: flex;\n  justify-content: space-between;\n  align-content: center;\n  align-items: center;\n}\n\n.rs-stack-item {\n  margin-top: 6px;\n  font-size: 14px;\n  color: grey;\n}\n\n.rs-picker-toggle-caret {\n  margin-top: 6px;\n}\n\n.rs-picker-toggle-clean {\n  margin-top: 6px;\n}\n\n.rs-picker-has-value .rs-btn .rs-picker-toggle-value,\n.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {\n  color: #4a4a4a;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
