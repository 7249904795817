import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import DataTable from "../../components/table/DataTable";
import TableFilters from "../../components/table/TableFilters";
import useCompanyLanguage from "../../hooks/language";
import useColor from "../../hooks/useColor";
import usePermission from "../../hooks/usePermission";
import AssignToGroupSVG from "../../icons/AssignToGroup";
import AssignToUserSVG from "../../icons/AssignToUserSVG";
import DeleteSVG from "../../icons/DeleteSVG";
import EditSVG from "../../icons/EditSVG";
import ViewResultsSVG from "../../icons/ViewResultsSVG";
import ViewSVGAction from "../../icons/ViewSVGAction";

import {
  bulkDeletePolicyAssessments,
  deletePolicyAssessment,
  getAll,
  getWholePolicyAssessments,
  updatePolicyAssessment,
} from "../../services/policyAssessmentService";
import {
  alertMessageSelector,
  setErr,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import {
  setPage,
  setPerPage,
  setSearch,
  setWholeData,
} from "../../store/reducers/policyAssessmentReducer";
import { RootState, useAppDispatch, useAppSelector } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import { confirmProps } from "../../utils/confirmProps";
import Style from "../Assessment/AssessmentList.module.scss";
import AssignToGroupModal from "./AssignToGroup";
import AssignToUserModal from "./AssignToUser";

function PolicyAssessment() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const t = useTranslation();
  const lang = useCompanyLanguage();

  const addPermission = usePermission("add_policyassessment");
  const editUpdatePermission = usePermission("change_policyassessment");
  const deletePermission = usePermission("delete_policyassessment");
  const viewPermission = usePermission("view_policyassessment");

  const wholeData = useAppSelector(
    (state) => state.policyAssessmentReducer.wholeData
  );

  const searchParams = new URLSearchParams(document.location.search);
  const [pageNum, setPageNum] = useState(Number(searchParams.get("page") || 1));
  const [id, setID] = useState();
  const [open, setOpen] = React.useState<any>(false);
  const [disableCheckboxes, setDisableCheckboxes] = React.useState<any>([]);
  const [save, setSave] = React.useState<any>(false);
  const [openGroup, setOpenGroup] = React.useState<any>(false);
  const [saveGroup, setSaveGroup] = React.useState<any>(false);

  const [perPageNum, setPerPageNum] = useState(
    Number(searchParams.get("perpage")) || 10
  );
  const [pageSearch, setPageSearch] = useState(
    searchParams.get("search") || ""
  );
  const [list, setList] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<any[]>([]);
  //   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  //   const open = Boolean(anchorEl);
  //   const [singleRowData, setSingleRowData] = useState();

  const isLoading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [addMember, setAddMember] = useState<any>(null);
  const [members, setMembers] = useState<any>([]);
  const [groups, setGroups] = useState<any>([]);
  const [defaultMemberRows, setDefaultMemberRows] = React.useState<any>([]);
  const [defaultGroupsRows, setDefaultGroupsRows] = React.useState<any>([]);
  const [selectedMemberRows, setSelectedMemberRows] = React.useState<any>([]);
  const [selectedGroupRows, setSelectedGroupRows] = React.useState<any>([]);

  const page = useSelector(
    (state: RootState) => state.policyAssessmentReducer.page
  );
  const perPage = useSelector(
    (state: RootState) => state.policyAssessmentReducer.perPage
  );
  const search = useSelector(
    (state: RootState) => state.policyAssessmentReducer.search
  );

  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;

  const dataList: any = useSelector(
    (state: RootState) => state.policyAssessmentReducer.data
  );

  useEffect(() => {
    dispatch(setPage(Number(searchParams.get("page") || 1)));
    dispatch(setPerPage(Number(searchParams.get("perpage") || 10)));
    dispatch(setSearch(String(searchParams.get("search"))));
    AllData();
  }, [
    dispatch,
    searchParams.get("page"),
    searchParams.get("perpage"),
    searchParams.get("search"),
  ]);

  useEffect(() => {
    setMembers({
      results:
        dataList && dataList?.results?.find((i: any) => i.id == id)?.members,
    });

    setGroups({
      results:
        dataList && dataList?.results?.find((i: any) => i.id == id)?.groups,
    });
  }, [id, dataList]);

  useEffect(() => {
    if (save && members && members?.results && members?.results.length > 0) {
      let assess = dataList.results.find((i: any) => i.id == id);
      let questions = assess?.questions.map((i: any) => i.id);
      let groups = assess?.groups.map((i: any) => i.id);

      let ids = members?.results.map((i: any) => Number(i.id));
      let f_payload = {
        shuffle_order: assess.shuffle_order,
        members: ids,
        questions: questions,
        groups: groups,
      };
      dispatch(
        updatePolicyAssessment(
          {
            ...f_payload,
            id: id,
          },
          "Policy assessment assigned to the user(s) successfully."
        )
      );
      setSave(false);
    }
  }, [save]);

  useEffect(() => {
    if (saveGroup && groups && groups?.results && groups?.results.length > 0) {
      let assess = dataList.results.find((i: any) => i.id == id);
      let ids = groups?.results.map((i: any) => i.id);
      let members = assess?.members.map((i: any) => i.id);

      let questions = assess?.questions.map((i: any) => i.id);

      let f_payload = {
        shuffle_order: assess.shuffle_order,
        groups: ids,
        members: members,
      };
      dispatch(
        updatePolicyAssessment(
          {
            ...f_payload,
            id: id,
            questions: questions,
          },
          "Policy assessment assigned to the user group(s) successfully."
        )
      );
      setSaveGroup(false);
    }
  }, [saveGroup]);

  const urlWithQueryString = (
    pageVal = Number(pageNum),
    perPageVal = Number(perPageNum),
    searchVal = String(pageSearch)
  ) => {
    let object: any = {};
    if (pageVal) {
      object.page = pageVal;
    }
    if (perPageVal) {
      object.perpage = perPageVal;
    }
    if (searchVal) {
      object.search = searchVal;
    }

    const objString = "?" + new URLSearchParams(object).toString();
    navigate(`/policy-assessment${objString}`);
  };

  useEffect(() => {
    if (alertMessage?.error) {
      toast.error(String(alertMessage?.error), {
        toastId: String(alertMessage?.error),
      });
      dispatch(setErr(null));
    }
    if (alertMessage?.success) {
      toast.success(String(alertMessage?.success), {
        toastId: String(alertMessage?.success),
      });
      dispatch(setSuccess(null));
      setSelectedCheckBox([]);
      let object: any = {};
      if (page) {
        object.page = page;
      }
      if (perPage) {
        object.page_size = perPage;
      }
      if (
        search !== null &&
        search !== "" &&
        search !== undefined &&
        search !== "null"
      ) {
        object.search = search;
      }
      AllData();
    }
  }, [alertMessage]);

  const handleChangePage = (event: React.ChangeEvent<unknown>, val: number) => {
    setPageNum(Number(val));
    urlWithQueryString(Number(val), Number(perPageNum), pageSearch);
  };

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setPageNum(1);
    setPerPageNum(Number(rowsPerPage));
    urlWithQueryString(1, Number(rowsPerPage), pageSearch);
  };

  const handleChangeSearch = (val: string) => {
    setPageNum(1);
    setPageSearch(String(val));
    setPerPageNum(Number(10));
    urlWithQueryString(1, perPageNum, String(val));
  };

  const AllData = () => {
    let query: any = {};
    query.page = Number(searchParams.get("page") || 1);

    query.page_size = Number(searchParams.get("perpage") || 10);

    query.search = String(searchParams.get("search") || "");
    dispatch(getAll(query));
  };

  const handleDeleteAction = (id: any) => {
    return confirm({
      title: t("Are you sure!"),
      description: t(`Do you want to delete policy assessment?`),
      ...confirmProps,
    })
      .then(() => {
        setAnchorEl(null);
        dispatch(deletePolicyAssessment(id));
      })
      .catch(() => {});
  };

  useEffect(() => {
    modifyRows();
  }, [dataList]);

  const bulkDelete = (ids: any) => {
    confirm({
      title: t("Are you sure!"),
      description: t(`Do you want to delete selected policy assessments?`),
      ...confirmProps,
    })
      .then(() => {
        dispatch(bulkDeletePolicyAssessments(ids));
        setSelectedCheckBox([]);
        urlWithQueryString(1, 10, pageSearch);
      })
      .catch(() => {
        setSelectedCheckBox([]);
      });
  };

  useEffect(() => {
    modifyRows();
  }, [anchorEl]);

  //   const handleClick = (event: React.MouseEvent<HTMLElement>, r: any) => {
  //     setSingleRowData(r);
  //     setAnchorEl(event.currentTarget);
  //   };
  //   const handleClose = () => {
  //     setAnchorEl(null);
  //   };
  const handleClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setID(row?.id);
    setAnchorEl(event.currentTarget);
    setDisableCheckboxes(row?.groups || []);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenGroup(false);
    setAnchorEl(null);
  };

  const modifyRows = () => {
    let f_rows: any = [];
    dataList?.results?.forEach(
      (
        r: {
          id: string;
          name: string;
          policy: any;
          category: any;
          //   individual_assessment: boolean;
          //   assessment_status: string;
          updated_at: string;
        },
        i: number
      ) => {
        f_rows = [
          ...f_rows,
          {
            id: r.id,
            name: r.name,
            policy: r?.policy?.name,
            category: r?.category?.name || "N/A",
            // category: r?.category?.name || "N/A",
            // type: r.individual_assessment ? "Standalone" : "Normal",
            date_modified:
              moment(new Date(r.updated_at)).format("MMM Do, YYYY") || "",
            // status: (
            //   <span
            //     className={
            //       ToPascalCase(r.assessment_status) == "Approved"
            //         ? Style.statusSuccess
            //         : Style.statusDraft
            //     }
            //   >
            //     {ToPascalCase(r.assessment_status)}
            //   </span>
            // ),
            actions:
              //   <div style={{ minWidth: "13em" }}>
              //     {viewPermission && (
              //       <button
              //         className="btn-link"
              //         type="button"
              //         onClick={() => {
              //           navigate("/assessments/assessment-detail/" + r?.id);
              //         }}
              //       >
              //         <ViewSVG />
              //       </button>
              //     )}
              //     {editUpdatePermission && (
              //       <button
              //         className="btn-link"
              //         type="button"
              //         onClick={() => {
              //           navigate("/assessments/update-assessment/" + r?.id);
              //         }}
              //       >
              //         <EditSVG />
              //       </button>
              //     )}
              //     {deletePermission && (
              //       <button
              //         className="btn-link"
              //         type="button"
              //         onClick={() => {
              //           handleDeleteAction(r);
              //         }}
              //       >
              //         <DeleteSVG color="red" />
              //       </button>
              //     )}
              //   </div>

              (viewPermission || editUpdatePermission || deletePermission) && (
                <>
                  <div className="col-3 text-center">
                    <IconButton
                      aria-label="more"
                      aria-controls={anchorEl ? "long-menu" : undefined}
                      aria-haspopup="true"
                      onClick={(e) => handleClick(e, r)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      className={Style.menu}
                      MenuListProps={{
                        "aria-labelledby": `long-button-${r?.id}`,
                      }}
                      id="long-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          width: "20ch",
                          boxShadow:
                            "0px 5px 5px -3px rgba(0,0,0,0.01), 0px 3px 6px 1px rgba(0,0,0,0.01), 0px 3px 6px 2px rgba(0,0,0,0.01)",
                        },
                      }}
                    >
                      {viewPermission && (
                        <MenuItem
                          onClick={(e: any) => {
                            navigate("/policy-assessment-detail/" + id);
                          }}
                        >
                          <ViewSVGAction />
                          <span className="px-2">{t("View")}</span>
                        </MenuItem>
                      )}
                      {editUpdatePermission && (
                        <MenuItem
                          onClick={() => {
                            navigate("/policy-assessment/update/" + id);
                          }}
                        >
                          <EditSVG /> <span className="px-2">{t("Edit")}</span>
                        </MenuItem>
                      )}
                      {editUpdatePermission && (
                        <MenuItem
                          onClick={(e) => {
                            setAnchorEl(null);
                            setOpen(true);
                          }}
                        >
                          <span>
                            <AssignToUserSVG color="#2B3674" />{" "}
                          </span>
                          <span className="px-1">{t("Assign to user")}</span>
                        </MenuItem>
                      )}
                      {editUpdatePermission && (
                        <MenuItem
                          onClick={(e) => {
                            setAnchorEl(null);
                            setOpenGroup(true);
                          }}
                        >
                          <span>
                            {" "}
                            <AssignToGroupSVG color="#2B3674" />
                          </span>
                          <span className="px-1">{t("Assign to group")}</span>
                        </MenuItem>
                      )}
                      {viewPermission && (
                        <MenuItem
                          onClick={(e) =>
                            navigate("/policy-assessment-result/" + id)
                          }
                        >
                          <span>
                            {" "}
                            <ViewResultsSVG color="#2B3674" />
                          </span>
                          <span className="px-1">{t("View Results")}</span>
                        </MenuItem>
                      )}
                      {deletePermission && (
                        <MenuItem onClick={(e) => handleDeleteAction(id)}>
                          <DeleteSVG color="red" />
                          <span className="px-2">{t("Remove")}</span>
                        </MenuItem>
                      )}
                    </Menu>
                  </div>
                </>
              ),
          },
        ];
      }
    );
    setList(f_rows);
  };

  const AddAssessment = () => {
    const handleAddNew = () => {
      navigate("/policy-assessment/add-new");
    };

    const interfaceBgTextColor =
      useColor("interface_background_text_color") ||
      defaultThemeColor.interface_background_text_color;

    return (
      <div className="d-flex flex-row gap-3">
        {addPermission && (
          <>
            <Button
              variant="text"
              color="inherit"
              endIcon={
                <AddIcon
                  style={{
                    fontSize: 16,
                    color: interfaceBgTextColor,
                    marginRight: lang === "ar" ? "10px" : "0px",
                  }}
                />
              }
              style={{
                fontSize: 13,
                color: interfaceBgTextColor,
                textTransform: "capitalize",
              }}
              onClick={() => handleAddNew()}
            >
              {t("Add New")}
            </Button>
          </>
        )}
      </div>
    );
  };

  const handleClicked = () => {
    getWhole();
  };

  const getWhole = () => {
    if (dataList)
      dispatch(
        getWholePolicyAssessments({
          page_size: dataList.count,
          page: 1,
          search: pageSearch,
        })
      );
  };

  return (
    <>
      <Header
        page={t("Policy Assessment List")}
        mainHead={t("Policy Management / Policy Assessment List")}
      />
      <TableFilters
        dataList={dataList}
        itemPerPage={perPageNum}
        setItemPerPage={handleChangeRowsPerPage}
        selectedCheckBox={selectedCheckBox}
        bulkDelete={bulkDelete}
        setSearchTable={handleChangeSearch}
        createButton={<AddAssessment />}
        handle="groups"
        isCheckboxes={deletePermission}
        exportCSV={dataList?.count ? true : false}
        setParentData={() => dispatch(setWholeData({}))}
        handleClicked={handleClicked}
        rows={wholeData?.results?.map((r: any, i: any) => ({
          name: r.name,
          policy: r?.policy?.name,
          category: r?.category?.name,
          description: r.description,

          date_modified:
            moment(new Date(r.updated_at)).format("MMM Do, YYYY") || "",
        }))}
        headers={[
          "Assessment Name",
          "Policy name",
          "Category",
          "Description",
          "Date Modified",
        ]}
        filename={`policy_assessment_list-${moment().format()}.csv`}
      />

      <DataTable
        columnData={[
          {
            id: "name",
            name: t("Assessment Name"),
            enableSort: true,
            align: lang === "ar" ? "center" : "left",
            disablePadding: "none",
          },

          {
            id: "policy",
            name: t("Policy Name"),
            enableSort: true,
            align: lang === "ar" ? "center" : "left",
            disablePadding: "none",
          },

          {
            id: "category",
            name: t("Category"),
            enableSort: true,
            align: lang === "ar" ? "center" : "left",
            disablePadding: "none",
          },
          //   {
          //     id: "type",
          //     name: "Type",
          //     enableSort: true,
          //     align: "left",
          //     disablePadding: "none",
          //   },

          {
            id: "date_modified",
            name: t("Date Modified"),
            enableSort: true,
            align: lang === "ar" ? "center" : "left",
            disablePadding: "none",
          },
          //   {
          //     id: "status",
          //     name: "Status",
          //     enableSort: false,
          //     align: "center",
          //     disablePadding: "checkbox",
          //   },

          {
            id: "actions",
            name: t("Actions"),
            enableSort: false,
            align: "left",
            disablePadding: "none",
          },
        ]}
        rows={list}
        isCheckboxes={deletePermission}
        selectedRowsPerPage={String(searchParams.get("perpage") || 10)}
        totalRecordCount={Number(dataList.count)}
        handleChangePageParent={handleChangePage}
        pagenum={Number(searchParams.get("page") || 1)}
        handleChangeRowsPerPageParent={handleChangeRowsPerPage}
        bulkDelete={bulkDelete}
        selectedCheckBox={selectedCheckBox}
        setSelectedCheckBox={setSelectedCheckBox}
      />

      {id && open && (
        <AssignToUserModal
          setParentOpen={setOpen}
          open={open}
          onClose={handleClose}
          isLoading={isLoading}
          setSelectedRows={setMembers}
          selectedRows={members}
          defaultRows={members}
          save={save}
          setSave={setSave}
        />
      )}
      {id && openGroup && (
        <AssignToGroupModal
          disableCheckboxes={disableCheckboxes}
          setParentOpen={setOpenGroup}
          open={openGroup}
          onClose={handleClose}
          isLoading={isLoading}
          setSelectedRows={setGroups}
          selectedRows={groups}
          defaultRows={groups}
          save={saveGroup}
          setSave={setSaveGroup}
        />
      )}
    </>
  );
}

export default PolicyAssessment;
