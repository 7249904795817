function ImageSVG(props: { color: string }) {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.3765 2.29503C16.6059 2.29503 16.7855 2.37086 16.9073 2.49267C17.0291 2.61448 17.1049 2.79402 17.1049 3.02346V13.5646C17.1049 13.7941 17.0291 13.9736 16.9073 14.0954C16.7855 14.2172 16.6059 14.2931 16.3765 14.2931H5.83532C5.60588 14.2931 5.42634 14.2172 5.30453 14.0954C5.18272 13.9736 5.10689 13.7941 5.10689 13.5646V3.02346C5.10689 2.79402 5.18272 2.61448 5.30453 2.49267C5.42634 2.37086 5.60588 2.29503 5.83532 2.29503H16.3765ZM16.3765 1.1166H5.83532C4.78621 1.1166 3.92846 1.97435 3.92846 3.02346V13.5646C3.92846 14.6138 4.78621 15.4715 5.83532 15.4715H16.3765C17.4256 15.4715 18.2834 14.6138 18.2834 13.5646V3.02346C18.2834 1.97435 17.4256 1.1166 16.3765 1.1166Z"
        fill={props.color}
        stroke={props.color}
        strokeWidth="0.3"
      />
      <path
        d="M16.6275 17.1793H3.07452C2.80743 17.1793 2.59652 17.091 2.45275 16.9472C2.30898 16.8034 2.2206 16.5925 2.2206 16.3254V2.77247C2.2206 2.58779 2.15839 2.42224 2.03863 2.30248C1.91887 2.18272 1.75332 2.12051 1.56864 2.12051C1.38397 2.12051 1.21841 2.18272 1.09865 2.30248C0.978894 2.42224 0.916681 2.58779 0.916681 2.77247V16.3254C0.916681 17.5126 1.88737 18.4833 3.07452 18.4833H16.6275C16.8121 18.4833 16.9777 18.421 17.0975 18.3013C17.2172 18.1815 17.2794 18.016 17.2794 17.8313C17.2794 17.6466 17.2172 17.4811 17.0975 17.3613C16.9777 17.2415 16.8121 17.1793 16.6275 17.1793Z"
        fill={props.color}
        stroke={props.color}
        strokeWidth="0.3"
      />
      <path
        d="M8.59609 5.18126C8.78789 5.18126 8.93606 5.24454 9.03591 5.34439C9.13576 5.44423 9.19903 5.59241 9.19903 5.7842C9.19903 5.976 9.13576 6.12417 9.03591 6.22402C8.93606 6.32387 8.78789 6.38714 8.59609 6.38714C8.4043 6.38714 8.25612 6.32387 8.15628 6.22402C8.05643 6.12417 7.99315 5.976 7.99315 5.7842C7.99315 5.59241 8.05643 5.44423 8.15628 5.34439C8.25612 5.24454 8.4043 5.18126 8.59609 5.18126ZM8.59609 4.12832C7.68501 4.12832 6.94021 4.87312 6.94021 5.7842C6.94021 6.69528 7.68501 7.44009 8.59609 7.44009C9.50717 7.44009 10.252 6.69528 10.252 5.7842C10.252 4.87312 9.50717 4.12832 8.59609 4.12832Z"
        fill={props.color}
        stroke={props.color}
        strokeWidth="0.3"
      />
      <path
        d="M6.12166 12.3322L6.13903 12.3486L6.1606 12.3587L6.22446 12.223L6.16063 12.3587L6.16066 12.3587L6.16073 12.3588L6.16094 12.3589L6.16155 12.3592L6.16355 12.3601L6.17051 12.3633L6.19492 12.3741C6.21523 12.3829 6.24352 12.3948 6.27523 12.4067C6.33041 12.4275 6.4237 12.4597 6.50077 12.4597C6.5815 12.4597 6.67635 12.4261 6.74721 12.3861C6.78452 12.365 6.82452 12.3371 6.85719 12.3016C6.87581 12.2814 6.89735 12.2528 6.91154 12.2169L9.25877 10.0899L10.6314 11.4624L10.6314 11.4624L10.6347 11.4655C10.8767 11.6932 11.2431 11.6932 11.485 11.4655L16.0902 7.13206C16.3373 6.8995 16.3373 6.53946 16.0902 6.3069C15.8482 6.07921 15.4818 6.07921 15.2399 6.3069L11.1059 10.197L9.73506 8.90698C9.4931 8.6793 9.12671 8.6793 8.88475 8.90698L6.12166 11.5071C5.87452 11.7396 5.87452 12.0997 6.12166 12.3322Z"
        fill={props.color}
        stroke={props.color}
        strokeWidth="0.3"
      />
    </svg>
  );
}

export default ImageSVG;
