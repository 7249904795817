/* eslint-disable eqeqeq */
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import { useState } from "react";
import { getLanguage, useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import useCompanyLanguage from "../hooks/language";
import useColor from "../hooks/useColor";
import Style from "../pages/Dashboard/AdminDashboard.module.scss";
import PolicyAcceptReject from "../pages/PolicyManagement/PolicyAcceptRejectDialogue";
import { updateNotificationStatus } from "../services/notificationService";
import { RootState, useAppDispatch } from "../store/store";
import { defaultThemeColor } from "../utils/colors_values";

const DashboardNotification = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const [selectedNotification, setSelectedNotification] = useState<any>();
  const userInfo = useSelector((state: any) => state.authReducer.loginData);
  const loading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );

  const ownNotifications = useSelector(
    (state: any) => state.notificationReducer.ownNotificationData
  );

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  const handleReadNotificationStatus = (noti: any) => {
    dispatch(
      updateNotificationStatus({
        id: noti?.id,
        read: true,
      })
    );
  };

  const handleNotificationDetail = (row: any) => {
    if (row?.notification_type == "NEW_POLICY_ASSIGNED") {
      setSelectedNotification(row);
      setOpen(true);
    } else if (
      (row?.notification_type === "NEW_PERMISSION" ||
        row?.notification_type === "UPDATE_PERMISSION") &&
      !userInfo?.is_superadmin &&
      userInfo?.primary_role === "Admin"
    ) {
      if (!row?.read) {
        handleReadNotificationStatus(row);
      }
      navigate(`/my-profile`);
      return;
    } else if (row?.notification_type === "NEW_POLICY_ASSESSMENT_ASSIGNED") {
      navigate(`/policy-assessment-start/${row?.instance?.id}`);
      return;
    } else if (!row?.read) {
      handleReadNotificationStatus(row);
    }
    return;
  };

  const handleClose = (open: any) => {
    setOpen(false);
    setSelectedNotification("");
  };

  return (
    <div className={Style.recentNotification}>
      <div
        className={Style.notificationHeading}
        style={{ color: interfaceTextColor }}
      >
        {t("Recent Notification")}
      </div>
      <div
        className={Style.notificationContainer}
        style={{ paddingTop: "1rem" }}
      >
        {ownNotifications.length > 0 ? (
          ownNotifications?.map((row: any) => {
            return (
              <div
                className={
                  row?.read
                    ? Style.flexWrapper
                    : Style.flexWrapper + " " + Style.unreadNotification
                }
                key={row?.id}
                onClick={() => {
                  handleNotificationDetail(row);
                }}
                style={{ cursor: "pointer" }}
              >
                <div
                  className={Style.assess}
                  style={{
                    paddingLeft: "10px",
                    width: "100%",
                  }}
                >
                  <div
                    className={Style.assessmentNum}
                    style={{ direction: lang === "ar" ? "initial" : "ltr" }}
                    dangerouslySetInnerHTML={{
                      __html:
                        getLanguage() === "ar"
                          ? row?.instance?.arabic_content
                          : row?.instance?.content,
                    }}
                  ></div>

                  <div
                    style={{
                      textAlign: "right",
                      paddingRight: "17px",
                      marginTop: "10px",
                    }}
                  >
                    <Tooltip
                      title={moment(new Date(row.created_at)).format(
                        "MMM Do, YYYY HH:mm:ss"
                      )}
                      placement="top"
                    >
                      <span>{moment(row.created_at).fromNow()}</span>
                    </Tooltip>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p className={Style.notificationItem} style={{ padding: "2rem" }}>
            {" "}
            {t("Record not found")}
          </p>
        )}
      </div>
      <div className={Style.viewAllBtn} style={{ color: interfaceTextColor }}>
        {ownNotifications.length > 5 && (
          <Link to="/notifications" style={{ color: interfaceTextColor }}>
            {t("View all")}
          </Link>
        )}
      </div>

      <PolicyAcceptReject
        setParentOpen={setOpen}
        open={open}
        onClose={handleClose}
        isLoading={loading}
        policy={selectedNotification && selectedNotification?.instance}
      />
    </div>
  );
};

export default DashboardNotification;
