/* eslint-disable react-hooks/rules-of-hooks */
import { t } from "react-multi-lang";
import { Sentencify } from "./sentencify";

export const designations_values = [
  t("Management"),
  t("Supervision"),
  t("Employee"),
];

export const experience_values = [
  t("Beginner"),
  t("Intermediate"),
  t("Advance"),
  // t("Management"),
];

export const experience_values_with_level_text = [
  t("Beginner"),
  t("Intermediate"),
  t("Advance"),
  // t("Management"),
];

export const roles_values = ["Admin", "Trainer", "Trainee"];
export const permissionGroupLabel: any = {
  department: "Department Management",
  image: "Image Library",
  dynamicformfields: "Form Management",
  customusergroup: "Group Management",
  user: "User Management",
  certificate: "Certificates Management",
  category: "Category",
  assessment: "Assessment",
  module: "Module",
  question: "Question",
  training: "Training",
  policy: "Policy List",
  policyassessment: "Policy Assessment",
};
export const assessmentTimeOptionsLabel: any = {
  No_Limit: t("No Limit"),
  Each_Question_Limit: t("Each Question Limit"),
  Full_Quiz_Limit: t("Full Quiz Limit"),
};

export const assessment_status_values = [t("Approved"), t("Draft")];
export const email_notifications_values = [
  t("PENDING"),
  t("APPROVED"),
  t("REJECTED"),
];

export const email_notifications_event_values = [
  {
    value: Sentencify("NEW_TRAINEE_SIGNUP".replaceAll("_", " ")),
    key: "NEW_TRAINEE_SIGNUP",
  },
  {
    value: Sentencify("NEW_TRAINING_CREATED_BY_TRAINER".replaceAll("_", " ")),
    key: "NEW_TRAINING_CREATED_BY_TRAINER",
  },
  {
    value: Sentencify("NEW_POLICY_CREATED".replaceAll("_", " ")),
    key: "NEW_POLICY_CREATED",
  },
  {
    value: Sentencify("NEW_POLICY_ASSIGNED".replaceAll("_", " ")),
    key: "NEW_POLICY_ASSIGNED",
  },
  {
    value: Sentencify("NEW_POLICY_ASSESSMENT_ASSIGNED".replaceAll("_", " ")),
    key: "NEW_POLICY_ASSESSMENT_ASSIGNED",
  },
  {
    value: Sentencify("LICENSE_EXPIRE".replaceAll("_", " ")),
    key: "LICENSE_EXPIRE",
  },
  {
    value: Sentencify("LICENSE_USER_REACH_REMINDER".replaceAll("_", " ")),
    key: "LICENSE_USER_REACH_REMINDER",
  },
  {
    value: Sentencify("LICENSE_USER_REACH".replaceAll("_", " ")),
    key: "LICENSE_USER_REACH",
  },
  {
    value: Sentencify("NEW_PERMISSION".replaceAll("_", " ")),
    key: "NEW_PERMISSION",
  },
  {
    value: Sentencify("UPDATE_PERMISSION".replaceAll("_", " ")),
    key: "UPDATE_PERMISSION",
  },
  {
    value: Sentencify("NEW_TRAINEE_ENROLLMENT".replaceAll("_", " ")),
    key: "NEW_TRAINEE_ENROLLMENT",
  },
  {
    value: Sentencify("NEW_TRAINING_COMPLETION".replaceAll("_", " ")),
    key: "NEW_TRAINING_COMPLETION",
  },
  {
    value: Sentencify("NEW_TRAINING_ASSIGNED".replaceAll("_", " ")),
    key: "NEW_TRAINING_ASSIGNED",
  },
  {
    value: Sentencify("SELF_ENROLMENT".replaceAll("_", " ")),
    key: "SELF_ENROLMENT",
  },
  {
    value: Sentencify("CERTIFICATE_ISSUE".replaceAll("_", " ")),
    key: "CERTIFICATE_ISSUE",
  },
];

export const assessment_category_values = ["Category 1", "Category 2"];

export const assessment_level_values = [
  t("Beginner"),
  t("Intermediate"),
  t("Advance"),
];
export const trainingFrom = [t("SCORM"), t("Module")];
export const module_status_values = [t("Draft"), t("Approved")];

// export const module_category_values = ["Category 1", "Category 2"];

export const module_level_values = [
  t("Beginner"),
  t("Intermediate"),
  t("Advance"),
];
export const enrolledLabel: any = {
  SelfEnrolled: t("Self Enrolled"),
  AdminEnrolled: t("Admin Enrolled"),
  AutoEnrolled: t("Auto Enrolled"),
  TrainerEnrolled: t("Trainer Enrolled"),
};
export const trainingTypeLabel: any = {
  Completed: t("Completed"),
  "In Progress": t("In-Progress"),
  Incomplete: t("In-Complete"),
  "Not Started": t("Not Started"),
};
export const roles_except_super_admin = [
  t("Admin"),
  t("Trainer"),
  t("Trainee"),
];
