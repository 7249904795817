import { yupResolver } from "@hookform/resolvers/yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import CancelButton from "../../../components/CancelButton";
import Header from "../../../components/Header";
import SubmitButton from "../../../components/SubmitButton";
import useCompanyLanguage from "../../../hooks/language";
import useColor from "../../../hooks/useColor";
import { updateProfilePassword } from "../../../services/adminUserService";
import {
  alertMessageSelector,
  setErr,
  setErrors,
  setSuccess,
} from "../../../store/reducers/alertMessageReducer";
import { useAppDispatch } from "../../../store/store";
import { defaultThemeColor } from "../../../utils/colors_values";
import Style from "../../myProfile/MyProfile.module.scss";

const schema = yup
  .object({
    current_password: yup.string().required("Password is required"),
    new_password: yup
      .string()
      .min(8, "New password must contain 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must contain 8 characters, One uppercase, one lowercase, One number and one special case character"
      )
      .required("Nem password is required"),
    confirm_password: yup
      .string()
      .min(8, "Confirm password must contain 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must contain 8 characters, One uppercase, one lowercase, One number and one special case character"
      )
      .required("Confirm password is required")
      .oneOf(
        [yup.ref("new_password"), ""],
        "Confirm password does not match with new password"
      ),
  })
  .required();
function ChangeCompanyPassword() {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const states = useSelector(alertMessageSelector).alertMessageReducer;

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const handleTogglePasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handleToggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChangepasswordCancel = () => {
    navigate("/company-setting/company-profile");
  };

  const Submit = (payload: any) => {
    dispatch(updateProfilePassword(payload));
  };

  useEffect(() => {
    if (states?.errors) {
      if (states?.errors?.current_password?.length > 0) {
        toast.error(states?.errors?.current_password?.toString(), {
          toastId: states?.errors?.current_password?.toString(),
        });
        setError("current_password", {
          type: "server",
          message: states?.errors?.current_password?.toString(),
        });
      }
      if (states?.errors?.new_password?.length > 0) {
        toast.error(states?.errors?.new_password?.toString(), {
          toastId: states?.errors?.new_password?.toString(),
        });
        setError("new_password", {
          type: "server",
          message: states?.errors?.new_password?.toString(),
        });
      }
      if (states?.errors?.confirm_password?.length > 0) {
        toast.error(states?.errors?.confirm_password?.toString(), {
          toastId: states?.errors?.confirm_password?.toString(),
        });
        setError("confirm_password", {
          type: "server",
          message: states?.errors?.confirm_password?.toString(),
        });
      }
      if (states?.errors) {
        dispatch(setErrors(null));
      }
    }
    if (states?.error) {
      toast.error(String(states?.error), {
        toastId: String(states?.error),
      });
      dispatch(setErr(null));
    }
    if (states?.success) {
      toast.success(String(states?.success), {
        toastId: String(states?.success),
      });
      dispatch(setSuccess(null));
      navigate("/company-setting/company-profile");
    }
  }, [states]);
  return (
    <div
      className={`${Style.background}`}
      style={{
        background:
          useColor("interface_background_color") ||
          defaultThemeColor.interface_background_color,
        color:
          useColor("interface_foreground_text_color") ||
          defaultThemeColor.interface_foreground_text_color,
      }}
    >
      <div style={{ paddingBottom: "1.6em" }}>
        <Header
          page={t("Company Profile Management")}
          mainHead={t("Company Settings / Change Password")}
          backLink="/company-setting/company-profile"
        />
      </div>
      <div>
        <div
          style={{
            background:
              useColor("interface_foreground_color") ||
              defaultThemeColor.interface_foreground_color,

            borderRadius: 10,
          }}
          className={Style.bottomSpace}
        >
          <form onSubmit={handleSubmit(Submit)} autoComplete="off">
            <div
              className={`${Style.leftSpace2} ${Style.topSpace} ${Style.bottomSpace}`}
              style={{ paddingRight: lang === "ar" ? "3rem" : "1rem" }}
            >
              <div
                className={`${Style.textHeader}`}
                style={{
                  color:
                    useColor("interface_foreground_text_color") ||
                    defaultThemeColor.interface_foreground_text_color,
                  textAlign: lang === "ar" ? "right" : "left",
                }}
              >
                {t("Change Company Profile Password")}
              </div>
              <div className="d-flex flex-column">
                <div className="col-lg-4 p-0 mt-4 mb-3">
                  <label
                    className={Style.label}
                    style={{
                      fontSize: "14px",
                      color:
                        useColor("interface_foreground_text_color") ||
                        defaultThemeColor.interface_foreground_text_color,
                    }}
                  >
                    {t("Current Password")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    type={showCurrentPassword ? "text" : "password"}
                    {...register("current_password")}
                    className={` ${
                      errors.current_password ? "is-invalid" : ""
                    }`}
                    style={{
                      color:
                        useColor("interface_background_text_color") ||
                        defaultThemeColor.interface_background_text_color,
                      background:
                        useColor("interface_background_color") ||
                        defaultThemeColor.interface_background_color,
                    }}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePasswordVisibility}>
                            {showCurrentPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <p className="color-red">
                    {errors.current_password?.message}
                  </p>
                </div>
                <div className="col-lg-4 p-0 mb-3">
                  <label
                    className={Style.label}
                    style={{
                      fontSize: "14px",
                      color:
                        useColor("interface_foreground_text_color") ||
                        defaultThemeColor.interface_foreground_text_color,
                    }}
                  >
                    {t("New Password")} <span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    type={showNewPassword ? "text" : "password"}
                    {...register("new_password")}
                    fullWidth
                    className={` ${errors.new_password ? "is-invalid" : ""}`}
                    style={{
                      color:
                        useColor("interface_background_text_color") ||
                        defaultThemeColor.interface_background_text_color,
                      background:
                        useColor("interface_background_color") ||
                        defaultThemeColor.interface_background_color,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleToggleNewPasswordVisibility}
                          >
                            {showNewPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <p className="color-red">{errors.new_password?.message}</p>
                </div>
                <div className="col-lg-4 p-0 mb-3">
                  <label
                    className={Style.label}
                    style={{
                      fontSize: "14px",
                      color:
                        useColor("interface_foreground_text_color") ||
                        defaultThemeColor.interface_foreground_text_color,
                    }}
                  >
                    {t("Confirm Password")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    type={showConfirmPassword ? "text" : "password"}
                    {...register("confirm_password")}
                    fullWidth
                    className={` ${
                      errors.confirm_password ? "is-invalid" : ""
                    }`}
                    style={{
                      color:
                        useColor("interface_background_text_color") ||
                        defaultThemeColor.interface_background_text_color,
                      background:
                        useColor("interface_background_color") ||
                        defaultThemeColor.interface_background_color,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleToggleConfirmPasswordVisibility}
                          >
                            {showCurrentPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <p className="color-red">
                    {errors.confirm_password?.message}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="d-flex w-100 gap-4 pt-5 p-4"
              style={{
                justifyContent: lang === "ar" ? "flex-start" : "flex-end",
              }}
            >
              <SubmitButton label={t("Update")} marginTop={false} />
              <CancelButton
                size="small"
                outlined={true}
                onClick={handleChangepasswordCancel}
                textColor=""
                outlineColor=""
                text={t("Cancel")}
                className={``}
                bg={""}
              ></CancelButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ChangeCompanyPassword;
