import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
export interface DepartmentDataList {
  count: number;
  next: string | null;
  previous: String | null;
  results: [];
}
export interface DepartmentData {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}
export interface DepartmentState {
  data: DepartmentDataList;
  wholedata: DepartmentDataList;
  membersData?: DepartmentDataList;
  success?: string;
  error?: string;
  errors?: any;
  detailData?: DepartmentData;
  page?: number;
  perPage?: number;
  search?: string;
}
export const initialState: DepartmentState = {
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  wholedata: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  page: 1,
  perPage: 10,
  search: "",
};

export const departmentSlice = createSlice({
  name: "department",
  initialState,
  reducers: {
    setDepartmentData: (state, { payload }) => {
      state.data = payload;
    },
    setWholeDepartmentData: (state, { payload }) => {
      state.wholedata = payload;
    },
    setDetailData: (state, { payload }) => {
      state.detailData = payload;
    },

    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setPerPage: (state, { payload }) => {
      state.perPage = payload;
    },
    setSearch: (state, { payload }) => {
      state.search = payload;
    },
    setMembersData: (state, { payload }) => {
      state.membersData = payload;
    },
  },
});

export const {
  setDepartmentData,
  setWholeDepartmentData,
  setMembersData,
  setDetailData,
  setPage,
  setPerPage,
  setSearch,
} = departmentSlice.actions;

export const departmentSelector = (state: RootState) => state;

export default departmentSlice.reducer;
