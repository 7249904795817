function DraggableSVG(props: { color: string }) {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.5 0H0.5V2H2.5V0ZM16.5 4H18.5V10H16.5V6H6.5V16H10.5V18H4.5V4H16.5ZM4.5 0H6.5V2H4.5V0ZM2.5 4H0.5V6H2.5V4ZM0.5 8H2.5V10H0.5V8ZM2.5 12H0.5V14H2.5V12ZM8.5 0H10.5V2H8.5V0ZM14.5 0H12.5V2H14.5V0ZM12.5 14V12H18.5V14H16.5V16H14.5V18H12.5V14ZM16.5 16V18H18.5V16H16.5Z"
        fill={props?.color ?? "#64748B"}
      />
    </svg>
  );
}

export default DraggableSVG;
