import axiosPrivate from "../lib/axiosPrivate";
import { setLoading } from "../store/reducers/loaderReducer";
import { AppThunk } from "../store/store";
import {
  setErr,
  setErrors,
  setSuccess,
} from "../store/reducers/alertMessageReducer";
import { setProgress } from "../store/reducers/progressReducer";
import {
  setData,
  setEmailByIdData,
} from "../store/reducers/emailNotificationReducer";

export const addEmailNotification =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await axiosPrivate
        .post(`notifications/email_template/`, payload)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));

          if (data?.detail) {
            dispatch(setSuccess(data?.detail));
          } else {
            dispatch(
              setSuccess("Email Template Notification added successfully!")
            );
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.message) {
            dispatch(setErr(error?.response?.data?.message));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getEmailNotifications =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setData(null));
    try {
      await axiosPrivate
        .get(
          `notifications/email_template/?page=${payload?.page}&page_size=${
            payload?.page_size
          }&search=${payload?.search || ""}`
        )
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setData(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setErr(error.response.data.message));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const getEmailNotificationByID =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setEmailByIdData({}));
    try {
      await axiosPrivate
        .get(`notifications/email_template/${payload?.id}/`)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setEmailByIdData(data));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErr(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErr("Something went wrong, Please try again"));
    }
  };

export const updateEmailNotification =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await axiosPrivate
        .patch(`notifications/email_template/${payload.id}/`, payload)
        .then((response) => {
          const data = response.data;
          dispatch(setLoading(false));
          dispatch(setSuccess("Email template updated successfully!"));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          if (error?.response?.data?.detail) {
            dispatch(setErrors(error?.response?.data?.detail));
          } else {
            dispatch(setErrors(error?.response?.data));
          }
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErrors("Something went wrong, Please try again"));
    }
  };
