import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Header from "../../components/Header";
import ImageSelect from "../../components/ImageSelect";
import useColor from "../../hooks/useColor";
import BaseImage from "../../images/ph_image.png";
import { getCompanyForm, updateCompanyForm } from "../../services/profileManagementService";
import { RootState, useAppDispatch } from "../../store/store";
import { defaultThemeColor } from "../../utils/colors_values";
import Style from "../companyProfileManagement/profileManagement.module.scss";
import EditProfile from "./components/EditProfile";
import ViewProfile from "./components/ViewProfile";
import { useTranslation } from "react-multi-lang";
import useCompanyLanguage from "../../hooks/language";

function CompanyProfileManagement() {
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();

  const companyFormData =
    useSelector((state: RootState) => state.profileManagementReducer.company_form_data) || [];

  const isLoading = useSelector((state: RootState) => state.loaderReducer.isLoading);
  const [edit, setEdit] = useState(false);
  const [selectedImage, setSelectedImage] = useState<any>();

  useEffect(() => {
    getCompanyFormData();
  }, []);

  useEffect(() => {
    setEdit(false);
  }, [companyFormData]);

  const updatePic = () => {
    let payload = companyFormData;

    dispatch(
      updateCompanyForm({
        ...payload,
        company_logo: {
          image: selectedImage,
          imagename: selectedImage.name,
        },
        image: selectedImage,
        imagename: selectedImage.name,
      })
    );
    setSelectedImage("");
  };

  const getCompanyFormData = () => {
    dispatch(getCompanyForm());
  };

  const primaryButton = useColor("button_primary") || defaultThemeColor.button_primary;
  const primaryButtonText =
    useColor("button_primary_text") || defaultThemeColor.button_primary_text;

  return (
    <div
      className={`${Style.background}`}
      style={{
        background:
          useColor("interface_background_color") || defaultThemeColor.interface_background_color,
      }}
    >
      <div style={{ paddingBottom: "1.6em" }}>
        <Header
          page={t("Company Profile Management")}
          mainHead={t("Company Settings / Company Profile")}
        />
      </div>
      <div>
        <div
          style={{
            background:
              useColor("interface_foreground_color") ||
              defaultThemeColor.interface_foreground_color,
            borderRadius: 10,
          }}
          className={"pb-3"}
        >
          <div className={`${Style.leftSpace2} ${Style.topSpace} ${Style.bottomSpace}`}>
            <div
              className={`${Style.textHeader}`}
              style={{
                color:
                  useColor("interface_foreground_text_color") ||
                  defaultThemeColor.interface_foreground_text_color,
                borderRadius: 10,
                textAlign: lang === "ar" ? "right" : "left",
                marginRight: lang === "ar" ? "16px" : "0px",
              }}
            >
              {t("Company Profile Details")}
            </div>
          </div>
          <div
            className={`d-flex justify-content-between align-items-center gap-5 ${Style.alignItemsInside}`}
          >
            <div className={`${Style.imageMain}`}>
              <ImageSelect
                selectedImage={selectedImage}
                imageFromBackend={(companyFormData && companyFormData.company_logo) || ""}
                BaseImage={BaseImage}
                isLoading={isLoading}
                setSelectedImage={setSelectedImage}
                updatePicSubmit={updatePic}
                minDimensions={true}
                showButtons={true}
                uploadButtonComponent={""}
                maxFileSizeinBytes={5242880}
              />
            </div>

            <div className="d-flex">
              {!edit ? (
                <button
                  className={`btn ${Style.loginBtn2}`}
                  style={{
                    backgroundColor: primaryButton,
                    color: primaryButtonText,
                  }}
                  type="button"
                  onClick={() => {
                    setEdit(true);
                  }}
                >
                  <span>
                    <span className="text-white" style={{ color: primaryButtonText }}>
                      {t("Edit")}
                    </span>
                  </span>
                </button>
              ) : null}
            </div>
          </div>
          <div style={{ paddingBottom: "2em" }}></div>
          <hr />

          {edit ? (
            <EditProfile
              setEdit={setEdit}
              detail={companyFormData && companyFormData}
              logo={selectedImage}
              setLogo={setSelectedImage}
            />
          ) : (
            <ViewProfile detail={companyFormData && companyFormData} />
          )}
        </div>
      </div>
    </div>
  );
}

export default CompanyProfileManagement;
