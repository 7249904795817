import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import CancelButton from "../../../components/CancelButton";
import SubmitButton from "../../../components/SubmitButton";
import useCompanyLanguage from "../../../hooks/language";

import { maskSecret } from "../../../helpers/helpers";
import { addData } from "../../../services/companyIntegrationService";
import {
  alertMessageSelector,
  setErr,
} from "../../../store/reducers/alertMessageReducer";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";

const schema = yup
  .object({
    client_id: yup
      .string()
      .max(150, "Maximum 150 characters are allowed!")
      .required("Client ID is required"),
    client_secret: yup
      .string()
      .max(150, "Maximum 150 characters are allowed!")
      .required("Client Secret is required"),
    tenant_id: yup
      .string()
      .max(150, "Maximum 150 characters are allowed!")
      .required("Tenant ID is required"),
  })
  .required();
export interface PropsInterface {
  setIsEdit: any;
  row: any;
}

function ActiveDirectoryEdit(props: PropsInterface) {
  const { setIsEdit, row } = props;
  const {
    setValue,
    setError,
    reset,
    register,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const isLoading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );
  const onSubmit = (payload: any) => {
    let fPayload = {
      type: payload.type,
      detail: {
        client_id: payload.client_id,
        client_secret: payload.client_secret,
        tenant_id: payload.tenant_id,
      },
    };

    dispatch(addData(fPayload));
  };
  useEffect(() => {
    let defaultValues = {
      type: row?.type,
      client_id: row?.detail?.client_id || "",
      client_secret: "",
      tenant_id: row?.detail?.tenant_id || "",
    };
    reset({ ...defaultValues });
  }, [row]);

  const alertMessage = useAppSelector(alertMessageSelector).alertMessageReducer;
  useEffect(() => {
    if (alertMessage?.error) {
      if (alertMessage?.error) {
        toast.error(alertMessage?.error?.toString(), {
          toastId: alertMessage?.error?.toString(),
        });
      }

      dispatch(setErr(null));
    }
  }, [alertMessage]);
  return (
    <>
      <form className="d-flex flex-column" onSubmit={handleSubmit(onSubmit)}>
        <div className={`row`}>
          <div className={`col-lg-6 px-2 `}>
            <span>
              {t("Client ID")}
              <span style={{ color: "red" }}> *</span>
            </span>
            <input
              className="form-control"
              style={{
                height: "50px",
                border: "1px solid #E2E8F0",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
              type="text"
              placeholder={t("Client ID")}
              autoComplete="off"
              {...register("client_id")}
            />
            <p className="color-red">{errors.client_id?.message}</p>
          </div>
          <div className={`col-lg-6  px-2 `}>
            <span>
              {t("Client Secret")}
              <span style={{ color: "red" }}> *</span>
            </span>
            <input
              className="form-control"
              style={{
                height: "50px",
                border: "1px solid #E2E8F0",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
              type="text"
              placeholder={t("Client Secret")}
              autoComplete="off"
              {...register("client_secret")}
            />
            {row?.detail?.client_secret && (
              <p>{maskSecret(row?.detail?.client_secret)}</p>
            )}
            <p className="color-red">{errors.client_secret?.message}</p>
          </div>
          <div className={`col-lg-6  px-2 `}>
            <span>
              {t("Tenant ID")}
              <span style={{ color: "red" }}> *</span>
            </span>
            <input
              className="form-control"
              style={{
                height: "50px",
                border: "1px solid #E2E8F0",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
              type="text"
              placeholder={t("Tenant ID")}
              autoComplete="off"
              {...register("tenant_id")}
            />
            <p className="color-red">{errors.tenant_id?.message}</p>
          </div>
          <p>
            Note: Please use Microsoft Active Directory Graph API for setup.
            (Base URL https://login.microsoftonline.com/)
          </p>
        </div>
        <div className="d-flex justify-content-end w-100 gap-4  p-4">
          <SubmitButton label="Save" />
          <CancelButton
            size="small"
            outlined={true}
            onClick={() => {
              setIsEdit(false);
            }}
            textColor=""
            outlineColor=""
            text={t("Cancel")}
            className={`mt-5`}
            bg={""}
          />
        </div>
      </form>
    </>
  );
}

export default ActiveDirectoryEdit;
