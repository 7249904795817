import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import { useTranslation } from "react-multi-lang";
import useCompanyLanguage from "../../../hooks/language";
import Style from "../ImageLibrary.module.scss";

export interface SimpleDialogProps {
  open: boolean;
  setParentOpen: any;
  onClose: (value: string) => void;
  image: string;
  imageName: string;
}
const ViewImage: React.FC<SimpleDialogProps> = ({
  onClose,
  open,
  setParentOpen,
  image,
  imageName,
}): JSX.Element => {
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const handleClose = () => {
    onClose("false");
  };

  return (
    <React.Fragment>
      <Dialog onClose={handleClose} open={open}>
        <div className="d-flex justify-content-between">
          <DialogTitle className="p-3">{imageName}</DialogTitle>
          <div className="p-3">
            <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
          </div>
        </div>
        <div
          className={`d-flex justify-content-center align-items-center px-3 pb-3 ${Style.imageView}`}
        >
          <img src={image} alt={imageName} className={Style.hoverZoom} />
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default ViewImage;
