/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Checkbox, Chip, FormControl, MenuItem, Select, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import { toast } from "react-toastify";
import * as yup from "yup";
import CancelButton from "../../components/CancelButton";
import Header from "../../components/Header";
import SubmitButton from "../../components/SubmitButton";
import DatePickerSVG from "../../icons/DatePickerSVG";
import axiosPrivate from "../../lib/axiosPrivate";
import { addAutoGroup } from "../../services/groupManagementService";
import {
  alertMessageSelector,
  setErr,
  setErrors,
  setSuccess,
} from "../../store/reducers/alertMessageReducer";
import { useAppDispatch } from "../../store/store";
import { light_text_color, primary_color } from "../../utils/colors_values";
import { designations_values, experience_values, roles_values } from "../../utils/staticEntities";
import Style from "./AddAutoGroup.module.scss";
import useColor from "../../hooks/useColor";
import { defaultThemeColor } from "../../utils/colors_values";
import useCompanyLanguage from "../../hooks/language";
import { useTranslation } from "react-multi-lang";

const designations = designations_values;
const roles = roles_values;
const experience = experience_values;

const schema = yup
  .object({
    group_name: yup.string().required("Group name is required"),
    departments: yup.array(),
    designations: yup.array(),
    experience_levels: yup.array(),
    roles: yup.array(),
    min_date: yup.date().typeError("Please input valid date or select from date picker"),
    max_date: yup.date().typeError("Please input valid date or select from date picker"),
  })
  .required();
function AddAutoGroup() {
  const {
    setError,
    getValues,
    watch,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });
  const dispatch = useAppDispatch();
  const states = useSelector(alertMessageSelector).alertMessageReducer;
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const [fromValue, setFromValue] = React.useState<any>();
  const navigate = useNavigate();
  const conveetNestedToFlatten = (accounts: any, showChild: boolean) => {
    var a: any = [];
    for (var i = 0; i < accounts.length; i++) {
      if (showChild) {
        if (accounts[i].children) {
          a = a.concat(conveetNestedToFlatten(accounts[i].children, showChild));
        }
      }
      a.push(accounts[i]);
    }
    return a;
  };
  const loadOptions = async (searchQuery: any, loadedOptions: any, { page }: any) => {
    const response = await axiosPrivate.get(
      `accounts/department/?page=${page}&page_size=100&search=${searchQuery}`
    );
    const data = response.data;
    const showChild = searchQuery ? false : true;
    const dataFlatten = conveetNestedToFlatten(data?.results, showChild);
    const options = dataFlatten?.map((row: any) => {
      return {
        label: row.name || "N/A",
        value: row.id,
      };
    });

    const users = {
      options: options || [],
      hasMore: data?.next ? true : false,
      additional: {
        page: searchQuery ? 1 : page + 1,
      },
    };

    return users;
  };
  const Submit = (payload: any) => {
    if (
      (payload?.departments?.length === 0 || !payload?.departments) &&
      payload?.designations?.length === 0 &&
      payload?.experience_levels?.length === 0 &&
      payload?.roles?.length === 0 &&
      !payload.max_date &&
      !payload.min_date
    ) {
      toast.info(
        t(
          "Please select at least one option from Designation, Role, Experience, Department or Created Date."
        ),
        {
          toastId: t(
            "Please select at least one option from Designation, Role, Experience, Department or Created Date."
          ),
        }
      );
      return;
    }
    if ((payload?.min_date && !payload?.max_date) || (!payload?.min_date && payload?.max_date)) {
      toast.error(t("From & To date are required."), {
        toastId: t("From & To date are required."),
      });
      return;
    }
    if (payload.max_date && payload.min_date) {
      let mStart = moment(payload.min_date);
      let mEnd = moment(payload.max_date);
      if (!mStart.isBefore(mEnd)) {
        toast.error(t("To date should be after from date."), {
          toastId: t("To date should be after from date."),
        });
        return;
      }
    }
    let depart: any = [];
    payload?.departments?.map((row: any) => depart.push(row.value));
    let query: any = { group_name: payload.group_name };
    if (depart?.length > 0) {
      query.departments = depart;
    } else {
      query.departments = [];
    }
    if (payload?.designations?.length > 0) {
      query.designations = payload.designations;
    } else {
      query.designations = [];
    }
    if (payload?.experience_levels?.length > 0) {
      query.experience_levels = payload.experience_levels;
    } else {
      query.experience_levels = [];
    }
    if (payload?.roles?.length > 0) {
      query.roles = payload.roles;
    } else {
      query.roles = [];
    }
    if (payload?.min_date) {
      query.min_date = moment(payload.min_date).format("YYYY-MM-DD");
    } else {
      query.min_date = null;
    }
    if (payload?.max_date) {
      query.max_date = moment(payload.max_date).format("YYYY-MM-DD");
    } else {
      query.max_date = null;
    }

    dispatch(addAutoGroup(query));
  };

  useEffect(() => {
    if (states?.errors) {
      if (states?.errors?.group_name?.length > 0) {
        toast.error(states?.errors?.group_name?.toString(), {
          toastId: states?.errors?.group_name?.toString(),
        });
        setError("group_name", {
          type: "server",
          message: states?.errors?.group_name?.toString(),
        });
      }
      if (states?.errors?.departments?.length > 0) {
        toast.error(states?.errors?.departments?.toString(), {
          toastId: states?.errors?.departments?.toString(),
        });
        setError("departments", {
          type: "server",
          message: states?.errors?.departments?.toString(),
        });
      }
      if (states?.errors?.designations?.length > 0) {
        toast.error(states?.errors?.designations?.toString(), {
          toastId: states?.errors?.designations?.toString(),
        });
        setError("designations", {
          type: "server",
          message: states?.errors?.designations?.toString(),
        });
      }
      if (states?.errors?.experience_levels?.length > 0) {
        toast.error(states?.errors?.experience_levels?.toString(), {
          toastId: states?.errors?.experience_levels?.toString(),
        });
        setError("experience_levels", {
          type: "server",
          message: states?.errors?.experience_levels?.toString(),
        });
      }
      if (states?.errors?.roles?.length > 0) {
        toast.error(states?.errors?.roles?.toString(), {
          toastId: states?.errors?.roles?.toString(),
        });
        setError("roles", {
          type: "server",
          message: states?.errors?.roles?.toString(),
        });
      }
      if (states?.errors?.min_date?.length > 0) {
        toast.error(states?.errors?.min_date?.toString(), {
          toastId: states?.errors?.min_date?.toString(),
        });
        setError("min_date", {
          type: "server",
          message: states?.errors?.min_date?.toString(),
        });
      }
      if (states?.errors?.max_date?.length > 0) {
        toast.error(states?.errors?.max_date?.toString(), {
          toastId: states?.errors?.max_date?.toString(),
        });
        setError("max_date", {
          type: "server",
          message: states?.errors?.max_date?.toString(),
        });
      }
      if (states?.errors) {
        dispatch(setErrors(null));
      }
    }
    if (states?.error) {
      toast.error(String(states?.error), {
        toastId: String(states?.error),
      });
      dispatch(setErr(null));
    }
    if (states?.success) {
      toast.success(String(states?.success), {
        toastId: String(states?.success),
      });
      dispatch(setSuccess(null));
      setTimeout(() => {
        navigate("/group-management");
      }, 2000);
    }
  }, [states]);

  const interfaceTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  const interfaceBgColor =
    useColor("interface_background_color") || defaultThemeColor.interface_background_color;

  const primaryButton = useColor("button_primary_text") || defaultThemeColor.button_primary_text;
  const primaryButtonBg = useColor("button_primary") || defaultThemeColor.button_primary;

  return (
    <>
      <div>
        <div style={{ paddingBottom: "4em" }}>
          <Header
            page={t("Group Management")}
            mainHead={t("Group Management")}
            backLink="/group-management"
          />
        </div>
        <div
          className="page-start"
          style={{
            background:
              useColor("interface_foreground_color") ||
              defaultThemeColor.interface_foreground_color,
          }}
        >
          <h2 className="page-heading mb-4" style={{ textAlign: lang === "ar" ? "right" : "left" }}>
            {t("Add Auto Group")}
          </h2>

          <form onSubmit={handleSubmit(Submit)} autoComplete="off">
            <div className="row align-items-baseline">
              <div className="col-md-6">
                <span className="form-label" style={{ lineHeight: "40px" }}>
                  {t("Group Name")}
                  <span className="color-red"> *</span>
                </span>

                <TextField
                  placeholder={t("Name")}
                  autoComplete="off"
                  {...register("group_name")}
                  variant="outlined"
                  className={`form-input`}
                  style={{
                    color: interfaceTextColor,
                    background: interfaceBgColor,
                  }}
                />

                <p className="color-red mt-1">{errors.group_name?.message}</p>
              </div>
              <div className="col-md-6">
                <span className="form-label" style={{ lineHeight: "40px" }}>
                  {t("Designation")}
                </span>
                <FormControl fullWidth>
                  <Controller
                    name="designations"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                      <FormControl>
                        <Select
                          {...field}
                          multiple
                          labelId="designations"
                          label=""
                          displayEmpty
                          placeholder={t("Designation")}
                          style={{
                            color: interfaceTextColor,
                            background: interfaceBgColor,
                          }}
                          sx={{
                            "& .MuiChip-root": {
                              color: primaryButton,
                              background: primaryButtonBg,
                            },
                          }}
                          MenuProps={{
                            sx: {
                              "&& .Mui-selected": {
                                color: light_text_color,
                                background: primary_color,
                                marginBottom: "1px",
                              },
                              "&& .Mui-selected:hover": {
                                color: "black",
                                background: "skyblue",
                              },
                            },
                          }}
                          renderValue={(selected) => {
                            return (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.slice(0, 2).map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                                {selected.length > 2 && (
                                  <span
                                    className={`badge ${Style.badge}`}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      color: primaryButton,
                                      backgroundColor: primaryButtonBg,
                                    }}
                                  >
                                    + {selected.length - 2}
                                  </span>
                                )}
                                {selected.length == 0 && (
                                  <span style={{ color: "hsl(0, 0%, 50%)" }}>
                                    {t("Select Designation")}
                                  </span>
                                )}
                              </Box>
                            );
                          }}
                        >
                          {designations.map((row) => (
                            <MenuItem
                              value={row}
                              key={row}
                              className="d-flex justify-content-between"
                            >
                              <div> {row}</div>
                              <div>
                                <Checkbox
                                  checked={
                                    getValues() && getValues()?.designations
                                      ? (getValues()?.designations as any).indexOf(row) > -1
                                      : false
                                  }
                                  color="default"
                                  sx={{
                                    color:
                                      getValues() && getValues()?.designations
                                        ? (getValues()?.designations as any).indexOf(row) > -1
                                          ? "white"
                                          : ""
                                        : "",
                                  }}
                                />
                              </div>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </FormControl>
                <p className="color-red mt-1">{errors.designations?.message}</p>
              </div>
              <div className="col-md-6">
                <span className="form-label" style={{ lineHeight: "40px" }}>
                  {t("Roles")}
                </span>
                <FormControl fullWidth>
                  <Controller
                    name="roles"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                      <FormControl>
                        <Select
                          {...field}
                          multiple
                          labelId="roles"
                          label=""
                          defaultValue={[]}
                          displayEmpty
                          style={{
                            color: interfaceTextColor,
                            background: interfaceBgColor,
                          }}
                          sx={{
                            "& .MuiChip-root": {
                              // color: light_text_color,
                              // background: primary_color,
                              color: primaryButton,
                              background: primaryButtonBg,
                            },
                          }}
                          MenuProps={{
                            sx: {
                              "&& .Mui-selected": {
                                color: light_text_color,
                                background: primary_color,
                                marginBottom: "1px",
                              },
                              "&& .Mui-selected:hover": {
                                color: "black",
                                background: "skyblue",
                              },
                            },
                          }}
                          renderValue={(selected) => {
                            return (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.slice(0, 2).map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                                {selected.length > 2 && (
                                  <span
                                    className={`badge ${Style.badge}`}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      color: primaryButton,
                                      backgroundColor: primaryButtonBg,
                                    }}
                                  >
                                    + {selected.length - 2}
                                  </span>
                                )}
                                {selected.length == 0 && (
                                  <span style={{ color: "hsl(0, 0%, 50%)" }}>
                                    {t("Select Roles")}
                                  </span>
                                )}
                              </Box>
                            );
                          }}
                        >
                          {roles.map((row) => (
                            <MenuItem
                              value={row}
                              key={row}
                              className="d-flex justify-content-between"
                            >
                              <div>{row}</div>
                              <div>
                                <Checkbox
                                  checked={
                                    getValues() && getValues().roles
                                      ? (getValues()?.roles as any).indexOf(row) > -1
                                      : false
                                  }
                                  color="default"
                                  sx={{
                                    color:
                                      getValues() && getValues().roles
                                        ? (getValues()?.roles as any).indexOf(row) > -1
                                          ? "white"
                                          : ""
                                        : "",
                                  }}
                                />
                              </div>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </FormControl>
                <p className="color-red mt-1">{errors.roles?.message}</p>
              </div>
              <div className="col-md-6">
                <span className="form-label" style={{ lineHeight: "40px" }}>
                  {t("Experience Level")}
                </span>
                <FormControl fullWidth>
                  <Controller
                    name="experience_levels"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                      <FormControl>
                        <Select
                          {...field}
                          multiple
                          labelId="experience_levels"
                          label=""
                          defaultValue={[]}
                          style={{
                            color: interfaceTextColor,
                            background: interfaceBgColor,
                          }}
                          sx={{
                            "& .MuiChip-root": {
                              color: primaryButton,
                              background: primaryButtonBg,
                              // height: "25px",
                            },
                          }}
                          MenuProps={{
                            sx: {
                              "&& .Mui-selected": {
                                color: light_text_color,
                                background: primary_color,
                                marginBottom: "1px",
                              },
                              "&& .Mui-selected:hover": {
                                color: "black",
                                background: "skyblue",
                              },
                            },
                          }}
                          displayEmpty
                          renderValue={(selected) => {
                            return (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.slice(0, 2).map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                                {selected.length > 2 && (
                                  <span
                                    className={`badge ${Style.badge}`}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      color: primaryButton,
                                      backgroundColor: primaryButtonBg,
                                    }}
                                  >
                                    + {selected.length - 2}
                                  </span>
                                )}
                                {selected.length == 0 && (
                                  <span style={{ color: "hsl(0, 0%, 50%)" }}>
                                    {t("Select Experience Level")}
                                  </span>
                                )}
                              </Box>
                            );
                          }}
                        >
                          {experience.map((row) => (
                            <MenuItem
                              value={row}
                              key={row}
                              className="d-flex justify-content-between"
                            >
                              <div> {row}</div>
                              <div>
                                <Checkbox
                                  color="default"
                                  sx={{
                                    color:
                                      getValues() && getValues().experience_levels
                                        ? (getValues()?.experience_levels as any).indexOf(row) > -1
                                          ? "white"
                                          : ""
                                        : "",
                                  }}
                                  checked={
                                    getValues() && getValues().experience_levels
                                      ? (getValues()?.experience_levels as any).indexOf(row) > -1
                                      : false
                                  }
                                />
                              </div>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </FormControl>
                <p className="color-red mt-1">{errors.experience_levels?.message}</p>
              </div>
              <div className="mt-3 p-0" style={{ display: lang === "ar" ? "block" : "" }}>
                <div className="col-md-6 px-2">
                  <span className="form-label">{t("Department")}</span>
                  <Controller
                    control={control}
                    name="departments"
                    render={({ field: { value, onChange, ref } }) => (
                      <AsyncPaginate
                        id="departments"
                        name="departments"
                        loadOptions={loadOptions}
                        onChange={onChange}
                        selectRef={ref}
                        value={value}
                        getOptionValue={(option: any) => option.value}
                        getOptionLabel={(option: any) => option.label}
                        className="add-member-select"
                        additional={{
                          page: 1,
                        }}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            padding: "0.6em",
                            overflow: "auto",
                            color: interfaceTextColor,
                            background: interfaceBgColor,
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? primary_color : "",
                            color: state.isFocused ? light_text_color : "",
                          }),
                          multiValue: (baseStyles) => ({
                            ...baseStyles,
                            backgroundColor: primaryButtonBg,
                          }),
                          multiValueLabel: (baseStyles) => ({
                            ...baseStyles,
                            color: primaryButton,
                          }),
                          multiValueRemove: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: primaryButtonBg,
                            color: primaryButton,
                            ":hover": {
                              backgroundColor: primaryButtonBg,
                              color: primaryButton,
                            },
                          }),
                        }}
                        isMulti
                        isClearable
                        isSearchable={true}
                        placeholder={t("Select Departments")}
                        debounceTimeout={500}
                      />
                    )}
                  />
                  <p className="color-red mt-1">{errors.departments?.message}</p>
                </div>
                <div className="col-md-6">
                  <div className="form-label" style={{ marginBottom: "0.7rem" }}>
                    {t("Created Date")}
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Controller
                        name="min_date"
                        control={control}
                        render={({ field }) => (
                          <FormControl
                            fullWidth
                            style={{
                              color: interfaceTextColor,
                              background: interfaceBgColor,
                            }}
                          >
                            <DatePicker
                              {...field}
                              label={lang === "ar" ? "" : t("From")}
                              value={fromValue}
                              className={`col-12 `}
                              slots={{
                                openPickerIcon: DatePickerSVG,
                              }}
                              format="YYYY-MM-DD"
                            />
                          </FormControl>
                        )}
                      />
                      <p className="color-red mt-1">{errors.min_date?.message}</p>
                    </div>
                    <div className="col-md-6">
                      <Controller
                        name="max_date"
                        control={control}
                        render={({ field }) => (
                          <FormControl
                            fullWidth
                            style={{
                              color: interfaceTextColor,
                              background: interfaceBgColor,
                            }}
                          >
                            <DatePicker
                              {...field}
                              label={lang === "ar" ? "" : t("To")}
                              minDate={watch("min_date")}
                              value={fromValue}
                              className="col-12"
                              slots={{
                                openPickerIcon: DatePickerSVG,
                              }}
                              format="YYYY-MM-DD"
                            />
                          </FormControl>
                        )}
                      />
                      <p className="color-red mt-1">{errors.max_date?.message}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="d-flex flex-wrap gap-3 w-100 px-3 mt-5 align-items-center"
                style={{
                  justifyContent: lang === "ar" ? "flex-start" : "flex-end",
                }}
              >
                <SubmitButton label={t("Save")} marginTop={false} />
                <CancelButton
                  size="small"
                  outlined={true}
                  onClick={() => navigate("/group-management")}
                  textColor="#2196F3"
                  outlineColor=""
                  text={t("Cancel")}
                  bg={"rgba(33, 150, 243, 0.04)"}
                  className={``}
                ></CancelButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddAutoGroup;
