import { useEffect } from "react";
import Style from "./DepartmentModal.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import SubmitButton from "../../components/SubmitButton";
import {
  alertMessageSelector,
  setErrors,
} from "../../store/reducers/alertMessageReducer";
import { useAppDispatch } from "../../store/store";
import { Box, Modal, Typography } from "@mui/material";
import useColor from "../../hooks/useColor";
import {
  addDepartment,
  updateDepartment,
} from "../../services/departmentService";
import { defaultThemeColor } from "../../utils/colors_values";
import { useTranslation } from "react-multi-lang";
import useCompanyLanguage from "../../hooks/language";
import CancelButton from "../../components/CancelButton";

const schema = yup
  .object({
    name: yup
      .string()
      .required("Department name is required")
      .max(50, "Department name must be at most 50 characters"),
  })
  .required();

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #fff",
  boxShadow: 24,
  p: 4,
};
interface Params {
  openEditModal: boolean;
  CloseEditModal: any;
  detail?: any;
  parentId: any;
  defaultFormValues: any;
  modalHeading: string;
  modalButtonLable: string;
}
function DepartmentModal(props: Params) {
  const {
    openEditModal,
    CloseEditModal,
    parentId,
    defaultFormValues,
    modalHeading,
    modalButtonLable,
  } = props;
  const alertMessage = useSelector(alertMessageSelector).alertMessageReducer;

  const {
    reset,
    setError,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  useEffect(() => {
    if (alertMessage?.errors) {
      if (alertMessage?.errors?.name?.length > 0) {
        toast.error(alertMessage?.errors?.name?.toString(), {
          toastId: alertMessage?.errors?.name?.toString(),
        });
        setError("name", {
          type: "server",
          message: alertMessage?.errors?.name?.toString(),
        });
      }

      if (alertMessage?.errors) {
        dispatch(setErrors(null));
      }
    }
  }, [alertMessage]);

  const Submit = (payload: any) => {
    if (defaultFormValues?.id) {
      dispatch(updateDepartment(Number(defaultFormValues?.id), payload));
      return;
    }
    if (parseInt(parentId) > 0) {
      payload.parent_id = parentId;
      dispatch(addDepartment(payload));
      return;
    } else {
      payload.parent_id = null;
      dispatch(addDepartment(payload));
      return;
    }
  };
  useEffect(() => {
    let defaultValues = defaultFormValues;
    reset({ ...defaultValues });
  }, [defaultFormValues]);
  return (
    <>
      <Modal
        open={openEditModal}
        onClose={CloseEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
      >
        <Box
          sx={style}
          style={{
            background:
              useColor("interface_foreground_color") ||
              defaultThemeColor.interface_foreground_color,
            color:
              useColor("interface_foreground_text_color") ||
              defaultThemeColor.interface_foreground_text_color,
            border: "none",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t(modalHeading)}
          </Typography>
          <Box id="modal-modal-description" sx={{ mt: 2 }}>
            <form onSubmit={handleSubmit(Submit)} autoComplete="off">
              <div className={`d-flex flex-wrap gap-5`}>
                <div className="w-100">
                  <span className="form-label">
                    {t("Department Name")}
                    <span className="color-red"> *</span>
                  </span>
                  <input
                    className={`form-input ${errors.name ? "is-invalid" : ""}`}
                    type="text"
                    placeholder={t("Name")}
                    autoComplete="off"
                    {...register("name")}
                    style={{
                      color:
                        useColor("interface_background_text_color") ||
                        defaultThemeColor.interface_background_text_color,
                      background:
                        useColor("interface_background_color") ||
                        defaultThemeColor.interface_background_color,
                    }}
                  />
                  <p className="error color-red">{errors.name?.message}</p>
                </div>
              </div>
              <div className="d-flex gap-3 align-items-center float-end">
                <SubmitButton label={t(modalButtonLable)} />
                {/* <button
                  type="button"
                  className={`btn mt-5 ${Style.cancelBtn}`}
                  style={{
                    color:
                      useColor("button_secondary_text") ||
                      defaultThemeColor.button_secondary_text,
                    background:
                      useColor("button_secondary") ||
                      defaultThemeColor.button_secondary,
                    border:
                      useColor("button_secondary") ||
                      defaultThemeColor.button_secondary
                        ? "none"
                        : "#0089cf",
                    outline: "none",
                  }}
                  onClick={CloseEditModal}
                >
                  {t("Cancel")}
                </button> */}
                <CancelButton
                  size="small"
                  outlined={true}
                  onClick={CloseEditModal}
                  textColor=""
                  outlineColor=""
                  text={t("Cancel")}
                  className={` mt-5`}
                  bg={""}
                ></CancelButton>
              </div>
            </form>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default DepartmentModal;
