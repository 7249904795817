import { CircularProgress } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../../components/Header";
import PDFViewer from "../../../components/PDFViewer";
import VideoPlayer from "../../../components/VideoPlayer/VideoPlayer";
import useCompanyLanguage from "../../../hooks/language";
import useColor from "../../../hooks/useColor";
import {
  getTraining,
  updateModuleStatusVideoPDF,
  updateVideoRecord,
} from "../../../services/trainingsService";
import {
  setTrainingData,
  setVideoData,
} from "../../../store/reducers/trainingsReducer";
import { RootState, useAppDispatch } from "../../../store/store";
import { defaultThemeColor } from "../../../utils/colors_values";
import { confirmProps } from "../../../utils/confirmProps";
import TrainingLectureSuccessModal from "../TrainingLectureSuccessModal";
import Style from "./Lectures.module.scss";
import LectureBar from "./components/LectureBar";
import StartAssessment from "./components/StartAssessment";
import StartScorm from "./components/StartScorm";

function Lectures() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const t = useTranslation();
  const lang = useCompanyLanguage();
  const { training_id } = useParams();
  const { id } = useParams();
  const { resume } = useParams();

  const trainingDetail: any = useSelector(
    (state: RootState) => state.trainingsReducer.trainingData
  );

  const isLoading = useSelector(
    (state: RootState) => state.loaderReducer.isLoading
  );

  const [activeBar, setActiveBar] = useState(1);
  const [hide, setHide] = useState(false);
  const [loading, setloading] = useState(true);
  const [hide2, setHide2] = useState(true);
  const [updateBackend, setupdateBackend] = useState(0);
  const [played, setPlayed] = useState(false);
  const [isFinishLecture, setIsFinishLecture] = useState(false);

  const [lectures, setLectures] = useState<any>([]);

  // useEffect(() => {
  //   setLectures([]);
  //   dispatch(setTrainingData({}));
  // }, []);

  useEffect(() => {
    setLectures([]);
    dispatch(setTrainingData([]));
    dispatch(getTraining(Number(training_id)));
    // }
  }, [training_id]);

  useEffect(() => {
    const currentModule = trainingDetail?.ordered_modules?.find(function (
      row: any
    ) {
      return parseInt(row?.module?.id) === Number(id);
    });
    console.log("currentModule", played, currentModule);
    if (played && currentModule?.module?.module_status == "BackLog") {
      dispatch(
        updateModuleStatusVideoPDF({
          training_id: Number(training_id),
          module_id: Number(id),
          module_status: "InProgress",
        })
      );
    }
  }, [played, trainingDetail]);

  useEffect(() => {
    setTimeout(() => {
      setHide2(false);
    }, 500);
  }, []);

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href); // preventing back initially
    window.addEventListener("popstate", confirmBack);
    return () => {
      window.removeEventListener("popstate", confirmBack);
    };
  }, []);
  useEffect(() => {
    if (isFinishLecture) {
      const module = trainingDetail?.ordered_modules?.find(
        (i: any) => i?.module?.id == id
      );
      console.log("handleFinish", trainingDetail, id, module);
      if (
        trainingDetail?.is_enrolled &&
        !(
          trainingDetail?.ordered_modules?.find((i: any) => i?.module?.id == id)
            ?.module?.module_status == "Completed"
        ) &&
        (module?.module?.type?.toLowerCase() === "video" ||
          module?.module?.type?.toLowerCase() === "pdf")
      ) {
        dispatch(
          updateModuleStatusVideoPDF({
            training_id: Number(training_id),
            module_id: Number(id),
            module_status: "Completed",
          })
        );
      }
    }
  }, [isFinishLecture, trainingDetail]);
  const handleFinish = () => {
    setIsFinishLecture(true);
  };

  function confirmBack() {
    confirm({
      title: t("Are you sure!"),
      description: t("Leaving this page?"),
      ...confirmProps,
    })
      .then(() => {
        window.removeEventListener("popstate", confirmBack);
        navigate("/training-details/" + training_id);
      })
      .catch(() => {
        window.addEventListener("popstate", confirmBack);
        window.history.pushState(null, document.title, window.location.href);
        window.history.pushState(null, document.title, window.location.href);
      })
      .finally(() => {
        window.addEventListener("popstate", confirmBack);
        window.history.pushState(null, document.title, window.location.href);
        window.history.pushState(null, document.title, window.location.href);
      });
  }

  useEffect(() => {
    if (
      (trainingDetail?.type == "Scheduled" ||
        trainingDetail?.type == "Duration" ||
        trainingDetail?.type == "Not_Scheduled") &&
      trainingDetail?.completion_status == "Incomplete"
    ) {
      toast.error(String(t("This training has been expired")), {
        toastId: String(t("This training has been expired")),
      });
      navigate(`/my-training`);
      return;
    }
    if (
      trainingDetail &&
      trainingDetail?.ordered_modules &&
      trainingDetail?.id == training_id
    ) {
      let order = trainingDetail?.ordered_modules
        ?.filter(
          (i: any) =>
            i?.module?.type.toLowerCase() == "video" ||
            i?.module?.type.toLowerCase() == "pdf" ||
            i?.module?.type.toLowerCase() == "assessment" ||
            i?.module?.type.toLowerCase() == "scorm"
        )
        .findIndex((k: any) => k?.module?.id == id);

      if (resume) {
        setActiveBar(order + 1 || 1);
      }
      setLectures(
        trainingDetail?.ordered_modules?.filter(
          (i: any) =>
            i?.module?.type.toLowerCase() == "video" ||
            i?.module?.type.toLowerCase() == "pdf" ||
            i?.module?.type.toLowerCase() == "assessment" ||
            i?.module?.type.toLowerCase() == "scorm"
        )
      );
      setloading(false);
    }
  }, [trainingDetail]);

  useEffect(() => {
    setHide(true);
    setTimeout(() => {
      setHide(false);
    }, 100);
  }, [activeBar]);

  const saveVideoStampForceToBE = (timeStamp: any) => {
    dispatch(
      updateVideoRecord({
        training_id: training_id,
        module_id: id,
        record: timeStamp,
      })
    );
  };

  const saveVideoRecord = (timeStamp: any) => {
    setupdateBackend(updateBackend + 1);
    let data: any = JSON.parse(localStorage.getItem("video_info") as any) || [];
    if (timeStamp == 0) {
    } else {
      let fin: any = [];
      if (
        data &&
        data?.find(
          (i: any) => i?.training_id == training_id && i?.module_id == id
        )
      ) {
        fin = [
          ...data.filter(
            (j: any) => !(j?.training_id == training_id && j?.module_id == id)
          ),
          {
            ...data?.find(
              (i: any) => i?.training_id == training_id && i?.module_id == id
            ),
            record: timeStamp,
          },
        ];
      } else {
        fin = [
          ...data?.filter(
            (j: any) => !(j?.training_id == training_id && j?.module_id == id)
          ),
          {
            training_id: training_id,
            module_id: id,
            record: timeStamp,
          },
        ];
      }

      dispatch(setVideoData(fin));
    }
    if (updateBackend % 10 == 0)
      dispatch(
        updateVideoRecord({
          training_id: training_id,
          module_id: id,
          record: timeStamp,
        })
      );
  };

  const interfaceBackgroundTextColor =
    useColor("interface_background_text_color") ||
    defaultThemeColor.interface_background_text_color;

  return (
    <div className="overflow-auto">
      <Header
        page={isLoading ? "" : trainingDetail?.name}
        mainHead={t("Trainings")}
        backLink={"/training-details/" + training_id}
      />
      {!isLoading && lectures && lectures.length == 0 && !hide2 && (
        <div className="w-100">
          <div
            className={`d-flex justify-content-center w-100 mt-5 pt-5 overflow-hidden ${Style.bigText}`}
          >
            {t("There is no lecture series added in this training.")}
          </div>
        </div>
      )}
      {
        <>
          <div className="d-flex flex-wrap overflow-x">
            <div
              className={`my-4 ${Style.col7}`}
              style={{
                marginRight: lang === "ar" ? "0rem" : "2rem",
                marginLeft: lang === "ar" ? "2rem" : "0rem",
              }}
            >
              {loading ? (
                <div
                  className={`d-flex justify-content-center w-100 overflow-hidden ${Style.loading}`}
                >
                  <CircularProgress size="3rem" color="primary" />
                </div>
              ) : (
                <div className="col-12">
                  {lectures &&
                  lectures.length > 0 &&
                  lectures[activeBar - 1]?.module?.file &&
                  !hide &&
                  lectures[activeBar - 1]?.module?.type == "Video" ? (
                    <VideoPlayer
                      mainTitle={lectures[activeBar - 1]?.module?.name}
                      mainSubTitle={""}
                      source={
                        lectures &&
                        lectures.length > 0 &&
                        lectures[activeBar - 1]?.module?.file
                      }
                      moduleName={trainingDetail?.name || ""}
                      topicName={lectures[activeBar - 1]?.module?.name || ""}
                      nextVideoClick={() => {
                        setActiveBar(activeBar + 1);
                      }}
                      onFinished={handleFinish}
                      timeCodes={[
                        {
                          fromMs: 0,
                          description: "",
                        },
                      ]}
                      saveVideoStamp={saveVideoRecord}
                      saveVideoStampForceToBE={saveVideoStampForceToBE}
                      setPlayed={setPlayed}
                      video_resume_time={
                        JSON.parse(
                          localStorage.getItem("video_info") as any
                        )?.find(
                          (i: any) =>
                            i.training_id == training_id && i.module_id == id
                        )?.record
                          ? JSON.parse(
                              localStorage.getItem("video_info") as any
                            )?.find(
                              (i: any) =>
                                i.training_id == training_id &&
                                i.module_id == id
                            )?.record
                          : lectures[activeBar - 1]?.module?.video_resume_time
                      }
                    />
                  ) : lectures &&
                    lectures.length > 0 &&
                    lectures[activeBar - 1]?.module?.file &&
                    !hide &&
                    lectures[activeBar - 1]?.module?.type == "PDF" ? (
                    <PDFViewer
                      link={lectures[activeBar - 1]?.module?.file}
                      moduleName={trainingDetail?.name}
                      topicName={lectures[activeBar - 1]?.module?.name}
                      nextVideoClick={() => setActiveBar(activeBar + 1)}
                      onFinished={handleFinish}
                      setSaved={setPlayed}
                    />
                  ) : lectures &&
                    lectures.length > 0 &&
                    !hide &&
                    lectures[activeBar - 1]?.module?.type == "Assessment" ? (
                    <StartAssessment
                      training_id={Number(training_id)}
                      module_id={Number(id)}
                      assessment_id={
                        lectures[activeBar - 1]?.module?.assessment?.id
                      }
                    />
                  ) : lectures &&
                    lectures.length > 0 &&
                    !hide &&
                    lectures[activeBar - 1]?.module?.type == "SCORM" ? (
                    <StartScorm
                      training_id={Number(training_id)}
                      module_id={Number(id)}
                    />
                  ) : null}
                </div>
              )}
              <div className="col-12 mt-3">
                <div
                  className={Style.header}
                  style={{ color: interfaceBackgroundTextColor }}
                >
                  {lectures && lectures[activeBar - 1]?.module?.name}
                </div>
                <div
                  className={`mt-2 ${Style.para}`}
                  style={{ color: interfaceBackgroundTextColor }}
                >
                  {lectures && lectures[activeBar - 1]?.module?.description}
                </div>
              </div>
            </div>
            {loading ? (
              <div
                className={`my-4 ${Style.col5} ${Style.sideMenu} d-flex justify-content-center align-items-center ${Style.loadingSide}`}
              >
                <CircularProgress size="3rem" color="primary" />
              </div>
            ) : (
              <div className={`my-4 ${Style.col5} ${Style.sideMenu}`}>
                {lectures &&
                  lectures.map((lecture: any, i: any) => (
                    <div
                      key={i}
                      className={`w-100`}
                      onClick={() => {
                        navigate(
                          `/training/${training_id}/lectures/${lecture?.module?.id}`
                        );
                        setActiveBar(i + 1);
                      }}
                    >
                      <LectureBar
                        index={i + 1}
                        number={lectures.length}
                        header={lecture?.module?.name || ""}
                        secondaryText={lecture?.module?.text || ""}
                        type={lecture?.module.type}
                        activeBar={activeBar}
                        checked={
                          lecture?.module?.module_status == "Completed" || false
                        }
                        video={
                          lecture?.module.type == "Video"
                            ? lecture?.module?.file
                            : null
                        }
                        row={lecture}
                      />
                    </div>
                  ))}
              </div>
            )}
          </div>
        </>
      }
      {trainingDetail?.compulsory_completed &&
        Object.keys(trainingDetail?.certificate).length > 0 &&
        !trainingDetail?.is_certificate_viewed && (
          <TrainingLectureSuccessModal trainingDetail={trainingDetail} />
        )}
    </div>
  );
}

export default memo(Lectures);
