import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

interface HorizontalPieChartProps {
  pieChartData: Chart.ChartData;
}

const PieChart: React.FC<HorizontalPieChartProps> = ({ pieChartData }) => {
  return <Pie data={pieChartData as any} />;
};

export default PieChart;
