function HomeSVG(props: { color: string }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 18V15"
        stroke={props.color}
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.64 19.2401C20.4 20.6501 19.03 21.8101 17.6 21.8101H6.39999C4.95999 21.8101 3.59999 20.6601 3.35999 19.2401L2.02999 11.2801C1.85999 10.3001 2.35999 8.99009 3.13999 8.37009L10.07 2.82009C11.13 1.97009 12.86 1.97009 13.93 2.83009L20.86 8.37009C21.63 8.99009 22.13 10.3001 21.97 11.2801L21.35 15.0001"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default HomeSVG;
